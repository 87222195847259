import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';

const actionTypes = mirrorCreator(['SET_FILES_DATA', 'SET_FILES_ERROR'], { prefix: 'files/' });

const mutators = {
  setFilesData: (files) => ($$state) => $$state.set('files', files),
  setFilesError: (error) => ($$state) => $$state.set('error', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_FILES_DATA:
      acts.push(mutators.setFilesData(action.files));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_FILES_ERROR:
      return pipe([mutators.setFilesError(action.error)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.files || Immutable.Map();
export const getFilesData = createSelector([getRoot], ($$state) => $$state.get('files'));
export const getFilesError = createSelector([getRoot], ($$state) => $$state.get('error'));

export function fetchFiles() {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.events + '/files/';

    let formData = {};
    formData.contact_form = true;

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.files && response.files === 'complete') {
          dispatch(setFilesData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setFilesError(response.log));
        }
      })
      .catch((error) => dispatch(setFilesError(error.message)));
  };
}
export function setFilesData(files) {
  return {
    type: actionTypes.SET_FILES_DATA,
    files,
  };
}
export function setFilesError(error) {
  return {
    type: actionTypes.SET_FILES_ERROR,
    error,
  };
}
