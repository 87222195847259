import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from 'config/api'
// import Prettyfier from 'components/UI/Prettyfier';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import Header from 'components/Header/Header';
import Footer from 'components/Footer';
import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
// import BlockTitle from 'components/UI/BlockTitle';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";

import {
  fetchData,
  getData,
  getLoadingError,
} from 'ducks/asyncFetcher';

const styles = (theme) => {
  return {
    root: {
      "& .MuiTypography-h5": {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
    },
    paper: {
      padding: "10px 20px",
      maxWidth: "1024px",
      margin: "0 auto 20px",
      textAlign: "left",
    },
  };
};


class CMSPage extends Component {
  componentDidMount() {
    const {page} = this.props;
    this.props.onMount({page});
  }
  render() {
    const { $$data, classes, page, headerProps } = this.props;
    const $$page = $$data !== undefined && $$data.get(page) !== undefined ? $$data.get(page) : undefined;

    return (
      <div className="home">
        <ScrollToTopOnMount />
        <Header current={false} condensed={true} {...headerProps}/>
        <div id="cookies" className={classes.root}>
          <Choose>
            <When condition={$$page !== undefined}>
              <ScrollToTopOnMount />
              <div className="legalWrapper">
                <Box align="middle" mt={4} mb={8} width="100%">
                  <Typography variant="h1" gutterBottom>
                    {$$page.get("title")}
                  </Typography>
                </Box>
                <Paper classes={{ root: classes.paper }}>
                  <div className="content">
                    <Paragrapher
                      prettyfy={true}
                      text={$$page.get("content")}
                      transformBullets={false}
                    />
                  </div>
                </Paper>
              </div>
            </When>
            <Otherwise>
              <div>LOADING !!</div>
            </Otherwise>
          </Choose>
        </div>
        <Footer style={{ zIndex: 189, marginTop: -10 }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$data: getData(state),
    error: getLoadingError(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: ({page}) => {dispatch(fetchData( {key: page, fetchUrl: config[page]!==undefined ? config[page] : config.baseUrl+"/api/page/get/"+page}))}
  };
}
// export default Mission;
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CMSPage));
