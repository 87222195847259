import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import config from 'config/api';
import isArray from 'mout/lang/isArray'
import DynamicForm from 'components/UI/DynamicForm';
import JobForm from 'components/UI/Job/JobForm';
import i18n from 'helpers/i18n';
import Immutable from 'immutable';
import LoginForm from 'components/UI/Login/LoginForm';
import ResetPasswordRequestForm from 'components/UI/ResetPassword/ResetPasswordRequestForm';
import { connect } from 'react-redux';
import ScrollToElementOnMount from 'components/UI/ScrollToElementOnMount';
import { subscribeToEvent, getSubscriptionState, getEventError } from 'components/UI/Event/ducks/event';
import { setCurrentUser, getCurrentUser } from 'components/UI/Account/ducks/authentication';
import Dialog from 'components/UI/Dialog/Dialog';

import RegistrationMessage from './RegistrationMessage'

const styles = (theme) => {
  return {
    root: {
      width: '100%',
      padding: '0 0 10px 0',
      '& .MuiTypography-body1': {
        '& a': {
          color: theme.palette.primary.main
        }
      },
      '& .MuiTypography-h2': {
        fontSize: '2rem',
        [theme.breakpoints.down('md')]: {
          fontSize: '1.6rem',
        },
      },
      '& .MuiTypography-h4': {
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
        },
      },
      '& .MuiTypography-h5': {
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
        },
      },
      '& .MuiTypography-h6': {
        textAlign: 'center',
      },
    },
    padderClass: {
      margin: '5px 0 20px'
    },
    formGroup: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(8),
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: '-1%',
        left: '-1%',
        width: '102%',
        height: '102%',
        content: "''",
        border: '1px #ccc solid',
        zIndex: -1
      },
      '&.noMargins': {
        marginBottom: 0,
        paddingBottom: 0,
      }
    },
    mainForm: {
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: 1,
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    jobForm: {
      maxWidth: 440,
      display: 'block',
      margin: '0 auto'
    },
    whiteBg: {
      position: 'relative',
      '&:before': {
        position: 'absolute',
        left: 0,
        top: 0,
        content: "' '",
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        opacity: 0.9,
      },
    },
    relative: {
      position: 'relative',
    },
    column: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      height: 'auto',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: '1%',
        left: '1%',
        width: '98%',
        height: '98%',
        content: "''",
        border: '1px #ccc solid',
        zIndex: -1
      },
      '& .MuiTypography-h5': {
        marginBottom: theme.spacing(3),
      },
    },
    columnSmall: {
      height: '315px',
      '&:nth-of-type(1)': {
        borderRight: '1px #ccc solid',
        [theme.breakpoints.down('sm')]: {
          borderRight: 'none',
          borderBottom: '1px #ccc solid',
          borderTop: '1px #ccc solid',
        },
      },
    },
    columnPadder: {
      position: 'relative',
      width: 'calc(100% - 20px)',
      marginRight: 10,
      marginLeft: 10,
    },
    padder: {
      padding: '20px 40px',
      margin: '0 30px 20px',
    },
    h5: {
      textAlign: 'center',
    },
    h6: {
      fontWeight: 'normal',
    },
    body1: {
      textAlign: 'center',
    },
    recap: {
      '& a': {
        color: theme.palette.primary.main,
      },
    },
    formTitle: {
      textAlign: 'center',
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(2),
    },
    formSubTitle: {
      paddingTop: theme.spacing(3),
      color: theme.palette.tertiary.main,
    },
    passwordReset: {
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    subscriptionFormNotice: {},
    subscriptionPendingBox: {
      color: '#de6446',
      backgroundColor: '#ffe8d8',
      border: '#ffbb8c 1px solid',
      padding: theme.spacing(3),
      borderRadius: '5px',
      maxWidth: '640px',
    },
    subscriptionPendingMessage: {
      color: '#de6446',
      textAlign: 'left !important',
      paddingLeft: '1em',
      fontWeight: 'bold',
    },
    CardMessageEtudiant:{
        backgroundColor:'white !important',
        padding:'1em',
        textAlign: 'center',
      },
    messageEtudiant: {
      },
    legend:{
      color:'#777',
      fontSize: '75%',
    },

    resgistrationButton:{
      margin:'1em 0',
    }
    };
};
class RegistrationTwoStepsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logMessage: false,
      isVerified: false,
      step: props.step ? props.step : 1,


      // DISABLE THIS
      // step: 2,
      // accountKind: 'rpps',
      // isNewMember: true,

      accountCategories: [],
      accountEventCategory: [],

      registrationResult:{},//B
      popupOpen:true,//B
    };
  }
  componentDidMount() {
    if (this.props.$$subscriptionState === 'complete')
    {
      this.setState({step: 3});
    }
  }
  componentDidUpdate(oldProps) {

    if (oldProps.$$subscriptionState !== 'complete' && this.props.$$subscriptionState === 'complete')
    {
      this._onFormComplete()
    }
    if (oldProps.$$eventError === undefined && this.props.$$eventError !== undefined)
    {
      this._onFormError()
    }
  }
  componentWillUnmount() {
  }
  _onRegistrationCancel = () => {
    if (this.props.onFormCanceled) {
      this.props.onFormCanceled()
    } else {
      this.setState(
        {
          accountJob: undefined,
          step: 1,
          isNewMember: false,
        },
        () => {
          if (this.props.onResize)
          this.props.onResize();
        },
      );
    }
  };
  _onLoginFormComplete = (r) => {
    const { subscribeToEvent, $$event, $$events } = this.props;
    let ef = []
    //console.log('$$event',$$event)
    //console.log('$$events', $$events)
    if ($$event !== undefined && $$event.get('id') !== undefined)
      ef.push($$event.get('id'));
    else if ($$events !== undefined && $$events.size && $$events.get(0) !== undefined)
      ef = $$events.map((e) => e.get('id')).toArray();
    else if ($$events !== undefined && isArray($$events) && $$events[0] !== undefined)
      ef = $$events;
    //console.log('sending subscription with', ef.toJS())
    // console.log('calling subscribeToEvent')
    // console.log('loginFormComplete', $$event, $$events, ef)

    subscribeToEvent({$$events: Immutable.fromJS(ef), action: 'subscribe'});
  }
  _onRegistrationComplete = (r) => {
    // console.log('RegistrationTwoStepsForm _onRegistrationComplete')

    const { showRecap } = this.props;
    const { accountKind } = this.state;
    //console.log('_onRegistrationComplete 2step ini', r);
    //MODIF B

    let newState={
      registrationResult : r,
      accountJob: r.accountJob,
      accountStatus: r.accountStatus,
      //accountProfessionalCat : r.accountProfessionalCat,
      isVerified: r.accountStatus === 'ok',
      //isVerified: accountKind === 'rpps' || r.accountJob === 'lb',
      //FIN
    };

    if (showRecap === true) {
        newState['step']=5;
        this.setState(newState);
    } else {
      this.setState(newState, () => {
        this._onFormComplete();
      })
    }
    //     // this.setState({step: 3, newUserRole: r.accountJob});
  };
  _onToggleAccountCategory = (category) => {
    let currentCategories = this.state.accountCategories;
  };
  _onShowJobSelect = () => {
    this.setState(
      {
        step: 5,
      },
      () => {
        if (this.props.onResize)
        this.props.onResize();
      },
    );
  };
  _onRegisterWith = (kind) => {
    const {accountJob} = this.state;
    this.setState(
      {
        accountJob: kind === 'norpps' ? undefined : accountJob,
        accountKind: kind,
        step: 2,
        isNewMember: true,
      },
      () => {
        if (this.props.onResize)
        this.props.onResize();
      },
    );
  }
  _onJobFormComplete = (o) => {
    //console.log(o.values);
    this.setState(
      {
        accountJob: o.values.accountJob,
        accountCategories: o.values.accountCategories,
        accountJobLabel: o.values.accountJobLabel,
        accountJobPrecision: o.values.accountJobPrecision,
        step: 2,
        accountKind: 'rpps',
        isNewMember: true,
      },
      () => {
        if (this.props.onResize)
        this.props.onResize();
      },
    );
  };
  _onButtonRRPS = () => {
    this.setState(
      {
        accountJob: "es10",
        accountJobLabel: "Médecin",
        accountJobPrecision: null,
        accountCategories: [
          "hasRpps",
          "hasSpeciality",
          "hasExerciseCivility",
          "hasProfessionalInfos",
          "isVerified"
        ],
        step: 2,
        accountKind: 'rpps',
        isNewMember: true,
      },
      () => {
        if (this.props.onResize)
        this.props.onResize();
      },
    );
  };
  _onButtonADELI = () => {
    this.setState(
      {
        accountJob: "es60",
        accountJobLabel: "Infirmier",
        accountJobPrecision: "adeli",
        accountCategories:[
          "hasProfessionalInfos",
          "hasJobPrecision",
          "hasAdeli",
          "isVerified"
        ],
        step: 2,
        accountKind: 'rpps',
        isNewMember: true,
      },
      () => {
        if (this.props.onResize)
        this.props.onResize();
      },
    );
  };
  _onLoginPasswordReset = () => {
    this.setState(
      {
        accountJob: undefined,
        step: 3,
      },
      () => {
        if (this.props.onResize)
        this.props.onResize();
      },
    );
  };
  _onFormComplete = () => {
    const { onClose, onFormComplete, closeOnComplete } = this.props;
    const { isNewMember, accountJob, accountKind, accountStatus, isVerified, registrationResult } = this.state;

    const {$$event, $$events} = this.props;
    console.log('RegistrationTwoStepsForm _onFormComplete')
    // console.log({accountKind, accountJob})
    if (onFormComplete === undefined) {
      if (onClose !== undefined) onClose();
    } else {
      onFormComplete({
        isNewMember: isNewMember,
        accountJob: accountJob,
        accountStatus: accountStatus,
        isVerified: isVerified,
        //isVerified: accountKind === 'rpps' || accountJob === 'lb',
        $$event,
        $$events,
        registrationResult:registrationResult,
      });
      if (closeOnComplete === true) {
        onClose();
      }
    }
  };

  _onFormError = () => {
    const { onClose, onFormComplete, closeOnComplete, $$eventError } = this.props;
    const { isNewMember, accountJob, accountKind, accountStatus, isVerified, registrationResult } = this.state;

    const {$$event, $$events} = this.props;
    console.log('RegistrationTwoStepsForm _onFormError', registrationResult);
    const newregistrationResult={...registrationResult, ...{registrationError:$$eventError}};
    // console.log({accountKind, accountJob})
    if (onFormComplete === undefined) {
      if (onClose !== undefined) onClose();
    } else {
      onFormComplete({
        isNewMember: isNewMember,
        accountJob: accountJob,
        accountStatus: accountStatus,
        isVerified: isVerified,
        $$event,
        $$events,
        registrationResult:newregistrationResult,
      });
      if (closeOnComplete === true) {
        onClose();
      }
    }
  };

  _onAcceptPopup = () => {
    this.setState({popupOpen: false});
  }
  _onPopupClose = () => {
    //laisser ouvert si non accepté
  }
  _toggleAddressChoice = (k, rows) => {
  }
  _onAccountEventCategoryChange = (kind, o) => {
    this.setState({accountEventCategory: kind})
  }
  
  render() {
    const {
      classes,
      $$event,
      $$events,
      onResize,
      $$user,
      showLogin,
      scrollOnMount,
      key,
      subscriptionPopup,
    } = this.props;

    const {
      accountJob,
      accountProfessionalCat,//MODIF B
      accountCategories,
      accountKind,
      accountJobLabel,
      accountJobPrecision,
      accountEventCategory,
      isVerified,
      $$extraFields,
      step,
      registrationResult,
      popupOpen,
    } = this.state;

    const moduleConfig = {
      module: 'account',
      fetchUrl: config.registration + '/edit',
      postUrl: config.registration + '/edit',
      redirectAfterEdit: false,
      afterEdit: 'onFormComplete',
      afterEditLog: '',
      afterEditHideForm: true,
      btCancel: true,
      onFormCancel: this._onRegistrationCancel,
      btCancelLabel: 'Retour',
      btSubmitLabel: 'Envoyer',
      buttonVariant: 'contained',
      formPrecisions: {
        // does nothing
        addressChoice: this._toggleAddressChoice
      },
      captcha: accountKind === 'norpps'
    };
    const hiddenFields = accountKind !== undefined && accountKind === 'rpps'  ? ['accountJob', 'accountJobPrecision'] : [];
    const jobCategory = accountCategories === undefined ? [] : accountCategories;

    console.log('USER', $$user ? $$user.toJS() : $$user);
    console.log('EVENT', $$event ? $$event.toJS() : $$event);
    console.log('EVENTS', $$events ? $$events : $$events);

    let ef = {};
    if ($$event !== undefined && $$event.get('id') !== undefined)
    ef = {
      fromEvent: $$event.get('id'),
    };
    else if ($$events !== undefined && $$events.size && $$events.get(0) !== undefined)
    ef = {
      fromEvents: $$events.map((e) => e.get('id')).toArray(),
    };
    else if ($$events !== undefined && isArray($$events) && $$events[0] !== undefined)
    ef = {
      fromEvents: $$events,
    };
    if (accountEventCategory.length > 0)
      ef.accountEventCategory = accountEventCategory;
    if (accountJob !== undefined)
    ef.accountJob = accountJob;
    if (accountJobPrecision !== undefined && accountJobPrecision !== null)
    ef.accountJobPrecision = accountJobPrecision;
    ef.accountCategories = accountCategories;

    const extraFields = Immutable.fromJS(ef);
    const userRole = $$user !== undefined ? $$user.get('role') : false;
    const userJob = $$user !== undefined ? $$user.get('job') : false;

console.log({step});

    return (
      <div key={key} id="mainform" className={clsx(classes.root, "job_" + accountJob)}>
        {/*<RegistrationMessage registrationResult={registrationResult}/>*/}
        <If condition={step === 1}>
          <If condition={scrollOnMount !== false}>
            <ScrollToElementOnMount />
          </If>
          <Grid container spacing={0}  style={{textAlign: showLogin !== false ? undefined : 'center'}}>
            <Grid item={true} xs={12}>
              <Box w={'100%'}>
                <If condition={userRole === 'member' || userRole === false || userRole === 'visitor'}>
                  <If condition={$$event !== undefined || $$events !== undefined}>
                  <Typography variant="h5" className={classes.formTitle} >
                    <If condition={$$event !== undefined || ($$events !== undefined && $$events.size === 1)}>
                      {i18n.get('subscriptionSelfTitleSingle')}
                    </If>
                    <If condition={$$events !== undefined && $$events.size > 1}>
                      {i18n.get('subscriptionSelfTitlePlural')}
                    </If>
                  </Typography>
                  </If>
                </If>
                <If condition={userRole === 'staff' || userRole === 'admin' || userJob === 'lb'}>
                  <Typography variant="h5">
                    ...
                  </Typography>
                </If>
              </Box>
            </Grid>
            <If condition={showLogin !== false}>
              <Grid item={true} xs={12} md={showLogin !== false ? 6 : false} className={clsx(classes.column)}>
                <Typography variant={'h5'} gutterBottom={true}>
                  {i18n.get('loginFormTitle')}
                </Typography>
                <LoginForm
                  onFormComplete={this._onLoginFormComplete}
                  onPasswordResetRequest={this._onLoginPasswordReset}
                  extraFields={extraFields}
                  showRequiredLegend={false}
                  />
              </Grid>
            </If>
            <Grid item={true} xs={12} md={showLogin !== false ? 6 : 12} className={clsx(classes.column)}>
              
              {/*Ancien formulaire choix profession*/}
              {/*
              <If condition={showLogin !== false}>
                <Typography variant={'h5'} gutterBottom={true}>
                  {i18n.get('subscriptionHasRpps')}
                </Typography>
              </If>
              <JobForm
                className={classes.jobForm}
                userRole={undefined}
                onFormComplete={this._onJobFormComplete}
                showRequiredLegend={false}
                fieldsSet={1}
                extraFields={extraFields}
                />
              */}

              {/*Nouveaux boutons*/}
              <Typography variant={'h5'} gutterBottom={true}>
                  Je m'inscris
                </Typography>

              <Box align="center" w="100%">
                <Button className={classes.resgistrationButton} color="primary" variant="contained" onClick={() => this._onButtonRRPS()}>J'ai un n° RPPS</Button>
              </Box>
              <Box align="center" w="100%">
                <Button className={classes.resgistrationButton} color="primary" variant="contained" onClick={() => this._onButtonADELI()}>J'ai un n° ADELI</Button>
              </Box>

              <Box align="center" w="100%">
                <Button className={classes.resgistrationButton} color="secondary" variant="contained" onClick={() => this._onRegisterWith('norpps')}>{i18n.get('subscriptionHasNoRpps',{pretty: false})}</Button>
              </Box>
            </Grid>
          </Grid>
        </If>
        <If condition={step === 2}>
          <If condition={scrollOnMount !== false}>
            <ScrollToElementOnMount />
          </If>
          <If condition={subscriptionPopup!=undefined}>
            <Dialog closeButton={false} onClose={this._onPopupClose} open={popupOpen}>
              <Box mb={2}>
              <Typography variant="body1">{subscriptionPopup}</Typography>
              </Box>
              <Box align="middle" mb={3}>
              <Button id="register_warning_message_button" variant="contained" color="primary" onClick={this._onAcceptPopup}>OK</Button>
              </Box>
            </Dialog>   
          </If>         
          <Box>
            <If condition={accountKind === 'rpps'}>
              <Typography variant={'h5'} color="primary" className={classes.formTitle}>
                {/*i18n.get('subscriptionHasRppsAndJob', {substitute: {'{job}': accountJobLabel}})*/}
                <If condition={accountJobPrecision === 'adeli'}>
                  {i18n.get('subscriptionHasAdeli')}
                <Else/>
                  {i18n.get('subscriptionHasRpps')}
                </If>

              </Typography>
            </If>
            <If condition={accountKind !== 'rpps'}>
              <Typography variant={'h5'} color="primary" className={classes.formTitle}>
                {i18n.get('subscriptionHasNoRpps')}
              </Typography>
            </If>
          </Box>
          <If condition={i18n.has('subscriptionFormInstructions') === true && accountKind === 'rpps'}>
            <Box className={classes.whiteBg} mb={1}>
              <Typography variant={'h6'} className={classes.h6} style={{ position: 'relative' }}>
                {i18n.get('subscriptionFormInstructions')}
              </Typography>
            </Box>
          </If>
          <Box align="left" className={clsx(classes.mainForm)}>
            <DynamicForm
              key={'form_'+accountKind}
              kind={accountKind}
              moduleConfig={moduleConfig}
              onFormReady={onResize}
              onFormComplete={this._onRegistrationComplete}
              formNotice={<React.Fragment>
                <Box w={'100%'} align="center" mt={-2} mb={2}>
                  <Typography variant="body1" className={classes.subscriptionFormNotice}>{i18n.get('subscriptionFormNotice')}</Typography>
                </Box>
                <If condition={accountKind === 'rpps'}>
                  <Box w={'100%'} align="center">
                    <Button color="secondary" onClick={() => this._onRegisterWith('norpps')}>{i18n.get('subscriptionHasNoId',{pretty: false})}</Button>
                  </Box>
                </If>
              </React.Fragment>
              }
              formFooter={undefined}
              extraFields={extraFields}
              forceHiddenFields={hiddenFields}
              onFormCancel={this._onRegistrationCancel}
              initialCategories={jobCategory}
              groupClass={classes.formGroup}
              padderClass={classes.padderClass}
              />

            <If condition={i18n.has('registrationLogLegend1')}>
              <br />
              <Typography variant={'body1'} classes={{ root: classes.legend }} gutterBottom>
                {i18n.get('registrationLogLegend1', { prettyfy: true })}
              </Typography>
            </If>
            <If condition={i18n.has('registrationLogLegend2')}>
              <hr />
              <Typography variant={'body1'} classes={{ root: classes.legend }} gutterBottom>
                {i18n.get('registrationLogLegend2', { prettyfy: true })}
              </Typography>
            </If>
          </Box>
        </If>
        <If condition={step === 3}>
          <If condition={scrollOnMount !== false}>
            <ScrollToElementOnMount />
          </If>
          <div className={clsx(classes.passwordReset)}>
            <ResetPasswordRequestForm onFormCancel={this._onRegistrationCancel} />
          </div>
        </If>
        <If condition={step === 4}></If>
        <If condition={step === 5}>
          <If condition={scrollOnMount !== false}>
            <ScrollToElementOnMount />
          </If>
            <Grid item xs={12} className={classes.formWrapper}>
              <Box align="center" my={4}>
                  <RegistrationMessage registrationResult={registrationResult}/>
                  {/*
                  <If condition={isMember($$user)}>
                    //B utilisé ????
                    <Typography variant="h5" component="div" color="secondary" align="center" gutterBottom>
                    {i18n.get('subscriptionMemberSuccessTitle', {pretty: true, element: 'div'})}
                  </Typography>
                  </If>
                  <If condition={!isMember($$user)}>

                    <RegistrationMessage registrationResult={registrationResult}/>

                  </If>
                */}
                <Box mt={4}>
                  <Button variant="contained" color="primary" onClick={(e) => {e.preventDefault(); this.props.history.push('/')}}>{i18n.get('navHome')}</Button>
                </Box>
              </Box>
            </Grid>
            </If>
          </div>
        );
      }
    }
    const mapStateToProps = ($$state) => {
      return {
        $$user: getCurrentUser($$state),
        $$subscriptionState: getSubscriptionState($$state),
        $$eventError:getEventError($$state),
      };
    };
    const mapDispatchToProps = (dispatch) => {
      return {
        setCurrentUser: (user) => dispatch(setCurrentUser(user)),
        subscribeToEvent: (props) => dispatch(subscribeToEvent(props)),
      };
    };
    export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegistrationTwoStepsForm)));
