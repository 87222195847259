import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { offerAllowedView, offerAllowedPost } from './ducks/offer';
import OfferNotConnected from './OfferNotConnected';
import OfferReserved from './OfferReserved';

class OfferProtection extends Component {
  render() {
    const { children, type, offerAllowedView, offerAllowedPost, forceDisallowed } = this.props;
    const allowed = type === 'post' ? offerAllowedPost : offerAllowedView;
    return (
      <Choose>
        <When condition={allowed === undefined}>
          <OfferNotConnected />
        </When>
        <When condition={allowed === false || forceDisallowed}>
          <OfferReserved />
        </When>
        <When condition={allowed === true}>{children}</When>
        {/*<Otherwise>
          <div className={'Loading'}>
            <Spinner style={{ marginTop: 200 }}>Chargement...</Spinner>
          </div>
    </Otherwise>*/}
      </Choose>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    offerAllowedView: offerAllowedView(state),
    offerAllowedPost: offerAllowedPost(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OfferProtection));
