import React, {
  Component
} from "react";
import {
  connect
} from "react-redux";
import DynamicList from "components/UI/DynamicList";
import config from "config/api";
import Box from "@material-ui/core/Box";
// import AccountListEmbed from "components/UI/Account/AccountListEmbed";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";

import {
  getCurrentUserRole
} from "ducks/authentication";
class AdminMediaList extends Component {
  render() {
    const {
      userRole,
      embed,
      showFilters,
      showActions
    } = this.props;
    const moduleConfig = {
      module: "media",
      fetchUrl: config.medias + "/list",
      deleteUrl: config.medias + "/delete",
      editUrl: "staff/media/edit",
      filter: this.props.filter,
      showFilters: showFilters,
      showActions: showActions,
      // deleteUrl: '/staff/event/edit',
      // title: 'Evénements'
    };
    return (<div>
      <If condition={ embed !== true}>
          <Box align="center" mt={3} mb={2} px={2}>
            <Typography variant="h2" gutterBottom color="primary">Médias</Typography>
              <If condition={userRole === "admin" && embed !== true}>
                <Button variant="contained" color="primary" onClick={(e) => {e.preventDefault(); this.props.history.push("/staff/media/edit")}}>Ajouter</Button>
              </If>
          </Box>
        </If>
        <DynamicList moduleConfig={moduleConfig} />
      </div>);
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}
export default withRouter(connect(mapStateToProps)(AdminMediaList));
