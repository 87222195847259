import React, { Component } from "react";
import Form from "./FeedbackForm";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
const styles = {
  paper: {
    maxWidth: 1280,
    width: '100%',
    padding: "10px 20px",
    margin: "20px auto 20px",
  },
};
class AdminFeedbackForm extends Component {
  constructor(props) {
    super(props);
    this._onClose = this._onClose.bind(this);
  }
  _onClose() {
    const { history, eventId } = this.props;
    history.push("/staff/feedback/"+eventId+"/list");
  }
  render() {
    const { id, classes, eventId } = this.props;
    return (
        <Paper classes={{ root: classes.paper }}>
          <Form
            id={id}
            onFormComplete={this._onClose}
            onFormCancel={this._onClose}
            eventId={eventId}
          />
        </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(AdminFeedbackForm));
