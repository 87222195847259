import React, { Component } from 'react';
import Form from 'components/UI/Account/AccountAdminForm';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom'
import i18n from 'helpers/i18n';
import { Link } from 'react-router-dom';

const styles = {
  breadCrumbs: {
    'a': {
      display: 'inline'
    }
  },
  paper: {
    maxWidth: 1024,
    padding: '10px 20px',
    margin: '20px auto 20px'
  }
}
// const AccountForm = ({ match, classes }) => {
class DashboardAccountEdit extends Component {
  constructor(props) {
    super(props);
    this._onClose = this._onClose.bind(this);
  }
  _onClose() {
    const { history } = this.props;
      history.push('/dashboard/account/list');
  }
  render() {
    const { id, classes } = this.props;
  return (
    <div>
      <Paper classes={{root: classes.paper}}>
      <Box className={classes.breadCrumbs}>
        <Link to={'/dashboard'}>Dashboard</Link>&nbsp;&gt;&nbsp;<Link to='/dashboard/account/list'>{i18n.get('dashboardNavigationAccounts', {pretty: false})}</Link>&nbsp;&gt;&nbsp;Edition
      </Box>

      <Form id={id} onFormComplete={this._onClose} onFormCancel={this._onClose}/>
      </Paper>
    </div>
  );
}
}

export default withRouter(withStyles(styles)(DashboardAccountEdit));
