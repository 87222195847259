import React from 'react';
import Prettyfier from 'components/UI/Prettyfier';
// import Typography from '@material-ui/core/Typography';
import isArray from 'mout/lang/isArray';
import map from 'mout/object/map';
import { withStyles } from '@material-ui/core/styles';

const doSubstitution = (str, substitute) => {
  if (substitute === undefined) return str;
  // console.log({str, substitute})
  map(substitute, (d, s) => {
    str = str.replace(s, d);
    // console.log(s,d)
  });
  return str;
};
const styles = {
  element: {
    minHeight: '1rem',
  },
};
const Paragrapher = ({
  text,
  prettyfy,
  cleanNewLines,
  element,
  elementStyle,
  substitute,
  classes,
  ...props
}) => {
  let wrapperProps = {};
  if (props.className) wrapperProps.className = props.className;
  if (props.style) wrapperProps.style = props.style;

  let elementProps = {};
  elementProps.className = props.elementClassName !== undefined ? props.elementClassName : classes.element;
  if (props.elementStyle) elementProps.style = props.elementStyle;

  // const parentTag = props.parentTag ? props.parentTag : 'span'
  let str;
  let processedText = text;
  // console.log({processedText})
  if (!processedText || processedText === '') return <span></span>;

  if (cleanNewLines === true) {
    // console.log('before', processedText)
    processedText = processedText.replace(/\n/gm, ' ');
  }

  // console.log('pre', processedText)
  if (!isArray(processedText)) processedText = processedText.split('\n');
  // console.log(processedText);
  // console.log('element', element)
  // console.log('post', processedText)
  // console.log('after', isArray(processedText), processedText)
  return (
    <React.Fragment>
      {processedText.map((item, key) => {
        str = substitute !== undefined ? doSubstitution(item, substitute) : item;
        // console.log('pre', str);
        if (str === '') str = ' ';
        str = prettyfy ? <Prettyfier text={str} /> : str;
        // return <div key={key} {...elementProps}>{str}</div>
        return React.createElement(element !== undefined ? element : 'div', {
          key: key,
          children: str,
          style: elementStyle,
          ...elementProps,
        });
      })}
    </React.Fragment>
  );
};

export default withStyles(styles)(Paragrapher);
