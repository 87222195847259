import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';
// import { getToken } from 'components/UI/Account/ducks/authentication';

// const actionTypes = mirrorCreator(['SET_ACCOUNT_PREVIEW', 'SET_ACCOUNTS_DATA', 'SET_ACCOUNT_ERROR', 'CLEAR_ACCOUNT'], { prefix: 'account/' });
const actionTypes = mirrorCreator(['SET_ACCOUNT_PREVIEW', 'SET_ACCOUNT_ERROR', 'CLEAR_ACCOUNT_PREVIEW'], { prefix: 'account/' });

const mutators = {
  setAccountPreview: (account) => ($$state) => $$state.set('preview', account),
  setAccountError: (error) => ($$state) => $$state.set('error', error),
  setAccountsData: (kind, data) => ($$state) => $$state.setIn(['accounts', kind], data),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {

    // case actionTypes.SET_ACCOUNTS_DATA:
    //   acts.push(mutators.setAccountsData(action.kind, action.accounts));
    //   return pipe(acts, $$state);

    case actionTypes.SET_ACCOUNT_PREVIEW:
      acts.push(mutators.setAccountPreview(action.account));
      return pipe(acts, $$state);

    case actionTypes.SET_ACCOUNT_ERROR:
      return pipe([mutators.setAccountError(action.error)], $$state);

    case actionTypes.CLEAR_ACCOUNT:
      return pipe([mutators.setAccountError(undefined), mutators.setAccountPreview(undefined)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.account || Immutable.Map();
export const getAccountPreview = createSelector([getRoot], ($$state) => $$state.get('preview'));
export const getAccountError = createSelector([getRoot], ($$state) => $$state.get('error'));
// export const getSubscriptionState = createSelector([getRoot], ($$state) => $$state.get('subscribedDates', {}));
export const getSubscriptionState = createSelector([getRoot], ($$state) => $$state.get('subscriptionState', false));

export function fetchAccountPreview({ accountId, params }) {
  return (dispatch) => {
    const url = config.account + '/preview/'+ accountId;

    let formData = {};
    formData.contact_form = true;

    dispatch(clearAccountPreview());

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.account && response.account === 'complete') {
          dispatch(setAccountPreview(Immutable.fromJS(response.data)));
        } else {
          dispatch(setAccountError(response.log));
        }
      })
      .catch((error) => dispatch(setAccountError(error.message)));
  };
}
export function setAccountPreview(account) {
  return {
    type: actionTypes.SET_ACCOUNT_PREVIEW,
    account,
  };
}
export function setAccountError(error) {
  return {
    type: actionTypes.SET_ACCOUNT_ERROR,
    error,
  };
}
export function clearAccountPreview() {
  return {
    type: actionTypes.CLEAR_ACCOUNT_PREVIEW,
  };
}
