import React, { Component } from 'react';
import { connect } from 'react-redux';
import Map, { Marker } from 'components/UI/Map';

import {
  getOffersData,
  getSelectedOffer,
  setSelectedOffer,
  setFilterValue,
  getLocationFilter,
} from './ducks/offer';

/*
const pinColors = {
  inst: {
    default: '0097D9',
    selected: 'D9F0FA',
  },
  remp: {
    default: 'E35323',
    selected: 'FBE5DE',
  },
  */
  const pinColors = {
    inst: {
      default: 'blue',
      selected: 'blue_selected',
    },
    remp: {
      default: 'red',
      selected: 'red_selected',
    },
  };

class OfferMarker extends Component {
  render() {
    const { $$offer, selected, onClick } = this.props;
    const lat = $$offer.get('offerLat');
    const lng = $$offer.get('offerLng');
    const category = $$offer.get('offerCategory');

    const google = window.google;
    const pinColor =
      category && pinColors[category] ? pinColors[category][selected ? 'selected' : 'default'] : pinColors['inst']['default'];

    // https://stackoverflow.com/questions/7095574/google-maps-api-3-custom-marker-color-for-default-dot-marker/7686977#7686977
    const pinImage = new google.maps.MarkerImage(
      //'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + pinColor,
      '/map_pins/chart_'+pinColor+'.png',
      new google.maps.Size(21, 34),
      new google.maps.Point(0, 0),
      new google.maps.Point(10, 34),
    );
    /*
    const pinShadow = new google.maps.MarkerImage(
      'https://chart.apis.google.com/chart?chst=d_map_pin_shadow',
      new google.maps.Size(40, 37),
      new google.maps.Point(0, 0),
      new google.maps.Point(12, 35),
    );*/
    const pinShadow=null;

    return (
      <Marker
        position={{ lat, lng }}
        lat={lat}
        lng={lng}
        onClick={onClick}
        icon={pinImage}
        shadow={pinShadow}
      />
    );
  }
}
OfferMarker = connect(
  (state, ownProps) => ({
    selected: getSelectedOffer(state) === ownProps.$$offer,
  }),
  (dispatch, ownProps) => ({
    onClick: () => dispatch(setSelectedOffer(ownProps.$$offer)),
  }),
)(OfferMarker);

class OfferMap extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      searchOnMapMove: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { $$locationFilter } = this.props;
    if ($$locationFilter !== prevProps.$$locationFilter) {
      const { northEast, southWest } = $$locationFilter.get('bounds').toJS();
      this.setMapBounds({ northEast, southWest });
    }
  }

  setMapBounds = ({ northEast, southWest }) => {
    const map = this.mapRef.current;
    if (!map) throw new Error('Cannot set bounds without a map');

    const bounds = {
      east: northEast.lng,
      north: northEast.lat,
      south: southWest.lat,
      west: southWest.lng,
    };
    map.fitBounds(bounds);
  };

  handleSearchOnMapMoveChange = (event) => {
    this.setState({ searchOnMapMove: event.target.checked });
  };

  handleIdleBoundsChange = ({ northEast, southWest }) => {
    const { setFilterValue } = this.props;

    const value = [northEast.lat, northEast.lng, southWest.lat, southWest.lng];
    setFilterValue('map', value);
  };

  render() {
    const { width = '100%', height = '70vh', $$offers } = this.props;

    return (
      <Map
        loadingComponent={() => <div style={{ width, height }}>Chargement de la carte...</div>}
        errorComponent={() => (
          <div style={{ width, height }}>{"Erreur lors de l'initialisation de la carte"}</div>
        )}
        style={{ width, height, borderRadius: '15px' }}
        className="offerMap"
        defaultCenter={{ lat: 47.071716, lng: 1.484191 }}
        defaultZoom={6}
        disableDefaultUI
        zoomControl
        onIdleBoundsChange={this.handleIdleBoundsChange}
        mapRef={this.mapRef}
      >
        {$$offers === undefined ||
          $$offers.map(($$offer) => <OfferMarker key={$$offer.get('id')} $$offer={$$offer} />)}
      </Map>
    );
  }
}

const mapStateToProps = (state) => ({
  $$offers: getOffersData(state),
  $$locationFilter: getLocationFilter(state),
});

const mapDispatchToProps = {
  setFilterValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferMap);
