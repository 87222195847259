const toHHMMSS = function (str, pad) {
  const sec_num = parseInt(str, 10); // don't forget the second param
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (pad) {
  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  }
  return hours+'H'+minutes+':'+seconds;
}
const toHHMM = function (time, pad) {
  const sec_num = parseInt(time, 10); // don't forget the second param
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  // var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (pad && hours < 10) {hours = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  // if (seconds < 10) {seconds = "0"+seconds;}
  let str = ''
  if (hours > 0)
    str += hours+' heure'+(hours>1? 's ': ' ')
  str += (minutes > 0 ? minutes : '')
  if (hours < 1)
    str += ' minute'+(minutes>1? 's ': ' ')
  return str
  // return hours+' heure'+(hours>1? 's ': ' ')+(minutes > 0 ? minutes : '');
}

export {toHHMMSS,toHHMM};
