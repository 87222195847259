/*eslint-disable no-unused-vars*/

import mirrorCreator from "mirror-creator";
import Immutable from "immutable";
import { createSelector } from "reselect";
import pipe from "helpers/redux-state-mutators.js";
import queryString from "query-string";
// import config from 'config';
import isObject from "mout/lang/isObject";
import keys from "mout/object/keys";
import map from "mout/object/map";

const actionTypes = mirrorCreator(
  [
    "SET_CONFIG",
    "SET_DATA",
    "CLEAR_DATA",
    "SET_LOADING_ERROR",
    "SET_POST_RUNNING",
    "SET_POST_COMPLETE",
    "SET_POST_ERROR",
    "SET_RESPONSE_DATA",
    "SET_REQUIRED_PRECISIONS",
    "CLEAR_FORM",
  ],
  { prefix: "dynamicForm/" }
);

const mutators = {
  setConfig: (id, config) => ($$state) =>
    $$state.setIn([id, "config"], Immutable.fromJS(config)),
  setData: (id, data) => ($$state) =>
    $$state.setIn([id, "data"], Immutable.fromJS(data)),
  clearData: (id, data) => ($$state) => $$state.deleteIn([id, "data"]),
  clearLoadingError: (id) => ($$state) =>
    $$state.deleteIn([id, "loadingError"]),
  setLoadingError: (id, error) => ($$state) =>
    $$state.setIn([id, "loadingError"], error),
  setPostRunning: (id, postRunning) => ($$state) =>
    $$state.setIn([id, "postRunning"], postRunning),
  setPostComplete: (id, postComplete) => ($$state) =>
    $$state.setIn([id, "postComplete"], postComplete),
  setResponseData: (id, responseData) => ($$state) =>
    $$state.setIn([id, "responseData"], responseData),
  setPostError: (id, postError) => ($$state) =>
    $$state.setIn([id, "postError"], postError),
  setRequiredPrecisions: (id, field, rows) => ($$state) =>
    $$state.setIn([id, 'precisions', field], rows),
  clear: (id) => ($$state) => $$state.delete(id),
};
export default function reducer($$state = Immutable.Map(), action) {
  switch (action.type) {
    case actionTypes.SET_CONFIG:
      return pipe([mutators.setConfig(action.formId, action.config)], $$state);
    case actionTypes.SET_DATA:
      return pipe(
        [
          mutators.setData(action.formId, action.data),
          mutators.clearLoadingError(action.formId),
        ],
        $$state
      );
    case actionTypes.CLEAR_DATA:
      return pipe(
        [
          mutators.clearData(action.formId),
          mutators.clearLoadingError(action.formId),
        ],
        $$state
      );
    case actionTypes.CLEAR_FORM:
      return pipe([mutators.clear(action.formId)], $$state);
    case actionTypes.SET_LOADING_ERROR:
      return pipe(
        [mutators.setLoadingError(action.formId, action.error)],
        $$state
      );
    case actionTypes.SET_POST_RUNNING:
      return pipe(
        [mutators.setPostRunning(action.formId, action.postRunning)],
        $$state
      );
    case actionTypes.SET_POST_COMPLETE:
      return pipe(
        [mutators.setPostComplete(action.formId, action.postComplete)],
        $$state
      );
    case actionTypes.SET_RESPONSE_DATA:
      return pipe(
        [mutators.setResponseData(action.formId, action.responseData)],
        $$state
      );
    case actionTypes.SET_REQUIRED_PRECISIONS:
      return pipe(
        [mutators.setRequiredPrecisions(action.formId, action.field, action.rows)],
        $$state
      );
    case actionTypes.SET_POST_ERROR:
      return pipe(
        [mutators.setPostError(action.formId, action.postError)],
        $$state
      );
    default:
      return $$state;
  }
}

const flattenFields = (fields) =>
  fields.reduce((list, field) => {
    switch (field.get("type")) {
      case "Group":
        return list.concat(flattenFields(field.get("items", Immutable.List())));
      default:
        if (field.has("id")) return list.push(field);
        else return list;
    }
  }, Immutable.List());

export const makeSelectors = (formId) => {
  const selectors = {};

  const getRoot = (selectors.getRoot = (state, props) =>
    state.dynamicForm.get(formId, Immutable.Map()));
  const getData = (selectors.getData = createSelector([getRoot], ($$state) =>
    $$state.get("data", Immutable.Map())
  ));
  const getIsDataLoaded = (selectors.getIsDataLoaded = createSelector(
    [getRoot],
    ($$state) => $$state.get("data") !== undefined
  ));
  const getFields = (selectors.getFields = createSelector([getData], (data) =>
    data.get("fields", Immutable.List())
  ));
  const getBottomActions = (selectors.getBottomActions = createSelector(
    [getData],
    (data) => data.get("bottomActions", Immutable.List())
  ));
  const getFlatFields = (selectors.getFlatFields = createSelector(
    [getFields],
    (fields) => flattenFields(fields)
  ));
  const getFieldNames = (selectors.getFieldNames = createSelector(
    [getFlatFields],
    (fields) =>
      fields.reduce(
        (result, field) => result.set(field.get("id"), field.get("label")),
        Immutable.Map()
      )
  ));
  const getLinkMap = (selectors.getLinkMap = createSelector([getData], (data) =>
    data.get("linkMap", Immutable.Map())
  ));
  // const getCategories = selectors.getLinkMap = createSelector(
  //   [getData],
  //   data => {
  //     let categories = Immutable.Map();
  //     data.get('fields', Immutable.Map()).map((field) => {
  //       if (field.get('categories') !== '')
  //     })
  //   }
  // );
  const getValues = (selectors.getValues = createSelector([getData], (data) =>
    data.get("values", Immutable.Map())
  ));
  const getInitialActiveCategories = (selectors.getInitialActiveCategories = createSelector([getData], (data) =>
    data.get("activeCategories", Immutable.Map())
  ));
  const isReadOnly = (selectors.isReadOnly = createSelector([getData], (data) =>
    data.get("readOnly", false)
  ));
  const getLoadingError = (selectors.getLoadingError = createSelector(
    [getRoot],
    ($$state) => $$state.get("loadingError")
  ));
  const isPostRunning = (selectors.isPostRunning = createSelector(
    [getRoot],
    ($$state) => $$state.get("postRunning", false)
  ));
  const isPostComplete = (selectors.isPostComplete = createSelector(
    [getRoot],
    ($$state) => $$state.get("postComplete") === true
  ));
  const getPostError = (selectors.getPostError = createSelector(
    [getRoot],
    ($$state) => $$state.get("postError")
  ));
  const getResponseData = (selectors.getResponseData = createSelector(
    [getRoot],
    ($$state) => $$state.get("responseData")
  ));
  const getRequiredPrecisions = (selectors.getRequiredPrecisions = createSelector(
    [getRoot],
    ($$state) => $$state.get("precisions")
  ));
  // const getFormToken = (selectors.getFormToken = createSelector([getData], (data) =>
  //   data.get("token", false)
  // ));

  return selectors;
};

const convertObjectsInQuery = (data) => {
  keys(data).forEach((key) => {
    const value = data[key];
    if (!isObject(value)) return;

    const subKeys = keys(value);
    if (subKeys.length > 0) {
      delete data[key];
      subKeys.forEach((subKey) => {
        const newKey = `${key}[${subKey}]`;
        data[newKey] = value[subKey];
      });
    }
  });
};

export function makeActionCreators(formId) {
  const addFormId = (action) => ({ ...action, formId });
  const actionCreators = {};

  const initForm = (actionCreators.initForm = (moduleConfig, id, kind) => {
    // console.log('initing with config', moduleConfig)
    return (dispatch) => {
      dispatch(clearForm());
      const url =
        id !== undefined
          ? moduleConfig.fetchUrl + "/" + id + (kind ? '?view='+kind : '')
          : moduleConfig.fetchUrl + (kind ? '?view='+kind : '');
      dispatch(fetchData(url));
    };
  });
  const clearForm = (actionCreators.clearForm = () => {
    return addFormId({
      type: actionTypes.CLEAR_FORM,
    });
  });
  const fetchData = (actionCreators.fetchData = (url) => {
    return (dispatch) => {
      return fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          dispatch(
            addFormId({
              type: actionTypes.SET_DATA,
              data: response.data,
            })
          );
        })
        .catch((error) => {
          // dispatch(addFormId({
          //   type: actionTypes.SET_LOADING_ERROR,
          //   error: error.message,
          // }));
          dispatch(setLoadingError(error.message));
          throw error;
        });
    };
  });
  const postData = (actionCreators.postData = (
    data,
    moduleConfig,
    id,
    kind,
    onPostComplete
  ) => {
    return (dispatch) => {
      let url = id ? moduleConfig.postUrl + "/" + id : moduleConfig.postUrl + (kind ? '?view='+kind : '');

      dispatch(setPostRunning(true));
      data.fsubmit = moduleConfig.module + "_form";
      // console.log('RECEIVED POST DATA', data)
      data = { ...data };
      convertObjectsInQuery(data);
      // console.log('RECEIVED POST DATA2', data)

      let fetchOptions;
      if (moduleConfig.submitMethod && moduleConfig.submitMethod === "GET") {
        url +=
          "?q=1&" + queryString.stringify(data, { arrayFormat: "bracket" });
        fetchOptions = {
          credentials: "include",
          method: moduleConfig.submitMethod || "POST",
          headers: {
            Accept:
              "application/json, application/xml, text/plain, text/html, *.*",
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        };
      } else
        fetchOptions = {
          credentials: "include",
          method: moduleConfig.submitMethod || "post",
          headers: {
            Accept:
              "application/json, application/xml, text/plain, text/html, *.*",
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
          },
          body: queryString.stringify(data, { arrayFormat: "bracket" }),
        };
      // console.log('FETCH OPTIONS', fetchOptions)
      fetch(url, fetchOptions)
        .then((response) => response.json())
        .then((response) => {
          // console.log('response was', response)
          dispatch(setPostRunning(false));
          if (response.form && response.form === 'incomplete') {
            if (response.data !== undefined) {
              let incompleteStates = {};
              map(response.data, (rows, k) => {
                dispatch(setRequiredPrecisions(k, Immutable.fromJS(rows)));
                // if (moduleConfig.formPrecisions[k])
                //   moduleConfig.formPrecisions[k](k, rows)
                // // console.log('missing infos for', k, rows)
              })
            }
          } else if (
            response.form &&
            (response.form === "complete" || response.form === "saved")
          ) {
            if (response.data !== undefined)
              dispatch(setResponseData(response.data));
            dispatch(setPostComplete(true));
            // if (onPostComplete && response.data) onPostComplete(response.data);
            return;
          } else {
            // if (onPostComplete && response.log) onPostComplete(response.log);
            // dispatch(setPostRunning(false));
            dispatch(setPostError(response.log));
            return;
            // throw new Error(response.log);
          }
        })
        .catch((error) => {
          dispatch(setPostRunning(false));
          dispatch(setPostError(error.message));
          throw error;
        })
        .finally(() => dispatch(setPostRunning(false)));
    };
  });
  const setPostError = (actionCreators.setPostError = (postError) => {
    return addFormId({
      type: actionTypes.SET_POST_ERROR,
      postError,
    });
  });
  const setPostRunning = (actionCreators.setPostRunning = (postRunning) => {
    return addFormId({
      type: actionTypes.SET_POST_RUNNING,
      postRunning,
    });
  });
  const setLoadingError = (actionCreators.setLoadingError = (loadingError) => {
    return addFormId({
      type: actionTypes.SET_LOADING_ERROR,
      loadingError,
    });
  });
  const setPostComplete = (actionCreators.setPostComplete = (postComplete) => {
    return addFormId({
      type: actionTypes.SET_POST_COMPLETE,
      postComplete,
    });
  });
  const setResponseData = (actionCreators.setResponseData = (responseData) => {
    return addFormId({
      type: actionTypes.SET_RESPONSE_DATA,
      responseData,
    });
  });
  const setRequiredPrecisions = (actionCreators.setPostRunning = (field, rows) => {
    return addFormId({
      type: actionTypes.SET_REQUIRED_PRECISIONS,
      field, rows,
    });
  });

  return actionCreators;
}
