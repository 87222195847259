import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import config from "config/api";
// import getFetchOptions from 'helpers/getFetchOptions';
import DynamicForm from "components/UI/DynamicForm";
import DialogConfirm from "components/UI/Dialog/DialogConfirm";

// import // getCurrentUserId,
// "ducks/authentication";

class NewsAdminForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      dialogName: undefined,
      dialogTitle: undefined,
      dialogText: undefined,
    };
    this._onConfirmed = this._onConfirmed.bind(this);
    this._onCanceled = this._onCanceled.bind(this);
  }

  _getI18n() {
    this.i18n = {
      send: "Enregistrer",
      sending: "Enregistrement...",
    };
  }
  _onConfirmed(name) {
    if (this["_do" + name]) {
      this["_do" + name]();
    }
  }
  _onCanceled() {
    this.setState({
      dialogOpen: false,
      dialogName: undefined,
      dialogTitle: undefined,
      dialogText: undefined,
    });
  }

  render() {
    const { id } = this.props;
    const moduleConfig = {
      module: "news",
      fetchUrl: config.news + "/edit",
      postUrl: config.news + "/edit",
      formTitle: false,
      redirectAfterEdit: false,
      afterEdit: "onFormComplete",
    };

    const { dialogOpen, dialogName, dialogTitle, dialogText } = this.state;
    if (this.state.redirectToList) {
      return (
        <Redirect
          to={{
            pathname: "/staff/news/list",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <If condition={dialogOpen}>
          <DialogConfirm
            open={true}
            onConfirmed={this._onConfirmed}
            onCanceled={this._onCanceled}
            name={dialogName}
            title={dialogTitle}
            text={dialogText}
            textStyle={{ textAlign: "center" }}
          />
        </If>
        <DynamicForm moduleConfig={moduleConfig} id={id} {...this.props} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ($$state) => {
  return {
    // currentUserId: getCurrentUserId($$state),
  };
};

export default connect(mapStateToProps)(NewsAdminForm);
