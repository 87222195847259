import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import EventRegistration from 'components/UI/Event/EventRegistration';

const EventRegistrationStyled = withStyles((theme) => ({
  root: {
    '& .MuiTypography-h1': {
      marginBottom: theme.spacing(3),
    },
  },
  header: {
    backgroundColor: 'transparent',
    '&:before': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      content: "' '",
      // height: '160px',
      height: '220px',
      backgroundColor: theme.palette.secondary.dark,
      // backgroundImage: 'url('+require('assets/images/header_registration.jpg')+')',
      backgroundPosition: '50% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  // whiteBg: {
  //   '&:before': {
  //     backgroundColor: 'transparent',
  //   },
  // },
  titleIcon: {
    backgroundImage: 'url(' + require('assets/images/logo.png') + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundColor: '#fff',
    backgroundSize: '70%',
  },
  left: {
    backgroundColor: '#fff',
    borderRadius: '16px',
    border: 'none',
    // marginBottom: '-170px',
    width: '80%',
    maxWidth: 960,
  },
  formWrapper: {
    marginTop: 0,
  },
}))(EventRegistration);

class EventRegistrationWrapper extends Component {
  render() {
    return <EventRegistrationStyled templateProps={{ kind: 'fullWidth', maxWidth: 'auto' }} />;
  }
}

export default EventRegistrationWrapper;
