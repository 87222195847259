import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';

const styles = (theme) => ({
  root: {
    position: 'relative',
    height: 387,
    width: '100%',
    borderRadius: 20,
    overflow: 'hidden',
  },
  image: {
    height: 387,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  mobileStepper: {
    marginTop: 0,
    paddingTop: 0,
    justifyContent: 'center',
    width: 'auto',
    display: 'inline-block',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 0,
    backgroundColor: 'transparent',
  },
  buttonNav: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: '#0A569A',
    zIndex: '9',
    boxShadow: theme.shadows[4],
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#fff',
    },
    '&$disabled': {
      backgroundColor: '#fff',
    },
    '& .MuiSvgIcon-root': {
      margin: 0,
    },
  },
  buttonLeft: {
    left: 20,
    [theme.breakpoints.down('md')]: {
      left: '-10px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
      left: '-10px',
      '& .MuiSvgIcon-root': {
        fontSize: '0.8rem',
      },
    },
  },
  buttonRight: {
    right: 20,
    [theme.breakpoints.down('md')]: {
      right: '-10px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
      right: '-10px',
      '& .MuiSvgIcon-root': {
        fontSize: '0.8rem',
      },
    },
  },
});

class OfferImages extends React.Component {
  state = {
    activeStep: 0,
    dialogOpen: false,
    dialogImageURL: undefined,
  };

  handleNextClick = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handlePreviousClick = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  handleImageClick = (url) => {
    //this.setState({ dialogOpen: true, dialogImageURL: url });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const { images, classes, theme } = this.props;
    const { activeStep, dialogOpen, dialogImageURL } = this.state;

    if (images === undefined || images.length === 0) return null;

    const imageCount = images.length;
    const maxSteps = imageCount;

    return (
      <div className={classes.root}>
        <SwipeableViews enableMouseEvents onChangeIndex={this.handleStepChange} index={activeStep}>
          {images.map((url) => (
            <div
              key={url}
              style={{ backgroundImage: `url("${url}")` }}
              className={classes.image}
              onClick={() => this.handleImageClick(url)}
            />
          ))}
        </SwipeableViews>
        {imageCount > 1 && (
          <>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              className={classes.mobileStepper}
            />
            <IconButton
              size="small"
              onClick={this.handleNextClick}
              disabled={activeStep === maxSteps - 1}
              className={[classes.buttonNav, classes.buttonRight]}
            >
              {theme.direction === 'rtl' ? <ArrowBack /> : <ArrowForward />}
            </IconButton>
            <IconButton
              size="small"
              onClick={this.handlePreviousClick}
              disabled={activeStep === 0}
              className={[classes.buttonNav, classes.buttonLeft]}
            >
              {theme.direction === 'rtl' ? <ArrowForward /> : <ArrowBack />}
            </IconButton>
          </>
        )}
        <Dialog maxWidth={600} open={dialogOpen} onClose={this.handleDialogClose}>
          <img src={dialogImageURL} />
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(OfferImages);
