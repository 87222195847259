import mirrorCreator from "mirror-creator";
import Immutable from "immutable";
import { createSelector } from "reselect";
import pipe from "helpers/redux-state-mutators.js";
import queryString from "query-string";
import config from "config/api";

const actionTypes = mirrorCreator(
  ["SET_MESSAGESS", "SET_ERROR", "CLEAR_MESSAGES"],
  { prefix: "adminMessages/" }
);

const mutators = {
  setMessages: (messages) => ($$state) => $$state.set("messages", messages),
  clearMessages: () => ($$state) => $$state.delete("messages"),
  setError: (error) => ($$state) => $$state.set("error", error),
  clearError: () => ($$state) => $$state.delete("error"),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_MESSAGESS:
      acts.push(mutators.setMessages(action.messages));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.CLEAR_MESSAGES:
      acts.push(mutators.clearMessages());
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_ERROR:
      return pipe([mutators.setError(action.error)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.adminMessages || Immutable.Map();
export const getMessages = createSelector([getRoot], ($$state) => {
  return $$state.get("messages")}
);
export const getMessagesError = createSelector([getRoot], ($$state) =>
  $$state.get("error")
);

export function fetchMessages({eventId}) {
  return (dispatch, getState) => {
    let url = config.message+'/list/'+eventId
    let formData = {};
    formData.contact_form = true;

    fetch(url, {
      credentials: "include",
      method: config.urlMethod || "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.message && response.message === "ok" && response.list === 'complete') {
          const currentMessages = getMessages(getState());
          const newMessages = Immutable.fromJS(response.data);
          if (currentMessages !== undefined && currentMessages.equals(newMessages)){
             // console.log('SAME')
          } else
          dispatch(setMessages(newMessages));
        } else {
          dispatch(setError(response.log));
        }
      })
      .catch((error) => dispatch(setError(error.message)))
  };
}
export function setMessages(messages) {
  return {
    type: actionTypes.SET_MESSAGESS,
    messages,
  };
}
export function clearMessages() {
  return {
    type: actionTypes.CLEAR_MESSAGES,
  };
}
export function setError(error) {
  return {
    type: actionTypes.SET_ERROR,
    error,
  };
}
