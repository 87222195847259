import React, { Component } from 'react';
import config from 'config/api';
import TemplateFixedWidth from 'components/UI/Template/TemplateFixedWidth';
import TemplateFullWidth from 'components/UI/Template/TemplateFullWidth';
class Template extends Component {
  render() {
    return (<Choose>
    <When condition={config.templateKind === 'fullWidth'}>
    <TemplateFullWidth {...this.props} />
    </When>
    <Otherwise>
    <TemplateFixedWidth {...this.props} />
    </Otherwise>
    </Choose>);

  }
}

export default Template;
