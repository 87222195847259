import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
// import colors from 'config/colors';
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  remainingTime: {
    textAlign: "inherit",
    color: theme.palette.primary.main,
    // textTransform: "uppercase",
    margin: "0 auto 10px",
  },
  remainingTimeInline: {
    textAlign: "inherit",
    display: "inline-block",
    verticalAlign: "middle",
    textTransform: "lowercase",
    // width: '33%',
    margin: "0 40px 0",
    // '& em': {
    // fontSize: '200%'
    // }
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px 10px",
    },
  },
  emphasis: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
});
class RemainingTime extends Component {
  render() {
    const { days, hours, minutes, classes, variant } = this.props;
    return (
      <React.Fragment>
        <Typography
          variant={variant || "subtitle1"}
          classes={{ root: classes.remainingTimeInline }}
          gutterBottom
        >
          <b>{days}</b> Jour{days > 1 ? "s" : ""}
          {" - "}
          <b>{hours}</b> Heure{hours > 1 ? "s" : ""}
          {" - "}
          <b>{minutes}</b> Minute{minutes > 1 ? "s" : ""}
        </Typography>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RemainingTime);
