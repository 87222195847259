import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Replays from 'components/UI/Replays/Replays';

const ReplaysStyled = withStyles((theme) => ({
  root: {},
  paperWrapper: {
    width: '100%',
    display: 'block',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      content: "' '",
      // height: '160px',
      height: '160px',
      backgroundColor: theme.palette.secondary.dark,
      // backgroundImage: 'url('+require('assets/images/header_replay.jpg')+')',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      zIndex: -1,
    },
  },
}))(Replays);

// class ReplaysWrapper extends Component {
//   render() {
//     return <Replays templateProps={{kind: 'fullWidth', maxWidth: 'auto'}}/>
//   }
// }

export default ReplaysStyled;
