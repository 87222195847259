import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import config from 'config/api';
import clsx from 'clsx';
import i18n from 'helpers/i18n';
import Template from 'components/UI/Template/Template';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Spinner from 'components/UI/Spinner';
import { withRouter } from 'react-router-dom';
import { fetchData, getDataHome, getLoadingError } from 'ducks/asyncFetcher';
import { fetchMedias, getMediasData } from 'components/UI/Media/ducks/media';
import { isLoggedIn, getCurrentUserRole, getCurrentUser, getUniverse, setUniverse } from 'components/UI/Account/ducks/authentication';
import MediaCard from 'components/Media/MediaCard';
import HomeHelp from 'components/Home/HomeHelp';

const AF_KEY = 'home';

const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      minHeight: '100%',
      width: '100%',
    },
    pageTitle: {
      paddingTop: 16,
    },

    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.primary.light,
        zIndex: -1
      }
    },
    paper: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& a': {
        color: 'inherit'
      }
    },


    mainWrapper: {
      // marginTop: "-300px",
      position: 'relative',
      zIndex: 9,
      color: '#fff',
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        // marginTop: "-270px",
      },
    },
    mainTitle: {
      // marginTop: 20,
      textAlign: 'center',
      marginBottom: 40,
    },
    livePaper: {
      color: '#fff',
      margin: '0 auto',
      padding: '40px 20px 50px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 20px',
      },
      // padding: '60px'
    },
    legend: {
      marginBottom: 30,
    },
  };
};
class Medias extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {
    if (this.props.match && this.props.match.params.universe && this.props.universe === undefined) this.props.setUniverse(this.props.match.params.universe);
    else {
      this.props.onMount();
    }
  }
  componentDidUpdate(oldProps) {
    if (oldProps.universe === undefined && this.props.universe !== undefined) {
      this.props.onMount();
    }
  }
  _redirectToLogin = (action) => {
    let pn = window.location.pathname;
    // console.log(pn[pn.length -1])
    if (pn[pn.length -1] === '/') {
      pn = pn.substring(0, pn.length -1);
      // console.log('mathced!')
    }

    let returnUrl = pn+window.location.search;
    // console.log(returnUrl)
    returnUrl = encodeURIComponent(returnUrl);
    // console.log(returnUrl)
    this.props.history.push(
      "/login?action="+action+"&_pn="+
      returnUrl
    )
  }

  render() {
    const { userRole, classes, $$medias, universe } = this.props;
// console.log($$medias)
    return (
      <Template current="medias">

        <Box className={classes.paperWrapper}>
          <Typography variant="h1" className={classes.pageTitle}>
            {i18n.get('mediasTitle')}
          </Typography>{' '}
          <Paper square elevation={0} className={classes.paper}>
            <Choose>
              <When condition={userRole === undefined}>
                <Box my={6} align="center">
                  <Typography
                    variant="h5"
                    gutterBottom={true}
                    className={clsx(classes.legend, classes.h6extends)}
                    >
                    Merci de vous connecter pour accéder à ce contenu
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '0 5px'}}
                    onClick={e => this._redirectToLogin('login')}>
                    {" "}
                    {i18n.get('loginAction', {pretty: false})}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '0 5px'}}
                    onClick={e => this._redirectToLogin('register')}
                    >
                    {' '}
                    {i18n.get('subscribeAction', {pretty: false})}
                  </Button>
                </Box>
              </When>
              <When
                condition={
                  userRole === 'member' || userRole === 'admin' || userRole === 'staff'
                }
                >
                <If condition={$$medias !== undefined && $$medias.size > 0}>
                  <Grid container>
                  {$$medias
                    .map(($$card, cIdx) => {
                      return (
                        <MediaCard key={'r_'+cIdx} $$item={$$card} kind={'media'} imagePosition={'horizontal'} useUniverseInLinks={universe !== undefined}/>
                      );
                    })
                    .toArray()}
                    </Grid>
                    <Box mt={4} width={'100%'}>
                      <HomeHelp/>
                    </Box>
                  </If>
                  <If condition={$$medias === undefined || $$medias.size === 0}>
                    <Typography align="center" variant={'h5'}>
                      Pas de média pour l'instant
                    </Typography>
                  </If>
                </When>
                <When condition={$$medias !== undefined && userRole === 'speaker'}>
                  <p>No place for speaker</p>
                </When>
                <Otherwise>
                  <div className={'Loading'}>
                    <Spinner style={{ marginTop: 200 }}>Chargement...</Spinner>
                  </div>
                </Otherwise>
              </Choose>
            </Paper>
          </Box>
        </Template>
      );
    }
  }

  function mapStateToProps(state) {
    const props = {
      $$data: getDataHome(state),
      error: getLoadingError(state),
      isLoggedIn: isLoggedIn(state),
      userRole: getCurrentUserRole(state),
      $$user: getCurrentUser(state),
      $$medias: getMediasData(state),
      universe: getUniverse(state),
    };
    return props;
  }
  function mapDispatchToProps(dispatch) {
    return {
      onMount: () => {
        dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
        dispatch(fetchMedias({}));
      },
      setUniverse: (universe) => {
        dispatch(setUniverse(universe));
      }

    };
  }

  // export default ;
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Medias)));
