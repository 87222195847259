import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import i18n from 'helpers/i18n';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// import Badge from '@material-ui/core/Badge';

const styles = (theme) => {
  return {
    button: {
      boxShadow: 'none',
      borderRadius: 'initial',
      fontSize: '0.9375rem',
      color: theme.palette.header.buttonColor,
      backgroundColor: theme.palette.header.buttonBackgroundColor,
      border: theme.palette.header.border,
      fontWeight:
        theme.palette.header && theme.palette.header.buttonFontWeight
          ? theme.palette.header.buttonFontWeight
          : 'inherit',
      position: 'relative',
      marginRight: theme.spacing(3),
      transition: 'color .25s ease-out',
      borderBottom:
        theme.palette.header && theme.palette.header.buttoBorderBottom
          ? theme.palette.header.buttoBorderBottom
          : undefined,
      '&:hover': {
        backgroundColor: 'transparent',
        color:
          theme.palette.header && theme.palette.header.buttoHoverColor
            ? theme.palette.header.buttoHoverColor
            : undefined,

        '&:before': {
          borderBottom:
            theme.palette.header && theme.palette.header.buttonHoverBorderBottom
              ? theme.palette.header.buttonHoverBorderBottom
              : undefined,
        },
      },
      '&:before': {
        content: "' '",
        borderBottom: 'transparent',
        textAlign: 'center',
        position: 'absolute',
        fontSize: '1rem',
        height: '100%',
        bottom: -20,
        width: '100%',
        color: theme.palette.header.buttonSelectedColor,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '.75rem',
        marginRight: theme.spacing(0),
      },
    },
    selected: {
      color: theme.palette.header.buttonSelectedColor,
      backgroundColor:
        theme.palette.header && theme.palette.header.buttonSelectedBackgroundColor
          ? theme.palette.header.buttonSelectedBackgroundColor
          : 'inherit',
      fontWeight:
        theme.palette.header && theme.palette.header.buttonSelectedFontWeight
          ? theme.palette.header.buttonSelectedFontWeight
          : 'inherit',
      '&:before': {
        content:
          theme.palette.header && theme.palette.header.buttonSelectedBeforeContent
            ? theme.palette.header.buttonSelectedBeforeContent
            : "'•'",
        borderBottom:
          theme.palette.header && theme.palette.header.buttonSelectedBorderBottom
            ? theme.palette.header.buttonSelectedBorderBottom
            : undefined,
        textAlign: 'center',
        position: 'absolute',
        fontSize: '1rem',
        height: '100%',
        bottom: 0,
        width: 'calc(100% - ' + theme.spacing(1) * 2 + 'px)',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        color: theme.palette.header.buttonSelectedColor,
      },
    },
  };
};

class HeaderMenu extends Component {
  contructor(props) {
    this.super(props);
  }

  render() {
    const { current, $$menu, classes, universe } = this.props;
    return (
      <React.Fragment>
        {$$menu
          .map((menu, i) => {
            if (menu.get('target') === '_blank')
              return (
                <Button
                  className={clsx(classes.button, { [classes.selected]: current === menu.get('icon') })}
                  key={'lnk_' + i}
                  aria-label={menu.get('title')}
                  onClick={() => {
                    window.open(
                      menu.get('url').replace('/{universe}', universe !== undefined ? '/' + universe : ''),
                    );
                  }}
                  disableRipple
                >
                  {i18n.get(menu.get('title'), { pretty: false })}
                </Button>
              );
            else
              return (
                <Button
                  className={clsx(classes.button, { [classes.selected]: current === menu.get('icon') })}
                  key={'lnk_' + i}
                  aria-label={menu.get('title')}
                  component={Link}
                  to={menu.get('url').replace('/{universe}', universe !== undefined ? '/' + universe : '')}
                >
                  {i18n.get(menu.get('title'), { pretty: false })}
                </Button>
              );
          })
          .toArray()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(HeaderMenu);
