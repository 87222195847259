import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';
import { getUniverse } from 'components/UI/Account/ducks/authentication'

const actionTypes = mirrorCreator(['SET_REPLAY_DATA', 'SET_REPLAY_ERROR', 'CLEAR_REPLAY', 'SET_REPLAYS_DATA', 'CLEAR_REPLAYS'], { prefix: 'replay/' });

const mutators = {
  setReplayData: (replay) => ($$state) => $$state.set('replay', replay),
  setReplaysData: (data) => ($$state) => $$state.set('replays', data),
  setReplayError: (error) => ($$state) => $$state.set('error', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_REPLAY_DATA:
      acts.push(mutators.setReplayData(action.replay));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_REPLAYS_DATA:
      acts.push(mutators.setReplaysData(action.data));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_REPLAY_ERROR:
      return pipe([mutators.setReplayError(action.error)], $$state);

    case actionTypes.CLEAR_REPLAY:
      return pipe([mutators.setReplayError(undefined), mutators.setReplayData(undefined)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.replay || Immutable.Map();
export const getReplayData = createSelector([getRoot], ($$state) => $$state.get('replay'));
export const getReplaysData = createSelector([getRoot], ($$state) => $$state.get('replays'));
export const getReplayError = createSelector([getRoot], ($$state) => $$state.get('error'));

export function fetchReplay({ replayId, params }) {
  return (dispatch, getState) => {
    let url = config.events + '/replay/' + replayId;

    if (params.k !== undefined && params.r === undefined) url = url+'?k='+params.k;
    if (params.r !== undefined && params.k === undefined) url = url+'?r='+params.r;
    if (params.r !== undefined && params.k !== undefined) url = url+'?k='+params.k+'&r='+params.r;
    let formData = {};
    formData.contact_form = true;

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.replay && response.replay === 'complete') {
          dispatch(setReplayData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setReplayError(response.log));
        }
      })
      .catch((error) => dispatch(setReplayError(error.message)));
  };
}
export function fetchReplays() {
  return (dispatch, getState) => {
    let url = config.events + '/get/replays';
    const universe = getUniverse(getState());
    if (universe !== undefined) url += '?universe='+universe

    dispatch(clearReplays());

    let formData = {};
    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.events && response.events === 'complete') {
          dispatch(setReplaysData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setReplayError(response.log));
        }
      })
      .catch((error) => {
        dispatch(setReplayError(error.message))
      });
  };
}

export function setReplayData(replay) {
  return {
    type: actionTypes.SET_REPLAY_DATA,
    replay,
  };
}
export function setReplaysData(data) {
  return {
    type: actionTypes.SET_REPLAYS_DATA,
    data,
  };
}
export function setReplayError(error) {
  return {
    type: actionTypes.SET_REPLAY_ERROR,
    error,
  };
}
export function clearReplay() {
  return {
    type: actionTypes.CLEAR_REPLAY,
  };
}
export function clearReplays() {
  return {
    type: actionTypes.CLEAR_REPLAYS,
  };
}
