export default function getRemainingTime(countDownDate) {
  if (!countDownDate) return;

  var now = new Date().getTime();
  var distance = countDownDate * 1000 - now;

  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  // var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  const tStr =
    days +
    ' Jour' +
    (days > 1 ? 's' : '') +
    ' ' +
    hours +
    ' Heure' +
    (hours > 1 ? 's' : '') +
    ' ' +
    minutes +
    ' Minute' +
    (minutes > 1 ? 's' : '') +
    '';
  return {
    tStr,
    days,
    hours,
    minutes,
  };
}
