import React from 'react';
import { connect } from 'react-redux';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { getFilters, setFilterValue } from './ducks/offer';

class OfferSelectFilter extends React.Component {
  render() {
    const { name, label, width, placeholder, options, $$filters, onChange } = this.props;
    return (
      <FormControl style={{ width }}>
        <InputLabel shrink>{label}</InputLabel>
        <Select value={$$filters.get(name, '')} onChange={onChange} displayEmpty>
          <MenuItem value={''}>{placeholder}</MenuItem>
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

const mapStateToProps = (state) => ({
  $$filters: getFilters(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (event) => dispatch(setFilterValue(ownProps.name, event.target.value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferSelectFilter);
