import React from "react";
import {
  connect
} from "react-redux";
import Immutable from "immutable";
import Form from "components/UI/Form/Form";
// import BlockTitle from "components/UI/BlockTitle";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import {
  getResetPassword,
  resetPassword as formSubmitAction,
  hasLoginFailed as isFormErrored,
  getError as getFormError,
  isLoggingIn as isFormRunning,
  isLoggedIn as isFormComplete,
  setError,
} from "components/UI/Account/ducks/authentication";
const FIELDS = [{
    id: "email",
    name: "email",
    label: "Email",
    validation: ["notEmpty", "email"],
    maxLength: 64,
    sizes: {
      xs: 12
    },
  },
  {
    id: "password",
    name: "password",
    label: "Mot de passe",
    type: "password",
    validation: ["notEmpty"],
    helperText: 'Doit contenir au moins 8 caractères, une majuscule et un chiffre',
    maxLength: 64,
    sizes: {
      xs: 12
    },
  },
  {
    id: "passwordConf",
    name: "passwordConf",
    type: "password",
    label: "Mot de passe (encore)",
    validation: ["notEmpty"],
    helperText: 'Doit contenir au moins 8 caractères, une majuscule et un chiffre',
    maxLength: 64,
    sizes: {
      xs: 12
    },
  },
];
const $$FIELDS = Immutable.fromJS(FIELDS);
class ResetPasswordChallengeForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      erroredFields: {},
    };
    // this._onFormSubmit = this._onFormSubmit.bind(this);
    // this._onFieldValueChange = this._onFieldValueChange.bind(this);
    this._getI18n();
    this.$$FIELDS = $$FIELDS;
  }
  componentDidMount() {
    this._setupState();
    this._mapRawValues(this.props.$$formValues);
  }
  render() {
    const {
      resetPassword
    } = this.props;
    return (
      <div>
        <Typography variant="h3">Changement de mot de passe</Typography>
        <Choose>
          <When
            condition={
              resetPassword &&
              resetPassword.get("step") === "challenge" &&
              resetPassword.get("result") === true
            }
          >
            <div className={"Login Form"}>
              <Paper style={{ padding: "20px 30px" }}>
                <Paragrapher text={resetPassword.get("log")} />
              </Paper>
            </div>
          </When>
          <Otherwise>
            <div className="Login Form">
              <div
                className="form"
                style={{
                  maxWidth: 800,
                  padding: "20px 20px 10px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Typography variant="h6" gutterBottom={true}>
                  Saisissez votre email ainsi qu'un nouveau mot de passe
                </Typography>
                {this._renderForm()}
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
const mapStateToProps = ($$state) => {
  return {
    resetPassword: getResetPassword($$state),
    loading: isFormRunning($$state),
    isFormErrored: isFormErrored($$state),
    error: getFormError($$state),
    isFormComplete: isFormComplete($$state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      const login = values.email;
      const key = values.key;
      const password = values.password;
      const passwordConf = values.passwordConf;
      if(password !== passwordConf)
        dispatch(setError("Les mots de passe de sont pas identiques !"));
      else
        dispatch(
          formSubmitAction("challenge", login, key, password, passwordConf)
        );
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordChallengeForm);
