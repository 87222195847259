import React from "react";
import {
  withStyles
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
// import CircularProgress from 'material-ui/CircularProgress';
const style = () => {
  return {
    root: {
      marginTop: 200,
      margin: "200px auto 0",
      width: 100,
      textAlign: "center",
    },
  };
};
class Spinner extends React.Component {
  render() {
    const {
      style,
      children,
      classes
    } = this.props;
    return (<div className={classes.root} style={{ ...style }}>
        <div className={classes.wrapper}>
          <CircularProgress />
          <div className={classes.text}>{children}</div>
        </div>
      </div>);
  }
}
export default withStyles(style)(Spinner);
