import React from "react";
import TextField from "@material-ui/core/TextField";
// import MUIInput from "@material-ui/core/Input";
// import Immutable from "immutable";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// import dateFormat from "dateformat";
import Grid from "@material-ui/core/Grid";
// import { v4 as uuidv4 } from 'uuid';
import remove from 'mout/array/remove'
// import every from 'mout/object/every'
import shortId from 'shortid'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import debounce from 'mout/function/debounce';
import {
  withStyles
} from "@material-ui/core/styles";
import MUICheckbox from "@material-ui/core/Checkbox";

const styles = (theme) => {
  // console.log('input theme', theme)
  return {
    root: {
      '& .MuiCheckbox-root': {
        color: theme.palette.primary.main
      }
    },
    input: inputIron(theme),
    answerContainer: {
      marginBottom: 10
    },
    checkbox: {
      verticalAlign: 'middle'
    }
  }
}

const inputIron = function (theme) {
  return {
    backgroundColor: theme.palette.background.default,
    '& input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 30px ' + theme.palette.background.default+' inset !important',
      '-webkit-text-fill-color': theme.palette.text.primary + ' !important'
    },
    '& input:-webkit-autofill:hover': {
      '-webkit-box-shadow': '0 0 0 30px ' + theme.palette.background.default+' inset !important'
    },
    '& input:-webkit-autofill:focus': {
      '-webkit-box-shadow': '0 0 0 30px ' + theme.palette.background.default+' inset !important'
    },
    '& input:-webkit-autofill:active': {
      '-webkit-box-shadow': '0 0 0 30px ' + theme.palette.background.default+' inset !important'
    },
  }
};

class QuizAnswer extends React.Component {
  constructor(props) {
    super(props);
    const rid = shortId.generate();
    this.state = {
      quizType: 'quiz',
      ['v_'+rid]: {rid:rid, field: '', correct: 'false' },
      c: [rid]
    };
    // this._onChange = this._onChange.bind(this);
    // this._onChange = debounce(this._onChange.bind(this), 500);
  }
  componentDidMount() {
    // if (this.props.value !== undefined)
    // console.log('got on mount values', this.props.value)
    // this.setState({
    // $values: Immutable.fromJS(this.props.value),
    // });
  }
  componentDidUpdate(oldProps) {
    if ((oldProps.externalValues === undefined && this.props.externalValues !== undefined) || (oldProps.externalValues !== undefined && this.props.externalValues !== undefined && oldProps.externalValues.quizType !== this.props.externalValues.quizType) ) {
      // console.log('SET SET SET !!!!', oldProps.externalValues.quizType, this.props.externalValues.quizType)
      this.setState({quizType: this.props.externalValues.quizType})
    }


    if(oldProps.value === undefined && this.props.value !== undefined) {
      // console.log('initializing values')
      let tmpValues = undefined;
      try {
        tmpValues = JSON.parse(this.props.value);
      } 
      catch(e) {}
      let newState = {};
      let newC = [];
      if (tmpValues !== undefined && tmpValues.length > 0) {
        tmpValues.forEach((val) => {
          newState['v_'+val.rid] = val;
          newC.push(val.rid);
        })
        newState.c = newC;
        this.setState(newState, () => {
          const {onChange, id} = this.props
          if(onChange) onChange("input", {
            id: id,
            value: JSON.stringify(this._getValues()),
          });
        })
      }
      // console.log('initialized state', this.state)
    }
  }
  _onChange(e, rid, key) { // do not convert to anon
    e.persist();
    const {
      onChange
    } = this.props;

    // const currentValue = this.state.value;
    // const newValue = {...currentValue}
    // const { $$values } = this.state;
    // const {c} = this.state;
    const currentValue = this.state['v_'+rid] !== undefined ? this.state['v_'+rid] : {rid: rid, field: '', correct: false}
    let newValue = {...currentValue};
    const id = e.target.id;
    const value = e.target.value;
    // console.log(id, value)
    // debounce(function() {
    // console.log('adding', id, value)

    if (key === 'field'){
      newValue.field = value;
      this.setState({
        ['v_'+rid]: newValue
      }, function() {
        if(onChange) onChange("input", {
          id: id,
          value: JSON.stringify(this._getValues()),
        });
      });
    } else if (key === 'correct') {
      newValue.correct = value !== 'true' ? 'true' : 'false';
      this.setState({
        ['v_'+rid]: newValue
      }, function() {
        if(onChange) onChange("input", {
          id: id,
          value: JSON.stringify(this._getValues()),
        });
      });
    } else {
      console.log('invalid key!')
    }
  }
  _getValues = () => {
    const { c } = this.state;
    let newValues = []
    c.map(i => {
      newValues.push(this.state['v_'+i]);
      return true;
    })
    return newValues;
  }
  _addItem = (e) => {
    e.preventDefault();
    let { c } = this.state;
    const rid = shortId.generate()
    c.push(rid)
    this.setState({
      c: c,
      ['v_'+rid]: {field: '', correct: 'false', rid: rid}
    })
  }
  _deleteItem = (e, rid) => {
    e.preventDefault();
    const {id, onChange} = this.props;
    const { c } = this.state;
    let newC = [...c]
    // console.log('not done yet')
    // this.state['m_'+rid] = undefined;
    remove(newC, rid);
    this.setState({c: newC, ['m_'+rid]: undefined}, () => {
      if(onChange) onChange("input", {
        id: id,
        value: JSON.stringify(this._getValues()),
      });
    })
  }
  render() {
    const {
      id,
      // name,
      label,
      type,
      align,
      variant,
      color,
      classes,
      margin,
      maxLength,
      // multiline,
      fullWidth,
      helperText,
      required,
      disabled,
      // rows,
      error,
      errorMessage,
      style,
      // $$externalValues,
    } = this.props;
    const {
      quizType,
      c
    } = this.state;
    const multiline = true;
    const rows = 1;
    let inputProps = {
      id,
      name: id,
      // name,
      // id: name,
      label,
      classes: {root: classes.input},
      // value: fieldValue !== undefined ? fieldValue : "",
      multiline,
      helperText,
      align,
      required,
      color,
      disabled,
      placeholder: "",
      fullWidth: fullWidth !== undefined ? fullWidth : true,
      variant: variant !== undefined ? variant : undefined,
      margin: margin !== undefined ? margin : undefined,
      type: type ? type : "text",
      // onChange: (e) => {this._onChange(e, 'field')},
      rows,
      maxRows: rows + 5,
      error,
      style,
    };
    if(maxLength !== undefined) inputProps.InputProps = {
      maxLength
    };
    inputProps.InputProps = {
      maxLength,
    };
    // let j, elements = [];
    // for ( j = 0; j < c; j++) {
    //   elements.push(j)
    // };
    // if ($$externalValues !== undefined)
    // console.log('$$externalValues', $$externalValues.toJS())
    if(errorMessage !== undefined && errorMessage !== false) inputProps.helperText = errorMessage;
    // console.log(this.state)
    return (
      <Grid item xs={12} container alignItems="center" className={classes.root}>
      <If condition={c !== undefined && c.length > 0}>
      {c.map((rid) => {
        return (<Grid key={'v_'+rid} item xs={12} container alignItems="center" className={classes.answerContainer}>
        <Grid item xs={quizType === 'quiz' ? 10 : 11}>
        <TextField {...inputProps} onChange={(e) => {this._onChange(e, rid, 'field')}} value={this.state['v_'+rid].field} />
        </Grid>
        <Grid item xs={quizType === 'quiz' ? 2 : 1}>
        <If condition={quizType === 'quiz'}>
        <FormControl
        component="fieldset"
        fullWidth={fullWidth}
        disabled={disabled}
        error={error}
        required={required}
        className={classes.checkbox}
        >
        <MUICheckbox
        id={id}
        name={id}
        checked={this.state['v_'+rid].correct === 'true' ? true : false}
        onChange={(e) => {this._onChange(e, rid, 'correct')}}
        value={this.state['v_'+rid].correct}
        />
        </FormControl>
        </If>
        <IconButton onClick={(e) => {this._deleteItem(e, rid)}} color="secondary"><DeleteForeverIcon /></IconButton>
        </Grid>
        </Grid>)
      })}
      </If>
      <Grid item xs={12} align="left">
      <Button onClick={this._addItem} variant="outlined" color="secondary">Ajouter une réponse</Button>
      </Grid>
      </Grid>
    )
  }
  }
  export default withStyles(styles)(QuizAnswer);
