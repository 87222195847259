import React from "react";
// import Immutable from 'immutable'
import Select from "components/UI/Form/Select";
import SelectBlocks from "components/UI/Form/SelectBlocks";
import Input from "components/UI/Form/Input";
import AutoComplete from "components/UI/Form/AutoComplete";
import Checkbox from "components/UI/Form/Checkbox";
import Upload from "components/UI/Form/Upload";
import PropTypes from "prop-types";
import withModuleConfig from "helpers/withModuleConfig";
import areIntlLocalesSupported from "intl-locales-supported";
import QuizAnswer from 'components/UI/Quiz/QuizAnswer'
let DateTimeFormat = undefined;
if(areIntlLocalesSupported(["fr"])) {
  DateTimeFormat = global.Intl.DateTimeFormat;
} else {
  // const IntlPolyfill = require('react-intl');
  // DateTimeFormat = IntlPolyfill.DateTimeFormat;
  // require('react-intl/locale-data/fr');
}
class DynamicField extends React.Component {
  static contextTypes = {
    muiTheme: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this._onFieldValueChange = this._onFieldValueChange.bind(this);
  }
  componentDidUpdate(oldProps) {
    if (oldProps.value === undefined && this.props.value !== undefined) {
      const {row} = this.props;
      if ((row.get("type") === "select" || row.get("type") === "SelectField") && row.get('categoryTrigger') && this.props.onCategoryTrigger)
        this.props.onCategoryTrigger(row.get('id'), this.props.value, row.get("categoryTrigger"));
    }
  }
  _onFieldValueChange(kind, target) {
    const {
      row,
      onCategoryTrigger
    } = this.props;
    if(row.get("type") === "select" || row.get("type") === "SelectField") {
      if(onCategoryTrigger)
        onCategoryTrigger(target.id, target.value, row.get("categoryTrigger"));
    }
    if(!target.id) console.log("missing id", target.value);
    // console.log(kind, target)
    this.props.onChange(kind, target);
  }
  _getValidators(validators) {}
  getLabel(label, required) {
    return label + (required ? " *" : "");
  }
  render() {
    const {
      // idx,
      row,
      // module,
      // $$linkMap,
      // moduleConfig,
      disabled,
      readOnly,
      erroredFields,
      variant,
      externalValues,
      // fieldVisibility,
      // onChange,
      value,
    } = this.props;
    // const disabled = true;
    // if (row.get('multiple') === true)
    // console.log(row.get('id'), value)
    let minDate;
    if(row.get("type") === "DatePicker") {
      minDate = new Date();
      if(row.get("minDate") !== undefined) {
        const md = row.get("minDate").toJS();
        minDate.setFullYear(md[0], md[1], md[2]);
      }
      minDate.setHours(0, 0, 0, 0);
      // console.log('mindate', minDate.getFullYear(), minDate.getMonth(), minDate.getDay());
    }
    let required = false;
    if(row.get("validators") !== undefined) {
      // console.log(row.get('validators').toJS())
      required =
        row.get("validators").keySeq().toArray().indexOf("notEmpty") >= 0;
      // console.log('R IS', r);
    }
    // console.log(erroredFields)
    return (
      <Choose>
        <When condition={row.get("type") === "QuizAnswer"}>

          <QuizAnswer
            id={row.get("id")}
            value={value !== null ? value : ""}
            type={row.get("typeVariant")}
            onChange={this._onFieldValueChange}
            label={row.get("label")}
            autoComplete={row.get("autoComplete")}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            maxLength={row.get("maxLength")}
            multiline={row.get("multiline")}
            helperText={row.get("helperText")}
            disabled={row.get("static") || disabled}
            rows={row.get("rows")}
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
            dateTimeFormat={DateTimeFormat}
            required={required}
            externalValues={externalValues}
          />
        </When>
        <When condition={row.get("type") === "Checkbox"}>
          <Checkbox
            name={row.get("id")}
            id={row.get("id")}
            value={value}
            label={row.get("label")}
            onChange={this._onFieldValueChange}
            options={row.get("options")}
            disabled={
              (row.get("static") !== undefined
                ? row.get("static")
                : (disabled
                ? true
                : undefined))
            }
            multiple={row.get("multiple")}
            required={required}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            variant={
              row.get("variant") !== undefined ? row.get("variant") : undefined
            }
          />
        </When>

        <When condition={row.get("type") === "upload"}>
          <Upload
            name={row.get("id")}
            id={row.get("id")}
            value={value}
            onChange={this._onFieldValueChange}
            label={row.get("label")}
            previewUrl={row.get("previewUrl")}
            previewTmpUrl={row.get("previewTmpUrl")}
            maxFiles={row.get("maxFiles")}
            multiple={row.get("multiple")}
            acceptedMime={row.get("mime")}
            url={row.get("url")}
            tmpUrl={row.get("tmpUrl")}
            uploadAttributes={row.get("uploadAttributes")}
            required={required}
            disabled={disabled}
            readOnly={
              disabled || readOnly || row.get("readOnly") ? true : false
            }
            variant={row.get("variant")}
          />
        </When>
        <When
          condition={
            row.get("type") === "select" || row.get("type") === "SelectField"
          }
        >
          <Select
            id={row.get("id")}
            value={value}
            label={row.get("label")}
            defValue={row.get("defValue")}
            onChange={this._onFieldValueChange}
            options={row.get("options")}
            disabled={
              row.get("static") !== undefined
                ? row.get("static")
                : disabled
                ? true
                : undefined
            }
            multiple={row.get("multiple")}
            required={required}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
          />
        </When>
        <When
          condition={
            row.get("type") === "SelectBlocks"
          }
        >
          <SelectBlocks
            id={row.get("id")}
            value={value}
            label={row.get("label")}
            defValue={row.get("defValue")}
            onChange={this._onFieldValueChange}
            options={row.get("options")}
            disabled={
              row.get("static") !== undefined
                ? row.get("static")
                : disabled
                ? true
                : undefined
            }
            multiple={true}
            required={required}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            showLabel={false}
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
          />
        </When>
        <When condition={row.get("type") === "AutoComplete"}>
          <AutoComplete
            id={row.get("id")}
            value={value}
            type={row.get("typeVariant")}
            onChange={this._onFieldValueChange}
            label={row.get("label")}
            autoComplete={row.get("autoComplete")}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            multiple={row.get("multiple")}
            disabled={row.get("static") || disabled}
            url={row.get("url")}
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
            dateTimeFormat={DateTimeFormat}
            required={required}
          />
        </When>
        <Otherwise>
          <Input
            id={row.get("id")}
            value={value !== null ? value : ""}
            type={row.get("typeVariant")}
            onChange={this._onFieldValueChange}
            label={row.get("label")}
            autoComplete={row.get("autoComplete")}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            maxLength={row.get("maxLength")}
            multiline={row.get("multiline")}
            helperText={row.get("helperText")}
            disabled={row.get("static") || disabled}
            rows={row.get("rows")}
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
            dateTimeFormat={DateTimeFormat}
            required={required}
          />
        </Otherwise>
      </Choose>
    );
  }
}
export default withModuleConfig(DynamicField);
