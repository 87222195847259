import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
// import { Link } from 'react-router-dom';
import Form from 'components/UI/Form/Form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import i18n from 'helpers/i18n';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import {
  login as formSubmitAction,
  hasLoginFailed as isFormErrored,
  getError as getFormError,
  getCurrentUserRole,
  isLoggingIn as isFormRunning,
  isLoggedIn as isFormComplete,
} from 'components/UI/Account/ducks/authentication';
import { clearData } from 'ducks/asyncFetcher';

const styles = (theme) => {
  return {
    formFooter: {
      textAlign: 'center',
      color: theme.palette.secondary.main
    },
    formNotice: {
      textAlign: 'center',
      color: theme.palette.secondary.main
    },
    formActions: {
      display: 'block', textAlign: 'left'
    }
  }
}

const FIELDS = [
  {
    id: 'email',
    name: 'email',
    label: 'loginFieldEmail',
    translate: true,
    validation: ['notEmpty', 'email'],
    maxLength: 64,
    sizes: { xs: 12 },
    autoComplete: 'email',
  },
  {
    id: 'password',
    name: 'password',
    label: 'loginFieldPassword',
    translate: true,
    type: 'password',
    validation: ['notEmpty'],
    maxLength: 64,
    sizes: { xs: 12 },
    autoComplete: 'current-password',
  },
];
const $$FIELDS = Immutable.fromJS(FIELDS);

const CONFIG = {
  showRequiredLegend: false,
  buttonColorSubmit: 'primary',
};
const $$CONFIG = Immutable.fromJS(CONFIG);

class LoginForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      erroredFields: {},
    };

    // this._onFormSubmit = this._onFormSubmit.bind(this);
    // this._onFieldValueChange = this._onFieldValueChange.bind(this);
    // this._onPasswordResetRequest = this._onPasswordResetRequest.bind(this);

    this._getI18n();
    this.$$FIELDS = $$FIELDS;
    this.$$CONFIG = $$CONFIG;
  }

  componentDidMount() {
    this._setupState(undefined, () => {
      if (this.props.isFormComplete && this.props.onFormComplete()) {
        // console.log('LoginForm componentDidMount onFormComplete')
        this.props.onFormComplete();
      }
    });
  }

  componentDidUpdate(oldProps) {
    if (
      (oldProps.isFormComplete === undefined || oldProps.isFormComplete === false) &&
      this.props.isFormComplete === true
    ) {
      if (this.props.onFormComplete) {
        // console.log('LoginForm componentDidUpdate onFormComplete')
        this.props.onFormComplete();
      }
    }
  }
  _getFormNotice = () => {
    return <div style={{height: 4}}></div>
  }
  _getFormFooter = () => {
    const {classes} = this.props;
    return (
      <Box w={'100%'} py={0} align="right" className={classes.formNotice}>
        <Button onClick={this._onPasswordResetRequest} color="secondary">
        {i18n.get('loginForgotPassword')}
      </Button>
      </Box>
    );
  }
  _onPasswordResetRequest = () => {
    const { onPasswordResetRequest } = this.props;
    // history.push('/rpwd')
    if (onPasswordResetRequest) onPasswordResetRequest();
  }
  _getI18n = () => {
    this.i18n = {
      send: i18n.get('loginFormSubmit', {pretty: false}),
      sending: i18n.get('loginFormSubmit', {pretty: false}),
      requiredFieldsLegend: i18n.get('formFieldsRequiredLegend', {pretty: false}),
      buttonClassSuffix:"login",
    };
  }

  render() {
    const {
      // loading,
      isFormComplete,
    } = this.props;
    if (isFormComplete && !this.props.onFormComplete) {
      return <Typography variant="h5">...</Typography>;
    } else if (isFormComplete && this.props.onFormComplete) {
      return <span></span>;
    }

    return <div style={{ maxWidth: 420, margin: '0 auto 20px' }}>{this._renderForm()}<Box w={'100%'} mt={4} align="center">{i18n.get('formFieldsRequiredLegend',{pretty: false})}</Box></div>;
  }
}

const mapStateToProps = ($$state) => {
  return {
    userRole: getCurrentUserRole($$state),
    loading: isFormRunning($$state),
    isFormErrored: isFormErrored($$state),
    error: getFormError($$state),
    isFormComplete: isFormComplete($$state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (formData) => {
      dispatch(formSubmitAction(formData));
    },
    doClearData: (formData) => {
      dispatch(clearData({ key: 'home' }));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginForm)));
