import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
// import Immutable from 'immutable';
// import { connect } from 'react-redux';
// import config from 'config/api'
// import { withRouter } from 'react-router-dom'
// import withWidth from '@material-ui/core/withWidth';
// import Typography from '@material-ui/core/Typography';
import Avatar from "@material-ui/core/Avatar";
// import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";

// import Spinner from 'components/UI/Spinner';
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
// import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
// import MyEvents from 'components/Event/MyEvents';
// import AvailableEvents from 'components/Event/AvailableEvents';
// import Header from 'components/Header/Header';
// import List from '@material-ui/core/List';
// import Circle from 'components/Circle';
// import BlockTitle from 'components/UI/BlockTitle';
// import BlockButton from 'components/UI/BlockButton';

// import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

// import {
//   fetchData,
//   getDataHome,
//   getLoadingError,
// } from 'ducks/asyncFetcher';

class ListRow extends Component {
  render() {
    const { children } = this.props;
    return <div {...this.props}>{children}</div>;
  }
}

// const AF_KEY = 'home';

const styles = (theme) => {
  return {
    root: {
      background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      borderRadius: 3,
      border: 0,
      color: "white",
      height: 48,
      padding: "0 30px",
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    },
    bullet: {
      backgroundColor: "transparent",
      color: "#EB9250",
      // fontSize: '2.6em',
    },
    list: {
      maxWidth: "33.33%",
      margin: "0 auto 20px",
      display: "block",
    },
    listItem: {
      width: "100%",
    },
    listItemText: {
      fontSize: "1.4em",
    },
    listItemAnswerText: {
      marginLeft: 50,
      // color: theme.palette.common.black
    },
  };
};

class HelpFaqItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerVisible: null,
    };
    this._onClick = this._onClick.bind(this);
  }
  _onClick(e) {
    const { onClick, answerVisible, forceOpen } = this.props;
    e.stopPropagation();
    if (forceOpen === true) return;
    const visibility = answerVisible === true ? null : this.props.hIdx;
    onClick(visibility);
  }
  _onEdit(e, item) {
    const { onEdit } = this.props;
    e.stopPropagation();
    onEdit(item);
  }
  _onDelete(e, item) {
    const { onDelete } = this.props;
    e.stopPropagation();
    onDelete(item);
  }
  render() {
    const {
      hIdx,
      classes,
      dense,
      $$help,
      answerVisible,
      permDelete,
      permEdit,
    } = this.props;

    // const {
    //   answerVisible
    // } = this.state;
    let bulletSize = 2.6;
    if (hIdx !== undefined)
      bulletSize =
        bulletSize / ($$help.get("faqPosition") | 0).toString().length + "em";
    // console.log('BSIZE', $$help.get('faqPosition'), hIdx, bulletSize)

    return (
      <ListItem dense={dense} classes={{ root: classes.list }}>
        <ListItem
          dense={dense}
          button
          component={ListRow}
          classes={{ root: classes.listItem }}
          onClick={this._onClick}
        >
          <ListItemAvatar>
            <Avatar
              className="bullet"
              classes={{ root: classes.bullet }}
              style={{ fontSize: bulletSize }}
            >
              {$$help.get("faqPosition") | 0}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary={$$help.get("faqQuestion")}
            secondary={$$help.get("faqSubQuestion")}
          />
          <If condition={permEdit}>
            <div>
              <IconButton
                aria-label="Delete"
                onClick={(e) => this._onEdit(e, $$help.get("id"))}
              >
                <EditIcon />
              </IconButton>
            </div>
          </If>
          <If condition={permDelete}>
            <div>
              <IconButton
                aria-label="Delete"
                onClick={(e) => this._onDelete(e, $$help.get("id"))}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </If>
        </ListItem>
        <If condition={answerVisible === true}>
          <ListItem dense={dense} component={ListRow}>
            <ListItemText
              classes={{
                root: classes.listItemText + " " + classes.listItemAnswerText,
              }}
              primary={
                <Paragrapher prettyfy={true} text={$$help.get("faqAnswer")} />
              }
            />
          </ListItem>
        </If>
      </ListItem>
    );
  }
}

export default withStyles(styles)(HelpFaqItem);
