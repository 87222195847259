import React from 'react';
import AccountList from './AdminAccountList';
// import { Route } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';

const AccountListEmbed = ({ $$panel, $$row }) => {
  const filter = $$row !== undefined && $$row.get('id') ? $$panel.get('filter')+'='+$$row.get('id') : null;
  return (
    <div>
      <Typography variant={'h3'} style={{marginBottom: 0}}>Invités :</Typography>
      <AccountList embed={true} filter={filter} showFilters={false}></AccountList>
    </div>
  );
}

export default AccountListEmbed;
