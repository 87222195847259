import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import i18n from 'helpers/i18n';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircle from '@material-ui/icons/CheckCircle';

const styles = (theme) => {
  return {
    root: {
      width: '100%',
      padding: '0 20px 10px 0',
    },
    subscriptionBox: {
      padding: theme.spacing(3),
      borderRadius: '5px',
      maxWidth: '640px',
    },
    subscriptionSuccessBox: {
      color: '#31a82d',
      backgroundColor: '#e6ffe3',
      border: '#a3dfa1 1px solid',
    },
    subscriptionPendingBox: {
      color: '#d73912',
      backgroundColor: '#ffe8d8',
      border: '#ffbb8c 1px solid',
    },
    subscriptionMessage:{
      paddingLeft: '1em',
      textAlign:'left !important',
    },
    };
};
class RegistrationMessage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      registrationResult,
      isMember,
    } = this.props;
    
    const isVerified = registrationResult!==undefined && registrationResult.accountStatus === 'ok';
    const isStudent  = registrationResult!==undefined && registrationResult.showStudentMessage
      && (registrationResult.accountJob=="st" || registrationResult.accountProfessionalCat==="E");
    const isError=registrationResult!==undefined && registrationResult.registrationError!==undefined;
    const errorMessage=isError && registrationResult.registrationError!='' ? i18n.get(registrationResult.registrationError) : "Erreur inconnue";

    return (
      <div className={classes.root}>
        <Grid item xs={12}>
          <Box align="center" my={4}>
          <If condition={isError}>
            <table className={clsx(classes.subscriptionBox,classes.subscriptionPendingBox)}>
                  <tbody>
                  <tr>
                    <td>
                      <WarningIcon/>
                    </td>
                    <td>
                        <Typography className={"REGISTRATION_MESSAGE_STUDENT "+classes.subscriptionMessage} gutterBottom><b>Erreur</b><br/>
                        {errorMessage}
                          </Typography>
                    </td>
                  </tr>
                  </tbody>
                </table>
          <Else/>
            <If condition={isVerified || isMember}>
              <table className={clsx(classes.subscriptionBox,classes.subscriptionSuccessBox)}>
                <tbody>
                  <tr>
                    <td>
                      <CheckCircle/>
                    </td>
                    <td>
                      <Typography className={"REGISTRATION_MESSAGE_SUCCESS "+classes.subscriptionMessage} component="div" align="left" gutterBottom>
                        {i18n.get('subscriptionVerifiedSuccessTitle', {pretty: true, element: 'div'})}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            <Else/>
              <table className={clsx(classes.subscriptionBox,classes.subscriptionPendingBox)}>
                <tbody>
                <tr>
                  <td>
                    <WarningIcon/>
                  </td>
                  <td>
                    <If condition={isStudent}>
                      <Typography className={"REGISTRATION_MESSAGE_STUDENT "+classes.subscriptionMessage} gutterBottom><b>Votre inscription a bien été prise en compte.</b><br/><br/>
                        <b>Attention&nbsp;:</b> Pour que votre inscription soit valide et définitive, vous devez adresser la photo ou le scan de l'autorisation de votre hiérarchie à assister à la webconférence à l'adresse suivante : <em><b><a href={"mailto:"+i18n.get('studentRegisterMessageEmail', {pretty: false})} target="_blank">{i18n.get('studentRegisterMessageEmail', {pretty: false})}</a></b></em> en précisant votre nom, prénom et faculté.<br/> Le modèle de ce document d'autorisation est <b><a href={i18n.get('studentRegisterMessageDownload', {pretty: false})} target="_blank">disponible ici </a></b>.<br/>
                        Vous recevrez un email de confirmation sous 48 heures (vérifiez vos spams).
                        </Typography>
                    <Else/>
                      <Typography className={"REGISTRATION_MESSAGE_PENDING "+classes.subscriptionMessage} component="div" align="left" gutterBottom>
                        {i18n.get('subscriptionPendingSuccessTitle', {pretty: true, element: 'div'})}
                      </Typography>
                    </If>
                  </td>
                </tr>
                </tbody>
              </table>
            </If>
            </If>
          </Box>
        </Grid>
      </div>
        );
      }
    }
    export default withRouter((withStyles(styles)(RegistrationMessage)));
