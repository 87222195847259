import React from "react";
import { connect } from "react-redux";
import config from "config/api";
import getFetchOptions from "helpers/getFetchOptions";
import DynamicList from "components/UI/DynamicList";
// import BlockButton from 'components/UI/BlockButton';
// import EventListEmbed from 'components/Event/EventListEmbed';
import {
  getCurrentUserRole,
  getToken,
} from "components/UI/Account/ducks/authentication";

class AdminMessageList extends React.Component {
  // Analyser la route actuelle, et determiner si on doit afficher le form ou la list
  // Pour l'instant on affiche que le form

  _toggleApprobation = async ($$item, callback) => {
    const url = config.message + "/toggle";
    const token = await getToken();
    const data = { message: $$item.get("id"), csrf: token };
    fetch(url, getFetchOptions("POST", data))
      .then((response) => response.json())
      .then((response) => {
        if (response.moderation && response.moderation === "complete") {
          if (callback !== undefined) callback();
          // this.setState({redirectToList: true, locked: false});
        }
      });
  };

  render() {
    const { parentList } = this.props;
    // console.log({parentList})
    const moduleConfig = {
      module: "message",
      fetchUrl:
        config.message +
        "/list" +
        (parentList !== undefined ? "/" + parentList : ""),
      deleteUrl: config.message + "/delete",
      // title: 'Liste des comptes',
      filter: this.props.filter,
      showFilters: this.props.showFilters,
      editUrl: "staff/message/edit",
      returnTo: parentList,
      actionsCallbacks: {
        onApprove: this._toggleApprobation,
      },
    };
    // const panels = [
    //   {
    //     'module': 'Events',
    //     'label': 'Webconfs',
    //     'component': EventListEmbed
    //   }
    // ];
    // const { embed, userRole } = this.props;
    // console.log('USER ROLE ?', userRole)
    return (
      <div className="">
        {/*        <If condition={embed !== true && userRole === 'admin'}>
        <BlockButton label="Ajouter" to={'/staff/message/edit'}/>
        </If>
        */}
        <DynamicList
          moduleConfig={moduleConfig}
          panels={undefined}
          autoReload={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}

export default connect(mapStateToProps)(AdminMessageList);
