import React from 'react';
import { connect } from 'react-redux';
import { setUniverse } from 'components/UI/Account/ducks/authentication';

class OfferUniverse extends React.Component {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  onMount: () => dispatch(setUniverse('offers')),
});

export default connect(undefined, mapDispatchToProps)(OfferUniverse);
