import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import EventDetails from 'components/UI/Event/EventDetails';
import { getUniverse } from 'components/UI/Account/ducks/authentication';

const EventDetailsStyled = withStyles((theme) => ({
  root: {
    color: '#262326',
  },
  left: {
    backgroundColor: theme.palette.secondary.main,
    '& .MuiTypography-h4': {
      color: theme.palette.text.onColoredBackground,
    },
    '& .MuiTypography-body1': {
      color: theme.palette.text.onColoredBackground,
    },
    '& .MuiTypography-caption': {
      color: theme.palette.text.onColoredBackground,
    },
    '& .MuiAvatar-colorDefault':{
      color:'#333',
    }
  },
  titleIcon: {
    marginBottom: 0,
    backgroundColor: '#fff',
  },
  itemThumb: {
    backgroundColor: 'transparent',
    '&:before': {
      backgroundImage: 'none',
      backgroundPosition: '50%',
    },
  },
}))(EventDetails);

class EventDetailsWrapper extends Component {
  render() {
    const { universe } = this.props;
    let thumbStyle = { backgroundPosition: '50%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' };
    if (universe === 'gyneco')
      thumbStyle = {
        ...thumbStyle,
        ...{
          //backgroundColor: '#F88D5E',
          backgroundImage: 'url(' + require('assets/images/event_rose.png') + ')',
        },
      };
    else if (universe === undefined || universe === 'gastro')
      thumbStyle = {
        ...thumbStyle,
        ...{
          //backgroundColor: '#3EA5FF',
          backgroundImage: 'url(' + require('assets/images/event_blue.png') + ')',
        },
      };

    return <EventDetailsStyled thumbStyle={thumbStyle} {...this.props} />;
  }
}

function mapStateToProps(state) {
  const props = {
    universe: getUniverse(state),
  };
  return props;
}

export default connect(mapStateToProps)(EventDetailsWrapper);
