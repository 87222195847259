import React, { Component } from "react";
// import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
// import getCardImage from 'helpers/getCardImage'
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
import remove from "mout/array/remove";
// import config from "config/api";
import Button from "@material-ui/core/Button";
// import Container from "@material-ui/core/Container";
// import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import BlockTitle from "components/UI/BlockTitle";
// import Rating from "@material-ui/lab/Rating";
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import { withRouter } from "react-router-dom";
import {
  fetchQuizQuestion,
  // fetchQuizResults,
  fetchQuizState,
  getQuizQuestion,
  getQuizState,
  getQuizResults,
  // getQuizError,
  sendQuiz,
  clearQuizData,
  // clearQuizResults,
} from "./ducks/quiz";
import { getLoadingError } from "ducks/asyncFetcher";
import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
} from "components/UI/Account/ducks/authentication";
// qid: 'c6b5279e681b7866afcc94d5fef27a3a',
import i18n from "helpers/i18n";
// import {
//   getContent
// } from "components/UI/Socket/ducks/relay"

// const AF_KEY = "home";
const styles = (theme) => {
  // console.log(theme)
  // theme.palette.contrastThreshold = 0.1
  return {
    root: {
      width: "100%",
      "& .MuiTypography-h1": {
        fontSize: "1.2rem",
        lineHeight: 1.85,
        backgroundColor: "transparent",
        color: "inherit",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: "left",
        boxSizing: "border-box",
      },
      "& .MuiTypography-body1": {
        textAlign: "left",
      },
      "& .MuiTypography-h4": {
        marginBottom: 0,
        fontSize: '1.4rem',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.primary.main
      },
      "& .MuiTypography-subtitle1": {
        fontSize: "0.8rem",
        textAlign: "center",
      },
    },
    question: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& .MuiTypography-subtitle1": {
        fontSize: "1.2rem",
        lineHeight: 1.85,
        color: "inherit",
      },
    },
    answerWrapper: {
      marginBottom: theme.spacing(2),
    },
    answer: {
      border: "1px " + theme.palette.primary.main + " solid",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      boxSizing: "inherit",
      "& .MuiTypography-body1": {
        color: "inherit",
      },
    },
    selected: {
      backgroundColor: theme.palette.quizz && theme.palette.quizz.selectedBackgroundColor ? theme.palette.quizz.selectedBackgroundColor : theme.palette.primary.main,
      color: theme.palette.quizz && theme.palette.quizz.selectedColor ? theme.palette.quizz.selectedColor : theme.palette.secondary.contrastText,
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: theme.palette.secondary.light
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main
        ),
      },
      "& .MuiLinearProgress-colorSecondary": {
        backgroundColor: theme.palette.primary.main,
      },
      "& .MuiLinearProgress-barColorSecondary": {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main
        ),
      },
    },
    correct: {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.getContrastText(theme.palette.success.dark),
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: theme.palette.success.light,
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.success.dark
        ),
      },
      "& .MuiLinearProgress-colorSecondary": {
        backgroundColor: theme.palette.success.light,
      },
      "& .MuiLinearProgress-barColorSecondary": {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.success.dark
        ),
      },
    },
    incorrect: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.getContrastText(theme.palette.error.dark),
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: theme.palette.error.light,
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.error.dark
        ),
      },
      "& .MuiLinearProgress-colorSecondary": {
        backgroundColor: theme.palette.error.light,
      },
      "& .MuiLinearProgress-barColorSecondary": {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.error.dark
        ),
      },
    },
    actions: {
      minHeight: 54,
    },
    log: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    percentage: {
      fontSize: "1.6rem",
      textAlign: "right",
    },
  };
};
class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = this._getInitState();
    // this._onQuizChange = this._onQuizChange.bind(this);
    // this._onQuizHover = this._onQuizHover.bind(this);
    this._onFormSubmit = this._onFormSubmit.bind(this);
  }
  _getInitState = () => {
    return {
      choice: [],
      // isReady: false,
      // answered: undefined,
      lockScreen: false,
      logMessage: false,
      errored: false,
    };
  };
  componentDidMount() {
    // const liveId = this.props.match.params.id;
    // console.log(this.props)
    const { eventId, questionId, revision, pull } = this.props;
    // console.log('MOUNT', questionId, eventId)
    if (questionId !== undefined && eventId !== undefined) {
      // console.log('TEST 1')
      this.props.onMount({
        eventId,
        revision,
        pull,
        questionId: questionId,
      });
    } else {
      // console.log('TEST 2 no eventId or questionId')
    }

    // if(this.props.answered) {
    //   console.log('alrady ?!')
    // }
    // this._startTimer();
  }
  componentDidUpdate(oldProps, oldState) {
    // console.log('quizupdate', oldProps, this.props)
    // if(oldProps.$$quiz === undefined && this.props.$$quiz !== undefined && this.props.$$quiz !== false) {
    //   let newState = {};
    //   newState.choice = [];
    //   newState.isReady = true;
    //   this.setState(newState, () => {
    //     console.log('update set !', this.state.isReady)
    //   });
    // }
    // console.log('oldstate', oldProps.$$state)
    if (oldProps.$$state === undefined && this.props.$$state !== undefined) {
      // console.log('new state loaded!s')
      let newState = {};
      newState.choice = [];
      // newState.isReady = true;
      const { $$state } = this.props;
      if ($$state.get("answered")) {
        // choice
        // console.log('found choice', $$state.get('answered'))
      }
      this.setState(newState);
    }

    if (
      (oldProps.questionId === undefined &&
        this.props.questionId !== undefined) ||
      (oldProps.questionId !== undefined &&
        oldProps.questionId !== this.props.questionId)
    ) {
      this.setState(this._getInitState(), () => {
        const {
          eventId,
          questionId,
          revision,
          pull,
          // $$content
        } = this.props;
        this.props.onMount({
          eventId,
          revision,
          pull,
          // questionId
          questionId: questionId,
        });
      });
    }

    if (oldProps.pull === true && oldProps.revision !== this.props.revision) {
      const {
        eventId,
        questionId,
        // $$content
      } = this.props;
      this.props.fetchQuizState({ eventId, questionId });
    }

    if (oldProps.$$results !== this.props.$$results) {
      if (this.props.$$results === true) this.setState({ lockScreen: true });
      else this.setState({ lockScreen: false });
    }
    // if(oldProps.$$results !== this.props.$$results) {
    //   const {
    //     showResults
    //   } = this.state;
    //   const newShowResults = this.props.$$results !== undefined && this.props.$$results.size > 0;
    //   // console.log({newShowResults})
    //   if(showResults !== newShowResults)
    //   this.setState({
    //     showResults: newShowResults
    //   })
    // }
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }
  // _onQuizHover(idx, newHover) {
  //   this.setState({
  //     ['h_' + idx]: newHover
  //   });
  // }
  // _onQuizChange(value) {
  // this.setState({
  //   ['v_' + idx]: value,
  //   errored: false
  // })
  // }
  _onFormSubmit() {
    const { sendQuiz, $$state, questionId, eventId } = this.props;
    // const {
    //   eventId,
    //   // questionId,
    // } = this.props.match.params;
    const {
      choice,
      // answered,
      showResults,
    } = this.state;
    // if(answered === true || showResults === true) return;
    if ($$state.get("answered") !== false || showResults === true) return;
    let errored = false;
    const choiceData = [...choice];
    if (choiceData.length === 0)
      errored = "Vous devez choisir au moins une réponse !";
    if (errored !== false) {
      this.setState({
        errored,
      });
    } else {
      this.setState({
        errored: false,
      });
      // console.log('SUBMIT !', eventId, quizData);
      sendQuiz({
        eventId,
        questionId,
        choiceData,
      });
    }
  }
  // _getQuizCard() {
  //   const {
  //     eventId
  //   } = this.props.match.params;
  //   const {
  //     $$data
  //   } = this.props;
  //   if($$data === undefined) return;
  //   // console.log(eventId, $$data.toJS())
  //   const found = $$data.get('allEvents').find(f => f.get('id') === eventId);
  //   return found
  // }
  _onAnswerClick = (rid) => {
    const { $$quiz, $$state, embed } = this.props;
    const { choice, showResults } = this.state;
    if (embed === true) return;
    else if (
      ($$state !== undefined && $$state.get("answered") !== false) ||
      showResults === true
    )
      return;
    let newChoice = [...choice];
    if (choice.indexOf(rid) >= 0) remove(newChoice, rid);
    else newChoice.push(rid);
    // const $$quiz = this._getQuiz();
    if (newChoice.length > $$quiz.get("quizMaxAnswers")) newChoice.shift();
    // console.log('too big')
    // console.log('new choice', newChoice)
    this.setState({
      choice: newChoice,
      errored: false,
    });
  };
  // _getQuiz = () => {
  //   const {
  //     $$quizData,
  //     questionId
  //   } = this.props;
  //   return $$quizData !== undefined && $$quizData.get(questionId) !== undefined ? $$quizData.get(questionId) : undefined;
  // }
  render() {
    const {
      // userRole,
      classes,
      $$state,
      // $$data,
      // questionId,
      $$quiz,
      $$results,
      embed,
    } = this.props;
    const {
      errored,
      // isReady,
      lockScreen,
      choice,
      // showResaults
    } = this.state;
    // console.log({ lockScreen });
    // const $$card = this._getQuizCard();
    // const cardImage = getCardImage($$card);
    const gridAnswersSize = 12;
    // console.log({showResults})
    const $$choices =
      $$state !== undefined &&
      $$state.get("answered") !== false &&
      $$state.get("answered") !== undefined
        ? $$state.get("answered")
        : undefined;

    const showResults =
      $$results !== null &&
      $$results !== undefined &&
      $$results !== false &&
      $$results !== true;

    // console.log('results are', $$results)
    // console.log("CHOICES", $$choices)
    // console.log({embed, answered, isReady, showResults})
    // console.log({state: ($$state !== undefined ? $$state.toJS() : undefined), $$choices})
    return (
      <Grid
        item={true}
        xs={12}
        className={classes.root}
        container
        spacing={0}
        alignContent="center"
      >
        <If condition={$$quiz !== undefined && $$quiz.get("id") !== undefined}>
          <Grid item={true} xs={12} container spacing={0} alignContent="center">
            <Grid item xs={12}>
              <Box mx={1} p={2} mb={2} className={classes.question}>
                <Typography variant={"h1"}>
                  {$$quiz.get("quizQuestion")}
                </Typography>
              </Box>
              <If condition={!embed}>
                <Box mx={1} px={2} mb={2} className={classes.instructions}>
                  <Choose>
                    <When
                      condition={
                        !showResults &&
                        $$choices === undefined &&
                        $$quiz.get("quizMaxAnswers") > 1
                      }
                    >
                      <Typography variant={"subtitle1"}>
                        {i18n.get("quizInstructionsPlural", {
                          substitute: {
                            "{max}": $$quiz.get("quizMaxAnswers"),
                            "{size}": $$quiz.get("quizAnswers").size,
                          },
                        })}
                      </Typography>
                    </When>
                    <When
                      condition={
                        !showResults &&
                        $$choices === undefined &&
                        $$quiz.get("quizMaxAnswers") <= 1
                      }
                    >
                      <Typography variant={"subtitle1"}>
                        {i18n.get("quizInstructionsSingle", {
                          substitute: {
                            "{size}": $$quiz.get("quizAnswers").size,
                          },
                        })}
                      </Typography>
                    </When>
                    <When condition={!embed && showResults === true}>
                      <Typography variant={"subtitle1"}>
                        {i18n.get("quizResults")}
                      </Typography>
                    </When>

                    {/*  <When condition={showResults}>
                      <Typography variant={'subtitle1'}>{i18n.get('quizResults')}</Typography>
                    </When>*/}
                    <Otherwise>
                      <Typography variant={"subtitle1"}>&nbsp;</Typography>
                    </Otherwise>
                  </Choose>
                </Box>
              </If>
            </Grid>
            {$$quiz
              .get("quizAnswers")
              .map(($$ans, aIdx) => {
                const percentage =
                  showResults === true
                    ? $$results.getIn([$$ans.get("rid"), "percentage"], 0)
                    : 0;

                return (
                  <Grid
                    item
                    xs={gridAnswersSize}
                    key={"a_" + aIdx}
                    container
                    alignContent="center"
                    className={classes.answerWrapper}
                  >
                    <Box
                      mx={1}
                      py={2}
                      px={2}
                      className={clsx(classes.answer, {
                        [classes.selected]:
                          embed !== true &&
                          (choice.indexOf($$ans.get("rid")) >= 0 ||
                            ($$choices !== undefined &&
                              $$choices.contains($$ans.get("rid")))),
                        [classes.correct]:
                          $$quiz.get("quizType") === "quiz" &&
                          showResults === true &&
                          $$results.get($$ans.get("rid")) !== undefined &&
                          $$results.get($$ans.get("rid")).get("correct") ===
                            true,
                        [classes.incorrect]:
                          $$quiz.get("quizType") === "quiz" &&
                          showResults === true &&
                          $$results.get($$ans.get("rid")) !== undefined &&
                          $$results.get($$ans.get("rid")).get("correct") !==
                            true,
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        this._onAnswerClick($$ans.get("rid"));
                      }}
                    >
                      <Grid container>
                        <Grid item xs={true}>
                          <Typography
                            variant={"body1"}
                            display={"block"}
                            width={"100%"}
                          >
                            {$$ans.get("field")}
                          </Typography>
                        </Grid>
                        <If condition={showResults === true}>
                          <Grid
                            item
                            xs={true}
                            style={{ maxWidth: "6rem", textAlign: "right" }}
                          >
                            <Typography
                              variant="body1"
                              className={classes.percentage}
                              style={{ textAlign: "inherit" }}
                            >
                              {percentage.toString()}&nbsp;%
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <LinearProgress
                              color="secondary"
                              variant="determinate"
                              value={Math.round(percentage)}
                            />
                          </Grid>
                        </If>
                      </Grid>
                    </Box>
                  </Grid>
                );
              })
              .toArray()}
          </Grid>
          <If condition={errored !== false}>
            <Grid item={true} xs={12} className={classes.log}>
              <Typography variant={"subtitle1"} color="error" align="center">
                {i18n.get("quizMustChose")}
              </Typography>
            </Grid>
          </If>
          <Grid
            item={true}
            xs={12}
            justifyContent="center"
            container
            alignContent="center"
            className={classes.actions}
          >
            <Choose>
              <When
                condition={
                  !embed &&
                  $$choices === undefined &&
                  showResults !== true &&
                  lockScreen !== true
                }
              >
                <Button
                  onClick={this._onFormSubmit}
                  disabled={choice.length === 0}
                  size={"large"}
                  color="secondary"
                  variant="contained"
                >
                  {i18n.get("quizSend")}
                </Button>
              </When>
              <When
                condition={
                  !embed &&
                  $$choices !== undefined &&
                  showResults !== true &&
                  lockScreen !== true
                }
              >
                <Typography variant={"h4"} color="secondary">
                  {i18n.get("quizSuccess")}
                </Typography>
              </When>
              {/*
                <When condition={!embed && showResults === true}>
                <Typography variant={'h4'} color="secondary">{i18n.get('quizResults')}</Typography>
              </When>
              */}
              <Otherwise>
                <Typography variant={"h4"} color="secondary">
                  &nbsp;
                </Typography>
              </Otherwise>
            </Choose>
          </Grid>
        </If>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    error: getLoadingError(state),
    isLoggedIn: isLoggedIn(state),
    userRole: getCurrentUserRole(state),
    $$state: getQuizState(state),
    $$user: getCurrentUser(state),
    $$quiz: getQuizQuestion(state),
    $$results: getQuizResults(state),
    // $$options: getQuizOptions(state),
    // $$content: getContent(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      // console.log('mouting with', props)
      if (props.eventId !== undefined) {
        dispatch(fetchQuizQuestion(props));
      }
    },
    // fetchResults: (props) => {
    //   if(props.eventId !== undefined) {
    //     dispatch(fetchQuizResults(props));
    //   }
    // },
    fetchQuizState: (props) => {
      if (props.eventId !== undefined) {
        dispatch(fetchQuizState(props));
      }
    },
    // clearQuizResults: () => {
    //   dispatch(clearQuizResults());
    // },
    onUnmount: () => {
      dispatch(clearQuizData());
    },
    sendQuiz: (props) => {
      // console.log('SEND')
      if (props.eventId !== undefined) {
        // console.log('SEND')
        dispatch(sendQuiz(props));
      }
    },
  };
}
// export default ;
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Quiz))
);
