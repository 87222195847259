import React, {
  Component
} from "react";
// import Typography from '@material-ui/core/Typography';
import {
  Link
} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  withStyles
} from "@material-ui/core/styles";
// import {
//   primaryColor,
// } from "helpers/colors";
const styles = (theme) => {
  return {};
};
class BlockButton extends Component {
  render() {
    const {
      label,
      to,
      // classes,
      // size,
      // variant,
      onClick,
      disabled,
      // align,
      style,
      buttonRef,
      // lowerCase,
      className,
      startIcon,
      endIcon,
    } = this.props;
    // let rootClass = size === 'big' ? classes.big : classes.small;
    // rootClass = lowerCase === true ? rootClass+' '+classes.lowerCase : rootClass;
    //
    // if (variant === 'white') rootClass += ' '+classes.white;
    // if (align === 'center') rootClass += ' '+classes.alignCenter;
    let buttonStyle = {
      // display: 'inline-flex',
      margin: "0 auto",
      ...style,
    };
    let buttonProps = {
      label,
      className,
      // ['aria-label']: label,
      // classes: {root: rootClass},
      style: buttonStyle,
      onClick,
      disabled,
      ref: buttonRef,
      startIcon,
      endIcon,
      variant: 'outlined',
      // variant: variant !== 'white' ? variant : undefined
      // gutterBottom,
    };
    if(to !== undefined) {
      buttonProps.component = Link;
      buttonProps.to = to;
    }
    // if (target !== undefined) {
    //   buttonProps.target = target;
    // }
    return <Button {...buttonProps}>{label}</Button>;
  }
}
export default withStyles(styles)(BlockButton);
