import React from "react";
import PropTypes from "prop-types";

export default class ModuleConfigProvider extends React.Component {
  static childContextTypes = {
    moduleConfig: PropTypes.object,
  };
  getChildContext() {
    return { moduleConfig: this.props.moduleConfig };
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
