export default function isMember($$user, role) {
  // if ($$user !== undefined)
  // console.log($$user.get('role'))
  if ($$user === undefined)
    return false;
  else if (role !== undefined && $$user !== undefined && $$user.get('role') === role)
    return true;
  else
    return ($$user !== undefined && ($$user.get('role') === 'member' || $$user.get('role') === 'speaker' || $$user.get('role') === 'staff' || $$user.get('role') === 'admin'));
}
