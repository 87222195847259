import React from "react";
// import { SelectField } from 'redux-form-material-ui'
// import { SelectField } from 'redux-form-material-ui'
import customStyles from "./DynamicFilter.cssmodule.css";
import queryString from "query-string";
import getFetchOptions from "helpers/getFetchOptions";

import MaterialUIAutoComplete from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/core/TextField";
import IconDelete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Spinner from "components/UI/Spinner";

class DynamicFilterSelectAsync extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      searchText: "",
    };
  }

  onChange(event, val) {
    // event.preventDefault();
    // event.stopPropagation();
    const obj = this.state.options[val];
    const valStr = obj.value;
    this.setState({ value: valStr }, this.propagateFilterChanges);
    // this.propagateFilterChanges();
  }
  propagateFilterChanges() {
    const {
      // $$filter,
      onChange,
      filterId,
    } = this.props;
    const valStr = this.state.value;
    // console.log('filtering', filterId, valStr)
    onChange(filterId, valStr);
  }
  onClearFilter = (event) => {
    this.setState(
      { value: null, searchText: "", options: [] },
      this.propagateFilterChanges
    );
  };

  onFocus = () => {
    const { $$filter } = this.props;
    // const { options } = this.state;

    this.setState({ fetching: true });
    const url = $$filter.get("filter_url");

    const query = queryString.parse(queryString.extract(url));

    const completeURL =
      url.replace(/\?.*$/, "") + "?" + queryString.stringify(query);

    fetch(completeURL, getFetchOptions())
      .then((response) => response.json())
      .then((response) => {
        this.setState({ options: response.data.options, fetching: false });
      });
  };
  render() {
    const { $$filter, filterId } = this.props;
    const { options, error } = this.state;

    let dataSource = options || [];

    // console.log(this.state.value)
    return (
      <span className="root" style={{ position: "relative" }}>
        <AutoComplete
          id={filterId}
          onFocus={this.onFocus}
          onNewRequest={this.onChange.bind(this)}
          openOnFocus={true}
          maxSearchResults={20}
          variant="outlined"
          filter={MaterialUIAutoComplete.caseInsensitiveFilter}
          textFieldStyle={{ width: "100%" }}
          dataSource={dataSource}
          dataSourceConfig={{ text: "text", value: "value" }}
          errorText={error}
          floatingLabelText={$$filter.get("filter_alias")}
          searchText={this.state.searchText}
          onUpdateInput={(searchText) => this.setState({ searchText })}
          style={{ width: "calc(100% - 50px)", verticalAlign: "bottom" }}
        />
        <If
          condition={
            this.state.value !== null &&
            this.state.value !== false &&
            this.state.value !== ""
          }
        >
          <IconButton
            tooltip={"Supprimer"}
            onTouchTap={this.onClearFilter}
            className={customStyles.filterTrash}
          >
            <IconDelete />
          </IconButton>
        </If>
        <If condition={this.state.fetching}>
          <Spinner size={20} style={{ position: "absolute", top: -25 }} />
        </If>
      </span>
    );
  }
}

export default DynamicFilterSelectAsync;
