// import React from 'react';
import React, { useState, FC, useCallback, useEffect } from 'react';
// import { useGoogleReCaptcha } from '../src/use-google-recaptcha';

// import ReactDOM from 'react-dom';
// import clsx from 'clsx';
// import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import InputLabel from '@material-ui/core/InputLabel';
// import config from 'config/api'
// import ColorPicker from 'material-ui-color-picker'
// import {CirclePicker} from 'react-color';
import { withStyles } from '@material-ui/core/styles';
// import Immutable from 'immutable';
import {
  useGoogleReCaptcha,
  // GoogleReCaptchaProvider,
  // GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import WithGoogleRecaptchaExample from './CaptchaComponent';

const styles = theme => ({
  wrapper: {
    // width: '100%',
    display: 'flex',
    margin: '50px auto 10px',
    width: 280,
    flexWrap: 'wrap',
  },
  colorWrapper: {
    margin: '0 5px 5px',
  },
  color: {
    width: 36,
    height: 36,
    display: 'block',
    borderRadius: 90,
    // display:
  },
  colorSelected: {
    boxShadow: '0 0 8px #000',
  },
});


//export const GoogleRecaptchaExample: FC = () => {
export const GoogleRecaptchaExample = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');
  const [noOfVerifications, setNoOfVerifications] = useState(0);
  const [dynamicAction, setDynamicAction] = useState('homepage');
  const [actionToChange, setActionToChange] = useState('');

  const clickHandler = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const result = await executeRecaptcha('dynamicAction');

    setToken(result);
    setNoOfVerifications(noOfVerifications => noOfVerifications + 1);
  }, [dynamicAction, executeRecaptcha]);

  const handleTextChange = useCallback(event => {
    setActionToChange(event.target.value);
  }, []);

  const handleCommitAction = useCallback(() => {
    setDynamicAction(actionToChange);
  }, [actionToChange]);

  useEffect(() => {
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha(dynamicAction);
      setToken(token);
      setNoOfVerifications(noOfVerifications => noOfVerifications + 1);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha, dynamicAction]);

  return (
    <div>
      <div>
        <p>Current ReCaptcha action: {dynamicAction}</p>
        <input type="text" onChange={handleTextChange} value={actionToChange} />
        <button onClick={handleCommitAction}>Change action</button>
      </div>
      <br />
      <button onClick={clickHandler}>Run verify</button>
      <br />
      {token && <p>Token: {token}</p>}
      <p> No of verifications: {noOfVerifications}</p>
    </div>
  );
};


class Captcha extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0,
      value: '',
    };
    this._onChange = this._onChange.bind(this);
  }
  componentDidMount() {
    this.setState({
      value: this.props.value,
      // labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }
  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      // console.log('FIELD VALUE CHANGED !!',oldProps.value, this.props.value)
      this.setState({
        value: this.props.value,
        // labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    }
  }

  _onChange(v) {
    // console.log(v)
    const { id, onChange } = this.props;
    this.setState(
      {
        value: v,
      },
      function() {
        // console.log('CHANGED T', t, t.name, t.value)
        if (onChange)
          onChange('color', {
            id: id,
            value: v,
          });
      },
    );
  }
  _handleVerify = (a,b,c) => {
    console.log(a,b,c)
  }
  render() {
    // const {
      // id,
      // classes,
      // label,
      // error,
      // required,
      // variant,
      // style,
      // errorMessage,
    // } = this.props;
    // console.log('CHECKBOX ERROR IS', error)
    // console.log('CHECK ID IS ', id, disabled)
    // const { value } = this.state;

    // let helperText = false;
    // if (errorMessage !== undefined && errorMessage !== false) helperText = errorMessage;
    // const variant = false;

    return (<WithGoogleRecaptchaExample/>)


    // return (
    //   <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaKey}>
    //     <WithGoogleRecaptchaExample />
    //   </GoogleReCaptchaProvider>
    // )
    // return (
    //   <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaKey} scriptProps={{ async: true, defer: true, appendTo: 'body' }}>
    //     <GoogleReCaptcha onVerify={this._handleVerify} />
    //     <GoogleRecaptchaExample />
    //   </GoogleReCaptchaProvider>
    //
    // )
    // return (
    //   <FormControl
    //     fullWidth={true}
    //     ref={ref => {
    //       this.InputLabelRef = ref;
    //     }}
    //     error={error}
    //     style={style}
    //     variant={variant}
    //   >
    //         <WithGoogleRecaptchaExample />
    //
    //     <If condition={helperText !== undefined && helperText !== false}>
    //       <FormHelperText error={error}>{helperText}</FormHelperText>
    //     </If>
    //   </FormControl>
    // );
  }
}
export default withStyles(styles)(Captcha);
