import React, { Component } from 'react';
import GoogleMapApiWrapper, { Status } from 'components/GoogleMapApiWrapper';
import config from 'config/api';

const MapContext = React.createContext(undefined);

export class Marker extends Component {
  static contextType = MapContext;

  markerOptions = () => {
    const map = this.context;
    const { onClick, ...otherProps } = this.props;
    const options = { map, ...otherProps };
    return options;
  };

  componentDidMount() {
    this.marker = new window.google.maps.Marker(this.markerOptions());
    this.marker.addListener('click', () => {
      const { onClick } = this.props;
      if (onClick) onClick();
    });
  }

  componentDidUpdate(prevProps) {
    this.marker.setOptions(this.markerOptions());
  }

  componentWillUnmount() {
    if (this.marker) this.marker.setMap(null);
  }

  render() {
    return null;
  }
}

class Map extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      map: undefined,
    };
  }

  handleMapRef = (element) => {
    if (!element) return;

    const { map: existingMap } = this.state;
    if (existingMap) return;

    const { defaultCenter, defaultZoom, disableDefaultUI, zoomControl } = this.props;

    const map = new window.google.maps.Map(element, {
      center: defaultCenter,
      zoom: defaultZoom,
      disableDefaultUI,
      zoomControl,
    });

    const { onIdle } = this.props;
    if (onIdle) map.addListener('idle', () => onIdle(map));

    const { onIdleBoundsChange } = this.props;
    if (onIdleBoundsChange)
      map.addListener('idle', () => {
        const bounds = map.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        onIdleBoundsChange({
          northEast: {
            lat: northEast.lat(),
            lng: northEast.lng(),
          },
          southWest: {
            lat: southWest.lat(),
            lng: southWest.lng(),
          },
        });
      });

    if (this.props.mapRef) this.props.mapRef.current = map;

    this.setState({ map });
  };

  renderMapStatus = (status) => {
    const { loadingComponent, errorComponent } = this.props;

    if (status === Status.LOADING && loadingComponent) return loadingComponent();
    if (status === Status.FAILURE && errorComponent) return errorComponent();

    return null;
  };

  render() {
    const { style, children, className } = this.props;
    const { map } = this.state;

    return (
      <GoogleMapApiWrapper apiKey={config.mapsApiKey} render={this.renderMapStatus}>
        <div ref={this.handleMapRef} style={style} className={className} />
        {map && <MapContext.Provider value={map}>{children}</MapContext.Provider>}
      </GoogleMapApiWrapper>
    );
  }
}

export default Map;
