import config from 'config/api';
import forEach from 'mout/array/forEach';
import isArray from 'mout/lang/isArray';

export default ($$card) => {
  const field = 'offerImages';
  let cardImage = [];
  // let cardPresentation = false;
  let decodedValues;
  if (
    $$card !== false &&
    $$card !== undefined &&
    $$card.get(field) !== undefined &&
    $$card.get(field) !== null &&
    $$card.get(field) !== false
  ) {
    try {
      decodedValues = JSON.parse($$card.get(field));
    } catch (e) {}
    // console.log('decoded is', decodedValues)
    if (decodedValues !== undefined && isArray(decodedValues)) {
      forEach(decodedValues, (v) => {
        if (v && v.dest)
          cardImage.push(config.offerthumbpreview.replace('{eventId}', $$card.get('id')) + '/' + v.dest);
      });
    }
  }
  return cardImage;
};
