import React from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import clsx from 'clsx';
// import {
//   Redirect,
// } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import i18n from 'helpers/i18n';

import Form from 'components/UI/Form/Form';
import {
  send as formSubmitAction,
  hasSentFailed as isFormErrored,
  getLog as getFormLog,
  getError as getFormError,
  isSending as isFormRunning,
  isSent as isFormComplete,
} from 'ducks/contact';

import { getCurrentUser } from 'components/UI/Account/ducks/authentication';

const style = (theme) => {
  return {
    requiredFieldsLegend: {
      marginTop: theme.spacing(4),
      fontStyle: 'italic',
    },
  };
};
const FIELDS_FULL = [
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    validation: ['notEmpty', 'email'],
    maxLength: 64,
    sizes: { xs: 12, sm: 6 },
  },
  {
    id: 'email2',
    name: 'email2',
    label: 'Email (confirmation)',
    validation: ['notEmpty', 'email', 'sameAs'],
    maxLength: 64,
    sizes: { xs: 12, sm: 6 },
    sameAs: {
      label: 'Email',
      field: 'email',
    },
  },
  {
    id: 'firstname',
    name: 'firstname',
    label: 'Prénom',
    validation: ['notEmpty', 'alphaNum'],
    maxLength: 64,
    sizes: { xs: 12, sm: 6 },
  },
  {
    id: 'name',
    name: 'name',
    label: 'Nom',
    validation: ['notEmpty', 'alphaNum'],
    maxLength: 64,
    sizes: { xs: 12, sm: 6 },
  },
  {
    id: 'city',
    name: 'city',
    label: 'Ville',
    validation: ['notEmpty', 'alphaNum'],
    maxLength: 64,
    sizes: { xs: 12, sm: 6 },
  },
  {
    id: 'cell',
    name: 'cell',
    label: 'Téléphone (mobile)',
    validation: ['cell'],
    sizes: { xs: 12, sm: 6 },
  },
  {
    id: 'subject',
    name: 'subject',
    label: 'Sujet',
    type: 'select',
    options: [
      { value: 'registration', label: "Problème d'inscription" },
      { value: 'connection', label: 'Problème de connexion' },
      { value: 'rpps', label: 'Problème de RPPS / Adeli' },
      { value: 'annonce', label: 'Problème avec une annonce' },
      // {'value': 'other', 'label': 'Autre'},
    ],
    validation: ['notEmpty', 'alphaNum'],
    maxLength: 128,
    sizes: { xs: 12 },
  },
  // {
  //   id: 'message',
  //   name: 'message',
  //   label: 'Message',
  //   multiline: true,
  //   validation: ['notEmpty', 'alphaNum'],
  //   sizes: {xs: 12},
  //   maxLength: 2000,
  //   rows: 4
  // }
];

class ContactForm extends Form {
  constructor(props) {
    super(props);
    this.state = {};
    // let $$FIELDS;
    // if (props.userRole === 'staff' || props.userRole === 'speaker')
    //   this.$$FIELDS = Immutable.fromJS(FIELDS_LIGHT);
    // else
    this.$$FIELDS = Immutable.fromJS(FIELDS_FULL);

    this.$$CONFIG = Immutable.fromJS({
      buttonColorSubmit: 'primary',
      buttonVariant: 'contained',
      requiredFieldsLegendAlign: 'center',
    });
    // this.$$FIELDS = $$FIELDS;
    // this._onFormSubmit = this._onFormSubmit.bind(this);
    // this._onFieldValueChange = this._onFieldValueChange.bind(this);
  }

  componentDidMount() {
    this._setupState({}, () => {
      // console.log('test?', this.props.$$formValues, this.props.initValues)
      if (this.props.initValues) this._mapRawValues(this.props.initValues);
      else this._mapRawValues(this.props.$$formValues);
    });
  }
  componentDidUpdate(oldProps) {
    if (oldProps.$$formValues === undefined && this.props.$$formValues !== undefined) {
      this._mapRawValues(this.props.$$formValues);
    }
  }

  _mapRawValues = ($$values) => {
    // if ($$values === undefined || !$$values.get || $$values.get('subject') === undefined) return;
    if ($$values === undefined || !$$values.get) return;
    var o = {
      email: $$values.get('email'),
      email2: $$values.get('email'),
      // email2: $$values.get('email'),
      name: $$values.get('lastname'),
      lastname: $$values.get('lastname'),
      firstname: $$values.get('firstname'),
      cell: $$values.get('cell'),
      city: $$values.get('city'),
      subject: $$values.get('subject'),
    };
    // return Immutable.fromJS(o);
    // return $$values;
    // var tmp = {}
    // $$values.map((val, k) => {
    this.$$FIELDS.map((f, idx) => {
      this._onFieldValueChange(null, { id: f.get('id'), value: o[f.get('id')] });
      return true;
    });
    // Immutable.fromJS(o).map((val, k) => {
    //   console.log(this.$$FIELDS.get(k), this.$$FIELDS.toJS())
    //   if (this.$$FIELDS.get(k)) {
    //     this.$$FIELDS.get(k).set('disabled', true)
    //     this._onFieldValueChange(null, { id: k, value: val });
    // }
    // return true;
    // });
    // return $$values;
    return Immutable.fromJS(o);
  };

  render() {
    const {
      // error,
      // loading,
      className,
      log,
      isFormComplete,
      // userRole,
    } = this.props;
    //
    // if (isFormComplete) {
    //   const url ='/contact'
    //   return <Redirect to={{
    //       pathname: url,
    //       state: { from: this.props.location }
    //     }}/>
    //   }

    return (
      <div className={clsx('Form', className)}>
        <If condition={!isFormComplete}>{this._renderForm()}</If>
        <If condition={isFormComplete}>
          <Box align="center">
            <Typography variant="h5" gutterBottom>
              {log}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {i18n.get('contactFormSuccess')}
            </Typography>
          </Box>
        </If>
      </div>
    );
  }
}

const mapStateToProps = ($$state) => {
  return {
    isFormErrored: isFormErrored($$state),
    log: getFormLog($$state),
    error: getFormError($$state),
    loading: isFormRunning($$state),
    isFormComplete: isFormComplete($$state),
    $$formValues: getCurrentUser($$state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (formData) => {
      dispatch(formSubmitAction(formData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ContactForm));
