import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';

const actionTypes = mirrorCreator(['SET_LIVE_DATA', 'SET_LIVE_ERROR', 'CLEAR_LIVE'], { prefix: 'live/' });

const mutators = {
  setLiveData: (live) => ($$state) => $$state.set('live', live),
  setLiveError: (error) => ($$state) => $$state.set('error', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_LIVE_DATA:
      acts.push(mutators.setLiveData(action.live));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_LIVE_ERROR:
      return pipe([mutators.setLiveError(action.error)], $$state);

    case actionTypes.CLEAR_LIVE:
      return pipe([mutators.setLiveError(undefined), mutators.setLiveData(undefined)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.live || Immutable.Map();
export const getLiveData = createSelector([getRoot], ($$state) => $$state.get('live'));
export const getLiveError = createSelector([getRoot], ($$state) => $$state.get('error'));

export function fetchLive({ liveId, params }) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.events + '/live/' + liveId;

    if (params.k !== undefined && params.r === undefined) url = url+'?k='+params.k;
    if (params.r !== undefined && params.k === undefined) url = url+'?r='+params.r;
    if (params.r !== undefined && params.k !== undefined) url = url+'?k='+params.k+'&r='+params.r;
    let formData = {};
    formData.contact_form = true;

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.live && response.live === 'complete') {
          dispatch(setLiveData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setLiveError(response.log));
        }
      })
      .catch((error) => dispatch(setLiveError(error.message)));
  };
}
export function setLiveData(live) {
  return {
    type: actionTypes.SET_LIVE_DATA,
    live,
  };
}
export function setLiveError(error) {
  return {
    type: actionTypes.SET_LIVE_ERROR,
    error,
  };
}
export function clearLive() {
  return {
    type: actionTypes.CLEAR_LIVE,
  };
}
