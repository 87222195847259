import isString from 'mout/lang/isString';
// import isArray from 'mout/lang/isArray';

const isInStringCategory = (category, activeCategories) => {
  let inCategory = false, catArray, inSubCategory;
  if (activeCategories === undefined) return false;
  if (category === undefined) inCategory = true;
  var r = new RegExp(/&/);
  if (r.test(category))
  {
      inSubCategory = true;
      catArray = category.split('&');
      catArray.forEach(cat => {
        // console.log('debug', activeCategories, category, cat)
        if (activeCategories.indexOf(cat) < 0) {
          // console.log('not found', cat)
          inSubCategory = false;
        } else {
          // console.log('found', cat)
        }
      })
      inCategory = inSubCategory;
  } else {
    if (activeCategories.indexOf(category) >= 0)
      inCategory = true;
  }
  return inCategory;
}
export default (field, activeCategories) => {
  let inCategory = false;
  if (activeCategories === undefined) return false;
  if (field.get('category') === undefined) inCategory = true;
  // else if (
  //   field.get('category') !== undefined &&
  //   isString(field.get('category')) &&
  //   activeCategories.indexOf(field.get('category')) >= 0
  // )
  else if (
    field.get('category') !== undefined &&
    isString(field.get('category'))
  ) {
    inCategory = isInStringCategory(field.get('category'), activeCategories)
  } else if (field.get('category') !== undefined && field.get('category').map !== undefined) {
    // console.log('test', field.get('id'))
    // const found = field.get('category').filter((c) => {
    // return  (isArray(activeCategories)) ? activeCategories.indexOf(c) >= 0 : activeCategories === c
    // });
    // if (found.size > 0) inCategory = true;
    field.get('category').map((c) => {
      const inCat = isInStringCategory(c, activeCategories) ;
      // console.log('testing, ',field.get('id'), field.get('category').toJS(), activeCategories, inCat)
      inCategory = inCategory !== true ? inCat : inCategory;
      return true;
    });
    // return  (isArray(activeCategories)) ? activeCategories.indexOf(c) >= 0 : activeCategories === c
    // });
    // if (found.size > 0) inCategory = true;
  }
  // console.log({id: field.get('id'), inCategory, activeCategories, categories: (field && field.get('category') && field.get('category').toJS ? field.get('category').toJS() : field.get('category')), field: field.toJS()})
  return inCategory;
};
