import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import config from 'config/api';
import { Link } from 'react-router-dom';
import { isLoggedIn, getCurrentUser, getUniverse } from 'components/UI/Account/ducks/authentication';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import i18n from 'helpers/i18n';

const styles = (theme) => {
  return {
    root: {
      marginTop: 'auto', // keep !
      width: '100%',
      color: theme.palette.footer.color,
      backgroundColor: theme.palette.footer.background,
      textAlign: 'center',
    },
    footerWrapper: {
      position: 'relative',
      maxWidth: 1280,
      margin: '0 auto',
      padding: theme.spacing(4),
    },
    logo: {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 50%',
      backgroundSize: 'contain',
      backgroundImage: `url(${require('assets/images/logo_footer.png')})`,
      // width: 234,
      width: '100%',
      maxWidth: 243,
      height: 44,
      display: 'block',
      margin: '0 auto 0',
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: '50% 50%',
        marginBottom: theme.spacing(2),
      },
      '& a': {
        display: 'block',
        width: '100%',
        height: '100%',
      },
    },
    logoInline: {
      // width: 300,
      // height: 40
    },
    inline: {
      // display: 'block',
      width: 'auto',
      padding: '0 10px',
      [theme.breakpoints.down('md')]: {
        // display: 'inline-block',
        padding: '0 5px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 5px',
      },
    },
    link: {
      textDecoration: 'none',
      textAlign: 'right',
      display: 'block',
      fontSize: '1rem',
      // fontSize: '0.6rem',
      color: theme.palette.footer.linkColor,
      '&:hover': {
        textDecoration: 'underline',
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    legalNotice: {
      fontSize: '.8rem',
      '& a': {
        color: theme.palette.footer.color,
      },
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
      '& .MuiTypography.caption': {
        fontSize: '.8rem',
        textAlign: 'left',
        borderTop: '1px #d0d9dd solid',
        paddingBottom: theme.spacing(4),
      },
    },
    menu: {
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    separator: {
      height: 1,
      borderTop: '1px #d0d9dd solid',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  };
};
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { $$user, classes, footerContent, universe } = this.props;

    const $$menu = config.getFooterMenu($$user, { asImmutable: true, universe });
    return (
      <div className={clsx(classes.root)}>
        <div className={classes.footerWrapper}>
          <Grid container spacing={0} alignContent="center" alignItems="center">
            <Hidden mdUp>
              <Grid item xs={12}>
                <div className={classes.logo}>
                  <a href="https://norgine.fr/">
                    <span></span>
                  </a>
                </div>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={3} container justifyContent="space-around" className={classes.menu}>
              <Hidden smDown>
                <ListItem className={classes.logoWrapper}>
                  <div className={clsx(classes.logo, classes.logoInline)} alt="logo">
                    <a href="https://norgine.fr/">
                      <span></span>
                    </a>
                  </div>
                </ListItem>
              </Hidden>
            </Grid>
            <If condition={footerContent !== undefined}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">{footerContent}</Typography>
              </Grid>
            </If>

            <Grid item xs={12} md={footerContent !== undefined ? 3 : 6} container className={classes.menu}>
              {$$menu
                .map((m, mIdx) => {
                  const url = m
                    .get('url')
                    .replace('/{universe}', universe !== undefined ? '/' + universe : '');
                  return (
                    <ListItem key={'fmn_' + mIdx} className={classes.inline}>
                      <ListItemText>
                        <If condition={m.get('target') === undefined}>
                          <Link className={classes.link} to={url}>
                            {i18n.get(m.get('title'), { pretty: false })}
                          </Link>
                        </If>
                        <If condition={m.get('target') !== undefined}>
                          <a className={classes.link} href={url} target={m.get('target')}>
                            {i18n.get(m.get('title'), { pretty: false })}
                          </a>
                        </If>
                      </ListItemText>
                    </ListItem>
                  );
                })
                .toArray()}
            </Grid>
            <Grid item xs={12} md={3} container className={classes.menu}>
              <ListItem key={'fmn_privacy'} className={classes.inline}>
                <ListItemText>
                  <a
                    className={classes.link}
                    href={'https://norgine.fr/a-propos-de-norgine'}
                    target={'_blank'}
                  >
                    {i18n.get('navAbout', { pretty: false })}
                  </a>
                </ListItemText>
              </ListItem>
              <ListItem key='fmn_effets' className={classes.inline}>
                <ListItemText>
                  <Link className={classes.link} to={'/effets'}>
                    Déclarer un effet indésirable
                  </Link>
                </ListItemText>
              </ListItem>
              {/*<ListItem key={'fmn_confidentiality'} className={classes.inline}>
                <ListItemText>
                  <Link className={classes.link} to={'/confidentiality'}>
                    {i18n.get('navConfidentiality', { pretty: false })}
                  </Link>
                </ListItemText>
              </ListItem>
              */}
              <ListItem key={'fmn_legal'} className={classes.inline}>
                <ListItemText>
                  <Link className={classes.link} to={'/legal'}>
                    {i18n.get('navLegal', { pretty: false })}
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem key='fmn_cookies' className={classes.inline}>
                <ListItemText>
                  <a className={classes.link} href='/cookies'>Politique de cookies</a>
                </ListItemText>
              </ListItem>
              <ListItem key='fmn_cookies' className={classes.inline}>
                <ListItemText>
                  <a className={classes.link} href='https://norgine.fr/politique-de-confidentialite' target='_blank'>Politique de confidentialité</a>
                </ListItemText>
              </ListItem>
            </Grid>
            <If condition={i18n.has('footerLegalText')}>
              <Grid item xs={12} className={classes.separator}></Grid>
              <Grid item xs={12} className={classes.legalNotice}>
                <Typography variant="caption" element="div" gutterBottom>
                  {i18n.get('footerLegalText', { pretty: true, elemement: 'div' })}
                </Typography>
              </Grid>
            </If>
            <Grid item xs={12} className={classes.separator}></Grid>
            <Grid item xs={12} className={classes.legalNotice}>
              <Typography variant="body1" element="div">
                {i18n.get('footerLegalNotice')}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
    $$user: getCurrentUser(state),
    universe: getUniverse(state),
  };
  return props;
}

export default connect(mapStateToProps)(withStyles(styles)(Footer));
