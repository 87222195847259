import React, { Component } from "react";
import { connect } from "react-redux";
import DynamicList from "components/UI/DynamicList";
import config from "config/api";
import { getCurrentUserRole } from "components/UI/Account/ducks/authentication";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";

class AdminThemeList extends Component {
  render() {
    const { userRole } = this.props;
    const moduleConfig = {
      module: "event",
      fetchUrl: config.theme + "/list",
      deleteUrl: config.theme + "/delete",
      editUrl: "staff/theme/edit",
    };
    return (
      <div>
        <If condition={userRole === "admin"}>
          <Box align="center" mt={3} mb={2} px={2}>
        <Typography variant="h2" gutterBottom color="primary">Thèmes</Typography>
          <Button variant="contained" color="primary" onClick={(e) => {e.preventDefault(); this.props.history.push("/staff/theme/edit")}}>Ajouter</Button>
          </Box>
        </If>
        <DynamicList moduleConfig={moduleConfig} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}

export default withRouter(connect(mapStateToProps)(AdminThemeList));
