import React, { Component } from "react";
// import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import Immutable from "immutable";
// import clsx from "clsx";
// import queryString from "query-string";
// import getFetchOptions from "helpers/getFetchOptions";
// import Dialog from "components/UI/Dialog/Dialog";
import DialogConfirm from "components/UI/Dialog/DialogConfirm";

// import getCardImage from 'helpers/getCardImage'
// import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
// import remove from "mout/array/remove";
// import config from "config/api";
// import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
// import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
// import MenuItem from "@material-ui/core/MenuItem";
// import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
// import BlockTitle from "components/UI/BlockTitle";
// import Rating from "@material-ui/lab/Rating";
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
// import Feedback from "components/UI/Feedback/FeedbackEmbed";
// import AdminOrchestratorBlock from "./AdminOrchestratorBlock";
import { withRouter } from "react-router-dom";
import {
  getFeedbackReset,
  resetFeedback,
} from "components/UI/Feedback/ducks/feedback";
// import {
  // setAnnounceManagerOption,
  // fetchAnnounces,
  // getAnnouncesData,
  // clearAnnounceData,
  // getQuizManagerResults,
  // clearQuizQuestions,
  // clearQuizManagerResults,
  // getQuizResults,
  // resetAnnounceQuestion,
// } from "components/UI/Announce/ducks/announce";
// import {
  // setQuizManagerOption,
  // fetchQuizQuestions,
  // getQuizQuestions,
  // getQuizManagerResults,
  // clearQuizQuestions,
  // clearQuizManagerResults,
  // getQuizResults,
  // resetQuizQuestion,
// } from "components/UI/Quiz/ducks/quiz";
import {
  isLoggedIn,
  // getCurrentUserRole,
  getCurrentUser,
  // getToken,
} from "components/UI/Account/ducks/authentication";
import {
  sendMessage,
  // getContent,
  setEventState,
  getEventState,
  setAdminEventState,
  // getAdminEventState,
  fetchEventState,
} from "components/UI/Socket/ducks/relay";
// qid: 'c6b5279e681b7866afcc94d5fef27a3a',
// import i18n from "helpers/i18n";
// const AF_KEY = "home";
// const USE_WEBSOCKET = false;
const styles = (theme) => {
  // console.log(theme)
  // theme.palette.contrastThreshold = 0.1
  return {
    root: {
      minWidth: 500,
      "& .MuiDivider-vertical": {
        marginLeft: 10,
        marginRight: 10,
      },
      "& .MuiButton-root": {
        "& .MuiButton-label": {
          [theme.breakpoints.down("md")]: {},
        },
      },
    },
    popinPlaceholder: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.dialog && theme.palette.dialog.backgroundColor ? theme.palette.dialog.backgroundColor : undefined,
      color: theme.palette.dialog && theme.palette.dialog.color ? theme.palette.dialog.color : undefined,
    },
    leftColumn: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: theme.spacing(2),
      },
    },
    rightColumn: {
      [theme.breakpoints.down("md")]: {
        "& .MuiButton-root": {
          "& .MuiButton-label": {
            // padding: 0,
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        "& .MuiButton-root": {
          "& .MuiButton-label": {
            padding: 0,
            fontSize: "0.6rem",
          },
        },
      },
    },
    onAir: {
      fontWeight: "bold",
      marginBottom: 40,
      border: "1px " + theme.palette.primary.main + " solid",
      display: "inline-block",
      padding: "5px 10px",
    },
    paperWrapper: {
      paddingLeft: 20,
      paddingRight: 20,
      margin: "20px auto",
      maxWidth: 1200,
      width: "100%",
      padding: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
    questionWrapper: {
      marginBottom: theme.spacing(2),
    },
    question: {
      boxSizing: "inherit",
      cursor: "pointer",
      "& .MuiTypography-subtitle1": {
        fontSize: "1.2rem",
        lineHeight: 1.85,
        color: "inherit",
        textAlign: "right",
      },
      "& .MuiTypography-subtitle2": {
        fontSize: "1.2rem",
        lineHeight: 1.85,
        color: "inherit",
      },
      border: "1px " + theme.palette.secondary.light + " solid",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-body1": {
        color: "inherit",
      },
    },
    selected: {
      cursor: "default",
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: theme.palette.secondary.light,
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.secondary.light
        ),
      },
    },
    // correct: {
    //   backgroundColor: theme.palette.success.dark,
    //   color: theme.palette.getContrastText(theme.palette.success.dark),
    //   '& .MuiLinearProgress-colorPrimary': {
    //     backgroundColor: theme.palette.success.light
    //   },
    //   '& .MuiLinearProgress-barColorPrimary': {
    //     backgroundColor: theme.palette.getContrastText(theme.palette.success.dark)
    //   }
    // },
    // incorrect: {
    //   backgroundColor: theme.palette.error.dark,
    //   color: theme.palette.getContrastText(theme.palette.error.dark),
    //   '& .MuiLinearProgress-colorPrimary': {
    //     backgroundColor: theme.palette.error.light
    //   },
    //   '& .MuiLinearProgress-barColorPrimary': {
    //     backgroundColor: theme.palette.getContrastText(theme.palette.error.dark)
    //   }
    // },
    actions: {
      minHeight: 54,
    },
    log: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    // percentage: {
    //   fontSize: '1.6rem'
    // }
  };
};
class AdminOrchestratorFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: [],
      answered: undefined,
      logMessage: false,
      errored: false,
      activeTab: "messages",
      dialogDeleteState: undefined,
    };
  }
  componentDidMount() {
    const {
      // eventId,
      match,
    } = this.props;
    const { eventId } = match.params;
    this.props.onMount({
      eventId,
    });

  }

  componentWillUnmount() {
    this.props.onUnmount();
  }
  _onContentActivate = (questionId) => {
    // const { questionActive } = this.state;
    const {
      // eventId,
      match,
      // $$quizQuestions,
      $$eventState,
      setEventState,
    } = this.props;
    // const { activeTab } = this.state;
    const { eventId } = match.params;
    if ($$eventState.get('contentType') !== 'feedback') {
      const $$content = this._getItemFeedback(questionId);
      setEventState({
        eventId,
        contentId: $$content.get("id"),
        contentType: 'feedback',
      });
    } else {
      setEventState({
        eventId,
        contentId: "none",
        contentType: "none",
      });
    }
  };

  _onFeedbackReset = () => {
    // console.log('click')
    const { questionSelected } = this.state;
    this.setState({
      dialogResetState: Immutable.fromJS({ id: questionSelected, kind: 'feedback' }),
    });
  };
  _onFeedbackResetConfirmed = ($$item) => {
    const {
      // eventId,
      match,
      resetFeedback,
    } = this.props;
    const { eventId } = match.params;
    resetFeedback({
      eventId,
    });
    this._onFeedbackResetCanceled();
  };
  _onFeedbackResetCanceled = ($$item) => {
    this.setState({ dialogResetState: undefined });
  };


  _getItemFeedback = () => {
    const { eventId } = this.props.match.params;
    const { $$quizQuestions } = this.props;
    return Immutable.fromJS({
      quizParent: eventId,
      id: "feedback",
      quizType: "feedback",
      quizPosition:
      $$quizQuestions !== undefined ? $$quizQuestions.size + 1 : 1,
    });
  };

  render() {
    const {
      $$user,
      classes,
      // $$quizQuestions,
      // $$announceQuestions,
      // eventId,
      match,
      // $$results,
      $$eventState,
      history,
    } = this.props;
    const { eventId } = match.params;
    const {
      // errored,
      // choice,
      // showResults,
      // questionSelected,
      questionActive,
      // questionShowResults,
      // activeTab,
      // dialogDeleteState,
      dialogResetState,
    } = this.state;
    // const gridQuestionsSize = 4;
    // const $$event = Immutable.fromJS({
      // id: eventId,
    // });

    const userRole = $$user !== undefined ? $$user.get("role") : "visitor";
    // const userSubRole =
    // $$user !== undefined ? $$user.get("subRole") : undefined;
    const isAdmin = userRole === "admin";

    return (<Grid item xs={12} container>
      <Paper className={classes.paperWrapper}>
        <Box m={3} align="center">
          <Typography variant="h3" gutterBottom>
            Feedback
          </Typography>

          <If condition={isAdmin === true}>
            <Grid container justifyContent={"flex-end"}>
              <Button
                color="secondary"
                size="small"
                disabled={questionActive === "feedback"}
                onClick={(e) => {
                  e.preventDefault();
                  this._onFeedbackReset(questionActive);
                }}

                >
                Remise à zéro
              </Button>
              <Button
                color="secondary"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/staff/feedback/" + eventId + "/list");
                }}
                >
                Editer
              </Button>
            </Grid>
          </If>
          <If condition={$$eventState.get('contentType') === "feedback"}>
            <Typography variant="body1" gutterBottom>
              Le Feedback est actuellement <strong>activé</strong>,
              <br />
              cliquez ci-dessous pour le désactiver.
              <br />
              <br />
            </Typography>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                this._onContentActivate("feedback");
              }}
              >
              Désactiver le Feedback
            </Button>
          </If>
          <If condition={$$eventState.get('contentType') !== "feedback"}>
            <Typography variant="body1" gutterBottom>
              Le Feedback est actuellement <strong>désactivé</strong>,
              <br />
              cliquez ci-dessous pour l'activer.
              <br />
              <br />
            </Typography>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                this._onContentActivate("feedback");
              }}
              >
              Activer le Feedback
            </Button>
          </If>
        </Box>
      </Paper>
      <If
        condition={
          dialogResetState !== undefined &&
          dialogResetState !== false &&
          dialogResetState !== null
        }
        >
        <DialogConfirm
          open={true}
          title="Confirmation de remize à zéro"
          onConfirmed={() => {
            if (dialogResetState.get('kind') === 'quiz')
            this._onQuizResetConfirmed(dialogResetState);
            else if (dialogResetState.get('kind') === 'feedback')
            this._onFeedbackResetConfirmed(dialogResetState);
          }}
          onCanceled={() => {
            if (dialogResetState.get('kind') === 'quiz')
            this._onQuizResetCanceled()
            else if (dialogResetState.get('kind') === 'feedback')
            this._onFeedbackResetCanceled()
          }
        }
        >
        <If condition={dialogResetState.get('kind') === 'quiz'}>
          <Typography align="center" gutterBottom>
            Souhaitez vous remettre à zéro cette question ?<br /> Cela
            effacera toutes les réponses des participants.
          </Typography>
        </If>
        <If condition={dialogResetState.get('kind') === 'feedback'}>
          <Typography align="center" gutterBottom>
            Souhaitez vous remettre à zéro le feedback ?<br /> Cela
            effacera toutes les réponses des participants.
          </Typography>
        </If>
      </DialogConfirm>
    </If>
    {/*<Feedback eventId={eventId}/>*/}
  </Grid>
);
}
}

function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
    $$user: getCurrentUser(state),
    $$eventState: getEventState(state),
    // $$adminEventState: getAdminEventState(state),
    feedbackReset: getFeedbackReset(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      if (props.eventId !== undefined) {
        dispatch(fetchEventState(props));
      }
    },
    onUnmount: () => {
    },
    sendMessage: (payload) => {
      dispatch(sendMessage(payload));
    },
    resetFeedback: (props) => {
      dispatch(resetFeedback(props));
    },
    setEventState: (props) => {
      dispatch(setEventState(props));
    },
    setAdminEventState: (props) => {
      dispatch(setAdminEventState(props));
    },
  };
}
// export default ;
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AdminOrchestratorFeedback))
);
