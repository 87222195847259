import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';

const actionTypes = mirrorCreator(['SET_DASHBOARD_STATS', 'SET_DASHBOARD_ERROR', 'CLEAR_DASHBOARD_STATS', 'SET_EXPORTS_STATS', 'SET_EXPORTS_ERROR', 'CLEAR_EXPORTS_STATS'], { prefix: 'dashboard/' });

const mutators = {
  setDashboardStats: (data) => ($$state) => $$state.set('stats', data),
  setDashboardError: (error) => ($$state) => $$state.set('error', error),
  setExportsData: (data) => ($$state) => $$state.set('exports', data),
  setExportsError: (error) => ($$state) => $$state.set('error', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_DASHBOARD_STATS:
      acts.push(mutators.setDashboardStats(action.data));
      return pipe(acts, $$state);
    // break;
    case actionTypes.SET_DASHBOARD_ERROR:
      acts.push(mutators.setDashboardError(action.error));
      return pipe(acts, $$state);

      // break;
    case actionTypes.CLEAR_DASHBOARD_STATS:
      return pipe([mutators.setDashboardError(undefined), mutators.setDashboardStats(undefined)], $$state);

    case actionTypes.SET_EXPORTS_STATS:
      acts.push(mutators.setExportsData(action.data));
      return pipe(acts, $$state);
    // break;
    case actionTypes.SET_EXPORTS_ERROR:
      acts.push(mutators.setExportsError(action.error));
      return pipe(acts, $$state);

      // break;
    case actionTypes.CLEAR_EXPORTS_DATA:
      return pipe([mutators.setExportsError(undefined), mutators.setExportsData(undefined)], $$state);

      // break;
    default:
      return $$state;
      // break;
  }
}

export const getRoot = (state) => state.dashboard || Immutable.Map();
export const getDashboardStats = createSelector([getRoot], ($$state) => $$state.get('stats'));
export const getDashboardError = createSelector([getRoot], ($$state) => $$state.get('error'));
export const getExportsData = createSelector([getRoot], ($$state) => $$state.get('exports'));
export const getExportsError = createSelector([getRoot], ($$state) => $$state.get('error'));

export function fetchDashboardStats({kind}) {
  return (dispatch) => {
    const url = config.dashboard + '/stats/'+kind

    let formData = {};

    dispatch(clearDashboardStats());

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.stats && response.stats === 'complete') {
          dispatch(setDashboardStats(Immutable.fromJS(response.data)));
        } else {
          dispatch(setDashboardError(response.log));
        }
      })
      .catch((error) => dispatch(setDashboardError(error.message)));
  };
}

export function setDashboardStats(data) {
  return {
    type: actionTypes.SET_DASHBOARD_STATS,
    data,
  };
}
export function setDashboardError(error) {
  return {
    type: actionTypes.SET_DASHBOARD_ERROR,
    error,
  };
}
export function clearDashboardStats() {
  return {
    type: actionTypes.CLEAR_DASHBOARD_STATS,
  };
}
export function fetchExportsData({kind}) {
  return (dispatch) => {
    const url = config.dashboard + '/exports/'+kind

    let formData = {};

    dispatch(clearExportsData());

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.exports && response.exports === 'complete') {
          dispatch(setExportsData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setExportsError(response.log));
        }
      })
      .catch((error) => dispatch(setExportsError(error.message)));
  };
}

export function setExportsData(data) {
  return {
    type: actionTypes.SET_EXPORTS_STATS,
    data,
  };
}
export function setExportsError(error) {
  return {
    type: actionTypes.SET_EXPORTS_ERROR,
    error,
  };
}
export function clearExportsData() {
  return {
    type: actionTypes.CLEAR_EXPORTS_STATS,
  };
}
