import React, { Component } from "react";
import Dialog from "./Dialog";

class DialogWrapper extends Component {
  render() {
    const { open, onClose, title, children, titleStyle, showOnClose } = this.props;
    // console.log('meh', titleStyle)
    return (
      <Dialog
        showOnClose={showOnClose}
        open={open}
        title={title}
        onClose={onClose}
        titleStyle={titleStyle}
      >
        {children}
      </Dialog>
    );
  }
}

class DialogPopinButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popinIsOpen: false,
    };
    this._onPopupOpen = this._onPopupOpen.bind(this);
    this._onPopupClose = this._onPopupClose.bind(this);
  }

  componentDidMount() {
    if (this.props.openOnMount === true) this._onPopupOpen();
  }
  componentDidUpdate() {}
  _onPopupOpen() {
    const { popinIsOpen } = this.state;
    this.setState({ popinIsOpen: !popinIsOpen }, () => {
      if (this.props.onOpened) this.props.onOpened();
    });
  }
  _onPopupClose(args) {
    this.setState({ popinIsOpen: false });
    // console.log('go1')
    if (this.props.onClosed) {
      // console.log('go2')
      this.props.onClosed(args);
    }
  }

  render() {
    const {
      trigger,
      popinTitle,
      popinTitleStyle,
      popinContent,
      popinContentProps,
      showOnClose,
    } = this.props;

    const { popinIsOpen } = this.state;
    let triggerProps = {
      ...this.props.triggerProps,
    };
    if (!this.props.triggerProps.onClick)
      triggerProps.onClick = this._onPopupOpen;

    // console.log('trigger props are', triggerProps)
    // console.log('RAW PROPS', popinContentProps)
    let contentProps = {
      ...(popinContentProps !== undefined ? popinContentProps : {}),
      ...{
        onClose: this._onPopupClose,
      },
    };
    // console.log(trigger, triggerProps)
    // console.log('FINAL PROPS', popinTitleStyle)
    return (
      <React.Fragment>
        {React.createElement(DialogWrapper, {
          open: popinIsOpen,
          showOnClose,
          onClose: (e) => this._onPopupClose(),
          title: popinTitle,
          titleStyle: popinTitleStyle,
          children: React.createElement(popinContent, contentProps),
        })}
        {React.createElement(trigger, triggerProps)}
      </React.Fragment>
    );
  }
}

// const withPopin = (subComponent) => {
//   return (props) => <WithPopin component={subComponent} initialProps={props} />
// }

// export default withPopin
export default DialogPopinButton;
