import React from 'react';
import { connect } from 'react-redux';
import config from 'config/api';
import Box from '@material-ui/core/Box';
import DynamicList from 'components/UI/DynamicList';
import Button from '@material-ui/core/Button';
import EventListEmbed from 'components/UI/Event/EventListEmbed';
import Typography from "@material-ui/core/Typography";
import { getCurrentUserRole } from 'components/UI/Account/ducks/authentication';
import { withRouter } from 'react-router-dom';

class AdminAccountList extends React.Component {
  // Analyser la route actuelle, et determiner si on doit afficher le form ou la list

  // Pour l'instant on affiche que le form
  render() {
    const moduleConfig = {
      module: 'account',
      fetchUrl: config.account + '/list',
      deleteUrl: config.account + '/delete',
      // title: 'Liste des comptes',
      filter: this.props.filter,
      showFilters: this.props.showFilters,
      editUrl: 'staff/account/edit',
    };
    const panels = [
      {
        module: 'Events',
        label: 'Webconfs',
        component: EventListEmbed,
      },
    ];
    const { embed, userRole } = this.props;
    // console.log('USER ROLE ?', userRole)
    return (
      <div className="">
        <If condition={embed !== true }>
          <Box align="center" mt={3} mb={2} px={2}>
            <Typography variant="h2" gutterBottom>Professionnels de santé</Typography>
              <If condition={ userRole === 'admin'}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push('/staff/account/edit');
              }}
            >
              Ajouter
            </Button>
            </If>
          </Box>
        </If>
        <DynamicList moduleConfig={moduleConfig} panels={panels} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}

export default withRouter(connect(mapStateToProps)(AdminAccountList));
