import React from "react";
import clsx from "clsx";
import {
  withStyles
} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MUICheckbox from "@material-ui/core/Checkbox";
import Paragrapher from 'components/UI/Paragrapher/Paragrapher'
const styles = (theme) => ({
  root: {
    // margin: theme.spacing(3),
    '& a': {
      color: 'inherit'
    },
    '& .MuiTypography-body1': {
      color: 'inherit'
    }
  },
  error: {
    color: theme.palette.error.main
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  radioLabel: {
    color: "#000",
  },
  checkbox: {
    textAlign: "left",
    paddingLeft: 10,
    color: 'inherit',
  },
  // checkboxText: {
  // color: 'red'
  // }
});
class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "off",
    };
    this._onChange = this._onChange.bind(this);
  }
  componentDidMount() {
    this.setState({
      value: this.props.value||this.props.defaultValue,
      // value: this.props.value,
    });
  }
  componentDidUpdate(oldProps) {
    if(oldProps.value !== this.props.value) {
      // console.log('FIELD VALUE CHANGED !!',oldProps.value, this.props.value)
      this.setState({
        value: this.props.value,
      });
    }
  }
  _onChange(e) {
    const {
      onChange
    } = this.props;
    const {
      value
    } = this.state;
    const t = e.target;
    // console.log({checked: t.checked, value: t.value})
    this.setState({
      value: t.value
    }, function() {
      // console.log(t)
      if(onChange) onChange("input", {
        id: t.name,
        value: value === "on" ? "off" : "on",
      });
    });
  }
  render() {
    const {
      id,
      // name,
      label,
      // type,
      // variant,
      // margin,
      required,
      fullWidth,
      // required,
      disabled,
      classes,
      // options,
      // rows,
      error,
      errorMessage
    } = this.props;
    // console.log({required, error, errorMessage})
    const {
      value
    } = this.state;
    return (<FormControl
        component="fieldset"
        className={classes.root}
        fullWidth={fullWidth}
        disabled={disabled}
        error={error}
        required={required}
      >
        {/*        <FormLabel component="legend"><Paragrapher prettyfy={true} text={label} /></FormLabel>*/}

        <FormControlLabel
          control={
            <MUICheckbox
              id={id}
              name={id}
              checked={value === "on" ? true : false}
              onChange={this._onChange}
              value="on"
              color="primary"
              className={classes.checkbox}
            />
          }
          classes={{label: clsx({[classes.error]: errorMessage !== false && errorMessage !== undefined})}}
          label={<Paragrapher prettyfy={true} text={label+(required? ' *' : '')} elementClass={classes.checkboxText}/>}
        />
      <If condition={errorMessage !== false && errorMessage !== undefined}>
      <FormHelperText>Vous devez cocher la case pour pouvoir continuer</FormHelperText>
      </If>

      </FormControl>);
  }
}
// export default Checkbox;
export default withStyles(styles)(Checkbox);
