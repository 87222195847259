import React from "react";
import Dropzone from "react-dropzone";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Immutable from "immutable";
import Dialog from 'components/UI/Dialog/Dialog'
import isArray from "mout/lang/isArray";
import remove from "mout/array/remove";
import Input from './Input'
import FileIcon from "@material-ui/icons/InsertDriveFile";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Spinner from "components/UI/Spinner";
import Button from "@material-ui/core/Button";
const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      // maxWidth: "1280px",
      // border: '1px #ccc solid',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      margin: "0 0",
      textAlign: 'left',
      wordWrap: 'break-word',
      '& a': {
        //color: theme.palette.primary.main,
        textDecoration: 'none'
      },
      // '&:hover': {
      //   borderColor: theme.palette.text.main,
      // }
    },
    filePreviewWrapper: {
      boxShadow: theme.shadows[1],
      padding: theme.spacing(1),
    },
    dialogButtons:{
      '& .MuiButtonBase-root':{
          marginRight:'2rem',
      },
    },
  }
}
class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      values: [],
      uploadAttributes: [],
      uploading: false,
      complete: false,
      popinOpen: false
    };
  }
  componentDidMount() {
    // const {id, onChange} = this.props;
    const initialState = this.getInitialState(this.props.value);
    // console.log({initialState})
    this.setState(initialState); // must keep!

  }
  componentDidUpdate(oldProps, oldState) {
    const {id, onChange} = this.props;
    if ((oldProps.value === undefined && this.props.value !== undefined) || (oldProps.value !== undefined && oldProps.value !== this.props.value)) {
      // console.log(id, 'didupdate debug', {old: oldProps.value, new: this.props.value, state: this.state})
      const initialState = this.getInitialState(this.props.value);
      this.setState(initialState, () => {
        if (onChange)
        onChange("upload", {
          id,
          value: JSON.stringify(initialState.values)
          // value: initialState.values,
        });
      });
      // this.setState((state, props) => {
      //   return {...initialState}
      // }, () => {
      //   console.log('changed')
      //   if (onChange)
      //   onChange("upload", {
      //     id,
      //     value: JSON.stringify(initialState.values)
      //     // value: initialState.values,
      //   });
      // });
      // setTimeout(() => {
      //   console.log('delayed')
      // }, 1000)
    }
  }
  getInitialState = (value) => {
    // const initValues = props.value;
    const files = [];
    const values = [];
    let decodedValues = [];
    try {
      decodedValues = JSON.parse(value);
    } catch (e) {
      // console.log('cannot decode!', value)
      // console.log(e)

    }
    if (decodedValues && isArray(decodedValues)) {
      decodedValues.forEach((val) => {
        files.push(val);
        values.push(val);
      });
    }
    return {
      files,
      values,
      uploading: false,
      complete: false,
    };
  }
  _upload() {
    const { id, onChange, maxFiles, uploadAttributes } = this.props;
    // console.log(this.props)
    var uploadUrl = this.props.url + (this.props.multiple ? "&m=1" : "");

    this.setState({ uploading: true });
    const file = this.state.toUpload[0];
    if (!file) {
      console.log("hum, no file?!");
      return;
    }
    // console.log('the files is', file)
    let reader = new FileReader();

    reader.onload = (e) => {
      var formData = new FormData();
      formData.append("dataURL", reader.result);
      formData.append("fileName", file.name);
      formData.append("fileType", file.type);
      formData.append("fileSize", file.size);
      return fetch(uploadUrl, {
        credentials: "include",
        method: "POST",
        headers: {
          Accept:
          "application/json, application/xml, text/plain, text/html, *.*",
          "X-Requested-With": "XMLHttpRequest", // needed by php api
        },
        body: formData,
      })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ complete: true });
        this.setState({ uploading: false });
        if (response.file) {
          let file = response.file;
          file.isTmp = true;
          let files = this.state.files;
          let values = this.state.values;

          let attributes = {}
          if (uploadAttributes !== null){
            uploadAttributes.map(($$ua, uIdx) => {
              console.log('STATE VALUE', this.state['ua_'+$$ua.get('id')])
              attributes[$$ua.get('id')] = this.state['ua_'+$$ua.get('id')];
              return true;
            })
          }
          file.attributes = attributes;
          files.push(file);
          values.push(file);
          if (files.length > maxFiles) {
            files.shift();
            values.shift();
          }
          let newState = { files, values }

          if (uploadAttributes !== null){
            uploadAttributes.map(($$ua, uIdx) => {
              newState['ua_'+$$ua.get('id')] = '';
              return true;
            })
          }
          newState.popinOpen = false;

          this.setState(newState, () => {
            if (onChange)
            onChange("upload", {
              id,
              // value: JSON.stringify(values)
              value: JSON.stringify(values),
            });
          });
        }
      });
    };
    reader.readAsDataURL(file);

  }
  onDrop(acceptedFiles) {
    this.setState(
      {
        toUpload: acceptedFiles,
      },
      this._upload
    );
  }
  onOpenClick(event) {
    event.preventDefault();
    if (this.state.uploading) return;
    this.dropzone.open();
  }
  onItemClick(event, file) {
    let url;
    if (file.isTmp) url = this.props.tmpUrl + "/" + file.dest;
    else url = this.props.previewUrl + "/" + file.dest;

    if (url !== "") window.open(url);
  }
  onRemoveFile = (event, fileRow) => {
    // console.log('file?', fileRow)
    event.preventDefault();
    event.stopPropagation();
    if (this.props.disabled) return;
    const { id, onChange } = this.props;
    const {values: originalValues, files: originalFiles} = this.state;
    const values = [ ...originalValues ];
    const files = [ ...originalFiles ];
    remove(values, values[fileRow])
    remove(files, files[fileRow])
    this.setState({ files, values }, () => {
      if (onChange)
      onChange("upload", {
        id,
        value: JSON.stringify(values)
      });
    });

  };

  onEditFile = (event, fileRow) => {
    event.preventDefault();
    event.stopPropagation();
    const { uploadAttributes } = this.props;
    if (uploadAttributes !== null){
      const { files } = this.state;
      const file = files[fileRow]
      const attributes = file.attributes
      let newState = {}
      uploadAttributes.map(($$ua, uIdx) => {
        newState['ua_'+$$ua.get('id')] = attributes[$$ua.get('id')];
        // console.log('reseting', $$ua.get('id'), newState)
        return true;
      })
      newState.ua_file = file;
      newState.ua_index = fileRow;
      newState.editMode = true;
      newState.popinOpen = true;
      this.setState(newState)
    }

    return;
  };

  onSaveClick() {
    const {ua_index} = this.state;
    // const uploadAttributes = ua_file.attributes
    const {files} = this.state;
    const {id, onChange, uploadAttributes} = this.props;

    if (ua_index === undefined) return;

    // console.log({uploadAttributes})
    const file = files[ua_index]
    let newState = {}, newFile = {...file}
    if (uploadAttributes !== null){
      uploadAttributes.map(($$ua, uIdx) => {
        newFile.attributes[$$ua.get('id')] = this.state['ua_'+$$ua.get('id')];
        newState['ua_'+$$ua.get('id')] = '';
        return true;
      })
    }
    newState.files = [...this.state.files];
    newState.values = [...this.state.values];
    newFile.name = this.state.ua_img_name;
    newState.files[ua_index] = newFile;
    newState.values[ua_index] = newFile;
    newState.ua_file = undefined
    newState.ua_index = undefined
    newState.popinOpen = false;
    // console.log('edit new state', newState)
    this.setState(newState, () => {
      if (onChange)
      onChange("upload", {
        id,
        // value: JSON.stringify(values)
        value: JSON.stringify(newState.values),
      });
    });

  }
  onCancel() {
    console.log("CANCEL CANCEL CANCEL");
  }

  _isImage(file) {
    var r = /((?:\.jpg)|(?:\.png)|(?:\.gif)|(?:\.jpeg)){1}$/g;
    return r.test(file.dest);
  }
  _onUploadAttributeChange = ($$ua, o) => {
    this.setState({['ua_'+$$ua.get('id')]: o.value})
    // console.log($$ua.get('id')+' changed!')
  }
  _closePopin = () => {
    this.setState({popinOpen: false})
  }
  _openPopin = () => {
    this.setState({popinOpen: true})
  }
  render() {
    const {
      disabled,
      readOnly,
      classes,
      uploadAttributes,
      variant,
    } = this.props;
    const { files, popinOpen } = this.state;

    const fileIconStyle = {
      display: "inline-block",
      verticalAlign: "middle",
      marginTop: "-2px",
      marginRight: "5px",
    };

    const ulFileSrc = this.state.ua_file ? (
      (this.state.ua_file.isTmp
        ? this.props.previewTmpUrl
        : this.props.previewUrl) +
        "/" +
        this.state.ua_file.dest
      ) : undefined;
      const ulFileName = this.state.ua_file ? (this.state.ua_file.name) : undefined;

      return (
        <div className={classes.root}>
          <If condition={readOnly !== true && disabled !== true}>
            <If condition={uploadAttributes !== null}>
              <If condition={popinOpen !== true}>
              <Button variant="contained" color="primary" size="small" onClick={this._openPopin}>{this.props.label} +</Button>
              </If>
              <Dialog closeButton={false} disableBackdropClick={true} open={popinOpen}>
                <Grid container>
                  <Grid item xs={12}>
                    <If condition={ulFileSrc !== undefined}>
                      <Typography variant="h2" align="center" gutterBottom={true}>Edition {this.props.label}</Typography>
                    </If>
                    <If condition={ulFileSrc === undefined}>
                      <Typography variant="h2" align="center" gutterBottom={true}>Upload {this.props.label}</Typography>
                    </If>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-around"
                    >
                    <If condition={!this.state.uploading}>
                      <If condition={ulFileSrc !== undefined}>
                        <Grid item xs={12}><img src={ulFileSrc} alt="preview"/></Grid>
                        <Grid item xs={12}><Input name={'img_name'} id={'img_name'} label={'Nom du fichier'} value={ulFileName === null ? '' : ulFileName}  onChange={(kind, o) => {this._onUploadAttributeChange( Immutable.fromJS({id: 'img_name'}), o)}} variant={variant}/></Grid>
                      </If>
                      <If condition={uploadAttributes !== null}>
                        {uploadAttributes.map(($$ua, uIdx) => {
                          return (<Grid key={'ia_'+uIdx} item xs={6}>
                          <Input name={$$ua.get('id')} id={$$ua.get('id')} label={$$ua.get('label')} value={this.state['ua_'+$$ua.get('id')] === null ? '' : this.state['ua_'+$$ua.get('id')]}  onChange={(kind, o) => {this._onUploadAttributeChange( $$ua, o)}} variant={variant}/>
                        </Grid>)
                      })}
                    </If>
                    <Grid item xs={12} container justifyContent="center" className={classes.dialogButtons}>
                      <Button color="primary" variant="contained"
                        onClick={this._closePopin.bind(this)}
                        >Annuler</Button>
                      <If condition={ulFileSrc !== undefined}>
                        <Button color="primary" variant="contained"
                          onClick={this.onSaveClick.bind(this)}
                          >Enregistrer</Button>
                      </If>
                      <If condition={ulFileSrc === undefined}>
                        <Button color="primary" variant="contained"
                          onClick={this.onOpenClick.bind(this)}
                          >Parcourir</Button>
                      </If>
                    </Grid>
                  </If>
                  <If condition={this.state.uploading}>
                    <Grid item xs={12}>
                      <Box w={150} align="center">
                        <div
                          style={{
                            width: "auto",
                            textAlign: "center",
                          }}
                          >
                          <Spinner
                            size={18}
                            style={{
                              width: 40,
                              height: 40,
                              display: 'block',
                              margin: "0 auto 10px",
                            }}
                            />
                          Upload en cours
                        </div>
                      </Box>
                    </Grid>
                  </If>
                  <Choose>
                    <When condition={this.state.uploading && this.state.toUpload > 0}>
                      <Grid item xs={12}>
                        <span>Uploading {this.state.files.length} file(s)...</span>
                      </Grid>
                    </When>
                  </Choose>
                </Grid>
              </Grid>
            </Dialog>
          </If>
          <If condition={uploadAttributes === null}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-around"
              >
              <If condition={!this.state.uploading}>
                <Grid item xs={12}>
                <Button
                  size="small"
                  variant="contained" color="primary"
                  onClick={this.onOpenClick.bind(this)}
                  >{this.props.label}{this.props.multiple ? ' +' : ''}</Button>
              </Grid>
            </If>
            <If condition={this.state.uploading}>
              <Grid item xs={12}>
                <Box w={150} align="center">
                  <div
                    style={{
                      width: "auto",
                      textAlign: "center",
                    }}
                    >
                    <Spinner
                      size={18}
                      style={{
                        width: 40,
                        height: 40,
                        display: 'block',
                        margin: "0 auto 10px",
                      }}
                      />
                    Upload en cours
                  </div>
                </Box>
              </Grid>
            </If>
            <Choose>
              <When condition={this.state.uploading && this.state.toUpload > 0}>
                <Grid item xs={12}>
                  <span>Uploading {this.state.files.length} file(s)...</span>
                </Grid>
              </When>
            </Choose>
          </Grid>
        </If>
      </If>
      <If condition={readOnly !== true && disabled !== true}>
          <Dropzone
            ref={(node) => {
              this.dropzone = node;
            }}
            onDrop={this.onDrop.bind(this)}
            onUploadStart={() => {console.log('uploading')}}
            onFileDialogCancel={this.onCancel.bind(this)}
            >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </If>
        <Choose>
          <When condition={files.length > 0}>
            <Box mt={2}>
              <Grid
                container
                spacing={0}
                alignItems="flex-start"
                justifyContent="flex-start"
                >
                {files.map((file, idx) => {
                  // console.log('istmp', file.isTmp)
                  const imgSrc =
                  (file.isTmp
                    ? this.props.previewTmpUrl
                    : this.props.previewUrl) +
                    "/" +
                    file.dest;
                    const isImage = this._isImage(file);
                    return (
                      <Grid item key={"file_" + idx} xs={12} sm={files.length > 1 ? 6 : 12} md={files.length > 1 ? 4 : 12} container className={classes.filePreviewWrapper} spacing={1}>
                        <If condition={isImage}>
                          <Grid item xs={6} >
                            <img
                              src={imgSrc}
                              alt="img"
                              width="95%"
                              style={{ verticalAlign: "middle", marginRight: 5 }}
                              />
                          </Grid>
                        </If>
                        <Grid item xs={isImage ? 6 : 12}>
                          <a href={imgSrc} target="_blank">
                            <If condition={!isImage}>
                              <FileIcon style={fileIconStyle} />
                            </If>
                            {file.name}</a> ({Math.round(file.size / 1000 / 1000*100)/100 + " Mb"})
                            <IconButton size="small" onClick={(e) => {this.onRemoveFile(e, idx)}}><IconDelete size="small"/></IconButton>&nbsp;
                            <If condition={uploadAttributes !== null}>
                            <IconButton size="small" onClick={(e) => {this.onEditFile(e, idx)}}><IconEdit size="small"/></IconButton><br />
                            </If>
                            <If condition={uploadAttributes !== null && file.attributes}>
                              {uploadAttributes.map(($$ua, uIdx) => {
                                return (<Typography variant="body1" key={'at_'+uIdx}>{$$ua.get('label')}: {file.attributes[$$ua.get('id')]}</Typography>)
                              }).toArray()}
                            </If>

                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </When>
              <When
                condition={
                  files.length < 1 && (readOnly === true || disabled === true)
                }
                >
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justifyContent="space-around"
                  >
                  <Grid item xs={12} md={6} lg={4}>
                    <em
                      style={{
                        width: "100%",
                        display: "block",
                        overflow: "hidden",
                        padding: "20px",
                      }}
                      >
                      Aucun fichier
                    </em>
                  </Grid>
                </Grid>
              </When>
            </Choose>
          </div>
        );
      }
    }
    export default withStyles(styles)(Upload);
