import React, { StrictMode } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CachedIcon from '@material-ui/icons/Cached';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';

import OfferSelectFilter from './OfferSelectFilter';
import OfferFilterDialogButton from './OfferFilterDialogButton';
import OfferLocationFilter from './OfferLocationFilter';

import { resetFilters } from './ducks/offer';

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      /*backgroundColor: 'green',*/
    },
    [theme.breakpoints.down('sm')]: {
      /*backgroundColor: 'blue',*/
    },
    [theme.breakpoints.down('xs')]: {
      /*backgroundColor: 'red',*/
    },
    '& .MuiInputBase-root': {},
  },
  addOfferButton: {
    backgroundColor: '#F88D5E !important',
    borderColor: '#F88D5E !important',
    '&:hover': {
      backgroundColor: 'white !important',
      borderColor: '#F88D5E !important',
      color: '#F88D5E !important',
    },
  },
});

class OfferFilters extends React.Component {
  handleResetClick = (e) => {
    this.props.resetFilters();
  };

  handleNewOfferClick = (e) => {
    e.preventDefault();
    this.props.history.push('/offers/new');
  };

  render() {
    const { $$selects, addOfferButton, classes } = this.props;
    const categoryOptions =
      $$selects && $$selects.get('options') ? $$selects.get('options').get('offerCategory').toJS() : [];
    const xs = 9,
      sm = 6,
      md = 4,
      lg = 3;
    //<Box display="flex" style={{ gap: 16 }} className={classes.main}>*
    return (
      <Grid
        container
        columns={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
        spacing={2}
      >
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <OfferSelectFilter
            name="offerCategory"
            label="Catégorie"
            placeholder="Sélectionnez une catégorie"
            options={categoryOptions}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <OfferLocationFilter />
        </Grid>
        <Grid item xs={xs} sm={sm} md={2} lg={lg} style={{ whiteSpace: 'nowrap' }}>
          <OfferFilterDialogButton $$selects={$$selects} />
          &nbsp;
          <Button
            variant="contained"
            color="secondary"
            onClick={this.handleResetClick}
            startIcon={<CachedIcon />}
          >
            Réinitialiser
          </Button>
        </Grid>
        <If condition={addOfferButton}>
          <Grid item xs={xs} sm={sm} md={md} lg={lg}>
            <Button
              variant="contained"
              color="primary"
              className={classes.addOfferButton}
              onClick={this.handleNewOfferClick}
              startIcon={<AddIcon />}
            >
              Je dépose une annonce
            </Button>
          </Grid>
        </If>
      </Grid>
    );
  }
}

export default withRouter(connect(undefined, { resetFilters })(withStyles(styles)(OfferFilters)));
