import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
// import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import getCardGuests from 'components/UI/helpers/getCardGuests';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import config from 'config/api';
import Collapse from '@material-ui/core/Collapse';
import Paragrapher from "components/UI/Paragrapher/Paragrapher"
import { withRouter } from "react-router-dom";
import i18n from "helpers/i18n";
import DateRangeIcon from '@material-ui/icons/DateRange';
import { formatDate } from 'helpers/stringUtils';
//import getCardImage from 'helpers/getCardImage';
import getThumb from 'components/UI/helpers/thumb';
import Grid from '@material-ui/core/Grid';
import { fetchNews, getNewsData } from 'components/UI/News/ducks/news';
import apiFetch from "components/UI/helpers/fetch";

import clsx from 'clsx';
import Card from '@material-ui/core/Card';
// import Hidden from '@material-ui/core/Hidden';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import BlockButton from 'components/UI/BlockButton';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    //maxWidth: 1280,//maj fullwidth
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: theme.spacing(3),
    position: 'relative',
    color: theme.palette.news.color,
    '& .MuiTypography-h2': {
      color: theme.palette.news.titleColor || theme.palette.news.color,
      fontSize: '2.1875rem',
      lineHeight: '2.8125rem',
      marginBottom: theme.spacing(2),
      marginLeft:'unset',//maj fullwidth
      marginRight:'unset',//maj fullwidth
      [theme.breakpoints.down('md')]: {
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
        // maxWidth: '50%',
      }

    },
    '& .MuiTypography-h3': {
      fontSize: '1rem',
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    '& .MuiTypography-caption': {
      color: '#fff',
      // backgroundColor: theme.palette.news.captionColor || theme.palette.news.color,
      // padding: '3px 6px'
    },
    '& .MuiTypography-body1': {
      // color: theme.palette.primary.main,
      // marginBottom: theme.spacing(2)
      '& a': {
        color: 'inherit'
      }
    },
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: theme.palette.primary.main,
      color: '#eee',
      opacity: .4,
    },
    '& .MuiButton-root.MuiButton-containedPrimary': {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },  
    }
  },
  wrapper: {
    backgroundColor: theme.palette.newsWrapper && theme.palette.newsWrapper.backgroundColor ? theme.palette.newsWrapper.backgroundColor : 'transparent',
    backgroundImage: theme.palette.newsWrapper && theme.palette.newsWrapper.backgroundImage ? theme.palette.newsWrapper.backgroundImage : 'none',
    backgroundSize: 'cover',

    width: '100%',
    //maxWidth: 1280,//maj fullwidth
    marginLeft: 'auto',
    marginRight: 'auto',
    transition: 'background-color .5s ease-out',
    padding:'0 1%',//maj fullwidth
    [theme.breakpoints.down('sm')]: {
      //padding:'0 2%',//maj fullwidth
    },
    [theme.breakpoints.down('xs')]: {
      padding:'0',//maj fullwidth
    },
  },
  innerWrapper:{
    //maxWidth: 1350,//maj fullwidth
    margin:'0 auto',
  },
  card: {
    cursor:'pointer',
    height: '100%',
    //maxWidth: 1350,//maj fullwidth
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    // paddingLeft: theme.spacing(6),
    // paddingRight: theme.spacing(6),
    backgroundColor: 'transparent',
    '& .MuiCardContent-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      // textAlign: 'center',
      // paddingLeft: theme.spacing(4),
      // paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      // textAlign: 'center',
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      // textAlign: 'center',
      // paddingLeft: theme.spacing(1.5),
      // paddingRight: theme.spacing(1.5),
    },
    newsContentWrapper: {}
  },
  newsContentWrapper: {
    marginTop:'.5em',
    '& [class*="MuiTypography-h2"]': {
      color: '#fff',
      textTransform:'none',
      textAlign:'left',
      fontFamily: 'Montserrat',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '140%',
      marginBottom:'.3em',
      marginTop:'.3em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        marginBottom:'.1em',
        marginTop:'.1em',
        }
      },
    [theme.breakpoints.down('xs')]: {
      //marginLeft:theme.spacing(1),
    }
  },
  // title: {
  //   color: (process.env.REACT_APP_SITE === 'neuro' ? theme.palette.primary.main : theme.palette.text.titleColor),
  //   textShadow: (process.env.REACT_APP_SITE === 'hemato' ? '1px 1px 2px '+theme.palette.text.shadowColor : 'none'),
  //   display: 'block',
  //   width: '100%',
  //   textAlign: 'left',
  //   fontSize: '1.6rem',
  //   lineHeight: '1.9rem',
  //   textTransform: 'uppercase',
  //   borderBottom: '2px '+(process.env.REACT_APP_SITE === 'neuro' ? theme.palette.primary.main : theme.palette.text.titleColor)+' solid',
  //   paddingBottom: theme.spacing(2),
  //   marginBottom: theme.spacing(1),
  //   color: theme.palette.primary.main,
  //   [theme.breakpoints.down('md')]: {
  //     fontSize: '1.5rem',
  //     lineHeight: '1.8rem',
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     fontSize: '1.3rem',
  //     lineHeight: '1.5rem',
  //     textAlign: 'center',
  //     paddingRight: 0,
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     // fontSize: '1.3rem',
  //     // lineHeight: '2rem',
  //   },
  //
  //   '& em': {
  //     fontStyle: 'normal',
  //     backgroundColor: '#fff',
  //     color: theme.palette.primary.main,
  //   },
  // },
  // header: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   height: 50,
  //   paddingLeft: theme.spacing(1),
  //   // backgroundColor: theme.palette.background.default,
  // },
  newsWrapper: {
    backgroundColor: theme.palette.news && theme.palette.news.backgroundColor ? theme.palette.news.backgroundColor : '#B4DFF0',
    // width: 'calc(100% - '+theme.spacing(8)+'px)',
    [theme.breakpoints.down('xs')]: {
      //flexDirection: 'column-reverse'//maj fullwidth
    }
  },
  newsWrapperPadded: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      }
  },
  newsSeparator: {
    height: '1rem',
    borderRight: '1px #1D202126 solid',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'inline-block',
    verticalAlign: 'middle',
    width: 1,
    [theme.breakpoints.down('sm')]: {
      //marginBottom: theme.spacing(2)
    }

  },
  newsHeader: {
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textTransform: 'uppercase',
    '& .MuiTypography-caption': {
      color: '#fff',
      backgroundColor: theme.palette.news.captionColor || theme.palette.news.color,
      padding: '3px 6px',
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        //marginBottom: theme.spacing(2)
      }
    },
    '& .MuiTypography-body1': {
      color: theme.palette.text.main,
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: 0,
      fontSize: '0.8125rem',
      // marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        //marginBottom: theme.spacing(2)
      }
    }
  },
  //TODO newsHeight : il faudrait refaire le composant pour qu'il ne soit plus dépendant d'un taille fixe, marche mal avec les nouveaux besoins image full + responsive
  mediaWrapper: {
    minHeight:config.newsHeight+'vw',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight:'unset',
    },
  },
  media: {
    minHeight:config.newsHeight+'vw',
    width: '100%',
    maxHeight:'200px',
    objectFit: 'cover',
    //objectFit: 'contain',
    width: 'auto',
    maxWidth: '45vw',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
      maxWidth: '50vw',
    },
    [theme.breakpoints.down('xs')]: {
        marginBottom: '0',
        display:'none',
    },
},
  mediaFull: {
    minHeight:config.newsHeight+'vw',
    maxWidth: 'unset',
    width: '100%',
    maxHeight:'200px',
    objectFit: 'cover',
    //objectFit: 'contain',
    //width: '100vw',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
    },
  },
  mobileStepper: {
    marginTop: 0,
    paddingTop: 0,
    justifyContent: 'center',
    width: 'auto',
    display: 'inline-block',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    //bottom: 0,
    backgroundColor: 'transparent',
    //top: '202px',//88
    //top:(config.newsHeight/2-12)+'px',
    top:(config.newsHeight/2-1)+'vw',
  },
  guests: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },  
  '&:after': {
      content: "''",
      display: 'block',
      clear: 'both',
    },
    '& .MuiTypography-body1':{
      fontSize:'0.9rem',
      [theme.breakpoints.down('sm')]: {
        fontSize:'0.75rem',
      },  
    },
  },
  buttonWrapper:{
    margin:'0 auto',
    //maxWidth:'1350px',
    position: 'relative',
    //top: '-215px',//100
    //top:(-config.newsHeight/2)+'px',
    top:(-config.newsHeight/2)+'vw',
  },
  buttonNav: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: '#fff',
    zIndex: '9',
    boxShadow: theme.shadows[4],
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#fff'
    },
    '&$disabled': {
      backgroundColor: '#fff',
    },
    '& .MuiSvgIcon-root': {
      margin: 0
    }
  },
  background: {
    // backgroundColor: '#B4DFF0'
    width: '100%',
    //maxWidth: 1280,
    marginLeft: 'auto',
    marginRight: 'auto',

  },
  buttonLeft: {
    //maj fullwidth
    //left: 0,
    //left: '1%',
    //

    [theme.breakpoints.down('md')]: {
      //left: '-10px',//maj fullwidth
    },
    [theme.breakpoints.down('sm')]: {
      left: '3%',
      //left: '-10px',//maj fullwidth
      '& .MuiSvgIcon-root': {
        //fontSize: '1rem'
      }
    },
    [theme.breakpoints.down('xs')]: {
        width: 22,
        height: 22,
        left: '1%',
        fontSize: '1rem',
      },
  },
  buttonRight: {
    //maj fullwidth
    //right: 0,
    right: '5px',
    //

    [theme.breakpoints.down('md')]: {
      //right: '-10px',//maj fullwidth
    },
    [theme.breakpoints.down('sm')]: {
      right: '3%',
      //right: '-10px',//maj fullwidth
      '& .MuiSvgIcon-root': {
        //fontSize: '1rem'
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: 22,
      height: 22,
      right: '1%',
      fontSize: '1rem',
    },
  },
  buttonLegend: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1)
  }

});

class News extends React.Component {
  state = {
    activeStep: 0,
  };

  componentDidMount() {
    this.props.onMount();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.universe === undefined && this.props.universe !== undefined) {
      this.props.onMount();
    }
  }

  handleNext = (e) => {
    e.stopPropagation();
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = (e) => {
    e.stopPropagation();
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  _toggleCollapse = function(id) {
    const cur = this.state['collapsed_'+id] === true ? true : false;
    // console.log(cur);
    this.setState({
      ['collapsed_'+id]: cur ? false : true
    })
  }
  _getNewsImage = (step) => {
    if (step.get('newsThumb') === '' || step.get('newsThumb') === undefined) return undefined;
    let image = JSON.parse(step.get('newsThumb'));
    image = image !== undefined && image !== null && image[0] !== undefined? config.newsthumbpreview.replace('{newsId}', step.get('id'))+'/'+image[0]['dest'] : undefined;
    return image;
  }

  onClick=(event, news)=>{
    event.stopPropagation();
    const id=news.get('id');
    const categ=news.get('newsCategory') ;
    console.log({categ});
    let url;
    if(categ=== 'replay'){
      const eventId=news.get('eventId');
      url='/replays/'+eventId;
    }else if(categ=== 'event'){
      const state=news.get('eventState');
      const eventId=news.get('eventId');
      console.log({state});
      if(state=='live')
        url='/live/'+eventId;
    } else {
      url=news.get('newsLinkExternal');
    }
    console.log("OnClick", id, url);
    this.track({id:id});
    if(url!==undefined && url!='')
      window.open(url);
  }
  
  track = (params) => {
    apiFetch('/news/click', params)
      .then((data)=>{
        console.log("fetchData ok", data);
      })
      .catch(({response, error})=>{
        console.log("fetchData error", error, response);
      })
  }
  
  render() {
    const { classes, theme, $$newsData, newsSeparator, newsCover } = this.props;
    const { activeStep } = this.state;
    // const maxSteps = 2;
    // let image, eventReplay, eventKind, eventPending;
    let image;


    let $$guests;

    let $$news = []
    if (newsCover !== undefined)
    $$news.push({newsTitle: newsCover.title, newsThumb: newsCover.thumb, newsPosition: 0, newsCategory: 'cover'})

    if ($$newsData !== undefined && $$newsData.size > 0)
    $$news.push(...$$newsData.toJS())

    $$news = Immutable.fromJS($$news).sortBy($$n => $$n.get('newsPosition'))

    const maxSteps = $$news.size;
    const autoPlay = maxSteps > 1;

    if ($$news.size < 1)
    return <span></span>

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
        <div className={classes.innerWrapper}>
          <If condition={$$news.size < 1}>
            <Card key={'key_'+0} className={classes.card}>
              <div>
              </div>
            </Card>
          </If>
          <If condition={$$news.size > 0}>
            <AutoPlaySwipeableViews
              steps={maxSteps}
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
              slideStyle={{width: '100%'}}
              className={classes.background}
              style={{maxWidth: '100%', width: '100%'}}
              containerStyle={{alignItems: 'flex-start'}}
              autoplay={autoPlay}
              interval={5000}
              >
              {$$news.map((step, index) => {
                if (step.get('newsCategory') === 'cover') {
                  image = step.get('newsThumb');
                } else if (step.get('newsCategory') === 'replay') {
                  // newsEvent = $$data !== undefined && $$data.get('replays') !== undefined ? $$data.get('replays').find($$e => $$e.get('id') === step.get('newsLinkReplay')) : undefined;
                  // if (newsEvent ===  undefined)
                  // newsEvent = $$data !== undefined && $$data.get('availableEvents') !== undefined ? $$data.get('availableEvents').find($$e => $$e.get('id') === step.get('newsLinkReplay')) : undefined;
                  image = this._getNewsImage(step)
                  if (!image || image === undefined){
                    //image = getCardImage(step);
                    image = getThumb('events', step.get('eventId'), step.get('eventThumb'));
                  }
                   //console.log('image is', image)
                } else if (step.get('newsCategory') === 'event') {
                  // newsEvent = $$data !== undefined && $$data.get('availableEvents') !== undefined ? $$data.get('availableEvents').find($$e => $$e.get('id') === step.get('newsLinkReplay')) : undefined;
                  $$guests = getCardGuests(step, {eventId: 'eventId'});
                  image = this._getNewsImage(step)
                  if (!image || image === undefined)
                    //image = getCardImage(step);
                    image = getThumb('events', step.get('eventId'), step.get('eventThumb'));
                } else {
                  image = this._getNewsImage(step);
                }
                return (
                  <Card key={'key_'+index} className={classes.card} onClick={(event)=>this.onClick(event, step)}>
                    <div style={{backgroundImage: image === 'test' ? 'url('+image+')' : undefined}}>
                      <Choose>
                        <When condition={step.get('newsCategory') === 'cover'}>
                          <CardContent>
                            <Grid container alignContent="center" justify="flex-start" className={clsx(classes.newsWrapper, {[classes.newsWrapperPadded]: $$news.size > 1})}>
                              <Grid item xs={12} sm={6} container alignContent="center">
                                <Box align="center" px={6}>
                                  <Typography variant="h2" align="left" style={{width: 'auto'}} element="div">
                                    <Paragrapher text={step.get('newsTitle')} prettyfy={true}/>
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} justify="center" container>
                                <img src={step.get('newsThumb')} alt="intro" style={{width: '100%', maxWidth: 450}}/>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </When>
                        <When condition={step.get('newsCategory') === 'info'}>
                          <CardContent>
                            <Grid container alignContent="center" justify="flex-start" className={clsx(classes.newsWrapper, {[classes.newsWrapperPadded]: $$news.size > 1})}>
                              <Grid item xs={image ? 12 : 12} sm={image ? 6 : 12} className={classes.newsContentWrapper}>
                                <Box w={'100%'} className={classes.newsHeader}>
                                  <Typography variant="caption" style={{fontWeight: 'bold'}}>{i18n.get('newsNew', {'pretty': false})}</Typography>
                                  <span className={classes.newsSeparator}>{newsSeparator}</span>
                                  <Typography variant="body1">{i18n.get('newsFlash', {'pretty': false})}</Typography>
                                </Box>
                                <Typography variant="h2"><Paragrapher  prettyfy={true} text={step.get('newsTitle')} element="div"/></Typography>

                                <If condition={this.state['collapsed_'+step.get('id')] !== true}>
                                  <Typography variant="body1" gutterBottom  >{step.get('newsShortDescription')}</Typography>
                                </If>
                                <If condition={this.state['collapsed_'+step.get('id')] !== true && step.get('newsDescription') !== undefined && step.get('newsDescription') !== '' && step.get('newsDescription') !== null}>
                                  <Button variant="contained" color="primary" size="large" onClick={(e) => {e.stopPropagation(); this._toggleCollapse(step.get('id'))}}>{i18n.get('newsShowMore', {'pretty': false})}</Button>
                                </If>
                                <If condition={step.get('newsDescription') !== ''}>
                                  <Collapse in={ this.state['collapsed_'+step.get('id')]} timeout="auto" unmountOnExit collapsedSize={'50px'}>
                                    <Typography variant="body1" component="div" gutterBottom><Paragrapher text={step.get('newsDescription')} pretty={true} element={'div'} gutterBottom/></Typography>
                                    <Button variant="contained" color="primary" size="large" onClick={(e) => {e.stopPropagation();this._toggleCollapse(step.get('id'))}}>{i18n.get('newsClose', {'pretty': false})}</Button>
                                  </Collapse>
                                </If>
                                {/*
                                <If condition={step.get('newsLinkExternal') !== null && step.get('newsLinkExternal') !== undefined && step.get('newsLinkExternal') !== ''}>
                                  <Button variant="contained" color="primary" size="large" onClick={(e) => {e.preventDefault();
                                      console.log('opening', step.get('newsLinkExternal')); window.open(step.get('newsLinkExternal'))}}>{i18n.get('newsShowMore')}</Button>
                                  </If>
                                  */}
                                </Grid>
                                <If condition={image}>
                                  <Grid item xs={12} sm={image ? 6 : 12} container justify="flex-end" alignContent="center" className={classes.mediaWrapper}>
                                      {/*<If condition={step.get('newsLinkExternal') !== null && step.get('newsLinkExternal') !== undefined && step.get('newsLinkExternal') !== ''}>
                                        <a href={step.get('newsLinkExternal')} target="_blank" style={{lineHeight:0}}><img
                                            className={classes.media}
                                            src={image}
                                            alt={step.get('newsTitle')}
                                            /></a>
                                      </If>
                                      <If condition={step.get('newsLinkExternal') === null || step.get('newsLinkExternal') === undefined || step.get('newsLinkExternal') === ''}>
                                */}
                                        <img
                                          className={classes.media}
                                          src={image}
                                          alt={step.get('newsTitle')}
                                          />
                                      {/*</If>*/}

                                  </Grid>
                                </If>

                              </Grid>
                            </CardContent>

                          </When>
                          <When condition={step.get('newsCategory') === 'image'}>
                          <CardContent>
                            <Grid container alignContent="center" justify="flex-start" className={clsx(classes.newsWrapperFull)}>
                                <If condition={image}>
                                  <Grid item xs={12} container justify="flex-end" alignContent="center" className={clsx(classes.mediaWrapper, classes.mediaWrapperFullImage)}>
                                      {/*<If condition={step.get('newsLinkExternal') !== null && step.get('newsLinkExternal') !== undefined && step.get('newsLinkExternal') !== ''}>
                                        <a href={step.get('newsLinkExternal')} target="_blank" style={{lineHeight:0}}><img
                                            className={classes.media}
                                            src={image}
                                            alt={step.get('newsTitle')}
                                            /></a>
                                      </If>
                                      <If condition={step.get('newsLinkExternal') === null || step.get('newsLinkExternal') === undefined || step.get('newsLinkExternal') === ''}>
                                */}
                                
                                        <img
                                          className={clsx(classes.mediaFull)}
                                          src={image}
                                          alt={step.get('newsTitle')}
                                          />
                              
                                      {/*</If>*/}

                                  </Grid>
                                </If>

                              </Grid>
                            </CardContent>

                          </When>
                          <When condition={step.get('newsCategory') === 'replay'}>
                            <CardContent>
                              <Grid container alignContent="center" justify="flex-start" className={clsx(classes.newsWrapper, {[classes.newsWrapperPadded]: $$news.size > 1})}>
                                <Grid item xs={image ? 12 : 12} sm={image ? 6 : 12} className={classes.newsContentWrapper}>
                                  <If condition={step !== undefined && !step.get('eventLinkReplay')}>

                                    <Box w={'100%'} className={classes.newsHeader}>
                                      <Typography variant="caption">{i18n.get('newsSoonAvailable', {'pretty': false})}</Typography>
                                      <span className={classes.newsSeparator}>{newsSeparator}</span>
                                      <Typography variant="body1" style={{fontWeight: 'bold'}}>{i18n.get('newsReplay', {'pretty': false})}</Typography>
                                    </Box>


                                    <Typography variant="h2"><Paragrapher  prettyfy={true} text={step.get('newsTitle')} element="div"/></Typography>
                                    {/*                          <Typography variant="body1" component="div"><Paragrapher text={eventReplay.get('eventDescription')} prettyfy={true} element={'div'}/></Typography>*/}
                                    <Button variant="contained" color="primary" size="large" disabled={true}>{i18n.get('replayAction')}</Button> <span className={classes.buttonLegend}>{i18n.get('newsSoonAvailable', {'pretty': false})}</span>
                                  </If>
                                  <If condition={step !== undefined && step.get('eventLinkReplay')}>

                                    <Box w={'100%'} className={classes.newsHeader}>
                                      <Typography variant="caption">{i18n.get('newsNew', {'pretty': false})}</Typography>
                                      <span className={classes.newsSeparator}>{newsSeparator}</span>
                                      <Typography variant="body1" style={{fontWeight: 'bold'}}>{i18n.get('newsReplay', {'pretty': false})}</Typography>
                                    </Box>


                                    <Typography variant="h2" ><Paragrapher  prettyfy={true} text={step.get('newsTitle')} element="div"/></Typography>
                                    <Button variant="contained" color="secondary" size="large" onClick={(e) => {e.preventDefault(); this.props.history.push('/replays/'+step.get('eventId'))}}>{i18n.get('replayAction')}</Button>
                                  </If>
                                </Grid>
                                <If condition={image}>
                                  <Grid item xs={12} sm={image ? 6 : 12} container justify="flex-end" alignContent="center" className={classes.mediaWrapper}>
                                      <img
                                        className={classes.media}
                                        src={image}
                                        alt={step.get('newsTitle')}
                                        />
                                  </Grid>
                                </If>

                              </Grid>

                            </CardContent>
                          </When>
                          <When condition={step.get('newsCategory') === 'event'}>
                            <CardContent>
                              <Grid container alignContent="center" justify="flex-start" className={clsx(classes.newsWrapper, {[classes.newsWrapperPadded]: $$news.size > 1})}>
                                <Grid item xs={image ? 12 : 12} sm={image ? 6 : 12} className={classes.newsContentWrapper}>

                                  <If condition={step !== undefined && step.get('eventState') === 'pending'}>
                                    <Box w={'100%'} className={classes.newsHeader}>
                                      <Typography variant="caption">{i18n.get('newsNew', {'pretty': false})}</Typography>
                                      <span className={classes.newsSeparator}>{newsSeparator}</span>
                                      <Typography variant="body1" style={{fontWeight: 'bold'}}>{i18n.get('newsEvent', {'pretty': false})}</Typography>
                                      <span className={classes.newsSeparator}>{newsSeparator}</span>
                                      <Typography variant="body1"><DateRangeIcon />  {formatDate(step.get('eventDate'), "dddd dd mmmm yyyy - HH'h'MM")}</Typography>
                                    </Box>


                                    <Typography variant="h2"><Paragrapher  prettyfy={true} text={step.get('newsTitle')} element="div"/></Typography>
                                    {/*                          <Typography variant="body1" component="div"><Paragrapher text={eventReplay.get('eventDescription')} prettyfy={true} element={'div'}/></Typography>*/}
                                    <If condition={$$guests !== false && $$guests.size > 1}>
                                      <Box className={classes.guests}>
                                        <AvatarGroup max={5} spacing={'medium'}>
                                          {$$guests.map(($$g, aIdx) => {
                                            return (
                                              <Avatar size="small" key={'av_'+aIdx} src={$$g.get('dest')} alt={$$g.getIn(['attributes','name'])}/>
                                            )
                                          }).toArray()}
                                        </AvatarGroup>
                                      </Box>
                                    </If>
                                    <If condition={$$guests !== false && $$guests.size === 1}>
                                      <Box className={classes.guests}>
                                            <Avatar size="big" src={$$guests.get(0).get('dest')} alt={$$guests.get(0).getIn(['attributes','name'])} style={{float: 'left', marginRight: 16, width: 32, height: 32}}/>
                                            <Typography variant="caption" style={{paddingTop: 9, display: 'block'}}>{i18n.get('eventAnimatedBy', {pretty: false})}</Typography>
                                            <Typography variant="body1"><strong>{$$guests.get(0).getIn(['attributes','name'])}</strong></Typography>
                                      </Box>
                                    </If>
                                    <Button variant="contained" color="primary" size="large"  onClick={(e) => {e.preventDefault(); this.props.history.push('/event/'+step.get('eventId'))}} >{i18n.get('subscribeAction', {'pretty': false})}</Button>
                                  </If>
                                  <If condition={step !== undefined && step.get('eventState') === 'live'}>
                                    <Box w={'100%'} className={classes.newsHeader}>
                                      <Typography variant="caption">{i18n.get('newsNew', {'pretty': false})}</Typography>
                                      <span className={classes.newsSeparator}>{newsSeparator}</span>
                                      <Typography variant="body1" style={{fontWeight: 'bold'}}>{i18n.get('newsEvent', {'pretty': false})}</Typography>
                                    </Box>

                                    <Typography variant="h2" ><Paragrapher  prettyfy={true} text={step.get('newsTitle')} element="div"/></Typography>
                                    <Button variant="contained" color="primary" size="large" onClick={(e) => {e.preventDefault(); this.props.history.push('/live/'+step.get('eventId'))}}>{i18n.get('liveAction')}</Button>
                                  </If>
                                </Grid>
                                <If condition={step !== undefined && image}>
                                  <Grid item xs={12} sm={image ? 6 : 12} container justify="flex-end" alignContent="center" className={classes.mediaWrapper}>
                                      <img
                                        className={classes.media}
                                        src={image}
                                        alt={step.get('eventTitle')}
                                        />
                                  </Grid>
                                </If>

                              </Grid>

                            </CardContent>
                          </When>
                        </Choose>
                      </div>

                    </Card>
                  )}).toArray()}
                </AutoPlaySwipeableViews>
                <Box className={classes.buttonWrapper}>
                <If condition={$$news.size > 1 }>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    className={classes.mobileStepper}
                    />
                  <If condition={activeStep !== maxSteps - 1}>
                  <IconButton size="small" onClick={this.handleNext} className={clsx(classes.buttonNav, classes.buttonRight)}>
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </IconButton>
                  </If>
                  <If condition={activeStep !== 0}>
                  <IconButton size="small" onClick={this.handleBack} className={clsx(classes.buttonNav, classes.buttonLeft)}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  </IconButton>
                  </If>
                </If>
                </Box>

              </If>
              </div>
              </div>
          </div>
        );
      }
    }

    News.propTypes = {
      classes: PropTypes.object.isRequired,
      theme: PropTypes.object.isRequired,
    };


    function mapStateToProps(state) {
      const props = {
        $$newsData: getNewsData(state),
      };
      return props;
    }
    function mapDispatchToProps(dispatch) {
      return {
        onMount: () => {
          dispatch(fetchNews());
        },
      };
    }

    // export default ;
    export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(News)));
