import React, { Component } from 'react';
// import { Route } from 'react-router-dom'
import i18n from 'helpers/i18n';
import Rating from "@material-ui/lab/Rating";
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchEventPreview, clearEventPreview, getEventError, getEventPreview } from 'components/UI/Event/ducks/event';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
// import Paper from '@material-ui/core/Paper';
// import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import Prettyfier from 'components/UI/Prettyfier';
import { formatDate } from "helpers/stringUtils";
import DashboardExportButton from "./DashboardExportButton";

const styles = (theme) => {
  return {
    root: {
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    breadCrumbs: {
      'a': {
        display: 'inline'
      }
    },
    identification: {
      '& .MuiTypography-subtitle1': {
        display: 'inline-flex',
        minWidth: 200,
        fontWeight: 'bold'
      },
      '& .MuiTypography-body1': {
        display: 'inline-flex'
      }
    },
    subscriptions: {

    },
    block: {
      boxShadow: theme.shadows[6],
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    }
  }
}
class DashboardEventPreview extends Component {
  // constructor(props) {
    // super(props);
  // }
  componentDidMount() {
    const {eventId} = this.props;
    this.props.onMount({eventId});
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }
  render() {
    const {$$event, classes, eventId} = this.props;
    if ($$event) console.log($$event.toJS())
    if ($$event) console.log($$event.get('subscriptions').get('rank'))
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <If condition={$$event !== undefined}>
            <Grid item xs={12}>
              <Box className={classes.breadCrumbs}>
                <Link to={'/dashboard'}>Dashboard</Link>&nbsp;&gt;&nbsp;<Link to='/dashboard/event/list'>{i18n.get('dashboardNavigationEvents', {pretty: false})}</Link>
              <If condition={$$event !== undefined}>
                &nbsp;&gt;&nbsp;{$$event.get('previewEvent').get('data').get('eventTitle')}
              </If>
              </Box>
              <If condition={$$event !== undefined}>
                <DashboardExportButton path={"event?id="+eventId} display="bloc"/>
              </If>
            </Grid>
            <Grid item xs={12} sm={12} container justifyContent={'flex-start'}>
              <Box className={clsx(classes.block, classes.identification)}>
                <Typography variant={'h6'} gutterBottom>{i18n.get('eventData')}</Typography>
                {$$event.get('previewEvent').get('labels').filter((label, lIdx) => {
                  const val = $$event.get('previewEvent').get('data').get(label.get('id'));
                  return val !== '' && val !== undefined && val !== null
                }).map((label, lIdx) => {
                  return (<Grid item xs={12} key={'l_'+lIdx}>
                  <Typography variant="subtitle1">{label.get('name')} : </Typography>&nbsp;<Typography variant="body1">{$$event.get('previewEvent').get('data').get(label.get('id'))}</Typography>
                </Grid>)
              }).toArray()}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} container justifyContent={'flex-start'}>
            <Box className={clsx(classes.block, classes.subscriptions)}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Inscriptions</Typography>
                <Typography variant="body1" gutterBottom style={{ display: "inline-flex", verticalAlign: 'middle' }}><strong>Présence</strong></Typography>
                <Box mb={3}>
                <Typography variant="body1" color="primary" component="div">Total : <em>{$$event.get('subscriptions').get('totalSubscribed')}</em></Typography>
                <Typography variant="body1" color="primary" component="div">Total présents: <em>{$$event.get('subscriptions').get('totalAttended')}</em></Typography>
                <Typography variant="body1" color="primary" component="div">Total absents: <em>{$$event.get('subscriptions').get('totalSubscribed') - $$event.get('subscriptions').get('totalAttended')}</em></Typography>
                  <Rating
                    readOnly={true}
                    name={"r_event"}
                    size="small"
                    style={{ display: "inline-flex", verticalAlign: 'middle', marginTop: -2}}
                    precision={0.5}
                    value={$$event.get('subscriptions').get('rank')}
                    />
              </Box>

                <Typography variant="body1" gutterBottom><strong>Détails</strong></Typography>
                <Box pl={1}>
                  <Grid container spacing={1}>
                  {$$event.get('subscriptions').get('details').map(($$s, sIdx) => {
                    return (<Grid item xs={12} sm={6} lg={4} key={'b_'+sIdx}>
                      <Box mb={3} >
                    <Typography variant="body1" color="primary" component="div">{$$s.get('accountFirstname')+' '+$$s.get('accountLastname')}</Typography>
                    <Typography variant="body1" element="div">
                      {i18n.get('eventPresent', {pretty: false})}&nbsp;:&nbsp;
                      <If condition={$$s.get('attended') !== '' && $$s.get('attended') !== null}>
                        {formatDate($$s.get('event'), "dd/mm/yyyy HH:MM")}
                      </If>
                      <If condition={$$s.get('attended') === '' || $$s.get('attended') === null}>
                        Non
                      </If>
                    </Typography>
                  </Box></Grid>);
                }).toArray()}
              </Grid>
              </Box>
            </Grid>
          </Box>

        </Grid>
      </If>


    </Grid>
  </div>
);
}
}

function mapStateToProps(state) {
  const props = {
    $$event: getEventPreview(state),
    $$error: getEventError(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      dispatch(fetchEventPreview(props));
    },
    onUnmount: (props) => {
      dispatch(clearEventPreview());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardEventPreview));
