import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import EventCard from 'components/UI/Event/EventCard';

const EventCardStyled = withStyles((theme) => {
  return {
        root: {
          '& .MuiTypography-caption': {
            color: theme.palette.primary.dark,
          },
          height: '100%',
          // marginBottom: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        },
        itemWrapper: {
          display: 'flex',
          overflow: 'hidden',
          width: '100%',
          borderRadius: 5,
          // border: '1px #1D202126 solid',
          backgroundColor: '#fff',
          // paddingBottom: theme.spacing(3),
          // width: 'calc(100% - '+(8*9)+'px)',
          height: '100%',
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(3),
          border: '1px #c7d3d7 solid',
          '& > div': {
            width: '100%',
          },
          [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            padding: theme.spacing(2),
          },
          [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
          },
        },
        itemContentWrapper: {
          paddingTop: theme.spacing(2),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
          },
        },
        itemThumbnailWrapper: {
          position: 'relative',
          width: '100%',
          height: '100%',
        },
        itemThumbnail: {
          position: 'relative',
          width: '100%',
          height: '100%',
          // height: 200,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
          backgroundSize: 'cover',
          backgroundColor: theme.palette.secondary.main,
          // marginBottom: theme.spacing(3),
        },
        itemThumbnailVertical: {
          backgroundPosition: '50% -20px',
        },
        itemTheme: {
          marginBottom: theme.spacing(0.5),
          fontSize: '0.75rem',
          lineHeight: '1.5',
        },
        itemTitle: {
          color: theme.palette.secondary.dark,
          width: '100%',
          marginBottom: theme.spacing(3),
        },
        itemDescription: {
          width: '100%',
          marginBottom: theme.spacing(3),
        },
        itemDate: {
          color: theme.palette.primary.dark,
          width: '100%',
          marginTop: theme.spacing(0.5),
          marginBottom: theme.spacing(0.5),
        },
        itemPrecisions: {
          width: '100%',
          marginBottom: theme.spacing(1.5),
          '&:last-of-type': {
            marginBottom: theme.spacing(3),
          },
        },
        itemPrecisionsSpacer: {
          [theme.breakpoints.down('xs')]: {
            display: 'none',
          },
        },
    
        invisible: {
          visibility: 'hidden',
        },
        liveNavigation: {
          '& .MuiButton-root.MuiButton-containedPrimary': {
            backgroundColor: theme.palette.secondary.light,
            borderColor: theme.palette.secondary.light,
            //color: '#fff',
            // fontWeight: 'bold',
            fontWeight: 700,
            '&:hover': {
              backgroundColor: '#fff',
              // borderColor: '#fff',
              color: theme.palette.secondary.light,
              borderColor: theme.palette.secondary.light,
            },
          },
          '& .MuiTypography-body1': {
            display: 'inline-block',
            marginLeft: theme.spacing(1),
            color: theme.palette.secondary.light,
            // fontWeight: 'bold'
            fontWeight: 700,
          },
        },
        iconPlay: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#fff',
          fontSize: '10rem',
          width: 100,
          height: 100,
          cursor: 'pointer',
        },
        itemGuests: {
          marginBottom: theme.spacing(3),
          '& .MuiTypography-caption': {
            marginBottom: theme.spacing(2),
          },
          '& .MuiTypography-body1': {
            // fontWeight: 'bold'
            fontWeight: 700,
          },
        },
        itemAvatars: {
          // marginLeft: theme.spacing(-1),
          marginBottom: theme.spacing(1),
          // paddingRight: theme.spacing(2),
          '& .MuiSvgIcon-root': {
            margin: 0,
          },
          '& .MuiAvatar-colorDefault': {
            color: theme.palette.secondary.light,
          },
        },
        itemGuestName: {
          // paddingLeft: theme.spacing(.4),
          paddingRight: theme.spacing(0.4),
          marginRight: theme.spacing(1),
          display: 'inline-block',
          position: 'relative',
          '&:before': {
            content: "','",
            textAlign: 'right',
            position: 'absolute',
            right: 0,
            // color: "red",
            width: '100%',
            height: '100%',
          },
          '&:last-of-type': {
            paddingRight: 0,
            marginRight: 0,
            '&:before': {
              content: "' '",
            },
          },
        },
        eventCategory:{
          display:'none',
        }
  };
})(EventCard);

class EventCardWrapper extends Component {
  render() {
    return <EventCardStyled 
      useUniverseInLinks={true}
      useUniverseDefaultThumb={true}
      itemIndex={this.props.itemIndex}
      $$item={this.props.$$item}
      kind={this.props.kind}
      classes={this.props.classes}
      variant={this.props.variant}
      isSubscribed={this.props.isSubscribed}
    />;
  }
}

export default EventCardWrapper;
