import React, { Component } from 'react';
import config from 'config/api';
import DynamicForm  from 'components/UI/DynamicForm'

class MessageForm extends Component {
  _getI18n() {
    this.i18n = {
      send: 'Enregistrer',
      sending: 'Enregistrement...'
    }
  }
  render() {
    const {
      onFormComplete,
    } = this.props;
    const moduleConfig = {
      module: 'message',
      fetchUrl: config.message+'/edit',
      postUrl: config.message+'/edit',
      formTitle: this.props.id !== undefined ? 'Edition' : 'Nouveau message',
      // redirectAfterEdit: userGroup != 50 ? '/help' : undefined,
      // afterEdit: userGroup == 50 ? 'log' : undefined,
      // afterEditLog: userGroup == 50 ? 'Une invitation a été envoyée par Email, ce nouveau profil sera disponible dès son activation par Prodeezi' : undefined,
      // afterEditHideForm: userGroup == 50 ? true : undefined
      redirectAfterEdit: false ,
      afterEdit: 'onFormComplete',
      afterEditLog: '',
      afterEditHideForm: true,
      onFormComplete,
      // onCancel: onFormComplete
      // onCancel: onClose
    }

    return (
      <DynamicForm moduleConfig={moduleConfig} {...this.props} />
    );
  }
}


export default MessageForm;
