import React, { Component } from "react";
// import config from "config/api";
// import DynamicForm from "components/UI/DynamicForm";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import {detect} from "detect-browser";
import { withStyles } from '@material-ui/core/styles';
import Dialog from 'components/UI/Dialog/Dialog'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const styles = (theme) => {
  return {
    paperClassName: {
      color: theme.palette.dialog && theme.palette.dialog.color ? theme.palette.dialog.color : undefined,
      backgroundColor: theme.palette.dialog && theme.palette.dialog.backgroundColor ? theme.palette.dialog.backgroundColor : undefined
    }
  }
}
class DetectBrowser extends Component {
  constructor(props) {
    super(props);
    const result = detect();
    // console.log(result)
    let lockScreen = false;
    lockScreen = result.name === 'ie' && result.version === '11.0.0' ? true : lockScreen;
    if (result.name === 'chrome' && this.props.minChrome !== undefined) {
      const cur = result.version.split('.');
      const req = this.props.minChrome.split('.');
      if (cur[0] < req[0] || (cur[0] === req[0] && cur[1] < req[1]) || (cur[0] === req[0] && cur[1] === req[1] && cur[2] < req[2]))
        lockScreen = true;
    }
    // lockScreen = true;
    // console.log(result)
    // lockScreen = this.props.minChrome && result.name === 'chrome' && result.version < this.props.minChrome ? true : lockScreen;
    // const lockScreen = true;
    this.state={open: lockScreen};
  }
  _toggle = () => {
    this.setState({open: false})
  }
  render() {
    // const browser = BD.detect();
    const {showBtClose, classes} = this.props;
    // const {open} = this.state;
    const {open} = this.state;
    return <Dialog open={open} showClose={true} title={'ATTENTION !'} titleStyle={{fontSize: '2rem'}} paperClassName={classes.paperClassName}>
      <Box width="100%" pb={3}>
      <Typography variant="body1" color="primary" style={{fontSize: '1.4rem', textAlign:'center'}} gutterBottom component="div"><Paragrapher text={"[b]Votre version d'Internet Explorer est trop ancienne\net n'est plus compatible avec notre site.[/b]"} prettyfy={true}/> </Typography>
      <Typography variant="body1" color="primary" style={{fontSize: '1.4rem', textAlign:'center'}} component="div"><Paragrapher text={"Veuillez utiliser un autre navigateur\n(Google Chrome de préférence)."} prettyfy={true}/> </Typography>
      <If condition={showBtClose !== false}>
      <Box pt={3} width="100%" align="center">
        <Button size="large" color="primary" variant="contained" onClick={this._toggle}>Fermer</Button>
      </Box>
      </If>
      </Box>
    </Dialog>
    // return <React.Fragment></React.Fragment>
  }
}

export default withStyles(styles)(DetectBrowser);
