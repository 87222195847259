import React from "react";
import Dialog from "./Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

class DialogConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popinIsOpen: false,
    };
    this._onPopupOpen = this._onPopupOpen.bind(this);
    this._onPopupCancel = this._onPopupCancel.bind(this);
    this._onPopupConfirm = this._onPopupConfirm.bind(this);
  }
  componentDidMount() {
    if (this.props.open === true) this._onPopupOpen();
  }
  // componentDidUpdate(oldProps) {
  //   if (oldProps.open !== this.props.open) {
  //     if (this.props.open)
  //   }
  //   if (this.props.open === true)
  //     this._onPopupOpen();
  // }
  _onPopupOpen() {
    const { popinIsOpen } = this.state;
    this.setState({ popinIsOpen: !popinIsOpen });
  }
  _onPopupCancel(args) {
    this.setState({ popinIsOpen: false });
    const { onCanceled, name } = this.props;
    if (onCanceled) {
      onCanceled(name);
    }
  }
  _onPopupConfirm() {
    const { onConfirmProps } = this.props;
    this.setState({ popinIsOpen: false });
    const { onConfirmed, name } = this.props;
    if (onConfirmed) {
      onConfirmed(name, onConfirmProps);
    }
  }

  render() {
    const { title, titleStyle, text, textStyle, children } = this.props;

    const { popinIsOpen } = this.state;

    return (

      <Dialog
        open={popinIsOpen}
        title={title}
        onClose={this._onPopupCancel}
        titleStyle={titleStyle}
      >
        <div className="Form">
        <If condition={text !== undefined}>
          <Typography variant="subtitle1" style={textStyle}>
            {text}
          </Typography>
          </If>
          <If condition={children !== undefined}>
          {children}
          </If>
          <Box my={2} align="center">
            <Button color="secondary" onClick={this._onPopupCancel}>
              Non
            </Button>
            <Button onClick={this._onPopupConfirm}>Oui</Button>
          </Box>
        </div>
      </Dialog>
    );
  }
}

export default DialogConfirm;
