import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Template from 'components/UI/Template/Template';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCurrentUserRole } from 'ducks/authentication';
import OfferUniverse from './OfferUniverse';
import PageWithHeader from 'components/UI/PageWithHeader';
import OfferProtection from './OfferProtection';
import OfferAdminForm from 'components/UI/Offers/OfferAdminForm';

const styles = (theme) => ({});

class OfferForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { id, userRole } = this.props;
    return (
      <Template current="medias">
        <OfferUniverse />
        <OfferProtection>
          <PageWithHeader title={id !== undefined ? "Modifier l'annonce" : 'Déposer une annonce'}>
            <OfferAdminForm id={id} />
          </PageWithHeader>
        </OfferProtection>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}
export default withRouter(connect(mapStateToProps)(withStyles(styles)(OfferForm)));
