import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import HomeHelp from 'components/UI/Home/HomeHelp';

const styles = (theme) => {
  return {
  };
};

class HomeHelpWrapper extends Component {
  render() {
    const { classes, showContact } = this.props;
    return (
      <HomeHelp showContact={showContact}/>
    );
  }
}

export default withRouter(withStyles(styles)(HomeHelpWrapper));