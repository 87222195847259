import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import i18n from 'helpers/i18n';

const styles = (theme) => {
  return {
    root: {
      padding: '0',
      marginBottom: theme.spacing(4),
      '& .MuiTypography-h3': {
        color:"#333",
        fontSize:'1.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'none',
        marginBottom: theme.spacing(2.5)
      },
    },
    inline: {
      // display: 'inline-block',
    },
    button: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: 5,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: 5,
      },
    },
  };
};

class HomeHelp extends Component {
  _goRedir(url) {
    const { history } = this.props;
    history.push(url);
  }
  render() {
    const { classes, showContact } = this.props;

    return (
      <Grid container={true} spacing={2} alignItems="center" justifyContent="space-around" className={classes.root}>
        <Grid item={true} sm={12} md={12} >
          <Typography variant="h3" gutterBottom component="div">{i18n.get('helpBannerTitle', {pretty: true, element: 'div'})}</Typography>
        </Grid>
        <Grid
          item={true}
          sm={12}
          md={12}
          container={true}
          spacing={2}
          alignItems="center"
          justify="center"
        >
          <span className={classes.inline}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                this._goRedir('/help');
              }}
            >
              {i18n.get('helpBannerGotoHelp', {pretty: true, element: 'div'})}
            </Button>
            <If condition={showContact !== false}>
            <Button
              variant="contained"
              color="secondary"
              className={clsx(classes.button)}
              onClick={(e) => {
                e.preventDefault();
                this._goRedir('/contact');
              }}
            >
            {i18n.get('helpBannerGotoContact', {pretty: true, element: 'div'})}
            </Button>
            </If>
          </span>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(HomeHelp));