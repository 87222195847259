import React from 'react';
import Form from './AdminEventForm';
import List from './AdminEventList';
// import { Switch, Route } from 'react-router-dom'
// import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
// import Header from 'components/Header/Header';
// import Footer from 'components/Footer';
import Template from 'components/UI/Template/Template'
import Box from '@material-ui/core/Box';
import config from 'config/api';

// import NoMatch from 'components/NoMatch';
// import Bubble from 'components/UI/Bubble';
const Events = ({
  match
}) => {
  return (
    <Template maxWidth='auto' current="admin" headerPosition={'relative'} headerImage={config.template && config.template.admin && config.template.admin.background ? config.template.admin.background : undefined}>
      <Box width="100%">
        <If condition={match.params.action === 'list'}><List/></If>
        <If condition={match.params.action === 'edit'}><Form id={match.params.id}/></If>
      </Box>
    </Template>
  );
}
export default Events;
