import React, { Component } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { fetchOffers, getOffersData, getFilters, offerAllowedPost } from './ducks/offer';

import OfferCard from './OfferCard';
import OfferFilters from './OfferFilters';
import OfferMap from './OfferMap';

const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
      width: '100%',
    },
    pageTitle: {
      paddingTop: 16,
      paddingLeft: '1em',
      textAlign: 'left',
    },
    subTitle: {
      paddingTop: '1em',
      fontSize: '30%',
    },

    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.primary.light,
        zIndex: -1,
      },
    },
    paper: {
      backgroundColor: 'transparent',
      padding: theme.spacing(3),
      //maxWidth: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0.5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.3),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.1),
      },
      '& .MuiCardContent-root': {
        [theme.breakpoints.down('sm')]: {
          padding: '4px',
          fontSize: '90%',
        },
        [theme.breakpoints.down('xs')]: {
          /*
          //VGAuche
          padding: '0px',
          fontSize:'80%',
          */
          padding: '8px',
          fontSize: '100%',
        },
      },
      '& h2': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.2rem',
          lineHeight: '1.3rem',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '1rem',
          lineHeight: '1.1rem',
        },
      },
      '& a': {
        color: 'inherit',
      },
      '& .noResult': {
        fontSize: 20,
        margin: 'auto',
        maxWidth: '25vw',
        marginTop: 120,
        fontWeight: 'bold',
        color: '#0A569A',
        opacity: 0.5,
        [theme.breakpoints.down('md')]: {},
        [theme.breakpoints.down('sm')]: {},
        [theme.breakpoints.down('xs')]: {
          fontSize: 15,
          maxWidth: '30vw',
        },
      },
    },
    listContainer: {
      maxHeight: '70vh',
      width: '100%',
      overflowY: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxHeight: 'unset',
      },
    },
    listBefore: {
      //version liste à gauche de la carte
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    listAfter: {
      //version liste en dessous de la carte
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    gridMap: {
      [theme.breakpoints.down('md')]: {},
      [theme.breakpoints.up('sm')]: {},
      [theme.breakpoints.down('xs')]: {
        margin: '3px',
        '& .offerMap': {
          height: '50vh !important',
          borderRadius: '4px !important',
        },
      },
    },
    /*
    mapFirst: {
      margin:'3px',
      '& .offer_map':{
        height:'50vh !important',
      },
      [theme.breakpoints.down('md')]: {
      },
      [theme.breakpoints.up('sm')]: {
        display:'none',
      },
      [theme.breakpoints.down('xs')]: {
      },
    },
    mapLast: {
      [theme.breakpoints.down('md')]: {
      },
      [theme.breakpoints.down('sm')]: {
      },
      [theme.breakpoints.down('xs')]: {
        display:'none',
      },
    },
    */
  };
};

const NoResult = () => (
  <Typography className="noResult" variant="h4" component="p" color="primary" align="center">
    Aucun résultat ne correspond à vos critères
  </Typography>
);

class OfferList extends Component {
  componentDidMount() {}
  componentDidUpdate(oldProps) {
    const { $$filters, onFiltersChange } = this.props;
    if ($$filters !== oldProps.$$filters) onFiltersChange();
  }
  render() {
    const { $$offers, classes, $$selects, offerAllowedPost } = this.props;
    const addOfferButton = offerAllowedPost === true;
    return (
      <div>
        <Box className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={3}>
                <OfferFilters $$selects={$$selects} addOfferButton={addOfferButton} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.listBefore}>
              {$$offers !== undefined && $$offers.size > 0 ? (
                <Box className={classes.listContainer}>
                  {$$offers.map((item) => (
                    <OfferCard item={item} key={item.get('id')} $$selects={$$selects} />
                  ))}
                </Box>
              ) : (
                <NoResult />
              )}
            </Grid>
            <Grid item xs={12} sm={7} className={classes.gridMap}>
              <OfferMap />
            </Grid>
            <Grid item xs={12} sm={5} className={classes.listAfter}>
              {$$offers !== undefined && $$offers.size > 0 ? (
                <Box className={classes.listContainer}>
                  {$$offers.map((item) => (
                    <OfferCard item={item} key={item.get('id')} $$selects={$$selects} />
                  ))}
                </Box>
              ) : (
                <NoResult />
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$offers: getOffersData(state),
    $$filters: getFilters(state),
    offerAllowedPost: offerAllowedPost(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {},
    onFiltersChange: () => {
      dispatch(fetchOffers('all'));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OfferList)));
