import io from "socket.io-client";
let Socket;

const connectToIOServer = ({endpoint, $$event, $$user, onSocketMessage, onSocketDisconnect}) => {
  // console.log('connecting to', endpoint, roomHash, token);
  // const transports = ['websocket', 'polling'];
  const url = process.env.REACT_APP_RELAY_SOCKET_URL
    ? process.env.REACT_APP_RELAY_SOCKET_URL + '/' + endpoint
    // : 'http://192.168.61.22:9100/' + endpoint;
    : 'http://192.168.62.21:9100/' + endpoint;
  console.log('connecting to ', url);
  const query =
    'eid=' +
    $$event.get('id') +
    '&aid=' +
    $$user.get('id')

  Socket = io(url, {
    query: query,
    // 'transports': transports,
    timeout: 5000,
    reconnect: true,
    'max reconnection attempts': 99,
    'sync disconnect on unload': true,
  });

  Socket.on('connect', () => {
    // console.log('connected !');
    // socketConnAttempts++;
  });

  Socket.on('connect_error', () => {
    if (console && console.log) console.log('connect_error');
  });

  Socket.on('reconnect_error', () => {
    if (console && console.log) console.log('reconnect_error');
  });

  Socket.on('reconnect_failed', () => {
    if (console && console.log) console.log('reconnect_failed');
  });

  Socket.on('error', err => {
    if (console && console.log) console.log('error', err);
  });

  Socket.on('disconnect', () => {
    if (console && console.log) console.log('disconnect');
    if (onSocketDisconnect) onSocketDisconnect();
  });

  Socket.on('reconnect', () => {
    if (console && console.log) console.log('reconnect');
  });

  Socket.on('message', data => {
    onSocketMessage(Socket, data);
  });
};


const disconnectFromIOServer = () => {
  if (Socket !== undefined && Socket.close !== undefined) Socket.close();
};
// export default ;
export { connectToIOServer, disconnectFromIOServer };
