import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import MediaCard from 'components/UI/Media/MediaCard';

const MediaCardStyled = withStyles((theme) => {
  return {
    root: {
    },
  };
})(MediaCard);

class MediaCardWrapper extends Component {
  render() {
    return <MediaCardStyled
    useUniverseInLinks={true}
    useUniverseDefaultThumb={true}
    itemIndex={this.props.itemIndex}
    $$item={this.props.$$item}
    kind={this.props.kind}
    classes={this.props.classes}
    variant={this.props.variant}
     />;
  }
}

export default MediaCardWrapper;

