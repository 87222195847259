import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';
const actionTypes = mirrorCreator(
  [
    'SET_OFFER_DATA',
    'SET_OFFERS_DATA',
    'SET_MY_OFFERS_DATA',
    'SET_OFFER_ERROR',
    'CLEAR_OFFER',
    'CLEAR_OFFERS',
    'CLEAR_MY_OFFERS',
    'SET_FILTER_VALUE',
    'SET_SELECTED_OFFER',
    'SET_OFFER_FIELDS',
    'SET_OFFER_SELECTS',
    'SET_LOCATION_FILTER',
    'RESET_FILTERS',
    'SET_OFFER_PENDING',
  ],
  { prefix: 'offer/' },
);

const mutators = {
  setOfferData: (offer) => ($$state) => $$state.set('offer', offer),
  setOfferError: (error) => ($$state) => $$state.set('error', error),
  setOffersData: (data) => ($$state) => $$state.set('offers', data),
  setMyOffersData: (data) => ($$state) => $$state.set('myoffers', data),
  setFilterValue: (name, value) => ($$state) => $$state.setIn(['filters', name], value),
  setSelectedOffer: ($$offer) => ($$state) =>
    $$state.set('selectedOfferId', $$offer ? $$offer.get('id') : undefined),
  setOfferFields: (data) => ($$state) => $$state.set('fields', data),
  setOfferSelects: (data) => ($$state) => $$state.set('selects', data),
  setLocationFilter: (data) => ($$state) => $$state.set('locationFilter', Immutable.fromJS(data)),
  resetFilters: () => ($$state) => $$state.delete('filters'),
  setOfferPending: (count) => ($$state) => $$state.set('offerPending', count),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_OFFERS_DATA:
      acts.push(mutators.setOffersData(action.offers));
      return pipe(acts, $$state);
    case actionTypes.SET_MY_OFFERS_DATA:
      acts.push(mutators.setMyOffersData(action.myoffers));
      return pipe(acts, $$state);
    case actionTypes.SET_OFFER_DATA:
      acts.push(mutators.setOfferData(action.offer));
      return pipe(acts, $$state);
    case actionTypes.SET_OFFER_ERROR:
      return pipe([mutators.setOfferError(action.error)], $$state);
    case actionTypes.CLEAR_OFFER:
      return pipe([mutators.setOfferError(undefined), mutators.setOfferData(undefined)], $$state);
    case actionTypes.CLEAR_OFFERS:
      return pipe([mutators.setOfferError(undefined), mutators.setOffersData(undefined)], $$state);
    case actionTypes.CLEAR_MY_OFFERS:
      return pipe([mutators.setOfferError(undefined), mutators.setMyOffersData(undefined)], $$state);
    case actionTypes.SET_FILTER_VALUE:
      return pipe([mutators.setFilterValue(action.name, action.value)], $$state);
    case actionTypes.SET_SELECTED_OFFER:
      return pipe([mutators.setSelectedOffer(action.$$offer)], $$state);
    case actionTypes.SET_OFFER_FIELDS:
      return pipe([mutators.setOfferFields(action.data)], $$state);
    case actionTypes.SET_OFFER_SELECTS:
      return pipe([mutators.setOfferSelects(action.data)], $$state);
    case actionTypes.SET_LOCATION_FILTER:
      return pipe([mutators.setLocationFilter(action.data)], $$state);
    case actionTypes.RESET_FILTERS:
      return pipe([mutators.resetFilters()], $$state);
    case actionTypes.SET_OFFER_PENDING:
      return pipe([mutators.setOfferPending(action.data)], $$state);
    default:
      return $$state;
  }
}

export const getRoot = (state) => state.offer || Immutable.Map();
export const getRootAuthentication = (state) => state.authentication || Immutable.Map();
export const getOfferData = createSelector([getRoot], ($$state) => {
  let data = $$state.get('offer');
  return data;
});
export const getOffersData = createSelector([getRoot], ($$state) => {
  let data = $$state.get('offers');
  return data; // || Immutable.List();
});
export const getMyOffersData = createSelector([getRoot], ($$state) => {
  let data = $$state.get('myoffers');
  return data;
});
export const getOfferFieldsData = createSelector([getRoot], ($$state) => {
  let data = $$state.get('fields');
  return data;
});
export const getOfferSelectsData = createSelector([getRoot], ($$state) => {
  let data = $$state.get('selects');
  return data;
});
export const getOfferError = createSelector([getRoot], ($$state) => $$state.get('error'));

export const getFilters = createSelector([getRoot], ($$state) => $$state.get('filters', Immutable.Map()));

export const getSelectedOffer = createSelector([getRoot, getOffersData], ($$state, $$offers) => {
  const id = $$state.get('selectedOfferId');
  if (id === undefined) return undefined;

  return $$offers.find(($$offer) => $$offer.get('id') === id);
});

export const getLocationFilter = createSelector([getRoot], ($$state) => $$state.get('locationFilter'));

export const getOfferPending = createSelector([getRoot], ($$state) => $$state.get('offerPending'));

export const offerAllowedView = createSelector([getRootAuthentication], ($$state) => {
  const $$user = $$state.get('user');
  if ($$user === undefined) return undefined;
  return true;//Accès tous les utilisateurs depuis le 28/4/23
  /*
  //anciennes conditions (gastro, norgine, ...)
  const isDelegue = /@norgine\.com$/.test($$user.get('email'));
  return (
    userOfferAllowedPost($$user) ||
    isDelegue ||
    ($$user.get('role') === 'staff' && $$user.get('subRole') === 'buadm') ||
    $$user.get('role') === 'admin'
  );
  */
});
export const offerAllowedPost = createSelector([getRootAuthentication], ($$state) => {
  return userOfferAllowedPost($$state.get('user'));
});
export const userOfferAllowedPost = ($$user) => {
  if ($$user === undefined) return undefined;
  return $$user.get('ordinalSpeciality') === 'SM24' && $$user.get('job') === 'es10'; //médecin gastro
};

export function fetchOffer({ offerId, params }) {
  return (dispatch) => {
    let url = config.offers + '/get/' + offerId;
    //console.log('fetchOffer', url);

    if (params.k !== undefined && params.r === undefined) url = url + '?k=' + params.k;
    if (params.r !== undefined && params.k === undefined) url = url + '?r=' + params.r;
    if (params.r !== undefined && params.k !== undefined) url = url + '?k=' + params.k + '&r=' + params.r;

    let formData = {};
    formData.contact_form = true;

    dispatch(clearOffer());

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log('RESPONSE IS', response);
        if (response.offer && response.offer === 'complete') {
          dispatch(setOfferData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setOfferError(response.log));
        }
      })
      .catch((error) => dispatch(setOfferError(error.message)));
  };
}
export function fetchOffers(type) {
  return (dispatch, getState) => {
    let url = config.offers + '/get/' + type;

    dispatch(setOfferError(undefined));

    let formData = {};

    const $$filters = getFilters(getState());
    $$filters.forEach((value, name) => {
      if (type == 'user' && name == 'map') return;
      const paramName = `filter[${name}]`;
      formData[paramName] = value;
    });

    //console.log('fetching offers ' + type, url, formData);

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData, { arrayFormat: 'bracket' }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.offers && response.offers === 'complete') {
          if (type == 'user') dispatch(setMyOffersData(Immutable.fromJS(response.data)));
          else dispatch(setOffersData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setOfferError(response.log));
        }
      })
      .catch((error) => {
        dispatch(setOfferError(error.message));
      });
  };
}

export function fetchOfferPending() {
  return (dispatch) => {
    let url = config.offers + '/pending';
    //console.log('fetchOfferPending', url);

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      //body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log('RESPONSE IS', response);
        if (response.pending !== undefined) {
          dispatch(setOfferPending(Immutable.fromJS(response.pending)));
        } else {
          dispatch(setOfferPending());
        }
      })
      .catch((error) => dispatch(setOfferError(error.message)));
  };
}

export function fetchOfferFields() {
  return (dispatch, getState) => {
    let url = config.offers + '/edit';
    let formData = {};

    //console.log('fetching fields', url, formData);

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData, { arrayFormat: 'bracket' }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.target && response.target === 'offer') {
          const $$data = Immutable.fromJS(response.data);
          const $$data_fields = $$data.get('fields');
          dispatch(setOfferFields(transformFields($$data_fields)));
        } else {
          //dispatch(setOfferError(response.log));
        }
      })
      .catch((error) => {
        //dispatch(setOfferError(error.message))
      });
  };
}

function transformFields($$data_fields) {
  const fields = {};
  $$data_fields.map((group, gIdx) => {
    group
      .get('items')
      .filter((f) => f.get('disabled') !== true)
      .map((field, fIdx) => {
        const fieldName = field.get('id');
        fields[fieldName] = field;
      });
  });
  return Immutable.Map(fields);
}

export function fetchOfferSelects() {
  return (dispatch, getState) => {
    let url = config.offers + '/list';
    let formData = {};

    //console.log('fetching selects', url, formData);

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData, { arrayFormat: 'bracket' }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.offer && response.offer === 'ok') {
          const $$data = Immutable.fromJS(response.data);
          const $$data_linkMap = $$data.get('linkMap');
          dispatch(setOfferSelects(transformSelects($$data_linkMap)));
        } else {
          //dispatch(setOfferError(response.log));
        }
      })
      .catch((error) => {
        //dispatch(setOfferError(error.message))
      });
  };
}

function transformSelects($$data_linkMap) {
  let res = { options: {}, values: {} };
  $$data_linkMap.forEach((values, key) => {
    res['options'][key] = [];
    res['values'][key] = {};
    values.forEach((v) => {
      res['values'][key][v.get('value')] = v.get('text');
      res['options'][key].push({ value: v.get('value'), label: v.get('text') });
    });
  });
  return Immutable.fromJS(res);
}

export function cleanup() {
  return (dispatch) => {
    dispatch(clearOffer());
  };
}

export function setOffersData(offers) {
  return {
    type: actionTypes.SET_OFFERS_DATA,
    offers,
  };
}
export function setMyOffersData(myoffers) {
  return {
    type: actionTypes.SET_MY_OFFERS_DATA,
    myoffers,
  };
}
export function setOfferData(offer) {
  return {
    type: actionTypes.SET_OFFER_DATA,
    offer,
  };
}
export function setOfferError(error) {
  return {
    type: actionTypes.SET_OFFER_ERROR,
    error,
  };
}
export function clearOffer() {
  return {
    type: actionTypes.CLEAR_OFFER,
  };
}
export function clearOffers() {
  return {
    type: actionTypes.CLEAR_OFFERS,
  };
}
export function clearMyOffers() {
  return {
    type: actionTypes.CLEAR_MY_OFFERS,
  };
}

export const setFilterValue = (name, value) => ({
  type: actionTypes.SET_FILTER_VALUE,
  name,
  value,
});

export const setSelectedOffer = ($$offer) => ({
  type: actionTypes.SET_SELECTED_OFFER,
  $$offer,
});

export const setOfferFields = (data) => ({
  type: actionTypes.SET_OFFER_FIELDS,
  data,
});

export const setOfferSelects = (data) => ({
  type: actionTypes.SET_OFFER_SELECTS,
  data,
});

export const setLocationFilter = (data) => ({
  type: actionTypes.SET_LOCATION_FILTER,
  data,
});

export const resetFilters = () => ({
  type: actionTypes.RESET_FILTERS,
});

export const setOfferPending = (data) => ({
  type: actionTypes.SET_OFFER_PENDING,
  data,
});
