import mirrorCreator from "mirror-creator";
import Immutable from "immutable";
import { createSelector } from "reselect";
import pipe from "helpers/redux-state-mutators.js";
// import queryString from "query-string";
import getFetchOptions from "helpers/getFetchOptions"
import config from "config/api";
import {
  sendMessage,
} from "components/UI/Socket/ducks/relay"

const actionTypes = mirrorCreator(
  ["SET_QUIZ_QUESTION_DATA", "SET_QUIZ_QUESTIONS_DATA", "SET_QUIZ_ERROR", "SET_QUIZ_STATE", "SET_QUIZ_ANSWERED", "SET_QUIZ_OPTION", "SET_QUIZ_RESULTS", "SET_QUIZ_MANAGER_RESULTS", "CLEAR_QUIZ_DATA", "CLEAR_QUIZ_RESULTS", "CLEAR_QUIZ_MANAGER_RESULTS", "CLEAR_QUIZ_QUESTIONS"],
  { prefix: "quiz/" }
);


const mutators = {
  setQuizQuestionData: (question) => ($$state) => $$state.set("question", question),
  setQuizQuestionsData: (questions) => ($$state) => $$state.set("questions", questions),
  setQuizError: (error) => ($$state) => $$state.set("error", error),
  setQuizState: (state) => ($$state) => $$state.set("state", state),
  setQuizAnswered: (choices) => ($$state) => $$state.setIn(['state', 'answered'], choices),
  setQuizOption: (name, value) => ($$state) => $$state.setIn(["state", "options", name], value),
  setQuizManagerResults: (results) => ($$state) => $$state.set("managerResults", results),
  clearQuizData: () => ($$state) => $$state.delete("question"),
  clearQuizState: () => ($$state) => $$state.delete("state"),
  clearQuizResults: () => ($$state) => $$state.deleteIn(["state", "options", "showResults"]),
  clearQuizManagerResults: () => ($$state) => $$state.delete("managerResults"),
  clearQuizQuestions: () => ($$state) => $$state.delete("questions"),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_QUIZ_QUESTION_DATA:
    acts.push(mutators.clearQuizData());
    acts.push(mutators.clearQuizResults());
    acts.push(mutators.clearQuizManagerResults());
    acts.push(mutators.clearQuizState());
    acts.push(mutators.setQuizQuestionData(action.question));
    // if (action.state === true) acts.push(mutators.clearErrors);
    return pipe(acts, $$state);
    case actionTypes.SET_QUIZ_QUESTIONS_DATA:
    acts.push(mutators.setQuizQuestionsData(action.questions));
    // if (action.state === true) acts.push(mutators.clearErrors);
    return pipe(acts, $$state);
    case actionTypes.SET_QUIZ_STATE:
    acts.push(mutators.setQuizState(action.state));
    // if (action.state === true) acts.push(mutators.clearErrors);
    return pipe(acts, $$state);

    case actionTypes.SET_QUIZ_ANSWERED:
    acts.push(mutators.setQuizAnswered(action.choices));
    // if (action.state === true) acts.push(mutators.clearErrors);
    return pipe(acts, $$state);

    case actionTypes.SET_QUIZ_OPTION:
    acts.push(mutators.setQuizOption(action.name, action.value));
    // if (action.state === true) acts.push(mutators.clearErrors);
    return pipe(acts, $$state);

    case actionTypes.SET_QUIZ_MANAGER_RESULTS:
    acts.push(mutators.setQuizManagerResults(action.results));
    return pipe(acts, $$state);

    case actionTypes.SET_QUIZ_ERROR:
    return pipe([mutators.setQuizError(action.error)], $$state);

    case actionTypes.CLEAR_QUIZ_DATA:
    return pipe([mutators.clearQuizData(), mutators.clearQuizState(), mutators.clearQuizResults(), mutators.clearQuizManagerResults()], $$state);

    case actionTypes.CLEAR_QUIZ_RESULTS:
    return pipe([mutators.clearQuizResults()], $$state);

    case actionTypes.CLEAR_QUIZ_MANAGER_RESULTS:
    return pipe([mutators.clearQuizManagerResults()], $$state);

    case actionTypes.CLEAR_QUIZ_QUESTIONS:
    return pipe([mutators.clearQuizQuestions()], $$state);

    default:
    return $$state;
  }
}

export const getRoot = (state) => state.quiz || Immutable.Map();
export const getQuizQuestion = createSelector([getRoot], ($$state) =>
$$state.get("question")
);
export const getQuizQuestions = createSelector([getRoot], ($$state) =>
$$state.get("questions")
);
export const getQuizState = createSelector([getRoot], ($$state) =>
$$state.get("state")
);
export const getQuizOptions = createSelector([getRoot], ($$state) =>
$$state.getIn(["state", "options"])
);
export const getQuizResults = createSelector([getRoot], ($$state) =>
$$state.getIn(["state", "options", "showResults"])
);
export const getQuizManagerResults = createSelector([getRoot], ($$state) =>
$$state.get("managerResults")
);
export const getQuizError = createSelector([getRoot], ($$state) =>
$$state.get("error")
);

export function fetchQuizQuestion({eventId, questionId, revision, pull}) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.quiz+'/get/'+eventId+'/?q='+questionId

    let formData = {};
    formData.contact_form = true;

    fetch(url, getFetchOptions(config.urlMethod || "post", formData))
    .then((response) => response.json())
    .then((response) => {
      if (response.quiz && response.quiz === "complete") {
        dispatch(setQuizQuestionData(Immutable.fromJS(response.data)));
        dispatch(fetchQuizState({eventId, questionId}));
      } else {
        dispatch(setQuizError(response.log));
      }
    })
    .catch((error) => dispatch(setQuizError(error.message)))
  };
}
export function fetchQuizQuestions({eventId}) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.quiz+'/list/'+eventId

    let formData = {};
    formData.contact_form = true;

    fetch(url, getFetchOptions(config.urlMethod || "POST", formData))
    .then((response) => response.json())
    .then((response) => {
      // console.log('RESPONSE IS', response)
      if (response.list && response.list === "complete") {
        dispatch(setQuizQuestionsData(Immutable.fromJS(response.data)));
      } else {
        dispatch(setQuizError(response.log));
      }
    })
    .catch((error) => dispatch(setQuizError(error.message)))
  };
}
export function fetchQuizState({eventId, questionId}) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.quiz+'/state/'+eventId+'/?q='+questionId

    let formData = {};
    formData.contact_form = true;

    fetch(url, getFetchOptions(config.urlMethod || "POST", formData))
    .then((response) => response.json())
    .then((response) => {
      if (response.quiz && response.quiz === "complete") {
        dispatch(setQuizState(Immutable.fromJS(response.data)));
      } else {
        dispatch(setQuizError(response.log));
      }
    })
    .catch((error) => dispatch(setQuizError(error.message)))
  };
}
export function setQuizManagerOption({eventId, questionId, name, value}) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.quiz+'/setOptions/'+eventId+'?q='+questionId

    let formData = {manager_options_form: true, name, value};

    fetch(url, getFetchOptions(config.urlMethod || "POST", formData))
    .then((response) => response.json())
    .then((response) => {
      if (response.quiz && response.quiz === "complete") {
        if (name === 'showResults') {
          dispatch(setQuizManagerResults(Immutable.fromJS(response.results)));

          dispatch(sendMessage({
            type: 'contentOption',
            name: 'showResults',
            value: response.results
          }))
        }
      } else {
        dispatch(setQuizError(response.log));
      }
    })
    .catch((error) => dispatch(setQuizError(error.message)))
  };
}
export function sendQuiz({eventId, questionId, choiceData}) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.quiz+'/store/'+eventId+'/?q='+questionId
    let formData = {};
    // console.log({choiceData})
    formData.choiceData = choiceData;
    // console.log(quizData)
    formData.quiz_form = true;
    // console.log(quizData)
    // formData.contact_form = true;

    fetch(url, getFetchOptions('POST', formData))
    .then((response) => response.json())
    .then((response) => {
      // console.log('RESPONSE IS', response)
      if (response.quiz && response.quiz === "complete") {
        dispatch(setQuizAnswered(Immutable.fromJS(choiceData)));
      } else {
        dispatch(setQuizError(response.log));
      }
    })
    .catch((error) => dispatch(setQuizError(error.message)))
  };
}
export function resetQuizQuestion({eventId, questionId}) {
  return (dispatch) => {
    let url = config.quiz+'/reset/'+eventId+'/?q='+questionId
    let formData = {};
    // console.log(quizData)
    console.log(url)
    formData.quiz_form = true;
    // console.log(quizData)
    // formData.contact_form = true;

    fetch(url, getFetchOptions('POST', formData))
    .then((response) => response.json())
    .then((response) => {
      // console.log('RESPONSE IS', response)
      if (response.reset && response.reset === "complete") {
        dispatch(setQuizAnswered(false));
      } else {
        dispatch(setQuizError(response.log));
      }
    })
    .catch((error) => dispatch(setQuizError(error.message)))
  };
}
export function setQuizQuestionData(question) {
  return {
    type: actionTypes.SET_QUIZ_QUESTION_DATA,
    question,
  };
}
export function setQuizQuestionsData(questions) {
  return {
    type: actionTypes.SET_QUIZ_QUESTIONS_DATA,
    questions,
  };
}
export function setQuizState(state) {
  return {
    type: actionTypes.SET_QUIZ_STATE,
    state,
  };
}
export function setQuizAnswered(choices) {
  return {
    type: actionTypes.SET_QUIZ_ANSWERED,
    choices,
  };
}
export function setQuizOption(name, value) {
  return {
    type: actionTypes.SET_QUIZ_OPTION,
    name, value,
  };
}
export function setQuizManagerResults(results) {
  return {
    type: actionTypes.SET_QUIZ_MANAGER_RESULTS,
    results,
  };
}
export function setQuizOptions(name, value) {
  console.log({name, value})
  return {
    type: actionTypes.SET_QUIZ_OPTION,
    name, value,
  };

  // switch (name) {
  //   case 'showResults':
  //   return setQuizResults(value !== false ? Immutable.fromJS(value) : undefined);
  //   break;
  // }

}
export function setQuizError(error) {
  return {
    type: actionTypes.SET_QUIZ_ERROR,
    error,
  };
}
export function clearQuizData() {
  return {
    type: actionTypes.CLEAR_QUIZ_DATA,
  };
}
export function clearQuizResults() {
  return {
    type: actionTypes.CLEAR_QUIZ_RESULTS,
  };
}
export function clearQuizManagerResults() {
  return {
    type: actionTypes.CLEAR_QUIZ_MANAGER_RESULTS,
  };
}
export function clearQuizQuestions() {
  return {
    type: actionTypes.CLEAR_QUIZ_QUESTIONS,
  };
}
