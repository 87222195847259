import React from "react";
import Immutable from "immutable";
import customStyles from "./DynamicFilter.cssmodule.css";
import IconDelete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import typeCast from "mout/string/typecast";
import Select from "components/UI/Form/Select";
import { capitalize } from "helpers/stringUtils";
import { formatDate } from "helpers/stringUtils";

export default class DynamicFilterPastPresent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueYear: '',
      valueMonth: '',
    };
  }

  componentDidMount() {
    if (this.props.$$filter.get('filter_value'))
    {
    const v = this.props.$$filter.get('filter_value').split('-');
    //MODIF B : gestion cas "" pour éviter NaN
    this.setState({
        valueYear: v[0]!=="" ? parseInt(v[0], 10) : "",
        valueMonth: v[1]!=="" ? parseInt(v[1], 10) : "",
       });
    }
  }

  handleChangeYear(kind, target) {
    this.setState({ valueYear: target.value }, this.propagateFilterChanges);
  }
  handleChangeMonth(kind, target) {
    this.setState({ valueMonth: target.value }, this.propagateFilterChanges);
  }
  onClearFilter = (event) => {
    this.setState(
      { valueYear: '', valueMonth: '' },
      this.propagateFilterChanges
    );
  };
  propagateFilterChanges() {
    const { valueYear, valueMonth } = this.state;
    /*if (
      valueYear === "" ||
      valueMonth === "" ||
      (valueYear !== null && valueMonth === null) ||
      (valueMonth !== null && valueYear === null)
    )
      return;*/
    const {
      onChange,
      filterId,
    } = this.props;
    const valStr = valueYear === '' && valueMonth === '' ? '@reset@' : valueYear + "-" + valueMonth;
    onChange(filterId, valStr);
  }
  render() {
    const { $$filter } = this.props;

    const { valueMonth, valueYear } = this.state;

    let months = [];

    let j, d, l;
    for (j = 1; j <= 12; j++) {
      d = new Date(2010, j-1, 1);
      l = capitalize(formatDate(d / 1000, "mmmm"));
      months.push({ value: j, label: l });
    }
    let years = [];
    $$filter.get("filter_years").map((year, yk) => {
      years.push({ value: typeCast(year), label: year });
      return true;
    });
    months = Immutable.fromJS(months);
    years = Immutable.fromJS(years);
    const showDelete =
      valueMonth !== '' || valueYear !== '';
    return (
      <div style={{
        whiteSpace:'nowrap',
      }}>
        <span
          style={{
            display: "inline-block",
            verticalAlign: 'middle',
            width: showDelete ? "calc(100% - 50px)" : "100%",
          }}
          className={customStyles.filterField}
        >
          <Select
            variant={'outlined'}
            label={"Mois"}
            value={valueMonth}
            onChange={this.handleChangeMonth.bind(this)}
            style={{ width: "60%" }}
            options={months}
          />
          <Select
            variant={'outlined'}
            label={"Année"}
            style={{ width: "40%" }}
            value={valueYear}
            onChange={this.handleChangeYear.bind(this)}
            options={years}
          />
        </span>

        <If condition={showDelete}>
          <IconButton
            tooltip={"Supprimer"}
            onClick={this.onClearFilter}
            className={customStyles.filterTrash}
            style={{ verticalAlign: "middle" }}
          >
            <IconDelete style={{ verticalAlign: "middle" }} />
          </IconButton>
        </If>
      </div>
    );
  }
}
