import React, {
  Component
} from "react";
// import Fade from "@material-ui/core/Fade";
import {
  withStyles
} from "@material-ui/core/styles";
// import getCardImage from 'helpers/getCardImage'
import {
  connect
} from "react-redux";
import config from "config/api";
// import Button from "@material-ui/core/Button";
// import Container from "@material-ui/core/Container";
// import Paper from "@material-ui/core/Paper";
// import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";
// import BlockTitle from "components/UI/BlockTitle";
// import Rating from '@material-ui/lab/Rating';
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import Feedback from "components/UI/Feedback/Feedback";
import Template from "components/Template/Template";
import {
  withRouter
} from "react-router-dom";
// import {
  // fetchFeedback,
  // getFeedbackData,
  // getFeedbackAnswered,
  // getFeedbackError,
  // sendFeedback,
// } from "./ducks/feedback";
import {
  fetchData,
  getDataHome,
  // getLoadingError,
} from "ducks/asyncFetcher";
import {
  isStateKnown as authStateKnown,
  // isLoggedIn,
  // getCurrentUserRole,
  // getCurrentUser,
} from "components/UI/Account/ducks/authentication";
// import i18n from "helpers/i18n";
const AF_KEY = "home";
const styles = (theme) => {
  // console.log(theme)
  return {
  }
};
class WrapperFeedback extends Component {
  componentDidMount() {
    // this.props.onMount();
    if (this.props.authStateKnown !== undefined)
      this.props.onMount();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.authStateKnown === undefined && this.props.authStateKnown !== undefined)
    this.props.onMount();

  }
  render() {
    const {
      $$data,
    } = this.props;
    const {
      eventId
    } = this.props.match.params;
    return (
      <Template>
      <If condition={$$data !== undefined }>
        <Feedback eventId={eventId}/>
      </If>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    authStateKnown: authStateKnown(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({
        key: AF_KEY,
        fetchUrl: config.home
      }));
    },
  };
}
// export default ;
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(WrapperFeedback))
);
