import mirrorCreator from "mirror-creator";
import Immutable from "immutable";
import { createSelector } from "reselect";
import pipe from "helpers/redux-state-mutators.js";
import queryString from "query-string";
import config from "config/api";
import { getToken } from "components/UI/Account/ducks/authentication";
import getFetchOptions from "helpers/getFetchOptions"
const actionTypes = mirrorCreator(
  [
    "SET_FEEDBACK_DATA",
    "SET_FEEDBACK_ERROR",
    "SET_FEEDBACK_RESET",
    "SET_FEEDBACK_ANSWERED",
    "CLEAR_FEEDBACK_DATA",
    "CLEANUP"
  ],
  { prefix: "feedback/" }
);

const mutators = {
  setFeedbackData: (feedback) => ($$state) => $$state.set("feedback", feedback),
  setFeedbackError: (error) => ($$state) => $$state.set("error", error),
  setFeedbackReset: (str) => ($$state) => $$state.set("reset", str),
  setFeedbackAnswered: (answered) => ($$state) =>
    $$state.set("answered", answered),
  clearFeedbackData: () => ($$state) =>
    $$state.set("feedback", Immutable.fromJS({})),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_FEEDBACK_DATA:
      acts.push(mutators.setFeedbackData(action.feedback));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);
    case actionTypes.CLEANUP:
      acts.push(mutators.setFeedbackAnswered(undefined));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);
    case actionTypes.SET_FEEDBACK_ANSWERED:
      acts.push(mutators.setFeedbackAnswered(action.answered));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_FEEDBACK_ERROR:
      return pipe([mutators.setFeedbackError(action.error)], $$state);

    case actionTypes.SET_FEEDBACK_RESET:
      return pipe([mutators.setFeedbackReset(action.str)], $$state);

    case actionTypes.CLEAR_FEEDBACK_DATA:
      return pipe([mutators.clearFeedbackData()], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.feedback || Immutable.Map();
export const getFeedbackData = createSelector([getRoot], ($$state) =>
  $$state.get("feedback")
);
export const getFeedbackAnswered = createSelector([getRoot], ($$state) =>
  $$state.get("answered")
);
export const getFeedbackError = createSelector([getRoot], ($$state) =>
  $$state.get("error")
);
export const getFeedbackReset = createSelector([getRoot], ($$state) =>
  $$state.get("reset")
);

export function resetFeedback({eventId}) {
  return (dispatch) => {
    let url = config.feedback+'/reset/'+eventId
    let formData = {};
    // console.log(url)
    formData.feedback_form = true;

    fetch(url, getFetchOptions('POST', formData))
    .then((response) => response.json())
    .then((response) => {
      // console.log('RESPONSE IS', response)
      if (response.reset && response.reset === "complete") {
        dispatch(setFeedbackReset(Math.rand()));
      } else {
        dispatch(setFeedbackError(response.log));
      }
    })
    .catch((error) => dispatch(setFeedbackError(error.message)))
  };
}

export function fetchFeedback({ eventId }) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.feedback + "/get/" + eventId + "/";

    let formData = {};
    formData.contact_form = true;

    fetch(url, {
      credentials: "include",
      method: config.urlMethod || "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: queryString.stringify(formData, { arrayFormat: "bracket" }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.feedback && response.feedback === "complete") {
          dispatch(setFeedbackAnswered(response.answered));
          dispatch(setFeedbackData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setFeedbackError(response.log));
        }
      })
      .catch((error) => dispatch(setFeedbackError(error.message)));
  };
}
export function sendFeedback({ eventId, feedbackData }) {
  // console.log({login, password})
  return async (dispatch) => {
    let url = config.feedback + "/store/" + eventId + "/";
    // let formData = {};
    // formData.feedbackData = feedbackData;
    feedbackData.csrf = await getToken();
    // console.log(feedbackData)
    feedbackData.feedback_form = true;
    // console.log(feedbackData)
    // formData.contact_form = true;

    fetch(url, {
      credentials: "include",
      method: "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: queryString.stringify(feedbackData, { arrayFormat: "bracket" }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.feedback && response.feedback === "complete") {
          dispatch(setFeedbackAnswered(true));
          dispatch(clearFeedbackData());
        } else {
          dispatch(setFeedbackError(response.log));
        }
      })
      .catch((error) => dispatch(setFeedbackError(error.message)));
  };
}
export function setFeedbackData(feedback) {
  return {
    type: actionTypes.SET_FEEDBACK_DATA,
    feedback,
  };
}
export function cleanupFeedback() {
  return {
    type: actionTypes.CLEANUP,
  };
}
export function setFeedbackAnswered(answered) {
  return {
    type: actionTypes.SET_FEEDBACK_ANSWERED,
    answered,
  };
}
export function setFeedbackError(error) {
  return {
    type: actionTypes.SET_FEEDBACK_ERROR,
    error,
  };
}
export function setFeedbackReset(str) {
  return {
    type: actionTypes.SET_FEEDBACK_RESET,
    str,
  };
}
export function clearFeedbackData() {
  return {
    type: actionTypes.CLEAR_FEEDBACK_DATA,
  };
}
