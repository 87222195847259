import i18n from 'helpers/i18n';
export default ($$item) => {
  let r;
  switch ($$item.get('eventState')) {
    case 'pending':
      r = i18n.get('eventStatePending', { pretty: false });
      break;
    case 'finished':
      r = i18n.get('eventStateFinished', { pretty: false });
      break;
    case 'live':
      r = i18n.get('eventStateLive', { pretty: false });
      break;
    case 'replay':
      r = i18n.get('eventStateReplay', { pretty: false });
      break;
    default:
      r = 'unknown';
      break;
  }
  return r;
};
