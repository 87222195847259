import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import getCardGuests from 'components/UI/helpers/getCardGuests';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import { connect } from 'react-redux';
import queryString from 'query-string';
import config from 'config/api';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import BlockRatio from 'components/BlockRatio';
import Spinner from 'components/UI/Spinner';
import Template from 'components/UI/Template/Template';
import { withRouter } from 'react-router-dom';
import { toHHMM } from 'components/UI/helpers/time';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Prettyfier from 'components/UI/Prettyfier';
import { fetchMedia, fetchMedias, getMediaData, getMediasData } from './ducks/media';
import { fetchData, getDataHome, getLoadingError, getMediaEvents } from 'ducks/asyncFetcher';
import { isLoggedIn, getCurrentUserRole, getCurrentUser, setUniverse, getUniverse } from 'components/UI/Account/ducks/authentication';
import i18n from 'helpers/i18n';
import { Link } from 'react-router-dom';

const AF_KEY = 'home';

const styles = (theme) => {

  return {
    root: {
      '& .MuiTypography-subtitle1': {
        textAlign: 'left',
      },
      '& .MuiSvgIcon-root': {
        verticalAlign: 'middle',
        marginTop: -2
      }

    },
    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.medias && theme.palette.medias.backgroundColor ? theme.palette.medias.backgroundColor : theme.palette.primary.light,
        zIndex: -1
      }
    },
    paper: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& a': {
        color: 'inherit'
      }
    },
    inline: {
      display: 'inline-block',
    },

    breadCrumbs: {
      marginTop: theme.spacing(3),
      fontSize: '0.8125rem',
      color: theme.palette.medias && theme.palette.medias.breadCrumbColor ? theme.palette.medias.breadCrumbColor : theme.palette.primary.main,
      '& a': {
        color: theme.palette.medias && theme.palette.medias.breadCrumbColor ? theme.palette.medias.breadCrumbColor : theme.palette.primary.main,
        textDecoration:'underline',
        display: 'inline-block',
        verticalAlign: 'middle',
      },
    },

    itemVideo: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2)
    },
    itemDescription: {
      marginBottom: theme.spacing(2)
    },
    itemDuration: {
      marginBottom: theme.spacing(2)
    },
    itemCopyright: {
      color: theme.palette.primary.main
    },
    itemGuests: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    navWrapper: {
      position: 'relative',
      height: 60
    },
    navButton: {
      position: 'absolute',
      bottom: 0,
    },
    navPrevious: {
      left: 0
    },
    navNext: {
      right: 0
    },
    itemTitle: undefined,
    itemTheme: undefined,
  };
};
class MediaPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = { logMessage: false };
  }
  componentDidMount() {
    const mediaId = this.props.match.params.id;
    const uri = queryString.parse(window.location.search);
    this.props.onMount({ mediaId, params: uri });
    if (this.props.match && this.props.match.params.universe) this.props.setUniverse(this.props.match.params.universe);

    // this._startTimer();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.match.params.id !== this.props.match.params.id) {
      const mediaId = this.props.match.params.id;
      const uri = queryString.parse(window.location.search);
      this.props.onMount({ mediaId, params: uri });
    }
    // if (oldProps.$$mediaData !== this.props.$$mediaData) {
    //   this._startTimer();
    // }
  }

  gotoMedia = (direction) => {
    const {$$card, $$medias, universe, useUniverseInLinks} = this.props;
    const index = $$medias.findIndex($$r => $$r.get('id') === $$card.get('id'))
    let newMedia = false;
    const debug = false;
    if (direction === 'next' && index < ($$medias.size-1)) {
      if (debug) console.log('next 1');
      newMedia = $$medias.get(index+1)
    } else if (direction === 'next' && index >= ($$medias.size -1)) {
      if (debug) console.log('next 2');
      newMedia = $$medias.get(0);
    } else if (direction === 'previous' && index > 0) {
      if (debug) console.log('previous 1');
      newMedia = $$medias.get(index-1)
    } else if (direction === 'previous' && index === 0) {
      if (debug) console.log('previous 2');
      newMedia = $$medias.get($$medias.size -1)
    }

    //MODIF B : ajout useUniverseInLinks pour risque compat car avant universe était toujours undefined (getUniverse manquait)
    this.props.history.push(
      (useUniverseInLinks && universe !== undefined ? '/'+universe : '')
       + '/media/'+newMedia.get('id')
       );
  }

  _redirectToLogin = (action) => {
    let pn = window.location.pathname;
    // console.log(pn[pn.length -1])
    if (pn[pn.length -1] === '/') {
      pn = pn.substring(0, pn.length -1);
      // console.log('mathced!')
    }

    let returnUrl = pn+window.location.search;
    // console.log(returnUrl)
    returnUrl = encodeURIComponent(returnUrl);
    // console.log(returnUrl)
    this.props.history.push(
      "/login?action="+action+"&_pn="+
      returnUrl
    )
  }

  render() {
    const { $$data, userRole, classes, $$card, $$medias, templateProps } = this.props;
    const $$guests = getCardGuests !== undefined ? getCardGuests($$card) : undefined;

    return (
      <Template current="medias" resize={false} {...templateProps}>
        <Box className={classes.paperWrapper} elevation={3}>
          <Paper square elevation={0} className={clsx(classes.root, classes.paper)}>

            <Choose>
              <When condition={$$data !== undefined && userRole === undefined}>
                <Box my={6} align="center">
                  <Typography
                    variant="h5"
                    gutterBottom={true}
                    className={clsx(classes.legend, classes.h6extends)}
                    >
                    Merci de vous connecter pour accéder à ce contenu
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '0 5px'}}
                    onClick={e => this._redirectToLogin('login')}>
                    {" "}
                    {i18n.get('loginAction', {pretty: false})}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '0 5px'}}
                    onClick={e => this._redirectToLogin('register')}
                    >
                    {' '}
                    {i18n.get('subscribeAction', {pretty: false})}
                  </Button>
                </Box>
              </When>
              <When
                condition={
                  $$data !== undefined &&
                  (userRole === 'member' || userRole === 'admin' || userRole === 'staff')
                }
                >
                <If condition={$$card !== undefined}>
                  <Grid container alignContent="center">
                    <Grid item xs={12} className={classes.breadCrumbs}>
                      <Typography variant="subtitle1"><Link to="/">{i18n.get('home', {pretty: false})}</Link>&nbsp;&nbsp;&gt;&nbsp;&nbsp;<Link to="/medias">{i18n.get('Medias', {pretty: false})}</Link>&nbsp;&nbsp;&gt;&nbsp;&nbsp;<Prettyfier text={$$card.get('mediaTitle')}/></Typography>
                    </Grid>

                    <Grid item xs={12} >
                      <BlockRatio ratio={'16_9'} className={classes.itemVideo}>
                        <iframe
                          title="media"
                          src={$$card.get('mediaLinkLive')}
                          className={'embed'}
                          width="100%"
                          height="100%"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          allowFullScreen
                          />
                      </BlockRatio>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" component="div" gutterBottom className={classes.itemTheme}>
                        <Paragrapher
                          text={$$card.get('mediaTheme')}
                          elementClassName={classes.content}
                          prettyfy={true}
                          element={'div'}
                          />
                      </Typography>
                      <Typography variant="h5" component="div" gutterBottom className={classes.itemTitle}>
                        <Paragrapher
                          text={$$card.get('mediaTitle')}
                          elementClassName={classes.content}
                          prettyfy={true}
                          element={'div'}
                          />
                      </Typography>
                      <If condition={$$guests !== false && $$guests.size > 0}>
                        <Box className={classes.itemGuests}>
                          <Typography variant={'caption'} gutterBottom style={{marginBottom: 8, display: 'block'}}>{i18n.get('mediaAnimatedBy', {pretty: false})}</Typography>
                          {$$guests.map(($$g, aIdx) => {
                            return (
                              <Box w={'100%'} key={'av_'+aIdx} style={{clear: 'both'}}>
                                <Avatar src={$$g.get('dest') !== false ? $$g.get('dest') : undefined} alt={$$g.getIn(['attributes','name'])} style={{float: 'left', marginRight: 8, marginBottom: 8, backgroundColor: '#fff'}}/>
                                <Typography variant={'body1'} >{$$g.getIn(['attributes','name'])}</Typography>
                                <Typography variant={'caption'} gutterBottom>{$$g.getIn(['attributes','description'])}</Typography>
                              </Box>)
                            }).toArray()}
                          </Box>
                        </If>

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box mt={0} mb={1} px={4}>
                          <If condition={$$card.get('mediaDescription')}>
                            <Typography variant="body1" component="div" gutterBottom className={classes.itemDescription}>
                              <Paragrapher
                                text={$$card.get('mediaDescription')}
                                elementClassName={classes.content}
                                prettyfy={true}
                                element={'div'}
                                />
                            </Typography>
                          </If>
                          <Typography variant="body1" component="div" gutterBottom className={classes.itemDuration}>
                            <If condition={$$card.get('mediaDuration') && $$card.get('mediaDuration') !== false && $$card.get('mediaDuration') !== false && $$card.get('mediaDuration') !== '' && $$card.get('mediaDuration') > 0}>
                              <Typography variant="body1" component="div" gutterBottom>
                                <WatchLaterIcon/> {toHHMM($$card.get('mediaDuration')*60)}
                                </Typography>
                              </If>
                            </Typography>

                            <Typography variant="caption" className={classes.itemCopyright}>
                              <span className={classes.inline}>{i18n.get('mediaCopyrightWarning')}</span>
                            </Typography>
                          </Box>
                        </Grid>
                        <If condition={$$medias !== undefined && $$medias.size > 1}>
                          <Grid item xs={12} className={classes.navWrapper}>
                            <Button variant="outlined" color="secondary" className={clsx(classes.navButton, classes.navPrevious)} onClick={e => this.gotoMedia('previous')}>{i18n.get('mediaPrevious',{pretty: false})}</Button>
                            <Button variant="contained" color="primary" className={clsx(classes.navButton, classes.navNext)} onClick={e => this.gotoMedia('next')}>{i18n.get('mediaNext',{pretty: false})}</Button>
                          </Grid>
                        </If>
                      </Grid>
                    </If>
                    <If condition={$$card === undefined}>
                      <Box my={8} align="center">
                        <Typography
                          variant="h5"
                          gutterBottom={true}
                          className={clsx(classes.legend, classes.h6extends)}
                          >
                          Il n'y a pas de Media pour cette webconférence :(
                          </Typography>
                          <Box mt={3}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                window.location.replace('/');
                              }}
                              >
                              Cliquez pour retourner à l'accueil
                            </Button>
                          </Box>
                        </Box>
                      </If>
                    </When>
                    <When condition={$$data !== undefined && userRole === 'speaker'}>
                      <p>No place for speaker</p>
                    </When>
                    <Otherwise>
                      <div className={'Loading'}>
                        <Spinner style={{ marginTop: 200 }}>Chargement...</Spinner>
                      </div>
                    </Otherwise>
                  </Choose>
                </Paper>
              </Box>
            </Template>
          );
        }
      }

      function mapStateToProps(state) {
        const props = {
          $$data: getDataHome(state),
          $$medias: getMediasData(state),
          error: getLoadingError(state),
          isLoggedIn: isLoggedIn(state),
          userRole: getCurrentUserRole(state),
          $$user: getCurrentUser(state),
          $$card: getMediaData(state),
          universe:getUniverse(state),
        };
        return props;
      }
      function mapDispatchToProps(dispatch) {
        return {
          onMount: (props) => {
            dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
            dispatch(fetchMedias(props));
            dispatch(fetchMedia(props));
          },
          setUniverse: (universe) => {
            dispatch(setUniverse(universe));
          }

        };
      }

      // export default ;
      export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MediaPlayer)));
