import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import img1 from 'assets/images/home_medical1.jpg';
import img2 from 'assets/images/home_medical2.jpg';
import bgimg1 from 'assets/images/event_rose.png';
import bgimg2 from 'assets/images/event.png';

import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import DateRangeIcon from '@material-ui/icons/DateRange';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

const styles = (theme) => {
  return {
    root: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: theme.spacing(1),
    },
    mainTable: {
      border: 'none',
      borderCollapse: 'collapse',
      margin: '0',
      marginBottom: theme.spacing(15),
      '& td': {
        verticalAlign: 'top',
        padding: 0,
      },
      '& .MuiTypography-root': {
        position: 'relative',
        zIndex: 5,
      },
    },
    leftBox1: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    photo: {
      display: 'block',
    },
    middleBox: {
      backgroundColor: '#f3b7cc', //'#f48864' //'#F88D5E';
      color: '#444',
      padding: '45px 48px !important',
      paddingBottom: '5px !important',
      position: 'relative',
      zIndex: 10,
      //background:'url('+bgimg1+')',
    },
    bgImageMiddle: {
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'rotate(180deg)',
      opacity: '0.6',
      //filter: 'sepia(0%) hue-rotate(160deg) saturate(160%)',
      //filter: 'sepia(0%) hue-rotate(330deg) saturate(140%)',
      zIndex: -1,
    },
    middleBoxOffres: {
      backgroundColor: '#0097D9',
      color: '#fff',
      padding: '100px 48px !important',
      paddingBottom: '5px !important',
      position: 'relative',
      zIndex: 10,
    },
    bgImageMiddleOffres: {
      position: 'absolute',
      top: 0,
      right: 0,
      opacity: '0.5',
      transform: 'rotate(180deg)',
      filter: 'hue-rotate(20deg)',
      zIndex: -1,
    },
    rightBox: {
      padding: 0,
    },
    gastroBox: {
      color: '#2a82b0',
      verticalAlign: 'middle',
      height: '343px',
      paddingTop: '121px',
      borderTop: '#ccc 1px solid',
      '& .MuiTypography-root': {
        fontWeight: 'bold',
      },
      '& > .MuiButton-root': {
        color: 'white',
        backgroundColor: '#0097da',
        fontWeight: 'bold',
        marginTop: '1em',
      },
      [theme.breakpoints.down('xs')]: {
        height: '200px',
        paddingTop: '60px',
      },
    },
    gynecoBox: {
      color: '#D1829E',//'#f3b7cc',//'#d45a31',
      verticalAlign: 'middle',
      height: '343px',
      paddingTop: '121px',
      borderTop: '#ccc 1px solid',
      '& .MuiTypography-root': {
        fontWeight: 'bold',
      },
      '& > .MuiButton-root': {
        color: 'black',
        backgroundColor: '#f3b7cc',//'#e94f2b',
        fontWeight: 'bold',
        marginTop: '1em',
      },
      [theme.breakpoints.down('xs')]: {
        height: '200px',
        paddingTop: '60px',
      },
    },
    gastroBox2: {
      color: '#2a82b0',
      verticalAlign: 'middle',
      height: '578px',
      paddingTop: '235px',
      borderBottom: '#ccc 1px solid',
      '& .MuiTypography-root': {
        fontWeight: 'bold',
      },
      '& > .MuiButton-root': {
        color: 'white',
        backgroundColor: '#0097da',
        fontWeight: 'bold',
        marginTop: '1em',
      },
      [theme.breakpoints.down('xs')]: {
        height: '200px',
        paddingTop: '60px',
      },
    },
    leftBox2LargeScreen: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    leftBox2SmallScreen: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    image2: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        '& img': {
          width: 'auto',
          height: 'auto',
          maxHeight: '530px',
          maxWidth: '100%',
        },
      },
    },
    universeTitle: {
      fontSize: '1.9rem',
      fontWeight: 'bold',
      marginBottom: theme.spacing(4),
    },
    universeSubTitle: {
      fontWeight: 'bold',
      fontSize: '120%',
      marginBottom: theme.spacing(4),
    },
    universeText: {
      fontSize: '100%',
      marginBottom: theme.spacing(4),
    },
    ListIcon: {
      height: '45px',
      width:'auto',
      padding:'7px',
      marginRight:'17px',
      color:'#444',
      border:'#fff7 1px solid',
      borderRadius:'50%',
    },
  };
};

class HomeDefault extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    const xs = 12,
      sm = 6,
      md = 4,
      lg = 4;

    return (
      <div>
        <Grid
          container
          columns={12}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          className={classes.root}
          spacing={0}
        >
          <Grid item xs={xs} sm={sm} md={md} lg={lg} className={classes.leftBox1}>
            <img src={img1} className={classes.photo} width="400" height="687" />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md} lg={lg} className={classes.middleBox}>
            <Typography align="left" variant="h2" className={classes.universeTitle}>
              Les prochaines webconférences
            </Typography>
            <Typography align="left" variant="subtitle1" className={classes.universeSubTitle}>
              Des évènements en direct et intéractifs mis en place par votre interlocuteur Norgine
            </Typography>
            <List component="nav">
              <ListItem className={classes.ListItem}>
                <ListItemIcon>
                  <LocalHospitalIcon className={classes.ListIcon}/>
                </ListItemIcon>
                <ListItemText
                  className={classes.ListItemText}
                  primary="Des sujets proches de votre environnement professionnel et de votre spécialité"
                />
              </ListItem>
              <ListItem className={classes.ListItem}>
                <ListItemIcon>
                  <DateRangeIcon className={classes.ListIcon}/>
                </ListItemIcon>
                <ListItemText
                  className={classes.ListItemText}
                  primary="Des sessions courtes à des dates et des horaires différents pour s’adapter à votre emploi du temps"
                />
              </ListItem>
              <ListItem className={classes.ListItem}>
                <ListItemIcon>
                  <QuestionAnswerIcon className={classes.ListIcon}/>
                </ListItemIcon>
                <ListItemText
                  className={classes.ListItemText}
                  primary="La possibilité de poser toutes vos questions en direct aux experts"
                />
              </ListItem>
            </List>
            <img className={classes.bgImageMiddle} src={bgimg1} />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md} lg={lg} className={classes.rightBox}>
            <Box align="center" className={classes.gastroBox}>
              <Typography variant="subtitle1">Gastro-entérologie</Typography>
              <Button variant="contained" onClick={(e) => this.props.history.push('gastro')}>
                Découvrir
              </Button>
            </Box>
            <Box align="center" className={classes.gynecoBox}>
              <Typography variant="subtitle1">Gynécologie</Typography>
              <Button variant="contained" onClick={(e) => this.props.history.push('/gyneco')}>
                Découvrir
              </Button>
            </Box>
          </Grid>
          <Grid item xs={xs} sm={sm} md={md} lg={lg} className={classes.leftBox2LargeScreen}>
            <Box align="center">
              <Box align="center" className={classes.gastroBox2}>
                <Typography variant="subtitle1">Gastro-entérologie</Typography>
                <Button variant="contained" onClick={(e) => this.props.history.push('/offers')}>
                  Découvrir
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={xs} sm={sm} md={md} lg={lg} className={classes.middleBoxOffres}>
            <Typography align="left" variant="h2" className={classes.universeTitle}>
              Offres entre professionnels
            </Typography>

            <Typography align="left" variant="subtitle1" className={classes.universeSubTitle}>
              Vous cherchez un(e) remplaçant(e), des remplacements dans votre région&nbsp;? Vous souhaitez
              vous installer ou céder votre cabinet&nbsp;?
            </Typography>

            <Typography align="left" variant="subtitle2" className={classes.universeText}>
              Consultez les annonces déposées par vos confrères gastro-entérologues
            </Typography>
            <img className={classes.bgImageMiddleOffres} src={bgimg2} />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md} lg={lg} className={classes.leftBox2SmallScreen}>
            <Box align="center">
              <Box align="center" className={classes.gastroBox2}>
                <Typography variant="subtitle1">Gastro-entérologie</Typography>
                <Button variant="contained" onClick={(e) => this.props.history.push('/offers')}>
                  Découvrir
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={xs} sm={sm} md={md} lg={lg} className={classes.image2}>
            <Box align="center">
              <img src={img2} className={classes.photo} width="400" height="578" />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(HomeDefault));
