import React from "react";
// import MUIPagination from 'material-ui-pagination'
import MUIPagination from "@material-ui/core/TablePagination";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
// import SelectField from '@material-ui/core/Select'
import { connect } from "react-redux";
import { makeSelectors, makeActionCreators } from "./ducks/dynamicList";
// import { Grid, Row, Col } from 'react-flexbox-grid';

class PaginationCell extends React.Component {
  render() {
    const { children } = this.props;
    return <div {...this.props}>{children}</div>;
  }
}

class Pagination extends React.Component {
  onLimitSelectorChange = (event, index, limit) => {
    console.log('limit', limit)
    this.props.onLimitChange(limit);
  };

  render() {
    let options = [],
      i,
      limitOptions = [10, 25, 50, 100, 250];
    const lom = limitOptions.length;
    for (i = 0; i < lom; i++) {
      options.push(
        <MenuItem
          key={"lmo" + i}
          value={limitOptions[i]}
          primary={limitOptions[i] + " par page"}
        />
      );
    }

    // const muiStyle = {
    //   paginationContainer: {
    //     display: 'inline-block',
    //     margin: '0 5px',
    //     height: 50,
    //     verticalAlign: 'middle'
    //   },
    //   limitContainer: {
    //     display: 'inline-block',
    //     margin: '0 5px',
    //     height: 50,
    //     verticalAlign: 'middle'
    //   },
    //   paginationLabel: {
    //     display: 'inline-block',
    //     verticalAlign: 'middle',
    //     margin: '0 5px 0 0',
    //     height: 50
    //   }
    // }
    //
    const {
      currentLimit,
      onLimitChange,
      current,
      total,
      onPageChange,
    } = this.props;
    const paginationProps = {
      count: total,
      page: current - 1,

      onPageChange: function (a, b, c) {
        onPageChange(b + 1);
      },
      onRowsPerPageChange: function (a, b, c) {
        onLimitChange(b.props && b.props.value ? b.props.value : b.key);
      },
      
      //compat
      onChangePage: function (a, b, c) {
        onPageChange(b + 1);
      },
      onChangeRowsPerPage: function (a, b, c) {
        //onLimitChange(b.key);
        onLimitChange(b.props && b.props.value ? b.props.value : b.key);
      },
      

      rowsPerPage: currentLimit,
      component: PaginationCell,
      labelDisplayedRows: ({ from, to, count }) => {
        return "" + from + "-" + to + "/" + count;
      },
      labelRowsPerPage: "Lignes par pages:",
      rowsPerPageOptions: [10, 25, 50, 100],
    };
    return (
      <Paper>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="space-around"
          style={{ height: 60 }}
        >
          <Grid item xs={12}>
            <MUIPagination {...paginationProps}></MUIPagination>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
const mapStateToProps = () => (state, ownProps) => {
  const selectors = makeSelectors(ownProps.listId);
  return {
    current: selectors.getCurrentPage(state),
    currentLimit: selectors.getCurrentLimit(state),
    total: selectors.getPageTotal(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const actionCreators = makeActionCreators(ownProps.listId);
  return {
    onPageChange: (page) =>
      dispatch(actionCreators.changePage(ownProps.moduleConfig, page)),
    onLimitChange: (limit) =>
      dispatch(actionCreators.changeLimit(ownProps.moduleConfig, limit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
