import queryString from 'query-string';

export default function getFetchOptions(method, data, encodeData = true) {
  method = method ? method : 'GET';
  let options;
  if (method === 'POST') {
    options = {
      credentials: 'include',
      method: method || 'POST',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
    };
    if (data && encodeData) options.body = queryString.stringify(data, { arrayFormat: 'bracket' });
    else if (data && !encodeData) options.body = data;
  } else {
    options = {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    };
  }
  return options;
}
