/* CAUTION: When using the generators, this file is modified in some places.
 *          This is done via AST traversal - Some of your formatting may be lost
 *          in the process - no functionality should be broken though.
 *          This modifications only run once when the generator is invoked - if
 *          you edit them, they are not updated again.
 */
// import 'core-js/es/map';
// import 'core-js/es/set';
import React, { Component } from 'react';

// import PropTypes from 'prop-types'
import { Provider } from 'react-redux';

import Main from './components/App';

// import config from './config/api'
import createStore from './stores';
// import injectTapEventPlugin from 'react-tap-event-plugin';

import promiseFinally from 'promise.prototype.finally';
promiseFinally.shim();

const store = createStore();

// injectTapEventPlugin();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Main />
      </Provider>
    );
  }
}

export default App;
