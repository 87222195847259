import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Immutable from "immutable";
import ModuleConfigProvider from "components/UI/ModuleConfigProvider";
import queryString from 'query-string';
// import getStyles from 'helpers/getStyles';
import isArray from "mout/lang/isArray";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import colors from "config/colors";
import BlockButton from "components/UI/BlockButton";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import { withRouter } from "react-router-dom";

// import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from '@material-ui/core/Table';
// import {Table, TableHeaderColumn, TableRow, TableRowColumn} from '@material-ui/core/Table';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
// import TableHeader from '@material-ui/core/TableHead';
// import TableHeaderColumn from '@material-ui/core/TableSortLabel';
import TableHeaderColumn from "@material-ui/core/TableCell";
import TableRowColumn from "@material-ui/core/TableCell";

// import TextField from '@material-ui/core/TextField';
import Dialog from "components/UI/Dialog/Dialog";
import Subheader from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import SortIcon from "@material-ui/icons/SwapVert";
import Icon from "components/Icon";
// import InterviewEmbed from '../InterviewEmbed';
import Spinner from "components/UI/Spinner";
import Pagination from "./Pagination";
// import { Grid, Row, Col } from 'react-flexbox-grid';

import DynamicFilterPastPresent from "./DynamicFilterPastPresent";
import DynamicFilterSelectAsync from "./DynamicFilterSelectAsync";
import DynamicFilterSelect from "./DynamicFilterSelect";
import DynamicFilterSearch from "./DynamicFilterSearch";

import styles from "./DynamicList.cssmodule.css";
import { formatDate } from "helpers/stringUtils";
// import {
//   red700,
// } from 'material-ui/styles/colors';
// var dateFormat = require('dateformat');

// const theme = {
//   rowCell: {
//     padding: 0,
//     textAlign: 'left'
//   }
// };

// const align = ($$field) => {
//   switch ($$field.get('type')) {
//     case 'numeric':
//     return 'right';
//     default:
//     return 'left';
//   }
// }

// const Header = ({$$field, ...props}) => {
//   return (
//     <TableHeaderColumn style={{textAlign: align($$field)}} {...props} >
//       {$$field.get('label')}
//     </TableHeaderColumn>
//   );
// };

const ColumnButton = ({ moduleConfig, $$row, $$action, ...props }) => {
  let button;
  const { rowIndex, active } = props;

  const clickWrapper = (event) => {
    // console.log('CLICK', $$action.toJS())
    props.onActionClick(event, $$action, $$row, rowIndex);
  };
  // console.log('moduleConfig', moduleConfig)
  // if (!$$action.get('url') && $$action.get('name') != 'edit') {
  if ($$action.get("name") !== "edit") {
    button = (
      <RowIcon
        active={active}
        palette={colors}
        $$action={$$action}
        onTouchTap={(e) => clickWrapper(e)}
        readOnly={$$row.get("readOnly")}
      />
    );
  } else {
    let editUrl = !moduleConfig.editUrl
      ? `/${moduleConfig.module}/${$$action.get("name")}/${$$row.get("id")}`
      : `/${moduleConfig.editUrl}/${$$row.get("id")}`;
    if (moduleConfig.returnTo) editUrl += "?rt=" + moduleConfig.returnTo;
    button = (
      <Link to={editUrl}>
        <RowIcon
          active={active}
          palette={colors}
          $$action={$$action}
          readOnly={$$row.get("readOnly")}
        />
      </Link>
    );
  }
  let style = props.style ? props.style : {};
  style.width = "50px";
  style.padding = "0";
  // style.backgroundColor = props.active
  // ? colors.primaryColor
  // : colors.backgroundColor;
  style.backgroundColor = props.active ? colors.primaryColor : "transparent";
  style.overflow = "visible";
  style.textAlign = "center";
  // console.log('debug', styles)
  return <TableRowColumn style={style}>{button}</TableRowColumn>;
  // }
};

const RowIcon = ({ $$action, onTouchTap, readOnly, active, ...props }) => {
  // console.log('DBG', $$action.toJS())
  let icon = $$action.get("icon") || $$action.get("name");
  if (icon === "edit" && readOnly) icon = "view";
  else if (icon === "delete" && readOnly) icon = "delete_forever";
  else if (icon === "preview" && readOnly) icon = "preview";

  // console.log(icon, readOnly)
  return (
    <Icon
      icon={icon}
      button={true}
      tooltip={$$action.get("title")}
      onTouchTap={onTouchTap !== undefined ? onTouchTap : undefined}
      readOnly={readOnly}
      className={active ? styles.activeRowColumn : ""}
    />
  );
};

// const Cell = ({$$field, value}) => {
//   // console.log($$field.get('type'));
//   switch($$field.get('type')) {
//     default:
//     break;
//     case 'SelectField':
//     var isInt = parseInt(value, 10);
//     if (isInt) {
//       value = isInt;
//     }
//     console.log('options', $$field.get('options').toJS(), value)
//     var options = $$field.get('options').toJS();
//     value = options[value] ? options[value] : value;
//     break;
//   }
//   return (
//     <TableRowColumn style={{textAlign: align($$field)}}>{value}</TableRowColumn>
//   );
// };

const FieldLinkMapLabel = ({ $$fieldLinkMap, value }) => {
  const option = $$fieldLinkMap.find(
    ($$option) => $$option.get("value") === value
  );
  if (option) {
    var text = option.get("text");
    let decodedValue;
    var r = new RegExp(/[[\]]/);
    if (
      text !== null &&
      text !== undefined &&
      r.test(text.toString())
    ) {
      decodedValue = (
        <Paragrapher text={text.toString()} prettyfy={true} />
      );
    } else {
      decodedValue = text;
    }


    return <span>{decodedValue}</span>;
  } else return <em title={value}></em>;
};

class Cell extends React.Component {
  render() {
    const {
      $$field,
      value,
      style,
      $$fieldLinkMap,
      rowId,
      ...cellProps
    } = this.props;

    // console.log($$field.get('type'));
    let decodedValue;
    if ($$field) {
      // console.log($$field.get('id'), $$field.get('type'))
      switch ($$field.get("type")) {
        case "Date":
        case "date":
        case "dateTime":
        case "DatePicker":
          // decodedValue = dateFormat(value*1000, 'dd/mm/yyyy');
          decodedValue = value === null ? "-" : formatDate(value, "dd/mm/yyyy");
          // console.log($$field.get('id'), value, decodedValue)
          break;
        case "Avatar":
          if (value !== null && value.length > 4)
            decodedValue = (
              <Avatar
                key={"avatar_" + $$field.get("id")}
                size={30}
                src={$$field.get("previewUrl") + value}
              />
            );
          else if (value !== null && value.length <= 4)
            decodedValue = (
              <Avatar key={"avatar_" + $$field.get("id")} size={30}>
                {value}
              </Avatar>
            );
          else
            decodedValue = (
              <Avatar key={"avatar_" + $$field.get("id")} size={30}></Avatar>
            );
          break;
        case "MultiDateRangePicker":
          // console.log('hmm', value)
          try {
            // console.log('raw values', value)
            let values = JSON.parse(value);
            let rVals,
              dVal,
              els = [];
            if (isArray(values)) {
              // console.log('decoded values', values)
              values.forEach((row, ridx) => {
                rVals = [];
                row.forEach((fld) => {
                  dVal = formatDate(fld, "dd/mm/yyyy");
                  // console.log(dVal)
                  rVals.push(dVal);
                });
                els.push(<div key={"t" + ridx}>{rVals.join(" => ")}</div>);
              });
              decodedValue = els;
              // let decodedArr = [], j;
              // const vl = values.length;
              // for (j=0; j<vl; j++) {
              //   decodedArr[j] = [];
              //   rl = values[j].length
              //   for (k=0; k<rl; k++) {
              //     decodedArr[j].push(options[values[j][k]]);
              //   }
              // }
              // console.log('ooo', decodedArr)
            }
          } catch (err) {
            console.log("outdate data structure", err);
          }
          break;
        case "SelectField":
        case "select":
        case 'SelectBlocks':
          // var isInt = parseInt(value, 32);
          // if (isInt) {
          // decodedValue = isInt;
          // } else {
          let options;
          // console.log('OPTONS ARE ', options)
          if ($$field.get("multiple")) {
            try {
              options = $$field.get("options");
              let values = JSON.parse(value);

              let decodedArr = [];
              if (values !== undefined && values.length !== undefined) {
                if (values.length > 0) {
                  options.map((o) => {
                    if (values.indexOf(o.get("value")) >= 0) {
                      decodedArr.push(o.get("label"));
                    }
                    return true;
                  });
                }
                decodedValue = decodedArr.join(", ");
              } else {
                decodedValue = "-";
              }
            } catch (err) {
              console.log("outdate data structure", err);
            }
          } else {
            const defValue = $$field.get("defValue");
            // console.log('defValue', defValue)
            const foundValue = $$field.get("options").find((o) => {
              return o.get("value") === value ? o : false;
            });
            const foundDefault =
              defValue !== undefined
                ? $$field.get("options").find((o) => {
                    return o.get("value") === defValue ? o : false;
                  })
                : false;
            if (value) {
              decodedValue =
                foundValue !== false && foundValue !== undefined
                  ? foundValue.get("label")
                  : value;
            } else if (defValue && foundDefault !== false) {
              decodedValue = foundDefault.get("label");
            }
          }
          // console.log('options', options, decodedValue, options[value], options[decodedValue]);
          break;

        case "AutoComplete":
          decodedValue = <FieldLinkMapLabel {...{ $$fieldLinkMap, value }} />;
          break;
        case "AutoCompleteMultiple":
          let values = value;
          if (typeof values === "string") {
            values = [values];
          }

          decodedValue = (
            <div style={{ display: "inline-block", width: "auto" }}>
              {values.map((singleValue) => (
                <div key={singleValue}>
                  <FieldLinkMapLabel
                    {...{ $$fieldLinkMap, value: singleValue }}
                  />
                </div>
              ))}
            </div>
          );

          break;

        case "File":
        case "file":
        case "upload":
          // console.log('FOUND FILE')
          // decodedValue = value ? (
          //   <Icon icon={'file'} button={true} tooltip={$$field.get('label')} onClick={(event) => {
          //       event.preventDefault();
          //       console.log('file',value)
          //       console.log('file',value)
          //       console.log('file',value)
          //       // return;
          //       // window.open($$field.get('previewUrl')+'/'+value);
          //       if (value.match(/\[/)) {
          //         const valueArr = JSON.parse(value);
          //         const url = $$field.get('previewUrl') +'/'+valueArr[0].dest;
          //         console.log('opening', url)
          //         window.open(url);
          //       } else {
          //         // console.log('opening', value)
          //         // const url = $$field.get('previewUrl') +'/'+value;
          //         // console.log('url is', url);
          //         window.open(value);
          //       }
          //     }}  />
          // ) : '';
          const valueArr = value ? JSON.parse(value) : false;
          const previewUrl =
            value && valueArr && valueArr[0] && value !== null && value.length > 0
              ? $$field
                  .get("previewUrl")
                  .replace(/\{\w+Id\}/, rowId)//MODIF B
                  /*
                  .replace("{eventId}", rowId)
                  .replace("{themeId}", rowId)
                  .replace("{podcastId}", rowId)
                  .replace("{newsId}", rowId)
                  .replace("{documentationId}", rowId)
                  */
                   + "/" +
                valueArr[0].dest
              : false;
          // console.log(previewUrl)

          decodedValue = value !== null && value.length > 0? (
            <Icon
              icon={
                <img src={previewUrl !== false ? previewUrl : undefined} alt={"img"} width="100%" heigh="100%" />
              }
              button={false}
              tooltip={$$field.get("label")}
            />
          ) : (
            ""
          );
          // console.log('dd', rowId, value, decodedValue)

          // style.width = '50px';
          // style.padding = '0';
          // style.overflow = 'visible';

          break;
        // case 'Files':
        //   decodedValue = value ? (
        //     <Icon icon={'file'} button={true} tooltip={$$field.get('label')} onTouchTap={(event) => {
        //         event.preventDefault();
        //         // window.open($$field.get('previewUrl')+'/'+value);
        //         value = JSON.parse(value);
        //         console.log(value);
        //         return;
        //         console.log('opening', value)
        //         window.open(value);
        //       }}  />
        //   ) : '';
        //     style.width = '50px';
        //     style.padding = '0';
        //     style.overflow = 'visible';
        //
        // break;
        case "Checkbox":
        case "RadioButton":
          // console.log($$field.get('labels').get(value))
          if (
            $$field.get("options") !== undefined &&
            $$field.get("options").get(value)
          )
            decodedValue = $$field.get("options").get(value);
          else if (
            $$field.get("tf_default") !== undefined &&
            $$field.get("options").get($$field.get("tf_default"))
          )
            decodedValue = $$field
              .get("options")
              .get($$field.get("tf_default"));
          else if ($$field.get("tf_default") !== undefined)
            decodedValue = $$field.get("tf_default");
          else decodedValue = value;
          break;
        // case 'RadioButton':
        // console.log($$field.toJS());
        // if ($$field.get('options') !== undefined && $$field.get('labels').get(value))
        //   decodedValue = $$field.get('labels').get(value);
        // else if ($$field.get('tf_default') !== undefined)
        //   decodedValue = $$field.get('tf_default');
        // else
        //   decodedValue = value;
        // break;
        default:
          // console.log($$field.get('type'))
          if (
            $$field.get("typeVariant") === "datetime-local" &&
            value !== undefined
          ) {
            decodedValue = formatDate(value, "dd/mm/yyyy HH:MM");
          } else if ($$field.get("isLink") === true && $$field.get('linkUrl')) {
            decodedValue = (<a target={$$field.get('linkTarget')} href={$$field.get('linkUrl').replace('{'+$$field.get('id')+'}', value)}>{value}</a>)
          } else if ($$field.get("embedType") === "vimeo") {
            if (value !== "" && value !== null)
              decodedValue = (
                <a href={value} target="_blank">
                  VIMEO
                </a>
              );
            else decodedValue = "-";
          } else {
            var r = new RegExp(/[[\]]/);
            if (
              value !== null &&
              value !== undefined &&
              r.test(value.toString())
            ) {
              decodedValue = (
                <Paragrapher text={value.toString()} prettyfy={true} />
              );
            } else {
              decodedValue = value;
            }
          }
          break;
      }
    }
    let compiledStyle = style;
    if (!compiledStyle) compiledStyle = {};
    compiledStyle.padding = "5px";
    return (
      <TableRowColumn style={compiledStyle} {...cellProps}>
        {decodedValue}
      </TableRowColumn>
    );
  }
}

export default withRouter(
  class DynamicList extends React.Component {
    static propTypes = {
      onMount: PropTypes.func.isRequired,
      // onDetailsRow: React.Prototype.func,
      moduleConfig: PropTypes.object,
    };
    state = {
      toDelete: [],
      // toDelete: Immutable.List(),
      openedPanels: Immutable.Map(),
      // sorting: Immutable.Map(),
      orderBy: false,
      orderWay: false,
    };

    static contextTypes = {
      muiTheme: PropTypes.object,
    };

    constructor(props) {
      super(props);
      // this._refresh = this.props.onMount.bind(this);
      this._onDelete = this._onDelete.bind(this);
      this._onDeleteConfirm = this._onDeleteConfirm.bind(this);
      this._onDeleteCancel = this._onDeleteCancel.bind(this);
      // this.moduleConfig = moduleConfig;
    }

    componentDidMount() {
      const uri = queryString.parse(window.location.search);
      if (uri.ft !== undefined && uri.fv !== undefined) {
        console.log('do filter!', uri.ft, uri.fv)
        this.props.onFilterChange(uri.ft, uri.fv)
      } else
      this.props.onMount(this.props.moduleConfig);
      if (this.props.autoReload === true) this._startAutoReloadTimer();

      // onOrderChange: (orderBy, orderWay) =>
      //   dispatch(
      //     actionCreators.changeOrder(ownProps.moduleConfig, orderBy, orderWay)
      //   ),
      // onFilterChange: (filterBy, filterValue) =>
      //   dispatch(
      //     actionCreators.changeFilter(
      //       ownProps.moduleConfig,
      //       filterBy,
      //       filterValue
      //     )
      //   ),

    }

    componentWillUnmount() {
      this._stopAutoReloadTimer();
      this.props.onUnmount();
    }

    _startAutoReloadTimer() {
      if (this.props.autoReload !== true) return;
      const delay =
        this.props.autoReloadDelay !== undefined
          ? this.props.autoReloadDelay
          : 5000;
      this.$autoReloadInterval = setInterval(() => {
        this.props.onMount(this.props.moduleConfig);
      }, delay);
    }
    _stopAutoReloadTimer() {
      if (this.$autoReloadInterval) {
        clearInterval(this.$autoReloadInterval);
        delete this.$autoReloadInterval;
      }
    }
    _onCellClick(rowId, foo) {
      // console.log('clicked', rowId, foo)
    }
    _onTableClick(rowId, cellId) {
      // comment obtenir le row[rowId][cellId] ?
    }
    _onDeleteConfirm(item) {
      // console.log('deleting', item)
      const { onDeleteRow, moduleConfig } = this.props;
      const { dialogDeleteState } = this.state;
      // const current = dialogDeleteState;
      const { actionsCallbacks } = moduleConfig;

      // this.setState({dialogDeleteState: item})
      // const t = this.state.dialogDeleteState;
      this.setState({ dialogDeleteState: false }, function () {
        if (actionsCallbacks && actionsCallbacks.onDeleteRow) {
          actionsCallbacks.onDeleteRow(moduleConfig, dialogDeleteState, {
            onDeleteRow,
          });
        } else onDeleteRow(moduleConfig, dialogDeleteState.get("id"));
        this.setState({ dialogDeleteState: false });

      });
    }
    _onDeleteCancel() {
      this.setState({ dialogDeleteState: false });
      // this.props.onDelete(item)
    }
    _onDelete(e, $$item) {
      this.setState({ dialogDeleteState: $$item });
      // this.setState({answerVisible: visibility})
    }

    onActionClick = (e, $$action, $$row, rowIndex) => {
      e.preventDefault();
      e.stopPropagation();
      const {
        onSetRowFieldData,
        onSetRowApparence,
        history,
        // onDeleteRow,
        moduleConfig,
      } = this.props;
      const { actionsCallbacks } = moduleConfig;
      switch ($$action.get("name")) {
        case "delete":
          if ($$row.get("readOnly") === 1) {
          } else {
            this._onDelete(e, $$row);
          }
          break;
        default:
          // let component;
          if ($$action.get("url")) {
            let url=$$action.get("url");
            if(url.includes('{invitedAccount}')){//AJOUT B : pour module invitation
              if($$row.get("invitedAccount")===null)
                return;
              url=url.replace('{invitedAccount}', $$row.get("invitedAccount"));
            }else{
              url=url.replace("{id}", $$row.get("id"));
            }
            history.push(url);
          } else if (this.props.panels) {
            this.props.panels.forEach((panel) => {
              if (panel.module === $$action.get("module")) {
                if (
                  !this.state.openedPanels.get(
                    $$row.get("id") + $$row.get("dateCreated")
                  )
                ) {
                  const op = Immutable.fromJS({ panel }).setIn(
                    ["panel", "filter"],
                    $$action.get("filter")
                  );
                  this.setState({
                    openedPanels: this.state.openedPanels.set(
                      $$row.get("id") + $$row.get("dateCreated"),
                      op
                    ),
                  });
                  if (panel.callback) {
                    panel.callback(rowIndex, $$row, {
                      onSetRowFieldData,
                      onSetRowApparence,
                    });
                  }
                } else {
                  const oldOp = this.state.openedPanels
                    .get($$row.get("id") + $$row.get("dateCreated"))
                    .get("panel");
                  const shouldReopen =
                    oldOp.get("module") !== panel.module ? true : false;

                  if (shouldReopen) {
                    const op = Immutable.fromJS({ panel }).setIn(
                      ["panel", "filter"],
                      $$action.get("filter")
                    );
                    this.setState({
                      openedPanels: this.state.openedPanels.set(
                        $$row.get("id") + $$row.get("dateCreated"),
                        op
                      ),
                    });
                  } else {
                    this.setState({
                      openedPanels: this.state.openedPanels.delete(
                        $$row.get("id") + $$row.get("dateCreated")
                      ),
                    });
                  }
                }
              }
            });
          } else if (
            $$action.get("callback") !== undefined &&
            actionsCallbacks !== undefined &&
            actionsCallbacks[$$action.get("callback")]
          ) {
            actionsCallbacks[$$action.get("callback")]($$row, () => {
              this.props.onMount(this.props.moduleConfig);
            });
          } else {
            console.log(
              "no click",
              $$action.toJS(),
              actionsCallbacks,
              this.props.moduleConfig
            );
          }

          break;
      }
    };
    sortByColumn(field) {
      const orderBy = field;
      const orderWay =
        this.state.orderBy === orderBy && this.state.orderWay === "a"
          ? "d"
          : "a";
      this.setState({ orderBy, orderWay });
      this.props.onOrderChange(orderBy, orderWay);

      // onFilterChange
    }
    render() {
      const {
        loaded,
        $$fields,
        $$rows,
        $$actions,
        $$filters,
        // $$toDelete,
        $$apparence,
        // textFilter,
        // readOnly,
        onFilterChange,
        moduleConfig,
        $$linkMap,
      } = this.props;

      const { dialogDeleteState } = this.state;
      // const { styles: theme } = this.props

      // const theme = getStyles(this.props, this.context, this.state);
      // console.log(theme)

      const colSpan =
        $$fields && $$actions
          ? $$fields.size + $$actions.size - 1
          : $$fields
          ? $$fields.size
          : 0;

      if (!loaded)
        return (
          <div>
            <Spinner text="Chargement..." />
          </div>
        );

      let gridArgs = { xs: 12, sm: 4, md: 3, lg: 3 };
      // const colStyle = {minWidth: 320}
      let colStyle = { paddingLeft: 8, paddingRight: 8};
      if ($$filters !== undefined && $$filters.size > 4) {
        colStyle.marginBottom = 16;
        gridArgs = { xs: 12, sm: 4, md: 2, lg: 2 };
      }
      return (
        <ModuleConfigProvider moduleConfig={moduleConfig}>
          <Paper style={{ padding: 20, overflowX: "auto" }}>
            <If condition={moduleConfig.title}>
              <Subheader>{moduleConfig.title}</Subheader>
            </If>
            {/*<If condition={moduleConfig.showFilter !== false} >
                  <TextField
                  hintText="Filtre..."
                  value={textFilter}
                  onChange={onTextFilterChange}
                  />
                  </If>*/}
            <If
              condition={
                $$filters &&
                $$filters.size > 0 &&
                moduleConfig.showFilters !== false
              }
            >
              <Box my={3}>
                <Grid
                  container
                  spacing={0}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  style={{ height: "100%" }}
                >
                  {$$filters
                    .map(($$filter, fIdx) => {
                      let el = "<span>no filter</span>";
                      switch ($$filter.get("filter_type")) {
                        case "PastPresent":
                          el = (
                            <Grid
                              item
                              xs={12}
                              key={"flt_" + fIdx}
                              {...gridArgs}
                              style={colStyle}
                            >
                              <DynamicFilterPastPresent
                                filterId={fIdx}
                                $$filter={$$filter}
                                onChange={onFilterChange}
                              />
                            </Grid>
                          );
                          break;
                        case "SelectAsync":
                          el = (
                            <Grid
                              item
                              xs={12}
                              key={"flt_" + fIdx}
                              {...gridArgs}
                              style={colStyle}
                            >
                              <DynamicFilterSelectAsync
                                filterId={fIdx}
                                $$filter={$$filter}
                                onChange={onFilterChange}
                              />
                            </Grid>
                          );
                          break;
                        case "Select":
                          el = (
                            <Grid
                              item
                              xs={12}
                              key={"flt_" + fIdx}
                              {...gridArgs}
                              style={colStyle}
                            >
                              <DynamicFilterSelect
                                filterId={fIdx}
                                $$filter={$$filter}
                                onChange={onFilterChange}
                              />
                            </Grid>
                          );
                          break;
                        case "Search":
                          el = (
                            <Grid
                              item
                              xs={12}
                              key={"flt_" + fIdx}
                              {...gridArgs}
                              style={colStyle}
                            >
                              <DynamicFilterSearch
                                filterId={fIdx}
                                $$filter={$$filter}
                                onChange={onFilterChange}
                              />
                            </Grid>
                          );
                          break;
                        default:
                          console.log(
                            "missing filter type",
                            $$filter.get("filter_type")
                          );
                          break;
                      }
                      return el;
                    })
                    .toArray()}
                </Grid>
              </Box>
            </If>
            <Table
              selectable={
                moduleConfig.selectable !== undefined
                  ? moduleConfig.selectable
                  : "false"
              }
              className={"Table"}
              style={{
                tableLayout: "auto",
                // tableLayout: 'fixed'
                // tableLayout: 'initial'
                // tableLayout: 'fixed',
                // tableLayout: 'initial'
                // wordBreak: 'break-word',
              }}
            >
              <TableBody>
                <TableRow
                  selectable={"false"}
                  striped={"false"}
                  onClick={this._onCellClick}
                  className={"tableHeaderRow"}
                >
                  <If condition={$$fields}>
                    {$$fields
                      .filter(($$field, index) => index > 0)
                      .map(($$field, index) => {
                        // return <Header key={$$field.get('id')} $$field={$$field} />
                        return (
                          <TableHeaderColumn
                            key={$$field.get("id")}
                            id={$$field.get("id")}
                            className={
                              this.state.orderBy === $$field.get("id")
                                ? styles.header + " " + styles.active
                                : styles.header
                            }
                            style={{
                              textAlign: "left",
                              padding: "0 5px",
                              backgroundColor: "transparent",
                              width: $$field.get("listHeaderWidth")
                                ? $$field.get("listHeaderWidth")
                                : "auto",
                            }}
                          >
                            <div
                              className={"headerColumnLabel"}
                              onClick={(e) =>
                                this.sortByColumn($$field.get("id"))
                              }
                            >
                              {$$field.get("label")}
                              <SortIcon className={"headerColumnIcon"} />
                            </div>
                          </TableHeaderColumn>
                        );
                      })
                      .toArray()}

                    <If
                      condition={
                        $$actions && moduleConfig.showActions !== false
                      }
                    >
                      {$$actions
                        .map(($$action, index) => {
                          // console.log($$action.toJS())
                          return (
                            <TableHeaderColumn
                              style={{
                                width: 50,
                                padding: 0,
                                backgroundColor: "transparent",
                              }}
                              key={"act_" + index}
                            >
                              &nbsp;
                            </TableHeaderColumn>
                          );
                        })
                        .toArray()}
                    </If>
                  </If>
                </TableRow>
                <If condition={$$rows && $$rows.size > 0}>
                  {$$rows
                    .map(($$row, rowIndex) => {
                      let $$result = Immutable.List();
                      $$result = $$result.push(
                        <TableRow
                          key={rowIndex}
                          selected={$$row.selected}
                          style={
                            this.state.toDelete !== undefined &&
                            this.state.toDelete.indexOf($$row.get("id")) >= 0
                              ? { border: "3px #ff0000 solid" }
                              : {}
                          }
                          className={
                            $$apparence.get(rowIndex) !== undefined
                              ? $$apparence.get(rowIndex)
                              : ""
                          }
                        >
                          {$$fields
                            .filter(($$field, index) => index > 0)
                            .map(($$field, index) => {
                              // console.log($$field.get('id'))
                              //  if (index > 0 && $$field.get('id') !== 'readOnly')
                              return (
                                <Cell
                                  key={$$field.get("id")}
                                  $$field={$$field}
                                  value={$$row.get($$field.get("id"))}
                                  rowId={$$row.get("id")}
                                  $$fieldLinkMap={$$linkMap.get(
                                    $$field.get("id"),
                                    Immutable.Map()
                                  )}
                                />
                              );
                            })
                            .toArray()}

                          <If
                            condition={
                              $$actions && moduleConfig.showActions !== false
                            }
                          >
                            {$$actions
                              .map(($$action, index) => {
                                return (
                                  <ColumnButton
                                    key={"act_" + index}
                                    active={
                                      this.state.openedPanels.get(
                                        $$row.get("id") +
                                          $$row.get("dateCreated")
                                      ) !== undefined &&
                                      this.state.openedPanels
                                        .get(
                                          $$row.get("id") +
                                            $$row.get("dateCreated")
                                        )
                                        .get("panel")
                                        .get("module") ===
                                        $$action.get("module")
                                    }
                                    moduleConfig={moduleConfig}
                                    $$row={$$row}
                                    $$action={$$action}
                                    onActionClick={this.onActionClick.bind(
                                      this
                                    )}
                                    rowIndex={rowIndex}
                                  />
                                );
                              })
                              .toArray()}
                          </If>
                        </TableRow>
                      );

                      if (
                        this.state.openedPanels.get(
                          $$row.get("id") + $$row.get("dateCreated")
                        ) !== undefined
                      ) {
                        const $$panel = this.state.openedPanels.getIn([
                          $$row.get("id") + $$row.get("dateCreated"),
                          "panel",
                        ]);
                        $$result = $$result.push(
                          <TableRow
                            key={rowIndex + "_"}
                            selectable={"false"}
                            striped={"false"}
                            style={{ height: "auto" }}
                            hoverable={"false"}
                          >
                            <TableRowColumn
                              colSpan={colSpan}
                              style={{ width: "100%", height: "auto" }}
                            >
                              {React.createElement($$panel.get("component"), {
                                $$panel,
                                $$row,
                                key: $$panel.get("module"),
                              })}
                            </TableRowColumn>
                          </TableRow>
                        );
                      }

                      return $$result;
                    })
                    .flatten()
                    .toArray()}
                </If>
                <If condition={!$$rows || $$rows.size === 0}>
                  <TableRow key={"1_"} selectable={"false"} striped={"false"}>
                    <TableRowColumn
                      colSpan={colSpan}
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      Aucun élément
                    </TableRowColumn>
                  </TableRow>
                </If>
              </TableBody>
            </Table>

            <If
              condition={
                moduleConfig.showPagination !== false &&
                $$rows &&
                $$rows.size > 0
              }
            >
              <Pagination
                listId={this.props.listId}
                moduleConfig={moduleConfig}
              />
            </If>
          </Paper>
          <If
            condition={
              dialogDeleteState !== undefined &&
              dialogDeleteState !== false &&
              dialogDeleteState !== null
            }
          >
            <Dialog
              title={"Confirmation de suppression"}
              actions={
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    margin: "20px 0",
                  }}
                >
                  <BlockButton
                    onClick={this._onDeleteCancel}
                    label="Annuler"
                    variant="white"
                    style={{ margin: "0 10px" }}
                  />
                  <BlockButton
                    onClick={this._onDeleteConfirm}
                    label="Confirmer"
                    style={{ margin: "0 10px" }}
                  />
                </div>
              }
              open={
                dialogDeleteState !== undefined && dialogDeleteState !== false
              }
            >
              <Typography align="center">
                <If
                  condition={
                    dialogDeleteState !== undefined &&
                    dialogDeleteState !== false &&
                    dialogDeleteState !== null
                  }
                >
                  Souhaitez vous supprimer cet élément ?
                </If>
              </Typography>
            </Dialog>
          </If>
        </ModuleConfigProvider>
      );
    }
  }
);
