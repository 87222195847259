import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import config from 'config/api';
import i18n from 'helpers/i18n';
import { fetchData, getDataHome } from 'ducks/asyncFetcher';
import { fetchMedias, getMediasData, clearMedias } from 'components/UI/Media/ducks/media';
import Spinner from 'components/UI/Spinner/Spinner';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import MediaCard from 'components/Media/MediaCard';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => {
  return {
    root: {
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& .MuiTypography-h3': {
        paddingRight: 4,
        paddingLeft: 22,
        marginBottom: theme.spacing(2),
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.1rem'
      },
      '& .MuiMobileStepper-dot': {
        marginLeft: '4px',
        marginRight: '4px',
      }

    },
    badge: {
      top: '50%',
      right: '-16px'
    },

    mobileStepper: {
      marginTop: 0,
      paddingTop: 0,
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        margin: 0,
      }
    },
    navigation: {
      color: theme.palette.primary.main,
      '& .MuiIconButton-label': {
        textAlign: 'center',
        width: 17.59,
        justifyContent: 'center',
        padding: 0
      },
    },
    invisible: {
      visibility: 'hidden'
    },
  };
};

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -30,
    top: '50%',
    width: 30,
    height: 30,
    fontSize: '1rem',
    borderRadius: '180px'
  },
}))(Badge);

class EventList extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     activeStep: 0,
  //     groupStep: 3
  //   }
  // }

  componentDidMount() {
    this.props.onMount({kind: this.props.kind})
    // this._onWindowResize();
    if (this.props.$$media !== undefined )
      this.props.onCounterChange('medias', this.props.$$medias.size);

    // window.addEventListener('resize', this._onWindowResize);
  }

  componentDidUpdate(oldProps) {
    const { kind } = this.props;
    if (oldProps.$$medias !== this.props.$$medias) {
      // this._onWindowResize();
      // console.log('medias events are ',kind, this.props.$$medias.size)
      if (this.props.onCounterChange !== undefined) {
        if (this.props.$$medias !== undefined)
          this.props.onCounterChange(kind, this.props.$$medias.size);
        else
          this.props.onCounterChange(kind, 0);
      }
    }

    // if (oldProps.universe !== undefined && oldProps.universe !== this.props.universe) {
    //   console.log('reloading eventlsit', this.props.universe)
    //   this.props.onMount({kind: this.props.kind})
    // }

  }

  componentWillUnmount() {
    // console.log('unmount')
    // window.removeEventListener('resize', this._onWindowResize);
    this.props.onUnmount()
  }

  render() {
    const { title, showCount, classes, kind, MediaCardClasses, itemFilter, $$medias } = this.props;
    const $$items = $$medias;
    const direction = 'regular'

    const autoPlay = false;
    let gridSize
    if ($$items !== undefined && $$items.size === 1)
      gridSize = {sm: 6, md:4 };
    else if ($$items !== undefined && $$items.size === 2)
    gridSize = {sm: 6, md:4 };
    else
    gridSize = {sm: 6, md:4 };

    if ($$items === undefined || $$items.size === 0)
    return <span></span>

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        className={classes.root}
        ref={r => { this.$grid = r}}
        >
        <If condition={title !== undefined}>
        <Grid item xs={12}>
          <If condition={showCount === true}>
            <Typography variant="h3"><StyledBadge badgeContent={$$items !== undefined ? $$items.size : 0} color="secondary">{title}</StyledBadge></Typography>
          </If>
          <If condition={showCount !== true}>
            <Typography variant="h3">{title}</Typography>
          </If>
        </Grid>
      </If>
        <Choose>
          <When condition={$$items !== undefined && $$items.size > 0}>
            <Grid item xs={12} container align="flex-start">
              {$$items.filter($$item => { return itemFilter !== undefined ? itemFilter($$item) : $$item }).map(($$item, idx) => {
                return (
                  <Grid  key={'i_'+idx} item xs={12} sm={gridSize.sm} md={gridSize.md}><MediaCard itemIndex={idx} $$item={$$item} kind={kind} classes={MediaCardClasses} variant="vertical" /></Grid>);
                })
                .toArray()}
              </Grid>
            </When>
            <Otherwise>
              <Spinner>{i18n.get('loading')}</Spinner>
            </Otherwise>
          </Choose>

        </Grid>
      );
    }
  }
  function mapStateToProps(state) {
    const props = {
      $$data: getDataHome(state),
      $$medias: getMediasData(state),
      // $$user: getCurrentUser(state),
    };
    return props;
  }
  function mapDispatchToProps(dispatch) {
    return {
      onMount: (props) => {
        dispatch(fetchData({ key: 'home', fetchUrl: config.home }));
        dispatch(fetchMedias({}));
      },
      onUnmount: (props) => {
        dispatch(clearMedias({}));
      }
    };
  }

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventList)));
