import React, { Component } from 'react';
// import { Route } from 'react-router-dom'
import cropStr from 'mout/string/crop';
import { withRouter } from "react-router-dom";
import i18n from 'helpers/i18n';
import Immutable from 'immutable';
import combine from "mout/array/combine";
// import Rating from "@material-ui/lab/Rating";
import Typography from '@material-ui/core/Typography';
import Select from 'components/UI/Form/Select';
import config from 'config/api';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchDashboardStats, clearDashboardStats, getDashboardError, getDashboardStats } from './ducks/dashboard';
import { fetchExportsData, clearExportsData, getExportsError, getExportsData } from './ducks/dashboard';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import DynamicList from 'components/UI/DynamicList';
import DashboardExportButton from './DashboardExportButton';

const styles = (theme) => {
  return {
    root: {
      maxWidth: '96%',
      marginLeft: 'auto',
      marginRight: 'auto',
      '& .MuiTypography-h1': {
        fontSize: '2.4rem',
        marginBottom: theme.spacing(4),
        padding: '0 92px',
      },
      '& label + .MuiInput-formControl': {
        marginTop: -4
      }
    },
    breadCrumbs: {
      'a': {
        display: 'inline'
      }
    },
    identification: {
      '& .MuiTypography-subtitle1': {
        display: 'inline-flex',
        minWidth: 200,
        fontWeight: 'bold'
      },
      '& .MuiTypography-body1': {
        display: 'inline-flex'
      }
    },
    subscriptions: {

    },
    block: {
      boxShadow: theme.shadows[6],
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  }
}

const STAT_TYPES = [
  {label: i18n.get('dashboardExportsEvents', {pretty: false}), value: 'events'},
  {label: i18n.get('dashboardExportsRegistrations', {pretty: false}), value: 'registrations'},
  {label: i18n.get('dashboardExportsFeedbacks', {pretty: false}), value: 'feedbacks'},
  {label: i18n.get('dashboardExportsReplays', {pretty: false}), value: 'replays'},
  {label: i18n.get('dashboardExportsOrders', {pretty: false}), value: 'orders'},
]
class DashboardExportsViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadCumbKind: null
    }
  }
  componentDidMount() {
    const {kind} = this.props;
    this.setState({breadCumbKind: kind}, () => {
      // this.props.onMount({kind: kind});
    })
  }
  componentDidUpdate(oldProps) {
    if (oldProps.kind !== undefined && oldProps.kind !== this.props.kind) {
      // const {kind} = this.props;
      this.setState({breadCumbKind: this.props.kind}, () => {
        // this.props.onMount({kind: this.props.kind});
      })
    }
  }
  componentWillUnmount() {
    // this.props.onUnmount();
  }
  getExports = () => {
    const {kind, $$exports} = this.props;
    if ($$exports === undefined) return []
    let stats;
    // switch(kind) {
    //   case 'speciality':
    //   stats = combine(
    //     [[i18n.get('dashboardStatsSpecialityX', {pretty: false}), i18n.get('dashboardStatsSpecialityY', {pretty: false})]],
    //     $$stats.map($$s => [$$s.get('speciality'), parseInt($$s.get('cnt'), 10)]).toArray()
    //     // $$stats.map($$s => [cropStr($$s.get('speciality'), 20, '...'), parseInt($$s.get('cnt'), 10)]).toArray()
    //   )
    //   // stats = combine([''], $$stats.map($$s => [$$s.get('speciality'), $$s.get('cnt')]).toArray()]
    //   break;
    //   case 'jobs':
    //   stats = combine(
    //     [[i18n.get('dashboardStatsJobsX', {pretty: false}), i18n.get('dashboardStatsJobsY', {pretty: false})]],
    //     $$stats.map($$s => [$$s.get('job'), parseInt($$s.get('cnt'), 10)]).toArray()
    //   )
    //   break;
    //   case 'subscriptions':
    //   stats = combine(
    //     [[i18n.get('dashboardStatsSubscriptionsX', {pretty: false}), i18n.get('dashboardStatsSubscriptionsY', {pretty: false})]],
    //     $$stats.map($$s => [$$s.get('date'), parseInt($$s.get('cnt'), 10)]).toArray()
    //   )
    //   break;
    //   case 'registrations':
    //   stats = combine(
    //     [[i18n.get('dashboardStatsRegistrationsX', {pretty: false}), i18n.get('dashboardStatsRegistrationsY', {pretty: false})]],
    //     $$stats.map($$s => [$$s.get('date'), parseInt($$s.get('cnt'), 10)]).toArray()
    //   )
    //   break;
    //   default:
    //   break;
    // }
    return $$exports;
  }
  onBreadCrumbChanged = (kind, o) => {
    this.setState({breadCumbKind: o.value}, () => {
      this.props.history.push('/dashboard/stats/'+o.value)
    })
  }
  render() {
    const {$$exports, classes} = this.props;
    const { breadCumbKind } = this.state;

    const moduleConfig = {
      module: 'exports',
      // showPagination: false,
      // filter: this.props.filter,
      // showFilters: this.props.showFilters,
    };

    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box className={classes.breadCrumbs}>
                <Link to={'/dashboard'}>Dashboard</Link>&nbsp;&gt;&nbsp;{i18n.get('dashboardNavigationStats', {pretty: false})}&nbsp;&gt;&nbsp;
                  <Select id={'breadCrumbSelector'} options={Immutable.fromJS(STAT_TYPES)} value={breadCumbKind}  label={false} fullWidth={false} paperWidth={350} onChange={this.onBreadCrumbChanged}/>
                </Box>
              </Grid>
              <Grid item xs={12} container justifyContent={'flex-start'} className={clsx(classes.block, classes.identification)}>
                <Grid item xs={12}>
                  <Typography variant="h1" color="primary" gutterBottom>
                    <Choose>
                      <When condition={breadCumbKind === 'events'}>
                        {i18n.get('dashboardExportsEvents', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'orders'}>
                        {i18n.get('dashboardExportsOrders', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'replays'}>
                        {i18n.get('dashboardExportsReplays', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'documents'}>
                        {i18n.get('dashboardExportsDocuments', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'podcasts'}>
                        {i18n.get('dashboardExportsPodcasts', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'medias'}>
                        {i18n.get('dashboardExportsMedias', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'feedbacks'}>
                        {i18n.get('dashboardExportsFeedbacks', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'registrations'}>
                        {i18n.get('dashboardExportsRegistrations', {pretty: false})}
                      </When>
                      <Otherwise><span></span></Otherwise>
                    </Choose>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Choose>
                    <When condition={breadCumbKind === 'events'}>
                      <DashboardExportButton path="event" display="relative"/>
                      <DynamicList moduleConfig={{...moduleConfig, ...{fetchUrl: config.dashboard + '/exports/events'}}}  />
                    </When>
                    <When condition={breadCumbKind === 'replays'}>
                    </When>
                    <When condition={breadCumbKind === 'feedbacks'}>
                    </When>
                    <When condition={breadCumbKind === 'registrations'}>
                      <DashboardExportButton path="registrations" display="relative"/>
                      <DynamicList moduleConfig={{...moduleConfig, ...{fetchUrl: config.dashboard + '/exports/registrations'}}}  />
                    </When>
                    <When condition={breadCumbKind === 'orders'}>
                      <DynamicList moduleConfig={{...moduleConfig, ...{fetchUrl: config.dashboard + '/exports/orders'}}}  />
                    </When>
                    <Otherwise>
                      Hello world!
                    </Otherwise>
                  </Choose>
                </Grid>
              </Grid>
          </Grid>
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    const props = {
      $$exports: getExportsData(state),
      $$error: getExportsError(state),
    };
    return props;
  }
  function mapDispatchToProps(dispatch) {
    return {
      // onMount: (props) => {
      //   dispatch(fetchExportsData(props));
      // },
      // onUnmount: (props) => {
      //   dispatch(clearExportsData());
      // },
    };
  }

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardExportsViewer)));
