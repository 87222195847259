import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import config from "config/api";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import AnnounceEmbed from './AnnounceEmbed';
import { Redirect } from "react-router-dom";

import { withRouter } from "react-router-dom";
import {
  getAnnounceData,
  getAnnounceAnswered,
} from "./ducks/announce";
import { fetchData, getDataHome, getLoadingError } from "ducks/asyncFetcher";
import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
} from "components/UI/Account/ducks/authentication";
const styles = (theme) => {
  return {
    root: {
      '& .MuiTypography-h3': {
        textAlign: 'center'
      }
    }
  };
};
class Announce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isComplete: false,
    };
  }
  componentDidMount() {
    this.props.onMount();
  }
  _onComplete = () => {
    console.log('complete!');
    this.setState({isComplete: true})
  }

  render() {
    const {
      userRole,
      $$data,
      classes,
      // $$announceData,
      // answered,
      // labelComplete,
      // $$data
    } = this.props;
    const { isComplete } = this.state;
    // const $$card = this._getAnnounceCard();
    // const cardImage = getCardImage($$card);
    // console.log({
    //   answered,
    //   isReady
    // })
    // console.log('check', $$announceData)
    // console.log('check', {isReady})
    // console.log('check', {answered})
    const eventId = this.props.match.params.eventId;

    let $$card;

    if ($$data !== undefined) {
      $$card = $$data.get('availableEvents').find($$e => $$e.get('id') === eventId);

      if ($$card === undefined)
      $$card = $$data.get('mypastevents').find($$e => $$e.get('id') === eventId);

      if ($$card === undefined){
        console.error('$$card not found for '+eventId+'!');
        return (<Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location },
          }}
          />)
        }
      }


      return (<If condition={$$data !== undefined}>
        <Box mt={2} className={classes.root} align="center" width="100%">
          <Choose>
            <When
              condition={$$data !== undefined && userRole === undefined}
              >
              <Box my={6} align="center">
                <Typography
                  variant="h5"
                  gutterBottom={true}
                  className={clsx(classes.legend, classes.h6extends)}
                  >
                  Merci de vous connecter, ou de créer un compte pour accéder à ce contenu
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{margin: '0 5px'}}
                  onClick={(e) =>
                    this.props.history.push(
                      "/login?_pn=" +
                      encodeURIComponent(window.location.pathname)
                    )
                  }
                  >
                  {" "}
                  Connexion
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{margin: '0 5px'}}
                  onClick={(e) => this.props.history.push('/registration?_pn='+encodeURIComponent(window.location.pathname+'/'+window.location.search))}
                  >
                  {' '}
                  Créer un compte
                </Button>

              </Box>
            </When>
            <When
              condition={
                $$data !== undefined &&
                (userRole === "member" ||
                userRole === "admin" ||
                userRole === "speaker" ||
                userRole === "staff")
              }
              >

              <Typography variant="h3" gutterBottom>Vous avez assisté à l'émission :</Typography>
              <Typography variant="h2" gutterBottom component={'div'}><Paragrapher text={$$card.get('eventTitle')} prettyfy={true} /></Typography>
              <If condition={isComplete !== true}>
                <Typography variant="h3" gutterBottom>Votre avis nous interesse !</Typography>
              </If>
              <Box maxWidth={800} mb={2}>
                <AnnounceEmbed eventId={eventId} onAnnounceComplete={this._onComplete} btClose={false} btBack={true} footer={$$card.get('eventLegalAnnounce')}/>
              </Box>
            </When>
            <Otherwise>
              <div className={"Loading"}>
                <Spinner style={{ marginTop: 200 }}>
                  Chargement...
                </Spinner>
              </div>
            </Otherwise>
          </Choose>



        </Box>
      </If>)
    }
  }

  function mapStateToProps(state) {
    const props = {
      $$data: getDataHome(state),
      error: getLoadingError(state),
      isLoggedIn: isLoggedIn(state),
      userRole: getCurrentUserRole(state),
      answered: getAnnounceAnswered(state),
      $$user: getCurrentUser(state),
      $$announceData: getAnnounceData(state),
    };
    return props;
  }

  function mapDispatchToProps(dispatch) {
    return {
      onMount: () => {
        dispatch(fetchData({ key: 'home', fetchUrl: config.home }));
      },
    };
  }
  // export default ;
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Announce))
  );
