import React, { Component } from 'react';
import clsx from 'clsx';
import Header from 'components/Header/Header';
import Footer from 'components/Footer';
import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';

// const styles = (theme) => {
//   return {
//     root: {
//       minHeight: '100%',
//       height: '100%',
//       width: '100%',
//       margin: '0 auto',
//       color: theme.palette.template && theme.palette.template.color ? theme.palette.template.color : 'inherit'
//     },
//     fullHeight: {
//       height: '100%',
//     },
//     wrapper: {
//       minHeight: 'calc(100% - 180px)',
//       [theme.breakpoints.down('lg')]: {
//         minHeight: 'auto',
//       },
//       [theme.breakpoints.down('sm')]: {
//       },
//       [theme.breakpoints.down('xs')]: {
//       },
//     },
//     content: {
//       marginLeft: 'auto',
//       marginRight: 'auto',
//       [theme.breakpoints.down('sm')]: {
//       // paddingLeft: theme.spacing(2),
//       // paddingRight: theme.spacing(2),
//     },
//     },
//     limitWidth: {
//       maxWidth: 1280,
//     },
//     titleSmall: {
//       fontSize: '2rem',
//       [theme.breakpoints.down('xs')]: {
//         fontSize: '1.2rem',
//         textAlign: 'center',
//       },
//     },
//     headerImage: {
//       textAlign: 'right',
//       [theme.breakpoints.down('sm')]: {
//         marginTop: theme.spacing(2),
//         textAlign: 'center',
//       },
//     },
//   };
// };
const styles = (theme) => {
  return {
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.template && theme.palette.template.color ? theme.palette.template.color : 'inherit',
    },
    wrapper: {
      [theme.breakpoints.down('lg')]: {
      },
      [theme.breakpoints.down('sm')]: {
      },
      [theme.breakpoints.down('xs')]: {
      },
    },
    content: {
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
    },
    },
    limitWidth: {
      maxWidth: 1280,
    },
    titleSmall: {
      fontSize: '2rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.2rem',
        textAlign: 'center',
      },
    },
    headerImage: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
      },
    },
    footer: {
      marginTop: 'auto'
    }
  };
};
class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedSize: false,
    };
  }
  componentDidMount() {
    // if (this.props.resize !== false)
    // {
    // this._onWindowResize();
    // window.addEventListener('resize', this._onWindowResize);
    // }
  }
  componendWillUnmount() {
    // if (this.props.resize !== false)
    // {
    //   window.removeEventListener('resize', this._onWindowResize);
    // }
  }
  // _onWindowResize = () => {
  //   const el = document.querySelector('#root');
  //   if (!el) return;
  //   if (el.offsetHeight <= window.innerHeight) {
  //     el.style.height = window.innerHeight + 'px';
  //   } else {
  //     el.style.height = 'auto';
  //   }
  // };
  render() {
    const { classes, title, children, current, headerBanner, headerImage, maxWidth, headerContent, headerClasses, footerClasses, backgroundColor, customClass } = this.props;
    return (
      <Fade in timeout={1000}>
      <Container
        disableGutters
        className={clsx(classes.root, customClass)}
        fixed={false}
        maxWidth={false}
        style={{
          height: '100%',
          minHeight: '940px',
          backgroundColor:backgroundColor!==undefined ? backgroundColor : 'transparent',
        }}
      >
        <Grid className={classes.wrapper} container alignContent="flex-start">
          <If condition={headerBanner !== undefined}>
            {headerBanner}
          </If>
          <Header current={current} headerTitle={title} headerImage={headerImage} headerContent={headerContent} classes={headerClasses}/>
          <ScrollToTopOnMount />
          <Grid item xs={12} container alignContent="flex-start" className={clsx(classes.content, {[classes.maxWidth]: maxWidth})}>
            {children}
          </Grid>
        </Grid>
        <Footer classes={footerClasses}/>
      </Container>
    </Fade>
    );
  }
}

export default withRouter(withStyles(styles)(Template));
