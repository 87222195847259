import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { getCurrentUser } from "components/UI/Account/ducks/authentication";
// import { Switch, Route } from 'react-router-dom'
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
// import BlockButton from 'components/UI/BlockButton';
import config from 'config/api';
import Template from 'components/UI/Template/Template'
import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
// import NoMatch from 'components/NoMatch';
// import Bubble from 'components/UI/Bubble';
import DashboardIndex from './DashboardIndex';
import DashboardAccountPreview from './DashboardAccountPreview';
import DashboardAccountList from "./DashboardAccountList";
import DashboardAccounEdit from "./DashboardAccounEdit";
import DashboardEventPreview from './DashboardEventPreview';
import DashboardEventList from "./DashboardEventList";
// import DashboarEventEdit from "./DashboardEventEdit";
import DashboardStatsViewer from "./DashboardStatsViewer";
import DashboardExportsViewer from "./DashboardExportsViewer";
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => {
  return {
  }
}
const Dashboard = (props) => {
  // console.log(userRole, userSubRole)
  const { match, $$user, location } = props;
  const userRole = $$user !== undefined ? $$user.get('role') : undefined;
  const userSubRole = $$user !== undefined ? $$user.get('subRole') : undefined;
  if ((userRole !== undefined && userRole !== "admin" && userSubRole !== 'buadm' && userSubRole !== 'invit'))
  return (
    <Redirect
      to={{
        pathname: "/",
        state: { from: location },
      }}
      />
  );
  return (
    <Template maxWidth='auto' current="admin" headerPosition={'relative'} headerImage={config.template && config.template.admin && config.template.admin.background ? config.template.admin.background : undefined}>

      <Box width="100%" mb={8}>
        <If condition={userRole !== undefined}>
          <div className="HomeBlock offsetSemiTop fullWidth">
            <Choose>
              <When condition={match.params.module === "account" && match.params.action === 'list' && match.params.id === undefined}>
                <DashboardAccountList />
              </When>
              <When condition={match.params.module === "account" && match.params.action === 'edit' && match.params.id !== undefined}>
                <DashboardAccounEdit id={match.params.id} />
              </When>
              <When condition={match.params.module === "account" && match.params.action === 'preview'}>
                <DashboardAccountPreview accountId={match.params.id} />
              </When>
              <When condition={match.params.module === "event" && match.params.action === 'list' && match.params.id === undefined}>
                <DashboardEventList />
              </When>
              <When condition={match.params.module === "event" && match.params.action === 'preview'}>
                <DashboardEventPreview eventId={match.params.id} />
              </When>
              <When condition={match.params.module === "stats" && (match.params.action === 'locations' || match.params.action === 'speciality' || match.params.action === 'subscriptions' || match.params.action === 'registrations' || match.params.action === 'jobs')}>
                <DashboardStatsViewer kind={match.params.action} />
              </When>
              <When condition={match.params.module === "exports" && (match.params.action === 'events' || match.params.action === 'orders' || match.params.action === 'registrations' || match.params.action === 'feedbacks' || match.params.action === 'replays')}>
                <DashboardExportsViewer kind={match.params.action} />
              </When>
              <Otherwise>
                <DashboardIndex />
              </Otherwise>
            </Choose>
          </div>
        </If>
      </Box>
    </Template>

  );
};

function mapStateToProps(state) {
  const props = {
    $$user: getCurrentUser(state),
  };
  return props;
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Dashboard)));
