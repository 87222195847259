import React, { Component } from 'react';

import { formatDate } from 'helpers/stringUtils';

export function OfferDates(props) {
  const format = 'd/mm/yyyy';
  const from = props.item.get('offerDateFrom');
  const to = props.item.get('offerDateTo');
  let text;

  if (to != undefined && to != null && to != 0)
    text = 'Du ' + formatDate(from, format) + ' au ' + formatDate(to, format);
  else text = 'À partir du ' + formatDate(from, format);
  return text;
}

export function OfferFullname(props) {
  return (
    <span>
      Dr. {props.item.get('offerFirstName')} {props.item.get('offerName')}
    </span>
  );
}

export function OfferRetrocession(props) {
  const { item } = props;
  const retrocession = item.get('offerRetrocession');

  if (retrocession === null) return null;

  return <React.Fragment>{retrocession}&nbsp;%</React.Fragment>;
}
