import React, {
  Component
} from "react";
import {
  withStyles
} from "@material-ui/core/styles";
import Slide from 'react-reveal/Slide';
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
const styles = (theme) => {
  return {
    root: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: 5
      }
    },
    onePerRow:{
    },
    bullet: {
      backgroundColor: "transparent",
      color: theme.palette.text.light,
      fontSize: "65px",
      fontWeight: "bold",
      display: "block",
      textAlign: "center",
    },
    listWrapper: {
      paddingBottom: 20,
      marginBottom: 10,
      width: "100%",
      backgroundColor: theme.palette.faq ? theme.palette.faq.backgroundColor : theme.palette.primary.light,
      '& a': {
        color: '#fff',
        fontWeight: 'bold'
      },
      '& .MuiTypography-body1': {
        color: '#fff'
      }
    },
    listItemText: {
      fontSize: "1.4em",
      textAlign: "center",
      padding: theme.spacing(0),
    },
    listItemTextMain: {
      color: theme.palette.faq ? theme.palette.faq.itemColor : theme.palette.text.light,
      fontWeight: "bold",
      letterSpacing: "0.76px",
      position: "relative",
      paddingTop: 0,
      marginBottom: 0,
      fontSize: "1.2rem",
      textTransform: "uppercase",
    },
    gridBullet: {
      display:'none',
      backgroundColor: "transparent",
      color: theme.palette.faq ? theme.palette.faq.bulletColor : theme.palette.text.light,
      fontSize: 90,
      fontWeight: "bold",
    },
    gridMainText: {
      // color: theme.palette.text.dark,
      color: theme.palette.faq ? theme.palette.faq.mainColor : '#fff',
      fontSize: 18,
      fontWeight: "bold",
      letterSpacing: "0.76px",
      position: "relative",
      textTransform: 'uppercase',
      textAlign: 'inherit'
    },
    gridAnswer: {
      // color: "#d9d9d9",
      padding: '0 20',
      color: theme.palette.faq ? theme.palette.faq.answerColor : theme.palette.text.light,
      "& a": {
        // color: theme.palette.text.light,
        color: theme.palette.faq ? theme.palette.faq.answerColor : theme.palette.text.light,
      },
    },
    gridAnswerBullet: {
      backgroundColor: "transparent",
      color: theme.palette.faq ? theme.palette.faq.bulletColor : theme.palette.text.light,
      fontSize: 90,
      fontWeight: "bold",
    },
    padder: {
      backgroundColor: theme.palette.faq ? theme.palette.faq.itemBackgroundColor : theme.palette.primary.light,
      height: '100%',
      //minHeight: '300px',
      padding: '50px 20px 70px',
      margin: '7px 0',
      boxShadow: theme.palette.faq ? theme.palette.faq.itemShadow : 'none',
      [theme.breakpoints.down("lg")]: {
        padding: '30px 20px 50px',
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: 500,
        margin: '0 auto',
        padding: '0 20px 30px',
      },
      '& a': {
        // color: '#fff',
        fontWeight: 'bold'
      }
      // boxShadow: theme.shadows[4],
    },
    last: {
      marginRight: 0,
      [theme.breakpoints.down("sm")]: {
        marginRight: 'auto'
      }
    },
    marginBottom: {
      marginBottom: theme.spacing(2)
    },
    centered: {
      textAlign: 'center'
    },
    left: {
      textAlign: 'left'
    },
  };
};
class HelpFaqItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerVisible: null,
    };
    this._onClick = this._onClick.bind(this);
  }
  _onClick(e) {
    const {
      onClick,
      answerVisible,
      forceOpen
    } = this.props;
    e.stopPropagation();
    if(forceOpen === true) return;
    const visibility = answerVisible === true ? null : this.props.hIdx;
    onClick(visibility);
  }
  _onEdit(e, item) {
    const {
      onEdit
    } = this.props;
    e.stopPropagation();
    onEdit(item);
  }
  _onDelete(e, item) {
    const {
      onDelete
    } = this.props;
    e.stopPropagation();
    onDelete(item);
  }
  render() {
    const {
      // hIdx,
      classes,
      // dense,
      $$help,
      direction,
      hIdx,
      // answerVisible,
      // permDelete,
      // permEdit,
      bulletIcon,
      last,
      onePerRow,
      offsetWidth,
      bulletDirection,
      showAnserBullet,
      showQuestionBullet,
      gridMainTextMinSize,
    } = this.props;
    // const {
    //   answerVisible
    // } = this.state;
    // let bulletSize = 2.6
    // if(hIdx !== undefined)
    // bulletSize = bulletSize / ($$help.get('faqPosition')|0).toString().length
    // +'em';
    // console.log('BSIZE', $$help.get('faqPosition'), hIdx, bulletSize)
    return (
      <React.Fragment>

        <Grid item={true} xs={12} md={direction === 'horizontal' ? 12 : (onePerRow ? 12 : 4)} container alignContent="flex-start" spacing={0} className={clsx(classes.root, {[classes.marginBottom]: direction === 'horizontal', [classes.centered]: direction !== 'horizontal', [classes.onePerRow]: onePerRow, [classes.left]: onePerRow})} style={offsetWidth !== undefined ? ({marginLeft: offsetWidth*hIdx+'%', maxWidth: 'calc(20px + '+offsetWidth+'%)'}) : undefined} justifyContent="center">
          <Grid item={true} xs={12}  className={clsx(classes.padder, {[classes.last]: last === true && direction !== 'horizontal'})} style={{height: 'auto', overflow: 'hidden'}}>
            <Slide bottom delay={100+hIdx}>
              <Grid container alignItems={direction === 'horizontal' ? 'center': 'flex-start'} alignContent="flex-start" spacing={0}>
                <If condition={showAnserBullet === 'top'}>
                    <span className={classes.gridAnswerBullet}>
                      {$$help.get("faqPosition") | 0}
                    </span>
                </If>
                <If condition={showQuestionBullet !== false}>
                <Grid item xs={direction === 'horizontal' || bulletDirection === 'horizontal' ? 3 : 12} justifyContent="center" container>
                  <span className={classes.gridBullet}>
                    <If condition={bulletIcon}>
                      {bulletIcon}
                    </If>
                    <If condition={!bulletIcon}>
                    {$$help.get("faqPosition") | 0}
                    </If>
                  </span>
                </Grid>
                </If>
                <Grid item xs={direction === 'horizontal' ? 12 : (bulletDirection === 'horizontal' ? 9 : (showQuestionBullet !== false ? 12 : 12))}  style={{minHeight: direction === 'horizontal' ? 'normal' : (gridMainTextMinSize ? gridMainTextMinSize : 'inherit')}} >
                  <span className={classes.gridMainText}>
                    {$$help.get("faqQuestion")}
                  </span>
                </Grid>
                <Grid item xs={direction === 'horizontal' ? 6 : 12}  >
                  <Box className={classes.gridAnswer}>
                    <Paragrapher
                      prettyfy={true}
                      text={$$help.get("faqAnswer")}
                      />
                    <If condition={showAnserBullet === 'bottom' || showAnserBullet === true}>
                      <span className={classes.gridAnswerBullet}>
                        {$$help.get("faqPosition") | 0}
                      </span>
                    </If>
                  </Box>
                </Grid>
              </Grid>
            </Slide>
          </Grid>
        </Grid>
        {/*</Hidden>*/}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(HelpFaqItem);
