import React from 'react';
import { connect } from 'react-redux';
import config from 'config/api';
import Form  from 'components/UI/Form/Form'
// import i18n  from 'helpers/i18n'
// import Box  from '@material-ui/core/Box'
// import Button  from '@material-ui/core/Button'
import DynamicForm  from 'components/UI/DynamicForm'
import { withStyles } from '@material-ui/core/styles';
import {
  getCurrentUserId,
} from 'components/UI/Account/ducks/authentication';

const styles = (theme) => {
  return {
    padderClass: {
      margin: '10px 0 20px',
      textAlign: 'left',
      '& .MuiTypography-h6': {
        textAlign: 'left',
      },
      '& .MuiTypography-body1': {
        '& a': {
          color: theme.palette.primary.main
        }
      },
    }
  }
}
class AccountOptinsForm extends Form {
  componentDidMount() {
    this._setupState();
  }

  _getI18n() {
    this.i18n = {
      send: 'Enregistrer',
      sending: 'Enregistrement...'
    }
  }

  render() {
    const {
      currentUserId,
      // showCancel,
      classes,
    } = this.props;
    const moduleConfig = {
      module: 'optins',
      fetchUrl: config.account+'/editOptins',
      postUrl: config.account+'/editOptins',
      formTitle: false,
      redirectAfterEdit: false ,
      afterEdit: 'both',
      afterEditLog: 'Vos modifications ont bien été enregistrées!',
      buttonVariant: 'contained',
      btCancel: this.props.btCancel !== true ? false : true,
      $$bottomActions: {
        btCancel: this.props.btCancel ===  true ? config.account : undefined
      }
    }
    // console.log(moduleConfig)
    return (
      <DynamicForm moduleConfig={moduleConfig} id={currentUserId} {...this.props} padderClass={classes.padderClass} />
    );

  }
}

const mapStateToProps = ($$state) => {
  return {
    currentUserId: getCurrentUserId($$state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountOptinsForm));
