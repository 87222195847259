import React from 'react';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';

import OfferFilterDialog from './OfferFilterDialog';

class OfferFilterDialogButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleButtonClick = () => {
    this.setState({ open: true });
  };

  handleDialogClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleButtonClick}
          startIcon={<FilterListIcon />}
        >
          Filtrer
        </Button>
        <OfferFilterDialog open={open} onClose={this.handleDialogClose} $$selects={this.props.$$selects} />
      </React.Fragment>
    );
  }
}

export default OfferFilterDialogButton;
