import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import HelpSpeedTest from 'components/Help/HelpSpeedTest';
import Template from 'components/UI/Template/Template';
import Faq from 'components/UI/Faq';
import i18n from 'helpers/i18n';
import { getCurrentUserRole } from 'components/UI/Account/ducks/authentication';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
      height: '100%',
      '& .HelpFaqItem-gridBullet': {
        color: 'red',
      },
    },
    pageTitle: {
      paddingTop: 16,
      marginBottom: 8 * 4,
    },
    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '45%',
        backgroundColor: theme.palette.secondary.dark,
        // backgroundImage: 'url('+require('assets/images/header_help.jpg')+')',
        backgroundResize: 'cover',
        zIndex: -1,
      },
    },
    paper: {
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    helpSpeedTest: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    faqItemPadder: {
      backgroundColor: '#fff',
      borderRadius: '16px',
      color: theme.palette.text.dark,
      boxShadow: 'none',
      border: 'none',
      // margin: theme.spacing(3),
      padding: theme.spacing(3),
      height: '100%',
      width: '100%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // '&:nth-of-type(1)': {
        //   marginLeft: theme.spacing(0),
        // }
      },
    },
    faqItemGridBullet: {
      border: '1px ' + theme.palette.secondary.dark + ' solid',
      color: theme.palette.secondary.dark,
      borderRadius: '180px',
      padding: 6,
      width: '2.6rem',
      height: '2.6rem',
      fontSize: '1.3rem',
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(4),
      marginLeft: '0',
      // marginRight: 'auto'
    },
    faqItemGridMainText: {
      color: theme.palette.text.dark,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
      textTransform: 'none',
      width: '100%',
      display: 'block',
    },
    faqItemGridAnswer: {
      color: '#7C878E',
      position: 'relative',
    },
    faqItemGridAnswerBullet: {
      position: 'absolute',
      display: 'inline-block',
      verticalAlign: 'bottom',
      // width: '100%',
      bottom: 10,
      height: 'auto',
      right: 0,
      color: theme.palette.secondary.main,
      opacity: 0.3,
      fontSize: '6rem',
      lineHeight: '1rem',
    },
  };
};

class Help extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { userRole, classes } = this.props;

    const isAdmin = userRole === 'admin';

    return (
      <Template current={'help'} kind={'fullWidth'} maxWidth={'auto'} resize={true}>
        <Box className={classes.paperWrapper}>
          <Typography variant="h1" className={classes.pageTitle}>
            {i18n.get('helpTitle')}
          </Typography>{' '}
          <Paper square elevation={0} className={classes.paper}>
            <Faq
              isAdmin={isAdmin}
              userRole={userRole}
              forceOpen={true}
              faqItemClasses={{
                padder: classes.faqItemPadder,
                gridBullet: classes.faqItemGridBullet,
                gridMainText: classes.faqItemGridMainText,
                gridAnswer: classes.faqItemGridAnswer,
                gridAnswerBullet: classes.faqItemGridAnswerBullet,
              }}
              showAnserBullet={false}
              showQuestionBullet={true}
              gridMainTextMinSize={'normal'}
            />
          </Paper>
          <Box width="100%" my={4}>
            <HelpSpeedTest className={classes.helpSpeedTest} />
          </Box>
        </Box>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}

export default connect(mapStateToProps)(withStyles(styles)(Help));
