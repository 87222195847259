import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import config from 'config/api';
import i18n from 'helpers/i18n';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import AccountOptinsForm from 'components/UI/Account/AccountOptinsForm';
import AccountSettingsForm from 'components/UI/Account/AccountSettingsForm';
import AccountDeleteForm from 'components/UI/Account/AccountDeleteForm';
import Input from 'components/UI/Form/Input';
import Typography from '@material-ui/core/Typography';
import { isLoggedIn, getCurrentUser } from 'components/UI/Account/ducks/authentication';
import { fetchData, getDataHome } from 'ducks/asyncFetcher';
import isMember from 'components/UI/helpers/isMember';
import Template from 'components/UI/Template/Template';
//MODIF B
import LoginRequired from 'components/UI/Login/LoginRequired';

const AF_KEY = 'home';

const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
      width: '100%'
    },
    pageTitle: {
      paddingTop: 16,
      fontSize:'3rem',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
    },
    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.secondary.dark,
        zIndex: -1
      },
      '& h1':{
        color:'#fff',
      },
    },
    paper: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(4),
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
};

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //MODIF B
      showPasswordForm: props.showPasswordForm!==undefined ? props.showPasswordForm : false,
      showOptinForm: props.showOptinForm!==undefined ? props.showOptinForm : false,
      passwordComplete: false,
    };
  }

  componentDidMount() {
    this.props.onMount();
  }

  //MODIF B
  history(url){
    const { history } = this.props;
    history.push(url);
  }
  _onClose = () => {
    this.history('/');
  }
  _onShowChangeOptinForm = () => {
    this.setState({showOptinForm: true})
    this.history('/account/changeoptin');
  }
  _onHideChangeOptinForm  = () => {
    this.setState({showOptinForm: false})
    this.history('/account');
  }
  _onOptinComplete = () => {
    this.setState({showOptinForm: true})
    this.history('/account');
  }
  _onShowChangePassForm = () => {
    this.setState({showPasswordForm: true})
    this.history('/account/changepassword');
  }
  _onHideChangePassForm = () => {
    this.setState({showPasswordForm: false})
    this.history('/account');
  }
  _onPasswordComplete = () => {
    this.setState({passwordComplete: true})
    this.history('/account');
  }

  render() {
    // const { onPanelToggle, classes, userRole, isLoggedIn, $$data } = this.props;
    const { classes, isLoggedIn, $$data, $$user, showOptins, showDelete } = this.props;
    const { showPasswordForm, showOptinForm, passwordComplete } = this.state;
    //console.log({showOptinForm});

    //MODIF B
    //Changement Redirect par message <LoginRequired/>
    /*
    if (!isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: this.props.location },
          }}
          />
      );
    }
    */

    return (
      <Template>
        <Box className={clsx(classes.paperWrapper)}>
          <Typography variant="h1" color="secondary" className={classes.pageTitle}>
            {i18n.get('accountFormTitle')}
          </Typography>{' '}
          <Paper square elevation={0} className={classes.paper}>
            <Paper square elevation={0} className={classes.paper}>
              <Choose>
                <When condition={!isLoggedIn}>
                  <LoginRequired message="Vous devez être connecté pour accéder à cette page."/>
                </When>
                <When condition={isLoggedIn && $$data !== undefined && showPasswordForm === true}>
                  <Box mt={2} width="100%" align="center">
                  <AccountSettingsForm onFormCancel={this._onHideChangePassForm} onFormComplete={this._onPasswordComplete}/>
                  </Box>
                </When>
                <When condition={isLoggedIn && $$data !== undefined && showOptinForm === true}>
                  <Box mt={2} width="100%" align="center">
                    <AccountOptinsForm btCancel={true} onFormCancel={this._onHideChangeOptinForm} onFormComplete={this._onPasswordComplete}/>
                  </Box>
                </When>
                <Otherwise>
                  <If condition={isLoggedIn && $$data !== undefined && isMember($$user, 'member')}>
                    <Box mb={4}>
                      <Typography variant="h6">{i18n.get('accountYourAccount', {pretty: false})}</Typography>
                    </Box>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <Input name="firstname" value={$$user.get('firstname')} label={i18n.get('accountFirstname', {pretty: false})} variant="outlined" disabled={true}/>
                      </Grid>
                      <Grid item xs={6}>
                        <Input name="lastname" value={$$user.get('lastname')} label={i18n.get('accountLastname', {pretty: false})} variant="outlined" disabled={true}/>
                      </Grid>
                      <Grid item xs={12}>
                        <Input name="email" value={$$user.get('email')} label={i18n.get('accountEmail', {pretty: false})} variant="outlined" disabled={true}/>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box align="center" width="100%">
                          <Button variant="contained" color="primary" onClick={this._onShowChangePassForm}>{i18n.get('accountChangePassBt', {pretty: false})}</Button>
                        </Box>
                      </Grid>
                      <If condition={showOptins===true}>
                        <Grid item xs={12} sm={6}>
                          <Box align="center" width="100%">
                            <Button variant="contained" color="primary" onClick={this._onShowChangeOptinForm}>{i18n.get('accountChangeOptinsBt', {pretty: false})}</Button>
                          </Box>
                        </Grid>
                      </If>
                      <If condition={showDelete===true}>
                        <Grid item xs={12}>
                          <Box align="center" width="100%">
                            <AccountDeleteForm onFormCancel={this._onClose} />
                          </Box>
                        </Grid>
                      </If>
                    </Grid>
                  </If>
                </Otherwise>
              </Choose>
            </Paper>
          </Paper>
        </Box>
      </Template>
    );
  }
}
function mapStateToProps(state) {
  const props = {
    $$user: getCurrentUser(state),
    $$data: getDataHome(state),
    isLoggedIn: isLoggedIn(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Account)));
