import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import Immutable from 'immutable';
import Template from 'components/UI/Template/Template';
import LoginForm from 'components/UI/Login/LoginForm';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { isLoggedIn, getCurrentUserRole } from 'components/UI/Account/ducks/authentication';
import ResetPasswordRequestForm from 'components/UI/ResetPassword/ResetPasswordRequestForm';
import RegistrationTwoStepsForm from 'components/UI/Registration/RegistrationTwoStepsForm';
import i18n from 'helpers/i18n';

const StyledRegistrationTwoStepsForm = withStyles(theme => ({
  root: {
    '& .MuiTypography-h5': {
      fontSize: '1.075rem',
      fontWeight: 600
    },
    '& .MuiButton-textSecondary': {
      color: theme.palette.primary.dark,
      textDecoration: 'underline'
    },
    '& .MuiButton-containedPrimary': {
    },
    '& .MuiMenuItem-root': {
      display: 'inline-block',
      color: theme.palette.secondary.dark,
      backgroundColor: 'transparent',
      width: '50%',
      marginRight: 0,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      }
    },

  },
  column: {
    padding: 0,
    '&:before': {
      border: 'none'
    }
  },
  formGroup: {
    '&:before': {
      border: 'none',
    },
    marginBottom: theme.spacing(2)

  },
  jobForm: {
    maxWidth: 420,
    display: 'block',
    margin: '0 auto',
    '& .MuiButton-containedPrimary': {
      display: 'block'
    },
  },
  '& .MuiMenuItem-root': {
    display: 'inline-block',
    color: theme.palette.secondary.dark,
    backgroundColor: 'transparent',
    width: 200,
    marginRight: theme.spacing(2),
  },
  subscriptionFormNotice: {
    textAlign: 'left',
  }
}))(RegistrationTwoStepsForm)


const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
      height: '100%',
      // maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
      '& .MuiTypography-h3': {
        textAlign: 'center',
      },
      '& a': {
        textAlign: 'left',
      },
      '& .MuiPaper-root': {
        // color: '#fff'
      },
    },
    pageTitle: {
      paddingTop: 16,
      marginBottom: 40,
    },

    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.secondary.dark,
        // backgroundImage: 'url('+require('assets/images/header_account.jpg')+')',
        backgroundResize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: -1,
      },
    },

    paper: {
      padding: theme.spacing(4),
      position: 'relative',
      // color: theme.palette.primary.main,
      '& .MuiTypography-h3': {
        // color: '#fff',
      },
    },
  };
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };
  }
  // componentDidMount = () => {
  // this._performActionRedirection()
  // }
  // componentDidUpdate = () => {
  // this._performActionRedirection();
  // }
  _onLoginPasswordReset = () => {
    this.setState({
      step: 3,
    });
  };
  _onRegistrationCancel = () => {
    this.setState({
      step: 1,
    });
  };
  render() {
    const { classes, isLoggedIn, kind, showStep } = this.props;
    const { step } = this.state;
    const uri = queryString.parse(window.location.search);
    if (isLoggedIn) {
      const url = uri._pn !== undefined ? decodeURIComponent(uri._pn) : '/';
      return (
        <Redirect
          to={{
            pathname: url,
            state: { from: this.props.location },
          }}
        />
      );
    }
    const action = uri.action;
    return (
      <Template current={'login'}>
        <Box className={classes.paperWrapper}>
          <Grid container spacing={2} className={classes.root}>
            <If condition={step === 1}>
              <Grid item xs={12}>
                <Typography variant="h1" className={classes.pageTitle}>
                  {' '}
                  {i18n.get('loginAction')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper square elevation={0} className={classes.paper}>
                  <If condition={kind === 'private'}>
                    <Box mb={4}>
                      <Typography variant="h5" gutterBottom align="center">
                        {i18n.get('accountAccessRestricted')}
                      </Typography>
                    </Box>
                  </If>
                  <If condition={kind !== 'private' && (action === 'login' || action === undefined)}>
                    <StyledRegistrationTwoStepsForm
                      showRequiredLegend={false}
                      showRecap={true}
                      showStep={showStep}
                      scrollOnMount={false}
                    />
                  </If>
                  <If condition={kind !== 'private' && action === 'register'}>
                    <StyledRegistrationTwoStepsForm
                      showRequiredLegend={false}
                      showRecap={true}
                      showStep={showStep}
                    />
                  </If>
                  <If condition={kind === 'private'}>
                    <LoginForm
                      onFormComplete={this._onLoginFormComplete}
                      onPasswordResetRequest={this._onLoginPasswordReset}
                      footerAlign={'left'}
                      showRequiredLegend={false}
                      extraFields={Immutable.fromJS({ kind })}
                    />
                  </If>
                </Paper>
              </Grid>
            </If>
            <If condition={step === 3}>
              <Grid item xs={12}>
                <Typography variant="h1" className={classes.pageTitle}>
                  {' '}
                  {i18n.get('loginForgotPassword')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper square elevation={0} className={classes.paper}>
                  <Box align="center">
                    <ResetPasswordRequestForm onFormCancel={this._onRegistrationCancel} noTitle />
                  </Box>
                </Paper>
              </Grid>
            </If>
          </Grid>
        </Box>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
    isLoggedIn: isLoggedIn(state),
  };
  return props;
}
export default withRouter(connect(mapStateToProps)(withStyles(styles)(Login)));
