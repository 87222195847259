import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import config from "config/api";
import getFetchOptions from "helpers/getFetchOptions";
import DynamicForm from "components/UI/DynamicForm";
import DialogConfirm from "components/UI/Dialog/DialogConfirm";
// import Immutable from "immutable";

// import { getCurrentUserId, getToken } from "components/UI/Account/ducks/authentication";
import {
  getCurrentUserId,
  getToken,
} from "components/UI/Account/ducks/authentication";

class AccountAdminForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      dialogName: undefined,
      dialogTitle: undefined,
      dialogText: undefined,
      log: undefined,
      // token: undefined,
    };
    // this._onConfirmed = this._onConfirmed.bind(this);
    // this._onCanceled = this._onCanceled.bind(this);
  }

  _getI18n() {
    this.i18n = {
      send: "Enregistrer",
      sending: "Enregistrement...",
    };
  }
  _onConfirmed = (name, props) => {
    if (this["_do" + name]) {
      this["_do" + name](props);
    }
  };
  _onCanceled = () => {
    this.setState({
      dialogOpen: false,
      dialogName: undefined,
      dialogTitle: undefined,
      dialogText: undefined,
    });
  };
  _onPreviewAccount = (a, b, $$values) => {
    console.log(a, b)
    // this.setState({
    //   dialogOpen: true,
    //   dialogTitle: "Souhaitez-vous activer ce compte ?",
    //   dialogText: $$values.get("accountEmail"),
    //   dialogName: "ActivateAccount",
    //   onConfirmProps: $$values,
    // });
    const url = '/staff/account/preview/'+$$values.get('id')
    console.log({url})
  };
  _onActivateAccount = (a, b, $$values) => {
    // console.log(a, b)
    this.setState({
      dialogOpen: true,
      dialogTitle: "Souhaitez-vous activer ce compte ?",
      dialogText: $$values.get("accountEmail"),
      dialogName: "ActivateAccount",
      onConfirmProps: $$values,
    });
  };
  _doActivateAccount = async (props) => {
    const url = config.account + "/activate";
    let data = { account: this.props.id };
    // console.log(props.get("accountEventCategory"))
    if (
      props.get("accountEventCategory") !== undefined &&
      props.get("accountEventCategory") !== null &&
      props.get("accountEventCategory") !== "" &&
      props.get("accountEventCategory").size !== undefined
    ) {
      data.accountEventCategory = props.get("accountEventCategory").toJS();
    }
    // data.csrf = this.state.token;
    data.csrf = await getToken();
    fetch(url, getFetchOptions("POST", data))
      .then((response) => response.json())
      .then((response) => {
        if (response.activation && response.activation === "complete") {
          this.setState({ redirectToList: true, locked: false , dialogOpen: false});
        } else if (response.status && response.status === "error") {
          this.setState({ activationError: response.log, locked: false, dialogOpen: false });
        }
      })
      // .catch((e) => {
      //   this.setState({ locked: false });
      // });
  };
  _onDeactivateAccount = (a, b, $$values) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: "Souhaitez-vous désactiver ce compte ?",
      dialogText: $$values.get("accountEmail"),
      dialogName: "DeactivateAccount",
      onConfirmProps: $$values,
    });
  };
  _doDeactivateAccount = async (props) => {
    const url = config.account + "/deactivate";
    let data = { account: this.props.id };
    // data.csrf = this.state.token;
    data.csrf = await getToken();
    if (
      props.get("accountEventCategory") !== undefined &&
      props.get("accountEventCategory") !== null &&
      props.get("accountEventCategory") !== "" &&
      props.get("accountEventCategory").size !== undefined
    ) {
      data.accountEventCategory = props.get("accountEventCategory").toJS();
    }

    fetch(url, getFetchOptions("POST", data))
      .then((response) => response.json())
      .then((response) => {
        if (response.deactivation && response.deactivation === "complete") {
          this.setState({ redirectToList: true, locked: false });
        } else if (response.status && response.status === "error") {
          this.setState({ activationError: response.log, locked: false });
        }
      });
  };
  _onBlockAccount = (a, b, $$values) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: "Souhaitez-vous bloquer ce compte ?",
      dialogText: $$values.get("accountEmail"),
      dialogName: "BlockAccount",
      onConfirmProps: $$values,
    });
  };
  _doBlockAccount = async (props) => {
    const url = config.account + "/block";
    let data = { account: this.props.id };
    // data.csrf = this.state.token;
    data.csrf = await getToken();
    if (
      props.get("accountEventCategory") !== null &&
      props.get("accountEventCategory") !== "" &&
      props.get("accountEventCategory").size !== undefined
    ) {
      data.accountEventCategory = props.get("accountEventCategory").toJS();
    }

    fetch(url, getFetchOptions("POST", data))
      .then((response) => response.json())
      .then((response) => {
        if (response.block && response.block === "complete") {
          this.setState({ redirectToList: true, locked: false });
        } else if (response.status && response.status === "error") {
          this.setState({ activationError: response.log, locked: false });
        }
      });
  };
  _onTokenSet = (token) => {
    this.setState({ token });
  };
  render() {
    const { id } = this.props;
    const moduleConfig = {
      module: "account",
      fetchUrl: config.account + "/edit",
      postUrl: config.account + "/edit",

      formTitle: false,
      redirectAfterEdit: false,
      afterEdit: "onFormComplete",
      buttonVariant: 'contained',
      bottomActionsEvents: {
        // 'onActivateAccount': this._onActivateAccount.bind(this),
        // 'onDeactivateAccount': this._onDeactivateAccount.bind(this),
        onPreviewAccount: this._onPreviewAccount,
        onActivateAccount: this._onActivateAccount,
        onDeactivateAccount: this._onDeactivateAccount,
        onBlockAccount: this._onBlockAccount,
      },
    };

    const {
      dialogOpen,
      dialogName,
      dialogTitle,
      dialogText,
      onConfirmProps,
    } = this.state;
    const { activationError } = this.state;

    if (this.state.redirectToList) {
      return (
        <Redirect
          to={{
            pathname: "/staff/account/list",
            state: { from: this.props.location },
          }}
        />
      );
    }
    // if (onConfirmProps !== undefined)
    // console.log(onConfirmProps.toJS())

    return (
      <React.Fragment>
        <If condition={dialogOpen}>
          <DialogConfirm
            open={true}
            onConfirmed={this._onConfirmed}
            onCanceled={this._onCanceled}
            name={dialogName}
            title={dialogTitle}
            text={dialogText}
            textStyle={{ textAlign: "center" }}
            onConfirmProps={onConfirmProps}
          />
        </If>
        <DynamicForm
          moduleConfig={moduleConfig}
          id={id}
          otherError={activationError}
          {...this.props}
          onTokenSet={this._onTokenSet}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ($$state) => {
  return {
    currentUserId: getCurrentUserId($$state),
  };
};

export default connect(mapStateToProps)(AccountAdminForm);
