import React from "react";
import ReactDOM from "react-dom";
import Immutable from "immutable";
import MUISelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import isArray from 'mout/lang/isArray';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import inputIron from './inputIron';
import Prettyfier from 'components/UI/Prettyfier'

import {
  withStyles
} from "@material-ui/core/styles";
const styles = (theme) => {

  const bgColor =
    theme.palette.background.input !== undefined
      ? theme.palette.background.input
      : theme.palette.background.default;

  return {
    root: {
      '& .MuiInputLabel-shrink': {
        backgroundColor: bgColor
      },
      '& .MuiFormLabel-root': {
        color: 'inherit'
      },
      '& .Mui-disabled': {
        color: theme.palette.text.disabled
      },
      '& .MuiMenuItem-root': {
        whiteSpace: 'normal',
        width: '100%',
        overflow: 'visible'
      },
      ...inputIron(theme)
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      alignSelf: 'flex-start'
    },
    selected: {
      textAlign: 'left'
    },
    item: {
      whiteSpace: 'normal',
      width: '100%',
      overflow: 'visible',
      '& small': {
        display: 'block'
      }
    }
  }
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0,
      value: "",
    };
    // this._onChange = this._onChange.bind(this);
  }
  componentDidMount() {
    let value;
    const {defValue} = this.props;
    if(this.props.multiple === true) {
      // console.log('start value is', this.props.id, this.props.value)
      value = this.props.value !== undefined && this.props.value !== null ? this.props.value : [];
      if (value.length === 0 && defValue) value.push(defValue);
      this.setState({
        value,
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    } else {
      // console.log('val', this.props.value, defValue)
      value = this.props.value === undefined && defValue !== undefined ? defValue : this.props.value;
      // console.log('select', this.props.id, value)
      this.setState({
        value,
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    }
  }
  componentDidUpdate(oldProps) {
    // if (this.props.multiple === true) {
    //   console.log('update value is', this.props.id, this.props.value)
    // }
    // if(oldProps.value === undefined && oldProps.value !== this.props.value) {
      if(oldProps.value !== this.props.value) {
      // console.log('FIELD VALUE CHANGED !!',oldProps.value, this.props.value)
      // console.log(ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth)
      this.setState({
        value: this.props.value,
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    }
  }
  _onFocus = (e) => {
    const {onFocus} = this.props;
    if (onFocus === undefined) return;
    else if(onFocus) {
      var t = e.target;
      onFocus("select", {
        id: t.name,
      });
    }

  }
  _onChange = (e) => {
    const {
      onChange,
      multiple
    } = this.props;
    const {
      value
    } = this.props;
    var t = e.target;
    let nValue;
    if(multiple !== true)
    nValue = t.value;
    else {
      if(value !== null && value !== undefined && value.size !== undefined && !isArray(value)) {
        // console.log('1')
        // nValue = value.toJS();
        // nValue.push(t.value[0]);
        nValue = t.value
        nValue = Immutable.fromJS(nValue);
        // nValue = Immutable.fromJS(t.value)
        // console.log('bleh', nValue.toJS())
      } else if(isArray(value)) {
        // console.log('2')
        // nValue = value;
        // nValue.push(t.value);
        // nValue = Immutable.fromJS(nValue);
        nValue = t.value;
        nValue = Immutable.fromJS(nValue);
      } else if (value === undefined) {
        nValue = Immutable.fromJS(t.value)
      } else if(value !== '') {
        // console.log('3')
        nValue = [value];
        nValue = Immutable.fromJS(nValue);
        // nValue.push(t.value);
        // nValue = Immutable.fromJS(nValue);
      } else {
        // console.log('4')
        nValue = value;
      }
    }
    // console.log('state value', value.toJS(), nValue, nValue.toJS())
    this.setState({
      value: nValue
    }, function() {
      // console.log('CHANGED T', t, t.name, t.value)

      if(onChange) {
        onChange("select", {
          id: t.name,
          value: nValue,
        });
      }
    });
  }
  render() {
    const {
      // id,
      // name,
      id,
      // name: id,
      options: propOptions,
      classes,
      label,
      chips,
      error,
      multiple,
      required,
      variant,
      // defValue,
      fullWidth,
      align,
      disabled,
      style,
      makeItems,
      errorMessage,
      showDefault,
      prettyfyLabel,
      prettyfyClassName,
      // placeholder,
      paperWidth,
      // addEmptyDefault
    } = this.props;
    // console.log('CHECKBOX ERROR IS', error)
    // console.log('CHECK ID IS ', id, disabled)
    const MenuProps = {
      PaperProps: {
        style: {
          // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          // maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
          maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
          width: paperWidth || 250,
        },
      },
    };

    const {
      value
    } = this.state;
    let finalValue = value;

    let options;
    // if (addEmptyDefault === true) {
      // options = Immutable.fromJS([{label: '...', value: '@reset@'}, ...propOptions.toJS()])
    // }
    // else
      options = propOptions;

    if(options !== false && options !== null) {
      // console.log(options)
      const found = options.filter((o) => o.get("value") === value);
      if(found === undefined || found.get(0) === undefined) finalValue = "";
    }
    let helperText = false;
    if(errorMessage !== undefined && errorMessage !== false)
    helperText = errorMessage;
    // const variant = false;
    let selectValue;
    if(multiple !== true) {
      selectValue = finalValue !== undefined && finalValue !== null ? finalValue : ''
      // console.log('selectValue', selectValue)
    } else {
      if(value !== undefined && value !== null && value.size !== undefined) {
        // console.log('a')
        selectValue = value.toJS()
      } else if(isArray(value)) {
        selectValue = value;
        // console.log('b')
      } else if(value !== '' && value !== null) {
        selectValue = [value]
        // console.log('c')
      } else {
        selectValue = [];
        // console.log('d')
      }
    }
    // if (selectValue === undefined) selectValue === '';
    // console.log({finalValue, selectValue})
    // console.log('selectValue',id, this.props.value, value, selectValue)
    let renderValue;
    if(multiple === true) {
      if(chips === true) {
        renderValue = (selected) => (
          <div className={classes.chips}>
            {selected.map((value) => {
              return (
                <Chip key={value !== undefined ? value : 'undef'} label={value !== undefined ? value : '...'} className={classes.chip} />
              )})}
            </div>);
          } else {
            renderValue = (selected, a, b) => {
              // console.log('check labels !!!')
              let labels = [];
              options.map((o) => {
                if(selected.indexOf(o.get('value')) >= 0)
                labels.push(o.get('label'))
                return true;
              });
              // console.log(labels);
              return (<React.Fragment>
                <If condition={selected.length === 0}>
                  <em>&nbsp;</em>
                </If>
                <If condition={selected.length > 0}>
                  <div className={classes.selected}>{labels.join(', ')}</div>
                </If>
              </React.Fragment>);
            }
          }
        }
        if(variant !== "outlined") {
          return (
            <FormControl
              fullWidth={fullWidth !== undefined ? fullWidth : true}
              className={classes.root}
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              error={error}
              style={style}
              disabled={disabled}
              variant={variant}
              >
              <InputLabel required={required} error={error} htmlFor={id} classes={{root: classes.input}}>
                {label}
              </InputLabel>
              <MUISelect
                id={id}
                name={id}
                value={
                  selectValue
                }
                align={align}
                fullWidth={fullWidth !== undefined ? fullWidth : true}
                onChange={this._onChange}
                onFocus={this._onFocus}
                multiple={multiple}
                required={required}
                disabled={disabled}
                renderValue={renderValue}
                MenuProps={MenuProps}
                style={{ textAlign: "left" }}

                >
                <If condition={showDefault}>

                  <MenuItem value={""}>
                    <em>...</em>
                  </MenuItem>
                </If>
                <If condition={options !== undefined && makeItems === undefined}>
                  {options
                    .map((opt, idx) => {
                      return (
                        <MenuItem key={'opt_'+idx} value={opt.get("value")}>
                          <If condition={prettyfyLabel === true}>
                            <Prettyfier className={prettyfyClassName} text={opt.get("label")}/>
                          </If>
                          <If condition={prettyfyLabel !== true}>
                            {opt.get("label")}
                          </If>
                        </MenuItem>
                      );
                    })
                    .toArray()}
                  </If>
                  <If condition={options !== undefined && makeItems !== undefined}>
                    {makeItems({options, multiple, selectValue})}
                  </If>
                </MUISelect>
                <If condition={helperText !== undefined && helperText !== false}>
                  <FormHelperText error={error}>{helperText}</FormHelperText>
                </If>
              </FormControl>
            );
          } else if(variant === "outlined") {
            return (
              <FormControl variant="outlined"
                fullWidth={fullWidth !== undefined ? fullWidth : true}
                error={error}
                className={classes.root} style={style}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor={"outlined-" + id + "-simple"}
                  disabled={disabled}
                  required={required}
                  >
                  {label}
                </InputLabel>
                <MUISelect
                  id={id}
                  name={id}
                  value={selectValue}
                  align={align}
                  fullWidth={fullWidth !== undefined ? fullWidth : true}
                  onFocus={this._onFocus}
                  onChange={this._onChange}
                  multiple={multiple}
                  disabled={disabled}
                  renderValue={renderValue}
                  MenuProps={MenuProps}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name={id}
                      id={"outlined-" + id + "-simple"}
                      />
                  }
                  >
                  <If condition={multiple !== true}>
                    <If condition={showDefault}>
                      <MenuItem value={""}>
                        <em>...</em>
                      </MenuItem>
                    </If>
                  </If>
                  <If condition={options !== null && options !== undefined && makeItems === undefined}>
                    {options
                      .map((opt, idx) => {
                        return (
                          <MenuItem key={'opt_'+idx} value={opt.get("value")} disabled={opt.get("disabled") === true} className={classes.item}>
                            <If condition={multiple === true}>
                              <Checkbox checked={selectValue.indexOf(opt.get('value')) > -1} />
                            </If>
                              <If condition={prettyfyLabel === true}>
                                <Prettyfier text={opt.get("label")}/>
                              </If>
                              <If condition={prettyfyLabel !== true}>
                                {opt.get("label")}
                              </If>

                          </MenuItem>
                        );
                      })
                      .toArray()}
                    </If>
                    <If condition={options !== undefined && makeItems !== undefined}>
                      {makeItems({options, multiple, selectValue})}
                    </If>

                  </MUISelect>
                </FormControl>
              );
            }
          }
        }
        export default withStyles(styles)(Select);
