import React, { Component } from 'react';
// import AccountList from './AdminAccountList';
// import { Route } from 'react-router-dom'
import i18n from 'helpers/i18n';
import Rating from "@material-ui/lab/Rating";
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchAccountPreview, clearAccountPreview, getAccountError, getAccountPreview } from './ducks/account';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
// import Paper from '@material-ui/core/Paper';
// import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import Prettyfier from 'components/UI/Prettyfier';
import { formatDate } from "helpers/stringUtils";

const styles = (theme) => {
  return {
    root: {
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4)
    },
    breadCrumbs: {
      'a': {
        display: 'inline'
      }
    },
    identification: {
      '& .MuiTypography-subtitle1': {
        display: 'inline-flex',
        minWidth: 200,
        fontWeight: 'bold'
      },
      '& .MuiTypography-body1': {
        display: 'inline-flex'
      }
    },
    subscriptions: {

    },
    block: {
      boxShadow: theme.shadows[6],
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    }
  }
}
class AccountPreview extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {
    const {accountId} = this.props;
    this.props.onMount({accountId});
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }
  render() {
    const {$$account, classes} = this.props;
    // console.log($$account)
    let hasAddressData = false;
    if ($$account !== undefined) {
    $$account.get('previewAddress').get('data').map((value, field) => {
      if (value !== null && value !== '') hasAddressData = true;
      return true;
    })
    }
    //console.log({hasAddressData})
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <If condition={$$account !== undefined}>
            <Grid item xs={12}>
              <Box className={classes.breadCrumbs}>
                <Link to={'/staff/dashboard'}>Dashboard</Link>&nbsp;&gt;&nbsp;<Link to='/staff/account/list'>{i18n.get('dashboardNavigationAccounts', {pretty: false})}</Link>
              <If condition={$$account !== undefined}>
                &nbsp;&gt;&nbsp;{$$account.get('previewAccount').get('data').get('accountFirstname')} {$$account.get('previewAccount').get('data').get('accountLastname')}
              </If>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} container justifyContent={'flex-start'}>
              <Box className={clsx(classes.block, classes.identification)}>
                <Typography variant={'h6'} gutterBottom>{i18n.get('accountData')}</Typography>
                {$$account.get('previewAccount').get('labels').filter((label, lIdx) => {
                  const val = $$account.get('previewAccount').get('data').get(label.get('id'));
                  return val !== '' && val !== undefined && val !== null
                }).map((label, lIdx) => {
                  return (<Grid item xs={12} key={'l_'+lIdx}>
                  <Typography variant="subtitle1">{label.get('name')} : </Typography>&nbsp;<Typography variant="body1">{$$account.get('previewAccount').get('data').get(label.get('id'))}</Typography>
                </Grid>)
              }).toArray()}
            </Box>
          </Grid>
            <Grid item xs={12} sm={6} container justifyContent={'flex-start'}>
              <Box className={clsx(classes.block, classes.identification)}>
                <Typography variant={'h6'} gutterBottom>{i18n.get('accountAddress')}</Typography>
                <If condition={hasAddressData !== false}>
                {$$account.get('previewAddress').get('labels').filter((label, lIdx) => {
                  const val = $$account.get('previewAddress').get('data').get(label.get('id'));
                  return val !== '' && val !== undefined && val !== null
                }).map((label, lIdx) => {
                  return (<Grid item xs={12} key={'l_'+lIdx}>
                  <Typography variant="subtitle1">{label.get('name')} : </Typography>&nbsp;<Typography variant="body1">{$$account.get('previewAddress').get('data').get(label.get('id'))}</Typography>
                </Grid>)
              }).toArray()}
              </If>
              <If condition={hasAddressData === false}>
                  <Typography variant="body1">Aucune information disponible</Typography>
              </If>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent={'flex-start'}>
            <Box className={clsx(classes.block, classes.subscriptions)}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Inscriptions</Typography>
                <If condition={$$account.get('subscriptions').get('rank') !== -1}>
                <Typography variant="body1" gutterBottom style={{ display: "inline-flex", verticalAlign: 'middle' }}><strong>Assiduité</strong></Typography>
                <Rating
                  readOnly={true}
                  name={"r_account"}
                  size="small"
                  style={{ display: "inline-flex", verticalAlign: 'middle', marginTop: -2, marginLeft: 8  }}
                  value={$$account.get('subscriptions').get('rank')}
                  />
                <Typography variant="body1" gutterBottom><strong>Détails</strong></Typography>
                <Box pl={1}>
                  {$$account.get('subscriptions').get('details').map(($$s, sIdx) => {
                    return (<Box mb={3} key={'b_'+sIdx}>
                    <Typography variant="caption" component="div">{formatDate($$s.get('eventDate'), "dd/mm/yyyy HH:MM")}</Typography>

                    <Typography variant="body1" color="primary" component="div"><Prettyfier text={$$s.get('eventTitle')} prettyfy={true} gutterBottom/></Typography>
                    <Typography variant="body1" element="div">
                      {i18n.get('accountPresent', {pretty: false})}&nbsp;:&nbsp;
                      <If condition={$$s.get('attended') !== '' && $$s.get('attended') !== null}>
                        {formatDate($$s.get('attended'), "dd/mm/yyyy HH:MM")}
                      </If>
                      <If condition={$$s.get('attended') === '' || $$s.get('attended') === null}>
                        Non
                      </If>
                    </Typography>
                  </Box>);
                }).toArray()}
              </Box>
              </If>
              <If condition={$$account.get('subscriptions').get('rank') === -1}>
              <Typography variant="body1" gutterBottom style={{ display: "inline-flex", verticalAlign: 'middle' }}>Aucune inscription</Typography>
              </If>
            </Grid>
          </Box>

        </Grid>
      </If>


    </Grid>
  </div>
);
}
}

function mapStateToProps(state) {
  const props = {
    $$account: getAccountPreview(state),
    $$error: getAccountError(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      dispatch(fetchAccountPreview(props));
    },
    onUnmount: (props) => {
      dispatch(clearAccountPreview());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountPreview));
