import Immutable from 'immutable';
import config from 'config/api';

export default ($$item, options) => {
    if ($$item === undefined || $$item.get('eventGuestsThumb') === null || $$item.get('eventGuestsThumb') === undefined || $$item.get('eventGuestsThumb') === false) return false;
    let guests;
    if ($$item.get('eventGuestsThumb').size)
      guests = $$item.get('eventGuestsThumb').toJS()
    else if ($$item.get('eventGuestsThumb') !== undefined &&
    $$item.get('eventGuestsThumb') !== null &&
    $$item.get('eventGuestsThumb') !== false){
      try {
        guests = JSON.parse($$item.get('eventGuestsThumb'))
      } catch(e) {
        guests = []
      }
    } else
    guests = []

    guests.map((g, gIdx) => {
      guests[gIdx].dest = config.eventthumbpreview.replace('{eventId}', $$item.get(options && options.eventId ? options.eventId : 'id')) + '/' + g.dest;
      return true;
    })
    return Immutable.fromJS(guests);
  }
