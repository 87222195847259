import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import Header from "components/Header/Header";
import ResetPasswordRequestForm from "./ResetPasswordRequestForm";
import { isLoggedIn } from "components/UI/Account/ducks/authentication";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
// import Banner from "components/Banner/Banner";
import Fade from "@material-ui/core/Fade";
import Footer from "components/Footer";
import { withRouter } from "react-router-dom";

const styles = (theme) => {
  return {
    root: {
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      "& > *": {
        flex: 1,
      },
    },
    paper: {
      padding: theme.spacing(4),
    },
  };
};

class ResetPasswordRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _onCancel = () => {
    if (this.props.onCancel && this.props.onCancel === 'gotoHome')
      this.props.history.push('/')
  }
  render() {
    const { onPanelToggle, classes, userRole, isLoggedIn } = this.props;

    if (isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <Fade in timeout={1000}>
        <div className={classes.root}>
          <Container fixed disableGutters style={{ display: "flex" }}>
            <ScrollToTopOnMount />
            <Grid container direction="column" style={{ flex: 1 }}>
              <Grid item xs={12} style={{ flex: 0 }}>
                <Header
                  onPanelToggle={onPanelToggle}
                  userRole={userRole}
                  current={"account"}
                  headerImage="background"
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ flex: 1 }}
                container
                direction="column"
              >
                <Box className={classes.paperWrapper}>
                  <Paper square elevation={0} className={classes.paper}>
                    <ResetPasswordRequestForm
                      onFormCancel={this._onCancel}
                    />
                  </Paper>
                </Box>
              </Grid>
              <Footer />
            </Grid>
          </Container>
        </div>
      </Fade>
    );
  }
}
function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
  };
  return props;
}

export default withRouter(withStyles(styles)(
  connect(mapStateToProps)(ResetPasswordRequest))
);
