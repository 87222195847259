import React, { Component } from 'react';
import clsx from 'clsx';
import Header from 'components/Header/Header';
import Footer from 'components/Footer';
import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
      height: '100%',
      width: '100%',
      maxWidth: '1280px',
      margin: '0 auto',
      color: theme.palette.template && theme.palette.template.color ? theme.palette.template.color : theme.palette.primary.main,
    },
    fullHeight: {
      height: '100%',
    },
    fullWidth: {
      width: '100%',
      maxWidth: 'none',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    wrapper: {
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        minHeight: 'auto',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    content: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      }
    },
    noPadding: {
      padding: 0,
    },
  };
};

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedSize: false,
    };
  }
  componentDidMount() {
    if (this.props.resize !== false)
    {
    this._onWindowResize();
    window.addEventListener('resize', this._onWindowResize);
    }
  }
  componendWillUnmount() {
    if (this.props.resize !== false)
    {
    window.removeEventListener('resize', this._onWindowResize);
  }
  }
  _onWindowResize = () => {
    // const { classes } = this.props;
    const el = document.querySelector('#root');
    // console.log(el.offsetHeight, window.innerHeight)
    if (el.offsetHeight <= window.innerHeight) {
      el.style.height = window.innerHeight + 'px';
    } else {
      el.style.height = 'auto';
    }
  };
  render() {
    const { classes, title, children, current, headerHeight, headerContent, headerClasses, headerImage, headerPosition, maxWidth } = this.props;

    return (
      <Container
        disableGutters
        className={clsx(classes.root, {[classes.fullWidth]: maxWidth === 'auto'})}
        fixed={false}
        maxWidth={false}
        style={{
          height: '100%',
          minHeight: '940px',
        }}
      >
        <Grid className={classes.wrapper} container alignContent="flex-start" style={{minHeight: 'calc(100% - '+(headerHeight !== undefined ? headerHeight: 150)+'px)'}}>
          <Header current={current} headerTitle={title} headerPosition={headerPosition} headerImage={headerImage} headerContent={headerContent} classes={headerClasses}/>
          <ScrollToTopOnMount />
          <Grid item xs={12} container alignContent="flex-start" className={clsx(classes.content,{[classes.noPadding]: maxWidth === 'auto'})}>
            {children}
          </Grid>
        </Grid>
        <Footer />
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(Template));
