import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const DialogTitle = withStyles((theme) => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: '20px ' + theme.spacing(1) + 'px 10px',
    maxWidth: '800px',
  },
  noTitle: {
    marginBottom: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    margin: '10px auto 0px',
    padding: '0 40px',
    textAlign: 'center',
    fontSize: '22px',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
}))((props) => {
  const { children, classes, onClose, showOnClose, titleStyle, upperCase } = props;
  // console.log({children})
  return (
    <MuiDialogTitle
      disableTypography
      className={clsx(classes.root, {
        [classes.noTitle]: children === undefined,
      })}
    >
      <If condition={children !== undefined}>
        <Typography
          variant="h4"
          classes={{
            root: classes.title + (upperCase !== false ? ' ' + classes.upperCase : ''),
          }}
          style={titleStyle}
        >
          {children}
        </Typography>
      </If>
      <If condition={showOnClose !== undefined && showOnClose !== false}>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </If>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing.unit * 2,
    padding: '0 ' + theme.spacing(2) + theme.unit,
    maxWidth: '800px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class CustomizedDialogDemo extends React.Component {
  state = {
    open: false,
  };

  componentDidMount = () => {
    this.setState({
      open: this.props.open,
    });
  };

  componentDidUpdate = (oldProps) => {
    if (oldProps.open !== this.props.open)
      this.setState({
        open: this.props.open,
      });
  };
  handleClose = (reason) => {
    console.log(reason)
    const { onClose } = this.props;
    if (onClose !== undefined) onClose();
    else this.setState({ open: false });
  };
  render() {
    const {
      title,
      children,
      // onClose,
      closeButton,
      titleStyle,
      titleClass,
      actions,
      showOnClose,
      paperStyle,
      paperClassName,
      onBackdropClick,
      // disableBackdropClick,
    } = this.props;

    const { open } = this.state;

    let PaperProps = {
      style: { width: '100%', maxWidth: 800 },
    };
    if (paperStyle !== undefined) PaperProps.style = { ...PaperProps.style, ...paperStyle };
    if (paperClassName !== undefined) PaperProps.className = paperClassName;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
        open={open}
        maxWidth={'md'}
        PaperProps={PaperProps}
        onBackdropClick={onBackdropClick}
      >
        <DialogTitle
          id="dialog-title"
          onClose={closeButton !== false ? this.handleClose : undefined}
          showOnClose={showOnClose}
          titleStyle={titleStyle}
          titleClass={titleClass}
        >
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <If condition={actions !== undefined}>
          <DialogActions>{actions}</DialogActions>
        </If>
      </Dialog>
    );
  }
}

export default CustomizedDialogDemo;
