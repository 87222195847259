import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import config from 'config/api';
import { withStyles } from '@material-ui/core/styles';
import sheets_download from 'assets/images/sheets_download.svg';

const styles = (theme) => {
  return {
    root: {
    },
    exportLinkDisplayBloc:{
      height:'105px',
    },
    exportLinkDisplayRelative:{
      display:'inline',
    },
    exportLinkRelativeBloc:{
      position:'relative',
    },
    exportLink:{
      position:'absolute',
      left:'5px',
      top:'-90px',
      fontSize:'85%',
      color:'#222',
      fontWeight:600,
      textDecoration:'none',
      backgroundColor: 'white',
      borderRadius: '6px',
      padding: '5px',
      border: '#fff 1px solid',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.16),0px 1px 1px 0px rgba(0,0,0,0.12),0px 1px 3px 0px rgba(0,0,0,0.1)',
      '& img':{
        verticalAlign:'bottom',
      },
      '&:hover':{
        border: '#c6eac1 1px solid',
      },
    },
  }
}


class DashboardExportButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes, path, display } = this.props;
    const spacerClass= display=='bloc' ? classes.exportLinkDisplayBloc : classes.exportLinkDisplayRelative;
    return (
        <React.Fragment>
        <div className={spacerClass}></div>
          <div className={classes.exportLinkRelativeBloc}>
            <a title="Télécharger les données au format Excel" className={classes.exportLink} href={config.baseUrl+"/api/export2/"+path}><img src={sheets_download} height="50"/><br/>Export XLSX</a>
          </div>
        </React.Fragment>
      );
  }
}


export default withRouter((withStyles(styles)(DashboardExportButton)));