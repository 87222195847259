import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import { withStyles } from '@material-ui/core/styles';
import Template from 'components/UI/Template/Template';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import config from 'config/api';
import i18n from 'helpers/i18n';
// import getCardImage from 'helpers/getCardImage';
import { formatDate } from 'helpers/stringUtils';
import { toHHMM } from 'components/UI/helpers/time';
import Button from '@material-ui/core/Button';
import Spinner from 'components/UI/Spinner/Spinner';
import { fetchData, getDataHome, getSubscriptions } from 'ducks/asyncFetcher';
import { fetchEvent, getEventData, cleanup, subscribeToEvent } from 'components/UI/Event/ducks/event';
import queryString from 'query-string';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import isMember from 'components/UI/helpers/isMember';
// import { Link } from 'react-router-dom';
import RegistrationTwoStepsForm from 'components/UI/Registration/RegistrationTwoStepsForm';
import {
  getCurrentUser,
} from 'components/UI/Account/ducks/authentication';
import RegistrationMessage from 'components/UI/Registration/RegistrationMessage'


const styles = (theme) => {
  return {
    root: {
      width: '100%',
      position: 'relative',
      minHeight: 450,
      '& .MuiTypography-h3': {
        paddingRight: 4,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.1rem'
      },
      '& .MuiButton-root.MuiButton-containedPrimary': {
        fontWeight: 700
      },
      '& .MuiTypography-h1': {
        marginBottom: theme.spacing(2),
      }
    },
    content: {
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    header: {
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '70%',
        backgroundColor: theme.palette.primary.light,
        zIndex: '-1'
      }
    },
    inline: {
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    titleIcon: {
      width: 115,
      height: 115,
      float: 'left',
      marginRight: theme.spacing(2),
      backgroundSize: 'contain',
    },
    breadCrumbs: {
      fontSize: '0.8125rem',
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(.5),
      '& a': {
        color: theme.palette.primary.main,
        textDecoration:'underline'
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      },

    },
    itemWrapper: {
      border: '1px #1D202126 solid',
      margin: theme.spacing(3),
      padding: theme.spacing(3),
      height: '100%',
    },
    badge: {
      top: '50%',
      right: '-16px'
    },
    itemThumbnail: {
      width: '100%',
      height: 200
    },
    itemTitle: {
      marginBottom: theme.spacing(1),
      color: theme.palette.registration && theme.palette.registration.titleColor ? theme.palette.registration.titleColor : theme.palette.primary.dark,
      fontWeight: 'bold',
      paddingLeft: 130
    },
    itemDescription: {
      marginBottom: theme.spacing(2),
    },
    itemDate: {
      marginBottom: 0,
    },
    separator: {
      borderTop: '1px #1D202126 solid',
      width: '100%',
      height: 1,
      marginBottom: theme.spacing(3),
    },
    dateSelector: {
      display: 'block',
      width: '100%',
      maxWidth: 280,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    left: {
      backgroundColor: '#fff',
      border: '1px #ccc solid',
      padding: theme.spacing(4),
      position: 'relative',
      '& .MuiTypography-h4': {
        // color: '#fff'
      },
      '& .MuiTypography-body1': {
        // color: '#fff'
      }
    },
    formWrapper: {
      marginTop: theme.spacing(4),
    }
  };
};


class EventRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: [],
      registrationComplete: false,
      registrationResult:{},
    }
  }
  componentDidMount() {
    const { eventMatchId } = this.props;
    const uri = queryString.parse(window.location.search);
    const eventQueryId = uri.q ? uri.q.split(',') : undefined;
    const eventIds = eventMatchId !== undefined ? eventMatchId : eventQueryId
    this.props.onMount({eventIds})
  }
  componentDidUpdate(oldProps) {
    // const { $$user } = this.props;
    if ((oldProps.$$item === undefined && this.props.$$item !== undefined) || (oldProps.$$item !== undefined && this.props.$$item !==  undefined && oldProps.$$item.get('id') !== this.props.$$item.get('id'))) {
      this.setState({selectedDate: this.props.$$item.get('id')})
    }
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }

  onRegistrationComplete = (r) => {
    console.log('registration complete.', r)
    this.setState({
      registrationComplete: r.$$event ? r.$$event : r.$$events,
      //MODIF B
      //isVerified: r.isVerified,
      registrationResult:r.registrationResult,
    })
  }
  render() {
    const { classes, $$item, $$user, templateProps, availableEventCategories } = this.props;
    const {registrationComplete, registrationResult} = this.state;
    console.log({registrationComplete});
    // console.log({registrationComplete, $$item, $$subscriptions})
    // const thumb = getCardImage($$item);

    const $$eventItem = $$item;
    const uri = queryString.parse(window.location.search);
    const $$eventItems = uri.q ? uri.q.split(',') : undefined
    // console.log('isMember', isMember($$user), $$user)
    return (
      <Template {...templateProps}>
        <Grid
          container
          justifyContent="flex-start"
          className={classes.root}
          >
          <Choose>
            <When condition={$$item !== undefined}>
              <Grid item xs={12} container alignContent="flex-start" className={classes.header}>
                <Grid item xs={12}><Typography variant="h1">{i18n.get('subscriptionTitle', {pretty: false})}</Typography></Grid>
                {/*              <Grid item xs={12} className={classes.breadCrumbs}>
                <Typography variant="subtitle1"><Link to="/">{i18n.get('home', {pretty: false})}</Link>&nbsp;&gt;&nbsp;  <Link to={'/event/'+$$item.get('id')}>{$$item.get('themeName')}</Link>&nbsp;&gt;&nbsp;{formatDate($$item.get('eventDate'), "dddd dd mmmm yyyy - HH'h'MM")}</Typography>
                </Grid>*/}
                <Grid item xs={12} sm={12} container justifyContent={'center'} alignContent="center"  className={classes.content}>
                  <Box className={classes.left}>
                    <div className={classes.titleIcon}></div>
                    <Typography variant="h4" component="div" gutterBottom className={classes.itemTitle}>
                      <Paragrapher
                        text={$$item.get('eventTitle')}
                        elementClassName={classes.content}
                        prettyfy={true}
                        element={'div'}
                        />
                    </Typography>
                    <Typography variant="body1" component="div" gutterBottom className={classes.itemDate}>
                      <DateRangeIcon /> {formatDate($$item.get('eventDate'), "dddd dd mmmm yyyy - HH'h'MM")}
                        <If condition={$$item.get('eventDuration') && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== '' && $$item.get('eventDuration') > 0}>
                          &nbsp;&nbsp;|&nbsp;&nbsp;<WatchLaterIcon/> {toHHMM($$item.get('eventDuration')*60)}
                        </If>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <If condition={registrationComplete === false}>
                  <Grid item xs={12} className={classes.formWrapper}>
                    <RegistrationTwoStepsForm
                      onFormComplete={this.onRegistrationComplete}
                      step={1}
                      scrollOnMount={false}
                      availableEventCategories={availableEventCategories}
                      $$event={$$eventItems === undefined ? $$eventItem : undefined}
                      $$events={$$eventItems !== undefined ? $$eventItems : undefined}
                      />
                  </Grid>
                </If>
                <If condition={registrationComplete !== false}>
                  <Grid item xs={12} className={classes.formWrapper}>
                    <Box align="center" my={4}>
                      <RegistrationMessage registrationResult={registrationResult} isMember={isMember($$user)}/>
                      <Box mt={4}>
                        <Button variant="contained" color="primary" onClick={(e) => {e.preventDefault(); this.props.history.push('/')}}>{i18n.get('navHome')}</Button>
                      </Box>
                    </Box>
                  </Grid>
                </If>
              </When>
              <Otherwise>
                <Spinner>{i18n.get('loading')}</Spinner>
              </Otherwise>
            </Choose>

          </Grid>
        </Template>
      );
    }
  }
  function mapStateToProps(state) {
    const props = {
      $$data: getDataHome(state),
      $$item: getEventData(state),
      $$subscriptions: getSubscriptions(state),
      $$user: getCurrentUser(state),
    };
    return props;
  }
  function mapDispatchToProps(dispatch) {
    return {
      onMount: ({eventIds}) => {
        const uri = queryString.parse(window.location.search);
        dispatch(
          fetchData({
            key: 'home',
            fetchUrl: config.home,
          })
        );
        // console.log('PARAMS ARE', uri)
        dispatch(
          fetchEvent({
            eventIds,
            params: {k: uri.k, r: uri.r}
          })
        );
      },
      onSubsribe: ({$$events, action, inviteEmail}) => {
        dispatch(subscribeToEvent({$$events, action, inviteEmail}))
      },
      onUnmount: () => {
        dispatch(cleanup())
      }
    };

  }

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventRegistration)));
