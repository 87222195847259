import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Error from 'components/UI/Error';
import Loading from 'components/UI/Loading';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';

import apiFetch from 'components/UI/helpers/fetch';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      margin: '10px 0',
      padding: '10px 18px',
      backgroundColor: theme.palette.share.buttonBG,
      color: theme.palette.share.buttonFG,
    },

    dialog: {
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.share.pageBG,
        borderRadius: 14,
        padding: 0,
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: 'black',
      zIndex: 1,
    },
    title: {
      borderBottom: theme.palette.share.title + ' 1px solid',
      backgroundColor: 'aliceblue',
      '& .MuiTypography-h2': {
        margin: 0,
        fontSize: '16pt',
        textAlign: 'left',
        color: theme.palette.share.title,
      },
    },
    content: {
      margin: '30px 40px',
    },
    text: {
      marginBottom: '18px',
    },
    textField: {
      minWidth: '400px',
    },
    success: {
      color: 'green',
      marginTop: '10px',
    },
    actions: {
      marginTop: '20px',
    },
  };
});

export default function Share({ module, id, url, title }) {
  const classes = useStyles();

  const [opened, setOpened] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [result, setResult] = React.useState(null);
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(false);
    setResult(false);
  }, [module, id]);

  const Url = url !== undefined ? url : window.location.href;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onClose = (event) => {
    setOpened(false);
  };

  const send = (value) => {
    sendData({
      module: module,
      id: id,
      url: Url,
      title: title,
      emails: value,
    });
  };

  const sendData = (params) => {
    setLoading(true);
    apiFetch('/share/send', params)
      .then((data) => {
        console.log('fetchData ok', data);
        setResult(data);
        setError(null);
        setLoading(false);
        setValue('');
      })
      .catch(({ response, error, msg }) => {
        console.log('fetchData error', error, response);
        setResult(false);
        setError({ error: error, msg: msg });
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Button variant="contained" className={clsx(classes.button)} startIcon={<ShareOutlinedIcon />} onClick={(e) => setOpened(true)}>
        Je partage
      </Button>

      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={opened} maxWidth="sm" className={clsx(classes.dialog)}>
        <MuiDialogTitle disableTypography className={classes.title}>
          <Typography variant="h2">
            <ShareOutlinedIcon /> Je partage
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Grid item xs={12} container justify="flex-start" alignContent="flex-start">
          <Paper className={classes.content} elevation={0} element="div">
            <Grid container style={{ height: '100%' }} alignContent="flex-start">
              <Grid item xs={12} container alignContent="center" style={{ position: 'relative' }}>
                <div className={classes.text}>
                  Partagez ce contenu en indiquant une ou plusieurs adresses e-mails dans le champ ci-dessous.
                  <br />
                  Veuillez noter que seules les adresses e-mails seront prises en compte, tout texte supplémentaire sera ignoré.
                </div>
                <form className={classes.form} noValidate autoComplete="off">
                  <div>
                    <TextField id="outlined-multiline-static" label="" multiline rows={3} variant="outlined" value={value} onChange={handleChange} className={classes.textField} />
                  </div>
                  <div>
                    <If condition={error}>
                      <Error result={error} />
                    </If>
                    <If condition={result}>
                      <Box className={classes.success}>
                        Partage réalisé vers :<br />
                        <ul>
                          {result.emails.map((email) => (
                            <li key={email}>{email}</li>
                          ))}
                        </ul>
                      </Box>
                    </If>
                  </div>
                  <div className={classes.actions}>
                    <Button disabled={loading} variant="contained" className={clsx(classes.button)} endIcon={<MailOutlineOutlinedIcon />} onClick={(e) => send(value)}>
                      Envoyer
                    </Button>
                    <If condition={loading}>
                      <Loading size={20} inline={true} text={false} />
                    </If>
                  </div>
                </form>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
