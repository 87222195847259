import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import queryString from 'query-string';
import config from 'config/api';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Spinner from 'components/UI/Spinner';
import Template from 'components/UI/Template/Template';
import { withRouter } from 'react-router-dom';
import { fetchOffer, getOfferData, fetchOfferSelects, getOfferSelectsData } from './ducks/offer';

import { getCurrentUser, setUniverse } from 'components/UI/Account/ducks/authentication';

import OfferProtection from './OfferProtection';
import OfferContent from './OfferContent';
import OfferUniverse from './OfferUniverse';
import PageWithHeader from 'components/UI/PageWithHeader';

const styles = (theme) => {
  return {
    root: {
      '& .MuiTypography-subtitle1': {
        textAlign: 'left',
      },
      '& .MuiSvgIcon-root': {
        verticalAlign: 'middle',
        marginTop: -2,
      },
    },
    paperWrapper: {
      backgroundColor: '#F2F2F2',
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.offer.light,
        zIndex: -1,
      },
    },
    paper: {
      backgroundColor: 'transparent',
      padding: theme.spacing(4),
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0.5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.3),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.1),
      },
      '& a': {
        color: 'inherit',
      },
    },
    inline: {
      display: 'inline-block',
    },

    breadCrumbs: {
      marginTop: theme.spacing(3),
      fontSize: '0.8125rem',
      color:
        theme.palette.medias && theme.palette.medias.breadCrumbColor
          ? theme.palette.medias.breadCrumbColor
          : theme.palette.primary.main,
      '& a': {
        color:
          theme.palette.medias && theme.palette.medias.breadCrumbColor
            ? theme.palette.medias.breadCrumbColor
            : theme.palette.primary.main,
        textDecoration: 'underline',
        display: 'inline-block',
        verticalAlign: 'middle',
      },
    },

    itemVideo: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    itemDescription: {
      marginBottom: theme.spacing(2),
    },
    itemDuration: {
      marginBottom: theme.spacing(2),
    },
    itemCopyright: {
      color: theme.palette.primary.main,
    },
    itemGuests: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    navWrapper: {
      position: 'relative',
      height: 60,
    },
    navButton: {
      position: 'absolute',
      bottom: 0,
    },
    navPrevious: {
      left: 0,
    },
    navNext: {
      right: 0,
    },
    itemTitle: undefined,
    itemTheme: undefined,
    ListItemText: {
      paddingLeft: theme.spacing(4),
    },
  };
};
class OfferDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { logMessage: false };
  }
  componentDidMount() {
    const offerId = this.props.match.params.id;
    const uri = queryString.parse(window.location.search);
    this.props.onMount({ offerId, params: uri });
    if (this.props.match && this.props.match.params.universe)
      this.props.setUniverse(this.props.match.params.universe);
  }
  componentDidUpdate(oldProps) {
    if (oldProps.match.params.id !== this.props.match.params.id) {
      const offerId = this.props.match.params.id;
      const uri = queryString.parse(window.location.search);
      this.props.onMount({ offerId, params: uri });
    }
  }

  render() {
    const { classes, $$offer, $$user, $$selects } = this.props;
    const isOwner =
      $$offer &&
      $$user &&
      $$offer.get('offerAccountEncoded') != '' &&
      $$offer.get('offerAccountEncoded') == $$user.get('id');
    const isPreview = $$offer && isOwner && $$offer.get('offerStatus') == 'dra';

    return (
      <Template current="home">
        <OfferUniverse />
        {isPreview && (
          <PageWithHeader title="Votre annonce">
            <OfferContent $$item={$$offer} $$user={$$user} $$selects={$$selects} breadcrumb={false} />
          </PageWithHeader>
        )}
        {!isPreview && (
          <Box className={classes.paperWrapper} elevation={3}>
            <Paper square elevation={0} className={clsx(classes.root, classes.paper)}>
              <OfferProtection>
                <If condition={$$offer !== undefined}>
                  <OfferContent $$item={$$offer} $$user={$$user} $$selects={$$selects} />
                  <Else />
                  <Box my={8} align="center">
                    <Typography
                      variant="h5"
                      gutterBottom={true}
                      className={clsx(classes.legend, classes.h6extends)}
                    >
                      Annonce non trouvée
                    </Typography>
                    <Box mt={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          window.location.replace('/');
                        }}
                      >
                        Cliquez pour retourner à l'accueil
                      </Button>
                    </Box>
                  </Box>
                </If>
              </OfferProtection>
            </Paper>
          </Box>
        )}
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$user: getCurrentUser(state),
    $$offer: getOfferData(state),
    $$selects: getOfferSelectsData(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      dispatch(fetchOffer(props));
      dispatch(fetchOfferSelects());
    },
    setUniverse: (universe) => {
      dispatch(setUniverse(universe));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OfferDetails)));
