import { connect } from "react-redux";
import React from "react";
import DynamicList from "./DynamicList";
import { makeSelectors, makeActionCreators } from "./ducks/dynamicList";
import { defaultMemoize as memoize } from "reselect";
import withStyles from "helpers/withStyles";
const mapStateToProps = () => {
  const getSelectors = memoize(makeSelectors);
  return (state, ownProps) => {
    const selectors = getSelectors(ownProps.listId);
    return {
      loaded: selectors.getIsDataLoaded(state),
      $$fields: selectors.getFields(state),
      $$rows: selectors.getFilteredRows(state),
      $$actions: selectors.getActions(state),
      $$filters: selectors.getFilters(state),
      $$apparence: selectors.getApparence(state),
      // $$toDelete: selectors.getToDelete(state),
      readOnly: selectors.isReadOnly(state),
      $$linkMap: selectors.getLinkMap(state),
      currentPage: selectors.getCurrentPage(state),
      pageCount: selectors.getPageCount(state),
    };
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  const actionCreators = makeActionCreators(ownProps.listId);
  return {
    onMount: (moduleConfig) => dispatch(actionCreators.initList(moduleConfig)),
    onUnmount: () => dispatch(actionCreators.removeList()),
    // onTextFilterChange: (event, newValue) => dispatch(actionCreators.setTextFilter(newValue)),
    onDeleteRow: (moduleConfig, id) =>
      dispatch(actionCreators.deleteRow(moduleConfig, id)),
    onSetRowFieldData: (rowId, fieldId, value) =>
      dispatch(actionCreators.setRowFieldValue(rowId, fieldId, value)),
    onSetRowApparence: (rowId, value) =>
      dispatch(actionCreators.setRowApparence(rowId, value)),
    onOrderChange: (orderBy, orderWay) =>
      dispatch(
        actionCreators.changeOrder(ownProps.moduleConfig, orderBy, orderWay)
      ),
    onFilterChange: (filterBy, filterValue) =>
    {console.log(filterBy, filterValue)
      dispatch(
        actionCreators.changeFilter(
          ownProps.moduleConfig,
          filterBy,
          filterValue
        )
      )
    }
  };
}

const Container = withStyles(
  connect(mapStateToProps, mapDispatchToProps)(DynamicList)
);

let nextId = 0;
export default class DynamicListWithDynamicId extends React.Component {
  constructor(props) {
    super(props);
    this.listId = nextId;
    nextId++;
  }

  render() {
    const listId = `list-${this.listId}`;
    const { props } = this;

    return <Container {...props} {...{ listId }} />;
  }
}
