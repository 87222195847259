import config from "config/api";
import queryString from 'query-string';

export default function apiFetch (url, post) {
  return new Promise((resolve, reject)=>{
  if(config.baseUrl===undefined)
    console.error("apiFetch missing config.baseUrl");
  fetch(config.baseUrl + '/api'+url, {
    credentials: 'include',
    method: 'post',
    headers: {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest', // needed by php api
    },
    body: queryString.stringify(post),
  })
    .then((response) => response.json())
    .then((response) => {
      if(response.result!==undefined && response.result!='ok'){
        console.log('apiFetch result error');
        reject({
          response:response,
          error:'error' in response ? response.error : null,
          msg:'msg' in response ? response.msg : null,
        });
      }else if(response.result=='ok' || response.data!==undefined){
        console.log('apiFetch success', response);
        resolve(response.data);
      }else{
        console.log('apiFetch no response.data', response);
        reject(response, null);
      }
    })
    .catch((response) => {
      console.log('apiFetch error', response);
      reject({
        response:response,
        error:'error' in response ? response.error : null,
        msg:'msg' in response ? response.msg : null,
      });
  });
  });
}