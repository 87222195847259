import React, { Component } from 'react';
import config from 'config/api';
import DynamicForm  from 'components/UI/DynamicForm'

class MediaForm extends Component {
  constructor(props) {
    super(props);
    this._onFormComplete = this._onFormComplete.bind(this);
  }

  _getI18n = () => {
    this.i18n = {
      send: 'Enregistrer',
      sending: 'Enregistrement...'
    }
  }
  _onFormComplete = () => {
    const { onClose, onFormComplete } = this.props;
    if (onClose) onClose();
    else if (onFormComplete) onFormComplete();
  }
  _onJobChanged = (fid, value) => {
    switch(value) {
      case 'intern': this.setState({activeCategories: ['isIntern']}); break;
      case 'md': case 'ph': this.setState({activeCategories: ['isMd']}); break;
      default:break;
    }
  }
  render() {
    const {
      id,
    } = this.props;
    const moduleConfig = {
      module: 'media',
      fetchUrl: config.medias+'/edit',
      postUrl: config.medias+'/edit',
      formTitle: this.props.id !== undefined ? 'Edition' : 'Nouveau Média',
      redirectAfterEdit: false ,
      afterEdit: 'onFormComplete',
      afterEditLog: '',
      afterEditHideForm: true,

    }
    return (
      <DynamicForm moduleConfig={moduleConfig} id={id} onFormComplete={this._onFormComplete} {...this.props} />
    );

  }

}


export default MediaForm;
