import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import config from 'config/api';
import getFetchOptions from 'helpers/getFetchOptions';
import DynamicForm from 'components/UI/DynamicForm';
import DialogConfirm from 'components/UI/Dialog/DialogConfirm';
import { withStyles } from '@material-ui/core/styles';
import { getToken } from 'components/UI/Account/ducks/authentication';

import { offerAllowedView, offerAllowedPost } from './ducks/offer';

import OfferNotConnected from './OfferNotConnected';
import OfferReserved from './OfferReserved';

const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        borderRadius: '30px',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '30px',
      },
      '& .MuiOutlinedInput-input': {
        borderRadius: '20px',
      },
      '& .MuiGrid-justify-content-xs-space-around': {
        justifyContent: 'left',
      },
      '& .MuiListItem-button': {
        display: 'inline-block',
        marginRight: '2em',
        borderRadius: '30px',
      },
      '& .MuiTypography-h6': {
        color: '#033B4C',
        fontSize: '115%',
      },
      '& .activateOfferButton': {
        backgroundColor: '#F88D5E !important',
        borderColor: '#F88D5E !important',
        marginRight: '1em',
        '&:hover': {
          backgroundColor: 'white !important',
          borderColor: '#F88D5E !important',
          color: '#F88D5E !important',
        },
      },
    },
  };
};

class OfferAdminForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      dialogOpen: false,
      dialogName: undefined,
      dialogTitle: undefined,
      dialogText: undefined,
      log: undefined,
      last_id: null,
    };
  }

  _getI18n() {
    this.i18n = {
      send: 'Enregistrer',
      sending: 'Enregistrement...',
    };
  }
  _onConfirmed = (name, props) => {
    if (this['_do' + name]) {
      this['_do' + name](props);
    }
  };
  _onCanceled = () => {
    this.setState({
      dialogOpen: false,
      dialogName: undefined,
      dialogTitle: undefined,
      dialogText: undefined,
    });
  };

  changeOfferStatus = async (props, urlAction) => {
    const url = config.offers + '/' + urlAction;
    let data = { id: this.props.id };
    data.csrf = await getToken();
    fetch(url, getFetchOptions('POST', data))
      .then((response) => response.json())
      .then((response) => {
        if (response.toggleStatus && response.toggleStatus === 'complete') {
          this.setState({ redirect: 'list', locked: false, dialogOpen: false });
        } else if (response.status && response.status === 'error') {
          //this.setState({ activationError: response.log, locked: false, dialogOpen: false });
        }
      });
  };

  _onActivateOffer = (a, b, $$values) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'Souhaitez-vous activer cette annonce ?',
      dialogText: '',
      dialogName: 'ActivateOffer',
      onConfirmProps: $$values,
    });
  };

  _doActivateOffer = async (props) => {
    this.changeOfferStatus(props, 'activate');
  };

  _onDeactivateOffer = (a, b, $$values) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'Souhaitez-vous désactiver cette annonce ?',
      dialogText: '',
      dialogName: 'DeactivateOffer',
      onConfirmProps: $$values,
    });
  };
  _doDeactivateOffer = async (props) => {
    this.changeOfferStatus(props, 'deactivate');
  };

  _onRefuseOffer = (a, b, $$values) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'Souhaitez-vous refuser cette annonce ?',
      dialogText: '',
      dialogName: 'RefuseOffer',
      onConfirmProps: $$values,
    });
  };
  _doRefuseOffer = async (props) => {
    this.changeOfferStatus(props, 'refuse');
  };

  _onTokenSet = (token) => {
    this.setState({ token });
  };

  _onFormComplete = (responseData) => {
    console.log('responseData', responseData);
    const last_id = 'return' in responseData ? responseData['return'] : null;
    console.log('last_id', last_id);
    this.setState({ redirect: 'detail', last_id: last_id });
  };

  handleClose = () => {
    this.setState({ redirect: 'list' });
  };

  render() {
    const { id, admin, history, classes, offerAllowedView, offerAllowedPost } = this.props;
    const isEdit = id !== undefined;
    const moduleConfig = {
      module: 'offer',
      fetchUrl: config.offers + '/edit',
      postUrl: config.offers + '/edit',

      formTitle: false,
      redirectAfterEdit: false,
      afterEdit: 'onFormComplete',
      buttonVariant: 'contained',
      bottomActionsEvents: {
        onPreviewOffer: this._onPreviewOffer,
        onActivateOffer: this._onActivateOffer,
        onDeactivateOffer: this._onDeactivateOffer,
        onRefuseOffer: this._onRefuseOffer,
        onBlockOffer: this._onBlockOffer,
      },
    };

    const { dialogOpen, dialogName, dialogTitle, dialogText, onConfirmProps, activationError } = this.state;
    if (this.state.redirect) {
      if (this.state.redirect == 'list') history.push(this.props.admin ? '/staff/offers/list' : '/offers');
      if (this.state.redirect == 'detail')
        history.push(this.props.admin ? '/staff/offers/list' : '/offers/' + this.state.last_id);
      return <div>Redirection...</div>;
    }

    return (
      <div className={classes.root}>
        <Choose>
          <When condition={offerAllowedView === undefined}>
            <OfferNotConnected />
          </When>
          <When condition={offerAllowedPost === true || (isEdit && offerAllowedView)}>
            <If condition={dialogOpen}>
              <DialogConfirm
                open={true}
                onConfirmed={this._onConfirmed}
                onCanceled={this._onCanceled}
                name={dialogName}
                title={dialogTitle}
                text={dialogText}
                textStyle={{ textAlign: 'center' }}
                onConfirmProps={onConfirmProps}
              />
            </If>
            <DynamicForm
              moduleConfig={moduleConfig}
              id={id}
              otherError={activationError}
              onFormComplete={this._onFormComplete}
              onClose={this.handleClose}
              {...this.props}
              onTokenSet={this._onTokenSet}
              f_offerName="Test 1"
            />
          </When>
          <Otherwise>
            <OfferReserved />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

const mapStateToProps = ($$state) => {
  const props = {
    offerAllowedView: offerAllowedView($$state),
    offerAllowedPost: offerAllowedPost($$state),
  };
  return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(OfferAdminForm)));
