import React from 'react';
import IconView from '@material-ui/icons/Search';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
import IconDeleteForever from '@material-ui/icons/DeleteForever';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

// import IconGroup from 'material-ui/svg-icons/social/group';
import IconAdd from '@material-ui/icons/Add';
// import IconReport from 'material-ui/svg-icons/content/report';
// import IconSend from 'material-ui/svg-icons/content/send';
// import IconMovieFilter from 'material-ui/svg-icons/image/movie-filter';
import IconAlarm from '@material-ui/icons/Alarm';
import IconDetails from '@material-ui/icons/Details';
// import IconAssignment from 'material-ui/svg-icons/action/assignment';
// import IconAssignmentTurnedIn from 'material-ui/svg-icons/action/assignment-turned-in';
// import IconDescription from 'material-ui/svg-icons/action/description';
// import IconFolderShared from 'material-ui/svg-icons/file/folder-shared';
// import IconReceipt from 'material-ui/svg-icons/action/receipt';
// import IconAddShoppingCart from 'material-ui/svg-icons/action/add-shopping-cart';
// import IconContentPaste from 'material-ui/svg-icons/content/content-paste';
// import IconArchive from 'material-ui/svg-icons/content/archive';
// import IconUnarchive from 'material-ui/svg-icons/content/unarchive';
import IconFile from '@material-ui/icons/InsertDriveFile';
import IconDone from '@material-ui/icons/Done';
import IconAlert from '@material-ui/icons/ErrorOutline';
// import IconMoney from 'material-ui/svg-icons/editor/attach-money';
// import IconPhoneCall from 'material-ui/svg-icons/action/perm-phone-msg';
// import IconAssessment from 'material-ui/svg-icons/action/assessment';

const Icon = ({ icon, button, tooltip, onTouchTap, style, className }) => {
  let iconComponent,
    wrapButton = false;
  wrapButton = button ? true : false;
  switch (icon) {
    case 'delete':
      iconComponent = <IconDelete style={style} />;
      break;
    case 'delete_forever':
      iconComponent = <IconDeleteForever style={style} />;
      break;
    case 'edit':
      iconComponent = <IconEdit style={style} />;
      break;
    case 'details':
      iconComponent = <IconDetails style={style} />;
      break;
    case 'subscribers':
      iconComponent = <IconDetails style={style} />;
      break;

    case 'view':
      iconComponent = <IconView style={style} />;
      break;
    case 'group':
      iconComponent = <IconGroup style={style} />;
      break;
    case 'add':
      iconComponent = <IconAdd style={style} />;
      break;
    case 'preview':
      iconComponent = <VisibilityIcon style={style} />;
      break;
    case 'report':
      iconComponent = <IconReport style={style} />;
      break;
    case 'send':
      iconComponent = <IconSend style={style} />;
      break;
    case 'movie_filter':
      iconComponent = <IconMovieFilter style={style} />;
      break;
    case 'alarm':
    case 'interviews':
      iconComponent = <IconAlarm style={style} />;
      break;
    case 'assignment':
      iconComponent = <IconAssignment style={style} />;
      break;
    case 'assignment_turned_in':
    case 'contracts':
      iconComponent = <IconAssignmentTurnedIn style={style} />;
      break;
    case 'description':
    case 'billing':
      iconComponent = <IconDescription style={style} />;
      break;
    case 'folder_shared':
      iconComponent = <IconFolderShared style={style} />;
      break;
    case 'receipt':
      iconComponent = <IconReceipt style={style} />;
      break;
    case 'add_shopping_cart':
      iconComponent = <IconAddShoppingCart style={style} />;
      break;
    case 'content_paste':
      iconComponent = <IconContentPaste style={style} />;
      break;
    case 'archive':
      iconComponent = <IconArchive style={style} />;
      break;
    case 'unarchive':
      iconComponent = <IconUnarchive style={style} />;
      break;
    case 'file':
      iconComponent = <IconFile style={style} />;
      break;
    case 'done':
      iconComponent = <IconDone style={style} />;
      break;
    case 'alert':
      iconComponent = <IconAlert style={style} />;
      break;
    case 'expenses':
      iconComponent = <IconMoney style={style} />;
      break;
    case 'phone_call':
      iconComponent = <IconPhoneCall style={style} />;
      break;
    case 'assessment':
      iconComponent = <IconAssessment style={style} />;
      break;
    default:
      iconComponent = (
        <Avatar size={24} style={style}>
          {icon}
        </Avatar>
      );
      break;
  }
  if (wrapButton)
    return (
      <IconButton
        tooltip={tooltip !== undefined && tooltip !== '' ? tooltip : null}
        onClick={onTouchTap !== undefined ? onTouchTap : undefined}
        className={className}
      >
        {iconComponent}
      </IconButton>
    );
  else return iconComponent;
};

export default Icon;
