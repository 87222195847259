import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';
import { getToken } from 'components/UI/Account/ducks/authentication';
import { getUniverse } from 'components/UI/Account/ducks/authentication'

const actionTypes = mirrorCreator(['SET_SUBSCRIPTION_STATE', 'SET_EVENT_DATA','SET_EVENT_PREVIEW', 'SET_EVENTS_DATA', 'SET_EVENT_ERROR', 'CLEAR_EVENT', 'CLEAR_EVENT_PREVIEW', 'CLEAR_EVENTS'], { prefix: 'event/' });

const mutators = {
  setEventData: (event) => ($$state) => $$state.set('event', event),
  setEventPreview: (event) => ($$state) => $$state.set('preview', event),
  setEventError: (error) => ($$state) => $$state.set('error', error),
  // setSubscriptionState: (id, state) => ($$state) => $$state.setIn(['subscribedDates', id], state),
  setSubscriptionState: (state) => ($$state) => $$state.set('subscriptionState', state),
  setEventsData: (kind, data) => ($$state) => $$state.setIn(['events', kind], data),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_SUBSCRIPTION_STATE:
      acts.push(mutators.setSubscriptionState(action.state));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_EVENTS_DATA:
      acts.push(mutators.setEventsData(action.kind, action.events));
      // acts.push(mutators.setSubscriptionState(false))
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_EVENT_DATA:
      acts.push(mutators.setEventData(action.event));
      // acts.push(mutators.setSubscriptionState(false))
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_EVENT_PREVIEW:
      acts.push(mutators.setEventPreview(action.event));
      // acts.push(mutators.setSubscriptionState(false))
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_EVENT_ERROR:
      return pipe([mutators.setEventError(action.error)], $$state);

    case actionTypes.CLEAR_EVENT:
      return pipe([mutators.setEventError(undefined), mutators.setEventData(undefined)], $$state);

    case actionTypes.CLEAR_EVENT_PREVIEW:
      return pipe([mutators.setEventError(undefined), mutators.setEventData(undefined)], $$state);

    case actionTypes.CLEAR_EVENTS:
      return pipe([mutators.setEventError(undefined), mutators.setEventsData(action.kind, undefined)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.wevent || Immutable.Map();
export const getEventData = createSelector([getRoot], ($$state) => $$state.get('event'));
export const getEventPreview = createSelector([getRoot], ($$state) => $$state.get('preview'));
export const getEventsData = createSelector([getRoot], ($$state) => $$state.getIn(['events', 'events']));
export const getReplaysData = createSelector([getRoot], ($$state) => $$state.getIn(['events', 'replays']));
export const getEventsKindData = createSelector([getRoot, (state, kind) => kind], ($$state, kind) => $$state.getIn(['events', kind]));
export const getEventsCounts = createSelector([getRoot, (state, kind) => kind], ($$state, kind) =>{
  const res={};
  const $$events=$$state.getIn(['events']);
  if($$events!==undefined){
    $$events.keySeq().forEach(k=>{
      res[k]=$$events.get(k)!==undefined ? $$events.get(k).size : 0;
    });
  }
  return Immutable.fromJS(res);
}  
);

export const getEventError = createSelector([getRoot], ($$state) => $$state.get('error'));
// export const getSubscriptionState = createSelector([getRoot], ($$state) => $$state.get('subscribedDates', {}));
export const getSubscriptionState = createSelector([getRoot], ($$state) => $$state.get('subscriptionState', false));

export function fetchEvent({ eventId, eventIds, params = {} }) {
  // console.log({login, password})
  return (dispatch) => {
    let url;
    if (eventIds !== undefined)
      url = config.events + '/get/'+ eventIds[0];
    else
      url = config.events + '/get/'+ eventId;

    if (params.k !== undefined && params.r === undefined) url = url+'?k='+params.k;
    if (params.r !== undefined && params.k === undefined) url = url+'?r='+params.r;
    if (params.r !== undefined && params.k !== undefined) url = url+'?k='+params.k+'&r='+params.r;
    let formData = {};
    formData.contact_form = true;

    dispatch(clearEvent());

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.event && response.event === 'complete') {
          dispatch(setEventData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setEventError(response.log));
        }
      })
      .catch((error) => dispatch(setEventError(error.message)));
  };
}
export function fetchEventPreview({ eventId, eventIds, params = {} }) {
  // console.log({login, password})
  return (dispatch) => {
    const url = config.events + '/preview/'+ eventId;

    let formData = {};
    formData.contact_form = true;

    dispatch(clearEventPreview());

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.event && response.event === 'complete') {
          dispatch(setEventPreview(Immutable.fromJS(response.data)));
        } else {
          dispatch(setEventError(response.log));
        }
      })
      .catch((error) => dispatch(setEventError(error.message)));
  };
}
export function fetchEvents({ kind, params }) {
  // console.log({login, password})
  return (dispatch, getState) => {
    let url = config.events + '/get/'+ kind || 'all';

    const universe = getUniverse(getState());
    if (universe !== undefined) url += '?universe='+universe

    let formData = {};

    //MODIF B : déplacement ds erreurs uniquement 
    //dispatch(setEventsData(kind, undefined));

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.events && response.events === 'complete') {
          dispatch(setEventsData(kind, Immutable.fromJS(response.data)));
        } else {
          dispatch(setEventsData(kind, undefined));
          dispatch(setEventError(response.log));
        }
      })
      .catch((error) =>{
        dispatch(setEventError(error.message))
      })
  };
}

export function subscribeToEvent({$$events, action, inviteEmail}) {
  return async (dispatch) => {
    console.log("subscribeToEvent");
    console.trace();
    let url;
    if (action === 'unsubscribe') url = config.eventUnsubscribe;
    else url = config.eventSubscribe;
    var formData = new FormData();
    // const $$events = onSubscriptionRequest();
    // console.log($$events, $$event)
    // const formEvents = $$events !== undefined ? $$events.map(f => f.get('id')).toArray() :
    // Immutable.fromJS([]);
    // console.log({
    //   $$events,
    //   test: $$events.toJS(),
    // });
    if ($$events !== undefined && $$events.size === 1) formData.append('rid', $$events.get(0));
    else if ($$events !== undefined && $$events.size > 0) formData.append('rids', JSON.stringify($$events));
    else return;

    if (inviteEmail !== undefined)
      formData.append('remail', inviteEmail);
    const token = await getToken();
    formData.append('csrf', token);

    dispatch(setSubscritionState('pending'));

    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (
          response.registration &&
          (response.registration === 'complete' || response.registration === 'already')
        ) {
          console.log('subscription complete.')
          dispatch(setSubscritionState('complete'));
        } else {
          console.log('subscription error : '+response.log)
          dispatch(setEventError(response.log));
        }
      });
    }
}
export function cleanup() {
  return (dispatch) => {
    dispatch(clearEvent());
    dispatch(clearSubscritionState());
  }
}

export function setEventsData(kind, events) {
  return {
    type: actionTypes.SET_EVENTS_DATA,
    kind, events,
  };
}
export function setEventData(event) {
  return {
    type: actionTypes.SET_EVENT_DATA,
    event,
  };
}
export function setEventPreview(event) {
  return {
    type: actionTypes.SET_EVENT_PREVIEW,
    event,
  };
}
export function setEventError(error) {
  return {
    type: actionTypes.SET_EVENT_ERROR,
    error,
  };
}
export function clearEvent() {
  return {
    type: actionTypes.CLEAR_EVENT,
  };
}
export function clearEventPreview() {
  return {
    type: actionTypes.CLEAR_EVENT_PREVIEW,
  };
}
export function clearEvents(kind) {
  return {
    type: actionTypes.CLEAR_EVENTS,
    kind
  };
}
export function setSubscritionState(state) {
  return {
    type: actionTypes.SET_SUBSCRIPTION_STATE,
    state,
  };
}
export function clearSubscritionState() {
  return {
    type: actionTypes.SET_SUBSCRIPTION_STATE,
    state: false,
  };
}
