import Immutable from 'immutable';
import { userOfferAllowedPost } from 'components/UI/Offers/ducks/offer';

const baseUrl = process.env.REACT_APP_BACKEND_URL;
export default {
  baseUrl:baseUrl,
  templateKind: 'fullWidth',
  home: baseUrl + '/api/home?api=3',
  profile: baseUrl + '/api/profil/get?api=3',
  legal:
    baseUrl +
    '/api/page/get/legal' +
    (process.env.REACT_APP_SITE === 'oestro' ? '_' + process.env.REACT_APP_SITE : ''),
  confidentiality:
    baseUrl +
    '/api/page/get/confidentiality' +
    (process.env.REACT_APP_SITE === 'oestro' ? '_' + process.env.REACT_APP_SITE : ''),
  cookies:
    baseUrl +
    '/api/page/get/cookies' +
    (process.env.REACT_APP_SITE === 'oestro' ? '_' + process.env.REACT_APP_SITE : ''),
  cms: baseUrl + '/api/cms',
  login: baseUrl + '/api/auth/login',
  private: baseUrl + '/api/private/login',
  logout: baseUrl + '/api/auth/logout',
  registration: baseUrl + '/api/registration',
  faq: baseUrl + '/api/faq',
  ping: baseUrl + '/api/ping',
  theme: baseUrl + '/api/theme',
  replays: baseUrl + '/api/event',
  account: baseUrl + '/api/account',
  job: baseUrl + '/api/job',
  utm: baseUrl + '/api/utm',
  dashboard: baseUrl + '/api/dashboard',
  message: baseUrl + '/api/message',
  medias: baseUrl + '/api/media',
  offers: baseUrl + '/api/offer',
  news: baseUrl + '/api/news',
  newsthumbpreview: baseUrl + '/cache/news/{newsId}',
  speedtest: baseUrl + '/api/speedtest',
  stWorker: baseUrl + '/api/st',
  events: baseUrl + '/api/events',
  availableevents: baseUrl + '/api/availableevents',
  myevents: baseUrl + '/api/myevents',
  mypastevents: baseUrl + '/api/mypastevents',
  eventthumbpreview: baseUrl + '/cache/events/{eventId}',
  themethumbpreview: baseUrl + '/cache/themes/{eventId}',
  offerthumbpreview: baseUrl + '/cache/offer/{eventId}',
  mediathumbpreview: baseUrl + '/cache/medias/{id}',
  eventSendInvite: baseUrl + '/api/events/invite',
  eventSubscribe: baseUrl + '/api/events/subscribe',
  eventUnsubscribe: baseUrl + '/api/events/unsubscribe',
  orchestrator: baseUrl + '/api/orchestrator',
  quiz: baseUrl + '/api/orchestrator/quiz',
  feedback: baseUrl + '/api/feedback',
  announce: baseUrl + '/api/announce',
  contact: baseUrl + '/api/contact',
  rpwd: baseUrl + '/api/auth/rpwd',
  cpwd: baseUrl + '/api/auth/cpwd',
  dpwd: baseUrl + '/api/auth/dpwd',

  fb: '',
  ld: '',
  recaptchaKey: '6Lf3LqodAAAAAMb1XCyMeA7SAbl7CSyJOdIIcnzc',
  mapsApiKey: 'AIzaSyDfDMz3hVLogQabMo90wxFodoon0afEoLM',

  universeDefaultThumb:{
    default :require('assets/images/event_blue.png'),
    gastro : require('assets/images/event_blue.png'),
    gyneco : require('assets/images/event_rose.png'),
  },

  enableVideoTracker:true,

  getMenu: ($$user, options) => {
    let userRole;
    if ($$user === undefined) userRole = 'visitor';
    else userRole = $$user.get('role');

    let menu = [];
    if (options.universe === undefined || options.universe === 'offers')
      menu.push({ icon: 'home', title: 'Accueil', url: '/{universe}/' });
    else if (options.universe === 'gastro') menu.push({ icon: 'home', title: 'Gastro', url: '/{universe}/' });
    else if (options.universe === 'gyneco') menu.push({ icon: 'home', title: 'Gynéco', url: '/{universe}/' });
    // if (userRole === 'member' || userRole === 'speaker' || userRole === 'admin') {
    //   menu.push({icon: 'lives', title: 'Live', url: '/live'})
    // }
    // if (userRole !== 'speaker') {
    // menu.push({ icon: 'agenda', title: 'Agenda', url: '/agenda'})
    // }

    /*
    //onglet Émissions du header, retrait 26/6/23
    if (userRole === 'staff' || userRole === 'admin') {
      menu.push({ icon: 'event', title: 'Émissions', url: '/staff/event/list' });
    }
    */

    if (options.universe !== 'offers') {
      if (options.universe !== undefined) {
        menu.push({ icon: 'replays', title: 'navReplays', url: '/{universe}/replays' });
        menu.push({ icon: 'medias', title: 'navMedias', url: '/{universe}/medias' });
      } else {
        menu.push({ icon: 'gyneco', title: 'Gastro', url: '/gastro/' });
        menu.push({ icon: 'gastro', title: 'Gynéco', url: '/gyneco/' });
      }
      menu.push({ icon: 'help', title: 'navHelp', url: '/help' });
    }

    menu.push({ icon: 'contact', title: 'navContact', url: '/contact' });

    return options !== undefined && options.asImmutable === true ? Immutable.fromJS(menu) : menu;
  },
  getFooterMenu: ($$user, options) => {
    let menu = [];
    /*if (options.universe === undefined) menu.push({ icon: 'home', title: 'Accueil', url: '/{universe}/' });
    else*/ if (options.universe === 'gastro')
      menu.push({ icon: 'home', title: 'Gastro', url: '/{universe}/' });
    else if (options.universe === 'gyneco') menu.push({ icon: 'home', title: 'Gynéco', url: '/{universe}/' });

    // menu.push({ icon: 'replays', title: 'navReplays', url: '/replays' });
    if (options.universe !== 'offers') {
      if (options.universe !== undefined) {
        menu.push({ icon: 'replays', title: 'navReplays', url: '/{universe}/replays' });
        menu.push({ icon: 'medias', title: 'navMedias', url: '/{universe}/medias' });
      } else {
        menu.push({ icon: 'gyneco', title: 'Gastro', url: '/gastro/' });
        menu.push({ icon: 'gastro', title: 'Gynéco', url: '/gyneco/' });
      }
      // menu.push({ icon: 'replays', title: 'navReplays', url: '/replays' });
      // menu.push({ icon: 'medias', title: 'navMedias', url: '/medias' });
      menu.push({ icon: 'help', title: 'navHelp', url: '/help' });
    }
    menu.push({ icon: 'contact', title: 'navContact', url: '/contact' });
    return options !== undefined && options.asImmutable === true ? Immutable.fromJS(menu) : menu;
  },
  // getJobs: (set) => {
  //   if (set === 1)
  //   return [
  //     { label: 'jobMd', value: 'es10' },
  //     { label: 'jobSF', value: 'es50' },
  //     // { label: 'jobPh', value: 'es21' },
  //     // { label: 'jobIn', value: 'es60' },
  //     // { label: 'jobInt', value: 'int' },
  //     // { label: 'jobX5', value: 'x5' },
  //     // { label: 'Etudiant en pharmacie', value: 'x6' },
  //     // { label: 'jobX7', value: 'x7' },
  //   ];
  //   else
  //   return [
  //     // { label: 'jobX2', value: 'x2' },
  //     // { label: 'jobX4', value: 'x4' },
  //     // { label: 'jobX3', value: 'x3' },
  //     // { label: 'jobLb', value: 'lb' },
  //   ];
  // },
  getJobsCategories: () => {
    return {
      // md: 'isMd',
      // ph: 'isPh',
      // in: 'isIn',
      es10: ['hasRpps', 'hasSpeciality', 'hasExerciseCivility', 'isVerified'],
      // es21: ['hasRpps', 'hasCnop', 'isVerified'],
      // es60: ['hasAdeli', 'hasSpeciality', 'hasExerciseCivility', 'isVerified'],
      es50: ['hasAdeli', 'hasSpeciality', 'hasExerciseCivility', 'isVerified'],
      // int: 'isInt',
      // lb: 'isLb',
      // x1: 'isX1',
      // x2: 'isX2',
      // x3: 'isX3',
      // x4: 'isX4',
      // x5: 'isX5',
      // x6: 'isX6',
      // x7: 'isX7',
    };
  },

  getProfileMenu: ($$user, options) => {
    let userRole = false;
    let userSubRole;
    if ($$user === undefined) userRole = 'visitor';
    else {
      userRole = $$user.get('role');
      userSubRole = $$user.get('subRole');
    }
    let menu = [];

    // if (userRole === 'admin')
    // menu.push({ title: 'navDashboard', url: '/dashboard' });

    if (userRole === 'member' || userRole === 'speaker' || userRole === 'staff' || userRole === 'admin') {
      menu.push({ title: 'navMyAccount', url: '/account' });
    }
    if (userOfferAllowedPost($$user) === true) {
      menu.push({
        icon: 'message',
        title: 'navMyOffers',
        url: '/offers/mylist',
      });
    }

    if (userSubRole === 'buadm') {
      menu.push({
        icon: 'account',
        title: 'navAccounts',
        url: '/staff/account/list',
      });
      menu.push({
        icon: 'media',
        title: 'navAdminOffers',
        url: '/staff/offers/list',
      });
    }
    if (userSubRole === 'buadmCtr') {
      menu.push({
        icon: 'account',
        title: 'navAccounts',
        url: '/staff/account/list',
      });
    }
    if (userSubRole === 'mod' || userSubRole === 'buadm' || userRole === 'speaker') {
      // menu.push({
      //   icon: 'event',
      //   title: 'navEvents',
      //   url: '/staff/event/list',
      // });
      menu.push({
        icon: 'message',
        title: 'navModerator',
        url: '/staff/orchestrator/list',
      });
    }
    if (userSubRole === 'buadm') {
      menu.push({
        icon: 'utm',
        title: 'navUtms',
        url: '/staff/utm/list',
      });
      menu.push({
        icon: 'export',
        title: 'navExports',
        url: '/api/_exports',
        target: '_blank',
      });
    }
    if (userRole === 'admin') {
      menu.push({
        icon: 'account',
        title: 'navAccounts',
        url: '/staff/account/list',
      });
      menu.push({
        icon: 'theme',
        title: 'navThemes',
        url: '/staff/theme/list',
      });
      menu.push({
        icon: 'event',
        title: 'navEvents',
        url: '/staff/event/list',
      });
      menu.push({
        icon: 'news',
        title: 'navNews',
        url: '/staff/news/list',
      });
      menu.push({
        icon: 'media',
        title: 'navAdminMedias',
        url: '/staff/media/list',
      });
      menu.push({
        icon: 'offer',
        title: 'navAdminOffers',
        url: '/staff/offers/list',
      });
      menu.push({ icon: 'cms', title: 'adminCms', url: '/staff/cms/list' });
      // menu.push({ icon: "news", title: "News", url: "/staff/news/list" });
      menu.push({
        icon: 'utm',
        title: 'navUtms',
        url: '/staff/utm/list',
      });

      menu.push({ icon: 'faq', title: 'navFaq', url: '/staff/faq/list' });
      menu.push({
        icon: 'message',
        title: 'navModerator',
        url: '/staff/orchestrator/list',
      });
      menu.push({
        icon: 'email',
        title: 'navEmails',
        url: '/api/_cron/mail',
        target: '_blank',
      });
      menu.push({
        icon: 'logs',
        title: 'navLogs',
        url: '/api/_logs',
        target: '_blank',
      });
      menu.push({
        icon: 'export',
        title: 'navExports',
        url: '/api/_exports',
        target: '_blank',
      });
    }

    menu.push({ title: 'Se déconnecter', url: '/logout' });
    return options !== undefined && options.asImmutable === true ? Immutable.fromJS(menu) : menu;
  },
};
