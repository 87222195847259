// Rewrite of https://github.com/googlemaps/react-wrapper/blob/main/src/index.tsx
// with a class component

import React, { Component, Fragment } from 'react';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';

export const Status = {
  LOADING: 'LOADING',
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
};

class GoogleMapApiWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: Status.LOADING,
    };
  }

  componentDidMount() {
    const { children, render, callback, ...options } = this.props;
    const loader = new Loader(options);

    const setStatusAndExecuteCallback = (status) => {
      if (callback) callback(status, loader);
      this.setState({ status });
    };

    setStatusAndExecuteCallback(Status.LOADING);

    loader.load().then(
      () => setStatusAndExecuteCallback(Status.SUCCESS),
      () => setStatusAndExecuteCallback(Status.FAILURE),
    );
  }

  render() {
    const { status } = this.state;
    const { children, render } = this.props;

    if (status === Status.SUCCESS && children) return <Fragment>{children}</Fragment>;

    if (render) return render(status);

    return <Fragment />;
  }
}

export default GoogleMapApiWrapper;
