import React from "react";
// import ReactDOM from "react-dom";
// import Immutable from "immutable";
// import MUISelect from "@material-ui/core/Select";
import i18n from 'helpers/i18n';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import isArray from 'mout/lang/isArray';
// import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
// import inputIron from './inputIron';
import Prettyfier from 'components/UI/Prettyfier'
import remove from 'mout/array/remove'
import {
  withStyles
} from "@material-ui/core/styles";
const styles = (theme) => {

  const bgColor =
  theme.palette.background.input !== undefined
  ? theme.palette.background.input
  : theme.palette.background.default;
  return {
    root: {
      '& ul': {
        margin: 0,
        padding: 0,
      },
      '& .MuiInputLabel-shrink': {
        backgroundColor: bgColor
      },
      '& .MuiFormLabel-root': {
        color: 'inherit',
        display: 'block',
      },
      '& .Mui-disabled': {
        color: theme.palette.text.disabled
      },
      '& .MuiMenuItem-root': {
        whiteSpace: 'normal',
        width: 'auto',
        overflow: 'visible',
        backgroundColor: bgColor,
        // border: '1px '+theme.palette.text.light+' solid',
        borderRadius: 5,
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(.5),
        [theme.breakpoints.down('sm')]: {
        }
      },
    },
    itemList: {
      backgroundColor: bgColor,
      border: '1px rgba(0, 0, 0, 0.23) solid',
      '&:hover': {
        borderColor: theme.palette.text.primary
      },
      '&:$active': {
        borderColor: theme.palette.primary.main
      }
    },
    item: {
      whiteSpace: 'normal',
      width: '100%',
      overflow: 'visible',
      '& small': {
        display: 'block'
      },
    },
    center: {
      textAlign: 'center',
      width: '50%',
      marginRight: 0,
      marginLeft: 0
    },
    alreadySubscribed: {
      color: theme.palette.primary.main
    }
  }
}
class SelectBlocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0,
      selectedDate: props.value!==undefined ? props.value : [],//MODIF B : ajout props.value
    };
  }
  componentDidUpdate(oldProps) {
    if(oldProps.value !== this.props.value) {
      this.setState({
        selectedDate: this.props.value,
      });
    }
  }
  selectDate = ($$opt) => {
    const {
      onChange,
      multiple,
    } = this.props;

    const {selectedDate} = this.state
    let newDates = [...selectedDate]
    // const value = o.value.toJS()
    const newValue = $$opt.get('value')
    // console.log('debug newValue', newValue, multiple)
    if (selectedDate.indexOf(newValue) >= 0) {
      if (!multiple) {
        console.log('debug should skip single mode')
        return
      }
      // console.log('debug found should remove')
      remove(newDates, newValue)
    } else {
      newDates.push(newValue)
    }
    this.setState({selectedDate:newDates})
    if(onChange) {
      console.log({newDates})
      onChange('SelectBlocks', {id: this.props.id, value: newDates});
    }
  }
  render() {
    const {
      id,
      options,
      classes,
      label,
      error,
      multiple,
      required,
      fullWidth,
      align,
      disabled,
      showLabel,
      style,
      makeItems,
      errorMessage,
      prettyfyLabel,
      labelComponent,
    } = this.props;
    // console.log('CHECKBOX ERROR IS', error)
    // console.log('CHECK ID IS ', id, disabled)
    const {
      selectedDate
    } = this.state;
    let $$options;
    $$options = options;

    let helperText = false;
    if(errorMessage !== undefined && errorMessage !== false)
    helperText = errorMessage;
    return (
      <FormControl variant="outlined"
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        error={error}
        className={classes.root} style={style}>
        <If condition={showLabel !== false}>
          <InputLabel
            focused={true}
            shrink={true}
            ref={(ref) => {
              this.InputLabelRef = ref;
            }}
            htmlFor={"outlined-" + id + "-simple"}
            disabled={disabled}
            required={required}
            >
            {label}
          </InputLabel>
        </If>
        <If condition={$$options !== null && $$options !== undefined && makeItems === undefined}>
          <ul className={classes.itemList}>
            {$$options
              .map(($$opt, idx) => {
                return (
                  <MenuItem key={'$$opt_'+idx} className={clsx(classes.item, {[classes.disabled]: $$opt.get("disabled") === true, [classes[align]]: align !== undefined})} onClick={() => this.selectDate($$opt)} disabled={$$opt.get('disabled') === true}>
                    <If condition={multiple === true}>
                      <Checkbox color="primary" checked={selectedDate.indexOf($$opt.get('value')) > -1} />
                    </If>
                    <If condition={labelComponent !== undefined}>
                      {labelComponent($$opt)}
                    </If>
                    <If condition={labelComponent === undefined}>
                      <If condition={prettyfyLabel === true}>
                        <Prettyfier text={$$opt.get("label")}/>
                      </If>
                      <If condition={prettyfyLabel !== true}>
                        {$$opt.get("label")}
                      </If>
                    </If>
                    <If condition={$$opt.get('disabled') === true}>
{/*                      <span className={classes.alreadySubscribed}>{i18n.get('subscriptionAlreadyExists')}</span>
*/}
                    </If>
                  </MenuItem>
                );
              })
              .toArray()}
            </ul>
          </If>
          <If condition={helperText !== undefined && helperText !== false}>
            <FormHelperText error={error}>{helperText}</FormHelperText>
          </If>
        </FormControl>
      );
    }
  }
  export default withStyles(styles)(SelectBlocks);
