import React, { Component } from "react";
import Form from "./UtmForm";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import DynamicFormHelper from 'components/UI/DynamicForm/DynamicFormHelp'
import Select from 'components/UI/Form/Select'
// import Prettyfier from 'components/UI/Prettyfier'
import { connect } from "react-redux";
import { fetchData, getData, getAvailableEvents, getReplayEvents } from 'ducks/asyncFetcher';
import { fetchEvents } from 'components/UI/Event/ducks/event';

import config from 'config/api';
import Immutable from 'immutable';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { formatDate } from "helpers/stringUtils";

const styles = {
  paper: {
    maxWidth: 1024,
    padding: "10px 20px",
    margin: "20px auto 20px",
  },
};
class AdminUtmForm extends Component {
  constructor(props) {
    super(props);
    this._onClose = this._onClose.bind(this);
    this.state={displayedUrl: undefined}
  }

  componentDidMount() {
    this.props.onMount();
  }

  _onClose() {
    const { history } = this.props;
    history.push("/staff/utm/list");
  }
  _onBuildUrl = (field, id) => {
    const {$$availableEvents, $$replayEvents} = this.props;
    if (field === 'eventSubscriptionCreator') {
      const $$event = $$availableEvents.find($$e => $$e.get('id') === id);
      const utmCode = this.state.values.get('utmCode')
      // console.log($$event.toJS(), utmCode)
      //this.setState({eventLinkSubscription: window.location.protocol+'//'+window.location.host+'/subscribe/'+$$event.get('themeId')+'/?r='+utmCode})
      this.setState({eventLinkSubscription: window.location.protocol+'//'+window.location.host+'/event/'+$$event.get('id')+'/?r='+utmCode})
    }
    else if (field === 'eventLiveCreator') {
      const $$event = $$availableEvents.find($$e => $$e.get('id') === id);
      const utmCode = this.state.values.get('utmCode')
      // console.log($$event.toJS(), utmCode)
      this.setState({eventLinkLive: window.location.protocol+'//'+window.location.host+'/live/'+$$event.get('id')+'/?r='+utmCode})
    }
    else if (field === 'replayLinkCreator') {
      const $$event = $$replayEvents.find($$e => $$e.get('id') === id);
      const utmCode = this.state.values.get('utmCode')
      this.setState({eventLinkReplay: window.location.protocol+'//'+window.location.host+'/replays/'+$$event.get('id')+'/?r='+utmCode})
    }
  }
  _setValues = (data) => {
    // console.log('SETTING VALUES')
    const utmCode = data.values.get('utmCode')
    const platformLink = window.location.protocol+'//'+window.location.host+'/?r='+utmCode;
    this.setState({values: data.values, platformLink})
  }
  render() {
    const { id, classes, $$data, $$availableEvents, $$replayEvents } = this.props;
    const {eventLinkSubscription, eventLinkLive, eventLinkReplay, platformLink} = this.state;
    // if ($$data !== undefined)console.log($$data.toJS())
    return (
      <div>
        <Paper classes={{ root: classes.paper }}>
          <Form
            id={id}
            onFormComplete={this._onClose}
            onFormCancel={this._onClose}
            onFormLoaded={this._setValues}
            />
          <If condition={id !== undefined && $$data !== undefined && $$data.get('home') !== undefined}>
            <Box width="100%" mb={4}>
              <Typography variant="h5" style={{textTransform: 'none', marginTop: 16}} gutterBottom>Lien vers la <strong>PLATEFORME</strong></Typography>
              <If condition={$$availableEvents.size > 0}>
                <Typography variant="h6" style={{textTransform: 'none', marginTop: 16}}>Lien UTM: {platformLink}</Typography>
              </If>
              <If condition={$$availableEvents.size === 0}>
                <Typography variant="h6" style={{textTransform: 'none', marginTop: 16}}>Il n'y a pas d'événements à venir !</Typography>
              </If>

            </Box>
            <Box width="100%" mb={4}>
              <Typography variant="h5" style={{textTransform: 'none', marginTop: 16}} gutterBottom>Lien vers page d'<strong>INSCRIPTION</strong> d'événement</Typography>
              <If condition={$$availableEvents.size > 0}>
                <Select id={'eventSubscriptionCreator'}
                  variant="outlined"
                  label="Evénement"
                  prettyfyLabel={true}
                  onChange={(kind, data) => this._onBuildUrl(data.id, data.value)}
                  options={$$availableEvents.sortBy($$e => -$$e.get('eventDate')).map($$e => { return Immutable.fromJS({label: formatDate($$e.get('eventDate'), "dd/mm/yyyy HH:MM")+' | '+$$e.get('eventTitle'), value: $$e.get('id')})})}
                  />
                <Typography variant="h6" style={{textTransform: 'none', marginTop: 16}}>Lien UTM: {eventLinkSubscription}</Typography>
              </If>
              <If condition={$$availableEvents.size === 0}>
                <Typography variant="h6" style={{textTransform: 'none', marginTop: 16}}>Il n'y a pas d'événements à venir !</Typography>
              </If>

            </Box>
            <Box width="100%" mb={4}>
              <Typography variant="h5" style={{textTransform: 'none', marginTop: 16}} gutterBottom>Lien vers page <strong>LIVE</strong> d'un événement</Typography>
              <If condition={$$availableEvents.size > 0}>
                <Select id={'eventLiveCreator'}
                  variant="outlined"
                  label="Evénement"
                  prettyfyLabel={true}
                  onChange={(kind, data) => this._onBuildUrl(data.id, data.value)}
                  options={$$availableEvents.sortBy($$e => -$$e.get('eventDate')).map($$e => { return Immutable.fromJS({label: formatDate($$e.get('eventDate'), "dd/mm/yyyy HH:MM")+' | '+$$e.get('eventTitle'), value: $$e.get('id')})})}
                  />
                <Typography variant="h6" style={{textTransform: 'none', marginTop: 16}}>{eventLinkLive}</Typography>
              </If>
              <If condition={$$availableEvents.size === 0}>
                <Typography variant="h6" style={{textTransform: 'none', marginTop: 16}}>Il n'y a pas d'événements à venir !</Typography>
              </If>

            </Box>
            <Box width="100%">
              <Typography variant="h5" style={{textTransform: 'none', marginTop: 16}} gutterBottom>Lien vers la <strong>Page de tous les REPLAYS</strong></Typography>
              <Typography variant="h6" style={{textTransform: 'none', marginTop: 16}}>{window.location.protocol+'//'+window.location.host+'/replays'}</Typography>
              </Box>
            <Box width="100%">
              <If condition={$$replayEvents.size > 0}>
                <Typography variant="h5" style={{textTransform: 'none', marginTop: 16}} gutterBottom>Lien vers un <strong>REPLAY</strong></Typography>
                <Select id={'replayLinkCreator'}
                  variant="outlined"
                  label="Replay"
                  prettyfyLabel={true}
                  onChange={(kind, data) => this._onBuildUrl(data.id, data.value)}
                  options={$$replayEvents.sortBy($$e => -$$e.get('eventDate')).map($$e => { return Immutable.fromJS({label: formatDate($$e.get('eventDate'), "dd/mm/yyyy HH:MM")+' | '+$$e.get('eventTitle'), value: $$e.get('id')})})}
                  />
                <Typography variant="h6" style={{textTransform: 'none', marginTop: 16}}>Lien UTM: {eventLinkReplay}</Typography>
              </If>
              <If condition={$$replayEvents.size === 0}>
                <Typography variant="h6" style={{textTransform: 'none', marginTop: 16}}>Il n'y a pas de replays !</Typography>
              </If>
            </Box>
          </If>
          <If condition={id === undefined}>
            <Typography variant="body1" align="center">Une fois l'UTM enregistré, vous pourrez accéder aux URLs</Typography>
          </If>
        </Paper>
      </div>
    );
  }
}

// export default withRouter(withStyles(styles)(AdminUtmForm));
function mapStateToProps(state) {
  const props = {
    $$data: getData(state),
    $$availableEvents: getAvailableEvents(state),
    $$replayEvents: getReplayEvents(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({ key: 'home', fetchUrl: config.home }));
      dispatch(fetchEvents({kind: 'events'  }));
      dispatch(fetchEvents({kind: 'replays'  }));
    },
  };
}

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AdminUtmForm)));
