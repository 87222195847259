// import React, { Component } from 'react';
import config from 'config/api';

export default ($liveEvent, callback) => {
  const url = config.events + '/redir/' + $liveEvent.get('id');
  // window.location = url;

  var formData = new FormData();

  formData.append('rd', Math.round(new Date() / 1000));
  // const url = config.events+'/redir/'+$liveEvent.get('id');
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      'X-Requested-With': 'XMLHttpRequest', // needed by php api
    },
    body: formData,
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.live && response.live === 'complete') callback(true);
      else if (response.live && response.live === 'redir' && response.to !== undefined) {
        console.log('calling ', response.to);
        window.location = response.to;
      } else callback(false);
    });
  // this._goto(url)
};
