//B : import depuis Norgine
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import Fade from 'react-reveal/Fade';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import i18n from 'helpers/i18n';
import getCardImage from './getCardImage';
import getCardGuests from 'components/UI/helpers/getCardGuests';
import { getDataHome, getAvailableEvents, getReplayEvents } from 'ducks/asyncFetcher';
import { getUniverse } from 'components/UI/Account/ducks/authentication';
import { fetchEvents } from 'components/UI/Event/ducks/event';
import { toHHMM } from 'components/UI/helpers/time';
import Button from '@material-ui/core/Button';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import BlockRatio from 'components/BlockRatio';
import config from 'config/api';

const styles = (theme) => {
  return {
    root: {
      '& .MuiTypography-caption': {
        color: theme.palette.primary.dark,
      },
      height: '100%',
      paddingBottom: theme.spacing(2),
      '& .MuiTypography-h5':{
        fontSize:'1.2rem',
      }
    },
    itemWrapper: {
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
      borderRadius: 5,
      backgroundColor: '#fff',
      height: '100%',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      border: '1px #c7d3d7 solid',
      '& > div': {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
      },
    },
    itemContentWrapper: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    itemThumbnailWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    itemThumbnail: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: 'contain',
      backgroundColor: 'transparent',
    },
    itemThumbnailDefaultBackground: {
      backgroundColor: theme.palette.secondary.main,
    },
    itemThumbnailVertical: {
      backgroundPosition: '50% -20px',
    },
    itemTheme: {
      marginBottom: theme.spacing(0.5),
      fontSize: '0.75rem',
      lineHeight: '1.5',
    },
    itemTitle: {
      color: theme.palette.secondary.dark,
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    itemDescription: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    itemDate: {
      color: theme.palette.primary.dark,
      width: '100%',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    itemPrecisions: {
      width: '100%',
      marginBottom: theme.spacing(1.5),
      '&:last-of-type': {
        marginBottom: theme.spacing(2),
      },
    },
    itemPrecisionsSpacer: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    invisible: {
      visibility: 'hidden',
    },
    liveNavigation: {
      marginBottom: theme.spacing(1),
      '& .MuiButton-root.MuiButton-containedPrimary': {
        fontWeight: 700,
      },
      '& .MuiTypography-body1': {
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        color: theme.palette.secondary.light,
        fontWeight: 700,
      },
    },
    iconPlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
      fontSize: '10rem',
      width: 100,
      height: 100,
      cursor: 'pointer',
    },
    itemGuests: {
      marginBottom: theme.spacing(2),
      '& .MuiTypography-caption': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiTypography-body1': {
        fontWeight: 700,
      },
    },
    itemAvatars: {
      marginBottom: theme.spacing(1),
      '& .MuiSvgIcon-root': {
        margin: 0,
      },
      '& .MuiAvatar-colorDefault': {
        color: theme.palette.secondary.light,
      },
    },
    itemGuestName: {
      paddingRight: theme.spacing(0.4),
      marginRight: theme.spacing(1),
      display: 'inline-block',
      position: 'relative',
      '&:before': {
        content: "','",
        textAlign: 'right',
        position: 'absolute',
        right: 0,
        width: '100%',
        height: '100%',
      },
      '&:last-of-type': {
        paddingRight: 0,
        marginRight: 0,
        '&:before': {
          content: "' '",
        },
      },
    },
  };
};

class MediaCard extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     activeStep: 0,
  //     groupStep: 3
  //   }
  // }

  componentDidMount() {
    // this.props.onMount({kind: this.props.kind})
    // this._onWindowResize();
    // window.addEventListener('resize', this._onWindowResize);
  }

  componentDidUpdate(oldProps) {
    // if (oldProps.$$availableEvents !== this.props.$$replayEvents || oldProps.$$replayEvents !== this.props.$$availableEvents) {
    //   this._onWindowResize();
    // }
  }

  gotoMedia = (e, $$event) => {
    e.preventDefault();
    const { history, useUniverseInLinks, universe } = this.props;
    history.push(
        (useUniverseInLinks && universe!==undefined ? '/' + universe : '')
         + '/media/' + $$event.get('id'));
  };
  render() {
    const { $$item, itemIndex, classes, kind, variant, universe, useUniverseDefaultThumb } = this.props;
    const $$guests = getCardGuests($$item);
    const imagePosition = variant ? variant : 'horizontal';
    let thumb = getCardImage($$item);
    let defaultThumb=false;
    if(thumb===false && useUniverseDefaultThumb && config.universeDefaultThumb!==undefined){
        if(universe!==undefined && config.universeDefaultThumb[universe]!==undefined){
          thumb=config.universeDefaultThumb[universe];
          defaultThumb=true;
        }else if(config.universeDefaultThumb['default']!==undefined){
          thumb=config.universeDefaultThumb['default'];
          defaultThumb=true;
        }
    }
    let thumbCls=clsx(classes.itemThumbnail, {
      [classes.itemThumbnailHorizontal]: imagePosition === 'horizontal' && thumb === false,
      [classes.itemThumbnailVertical]: imagePosition !== 'horizontal' && thumb === false,
      [classes.itemThumbnailDefaultBackground] : defaultThumb,
    });
    return (
      <Grid
        item
        xs={12}
        container
        justifyContent={'flex-start'}
        alignContent="flex-start"
        className={classes.root}
      >
        <Paper className={classes.itemWrapper} elevation={0} element="div">
          <Fade delay={250 * (itemIndex ? itemIndex + 1 : 1)} cascade style={{ height: '100%' }}>
            <Grid container style={{ height: '100%' }} alignContent="flex-start">
              <Grid
                item
                xs={imagePosition === 'horizontal' ? 5 : 12}
                container
                alignContent={imagePosition !== 'horizontal' ? 'center' : 'flex-start'}
                style={{ position: 'relative' }}
              >
                <Box className={classes.itemThumbnailWrapper}>
                  <BlockRatio
                    ratio="16_9"
                    style={{ height: imagePosition === 'horizontal' ? '100%' : undefined }}
                  >
                    <div
                      className={thumbCls}
                      style={{
                        backgroundImage: 'url('+(thumb !== false ? thumb : '')+')',
                      }}
                    ></div>
                  </BlockRatio>
                </Box>
              </Grid>
              <Grid
                item
                xs={imagePosition === 'horizontal' ? 7 : 12}
                container
                alignContent="flex-start"
                className={classes.itemContentWrapper}
              >
                <Typography variant="subtitle1" component="div" gutterBottom className={classes.itemTheme}>
                  <Paragrapher text={$$item.get('mediaTheme')} prettyfy={true} element={'div'} />
                </Typography>

                <Typography variant="h5" component="div" gutterBottom className={classes.itemTitle}>
                  <Paragrapher
                    text={$$item.get('mediaTitle')}
                    elementClassName={classes.content}
                    prettyfy={true}
                    element={'div'}
                  />
                </Typography>
                <If condition={$$item.get('mediaShortDescription')}>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    className={classes.itemDescription}
                  >
                    <Paragrapher
                      text={$$item.get('mediaShortDescription')}
                      elementClassName={classes.content}
                      prettyfy={true}
                      element={'div'}
                    />
                  </Typography>
                </If>
                <If
                  condition={
                    $$item.get('mediaDuration') &&
                    $$item.get('mediaDuration') !== false &&
                    $$item.get('mediaDuration') !== false &&
                    $$item.get('mediaDuration') !== '' &&
                    $$item.get('mediaDuration') > 0
                  }
                >
                  <If condition={kind !== 'replays'}>{/*&nbsp;&nbsp;|&nbsp;&nbsp;*/}</If>
                  <Typography
                    variant="caption"
                    component="div"
                    gutterBottom
                    className={classes.itemPrecisions}
                  >
                    <WatchLaterIcon /> {i18n.get('mediaDuration', { pretty: false })}{' '}
                    {toHHMM($$item.get('mediaDuration') * 60)}
                  </Typography>
                </If>
                <If condition={$$guests !== false && $$guests.size > 0}>
                  <Grid item xs={12} container>
                    <Box className={classes.itemGuests}>
                      <Typography variant={'caption'} gutterBottom>
                        {i18n.get('mediaAnimatedBy', { pretty: false })}
                      </Typography>
                      <Box className={classes.itemAvatars} mt={0.5}>
                        <AvatarGroup max={5} spacing={'medium'}>
                          {$$guests
                            .map(($$g, aIdx) => {
                              return (
                                <Avatar
                                  size="small"
                                  key={'av_' + aIdx}
                                  src={$$g.get('dest')}
                                  alt={$$g.getIn(['attributes', 'name'])}
                                />
                              );
                            })
                            .toArray()}
                        </AvatarGroup>
                      </Box>
                      <Typography variant={'body1'} gutterBottom>
                        {$$guests
                          .map(($$g, aIdx) => {
                            return (
                              <span key={'an_' + aIdx} className={classes.itemGuestName}>
                                {$$g.getIn(['attributes', 'name'])}
                              </span>
                            );
                          })
                          .toArray()}
                      </Typography>
                    </Box>
                  </Grid>
                </If>
                <Box className={classes.liveNavigation} mb={2}>
                  <Button variant="contained" color="primary" onClick={(e) => this.gotoMedia(e, $$item)}>
                    {i18n.get('mediaAction')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Fade>
        </Paper>
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    $$availableEvents: getAvailableEvents(state),
    $$replayEvents: getReplayEvents(state),
    universe: getUniverse(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      dispatch(fetchEvents({ kind: props.kind }));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MediaCard)));
