import React from "react";
// import AsyncSelect from "react-select/lib/Async";
import TextField from "@material-ui/core/TextField";
// import Typography from "@material-ui/core/Typography";
// import Paper from "@material-ui/core/Paper";
import Paragrapher from "components/UI/Paragrapher/Paragrapher"
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
// import classNames from "classnames";
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
      ? theme.palette.grey[300]
      : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});


class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      inputValue: "",
      defaultValues: undefined,
    };
    // this._onChange = this._onChange.bind(this);
    // this._onInputChange = this._onInputChange.bind(this);
  }
  componentDidMount() {
    // if (this.props.value !== "" && this.props.value !== undefined) {
    //   console.log("found field value!", this.props.value);
    const {value} = this.props;

    // console.log('autocomplete', value)
    if (value === null || value === undefined)
    {
    this._send(
      null
    );
  } else {
    this._send(
      null,
      (r) => {
        console.log('got response !!', r)
      },
      this.props.value
    );
    }
      // const { value } = this.props;
      // if (value !== undefined) {
      //   const selectedOption = countries.find(c => c.code === value);
      //   this._onChange(null, selectedOption);
      //   // this.setState({value: selectedOption.code, inputValue: selectedOption.label}, () => {
      //   // })
      // }

    // }
    // this.setState({
    //   value: this.props.value,
    // });
  }
  componentDidUpdate(oldProps, oldState) {
    if (oldState.defaultValues === undefined && this.state.defaultValues !== undefined) {
      // console.log('test1')
      if (this.props.value !== undefined && this.state.defaultValues !== undefined) {
        // console.log('test2')
        const idx = this.state.defaultValues.findIndex(o => o.value === this.props.value);
        if (idx !== undefined && idx >= 0) {
          console.log('DO FIX', this.props.label, this.state.defaultValues[idx])
        const newState = {
          // value: this.state.defaultValues[idx].value !== '' ? this.state.defaultValues[idx].value : undefined,
          inputValue: this.state.defaultValues[idx].value !== '' ? this.state.defaultValues[idx].text : undefined,
        };
        // console.log('updating state with', {newState})
        this.setState(newState);
        }
      }
    }
  }
  _onChange = (event, option) => {
    // console.log('onchange', this.props.label)
    const { onChange } = this.props;
    const id = this.props.id;
    const value = option !== null ? option.value : null
    // debounce(function() {
    // console.log('adding', id, value)
    this.setState({ value: option.text }, function () {
      if (onChange)
      onChange("input", {
        id: id,
        value: value,
      });
    });
    // }.bind(this), 100)
  }
  // _onInputChange = (event, str) => {
  //   // console.log('str', str)
  //   console.log('onInputChange', this.props.label, str, this.state.defaultValues)
  //   // this.setState({ inputValue: str });
  //   const idx = this.state.defaultValues.findIndex(o => o.value === this.props.value);
  //   if (idx !== undefined && idx >= 0) {
  //     this.setState({
  //       inputValue: str,
  //       inputValueStr: this.state.defaultValues[idx].value !== '' ? this.state.defaultValues[idx].text : undefined
  //     })
  //   } else {
  //     console.log('bug')
  //   }
  //
  // }
  _onInputChange = (event, str) => {
    console.log('inputchanged', str)
      this.setState({
        inputValue: str,
      }, () => {
        if (str.length === 5)
        this._send(str);
      })

  }
  _send(str, callback, id) {
    const { url } = this.props;
    const { sending } = this.state;

    if (sending === true) return;

    this.setState({ sending: true, log: false });
    var formData = new FormData();
    formData.append("ac", str);
    formData.append("aid", id);
    // console.log('sending', str, id)
    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: formData,
    })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.status && response.status === "ok" && response.data) {
        // console.log('got ok response', response)
        this.setState({ defaultValues: response.data.options, sending: false });
        if (callback) {
          callback(response.data.options);
        }
      } else {
        this.setState({ sending: false });
      }
    });
  }

  render() {
    const {
      id,
      // name,
      label,
      type,
      variant,
      margin,
      maxLength,
      // multiline,
      fullWidth,
      required,
      disabled,
      // rows,
      error,
      errorMessage,
      style,
      helperText,
      // prettyfyLabel,
      // classes,
      // theme,
    } = this.props;
    const {
      value,
      inputValue,
      defaultValues,
    } = this.state;
    // const inputValue = 'hello'
    // const selectStyles = {
    //   input: (base) => ({
    //     ...base,
    //     color: theme.palette.text.primary,
    //     "& input": {
    //       font: "inherit",
    //     },
    //   }),
    // };
    let textFieldProps = {
      label, variant, error, style, required
    }
    if (maxLength !== undefined) textFieldProps.maxLength = maxLength ;
// console.log({inputValue})
    if (errorMessage !== undefined && errorMessage !== false) textFieldProps.helperText = errorMessage;
    let autoCompleteProps = {
      id,
      name: id,
      // name,
      // id: name,
      label,
      value: value,
      disabled,
      // loading: true,
      // loadingText: '...',
      noOptionsText: helperText,
      placeholder: "",
      fullWidth: fullWidth !== undefined ? fullWidth : true,
      variant: variant !== undefined ? variant : 'oulined',
      margin: margin !== undefined ? margin : "dense",
      type: type ? type : "text",
      onChange: this._onChange,
      disableClearable: true,
      // freeSolo: true,
      inputValue,
      getOptionLabel: (option) => option.text || option,
      onInputChange: this._onInputChange,
      getOptionSelected: (option, value) => option.value === value,
      options: defaultValues,
      renderInput: (params) => { return (<TextField {...params} {...textFieldProps} />)},
      renderOption: (option) => { return (<div><Paragrapher prettyfy={true} text={option.text}/> </div> )}
};

// console.log({value,inputValue: autoCompleteProps.inputValue})

return <If condition={defaultValues !== undefined && value !== undefined && inputValue !== undefined}>
  <Autocomplete

    {...autoCompleteProps}
    />
</If>

}
}
export default withStyles(styles, { withTheme: true })(AutoComplete);
