import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import config from 'config/api';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import Prettyfier from 'components/UI/Prettyfier';
import { Link } from 'react-router-dom';
import getFetchOptions from 'helpers/getFetchOptions';
import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';

import { formatDate } from 'helpers/stringUtils';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';

import DateRangeIcon from '@material-ui/icons/DateRange';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ListIcon from '@material-ui/icons/List';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import HomeIcon from '@material-ui/icons/Home';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import getOfferImages from 'helpers/getOfferImages';
import { getToken } from 'components/UI/Account/ducks/authentication';
import imgStetho from 'assets/images/stethoscope.svg';
import imgPouce from 'assets/images/pouce.svg';
import bgimg1 from 'assets/images/event.png';
import details_fond from 'assets/images/details_fond.jpg';
import header_news_intro from 'assets/images/header_news_intro.png';
import DialogConfirm from 'components/UI/Dialog/DialogConfirm';

import OfferImages from './OfferImages';
import { OfferDates, OfferFullname, OfferRetrocession } from './OfferHelper';

const styles = (theme) => {
  return {
    root: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        /*backgroundColor: 'green',*/
      },
      [theme.breakpoints.down('sm')]: {
        /*backgroundColor: 'blue',*/
      },
      [theme.breakpoints.down('xs')]: {
        /*backgroundColor: 'red',*/
      },
    },
    contentGrid: {
      '& .MuiCardContent-root': {
        [theme.breakpoints.down('md')]: {},
        [theme.breakpoints.down('sm')]: {
          padding: '8px',
          fontSize: '90%',
        },
        [theme.breakpoints.down('xs')]: {
          padding: '20px',
          fontSize: '100%',
        },
      },
    },
    mainGrid: {
      position: 'static',
    },
    mainGridOnPreview: {
      position: 'relative',
    },
    topImage: {
      objectFit: 'cover',
      height: '300px',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    topImageOnPreview: {
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
    main: {
      marginTop: '241px',
    },
    mainPublished: {
      marginTop: 0,
    },
    breadCrumbs: {
      zIndex: 10,
      position: 'relative',
      background: 'rgb(35,113,129)',
      background: 'linear-gradient(180deg, rgba(39, 132, 151, 0.66) 0%, rgba(26, 151, 177, 0.00) 100%)',
      padding: '20px',
      margin: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      '& .MuiTypography-root': {
        color: 'white',
      },
    },
    title: {
      borderRadius: 20,
      margin: 5,
      padding: 10,
      zIndex: 10,
      position: 'relative',
      '& h2': {
        [theme.breakpoints.down('md')]: {
          fontSize: '1.8rem',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.6rem',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '1.4rem',
        },
      },
      '& .MuiCardContent-root': {
        [theme.breakpoints.down('md')]: {},
        [theme.breakpoints.down('sm')]: {
          padding: '10px',
        },
        [theme.breakpoints.down('xs')]: {
          padding: '5px',
        },
      },
    },
    titleDates: {
      fontSize: '50%',
      marginLeft: 20,
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
    },
    type: {
      backgroundColor: '#0097D926',
      borderRadius: 10,
      margin: 5,
      '& .MuiTypography-root': {
        color: '#0097D9',
        fontWeight: 'bold',
      },
    },
    infos: {
      borderRadius: 10,
      margin: 5,
    },
    domain: {
      color: '#0A569A',
      background: '#0A569A26 0% 0% no-repeat padding-box',
      borderRadius: '5px',
      padding: '2px',
      margin: '2px 6px',
      display: 'inline-block',
    },
    descriptionCard: {
      borderRadius: 10,
      margin: 5,
      marginBottom: 10,
      position: 'relative',
    },
    bgImage: {
      position: 'absolute',
      top: 0,
      right: 0,
      opacity: '0.05',
      transform: 'rotate(180deg) scale(1.5)',
    },
    text: {
      whiteSpace: 'pre-wrap',
      padding: '.5em',
      lineHeight: '2.2',
    },
    plusCard: {
      borderRadius: 10,
      margin: 5,
      position: 'relative',
    },
    ListItemText: {
      paddingLeft: theme.spacing(4),
    },
    images: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    mainTable: {
      marginTop: '1em',
      color: 'rgb(10, 86, 154)',
      '& td': {
        padding: '5px 8px',
        [theme.breakpoints.down('md')]: {},
        [theme.breakpoints.down('sm')]: {
          padding: '3px 4px',
        },
        [theme.breakpoints.down('xs')]: {
          padding: '5px 8px',
        },
      },
    },
    tableIcon: {},
    tableLabel: {},
    tableContent: {
      paddingLeft: '1em !important',
    },
    publishButton: {
      backgroundColor: '#F88D5E !important',
      borderColor: '#F88D5E !important',
      '&:hover': {
        backgroundColor: 'white !important',
        borderColor: '#F88D5E !important',
        color: '#F88D5E !important',
      },
    },
  };
};
class OfferContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      published: false,
      dialogDeactivate: false,
    };
  }

  onEdit = () => {
    const id = this.props.$$item.get('id');
    this.props.history.push('/offers/edit/' + id);
  };
  onPublish = async () => {
    const id = this.props.$$item.get('id');
    const url = config.offers + '/publish';
    let data = { id: id };
    data.csrf = await getToken();
    fetch(url, getFetchOptions('POST', data))
      .then((response) => response.json())
      .then((response) => {
        if (response.toggleStatus && response.toggleStatus === 'complete') {
          this.setState({ published: true });
        } else if (response.status && response.status === 'error') {
          this.setState({ error: response.log });
        }
      });
  };
  onDeactivate = async (props) => {
    const id = this.props.$$item.get('id');
    const url = config.offers + '/deactivate';
    let data = { id: id };
    data.csrf = await getToken();
    fetch(url, getFetchOptions('POST', data))
      .then((response) => response.json())
      .then((response) => {
        if (response.toggleStatus && response.toggleStatus === 'complete') {
          this.props.history.push('/offers');
        } else if (response.status && response.status === 'error') {
          this.setState({ error: response.log });
        }
      });
  };

  mainContent = () => {
    const { classes, $$item, $$user, $$selects } = this.props;
    const { published, dialogDeactivate, error } = this.state;
    const images = getOfferImages($$item);

    const domains =
      $$selects && $$selects.get('values') && $$selects.get('values').get('offerDomain')
        ? $$selects.get('values').get('offerDomain').toJS()
        : {};
    const practiceModes =
      $$selects && $$selects.get('values') && $$selects.get('values').get('offerPracticeMode')
        ? $$selects.get('values').get('offerPracticeMode').toJS()
        : {};
    const offerTypes =
      $$selects && $$selects.get('values') && $$selects.get('values').get('offerType')
        ? $$selects.get('values').get('offerType').toJS()
        : {};
    let domainValues = $$item.get('offerDomain') != '' ? JSON.parse($$item.get('offerDomain')) : [];
    if (!Array.isArray(domainValues)) domainValues = Object.values(domainValues);

    const domainViews = domainValues.map((val) => domains[val]);
    const isOwner = this.isOwner();
    const publishButton = this.isPreview();
    const deactivateButton = isOwner && $$item.get('offerStatus') == 'ok';
    const type = $$item.get('offerType');
    const typeLabel = offerTypes[type];
    let categories = {};
    if ($$selects !== undefined && $$selects.get('values')) {
      const values = $$selects.get('values');
      if (values.get('offerCategory')) categories = values.get('offerCategory').toJS();
    }
    const categValue = $$item !== undefined && $$item.get('offerCategory') ? $$item.get('offerCategory') : '';
    const category = categValue in categories ? categories[categValue] : '';
    const practiceMode =
      $$item !== undefined &&
      $$item.get('offerPracticeMode') &&
      $$item.get('offerPracticeMode') in practiceModes
        ? practiceModes[$$item.get('offerPracticeMode')]
        : '';

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={10} md={7}>
          <Card className={classes.title}>
            <CardContent>
              <Typography variant="h2" component="h2">
                {$$item.get('offerCity')}
                <Box component="span" className={classes.titleDates}>
                  <DateRangeIcon /> <OfferDates item={$$item} />
                </Box>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid container spacing={1} className={classes.contentGrid}>
          <Grid item xs={12} sm={6} md={5}>
            {typeLabel && (
              <Card className={classes.type}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {typeLabel}
                  </Typography>
                </CardContent>
              </Card>
            )}
            <Card className={classes.infos}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {$$item.get('offerInstitutionName')}
                </Typography>
                <Typography variant="subtitle1" component="h2">
                  {category}
                </Typography>
                <Typography variant="subtitle1" component="h2">
                  <OfferFullname item={$$item} />
                </Typography>

                <table className={classes.mainTable}>
                  <tbody>
                    <If condition={categValue == 'remp' && $$item.get('offerRetrocession') != 0}>
                      <tr>
                        <td className={classes.tableIcon}>
                          <BusinessCenterIcon />
                        </td>
                        <td className={classes.tableLabel}>Rétrocession</td>
                        <td className={classes.tableContent}>{<OfferRetrocession item={$$item} />}</td>
                      </tr>
                    </If>
                    <tr>
                      <td className={classes.tableIcon}>
                        <HomeWorkIcon />
                      </td>
                      <td className={classes.tableLabel}>Mode d'exercice</td>
                      <td className={classes.tableContent}>{practiceMode}</td>
                    </tr>
                    {/*<tr>
                    <td className={classes.tableIcon}><LocalHospitalIcon /></td>
                    <td className={classes.tableLabel}>Cabinet de groupe</td>
                    <td className={classes.tableContent}>{$$item.get('offerGroupOffice')==='on' ? 'Oui' : 'Non'}</td>
                    </tr>*/}
                    <If condition={domainViews.length > 0}>
                      <tr>
                        <td className={classes.tableIcon}>
                          <ListIcon />
                        </td>
                        <td className={classes.tableLabel}>
                          Compétences
                          <br />
                          requises
                        </td>
                        <td className={classes.tableContent}>
                          {domainViews.map((val) => (
                            <Box key={val} className={classes.domain}>
                              {val}
                            </Box>
                          ))}
                        </td>
                      </tr>
                    </If>
                    <If condition={categValue == 'remp'}>
                      <tr>
                        <td className={classes.tableIcon}>
                          <HomeIcon />
                        </td>
                        <td className={classes.tableLabel}>Logement disponible</td>
                        <td className={classes.tableContent}>
                          {$$item.get('offerHousing') === 'on' ? 'Oui' : 'Non'}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tableIcon}>
                          <DirectionsCarIcon />
                        </td>
                        <td className={classes.tableLabel}>Véhicule nécessaire</td>
                        <td className={classes.tableContent}>
                          {$$item.get('offerVehicleRequired') === 'on' ? 'Oui' : 'Non'}
                        </td>
                      </tr>
                    </If>
                    <tr>
                      <td className={classes.tableIcon}>
                        <EmailIcon />
                      </td>
                      <td className={classes.tableLabel}>E-mail</td>
                      <td className={classes.tableContent}>{$$item.get('offerEmail')}</td>
                    </tr>
                    <tr>
                      <td className={classes.tableIcon}>
                        <PhoneIcon />
                      </td>
                      <td className={classes.tableLabel}>Tél</td>
                      <td className={classes.tableContent}>{$$item.get('offerPhone')}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Box m={3}>
              <DateRangeIcon /> Annonce créée le : {formatDate($$item.get('dateCreated'), 'dd/mm/yyyy')}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={7}>
            <Card className={classes.descriptionCard}>
              <CardContent>
                <Typography variant="h5" component="h3">
                  <img
                    src={imgStetho}
                    style={{ verticalAlign: 'middle', marginRight: '1em', marginBottom: '.6em' }}
                  />
                  L'activité
                </Typography>
                <Box className={classes.text}>{$$item.get('offerDescription')}</Box>
                <img className={classes.bgImage} src={bgimg1} />
                <OfferImages images={images} />
              </CardContent>
            </Card>

            <Card className={classes.plusCard}>
              <CardContent>
                <Typography variant="h5" component="h3">
                  <img
                    src={imgPouce}
                    style={{ verticalAlign: 'middle', marginRight: '1em', marginBottom: '.6em' }}
                  />
                  Les plus
                </Typography>
                <Box className={classes.text}>{$$item.get('offerPlus')}</Box>
                <img className={classes.bgImage} src={bgimg1} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box my={5} width="100%">
          {publishButton && (
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button variant="outlined" color="primary" onClick={this.onEdit} startIcon={<EditIcon />}>
                Modifier mon annonce
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onPublish}
                className={classes.publishButton}
              >
                Publier mon annonce
              </Button>
            </Box>
          )}
          <If condition={error !== null}>
            <Box mb={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          </If>

          {deactivateButton && (
            <div>
              <If condition={dialogDeactivate}>
                <DialogConfirm
                  open={true}
                  onConfirmed={() => {
                    this.setState({
                      dialogDeactivate: false,
                    });
                    this.onDeactivate();
                  }}
                  name="Name"
                  title="Souhaitez-vous désactiver cette annonce ?"
                  text=""
                  textStyle={{ textAlign: 'center' }}
                  onCanceled={() => {
                    this.setState({
                      dialogDeactivate: false,
                    });
                  }}
                />
              </If>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState({
                    error: null,
                    dialogDeactivate: true,
                  });
                }}
              >
                Désactiver mon annonce
              </Button>
            </div>
          )}
        </Box>
      </Grid>
    );
  };

  validation = () => {
    return (
      <div>
        <ScrollToTopOnMount />
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '120px' }}
        >
          <Grid item sm={12} md={6} align="center">
            <Typography variant="h3">Votre annonce est en cours de validation.</Typography>
            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
              Vous recevrez un email dès qu'elle sera validée.
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} align="center">
            <img src={header_news_intro} />
          </Grid>
          <Grid item xs={12} align="center">
            <Box my={5}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push('/offers');
                }}
              >
                Revenir à l'accueil
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  };

  isOwner = () => {
    const { $$item, $$user } = this.props;
    return $$item.get('offerAccountEncoded') != '' && $$item.get('offerAccountEncoded') == $$user.get('id');
  };

  isDraft = () => {
    const { $$item } = this.props;
    return $$item.get('offerStatus') == 'dra';
  };

  isPreview = () => this.isOwner() && this.isDraft();

  render() {
    const { classes, breadcrumb = true } = this.props;
    const { published } = this.state;
    const isPreview = this.isPreview();
    return (
      <div>
        {isPreview && !published && (
          <Grid item xs={12}>
            <Box mb={2}>
              <Alert severity="info" variant="filled" color="warning">
                <b>Votre annonce n'est pas encore publiée</b>, elle a été enregistrée comme <b>brouillon</b>.<br/>
                Si tout vous semble correct vous pouvez <b>la publier</b>. Une annonce publiée n'est plus modifiable.<br/>
                Cliquez sur « <b>Modifier mon annonce</b> » pour la corriger.<br/>
                Retrouvez <b>vos brouillons</b> dans le menu « <b>Mes annonces</b> » en cliquant sur votre nom d'utilisateur.
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid
          container
          alignContent="center"
          className={isPreview ? classes.mainGridOnPreview : classes.mainGrid}
        >
          {!published && (
            <div>
              <img
                src={details_fond}
                className={clsx(classes.topImage, { [classes.topImageOnPreview]: isPreview })}
              />
            </div>
          )}
          {breadcrumb && (
            <Grid item xs={12} className={classes.breadCrumbs}>
              <Typography variant="subtitle1">
                <Link to="/offers">Annonces</Link>&nbsp;&nbsp;&gt;&nbsp;&nbsp;
                <Prettyfier text="Détails de l'annonce" />
              </Typography>
            </Grid>
          )}
          <div className={clsx(classes.main, { [classes.mainPublished]: published })}>
            {published ? this.validation() : this.mainContent()}
          </div>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(OfferContent));
