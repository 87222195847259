import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import PageHeader from 'components/UI/PageHeader';

const styles = (theme) => ({
  root: {
    width: '100%',
    background: '#F2F2F2',
  },
  content: {
    paddingTop: theme.spacing(4),
    maxWidth: 1200,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
    },
  },
});

class PageWithHeader extends React.Component {
  render() {
    const { classes, title, subtitle, children, style } = this.props;
    return (
      <Box className={classes.root}>
        <PageHeader title={title} subtitle={subtitle} />
        <Box className={classes.content} style={style}>
          {children}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(PageWithHeader);
