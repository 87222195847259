import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import ContactForm from './ContactForm';
import Template from 'components/UI/Template/Template';
import i18n from 'helpers/i18n';
import { getCurrentUser } from 'components/UI/Account/ducks/authentication';
import Immutable from 'immutable';
import queryString from 'query-string';
import HomeHelp from 'components/Home/HomeHelp';

const styles = (theme) => {
  return {
    root: {
      width: '100%',
    },
    pageTitle: {
      paddingTop: 16,
    },

    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      fontWeight: 'bold',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        // height: '160px',
        height: '160px',
        backgroundColor: theme.palette.secondary.dark,
        // backgroundImage: 'url('+require('assets/images/header_contact.jpg')+')',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        zIndex: -1,
      },
    },
    paper: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& a': {
        color: 'inherit',
      },
    },
    requiredFieldsLegend: {
      color: 'red',
    },
  };
};

class Contact extends Component {
  render() {
    const { classes, $$user, showHelp } = this.props;
    const uri = queryString.parse(window.location.search);
    const $$initValues = uri.s && uri.s === '3' ? Immutable.fromJS({ subject: 'rpps' }) : undefined;

    const $$formValues = $$user;
    return (
      <Template current={'contact'} kind={'fullWidth'} maxWidth={'auto'}>
        <Box className={clsx(classes.paperWrapper)}>
          <Typography variant="h1" className={classes.pageTitle}>
            {i18n.get('contactFormTitle')}
          </Typography>{' '}
          <Paper square elevation={0} className={classes.paper}>
            <ContactForm
              userRole={$$user !== undefined ? $$user.get('role') : undefined}
              initValues={$$initValues}
              formNotice={undefined}
              classes={{ requiredFieldsLegend: classes.requiredFieldsLegend }}
              $$formValues={$$formValues}
            />
          </Paper>
          <If condition={showHelp !== false}>
            <HomeHelp showContact={false} />
          </If>
        </Box>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$user: getCurrentUser(state),
  };
  return props;
}

export default withStyles(styles)(connect(mapStateToProps)(Contact));
