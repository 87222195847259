import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator(['CLEAR_ALL_STATE'], { prefix: 'global/' });

export default function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.CLEAR_ALL_STATE:
      return {};

    default:
      return state;
  }
}

export function clearAllState() {
  return {
    type: actionTypes.CLEAR_ALL_STATE,
  };
}
