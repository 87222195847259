import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import Fade from 'react-reveal/Fade';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import config from 'config/api';
import i18n from 'helpers/i18n';
import getCardImage from 'helpers/getCardImage';
import getCardGuests from 'components/UI/helpers/getCardGuests';
import { fetchData, getDataHome, getAvailableEvents, getReplayEvents } from '../../../ducks/asyncFetcher';
import { getUniverse } from 'components/UI/Account/ducks/authentication';
import { fetchEvents } from 'components/UI/Event/ducks/event';
import { formatDate } from 'helpers/stringUtils';
import { toHHMM } from 'components/UI/helpers/time';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import BlockRatio from 'components/BlockRatio';
import imageDefault from 'assets/images/event.png';

const styles = (theme) => {
  return {
    root: {
      height: '100%',
      paddingBottom: theme.spacing(3),
    },
    itemWrapper: {
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
      borderRadius: 5,
      backgroundColor: '#fff',
      height: '100%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '& > div': {
        width: '100%'
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: 0,
      }
    },
    itemContentWrapper: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    itemThumbnailWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    itemThumbnail: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundRepeat: "no-repeat",
      backgroundPosition: '50%',
      backgroundSize: 'cover',
    },
    itemThumbnailVertical: {
    },
    itemTheme: {
      marginBottom: theme.spacing(.5),
      fontSize: '0.75rem',
      lineHeight: '1.5'
    },
    itemTitle: {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    itemDescription: {
      color: theme.palette.secondary.dark,
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    itemDate: {
      width: '100%',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    itemPrecisions: {
      width: '100%',
      marginBottom: theme.spacing(1.5),
      '&:last-of-type': {
        marginBottom: theme.spacing(3),
      }
    },
    itemPrecisionsSpacer: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },

    invisible: {
      visibility: 'hidden'
    },
    liveNavigation: {
      '& .MuiTypography-body1': {
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        fontWeight: 700,
      }
    },
    eventCategory: {
      position: 'relative',
      top: theme.spacing(3),
      left: theme.spacing(3),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderRadius: '25px',
      padding: theme.spacing(1)+'px '+theme.spacing(2)+'px',
      fontWeight: 'bold',
      '&.live': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '&.hemopholie': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '&.immunologie': {
        backgroundColor: theme.palette.secondary.dark,
        color: '#fff',
      },
      '&.transverse': {
        display:'none',
        backgroundColor: '#fff',
        color: theme.palette.secondary.dark,
      }
    },
    eventCode: {
      position: 'absolute',
      fontSize: '.5rem',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderRadius: '25px',
      padding: theme.spacing(.5)+'px '+theme.spacing(1)+'px',
      fontWeight: 'bold',
      lineHeight: '0.5375rem'
    },
    iconPlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
      fontSize: '10rem',
      width: 100,
      height: 100,
      cursor: 'pointer'
    },
    itemGuests: {
      marginBottom: theme.spacing(3),
      '& .MuiTypography-caption': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiTypography-body1': {
        color: theme.palette.secondary.dark,
        fontWeight: 700,
      }
    },
    itemAvatars: {
      marginBottom: theme.spacing(1),
      '& .MuiSvgIcon-root': {
        margin: 0
      },
      '& .MuiAvatar-colorDefault': {
        color: theme.palette.secondary.light,
      }

    },
    itemGuestName: {
      paddingRight: theme.spacing(.4),
      marginRight: theme.spacing(1),
      display: 'inline-block',
      position: 'relative',
      '&:before': {
        content: "','",
        textAlign: 'right',
        position: 'absolute',
        right: 0,
        width: '100%',
        height: '100%'
      },
      '&:last-of-type': {
        paddingRight: 0,
        marginRight: 0,
        '&:before': {
          content: "' '",
        }
      }
    }
  };
};


class EventCard extends Component {

  componentDidMount() {
  }

  componentDidUpdate(oldProps) {
  }


  gotoEvent = (e, $$event) => {
    e.preventDefault();
    const { history, useUniverseInLinks, universe } = this.props;
    history.push(
        (useUniverseInLinks && universe!==undefined ? '/' + universe : '')
         + '/event/' + $$event.get('id'));
  }

  gotoLive = (e, $$event) => {
    e.preventDefault();
    const { history, useUniverseInLinks, universe } = this.props;
    history.push(
        (useUniverseInLinks && universe!==undefined ? '/' + universe : '')
         + '/live/' + $$event.get('id'));
  }

  gotoReplay = (e, $$event, kind) => {
    e.preventDefault();
    const { history, useUniverseInLinks, universe } = this.props;
    if(kind=='other'){
      history.push(
        (useUniverseInLinks && universe!==undefined ? '/' + universe : '')
         + '/video/' + $$event.get('id'));
    }else{
      history.push(
        (useUniverseInLinks && universe!==undefined ? '/' + universe : '')
         + '/replays/' + $$event.get('id'));
    }
  }
  render() {
    const { $$item, itemIndex, classes, kind, variant, isSubscribed, useUniverseDefaultThumb, universe } = this.props;
    const $$guests = getCardGuests($$item);
    const imagePosition = variant ? variant : "horizontal"
    let thumb = getCardImage($$item);
    let defaultBG=true;
    if(thumb===false){
      if(useUniverseDefaultThumb && config.universeDefaultThumb!==undefined){
        if(universe!==undefined && config.universeDefaultThumb[universe]!==undefined)
          thumb=config.universeDefaultThumb[universe];
        else if(config.universeDefaultThumb['default']!==undefined)
          thumb=config.universeDefaultThumb['default'];
          defaultBG=false;
      }else{
        thumb=null;
      }
    }
    let backgroundThumb = '';
    if(thumb!='')
      backgroundThumb+='url('+thumb+')';
    if(config.eventDefaultBackground!==undefined)
      backgroundThumb+=(backgroundThumb!='' ? "," : "")+' '+config.eventDefaultBackground;
    else if(defaultBG)
      backgroundThumb+=(backgroundThumb!='' ? "," : "") +" url("+imageDefault+")";

    //if(config.universeDefaultThumb[universe])
      //), linear-gradient(90deg, #2D5E77 0%, #4CA1A6 100%)'}
      //event:{    background: 'linear-gradient(90deg, #2D5E77 0%, #4CA1A6 100%)',
    const extraCategoryClass = $$item.get('eventCategory') !== undefined ? $$item.get('eventCategory').replace(/é/g, 'e').toLowerCase() : undefined;
    return (
      <Grid item xs={12} container justify="flex-start" alignContent="flex-start" className={classes.root}>
        <Paper className={classes.itemWrapper} elevation={0} element="div">
          <Fade  delay={50*(itemIndex ? itemIndex+1 : 1)} cascade style={{height: '100%'}}>
            <Grid container style={{height: '100%'}} alignContent="flex-start">
              <Grid item xs={imagePosition === 'horizontal' ? 5 : 12} container alignContent={imagePosition !== 'horizontal' ? 'center' : 'flex-start'} style={{position: 'relative'}}>
                <Box className={classes.itemThumbnailWrapper} >
                  <BlockRatio ratio="16_9" style={{height: imagePosition === 'horizontal' ? '100%' : undefined}}>
                    <div className={clsx(classes.itemThumbnail, {
                        [classes.itemThumbnailHorizontal]: imagePosition === 'horizontal' && thumb === false,
                        [classes.itemThumbnailVertical]: imagePosition !== 'horizontal' && thumb === false
                      })} style={{backgroundImage: backgroundThumb}}>
                      <If condition={kind === 'replays'}>
                        <If condition={$$item.get('isLive') === 1}>
                        <Typography variant="caption" className={clsx(classes.eventCategory, 'live')}>{i18n.get('eventStateOnline', {pretty: false})}</Typography>
                        </If>
                        <If condition={$$item.get('isLive') !== 1}>
                        <Typography variant="caption" className={classes.eventCategory}>{i18n.get('eventStatePending', {pretty: false})}</Typography>
                        </If>
                        <If condition={$$item.get('eventCodeReplay') !== undefined && $$item.get('eventCodeReplay') !== '' && $$item.get('eventCodeReplay') !== null}>
                        <Typography variant="caption" className={classes.eventCode}>{$$item.get('eventCodeReplay')}</Typography>
                        </If>

                      </If>
                      <If condition={kind === 'events'}>
                        <Typography variant="caption" className={clsx(classes.eventCategory, extraCategoryClass)}>{$$item.get('eventCategory')}</Typography>
                        <If condition={$$item.get('eventCodeList') !== undefined && $$item.get('eventCodeList') !== '' && $$item.get('eventCodeList') !== null}>
                        <Typography variant="caption" className={classes.eventCode}>{$$item.get('eventCodeList')}</Typography>
                        </If>
                      </If>

                    </div>
                      {/*<If condition={kind === 'replays'}>
                      <IconButton className={classes.iconPlay}  onClick={e => this.gotoReplay(e, $$item)}>
                      <PlayCircleOutlineIcon style={{width: '100%', height: '100%', margin: 0}}/></IconButton>
                      </If>
                      <If condition={kind === 'events' && $$item.get('eventState') === 'live'}>
                      <IconButton className={classes.iconPlay}  onClick={e => this.gotoLive(e, $$item)}>
                      <PlayCircleOutlineIcon style={{width: '100%', height: '100%', margin: 0}}/></IconButton>
                      </If>*/}
                    </BlockRatio>
                  </Box>
                </Grid>
                <Grid item xs={imagePosition === 'horizontal' ? 7 : 12} container alignContent="flex-start" className={classes.itemContentWrapper}>
                  <Typography variant="subtitle1" component="div" gutterBottom className={classes.itemTheme}>
                    <Paragrapher
                      text={$$item.get('themeName')}
                      prettyfy={true}
                      element={'div'}
                      />
                  </Typography>
                  <If condition={kind !== 'replays'}>
                    {/*  <Typography variant="body1" component="div" gutterBottom className={classes.itemDate}>
                    <DateRangeIcon/> {formatDate($$item.get('eventDate'), "dddd dd mmmm yyyy - HH'h'MM")}
                    <If condition={$$item.get('eventDuration') && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== '' && $$item.get('eventDuration') > 0}>
                    {formatDate($$item.get('eventDate')+($$item.get('eventDuration')*60), " - HH'h'MM")}
                    </If>
                    </Typography>*/}
                  </If>
                  <Typography variant="h5" component="div" gutterBottom className={classes.itemTitle}>
                    <Paragrapher
                      text={$$item.get('eventTitle')}
                      elementClassName={classes.content}
                      prettyfy={true}
                      element={'div'}
                      />
                  </Typography>
                  <If condition={$$item.get('eventShortDescription')}>
                    <Typography variant="body1" component="div" gutterBottom className={classes.itemDescription}>
                      <Paragrapher
                        text={$$item.get('eventShortDescription')}
                        elementClassName={classes.content}
                        prettyfy={true}
                        element={'div'}
                        />
                    </Typography>
                  </If>
                  {/*  <If condition={kind !== 'replays'}>
                  <DateRangeIcon/> {formatDate($$item.get('eventDate'), "dddd dd mmmm yyyy - HH'h'MM")}
                  <If condition={$$item.get('eventDuration') && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== '' && $$item.get('eventDuration') > 0}>
                  {formatDate($$item.get('eventDate')+($$item.get('eventDuration')*60), " - HH'h'MM")}
                  </If>
                  </If>*/}
                  <If condition={kind !== 'replays' && kind !== 'other'}>
                    <Typography variant="caption" component="div" gutterBottom className={classes.itemDate}>
                      <DateRangeIcon/> {formatDate($$item.get('eventDate'), "ddd dd mmmm yyyy - HH'h'MM")}
                        <If condition={$$item.get('eventDuration') && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== '' && $$item.get('eventDuration') > 0}>
                          {formatDate($$item.get('eventDate')+($$item.get('eventDuration')*60), " - HH'h'MM")}
                        </If>
                        <If condition={$$item.get('eventDuration') && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== '' && $$item.get('eventDuration') > 0}>
                        </If>
                      </Typography>

                    </If>

                    <If condition={$$item.get('eventDuration') && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== '' && $$item.get('eventDuration') > 0}>
                      <Typography variant="caption" component="div" gutterBottom className={classes.itemPrecisions}>
                        <WatchLaterIcon/> {i18n.get('eventDuration', {pretty: false})} {toHHMM($$item.get('eventDuration')*60)}
                        </Typography>
                      </If>
                      <If condition={$$guests !== false && $$guests.size > 1}>
                        <Grid item xs={12} container>
                          {/*
                            {$$guests.map(($$g, aIdx) => {
                            return (
                            <Grid item xs={12} key={'av_'+aIdx} container >
                            <Grid item xs={3}>
                            <Avatar src={$$g.get('dest')} alt={$$g.getIn(['attributes','name'])}/>
                            </Grid>
                            <Grid item xs={9}>
                            <Typography variant={'caption'} gutterBottom>{i18n.get('eventAnimatedBy',{pretty: false})}</Typography>
                            <Typography variant={'body1'} gutterBottom>{$$g.getIn(['attributes','name'])}</Typography>
                            </Grid>
                            </Grid>)
                            }).toArray()}
                            */}
                            <Box className={classes.itemGuests}>
                              <Typography variant={'caption'} gutterBottom>{i18n.get('eventAnimatedBy',{pretty: false})}</Typography>
                              <Box className={classes.itemAvatars} mt={0.5}>
                                <AvatarGroup max={5} spacing={'medium'}>
                                  {$$guests.map(($$g, aIdx) => {
                                    return (
                                      <Avatar size="small" key={'av_'+aIdx} src={$$g.get('dest')} alt={$$g.getIn(['attributes','name'])}/>
                                    )
                                  }).toArray()}
                                </AvatarGroup>
                              </Box>
                              <Typography variant={'body1'} gutterBottom>
                                {$$guests.map(($$g, aIdx) => {
                                  return <span key={'an_'+aIdx} className={classes.itemGuestName}>{$$g.getIn(['attributes','name'])}</span>
                                }).toArray()}
                              </Typography>
                            </Box>


                          </Grid>
                        </If>
                        <If condition={$$guests !== false && $$guests.size === 1}>
                          <Grid item xs={12} container>
                            <Box className={classes.itemGuests} width={'100%'}>
                              <Avatar size="big" src={$$guests.get(0).get('dest')} alt={$$guests.get(0).getIn(['attributes','name'])} style={{float: 'left', marginRight: 16, width: 66, height: 66}}/>
                              <Typography variant="caption" style={{paddingTop: 9, display: 'block', marginBottom: 0}}>{i18n.get('eventAnimatedBy', {pretty: false})}</Typography>
                              <Typography variant="body1"><strong>{$$guests.get(0).getIn(['attributes','name'])}</strong></Typography>
                            </Box>
                          </Grid>
                        </If>
                        <If condition={kind === 'events'}>
                          <Choose>
                            <When condition={$$item.get('eventState') === 'live'}>
                              <Box className={classes.liveNavigation} mb={2}>
                                <Button className="liveButton" variant="contained" color="primary" onClick={e => this.gotoLive(e, $$item)} >{i18n.get('liveAction')}</Button> <Typography color="primary" variant="body1">{i18n.get('liveEventIsStarted')}</Typography>
                              </Box>
                            </When>
                            {/*<When condition={$$item.get('eventState') !== 'live' && $$subscriptions.find(id => id === $$item.get('id'))}>
                            <Box className={classes.liveNavigation}>
                            <Button variant="contained" color="primary" onClick={e => this.gotoLive(e, $$item)} disabled={true}>{i18n.get('subscriptionAlreadyExists',{pretty: false})}</Button>
                            </Box>
                            </When>*/}
                            <When condition={isSubscribed === true}>
                              <Box className={classes.liveNavigation} mb={2}>
                                <Button variant="contained" color="primary" onClick={e => this.gotoEvent(e, $$item)} >{i18n.get('subscriptionShowProgram')}</Button> <Typography variant="body1">{i18n.get('subscriptionAlreadyExists')}</Typography>
                              </Box>
                            </When>
                            <Otherwise>
                              <Box className={classes.liveNavigation} mb={2}>
                                <Button className={"go_to_event go_to_event_"+$$item.get('id')} variant="contained" color="primary" onClick={e => this.gotoEvent(e, $$item)}>{i18n.get('subscribeAction')}</Button>
                              </Box>
                            </Otherwise>
                          </Choose>
                        </If>
                        <If condition={kind === 'replays' || kind === 'other' }>
                          <Box className={classes.liveNavigation} mb={2}>
                            <If condition={$$item.get('isLive') === 1}>
                            <Button variant="contained" color="primary" onClick={e => this.gotoReplay(e, $$item, kind)}>{i18n.get('replayAction')}</Button>
                            </If>
                            <If condition={$$item.get('isLive') !== 1}>
                              <Typography variant="body1" color="primary">
                              {i18n.get('eventStatePending', {pretty: false})}
                              </Typography>
                            </If>
                          </Box>
                        </If>
                      </Grid>
                    </Grid>
                  </Fade>
                </Paper>
              </Grid>
            );
          }
        }

function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    $$availableEvents: getAvailableEvents(state),
    $$replayEvents: getReplayEvents(state),
    universe: getUniverse(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      dispatch(fetchData({ key: 'home', fetchUrl: config.home }));
      dispatch(fetchEvents({ kind: props.kind }));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventCard)));
