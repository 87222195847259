import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { setFilterValue, getFilters } from './ducks/offer';

const styles = (theme) => {
  return {
    card: {
      '& .MuiDialog-paper': {
        borderRadius: '15px',
      },
      '& .MuiDialogTitle-root .MuiTypography-root': {
        color: 'rgb(10, 86, 154)',
        fontSize: '200%',
      },
      '& .MuiFormLabel-root': {
        marginBottom: '13px',
        color: 'rgb(10, 86, 154)',
        fontWeight: 'bold',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  };
};

const defaultFilters = {
  //offerType: 'Association',
  offerPracticeMode: null,
  offerDomain: [],
};

class OfferFilterDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultFilters;
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (props.open && !prevProps.open) {
      const { $$filters } = props;
      const newState = {};
      Object.keys(this.state).forEach((key) => {
        newState[key] = $$filters.get(key) || defaultFilters[key];
      });
      this.setState(newState);
    }
  }

  handleChange = (event) => {
    const newState = {};
    newState[event.target.name] = event.target.value;
    //console.log('handleChange', event.target.name, event.target.value);
    this.setState(newState);
  };

  handleButton = (name, value) => {
    const newState = {};
    newState[name] = value;
    //console.log('handleButton', name, value);
    this.setState(newState);
  };

  handleButtonMultiple = (name, value) => {
    const newState = {};
    if (!name in this.state) {
      newState[name] = [value];
    } else {
      //toggle value in array
      const newValues = [...this.state[name]];
      const index = newValues.indexOf(value);
      if (index != -1) newValues.splice(index, 1);
      else newValues.push(value);
      newState[name] = newValues;
    }
    this.setState(newState);
    //console.log('handleButtonMultiple', name, value, newState[name]);
  };

  handleSubmit = () => {
    const { setFilterValue, onClose } = this.props;
    //console.log('handleSubmit', this.state);

    Object.keys(this.state).forEach((key) => {
      setFilterValue(key, this.state[key]);
    });
    onClose();
  };

  render() {
    const { open, onClose, classes, $$filters, $$selects } = this.props;
    const { type } = this.state;

    const categoryFilter = $$filters.get('offerCategory');
    const domains =
      $$selects && $$selects.get('values') && $$selects.get('values').get('offerDomain')
        ? $$selects.get('values').get('offerDomain').toJS()
        : {};
    const practiceModes =
      $$selects && $$selects.get('values') && $$selects.get('values').get('offerPracticeMode')
        ? $$selects.get('values').get('offerPracticeMode').toJS()
        : {};
    const offerTypes =
      $$selects && $$selects.get('values') && $$selects.get('values').get('offerType')
        ? $$selects.get('values').get('offerType').toJS()
        : {};
    return (
      <Dialog onClose={onClose} open={open} fullWidth maxWidth="md" className={classes.card}>
        <DialogTitle>Filtres</DialogTitle>
        <DialogContent>
          {categoryFilter === 'inst' && (
            <Box p={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Type</FormLabel>
                <RadioGroup row name="offerType" value={this.state.offerType}>
                  {Object.keys(offerTypes).map((key) => (
                    <FormControlLabel
                      value={key}
                      control={<Radio />}
                      label={offerTypes[key]}
                      onChange={this.handleChange}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          )}

          <Box p={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Compétences</FormLabel>
              <ToggleButtonGroup name="offerDomain" value={this.state.offerDomain}>
                {Object.keys(domains).map((key) => (
                  <ToggleButton
                    key={key}
                    value={key}
                    name="offerDomain"
                    onClick={() => {
                      this.handleButtonMultiple('offerDomain', key);
                    }}
                  >
                    {domains[key]}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </FormControl>
          </Box>

          <Box p={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Mode d'exercice</FormLabel>
              <ToggleButtonGroup name="offerPracticeModes" value={this.state.offerPracticeMode}>
                {Object.keys(practiceModes).map((key) => (
                  <ToggleButton
                    key={key}
                    value={key}
                    onClick={() => {
                      this.handleButton('offerPracticeMode', key);
                    }}
                  >
                    {practiceModes[key]}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary" variant="outlined">
            Annuler
          </Button>
          <Button onClick={this.handleSubmit} color="primary" variant="contained">
            Filtrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  $$filters: getFilters(state),
});
const mapDispatchToProps = { setFilterValue };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OfferFilterDialog));
