import Immutable from 'immutable';
import mirrorCreator from 'mirror-creator';
import pipe from 'helpers/redux-state-mutators.js';
import { createSelector } from 'reselect';
import { connectToIOServer, disconnectFromIOServer } from 'components/UI/Socket/io';
import { setQuizOptions } from 'components/UI/Quiz/ducks/quiz';
import getFetchOptions from "helpers/getFetchOptions"
import config from "config/api";

let USE_WEBSOCKET;

const debug = true;

const actionTypes = mirrorCreator(
  [
    'EVENT_STATE_SET',
    'EVENT_STATE_CLEAR',
    'ADMIN_EVENT_STATE_SET',
    'ADMIN_EVENT_STATE_CLEAR',
    'EVENT_STATE_SET_ERROR',
    'EVENT_STATE_CLEAR_ERROR',
    'CONTENT_LOAD',
    'CONTENT_SET_OPTION',
  ],
  {
    prefix: 'relay/',
  },
);

const mutators = {
  contentSet: (contentType, contentId) => $$state => $$state.setIn(['content'], Immutable.fromJS({contentType, contentId, options: {}})),
  contentSetOption: (name, value) => $$state => $$state.setIn(['content', 'options', name], value),
  eventStateSet: (eventId, content) => $$state => $$state.setIn(['event'], Immutable.fromJS(content)),
  eventStateClear: (eventId) => $$state => $$state.delete('event'),
  eventStateSetError: (error) => $$state => $$state.set('error', error),
  eventStateClearError: () => $$state => $$state.delete('error'),
};

let $timer;

export default function reducer($$state = Immutable.Map(), action) {
  switch (action.type) {
    case actionTypes.CONTENT_LOAD:
    // console.log('should load ', action.index)
    return pipe(
      [mutators.contentSet(action.contentType, action.contentId)],
      $$state,
    );
    case actionTypes.CONTENT_SET_OPTION:
    // console.log('should load ', action.index)
    return pipe(
      [mutators.contentSetOption(action.name, action.value)],
      $$state,
    );

    case actionTypes.EVENT_STATE_SET:
    // console.log('should load ', action.index)
    return pipe(
      [mutators.eventStateSet(action.eventId, action.content)],
      $$state,
    );

    case actionTypes.EVENT_STATE_CLEAR:
    // console.log('should load ', action.index)
    return pipe(
      [mutators.eventStateClear(action.eventId)],
      $$state,
    );

    case actionTypes.EVENT_STATE_SET_ERROR:
    // console.log('should load ', action.index)
    return pipe(
      [mutators.eventStateSetError(action.error)],
      $$state,
    );

    case actionTypes.EVENT_STATE_CLEAR_ERROR:
    // console.log('should load ', action.index)
    return pipe(
      [mutators.eventStateClearError()],
      $$state,
    );

    case actionTypes.ADMIN_EVENT_STATE_SET:
    // console.log('should load ', action.index)
    return pipe(
      [mutators.adminEventStateSet(action.eventId, action.content)],
      $$state,
    );

    case actionTypes.ADMIN_EVENT_STATE_CLEAR:
    // console.log('should load ', action.index)
    return pipe(
      [mutators.adminEventStateClear(action.eventId)],
      $$state,
    );

    default:
    return $$state;
  }
}

export const getRoot = state => state.relay;
export const getContent = createSelector(
  getRoot,
  $$root => $$root.get('content', undefined),
);

export const getEventState = createSelector(
  getRoot,
  $$root => $$root.get('event', undefined),
);

export const getAdminEventState = createSelector(
  getRoot,
  $$root => $$root.get('event', undefined),
);

let socket;
export const sendMessage = message => () => {
  if (USE_WEBSOCKET !== true) return;
  if (socket === undefined) {
    console.error('No socket (not existing)');
    return;
  } else if (socket.diconnected) {
    console.error('No socket (disconnected)');
    return;
  }
  if (debug) console.log('sending message', message);
  socket.emit('message', message);
};

export const handleSocketMessage = message => async (dispatch, getState) => {
  console.log('received message', message);
  const { type } = message;
  if (type === 'contentLoad') {
    // dispatch({
    //   type: actionTypes.CONTENT_LOAD,
    //   contentType: message.payload.contentType,
    //   contentId: message.payload.contentId,
    // });
    dispatch({
      type: actionTypes.EVENT_STATE_SET,
      content: {
        contentType: message.payload.contentType,
        contentId: message.payload.contentId,
        contentRevision: message.payload.contentRevision,
      }
    });
  } else if (type === 'contentOption') {
    const content = getEventState(getState())
    const {name,value} = message.payload;
    switch(content.get('contentType')) {
      case 'quiz':
      dispatch(setQuizOptions(name, Immutable.fromJS(value)));
      break;
      default:
      console.log('unknown content type!')
      break;
    }
  }
};

export function setEventState({eventId, contentId, contentType}) {
  // export const setEventState = ({eventId, contentId, contentType}) => async (dispatch, getState) => {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.orchestrator+'/state/set/'+eventId
    let formData = {contentId, contentType, event_state_form: true};

    fetch(url, getFetchOptions('POST', formData))
    .then((response) => response.json())
    .then((response) => {
      // console.log('RESPONSE IS', response)
      if (response.state && response.state === "complete") {
        // dispatch(eventStateSet({eventId, contentId, contentType}));
        dispatch(sendMessage({
          type: 'contentLoad',
          contentType: response.data.contentType,
          contentId: response.data.contentId,
          contentOptions: response.data.contentOptions
        }))

      } else {
        dispatch(eventStateSetError(response.log));
      }
    })
    .catch((error) => dispatch(eventStateSetError(error.message)))
  };
}
export function setAdminEventState({eventId, contentId, contentType}) {
  // export const setEventState = ({eventId, contentId, contentType}) => async (dispatch, getState) => {
  // console.log({login, password})
  return (dispatch) => {
    // let url = config.orchestrator+'/state/set/'+eventId
    let url = config.quiz+'/get/'+eventId+'/?q='+contentId
    // http://sobi.lan.new-net.net:35080/france/api/orchestrator/quiz/get/7b1cd150910204cacb7bc82d2bb13cfc/?q=99b7b4fdee89c1c0a41cce9dbb41a2c2
    let formData = {contentId, contentType, event_state_form: true};

    fetch(url, getFetchOptions('POST', formData))
    .then((response) => response.json())
    .then((response) => {
      // console.log('RESPONSE IS', response)
      if (response.state && response.state === "complete") {
        // dispatch(eventStateSet({eventId, contentId, contentType}));
        // dispatch(sendMessage({
        //   type: 'contentLoad',
        //   contentType: response.data.contentType,
        //   contentId: response.data.contentId,
        //   contentOptions: response.data.contentOptions
        // }))
        dispatch(adminEventStateSet({
          eventId,
          content: {
            contentType: response.data.contentType,
            contentId: response.data.contentId,
            contentOptions: response.data.contentOptions
          }
        }))

      } else {
        dispatch(eventStateSetError(response.log));
      }
    })
    .catch((error) => dispatch(eventStateSetError(error.message)))
  };
}
export function fetchEventState({eventId}) {
  // console.log({login, password})
  return async (dispatch, getState) => {
    let url = config.orchestrator+'/state/get/'+eventId

    fetch(url, getFetchOptions('GET'))
    .then((response) => response.json())
    .then((response) => {
      // console.log('RESPONSE IS', response)
      if (response.state && response.state === "complete") {
        const $$content = getEventState(getState())
        if ($$content === undefined || $$content.get('contentId') !== response.data.contentId || $$content.get('contentRevision') !== response.data.contentRevision) {

          // console.log('changed!')
          dispatch(eventStateSet({eventId, content: {
            contentId: response.data.contentId,
            contentType: response.data.contentType,
            contentRevision: response.data.contentRevision,
          }
        }));
      } else {
        // console.log('no change', $$content.toJS(), response.data)
      }
    } else {
      dispatch(eventStateSetError(response.log));
    }
  })
  .catch((error) => dispatch(eventStateSetError(error.message)))
};
}


// export function fetchBackendData({eventId}) {
//   // console.log({login, password})
//   return (dispatch) => {
//     let url = config.orchestrator+'/state/get/'+eventId
//
//     fetch(url, getFetchOptions('GET'))
//       .then((response) => response.json())
//       .then((response) => {
//         // console.log('RESPONSE IS', response)
//         if (response.state && response.state === "complete") {
//           dispatch(eventStateSet({eventId, contentId: response.data.contentId, contentType: response.data.contentType}));
//         } else {
//           dispatch(eventStateSetError(response.log));
//         }
//       })
//       .catch((error) => dispatch(eventStateSetError(error.message)))
//   };
// }



export const connectToRelay = ({ endpoint, $$event, $$user, pull }) => dispatch => {

  const onSocketMessage = async (Socket, message) => {
    socket = Socket;
    dispatch(handleSocketMessage(message));
  };

  const onSocketDisconnected = Socket => {
    // dispatch({
    //   type: actionTypes.CLEAR_ID,
    // });
  };

  USE_WEBSOCKET = pull !== true ? true : false;
  if (pull !== true)
  connectToIOServer({endpoint, $$event, $$user, onSocketMessage, onSocketDisconnected});
  else {
    // console.log(endpoint)
    const isAdmin = $$user.get('role') === 'admin' || $$user.get('role') === 'speaker'
    ||$$user.get('role') === 'staff';
    const interval = isAdmin && endpoint !== 'participant' ? 2000 : 5000;
    // console.log(isAdmin, endpoint, interval)
    $timer = setInterval(() => {
    // console.log('FETCH')
    dispatch(fetchEventState({eventId: $$event.get('id')}))
  }, interval)
  }
};

export const disconnectFromRelay = ({pull}) => dispatch => {
  // dispatch({
  //   type: actionTypes.CLEAR_ID,
  // });
  if (pull !== true)
  disconnectFromIOServer();
  else {
    if ($timer)
    clearInterval($timer)
  }
};
export function eventStateSet({eventId, content}) {
  return {
    type: actionTypes.EVENT_STATE_SET,
    eventId, content
  };
}
export function eventStateSetError({error}) {
  return {
    type: actionTypes.EVENT_STATE_SET_ERROR,
    error,
  };
}
export function eventStateClear({eventId}) {
  return {
    type: actionTypes.EVENT_STATE_CLEAR,
    eventId
  };
}
export function adminEventStateSet({eventId, content}) {
  return {
    type: actionTypes.ADMIN_EVENT_STATE_SET,
    eventId, content
  };
}
export function adminEventStateClear({eventId}) {
  return {
    type: actionTypes.ADMIN_EVENT_STATE_CLEAR,
    eventId
  };
}
