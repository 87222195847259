import React from 'react';
import PropTypes from 'prop-types';

export default function withModuleConfig(subComponent) {
  const superComponent = function (props, context) {
    const { moduleConfig } = context;
    const newProps = {
      ...props,
      moduleConfig,
    };
    return React.createElement(subComponent, newProps);
  };

  superComponent.contextTypes = {
    moduleConfig: PropTypes.object,
  };

  return superComponent;
}
