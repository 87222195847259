import React from "react";
// import Icon from "components/Icon";
// import Grid from '@material-ui/core/Grid';
// import Avatar from '@material-ui/core/Avatar';
// import ListItem from "@material-ui/core/List";
// import MenuItem from "@material-ui/core/MenuItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
// import Paragrapher from "components/UI/Paragrapher/Paragrapher";
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Alert from "@material-ui/lab/Alert";

import { withStyles } from "@material-ui/core/styles";
const styles = {
  alert: {
    "& svg": {
      alignSelf: "center",
    },
    '& a': {
      color: 'inherit'
    }
  },
};
const Logger = ({ className, primary, style, icon, classes, status }) => {
  return (
    <Alert severity={status} className={classes.alert}>
      {primary}
    </Alert>
  );
};

export default withStyles(styles)(Logger);
