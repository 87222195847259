import React from "react";
import {
  connect
} from "react-redux";
import {
  Redirect,
  withRouter
} from "react-router-dom";
import {
  getCurrentUserRole
} from "components/UI/Account/ducks/authentication";
// import Control from "components/UI/Quiz/AdminQuizControl";
import Form from "components/UI/Quiz/AdminQuizForm";
// import List from "components/UI/Quiz/AdminQuizList";
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import config from 'config/api';

// import NoMatch from 'components/NoMatch';
import Template from 'components/UI/Template/Template'
const AdminQuiz = (props) => {
  const {
    match,
    userRole,
    location
  } = props;
  // console.log(match)
  if(userRole !== undefined && userRole !== "admin")
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location },
        }}
      />
    );
  return (
    <Template maxWidth='auto' current="admin" headerPosition={'relative'} headerImage={config.template && config.template.admin && config.template.admin.background ? config.template.admin.background : undefined}>
      <If condition={userRole === 'admin'}>
        <If condition={match.params.action === "edit"}>
          <Form id={match.params.id} eventId={match.params.eventId} />
        </If>
        </If>
    </Template>
  );
};

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}
export default withRouter(connect(mapStateToProps)(AdminQuiz));
