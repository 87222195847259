import React from 'react';
import { connect } from 'react-redux';
// import config from 'config/api';
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Form from 'components/UI/Form/Form'
import Input from 'components/UI/Form/Input'
// import DynamicForm from 'components/UI/DynamicForm'
import i18n from 'helpers/i18n'
import Dialog from 'components/UI/Dialog/Dialog'
import {
  destroyAccount,
  getError,
} from 'components/UI/Account/ducks/authentication';

class AccountDeleteForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      popinState : false,
      currentPassword: undefined,
      log: false,
    }

  }
  componentDidMount() {
    this._setupState();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.$$error !== this.props.$$error && this.props.$$error !== undefined) {
      this.log(this.props.$$error)
    }
  }
  componentWillUnmount() {
    if (this.$timer) {
      clearTimeout(this.$timer)
      delete this.$timer;
    }
  }
  _getI18n() {
    this.i18n = {
      send: 'Enregistrer',
      sending: 'Enregistrement...'
    }
  }

  onPopinOpen = () => {
    this.setState({popinState: true})
  }

  onPopinClose = () => {
    this.setState({popinState: false})
  }
  onPasswordChange = (kind, o) => {
    this.setState({currentPassword: o.value})
  }
  onSubmit = () => {
    const {currentPassword} = this.state;
    if (currentPassword === '' || currentPassword === undefined) {
      this.log(i18n.get('accountDeleteMustSpecifyPassword', {'pretty': false}))
      return;
    }
    this.props.onDestroyAccount({password:currentPassword})
  }
  log = (message) => {
    this.setState({log: message}, () => {
      this.$timer = setTimeout(() => {
        this.setState({log: false})
      }, 1000*4)
    })
  }

  render() {
    // const {
    //   $$error
    // } = this.props;
    const {
      popinState,
      currentPassword,
      log,
    } = this.state;
    // console.log('error is', $$error)
    return (
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={() => this.onPopinOpen()}>{i18n.get('accountDeleteButton', {pretty: false})}</Button>

        <Dialog closeButton={false} disableBackdropClick={true} open={popinState}>
          <Box mb={2} align="center">
            <Typography variant="h2" gutterBottom>{i18n.get('accountDeleteTitle')}</Typography>
            <Typography variant="body1" gutterBottom>{i18n.get('accountDeleteText')}</Typography></Box>
            <Box maxWidth="400px" align="middle" mx="auto" mb={3}>
              <Input id="email" name="email" type="password" variant="outlined" label={i18n.get('accountCurrentPassword')} value={currentPassword} onChange={this.onPasswordChange}/>
              <If condition={log !== false}>
                <Box mt={2}>
                  <Typography variant="body1" color="error">{log}</Typography>
                </Box>
              </If>

            </Box>
            <Box align="middle" mb={3}>
              <Button variant={'contained'} onClick={this.onPopinClose} style={{marginRight: 5}}>{i18n.get('formCancel')}</Button>
              <Button color="primary" variant={'contained'} onClick={this.onSubmit} style={{marginLeft: 5}}>{i18n.get('accountDeleteBtConfirm')}</Button>
            </Box>
          </Dialog>
        </React.Fragment>
      );

    }
  }

  const mapStateToProps = ($$state) => {
    return {
      $$error: getError($$state),
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      onDestroyAccount: (props) => {
        dispatch(destroyAccount(props));
      },
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(AccountDeleteForm);
