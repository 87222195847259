import config from "config/api";

export default function getThumb(module, id, thumbField, background) {
  //console.log("getThumb", module, id, thumbField)
  const file=thumbFile(thumbField);
  if(file!==null){
    const path=thumbPath(module, id, file);
    if(background!==undefined)
      return "url(" + path + ")"+(background!==true && background!='' ? ", "+background : '');
    else
      return path;
  }
  if(background!==undefined && background!==true && background!='')
    return background;
  else
    return null;
}

export function thumbFile(thumbField) {
  const res=thumbsFiles(thumbField);
  if(res[0]!==undefined)
    return res[0];
  return null;
}

export function thumbPath(module, id, file) {
  return config.baseUrl + "/cache/"+module+"/" + id + "/" + file;
}

export function thumbsFiles(thumbField) {
    let res = [];
    if (thumbField===undefined || thumbField == "")
      return res;
    let decodedValues;
    try {
      decodedValues = JSON.parse(thumbField);
    } catch (e) {}
    if (decodedValues !== undefined && Array.isArray(decodedValues)) {
      decodedValues.forEach((v) => {
        if (v && v.dest)
          res.push(v.dest);
      });
    }
    return res;
  };
