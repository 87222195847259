//AJOUT B
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withRouter, Redirect } from 'react-router-dom';

const styles = (theme) => {
  return {
    root: {
        padding:10,
      },
    legend: {
      marginBottom:20,
    },
    buttonMyAccount: {
      marginLeft: 10,
      '&.secondary': {
        backgroundColor: theme.palette.secondary.dark
      },
      [theme.breakpoints.down('sm')]: {
      },
      marginTop: 0,
      '& .MuiButton-label': {
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 5,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
      },

    },
  };
};

class LoginRequired extends Component {

  render() {
    const {
      classes,
      message,
    } = this.props;

      return (
        <Box mt={2} className={classes.root} align="center" width="100%">
            <Box my={6} align="center">
              <If condition={message!==undefined}>
                <Typography
                    variant="h5"
                    gutterBottom={true}
                    className={classes.legend}
                    >
                    {message}
                </Typography>
              </If>
            <Button color="primary" variant="contained" className={classes.buttonMyAccount} 
              onClick={(e) =>
                this.props.history.push(
                    "/login?_pn=" +
                    encodeURIComponent(window.location.pathname)
                )
                }
              >Connexion</Button>
              {' '}
              {/*
              <Button color="primary" variant="contained" className={classes.buttonMyAccount} 
                onClick={(e) => this.props.history.push('/registration?_pn='+encodeURIComponent(window.location.pathname+'/'+window.location.search))}
                >
                {' '}
                Créer un compte
            </Button>
              */}
            </Box>
        </Box>
      );
}
}

export default withRouter((withStyles(styles)(LoginRequired)));
