import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';
import { getUniverse } from 'components/UI/Account/ducks/authentication'

const actionTypes = mirrorCreator(['SET_NEWS_ERROR', 'SET_NEWS_DATA', 'CLEAR_NEWS'], { prefix: 'news/' });

const mutators = {
  setNewsData: (data) => ($$state) => $$state.set('news', data),
  setNewsError: (error) => ($$state) => $$state.set('error', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_NEWS_DATA:
      acts.push(mutators.setNewsData(action.data));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_NEWS_ERROR:
      return pipe([mutators.setNewsError(action.error)], $$state);

    case actionTypes.CLEAR_NEWS:
      return pipe([mutators.setNewsError(undefined), mutators.setNewsData(undefined)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.news || Immutable.Map();
export const getNewsData = createSelector([getRoot], ($$state) => $$state.get('news'));
export const getNewsError = createSelector([getRoot], ($$state) => $$state.get('error'));

export function fetchNews() {
  return (dispatch, getState) => {
    let url = config.news + '/get/all';
    const universe = getUniverse(getState());
    if (universe !== undefined) url += '?universe='+universe

    dispatch(clearNews());

    let formData = {};
    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.news && response.news === 'complete') {
          dispatch(setNewsData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setNewsError(response.log));
        }
      })
      .catch((error) => {
        dispatch(setNewsError(error.message))
      });
  };
}
export function setNewsData(data) {
  return {
    type: actionTypes.SET_NEWS_DATA,
    data,
  };
}

export function setNewsError(error) {
  return {
    type: actionTypes.SET_NEWS_ERROR,
    error,
  };
}
export function clearNews() {
  return {
    type: actionTypes.CLEAR_NEWS,
  };
}
