import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from 'config/api'
// import Prettyfier from 'components/UI/Prettyfier';
// import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
// import Header from 'components/Header/Header';
// import Footer from 'components/Footer';
// import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
// import BlockTitle from 'components/UI/BlockTitle';
// import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// import Template from "components/UI/Template/Template";
import i18n from "helpers/i18n";
import { Link } from 'react-router-dom';

import {
  fetchData,
  getData,
  getLoadingError,
} from 'ducks/asyncFetcher';

const styles = (theme) => {
  return {
    root: {
      width: '100%',
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    breadCrumbs: {
      'a': {
        display: 'inline'
      }
    },
    dashboardWrapper: {
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      boxShadow: theme.shadows[6],
      "& .MuiTypography-h5": {
        // marginTop: theme.spacing(3),
      },
      "& .MuiTypography-h6": {
        fontSize: "1.1em",
        display: 'inline-flex',
        '& a': {
          color: 'inherit'
        }

      },
    }
  };
};

class DashboardIndex extends Component {
  componentDidMount() {
    const {page} = this.props;
    this.props.onMount({page});
  }
  render() {
    const { $$data, classes } = this.props;
    const $$dashboardData = $$data !== undefined && $$data.get('dashboard') !== undefined ? $$data.get('dashboard').get('stats') : undefined;
    return (

      <Grid container spacing={4} className={classes.root}>

        <Grid item xs={12}>
          <Box className={classes.breadCrumbs}>
            Dashboard
          </Box>
        </Grid>

        <If condition={$$dashboardData !== undefined}>
          <Grid item xs={12} sm={6} container justifyContent="center">
            <Box className={classes.dashboardWrapper}>
              <Typography variant="h5" gutterBottom>{i18n.get('dashboardStatsUsersTitle', {pretty: false})}</Typography>
              <div><Typography variant="h6" gutterBottom>{i18n.get('dashboardStatsTotal', {pretty: false})}:</Typography> <span className={classes.value}>{$$dashboardData.get('users').get('total')}</span></div>
              {$$dashboardData.get('users').get('byJobs').map(($$item, jIdx) => {
                return (<div key={'j_'+jIdx}><Typography variant="h6" gutterBottom>{$$item.get('label')}:</Typography> <span className={classes.value}>{$$item.get('value')}</span></div>)}).toArray()}
                <hr />
                  <div><Typography variant="h6" gutterBottom><Link to={'/dashboard/account/list?ft=filter_1&fv=pen&'}>{i18n.get('dashboardStatsPending', {pretty: false})}:</Link></Typography> <span className={classes.value}>{$$dashboardData.get('users').get('pending')}</span></div>

              </Box>
            </Grid>
            <Grid item xs={12} sm={6} container justifyContent="center">
              <Box className={classes.dashboardWrapper}>
                <Typography variant="h5" gutterBottom>{i18n.get('dashboardStatsEventsTitle', {pretty: false})}</Typography>
                <div><Typography variant="h6" gutterBottom>{i18n.get('dashboardStatsTotal', {pretty: false})}: </Typography> <span className={classes.value}>{$$dashboardData.get('events').get('total')}</span></div>
                {$$dashboardData.get('events').get('byState').map(($$item, jIdx) => {
                  return (<div key={'j_'+jIdx}><Typography variant="h6" gutterBottom>{$$item.get('label')}:</Typography> <span className={classes.value}>{$$item.get('value')}</span></div>)}).toArray()}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="center">
                <Box className={classes.dashboardWrapper}>
                  <Typography variant="h5" gutterBottom>{i18n.get('dashboardNavigationTitle', {pretty: false})}</Typography>
                  <div><Typography variant="h6" gutterBottom><Link to="/dashboard/account/list">{i18n.get('dashboardNavigationAccounts', {pretty: false})}</Link> </Typography></div>
                  <div><Typography variant="h6" gutterBottom><Link to="/dashboard/event/list">{i18n.get('dashboardNavigationEvents', {pretty: false})}</Link> </Typography></div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="center">
                <Box className={classes.dashboardWrapper}>
                  <Typography variant="h5" gutterBottom>{i18n.get('dashboardNavigationStats', {pretty: false})}</Typography>
                  {/*<div><Typography variant="h6" gutterBottom><Link to="/dashboard/stats/exercise">{i18n.get('dashboardNavigationStatsExercise', {pretty: false})}</Link> </Typography></div>*/}
                  <div><Typography variant="h6" gutterBottom><Link to="/dashboard/stats/jobs">{i18n.get('dashboardNavigationStatsJobs', {pretty: false})}</Link> </Typography></div>
                  <div><Typography variant="h6" gutterBottom><Link to="/dashboard/stats/speciality">{i18n.get('dashboardNavigationStatsSpeciality', {pretty: false})}</Link> </Typography></div>
                  {/*<div><Typography variant="h6" gutterBottom><Link to="/dashboard/stats/locations">{i18n.get('dashboardNavigationStatsLocations', {pretty: false})}</Link> </Typography></div>*/}
                  <div><Typography variant="h6" gutterBottom><Link to="/dashboard/stats/subscriptions">{i18n.get('dashboardNavigationStatsSubscriptions', {pretty: false})}</Link> </Typography></div>
                  <div><Typography variant="h6" gutterBottom><Link to="/dashboard/stats/registrations">{i18n.get('dashboardNavigationStatsRegistrations', {pretty: false})}</Link> </Typography></div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="center">
                <Box className={classes.dashboardWrapper}>
                  <Typography variant="h5" gutterBottom>{i18n.get('dashboardNavigationExports', {pretty: false})}</Typography>
                  {$$data.get('dashboard').get('exports').map(($$export, eIdx) => {
                    return (
                      <div key={'exports_'+eIdx}><Typography variant="h6" gutterBottom><Link to={'/dashboard/exports/'+$$export.get('url')}>{$$export.get('label')}</Link> </Typography></div>
                    )
                  }).toArray()}
                </Box>
              </Grid>
            </If>
          </Grid>

        );
      }
    }

    function mapStateToProps(state) {
      const props = {
        $$data: getData(state),
        error: getLoadingError(state),
      };
      return props;
    }
    function mapDispatchToProps(dispatch) {
      return {
        onMount: ({page}) => {dispatch(fetchData( {key: 'dashboard', fetchUrl: config['dashboard']}))}
      };
    }
    // export default Mission;
    export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardIndex));
