import React, { Component } from "react";
// import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
// import getCardImage from 'helpers/getCardImage'
import { connect } from "react-redux";
// import config from "config/api";
import Button from "@material-ui/core/Button";
// import Container from "@material-ui/core/Container";
// import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import BlockTitle from "components/UI/BlockTitle";
import Rating from "@material-ui/lab/Rating";
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import Input from "components/UI/Form/Input";
import { withRouter } from "react-router-dom";
import {
  fetchFeedback,
  getFeedbackData,
  getFeedbackAnswered,
  // getFeedbackError,
  cleanupFeedback,
  sendFeedback,
} from "./ducks/feedback";
// import { fetchData, getDataHome, getLoadingError } from "ducks/asyncFetcher";
import { getDataHome, getLoadingError } from "ducks/asyncFetcher";
import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
} from "components/UI/Account/ducks/authentication";
import i18n from "helpers/i18n";
const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      // maxWidth: "1280px",
      margin: "20px auto",
      // minHeight: 300,
      padding: "0 10px",
      // width: 600,
      // width: 600,
      width: "100%",
      textAlign: "left",
      // maxWidth: 420,
      [theme.breakpoints.down("md")]: {
        // width: 420,
      },
      [theme.breakpoints.down("sm")]: {
        // width: 300,
      },
      '& .MuiRating-root': {
        marginBottom: theme.spacing(2)
      },
      '& .MuiTypography-h4': {
        fontSize: '1.4rem'
      }
    },
    fullWidth: {
      width: "100%",
    },
    headerBg: {
      // minHeight: 210,
      // backgroundImage: "url(" + require("assets/images/header.jpg") + ")",
      backgroundPosition: "0 0",
      [theme.breakpoints.down("sm")]: {
        backgroundImage: "none",
      },
    },
    mainWrapper: {
      fontSize: "14px",
    },
    mainTitle: {
      marginBottom: 40,
      textAlign: "center",
    },
    livePaper: {
      color: theme.palette.common.black,
      maxWidth: 1280,
      margin: "30px auto",
      padding: "40px 20px 50px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 20px",
      },
    },
    inline: {
      display: "inline-block",
    },
    header: {
      // height: 150,
      // [theme.breakpoints.down("sm")]: {
      //   height: 200
      // }
    },
    content: {
      // height: 'calc(100% - 150px - 120px)',
      // [theme.breakpoints.down("sm")]: {
      // height: 'auto'
      // }
    },
    regularHeight: {
      height: "auto",
    },
    footer: {
      width: "100%",
      // height: 120,
      // [theme.breakpoints.down("sm")]: {
      //   height: 'auto'
      // }
    },
  };
};
const labels = {
  1: "Très peu satisfait",
  2: "Peu satisfait",
  3: "Satisfait",
  4: "Très satisfait",
  5: "Excellent !",
};
class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logMessage: false,
      errored: false,
    };
    // this._onFeedbackChange = this._onFeedbackChange.bind(this);
    // this._onFeedbackHover = this._onFeedbackHover.bind(this);
    // this._onFormSubmit = this._onFormSubmit.bind(this);
  }
  componentDidMount() {
    // const liveId = this.props.match.params.id;
    // console.log(this.props)
    const eventId = this._getEventId();
    this.props.onMount(eventId);
    // this._startTimer();
  }
  componentDidUpdate(oldProps, oldState) {
    // console.log("updating", oldProps.$$feedbackData, this.props.$$feedbackData)
    if (
      (oldProps.$$feedbackData === undefined  &&
      this.props.$$feedbackData !== undefined &&
      this.props.$$feedbackData !== false)
      || (oldProps.$$feedbackData !== undefined && this.props.$$feedbackData !== undefined && this.props.$$feedbackData !== false && oldProps.$$feedbackData !== this.props.$$feedbackData)
    ) {
      // console.log('GOT DATA')
      // console.log('GOT DATA')
      // console.log('GOT DATA')
      let newState = {};
      this.props.$$feedbackData.map(($f, fIdx) => {
        if ($f.get('feedbackType') === 'stars')
        newState["v_" + fIdx] = 0;
        else
        newState["v_" + fIdx] = '';
        newState["h_" + fIdx] = false;
        return true;
      });
      newState["isReady"] = true;
      this.setState(newState, () => {
        // console.log('update set !', this.state.isReady)
      });
    }
    // console.log(oldProps.isReady, this.props.isReady)

    if (oldProps.answered !== true && this.props.answered === true && this.props.btClose === false && this.props.onFeedbackComplete)
      this.props.onFeedbackComplete()
  }
  componentWillUnmount() {
    this.props.cleanup();
  }
  _getEventId = () => {
    let eventId;
    if (this.props.eventId) eventId = this.props.eventId;
    else if (this.props.match !== undefined && this.props.match.params.eventId)
    eventId = this.props.match.params.eventId;
    // console.log(this.props.eventId, this.props.match);
    return eventId;
  };
  _onFeedbackHover = (idx, newHover) => {
    this.setState({
      ["h_" + idx]: newHover,
    });
  };
  _onFeedbackChange = (idx, value) => {
    this.setState({
      ["v_" + idx]: value,
      errored: false,
    });
  };
  _onFormSubmit = () => {
    let feedbackData = {};
    // console.log('SUBMIT')
    const { sendFeedback } = this.props;
    // const {
    //   eventId
    // } = this.props;
    const eventId = this._getEventId();
    let errored = false;
    this.props.$$feedbackData.map(($f, fIdx) => {
      if (this.state["v_" + fIdx] === 0) {
        errored = fIdx;
      } else {
        feedbackData["e_" + fIdx] = this.state["v_" + fIdx];
      }
      return true;
    });
    // console.log({errored})
    if (errored !== false) {
      this.setState({
        errored,
      });
    } else {
      this.setState({
        errored: false,
      });
      // console.log('SUBMIT !', eventId, feedbackData);
      sendFeedback(eventId, feedbackData);
    }
  };
  // _getFeedbackCard() {
  //   const {
  //     eventId
  //   } = this.props.match.params;
  //   const {
  //     $$data
  //   } = this.props;
  //   if($$data === undefined) return;
  //   // console.log(eventId, $$data.toJS())
  //   const found = $$data.get('allEvents').find(f => f.get('id') === eventId);
  //   return found
  // }
  _onFeedbackComplete = () => {
    const { onFeedbackComplete } = this.props;
    if (onFeedbackComplete !== undefined) onFeedbackComplete();
  };
  render() {
    const {
      // userRole,
      classes,
      $$feedbackData,
      answered,
      labelComplete,
      labelBack,
      btClose,
      btBack,
      footer,
      // $$data
    } = this.props;
    const { errored, isReady } = this.state;
    // const $$card = this._getFeedbackCard();
    // const cardImage = getCardImage($$card);
    // console.log({
    //   answered,
    //   isReady
    // })
    // console.log('check', $$feedbackData)
    // console.log('check', {isReady})
    // console.log('check', {answered})
    return (
      <If condition={$$feedbackData !== undefined}>
        <div className={classes.root}>
          <If condition={answered === false && isReady}>
            {$$feedbackData
              .map(($$feedback, fIdx) => {
                return (
                  <Grid key={"e_" + fIdx} item={true} xs={12}>
                    <Choose>
                      <When condition={$$feedback.get('feedbackType') === 'free'}>
                        <Box>
                          <Typography variant={"subtitle1"} gutterBottom={true}>
                            {$$feedback.get("feedbackQuestion")}
                          </Typography>
                          <Box display={"flex"}>
                            <Input id={"h_" + fIdx} name={"h_" + fIdx} multiline={true} rows={2} value={this.state["v_"+fIdx] !== null && this.state["v_"+fIdx] !== undefined ? this.state["v_"+fIdx] : ''} onChange={(kind,h) => {this._onFeedbackChange(fIdx, h.value);}}/>
                            </Box>
                          </Box>
                                           </When>
                      <Otherwise>
                    <Box>
                      <Typography variant={"subtitle1"} gutterBottom={true}>
                        {$$feedback.get("feedbackQuestion")}
                      </Typography>
                      <Box display={"flex"}>
                        <If condition={this.state["v_" + fIdx] !== undefined}>
                          <Rating
                            name={"r_" + fIdx}
                            style={{ display: "inline-flex" }}
                            value={this.state["v_" + fIdx]}
                            onChange={(event, newValue) => {
                              this._onFeedbackChange(fIdx, newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              this._onFeedbackHover(fIdx, newHover);
                            }}
                            />
                          <If
                            condition={
                              this.state["h_" + fIdx] !== undefined ||
                              this.state["v_" + fIdx] !== undefined
                            }
                            >
                            {" "}
                            <Typography
                              variant={"body1"}
                              color={"primary"}
                              style={{ marginLeft: 10 }}
                              >
                              {" "}
                              {labels[this.state["h_" + fIdx]] !== undefined
                                ? labels[this.state["h_" + fIdx]]
                                : labels[this.state["v_" + fIdx]]}
                              </Typography>
                            </If>
                          </If>
                        </Box>
                      </Box>
                    </Otherwise>
                  </Choose>

                    </Grid>
                  );
                })
                .toArray()}
                <If condition={errored !== false}>
                  <Grid item={true} xs={12}>
                    <Typography variant={"subtitle1"} color="error">
                      {i18n.get("feedbackMustAnswerAll")}
                    </Typography>
                  </Grid>
                </If>
                <Grid item={true} xs={12} align="center">

                  <Box my={3}>
                    <Button variant="contained" color="primary" onClick={this._onFormSubmit} size={"large"}>
                      Envoyer
                    </Button>
                  </Box>
                  <If condition={footer !== undefined}>
                    <Box mb={2}>
                      <Typography variant="caption" component={'div'}><Paragrapher text={footer} prettyfy={true} /></Typography>
                    </Box>
                  </If>
                </Grid>
              </If>

              <If condition={answered === true}>
                <Grid item={true} xs={12} align="center">
                  <Box mb={4}>
                  <Typography variant={"h4"} color="primary" gutterBottom>
                    {i18n.get("feedbackSuccess")}
                  </Typography>
                  </Box>
                  <If condition={btClose !== false}>
                  <Button variant="contained" color="primary" onClick={this._onFeedbackComplete} size={"large"}>
                    {labelComplete !== undefined ? labelComplete : "Fermer"}
                  </Button>
                  </If>
                  <If condition={btBack === true}>
                  <Button variant="contained" color="primary" onClick={this._onFeedbackComplete} size={"large"}>
                    {labelBack !== undefined ? labelBack : "Retour"}
                  </Button>
                  </If>
                </Grid>
              </If>
            </div>
          </If>
        );
      }
    }

    function mapStateToProps(state) {
      const props = {
        $$data: getDataHome(state),
        error: getLoadingError(state),
        isLoggedIn: isLoggedIn(state),
        userRole: getCurrentUserRole(state),
        answered: getFeedbackAnswered(state),
        $$user: getCurrentUser(state),
        $$feedbackData: getFeedbackData(state),
      };
      return props;
    }

    function mapDispatchToProps(dispatch) {
      return {
        onMount: (eventId) => {
          if (eventId !== undefined) {
            dispatch(
              fetchFeedback({
                eventId,
              })
            );
          } else {
            console.log("no event id");
          }
        },
        cleanup: (eventId) => {
          dispatch(
            cleanupFeedback()
          );
        },
        sendFeedback: (eventId, feedbackData) => {
          if (eventId !== undefined) {
            dispatch(
              sendFeedback({
                eventId,
                feedbackData,
              })
            );
          }
        },
      };
    }
    // export default ;
    export default withRouter(
      connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Feedback))
    );
