import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import config from 'config/api'

import { withStyles } from '@material-ui/core/styles';
// import { connect } from 'react-redux';
// import config from 'config/api'
// import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from 'components/UI/Dialog/Dialog';
// import queryString from 'query-string';
import WarningIcon from '@material-ui/icons/Warning';

// import ContactForm from 'components/Forms/ContactForm';



const styles = (theme) => {
  return {
    root: {
      '& .MuiTypography-h4': {
        '& a': {
          color: theme.palette.primary.main,
          // textDecoration: 'none'
        }
      }
    }
    }
};

class BrowserBarrier extends Component {
  constructor(props) {
    super(props);
    this.state = {ie: false}
  }
  componentDidMount() {
    this.msieversion();
  }
  msieversion = () => {
    // https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))
    // if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))
    {
        this.setState({ ie:parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10)})
    }
    else  // If another browser, return 0
    {
      // this.setState({ ie:false})
    }

    return false;
}
  render() {
    const {classes} = this.props;
    const {ie} = this.state;
      return (<Dialog
        title={<Box><WarningIcon style={{display: 'inline-block', verticalAlign:'middle', marginTop: -4}} /> Oops</Box>}
        open={ie !== false}
        disableBackdropClick={true}
        >
        <Box align="center" width="100%" mb={3} className={classes.root}>
        <Typography variant="h4" gutterBottom>Votre navigateur Internet Explorer n'est pas compatible avec notre site.</Typography>
        <Typography variant="h4" gutterBottom>Veuillez utiliser <a href="https://chrome.com" rel="noopener noreferrer" target="_blank">Chrome</a>, <a href="https://firefox.com" rel="noopener noreferrer" target="_blank">Firefox</a>, ou <a href="https://www.microsoft.com/fr-fr/edge" rel="noopener noreferrer" target="_blank">Edge</a>.</Typography>
        </Box>
      </Dialog>);
  }
}

export default withStyles(styles)(BrowserBarrier);
