import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'block',
    position: 'relative',
    fontWeight: 'bold',
    height: 183,
    backgroundColor: theme.palette.secondary.dark,
    [theme.breakpoints.down('md')]: {
      '& h1': {},
    },
    [theme.breakpoints.down('sm')]: {
      '& h1': {
        fontSize: '2.6rem',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& h1': {
        fontSize: '2.2rem',
      },
    },
  },
  pageTitle: {
    paddingTop: theme.spacing(8),
    paddingLeft: '1em',
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 0,
  },
  pageTitleWithSubtitle: {
    paddingTop: theme.spacing(5),
  },
  subTitle: {
    paddingTop: '1em',
    fontSize: '30%',
    fontWeight: 'normal',
  },
});

class PageHeader extends React.Component {
  render() {
    const { classes, title, subtitle } = this.props;
    return (
      <Box className={classes.root}>
        <Typography
          variant="h1"
          className={clsx(classes.pageTitle, { [classes.pageTitleWithSubtitle]: !!subtitle })}
        >
          {title}
          {subtitle && <div className={classes.subTitle}>{subtitle}</div>}
        </Typography>
      </Box>
    );
  }
}

export default withStyles(styles)(PageHeader);
