import React from "react";
import customStyles from "./DynamicFilter.cssmodule.css";
import throttle from 'mout/function/throttle'
// import debounce from 'mout/function/debounce'

// import Select from "components/UI/Form/Select";
import Input from "components/UI/Form/Input";
// import SelectField from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import IconDelete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

class DynamicFilterSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: undefined,
    };
    this.onChange = throttle(this._onChange.bind(this), 300)
  }
  componentDidMount() {
    if (this.props.$$filter && this.props.$$filter.get('filter_value') !== '' && this.props.$$filter.get('filter_value') !== undefined)
    this.setState({ value: this.props.$$filter.get('filter_value') });
    else
    this.setState({ value: "" });
    // console.log(this.props.$$filter.toJS())
  }
  _onChange(kind, target) {
    // event.preventDefault();
    // event.stopPropagation();

    this.setState({ value: target.value }, this.propagateFilterChanges);
    // this.propagateFilterChanges();
  }
  propagateFilterChanges() {
    const { onChange, filterId } = this.props;
    const valStr = this.state.value;
    onChange(filterId, valStr);
  }
  onClearFilter = (event) => {
    this.setState({ value: undefined }, this.propagateFilterChanges);
  };

  render() {
    const { $$filter, filterId } = this.props;
    const showDelete =
      this.state.value !== null &&
      this.state.value !== undefined &&
      this.state.value !== false &&
      this.state.value !== "";
    return (
      <React.Fragment>
        {/*<SelectField
          id={filterId}
          onChange={this.onChange.bind(this)}
          value={this.state.value}
          floatingLabelText={$$filter.get('filter_alias')}
          style={{width: 'calc(100% - 50px)'}}
          className={customStyles.filterField}
          >
          {$$filter.get('filter_items').map((item, value) =>
            <MenuItem key={'fi_'+value} value={value} primary={item} />
          ).toArray()}

        </SelectField>*/}
        <Input
          id={filterId}
          variant="outlined"
          onChange={this.onChange.bind(this)}
          value={this.state.value}
          label={$$filter.get("filter_alias")}
          style={{ width: showDelete ? "calc(100% - 52px)" : "100%" }}
          className={customStyles.filterField}
          />
        <If condition={showDelete}>
          <IconButton
            tooltip={"Supprimer"}
            onClick={this.onClearFilter}
            className={customStyles.filterTrash}
          >
            <IconDelete />
          </IconButton>
        </If>
      </React.Fragment>
    );
  }
}

export default DynamicFilterSearch;
