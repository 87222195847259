export default {
  // my_account: "Mon compte",
  home: 'Accueil',
  subscribeAction: "Je m'inscris",

  newsSoonAvailable: 'Bientôt disponible !',
  newsNew: 'Nouveau',
  newsShowMore: 'Voir plus',
  newsClose: 'Fermer',
  newsReplay: 'Replay',
  newsEvent: 'Webconference',
  newsFlash: 'Information',

  commonBack: 'Retour',
  commonError: 'Erreur',
  footerLegalNotice:
    'NORGINE et le logo avec la voile sont des marques déposées du groupe de sociétés Norgine.\n© Norgine 2023 All rights reserved',

  accountFormTitle: 'Mon compte',
  accountDeleteButton: 'Supprimer mon compte',
  accountDeleteTitle: 'Suppression de votre compte',
  accountDeleteText: 'Attention !\nLa suppression de votre compte est définitive !!!',
  accountDeleteBtConfirm: 'Je confirme vouloir supprimer mon compte',
  accountCurrentPassword: 'Mot de passe actuel',
  accountDeleteMustSpecifyPassword: 'Veuillez saisir votre mot de passe.',
  accountChangePassBt: 'Modifier mon mot de passe',
  accountEmail: 'Email',
  accountFirstname: 'Prénom',
  accountLastname: 'Nom',
  accountJob: 'Profession',
  accountYourAccount: 'Votre compte',
  accountPresent: 'Présent(e)',
  accountData: 'Identification',
  accountAddress: 'Coordonnées',
  accountAccessRestricted: 'Accès Administrateur',

  loginAction: 'Se connecter',
  loginForgotPassword: 'Mot de passe oublié ?',
  loginForgotPasswordText:
    'Saisissez votre identifiant afin de recevoir un mail qui vous\npermettra de changer votre mot de passe',
  loginFieldEmail: 'Email',
  loginFieldPassword: 'Mot de passe',
  loginFormSubmit: 'Je me connecte',
  liveClickToRedirect: 'Cliquez pour être redirigé vers la salle de la webconférence',
  liveMustConfigureEmbed: 'Vous devez configurer la vidéo ou le lien externe de cet événement !!!',
  helpBannerTitle: "Besoin d'aide pour utiliser l'outil ?",
  helpBannerGotoHelp: 'Rubrique aide',
  helpBannerGotoContact: 'Nous contacter',

  eventDescription: 'Description',
  eventDate: 'Date',
  eventDates: 'Date(s)',
  eventProgram: 'Programme',
  eventAnimatedBy: 'Animée par : ',
  eventDuration: 'Durée : ',
  homeIntroTitle: 'Des évènements en direct et interactifs',
  homeIntroSubTitle: 'Mis en place par votre interlocuteur Norgine',
  homeAvailableEvents: 'Nos webconférences',
  homeYourEvents: 'Mon agenda',
  homeReplays: 'Replays',

  loading: 'Chargement',
  helpTitle: 'Comment ça marche ?',
  speedTestButtonLabel: 'Testez votre connexion internet',
  speedTestButtonStart: 'Lancer le test',
  speedTestButtonRestart: 'ReLancer le test',

  contactFormTitle: 'Contactez-nous',
  contactFormSuccess: 'Nous vous recontacterons dans les plus brefs délais.',
  formFieldsRequiredLegend: '* Champs obligatoires',
  formPleaseMakeSelection: 'Veuillez faire votre choix',
  formCancel: 'Annuler',

  liveAction: 'Regarder',
  liveEventIsStarted: 'Live !',
  liveYourEventStartsIn: 'Votre webconférence débute dans :',
  liveClickToRefreshPage: 'Cliquez pour recharger la page et accéder au live',
  liveClickToRefreshPageAndGotoLive: 'Cliquez pour recharger la page et accéder au live',
  ClickToRefreshPage: 'Cliquez pour recharger la page',

  loginFormTitle: "J'ai déjà un compte",
  // subscriptionFormTitle: "Je n'ai pas de compte",
  subscriptionAction: "S'inscrire",
  subscriptionTitle: 'Inscription',
  subscriptionVerifiedSuccessTitle: 'Votre inscription a bien été\n prise en compte',
  subscriptionMemberSuccessTitle: 'Votre inscription a bien été\n prise en compte',
  subscriptionPendingSuccessTitle:
    "Votre inscription a bien été prise en compte.\nVous recevrez un email de confirmation dès qu'elle sera validée",
  subscriptionShowProgram: 'Programme',
  subscriptionAlreadyExists: 'Déjà inscrit(e)',
  subscriptionAlreadyExistsLong: 'Déjà inscrit(e) à la date sélectionnée',
  subscriptionMustChoseDate: 'Veuillez choisir une date ci-dessus',
  subscriptionHasNoId: "Je n'ai pas de numéro RPPS/ADELI",
  subscriptionHasRpps: 'Je suis un professionnel de santé avec numéro RPPS/ADELI',
  subscriptionHasRppsAndJob: 'Je suis {job} avec numéro RPPS/ADELI',
  subscriptionFormInstructions: 'Indiquez vos noms/prénoms tels que sur votre carte professionnelle',
  subscriptionHasNoRpps: 'Je suis un professionnel de santé SANS numéro RPPS/ADELI',
  // subscriptionSelfChoiceMdNoRPPS: "Je n'ai pas de numéro RPPS/ADELI",
  // subscriptionGuestChoiceMdRPPS: 'Je suis un professionnel de santé avec numéro RPPS/ADELI',
  // subscriptionGuestChoiceMdNoRPPS: "Je n'ai pas de numéro RPPS/ADELI",
  // subscriptionSelfHasNoAccount: "Je n'ai pas de numéro RPPS/ADELI",
  subscriptionGuestitle: 'VOUS SOUHAITEZ INSCRIRE ...',
  subscriptionSelfTitleSingle:
    'Pour vous inscrire à votre réunion,\nmerci de vous identifier ou de créer un compte',
  subscriptionSelfTitlePlural:
    'Pour vous inscrire à vos réunions,\nmerci de vous identifier ou de créer un compte',
  subscriptionFormNotice:
    "En validant ce formulaire d'inscription à l'évènement sélectionné, vous êtes informé que vous créez un compte personnel qui vous permettra de faciliter votre inscription à d'autres évènements organisés par l'intermédiaire de ce site ainsi que les communications qui seraient susceptibles de vous être adressées relatives à ces évènements.",
  jobMd: 'Médecin',
  jobPh: 'Pharmacien',
  jobIn: 'Infirmier',
  jobInt: 'Interne',
  jobX5: 'Interne sans RPPS',
  jobX7: 'Préparateur',
  jobX2: 'Médecin',
  jobX4: 'Pharmacien',
  jobX3: 'Infirmier',
  jobLb: 'Norgine',

  navHome: 'Accueil',
  navContact: 'Contact',
  navHelp: 'Aide',
  navReplays: 'Replays',
  navLegal: 'Mentions légales',
  navConfidentiality: 'Données personnelles',
  navMyAccount: 'Mon compte',
  navCookies: 'Politique en matière de cookie',
  navAccounts: 'Professionnels',
  navEvents: 'Evénements',
  navThemes: 'Thèmes',
  navModerator: 'Modération',
  navExports: 'Exports',
  navAbout: 'A propos de Norgine',
  navFaq: 'Admin Aide',
  adminCms: 'Admin CMS',
  navNews: 'Admin News',
  navUtms: 'Admin UTMs',
  navLogs: 'Admin Logs',
  navEmails: 'Admin Emails',

  replayNext: 'Replay suivant >',
  replayPrevious: '< Replay précédent',
  replayAction: 'Regarder',
  replaysTitle: 'Replays',
  replayCopyrightWarning:
    "Tout enregistrement, reproduction, diffusion, publication ou retransmission du contenu de cette webconférence est strictement interdit sans l'autorisation écrite du détenteur des droits d'auteur.",

  liveMustLoginFirst: 'Merci de vous connecter, ou de créer un compte pour accéder à ce contenu',
  liveCopyrightWarning:
    "Tout enregistrement, reproduction, diffusion, publication ou retransmission du contenu de cette webconférence est strictement interdit sans l'autorisation écrite du détenteur des droits d'auteur.",
  liveHelpTitle: 'Comment bien visualiser votre live ?',
  liveHelpMore: 'Voir les autres conditions de visualisation',
  // liveHelpDetails: "* Utilisez un ordinateur.\n* Privilégiez les navigateurs Mozilla Firefox ou Google Chrome.\n* Si vous n'entendez pas bien, votre débit de bande passante peut être insuffisant, veuillez «figer» le flux vidéo de l'orateur (en cliquant sur l'icône de la caméra sous la vidéo). Vous pouvez aussi fermer vos applications (Skype®, YouTube®, Facebook®, Outlook®...).\n* Connectez-vous à Internet en filaire plutôt qu'en wifi.",
  liveHelpDetails: '* Lore ipsum lorem ipsum lorem ipsum.',

  eventStateLive: 'Live',
  eventStatePending: 'A venir',
  eventStateFinished: 'Terminé',
  eventStateReplay: 'Replay',

  footerLegalText: false,

  feedbackSuccess: 'Votre évaluation a bien été enregistrée !',
  feedbackMustAnswerAll: "Merci de répondre à toutes les questions afin d'envoyer le formulaire...",

  quizSend: 'Envoyer',
  quizInstructionsPlural: 'Veuillez choisir de 1 à {max} réponse(s) parmi les {size} possibles.',
  quizInstructionsSingle: 'Veuillez choisir 1 réponse parmi les {size} possibles.',
  quizSuccess: 'Votre réponse a bien été enregistrée !',
  quizMustChose: "Veuillez faire votre choix afin d'envoyer votre réponse",
  quizQuestionActivate: 'Activer',
  quizQuestionIsActive: 'Activée',
  quizResults: 'Résultats :',

  navDashboard: 'Dashboard',
  dashboardStatsUsersTitle: 'Inscrits',
  dashboardStatsEventsTitle: 'Émissions',
  dashboardStatsTotal: 'Total',
  dashboardStatsPending: 'En attente',
  dashboardNavigationTitle: 'Navigation',
  dashboardNavigationAccounts: 'Annuaire des professionnels de santé',
  dashboardNavigationStats: 'Statistiques',
  dashboardNavigationStatsLocations: "Lieu d'exercice",
  dashboardNavigationStatsExercise: "Mode d'exercice",
  dashboardNavigationStatsSpeciality: 'Spécialité',
  dashboardNavigationStatsJobs: 'Professions',
  dashboardNavigationStatsRegistrations: 'Dates de création de comptes',
  dashboardNavigationStatsSubscriptions: "Dates d'inscription aux événements",
  dashboardStatsSpecialityX: 'Spécialités',
  dashboardStatsSpecialityY: 'Nombre de comptes',
  dashboardStatsJobsX: 'Profession',
  dashboardStatsJobsY: 'Nombre de comptes',
  dashboardStatsSubscriptionsX: '',
  dashboardStatsSubscriptionsY: "Nombre d'inscriptions aux événements",
  dashboardStatsRegistrationsX: '',
  dashboardStatsRegistrationsY: "Nombre d'inscriptions à la plateforme",
  homeMedias: 'Médias',
  mediasTitle: 'Médias',
  mediaAction: 'Regarder',
  mediaCopyrightWarning: '',
  mediaAnimatedBy: 'Animé par : ',
  mediaNext: 'Média suivant',
  mediaPrevious: 'Média précédent',
  navMedias: 'Médias',
  navAdminMedias: 'Admin Médias',

  offersTitle: 'Offres',
  navMyOffers: 'Mes annonces',
  navAdminOffers: 'Admin Annonces',

  rgpdPopin:
    "Nous utilisons les cookies nécessaires pour faire fonctionner notre site.\nPour plus d'informations sur les cookies que nous utilisons, consultez notre [a url=/cookies]Politique de Cookies[/local].\n\n[small][u]Cookies nécessaires : [/u][/small]\n[small]Les cookies nécessaires activent les fonctionnalités de base telles que la sécurité, la gestion du réseau et l'accessibilité. Vous pouvez les désactiver en modifiant les paramètres de votre navigateur, mais cela peut affecter le fonctionnement du site Web.[/small]",
  rgpdAccept: 'Fermer',

};
