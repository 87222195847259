import React, { Component } from "react";
import Form from "./AnnounceForm";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import DynamicFormHelper from 'components/UI/DynamicForm/DynamicFormHelp'
const styles = {
  paper: {
    maxWidth: 1280,
    width: '100%',
    padding: "10px 20px",
    margin: "20px auto 20px",
  },
};
class AdminAnnounceForm extends Component {
  constructor(props) {
    super(props);
    this._onClose = this._onClose.bind(this);
  }
  _onClose() {
    const { history, eventId } = this.props;
    history.push("/staff/announce/"+eventId+"/list");
  }
  render() {
    const { id, classes, eventId } = this.props;
    return (
        <Paper classes={{ root: classes.paper }}>
          <Form
            id={id}
            onFormComplete={this._onClose}
            onFormCancel={this._onClose}
            eventId={eventId}
          />
          <DynamicFormHelper />
        </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(AdminAnnounceForm));
