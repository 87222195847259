import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';
// import { getToken } from 'components/UI/Account/ducks/authentication';

const actionTypes = mirrorCreator(['SET_MODERATION_DATA', 'CLEAR_MODERATION_DATA', 'SET_MODERATION_ERROR'], { prefix: 'moderation/' });

const mutators = {
  setModerationData: (data) => ($$state) => $$state.set('moderation', data),
  setModerationError: (error) => ($$state) => $$state.set('error', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_MODERATION_DATA:
      acts.push(mutators.setModerationData(action.data));
      return pipe(acts, $$state);

    case actionTypes.CLEAR_MODERATION_DATA:
      acts.push(mutators.setModerationData(undefined));
      acts.push(mutators.setModerationError(undefined));
      return pipe(acts, $$state);

    case actionTypes.SET_MODERATION_ERROR:
      acts.push(mutators.setModerationError(action.error));
      return pipe(acts, $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.moderation || Immutable.Map();
export const getModerationData = createSelector([getRoot], ($$state) => $$state.get('moderation'));

export function fetchModeration() {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.events + '/moderation';

    // if (params.k !== undefined && params.r === undefined) url = url+'?k='+params.k;
    // if (params.r !== undefined && params.k === undefined) url = url+'?r='+params.r;
    // if (params.r !== undefined && params.k !== undefined) url = url+'?k='+params.k+'&r='+params.r;
    let formData = {};
    // formData.contact_form = true;

    dispatch(setModerationData(undefined));

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.moderation && response.moderation === 'complete') {
          dispatch(setModerationData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setModerationError(response.log));
        }
      })
      .catch((error) => dispatch(setModerationError(error.message)));
  };
}
export function setModerationData(data) {
  return {
    type: actionTypes.SET_MODERATION_DATA,
    data,
  };
}
export function clearModerationData() {
  return {
    type: actionTypes.CLEAR_MODERATION_DATA,
  };
}
export function setModerationError(error) {
  return {
    type: actionTypes.SET_MODERATION_ERROR,
    error
  };
}
