import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import { withStyles } from '@material-ui/core/styles';
import Template from 'components/UI/Template/Template';
import SelectBlocks from 'components/UI/Form/SelectBlocks';
import Avatar from '@material-ui/core/Avatar';
import getCardGuests from 'components/UI/helpers/getCardGuests';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import config from 'config/api';
import i18n from 'helpers/i18n';
import isArray from 'mout/lang/isArray'
import { formatDate } from 'helpers/stringUtils';
import { toHHMM } from 'components/UI/helpers/time';
import Button from '@material-ui/core/Button';
import Spinner from 'components/UI/Spinner/Spinner';
import { fetchData, getDataHome, getSubscriptions } from 'ducks/asyncFetcher';
import { fetchEvent, getEventData, clearEvent } from 'components/UI/Event/ducks/event';
import queryString from 'query-string';
import EditIcon from '@material-ui/icons/Edit';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import HomeHelp from 'components/Home/HomeHelp';
import isSubscribed from 'components/UI/helpers/isSubscribed';
import Immutable from 'immutable';
import {pluralize} from 'components/UI/helpers/stringUtils'
import { setUniverse } from 'components/UI/Account/ducks/authentication';
import clsx from 'clsx';
import BlockRatio from 'components/BlockRatio';
import { getCurrentUser } from 'components/UI/Account/ducks/authentication';
import EventDetailsInvit from "./EventDetailsInvit";

const MULTIPLE_SELECTION = true;

const styles = (theme) => {
  return {
    root: {
      //maj fullwidth
      //maxWidth: 1280,
      //paddingLeft: theme.spacing(2),
      //paddingRight: theme.spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      minHeight: 450,
      '& .MuiTypography-h3': {
        paddingRight: 4,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.1rem'
      },
      '& .MuiButton-root.MuiButton-containedPrimary': {
        fontWeight: 700
      },
    },
    header: {
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '70%',
        backgroundColor: theme.palette.primary.light,
        zIndex: '-1'
      }
    },
    inline: {
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    titleIcon: {
      border: '1px '+theme.palette.secondary.main+' solid',
      borderRadius: '180px',
      padding: 6,
      width: '2.5rem',
      height: '2.5rem',
      marginRight: theme.spacing(.5),
      // marginTop: theme.spacing(1.5),
      // marginBottom: theme.spacing(1.5)
    },
    breadCrumbs: {
      fontSize: '0.8125rem',
      // color: theme.palette.primary.main,
      color: '#fff',
      position: 'absolute',
      top: 10,
      left: 10,
      marginBottom: theme.spacing(.5),
      '& a': {
        // color: theme.palette.primary.main,
        color: '#fff',
        textDecoration:'underline'
      }
    },
    itemWrapper: {
      border: '1px #1D202126 solid',
      margin: theme.spacing(3),
      padding: theme.spacing(3),
      height: '100%',
    },
    badge: {
      top: '50%',
      right: '-16px'
    },
    itemThumb: {
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: 'cover',
      // position: 'relative',
      // backgroundImage: 'url('+require('assets/images/event.png')+')',
      // backgroundSize: 'cover',
      // backgroundPosition: '50% 20px',
      //backgroundColor: '#a7dadb',
      '&:before': {
        position: 'absolute',
        content: "''",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'url('+require('assets/images/event.png')+')',
        backgroundSize: 'cover',
        backgroundPosition: '50% 20px',
        //backgroundColor: '#a7dadb',
        backgroundRepeat: 'no-repeat',
      }
    },
    itemTitle: {
      marginBottom: theme.spacing(2),
    },
    itemDescription: {
      marginBottom: theme.spacing(2),
    },
    itemDate: {
      marginBottom: theme.spacing(2),
    },
    itemGuests: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& .MuiTypography-body1': {
        fontWeight: 700
      }
    },
    separator: {
      borderTop: '1px #1D202126 solid',
      width: '100%',
      height: 1,
      marginBottom: theme.spacing(3),
    },
    dateSelector: {
      display: 'block',
      width: '100%',
      // maxWidth: 280,
      maxWidth: '80%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    left: {
      padding: theme.spacing(4),
      width: '100%',
      height: '100%', //?
      position: 'relative',
      '& .MuiTypography-h4': {
        color: '#fff'
      },
      '& .MuiTypography-body1': {
        color: '#fff'
      },
      '& .MuiTypography-caption': {
        color: '#fff'
      }
    },
    rightGrid:{
    },
    right: {
      width: '100%',
      padding: theme.spacing(3),
      paddingTop: theme.spacing(4),
    },
    itemVideo: {
      position: 'relative',
      marginBottom: theme.spacing(2)
    },    
  };
};


class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState()
  }
  componentDidMount() {
    if (this.props.match && this.props.match.params.universe)
      this.props.setUniverse(this.props.match.params.universe);
    const {eventId, $$item, $$subscriptions} = this.props;
    this.props.onMount({eventId})
    if ($$item !== undefined && $$subscriptions !== undefined)
    this.setSubscriptions()
  }
  componentDidUpdate(oldProps) {
    const {$$item, $$subscriptions} = this.props;
    if (
      (((oldProps.$$item === undefined && this.props.$$item !== undefined) || (oldProps.$$item !== undefined && oldProps.$$item !== this.props.$$item && this.props.$$item !== undefined)) && $$subscriptions !== undefined) ||
      (((oldProps.$$subscriptions === undefined && this.props.$$subscriptions !== undefined) || (oldProps.$$subscriptions !== this.props.$$subscriptions)) && $$item !== undefined)
    )
    {
      this.setState(this.getDefaultState(), () => {
        this.setSubscriptions();
      })
    }

    if (oldProps.$$item === undefined && this.props.$$item !== undefined) {
      if ($$item.get('eventDates').size === 1 && !$$subscriptions.contains($$item.get('eventDates').get(0).get('value'))) {
        // console.log($$item.get('eventDates').get(0).toJS())
        this.selectDate('SelectBlocks', {id: 'dateSelector', value: [$$item.get('eventDates').get(0).get('value')]})
      }
    }
  }
  componentWillUnmount() {
    if (this.$timer) {
      clearTimeout(this.$timer);
      delete this.$timer;
    }
  }
  getDefaultState = () => {
    return {
      selectedDate: MULTIPLE_SELECTION ? [] : null,
      log: false,
      hasAvailableDates: true
    }
  }
  setSubscriptions = () => {
    const {$$item, $$subscriptions} = this.props;
    // console.log('SETTING SUBSCRIPTIONS ON MOUNT TO', $$subscriptions.toJS())
    let check = 0;
    let newDates = [];
    $$item.get('eventDates').map($$d => {
      if ($$subscriptions.contains($$d.get('value'))) {
        check++;
        newDates.push($$d.get('value'))
      }
      return true
    })
    // console.log({newDates, hasAvailableDates: $$item.get('eventDates').size - check})
    if (check > 0 && MULTIPLE_SELECTION) {
      this.setState({selectedDate: newDates, hasAvailableDates: ($$item.get('eventDates').size - check > 0 ? true : false)})
    } else if (check > 0 && !MULTIPLE_SELECTION) {
      this.setState({selectedDate: $$item.get('id')})
    }
  }

  selectDate = (kind, o) => {
    this.setState({selectedDate:o.value})
  }

  log = (message) => {
    this.setState({log: message}, () => {
      this.$timer = setTimeout(() => {
        // console.log('delayed')
        this.setState({log: false})
      }, 1000*4)
    })
  }
  gotoSubscribe = (e, $$event) => {
    const { history, $$subscriptions } = this.props;
    const { selectedDate } = this.state;

    if (MULTIPLE_SELECTION) {
      let count = 0;
      selectedDate.map(d => {
        if (!$$subscriptions.contains(d)) count++;
        return true;
      })
      if (count === 0) {
        this.log(i18n.get('subscriptionMustChoseDate', {pretty: false}));
        return;
      }
      // console.log('count is', count); return;
    } else
    if (!selectedDate) {
      this.log(i18n.get('subscriptionMustChoseDate', {pretty: false}));
      return;
    }
    // console.log({selectedDate})
    // if (!$$event || !$$event.get('id')) return;
    let q;
    let tmpDates;
    let finalDates = [];
    if (selectedDate && selectedDate.toJS) {
      let tmpDates = selectedDate.toJS();
      tmpDates.forEach((d) => {
        if (d !== null && !isSubscribed(d, $$subscriptions)) {
          // console.log('pushing', d)
          finalDates.push(d)
        }
      })
      // console.log({finalDates})
      q = finalDates.join(',')
    } else if (isArray(selectedDate)) {
      tmpDates = selectedDate;
      tmpDates.forEach((d) => {
        if (d !== null && !isSubscribed(d, $$subscriptions)) {
          // console.log('pushing', d)
          finalDates.push(d)
        }
      })
      // console.log({finalDates})
      q = finalDates.join(',')
    } else
    q = selectedDate
    history.push('/subscribe/?q=' + q);
  }

  render() {
    const { classes, $$item, $$subscriptions, thumbStyle, $$user } = this.props;
    const {selectedDate, log, hasAvailableDates} = this.state;

    const userRole = $$user !== undefined ? $$user.get('role') : undefined;
    const userSubRole = $$user !== undefined ? $$user.get('subRole') : undefined;
    const isPdsInvit=userRole=="staff" && userSubRole=="invit";
    
    let availableDates = []
    if ($$item !== undefined && $$item.get('eventDates') !== undefined) {
      $$item.get('eventDates').map($$date => {
        const disabled = $$subscriptions.find(id => id === $$date.get('value'));
        availableDates.push({
          value: $$date.get('value'),
          label: $$date.get('label')+(disabled ? ' - '+i18n.get('subscriptionAlreadyExists', {pretty: false}) : ''),
          disabled: disabled !== undefined
        })
        return true;
      });
    }
    availableDates = Immutable.fromJS(availableDates)
    // const thumb = getCardImage($$item);
    const $$guests = getCardGuests($$item);
    // const alreadySubscribed = hasAvailableDates
    if(isPdsInvit)
      return <EventDetailsInvit/>;

    return (
      <Template>
        <Grid
          container
          justify="flex-start"
          className={classes.root}
          >
          <Choose>
            <When condition={$$item !== undefined}>
              <Grid item xs={12} sm={6} container justify={'center'} alignContent="center" className={classes.itemThumb} >
                <Box className={classes.left} style={thumbStyle}>
                  <Typography variant="h4" component="div" gutterBottom className={classes.itemTitle}>
                    <Paragrapher
                      text={$$item.get('eventTitle')}
                      elementClassName={classes.content}
                      prettyfy={true}
                      element={'div'}
                      />
                  </Typography>
                  <If condition={$$guests !== false && $$guests.size > 0}>
                    <Box className={classes.itemGuests}>
                      {$$guests.map(($$g, aIdx) => {
                        return (
                          <Box w={'100%'} key={'av_'+aIdx} style={{clear: 'both'}}>
                            <Avatar src={$$g.get('dest') !== false ? $$g.get('dest') : undefined} alt={$$g.getIn(['attributes','name'])} style={{float: 'left', marginRight: 8, marginBottom: 8, backgroundColor: '#fff'}}/>
                            <Box w={'calc(100% - 40px)'}>
                              <Typography variant={'body1'}>{$$g.getIn(['attributes','name'])}</Typography>
                              <Typography variant={'caption'} gutterBottom>{$$g.getIn(['attributes','description'])}</Typography>
                            </Box>
                          </Box>)
                        }).toArray()}
                      </Box>
                    </If>

                    <Typography variant="body1" component="div" gutterBottom className={classes.itemDate}>
                      <DateRangeIcon /> {formatDate($$item.get('eventDate'), "dddd dd mmmm yyyy - HH'h'MM")}
                        <If condition={$$item.get('eventDuration') && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== false && $$item.get('eventDuration') !== '' && $$item.get('eventDuration') > 0}>
                          &nbsp;&nbsp;|&nbsp;&nbsp;<WatchLaterIcon/> {toHHMM($$item.get('eventDuration')*60)}
                        </If>
                      </Typography>
                      
                      <If condition={$$item.get('eventLinkTeasing') !== undefined && $$item.get('eventLinkTeasing') !== '' && $$item.get('eventLinkTeasing') !== null}>
                      <Grid item xs={12}>
                        <BlockRatio ratio={'16_9'} className={classes.itemVideo}>
                          <iframe
                            id="player"
                            title="replay"
                            src={$$item.get('eventLinkTeasing')}
                            className={'embed'}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            />
                        </BlockRatio>
                      </Grid>
                    </If>

                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} container className={classes.rightGrid}>
                    <Box pl={5} className={classes.right}>
                      <If condition={$$item.get('eventDescription') !== undefined && $$item.get('eventDescription') !== null && $$item.get('eventDescription') !== ''}>
                      <Typography variant="h5" gutterBottom>
                        <EditIcon className={classes.titleIcon}/> {i18n.get('eventDescription', {pretty: false})}
                        </Typography>
                        <Typography variant="body1" component="div" gutterBottom className={classes.itemDescription}>
                          <Paragrapher
                            text={$$item.get('eventDescription')}
                            elementClassName={classes.content}
                            prettyfy={true}
                            element={'div'}
                            />
                        </Typography>

                        <div className={classes.separator}></div>
                        </If>

                        <If condition={$$item.get('eventProgram') !== false && $$item.get('eventProgram') !== null && $$item.get('eventProgram') !== ''}>
                          <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                              <FormatListBulletedIcon className={classes.titleIcon}/> {i18n.get('eventProgram', {pretty: false})}
                              </Typography>
                              <Typography variant="body1" component="div" gutterBottom className={classes.itemDescription}>
                                <Paragrapher
                                  text={$$item.get('eventProgram')}
                                  elementClassName={classes.content}
                                  prettyfy={true}
                                  element={'div'}
                                  />
                              </Typography>
                            </React.Fragment>

                            <div className={classes.separator}></div>
                          </If>

                          <Typography variant="h5" gutterBottom>
                            <DateRangeIcon className={classes.titleIcon}/> {i18n.get('eventDates', {pretty: false})}
                            </Typography>
                            <Box className={classes.dateSelector}>
                              <SelectBlocks name={'dateSelector'} showLabel={false} multiple={MULTIPLE_SELECTION} variant="outlined" label={pluralize(i18n.get('eventDate', {pretty: false}), $$item.get('eventDates').size)} value={selectedDate} options={availableDates} onChange={this.selectDate} />
                            </Box>
                            <Button className={"subscribe_event subscribe_event_"+$$item.get('id')} size="large" variant="contained" color="primary" onClick={e => this.gotoSubscribe(e)} disabled={hasAvailableDates === false}>{hasAvailableDates === false ? i18n.get('subscriptionAlreadyExists', {pretty: false}) : i18n.get('subscribeAction', {pretty: false})}</Button>
                            <If condition={log !== false}>
                              <Box mt={2}>
                                <Typography variant="body1" color="error">{log}</Typography>
                              </Box>
                            </If>
                          </Box>
                        </Grid>
                        <Grid item xs={12} className="HomeHelpContainer">
                          <Box mt={14} width={'100%'}>
                            <HomeHelp/>
                          </Box>
                        </Grid>
                      </When>
                      <Otherwise>
                        <Spinner>{i18n.get('loading')}</Spinner>
                      </Otherwise>
                    </Choose>

                  </Grid>
                </Template>
              );
            }
          }
  function mapStateToProps(state) {
    const props = {
      $$data: getDataHome(state),
      $$item: getEventData(state),
      $$subscriptions: getSubscriptions(state),
      $$user: getCurrentUser(state),
    };
    return props;
  }
  function mapDispatchToProps(dispatch) {
    return {
      onMount: ({eventId}) => {
        const uri = queryString.parse(window.location.search);
        dispatch(
          fetchData({
            key: 'home',
            fetchUrl: config.home,
          })
        );
        // console.log('PARAMS ARE', uri)
        dispatch(
          fetchEvent({
            eventId,
            params: {k: uri.k, r: uri.r}
          })
        );
      },
      onUnmount: () => {
        dispatch(clearEvent())
      },
      setUniverse: (universe) => {
        dispatch(setUniverse(universe));
      },
    };

  }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventDetails)));
