import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { getCurrentUserRole } from "components/UI/Account/ducks/authentication";
import Form from "./AdminThemeForm";
import List from "./AdminThemeList";
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
// import NoMatch from 'components/NoMatch';
// import Bubble from 'components/UI/Bubble';
import config from 'config/api';
import Template from 'components/UI/Template/Template'
import Box from '@material-ui/core/Box';

const AdminTheme = (props) => {
  const { match, userRole, location } = props;
  // console.log(match)
  if (userRole !== "admin")
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location },
        }}
      />
    );
  return (
    <Template maxWidth='auto' current="admin" headerPosition={'relative'} headerImage={config.template && config.template.admin && config.template.admin.background ? config.template.admin.background : undefined}>
      <Box width="100%">

        <If condition={match.params.action === "list"}>
          <List />
        </If>
        <If condition={match.params.action === "edit"}>
          <Form id={match.params.id} />
        </If>
</Box>
</Template>
  );
};

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}

export default withRouter(connect(mapStateToProps)(AdminTheme));
