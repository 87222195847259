// export default function (theme) {
//   return {
//     backgroundColor: theme.palette.background.default,
//     '& input:-webkit-autofill': {
//       '-webkit-box-shadow': '0 0 0 30px ' + theme.palette.background.default+' inset !important',
//       '-webkit-text-fill-color': theme.palette.text.primary + ' !important'
//     },
//     '& input:-webkit-autofill:hover': {
//       '-webkit-box-shadow': '0 0 0 30px ' + theme.palette.background.default+' inset !important'
//     },
//     '& input:-webkit-autofill:focus': {
//       '-webkit-box-shadow': '0 0 0 30px ' + theme.palette.background.default+' inset !important'
//     },
//     '& input:-webkit-autofill:active': {
//       '-webkit-box-shadow': '0 0 0 30px ' + theme.palette.background.default+' inset !important'
//     },
//   }
// };
export default function (theme) {
  const color = theme.palette.text.primary;
  const bgColor =
    theme.palette.background.input !== undefined
      ? theme.palette.background.input
      : theme.palette.background.default;
  return {
    backgroundColor: bgColor,
    "& input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 30px " + bgColor + " inset !important",
      "-webkit-text-fill-color": color + " !important",
    },
    "& input:-webkit-autofill:hover": {
      "-webkit-box-shadow": "0 0 0 30px " + bgColor + " inset !important",
    },
    "& input:-webkit-autofill:focus": {
      "-webkit-box-shadow": "0 0 0 30px " + bgColor + " inset !important",
    },
    "& input:-webkit-autofill:active": {
      "-webkit-box-shadow": "0 0 0 30px " + bgColor + " inset !important",
    },
  };
}
