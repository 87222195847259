import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import NotConnected from "components/UI/NotConnected";

const styles = (theme) => {
  return {
    root: {
      color:'red',
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(0),
      '& a': {
        color: 'inherit',
      },
    },
    legend: {
       marginBottom: 30,
    },
    inline:{
       display:'inline',
       padding:0,
       margin:0,
       fontSize:'100%',
    },
  };
};
class Error extends Component {

  render() {
    const { classes, message, result, inline } = this.props;

    const msg=message!==undefined ? message : (result && result.msg!==undefined && result.msg!==null ? result.msg : null);
    const code= result && result.error!==undefined ? result.error : result;
    return (
      <Paper square elevation={0} className={clsx(classes.root, inline ? classes.inline : null)}>
        <If condition={msg!==null}>
            <Box align="center">
              <Typography variant="h5" gutterBottom={true} className={clsx(classes.legend, inline ? classes.inline : null)}>
              {msg}
              </Typography>
            </Box>
          </If>
        <Box my={6} align="center">
          <Typography variant="h5" gutterBottom={true} className={clsx(classes.legend, inline ? classes.inline : null)}>
              <Choose>
              <When condition={code=='not_connected'}>
                <NotConnected/>
              </When>
              <When condition={code=='not_found'}>
                Erreur contenu non trouvé
              </When>
              <Otherwise>
                <If condition={msg===null}>
                  {"Une erreur est survenue"+(code!=null ? " : "+code : "")}
                </If>
              </Otherwise>
              </Choose>
          </Typography>
        </Box>
      </Paper>
    );
  }
}

export default withRouter(
  withStyles(styles)(Error),
);
