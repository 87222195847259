// import React from 'react';
//
// import parser, { Tag } from 'bbcode-to-react';
// // import IconDisc from '@material-ui/icons/FiberManualRecord';
// import Typography from '@material-ui/core/Typography';
//
// class TitleTag extends Tag {
//   toReact() {
//     // using this.getComponents() to get children components.
//     return (
//       <Typography variant="h5" gutterBottom>
//         {this.getComponents()}
//       </Typography>
//     );
//   }
// }
// class SubTitleTag extends Tag {
//   toReact() {
//     // using this.getComponents() to get children components.
//     return (
//       <Typography variant="h6" gutterBottom>
//         {this.getComponents()}
//       </Typography>
//     );
//   }
// }
// class PTag extends Tag {
//   toReact() {
//     // using this.getComponents() to get children components.
//     return <p>{this.getComponents()}</p>;
//   }
// }
// class SmallTag extends Tag {
//   toReact() {
//     // using this.getComponents() to get children components.
//     return <small>{this.getComponents()}</small>;
//   }
// }
// class EmphasisTag extends Tag {
//   toReact() {
//     // using this.getComponents() to get children components.
//     return <em>{this.getComponents()}</em>;
//   }
// }
// class AltColorTag extends Tag {
//   toReact() {
//     // using this.getComponents() to get children components.
//     return (
//       <Typography color="secondary" element="span">
//         {this.getComponents()}
//       </Typography>
//     );
//   }
// }
//
// parser.registerTag('title', TitleTag); // add new tag
// parser.registerTag('subtitle', SubTitleTag); // add new tag
// parser.registerTag('p', PTag); // add new tag
// parser.registerTag('small', SmallTag); // add new tag
// parser.registerTag('em', EmphasisTag); // add new tag
// parser.registerTag('alt', AltColorTag); // add new tag
//
// // class TestComp extends React.Component {
// //   render() {
// //     return <span>{this.props.children}</span>
// //   }
// // }
// class Prettyfier extends React.Component {
//   render() {
//     const { text, className, transformBullets } = this.props;
//     // return (<span className={className}>{parser.toReact(text)}</span>);
//     let rText = text.toString();
//     // // console.log({rText})
//     if (transformBullets !== false) {
//       // rText = rText.replace(/\. /g, '&ofcir; ').replace(/ \./g, ' &ofcir;');
//       // rText = rText.replace(/\+ /g, '&oplus; ').replace(/ \+/g, ' &oplus;');
//     }
//     rText = rText.replace(/ \?/gm, ' ?');
//     rText = rText.replace(/ !/gm, ' !');
//     rText = rText.replace(/ !/gm, ' :');
//     // return (<span className={className} dangerouslySetInnerHTML={{__html:parser.toReact(rText)}}></span>);
//     return <span className={className}>{parser.toReact(rText)}</span>;
//   }
// }
//
// export default Prettyfier;

import React from 'react';

import parser, { Tag } from 'bbcode-to-react';
// import IconDisc from '@material-ui/icons/FiberManualRecord';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

class TitleTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return (
      <Typography variant="h5" gutterBottom>
        {this.getComponents()}
      </Typography>
    );
  }
}
class SubTitleTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return (
      <Typography variant="h6" gutterBottom>
        {this.getComponents()}
      </Typography>
    );
  }
}
class PTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return <p>{this.getComponents()}</p>;
  }
}
class SmallTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return <small>{this.getComponents()}</small>;
  }
}
class EmphasisTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return <em>{this.getComponents()}</em>;
  }
}
class AltColorTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return (
      <Typography color="secondary" component="span">
        {this.getComponents()}
      </Typography>
    );
  }
}
class LocalLinkTag extends Tag {
  toReact() {
    return <Link to={this.params.url}>{this.getComponents()}</Link>;
  }
}
class ColorTag extends Tag {
  toReact() {
    return <span style={{color: this.params.color}}>{this.getComponents()}</span>;
  }
}
class SupTag extends Tag {
  toReact() {
    return <sup>{this.getComponents()}</sup>;
  }
}
class SubTag extends Tag {
  toReact() {
    return <sub>{this.getComponents()}</sub>;
  }
}
//AJOUT B
class ATag extends Tag {
  toReact() {
    return <a href={this.params.url} target='_blank'>{this.getComponents()}</a>;
  }
}
parser.registerTag('title', TitleTag); // add new tag
parser.registerTag('subtitle', SubTitleTag); // add new tag
parser.registerTag('p', PTag); // add new tag
parser.registerTag('small', SmallTag); // add new tag
parser.registerTag('em', EmphasisTag); // add new tag
parser.registerTag('alt', AltColorTag); // add new tag
parser.registerTag('local', LocalLinkTag); // add new tag
parser.registerTag('color', ColorTag); // add new tag
parser.registerTag('sup', SupTag); // add new tag
parser.registerTag('sub', SubTag); // add new tag
parser.registerTag('a', ATag); // add new tag

// class TestComp extends React.Component {
//   render() {
//     return <span>{this.props.children}</span>
//   }
// }
class Prettyfier extends React.Component {
  render() {
    const { text, className, transformBullets } = this.props;
    // console.log({text, className})
    // return (<span className={className}>{parser.toReact(text)}</span>);
    let rText = text.toString();
    // // console.log({rText})
    if (transformBullets !== false) {
      // rText = rText.replace(/\. /g, '&ofcir; ').replace(/ \./g, ' &ofcir;');
      // rText = rText.replace(/\+ /g, '&oplus; ').replace(/ \+/g, ' &oplus;');
    }
    rText = rText.replace(/ \?/gm, ' ?');
    rText = rText.replace(/ !/gm, ' !');
    rText = rText.replace(/ :/gm, ' :');
    // return (<span className={className} dangerouslySetInnerHTML={{__html:parser.toReact(rText)}}></span>);
    return <span className={className}>{parser.toReact(rText)}</span>;
  }
}

export default Prettyfier;
