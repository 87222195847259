import React from "react";
import {
  connect
} from "react-redux";
import i18n from "helpers/i18n";
import Immutable from "immutable";
import Form from "components/UI/Form/Form";
// import BlockTitle from "components/UI/BlockTitle";
// import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import Typography from "@material-ui/core/Typography";
import {
  getResetPassword,
  resetPassword as formSubmitAction,
  hasLoginFailed as isFormErrored,
  getError as getFormError,
  getCurrentUserRole,
  isLoggingIn as isFormRunning,
  isLoggedIn as isFormComplete,
} from "components/UI/Account/ducks/authentication";
const FIELDS = [{
  id: "email",
  name: "email",
  label: "Email",
  validation: ["notEmpty", "email"],
  maxLength: 64,
  sizes: {
    xs: 12
  },
  autoComplete: "email",
}, ];
const $$FIELDS = Immutable.fromJS(FIELDS);
const CONFIG = {
  btCancel: true,
  buttonColorSubmit: 'primary',
};
const $$CONFIG = Immutable.fromJS(CONFIG);
class ResetPasswordForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      erroredFields: {},
    };
    // this._onFormSubmit = this._onFormSubmit.bind(this);
    // this._onFormCancel = this._onFormCancel.bind(this);
    // this._onFieldValueChange = this._onFieldValueChange.bind(this);
    this._getI18n();
    this.$$FIELDS = $$FIELDS;
    this.$$CONFIG = $$CONFIG;
  }
  componentDidMount() {
    this._setupState();
  }
  _getI18n = () => {
    this.i18n = {
      send: "Envoyer",
      sending: "Envois...",
      cancel: "Retour",
    };
  }
  render() {
    const {
      resetPassword,
      noTitle
    } = this.props;
    return (
      <div className={"fullWidth"}>
        <If condition={!noTitle}>
          <Typography variant="h3" align="center">{i18n.get('loginForgotPassword', {pretty: false})}</Typography>
        </If>

        <Choose>
          <When
            condition={
              resetPassword &&
              resetPassword.get("step") === "request" &&
              resetPassword.get("result") === true
            }
          >
            <div className={"Login"}>
              <Box align="center" py={2}>
                <Paragrapher text={resetPassword.get("log")} prettyfy={true} />
              </Box>
            </div>
          </When>
          <Otherwise>
            <div className="Login">
              <div
                className="form"
                style={{
                  maxWidth: 800,
                  // padding: "20px 20px 10px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom={true}
                  style={{ marginBottom: 20 }}
                >
                  {i18n.get('loginForgotPasswordText')}
                </Typography>
                {this._renderForm()}
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
const mapStateToProps = ($$state) => {
  return {
    resetPassword: getResetPassword($$state),
    userRole: getCurrentUserRole($$state),
    loading: isFormRunning($$state),
    isFormErrored: isFormErrored($$state),
    error: getFormError($$state),
    isFormComplete: isFormComplete($$state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      const login = values.email;
      const s3c = "DkopdqDAza+==";
      dispatch(formSubmitAction("request", login, s3c));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
