import React, { Component } from "react";
// import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import Immutable from "immutable";
// import clsx from "clsx";
// import queryString from "query-string";
// import getFetchOptions from "helpers/getFetchOptions";
// import Dialog from "components/UI/Dialog/Dialog";
// import DialogConfirm from "components/UI/Dialog/DialogConfirm";

// import getCardImage from 'helpers/getCardImage'
// import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
// import remove from "mout/array/remove";
// import config from "config/api";
// import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
// import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
// import MenuItem from "@material-ui/core/MenuItem";
// import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
// import BlockTitle from "components/UI/BlockTitle";
// import Rating from "@material-ui/lab/Rating";
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import SocketHelper from "components/UI/Socket/SocketHelper";
// import Feedback from "components/UI/Feedback/FeedbackEmbed";
import Template from "components/Template/Template";
// import Quiz from "components/UI/Quiz/Quiz";
import AdminMessageGrid from "components/UI/Message/AdminMessageGrid";
// import AdminOrchestratorBlock from "./AdminOrchestratorBlock";
// import Select from "components/UI/Form/Select";
import AdminOrchestratorQuiz from "components/UI/Quiz/AdminOrchestratorQuiz";
import AdminOrchestratorAnnounce from "components/UI/Announce/AdminOrchestratorAnnounce";
import AdminOrchestratorFeedback from "components/UI/Feedback/AdminOrchestratorFeedback";
import { withRouter } from "react-router-dom";
// import {
  // getFeedbackReset,
  // resetFeedback,
// } from "components/UI/Feedback/ducks/feedback";
// import {
  // setAnnounceManagerOption,
  // fetchAnnounces,
  // getAnnouncesData,
  // clearAnnounceData,
  // getQuizManagerResults,
  // clearQuizQuestions,
  // clearQuizManagerResults,
  // getQuizResults,
  // resetAnnounceQuestion,
// } from "components/UI/Announce/ducks/announce";
// import {
  // setQuizManagerOption,
  // fetchQuizQuestions,
  // getQuizQuestions,
  // getQuizManagerResults,
  // clearQuizQuestions,
  // clearQuizManagerResults,
  // getQuizResults,
  // resetQuizQuestion,
// } from "components/UI/Quiz/ducks/quiz";
import {
  isLoggedIn,
  // getCurrentUserRole,
  getCurrentUser,
  // getToken,
} from "components/UI/Account/ducks/authentication";
import {
  sendMessage,
  // getContent,
  setEventState,
  getEventState,
  setAdminEventState,
  getAdminEventState,
  fetchEventState,
} from "components/UI/Socket/ducks/relay";
// qid: 'c6b5279e681b7866afcc94d5fef27a3a',
// import i18n from "helpers/i18n";
// const AF_KEY = "home";
const USE_WEBSOCKET = false;
const styles = (theme) => {
  // console.log(theme)
  // theme.palette.contrastThreshold = 0.1
  return {
    root: {
      minWidth: 500,
      "& .MuiDivider-vertical": {
        marginLeft: 10,
        marginRight: 10,
      },
      "& .MuiButton-root": {
        "& .MuiButton-label": {
          [theme.breakpoints.down("md")]: {},
        },
      },
      '& .MuiBadge-anchorOriginTopRightRectangle': {
        top: -7,
        right: -7,
        border: '1px '+theme.palette.secondary.main+' solid',
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        whiteSpace: 'nowrap'
      }
    },
    popinPlaceholder: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.dialog && theme.palette.dialog.backgroundColor ? theme.palette.dialog.backgroundColor : undefined,
      color: theme.palette.dialog && theme.palette.dialog.color ? theme.palette.dialog.color : undefined,
    },
    leftColumn: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: theme.spacing(2),
      },
    },
    rightColumn: {
      [theme.breakpoints.down("md")]: {
        "& .MuiButton-root": {
          "& .MuiButton-label": {
            // padding: 0,
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        "& .MuiButton-root": {
          "& .MuiButton-label": {
            padding: 0,
            fontSize: "0.6rem",
          },
        },
      },
    },
    onAir: {
      fontWeight: "bold",
      marginBottom: 40,
      border: "1px " + theme.palette.primary.main + " solid",
      display: "inline-block",
      padding: "5px 10px",
    },
    paperWrapper: {
      paddingLeft: 20,
      paddingRight: 20,
      margin: "20px auto",
      maxWidth: 1200,
      width: "100%",
      padding: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
    questionWrapper: {
      marginBottom: theme.spacing(2),
    },
    question: {
      boxSizing: "inherit",
      cursor: "pointer",
      "& .MuiTypography-subtitle1": {
        fontSize: "1.2rem",
        lineHeight: 1.85,
        color: "inherit",
        textAlign: "right",
      },
      "& .MuiTypography-subtitle2": {
        fontSize: "1.2rem",
        lineHeight: 1.85,
        color: "inherit",
      },
      border: "1px " + theme.palette.secondary.light + " solid",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-body1": {
        color: "inherit",
      },
    },
    selected: {
      cursor: "default",
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: theme.palette.secondary.light,
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.secondary.light
        ),
      },
    },
    // correct: {
    //   backgroundColor: theme.palette.success.dark,
    //   color: theme.palette.getContrastText(theme.palette.success.dark),
    //   '& .MuiLinearProgress-colorPrimary': {
    //     backgroundColor: theme.palette.success.light
    //   },
    //   '& .MuiLinearProgress-barColorPrimary': {
    //     backgroundColor: theme.palette.getContrastText(theme.palette.success.dark)
    //   }
    // },
    // incorrect: {
    //   backgroundColor: theme.palette.error.dark,
    //   color: theme.palette.getContrastText(theme.palette.error.dark),
    //   '& .MuiLinearProgress-colorPrimary': {
    //     backgroundColor: theme.palette.error.light
    //   },
    //   '& .MuiLinearProgress-barColorPrimary': {
    //     backgroundColor: theme.palette.getContrastText(theme.palette.error.dark)
    //   }
    // },
    actions: {
      minHeight: 54,
    },
    log: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    // percentage: {
    //   fontSize: '1.6rem'
    // }
  };
};
class AdminQuizControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: [],
      answered: undefined,
      logMessage: false,
      errored: false,
      activeTab: "messages",
      // dialogDeleteState: undefined,
    };
  }
  componentDidMount() {
    const {
      // eventId,
      match,
    } = this.props;
    const { eventId } = match.params;
    this.props.onMount({
      eventId,
    });

    // const $$initValues = uri.from && uri.from === 'rpps' ? Immutable.fromJS({
  }
  componentDidUpdate(oldProps, oldState) {
    // if (
    //   oldProps.$$eventState === undefined &&
    //   this.props.$$eventState !== undefined
    // ) {
    //   const uri = queryString.parse(window.location.search);
    //   if (uri.t && uri.t === "quiz") {
    //     this.setState({ activeTab: "quiz" });
    //   } else if (uri.t && uri.t === "feedback") {
    //     this.setState({ activeTab: "feedback" });
    //   } else if (uri.t && uri.t === "announce") {
    //     this.setState({ activeTab: "announce" });
    //   }
    // }
    //
    // // Keep
    // if (
    //   oldProps.$$quizQuestions === undefined &&
    //   this.props.$$quizQuestions !== undefined &&
    //   this.props.$$quizQuestions.get("rows").size > 0
    // ) {
    //   // console.log(
    //   //   "test forcing question to",
    //   //   this.props.$$quizQuestions.get("rows").get(0).toJS()
    //   // );
    //   this.setState({
    //     questionSelected: this.props.$$quizQuestions
    //     .get("rows")
    //     .get(0)
    //     .get("id"),
    //   });
    // }
    //
    // if (
    //   oldProps.$$announceQuestions === undefined &&
    //   this.props.$$announceQuestions !== undefined &&
    //   this.props.$$announceQuestions.get("rows").size > 0
    // ) {
    //   // console.log(
    //   //   "test forcing question to",
    //   //   this.props.$$announceQuestions.get("rows").get(0).toJS()
    //   // );
    //   this.setState({
    //     questionSelected: this.props.$$announceQuestions
    //     .get("rows")
    //     .get(0)
    //     .get("id"),
    //   });
    // }

    // if (oldProps.$$eventState !== this.props.$$eventState) {
    //   // const { $$eventState, sendMessage } = this.props;
    //   const { questionActive, questionSelected } = this.state;
    //   if (questionActive !== this.props.$$eventState.get("contentId")) {
    //     let newState = {
    //       questionActive: this.props.$$eventState.get("contentId"),
    //     };
    //     // console.log('bleh', questionActive)
    //     if (
    //       oldProps.$$eventState === undefined &&
    //       this.props.$$eventState.get("contentId") !== false &&
    //       this.props.$$eventState.get("contentId") !== "none" &&
    //       this.props.$$eventState.get("contentId") !== "feedback" &&
    //       this.props.$$eventState.get("contentId") !== "announce"
    //     ) {
    //       newState.questionSelected = this.props.$$eventState.get("contentId");
    //       // console.log(
    //       //   "forcing question to",
    //       //   this.props.$$eventState.get("contentId")
    //       // );
    //     } else if (
    //       questionSelected === undefined ||
    //       questionSelected === false
    //     ) {
    //       // console.log(
    //       //   "test should set",
    //       //   this.props.$$quizQuestions.get("rows").toJS()
    //       // );
    //
    //       if (
    //         this.props.$$quizQuestions !== undefined &&
    //         this.props.$$quizQuestions.get("rows") !== undefined &&
    //         this.props.$$quizQuestions.get("rows").size >= 1
    //       ) {
    //         newState.questionSelected = this.props.$$quizQuestions
    //         .get("rows")
    //         .get(0)
    //         .get("id");
    //         // this.props.$$quizQuestions
    //         //   .get("rows")
    //         //   .get(0)
    //       }
    //       else if (
    //         this.props.$$announceQuestions !== undefined &&
    //         this.props.$$announceQuestions.get("rows") !== undefined &&
    //         this.props.$$announceQuestions.get("rows").size >= 1
    //       ) {
    //         newState.questionSelected = this.props.$$announceQuestions
    //         .get("rows")
    //         .get(0)
    //         .get("id");
    //         // this.props.$$quizQuestions
    //         //   .get("rows")
    //         //   .get(0)
    //       }
    //     }
    //     // console.log("test setting newstate", newState, {
    //     //   questionActive,
    //     //   questionSelected,
    //     // });
    //     this.setState(newState);
    //   }
    // }
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }
  // _getQuestion = (questionId) => {
  //   const { $$quizQuestions, $$eventState } = this.props;
  //   const {activeTab} = this.state
  //   let $$content;
  //   // ici
  //   if (questionId === "messages") {
  //     console.log('DEBUG A', activeTab, questionId)
  //     $$content = this._getItemMessages();
  //   }
  //   else if (questionId === "feedback" && activeTab === 'feedback') {
  //     console.log('DEBUG B', activeTab, questionId)
  //     $$content = this._getItemFeedback();
  //   }
  //   else if ($$eventState !== undefined && activeTab === 'announce') {
  //     console.log('DEBUG C', activeTab, questionId)
  //     $$content = this._getItemAnnounce(questionId);
  //   } else if ($$eventState !== undefined && activeTab === 'quiz') {
  //     console.log('DEBUG D', activeTab, questionId)
  //       $$content = this._getItemQuestion(questionId)
  //     // console.log('DEBUG D', questionId)
  //     // $$content = $$quizQuestions
  //     // .get("rows")
  //     // .filter(($$q) => $$q.get("id") === questionId)
  //     // .get(0);
  //   }
  //   return $$content;
  // };
  // _onContentActivate = (questionId) => {
  //   const { questionActive } = this.state;
  //   const {
  //     // eventId,
  //     match,
  //     // $$quizQuestions,
  //     setEventState,
  //   } = this.props;
  //   const { activeTab } = this.state;
  //   const { eventId } = match.params;
  //   if (questionId !== questionActive) {
  //     const $$content = this._getQuestion(questionId);
  //     console.log('DEBUG', $$content.toJS())
  //     setEventState({
  //       eventId,
  //       contentId: $$content.get("id"),
  //       // contentType: $$content.get("quizType"),
  //       contentType: activeTab,
  //     });
  //   } else {
  //     // const $$content = this._getQuestion(questionId);
  //     setEventState({
  //       eventId,
  //       contentId: "none",
  //       contentType: "none",
  //     });
  //   }
  // };
  // _onContentClick = (questionId) => {
  //   const {
  //     questionSelected
  //   } = this.state
  //   const {
  //     // eventId,
  //     match,
  //     $$quizQuestions,
  //     setAdminEventState
  //   } = this.props;
  //   const {
  //     eventId
  //   } = match.params
  //   if(questionId !== questionSelected) {
  //     const $$content = this._getQuestion(questionId);
  //     setAdminEventState({
  //       eventId,
  //       contentId: $$content.get('id'),
  //       contentType: $$content.get('quizType')
  //     })
  //   } else {
  //     // const $$content = this._getQuestion(questionId);
  //     // setEventState({
  //     //   eventId,
  //     //   contentId: 'none',
  //     //   contentType: 'none'
  //     // })
  //   }
  // }
  // _onContentClick = (questionId) => {
  //   // const {} = this.props;
  //   const { questionSelected } = this.state;
  //   if (questionId !== questionSelected)
  //   this.setState({
  //     questionSelected: questionId,
  //   });
  // };
  // _onQuizShowResults = (questionId) => {
  //   // const { clearQuizManagerResults, $$results } = this.props;
  //   const { $$results } = this.props;
  //   const questionShowResults =
  //   $$results !== null && $$results !== undefined && $$results !== false;
  //   const {
  //     match,
  //     // eventId,
  //     // $$content,
  //     setQuizManagerOption,
  //   } = this.props;
  //   const { eventId } = match.params;
  //   const { questionActive } = this.state;
  //   if (questionShowResults === undefined || questionShowResults === false) {
  //     // this.setState({
  //     //   questionShowResults: true
  //     // }, () => {
  //     setQuizManagerOption({
  //       eventId,
  //       questionId: questionActive,
  //       name: "showResults",
  //       value: true,
  //     });
  //     // })
  //   } else {
  //     this.setState(
  //       {
  //         questionShowResults: undefined,
  //       },
  //       () => {
  //         setQuizManagerOption({
  //           eventId,
  //           questionId: questionActive,
  //           name: "showResults",
  //           value: false,
  //         });
  //       }
  //     );
  //   }
  // };

  // _onQuizDeleteConfirmed = async ($$item) => {
  //   const url = config.quiz + "/delete";
  //   const token = await getToken();
  //   const data = { id: $$item.get("id"), csrf: token };
  //   fetch(url, getFetchOptions("POST", data))
  //   .then((response) => response.json())
  //   .then((response) => {
  //     if (response.deleted && response.deleted === "complete") {
  //       const { eventId, onMount } = this.props;
  //       this.setState({
  //         dialogDeleteState: undefined,
  //         questionSelected: undefined,
  //       });
  //       onMount({ eventId });
  //       // this.setState({redirectToList: true, locked: false});
  //     }
  //   });
  // };
  // _onQuizDeleteCanceled = ($$item) => {
  //   this.setState({ dialogDeleteState: undefined });
  // };
  // _onQuizDelete = () => {
  //   const { questionSelected } = this.state;
  //   this.setState({
  //     dialogDeleteState: Immutable.fromJS({ id: questionSelected }),
  //   });
  // };
  //
  // _onQuizResetConfirmed = ($$item) => {
  //   const {
  //     // eventId,
  //     match,
  //     resetQuizQuestion,
  //   } = this.props;
  //   const { eventId } = match.params;
  //   resetQuizQuestion({
  //     eventId,
  //     questionId: $$item.get("id"),
  //   });
  //   this._onQuizResetCanceled();
  // };
  // _onQuizResetCanceled = ($$item) => {
  //   this.setState({ dialogResetState: undefined });
  // };
  // _onQuizReset = () => {
  //   const { questionSelected } = this.state;
  //   this.setState({
  //     dialogResetState: Immutable.fromJS({ id: questionSelected, kind: 'quiz' }),
  //   });
  // };
  //
  // _onFeedbackReset = () => {
  //   // console.log('click')
  //   const { questionSelected } = this.state;
  //   this.setState({
  //     dialogResetState: Immutable.fromJS({ id: questionSelected, kind: 'feedback' }),
  //   });
  // };
  // _onFeedbackResetConfirmed = ($$item) => {
  //   const {
  //     // eventId,
  //     match,
  //     resetFeedback,
  //   } = this.props;
  //   const { eventId } = match.params;
  //   resetFeedback({
  //     eventId,
  //   });
  //   this._onFeedbackResetCanceled();
  // };
  // _onFeedbackResetCanceled = ($$item) => {
  //   this.setState({ dialogResetState: undefined });
  // };
  //
  //
  // _getItemFeedback = () => {
  //   const { eventId } = this.props.match.params;
  //   const { $$quizQuestions } = this.props;
  //   return Immutable.fromJS({
  //     quizParent: eventId,
  //     id: "feedback",
  //     quizType: "feedback",
  //     quizPosition:
  //     $$quizQuestions !== undefined ? $$quizQuestions.size + 1 : 1,
  //   });
  // };
  // _getItemQuestion = (questionId) => {
  //   const { $$quizQuestions } = this.props;
  //   return $$quizQuestions
  //   .get("rows")
  //   .filter(($$q) => $$q.get("id") === questionId)
  //   .get(0);
  // }
  // _getItemAnnounce = (questionId) => {
  //   const { eventId } = this.props.match.params;
  //   const { $$announceQuestions } = this.props;
  //   // return Immutable.fromJS({
  //   //   quizParent: eventId,
  //   //   id: "announce",
  //   //   quizType: "announce",
  //   //   quizPosition:
  //   //   $$announceQuestions !== undefined ? $$announceQuestions.size + 1 : 1,
  //   // });
  //   console.log($$announceQuestions.toJS(), questionId)
  //   return $$announceQuestions
  //   .get("rows")
  //   .filter(($$q) => $$q.get("id") === questionId)
  //   .get(0);
  //
  // };
  _showTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };
  render() {
    const {
      $$user,
      classes,
      // $$quizQuestions,
      // $$announceQuestions,
      // eventId,
      match,
      // $$results,
      $$eventState,
      // history,
    } = this.props;
    const { eventId } = match.params;
    const {
      // errored,
      // choice,
      // showResults,
      // questionSelected,
      // questionActive,
      // questionShowResults,
      activeTab,
      // dialogDeleteState,
      // dialogResetState,
    } = this.state;
    // const gridQuestionsSize = 4;
    const $$event = Immutable.fromJS({
      id: eventId,
    });
    // console.log({questionShowResults})
    // console.log({$$results})
    // console.log({
    //   questionActive
    // })
    // const questionTypes = { poll: "Sondage", quiz: "Question" };
    // if ($$user !== undefined)
    const userRole = $$user !== undefined ? $$user.get("role") : "visitor";
    const userSubRole =
    $$user !== undefined ? $$user.get("subRole") : undefined;
    // if ($$user !== undefined)
    // console.log($$user.toJS())
    // console.log({userRole, userSubRole});
    // const isAdmin =
    //   $$user !== undefined ? $$user.get("role") === "admin" : false;
    // const isAdmin =
    //   userRole === 'admin' || (userRole === 'staff' && ['buadm', 'mod'].indexOf(userSubRole) >=0)
    const isAdmin = userRole === "admin";
    // const $$selectorOptions =
    //   $$quizQuestions !== undefined
    //     ? $$quizQuestions.get("rows").map(($$r) =>
    //         Immutable.fromJS({
    //           label:
    //             questionTypes[$$r.get("quizType")] +
    //             " N°" +
    //             $$r.get("quizPosition") +
    //             " : " +
    //             $$r.get("quizQuestion"),
    //           value: $$r.get("id"),
    //         })
    //       )
    //     : Immutable.fromJS([]);
    // let $$selectorOptions = Immutable.fromJS([]);

//     if (activeTab === "quiz") {
//     //   $$selectorOptions = $$quizQuestions.get("rows").map(($$r) =>
//     //   Immutable.fromJS({
//     //     label:
//     //     questionTypes[$$r.get("quizType")] +
//     //     " N°" +
//     //     $$r.get("quizPosition") +
//     //     " : " +
//     //     $$r.get("quizQuestion"),
//     //     value: $$r.get("id"),
//     //   })
//     // )
//   } else if (activeTab === "announce" && $$announceQuestions !== undefined) {
//     $$selectorOptions = $$announceQuestions.get("rows").map(($$r) =>
//     // Immutable.fromJS({
//     //   label:
//     //   questionTypes[$$r.get("quizType")] +
//     //   " N°" +
//     //   $$r.get("quizPosition") +
//     //   " : " +
//     //   $$r.get("quizQuestion"),
//     //   value: $$r.get("id"),
//     // })
//     Immutable.fromJS({
//       label: $$r.get("announceQuestion"),
//       value: $$r.get("id"),
//     })
//   )
// }

let headerGridSize;
if (userSubRole === 'mod' || userSubRole === 'buadm')
headerGridSize = 12;
if (isAdmin === true)
headerGridSize = 2;
// const headerGridSize = isAdmin || userSubRole === "mod" ||
//     userSubRole === "buadm"? 3 : 4;

// console.log({isAdmin, userSubRole, headerGridSize})
// console.log({dialogResetState})
// console.log('TEST', $$user, $$event)
// console.log({questionActive})
// console.log({questionSelected})
return (
  <Template
    current={"account"}
    headerImage={"admin"}
    condensed={false}
    headerContent={undefined}
    >
    <Grid
      item={true}
      xs={12}
      className={classes.root}
      container
      spacing={0}
      alignContent="flex-start"
      style={{}}
      >
      <If condition={$$event !== undefined && $$user !== undefined}>
        <SocketHelper
          $$event={$$event}
          $$user={$$user}
          endpoint={"presenter"}
          pull={USE_WEBSOCKET === false}
          />
      </If>

      {/*<Grid item xs={12} align="center">
        <Typography variant="h2" color="primary" className={classes.onAir}>
          On Air :&nbsp;
          <Choose>
            <When
              condition={
                $$eventState !== undefined &&
                ["quiz", "poll"].indexOf($$eventState.get("contentType")) >=
                0
              }
              >
              Quiz
            </When>
            <When
              condition={
                $$eventState !== undefined &&
                $$eventState.get("contentType") === "feedback"
              }
              >
              Feedback
            </When>
            <When
              condition={
                $$eventState !== undefined &&
                $$eventState.get("contentType") === "announce"
              }
              >
              Annonce
            </When>
            <Otherwise>Messages</Otherwise>
          </Choose>
        </Typography>
      </Grid>*/}

      <If condition={isAdmin === true}>
        <Grid item xs={headerGridSize} align="center">
          <Button
            variant="contained"
            color={activeTab === "messages" ? "primary" : ($$eventState !== undefined && $$eventState.get('contentType') === 'none' ? 'secondary' : undefined)}
            onClick={(e) => {
              e.preventDefault();
              this._showTab("messages");
            }}
            >
            <Badge badgeContent={$$eventState !== undefined && $$eventState.get('contentType') === 'none' ? 'Live !' : undefined} color="primary">
            Messages
            </Badge>
          </Button>
        </Grid>
        <Grid item xs={headerGridSize} align="center">
          <Button
            variant="contained"
            color={activeTab === "quiz" ? "primary" : ($$eventState !== undefined && $$eventState.get('contentType') === 'quiz' ? 'secondary' : undefined)}
            onClick={(e) => {
              e.preventDefault();
              this._showTab("quiz");
            }}
            >
            <Badge badgeContent={$$eventState !== undefined && $$eventState.get('contentType') === 'quiz' ? 'Live !' : undefined} color="primary">
            Quizz
          </Badge>
          </Button>
        </Grid>
        <Grid item xs={headerGridSize} align="center">
          <Button
            variant="contained"
            color={activeTab === "feedback" ? "primary" : ($$eventState !== undefined && $$eventState.get('contentType') === 'feedback' ? 'secondary' : undefined)}
            onClick={(e) => {
              e.preventDefault();
              this._showTab("feedback");
            }}
            >
            <Badge badgeContent={$$eventState !== undefined && $$eventState.get('contentType') === 'feedback' ? 'Live !' : undefined} color="primary">
            Feedback
          </Badge>
          </Button>
        </Grid>
        <Grid item xs={headerGridSize} align="center">
          <Button
            variant="contained"
            color={activeTab === "announce" ? "primary" : ($$eventState !== undefined && $$eventState.get('contentType') === 'announce' ? 'secondary' : undefined)}
            onClick={(e) => {
              e.preventDefault();
              this._showTab("announce");
            }}
            >
            <Badge badgeContent={$$eventState !== undefined && $$eventState.get('contentType') === 'announce' ? 'Live !' : undefined} color="primary">
            Annonce
          </Badge>
          </Button>
        </Grid>
      </If>
      <If condition={isAdmin === true || userSubRole === "mod" ||
        userSubRole === "buadm"}>
        <Grid item xs={headerGridSize} align="center">
          <Button
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              window.open("/live/" + eventId);
            }}
            >
            Voir le Live
          </Button>
        </Grid>
      </If>

      <If condition={activeTab === "messages"}>
        <Paper className={classes.paperWrapper}>
          <Box m={3} align="center">
            <Typography variant="h3" gutterBottom>
              Messages
            </Typography>
            <AdminMessageGrid eventId={eventId} />
          </Box>
        </Paper>
      </If>

      <If condition={activeTab === "quiz"}>
        <AdminOrchestratorQuiz />
        </If>
        <If condition={activeTab === "feedback"}>


        <AdminOrchestratorFeedback/>
        </If>
        <If condition={activeTab === "announce"}>
          <AdminOrchestratorAnnounce />
          </If>
        </Grid>
    </Template>
  );
}
}

function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
    $$user: getCurrentUser(state),
    // $$quizQuestions: getQuizQuestions(state),
    // $$announceQuestions: getAnnouncesData(state),
    // $$content: getContent(state),
    // $$managerResults: getQuizManagerResults(state),
    // $$results: getQuizResults(state),
    $$eventState: getEventState(state),
    $$adminEventState: getAdminEventState(state),
    // feedbackReset: getFeedbackReset(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      if (props.eventId !== undefined) {
        // dispatch(fetchQuizQuestions(props));
        // dispatch(fetchAnnounces(props));
        dispatch(fetchEventState(props));
      }
    },
    onUnmount: () => {
      // dispatch(clearQuizQuestions());
      // dispatch(clearAnnounceData());
    },
    // refreshQuestions: (props) => {
    //   if (props.eventId !== undefined) {
    //     dispatch(fetchQuizQuestions(props));
    //   }
    // },
    // refreshAnnounces: (props) => {
    //   if (props.eventId !== undefined) {
    //     dispatch(fetchAnnounces(props));
    //   }
    // },
    sendMessage: (payload) => {
      dispatch(sendMessage(payload));
    },
    // setQuizManagerOption: (props) => {
    //   if (props.eventId !== undefined) {
    //     dispatch(setQuizManagerOption(props));
    //   }
    // },
    // clearQuizManagerResults: () => {
    //   dispatch(clearQuizManagerResults());
    // },
    // resetQuizQuestion: (props) => {
    //   dispatch(resetQuizQuestion(props));
    // },
    // resetFeedback: (props) => {
    //   dispatch(resetFeedback(props));
    // },
    setEventState: (props) => {
      dispatch(setEventState(props));
    },
    setAdminEventState: (props) => {
      dispatch(setAdminEventState(props));
    },
  };
}
// export default ;
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AdminQuizControl))
);
