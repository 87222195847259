import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import i18n from 'helpers/i18n';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';

const styles = (theme) => {
  return {
    root: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      '& .MuiTypography-body1': {
        fontSize: '0.9375rem',
        // paddingLeft: theme.spacing(4),
        // paddingRight: theme.spacing(4),
      },
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      position: 'relative',
    },
    background: {
      transition: 'background-color .5s ease-out',
      backgroundColor: '#FFC337',
      width: '100%',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(20),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
    },
    wrapper: {
      maxWidth: 1280,
    },
    icon: {
      border: '1px transparent solid',
      borderRadius: '180px',
      padding: 6,
      width: '2.5rem',
      height: '2.5rem',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    itemWrapper: {
      marginTop: -152,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    item: {
      border: '6px transparent solid',
      backgroundColor: '#EFEBEF',
      padding: '1.3rem',
      borderRadius: '180px',
      aspectRatio: 1,
      width: '16rem',
      textAlign: 'center',
      margin: '0 auto',
      '& .MuiTypography-body1': {},
      '& img': {
        width: 78,
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
          width: 65,
          marginTop: theme.spacing(1.5),
          marginBottom: theme.spacing(0.5),
        },
      },
      [theme.breakpoints.down('sm')]: {
        '&:first-of-type': {
          marginTop: theme.spacing(3),
        },
        marginBottom: theme.spacing(1),
      },
    },
    title: {
      color: '#000',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.9875rem',
      },
    },
    subTitle: {
      color: '#000',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8375rem',
      },
    },
  };
};
class HomeIntro extends Component {
  render() {
    const { $$intro, classes, itemsStyle, background } = this.props;
    const colors = ['#fff', '#fff', '#fff'];
    return (
      <Grid item xs={12} container className={classes.root} justifyContent="center">
        <Grid
          item
          container
          spacing={0}
          alignItems="center"
          justifyContent="space-around"
          className={classes.wrapper}
        >
          <Box
            p={2}
            className={classes.background}
            mb={2}
            style={{ backgroundImage: background !== undefined ? 'url(' + background + ')' : undefined }}
          >
            <If condition={i18n.has('homeIntroTitle')}>
              <Grid item xs={12}>
                <Typography variant={'h2'} align="center" className={classes.title}>
                  {i18n.get('homeIntroTitle')}
                </Typography>
              </Grid>
            </If>
            <If condition={i18n.has('homeIntroSubTitle')}>
              <Grid item xs={12}>
                <Typography variant={'body1'} align="center" className={classes.subTitle}>
                  {i18n.get('homeIntroSubTitle')}
                </Typography>
              </Grid>
            </If>
          </Box>
          <Grid item container alignContent="center" justifyContent="center" className={classes.itemWrapper}>
            {$$intro
              .map(($$i, iIdx) => {
                const mdSize = $$intro.size === 2 ? 6 : 3;
                return (
                  <Grid item xs={12} sm={6} md={mdSize} key={'int_' + iIdx}>
                    <Box
                      className={classes.item}
                      style={{ borderColor: colors[iIdx] }}
                      style={itemsStyle && itemsStyle[iIdx] ? itemsStyle[iIdx] : undefined}
                    >
                      <img src={require('assets/images/homeIntro_' + (iIdx + 1) + '.png')} alt="Intro" />
                      <Typography variant="body1" align="center" gutterBottom component="div">
                        <Paragrapher text={$$i.get('title')} prettyfy={true} element="div" />
                      </Typography>
                    </Box>
                  </Grid>
                );
              })
              .toArray()}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(HomeIntro));
