import React,{Component} from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
// import Immutable from 'immutable';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import DynamicForm from 'components/UI/DynamicForm';
// import {
//   Redirect,
// } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import i18n from 'helpers/i18n';

// import Form from 'components/UI/Form/Form';
import {
  // send as formSubmitAction,
  hasSentFailed as isFormErrored,
  getLog as getFormLog,
  getError as getFormError,
  isSending as isFormRunning,
  isSent as isFormComplete,
} from 'ducks/contact';

import { getCurrentUser } from 'components/UI/Account/ducks/authentication';

import config from 'config/api';

// const CONFIG = {
// btCancel: true,
// btCancelLabel: 'Retour'
// }
const moduleConfig = {
  module: 'job',
  fetchUrl: config.job + '/edit',
  postUrl: config.job + '/edit',
  redirectAfterEdit: false,
  afterEdit: 'onFormComplete',
  afterEditLog: '',
  afterEditHideForm: true,
  btCancel: false,
  btSubmitLabel: i18n.get('subscriptionAction', {pretty: false}),
  buttonVariant: 'contained',

};

class JobForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // let $$FIELDS;

    // let jobs = config.getJobs(props.fieldsSet)
    // jobs.map(job => job.label = i18n.get(job.label, {pretty: false}))
    //
    // const FIELDS_FULL = [
    //   {
    //     id: 'accountJob',
    //     name: 'accountJob',
    //     label: 'Profession',
    //     validation: ['notEmpty'],
    //     type: 'select',
    //     placeholder: 'hello',
    //     // options: [
    //     //   {'label': 'Médecin', value: 'md'},
    //     //   // {'label': 'Sage-femme', value: 'sf'},
    //     //   {'label': 'Chiesi', value: 'lb'},
    //     // ],
    //     options: jobs,
    //     sizes: { xs: 12 },
    //   },
    // ];

    // this.$$FIELDS = Immutable.fromJS(FIELDS_FULL);
    // this.$$CONFIG = Immutable.fromJS({
    //   buttonVariant: 'contained',
    //   buttonColorSubmit: 'primary'
    // });

    // this._onSubmit = this._onSubmit.bind(this);
    // this.$$FIELDS = $$FIELDS;
    // this._onFormSubmit = this._onFormSubmit.bind(this);
    // this._onFieldValueChange = this._onFieldValueChange.bind(this);
  }

  componentDidMount() {
    // this._setupState();
    // this._mapRawValues(this.props.$$formValues);
  }
  // _mapRawValues($$values) {
  //   if ($$values === undefined || !$$values.get) return;
  //   var o = {};
  //   // return Immutable.fromJS(o);
  //   // return $$values;
  //   // var tmp = {}
  //   // $$values.map((val, k) => {
  //   Immutable.fromJS(o).map((val, k) => {
  //     this._onFieldValueChange(null, { id: k, value: val });
  //     return true;
  //   });
  //   // return $$values;
  //   return Immutable.fromJS(o);
  // }
  _onSubmit = (formData) => {
    // console.log(formData);
    if (formData.accountJob === 'link@contact') return this.props.history.push('/contact?s=3');
    else this.props.onFormComplete({ values: formData });
  }

  // _onFormComplete = (formData) => {
  _onFormComplete = (formData) => {
    this.props.onFormComplete({ values: formData });
  }

  render() {
    const {
      className,
      log,
      isFormComplete,
    } = this.props;
    return (
      <div className={clsx('Form', className)} >
        <If condition={!isFormComplete}>
          <DynamicForm
            moduleConfig={moduleConfig}
            onFormComplete={this._onFormComplete}
          />
        </If>
        <If condition={isFormComplete}>
          <Typography variant="h5">{log}</Typography>
        </If>
      </div>
    );
  }
}

const mapStateToProps = ($$state) => {
  return {
    isFormErrored: isFormErrored($$state),
    log: getFormLog($$state),
    error: getFormError($$state),
    loading: isFormRunning($$state),
    isFormComplete: isFormComplete($$state),
    $$formValues: getCurrentUser($$state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onSubmit: (formData) => {
    //   dispatch(formSubmitAction(formData));
    // },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobForm));
