import React, { Component } from "react";
import Form from "./FaqForm";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
const styles = {
  paper: {
    maxWidth: 1024,
    padding: "10px 20px",
    margin: "20px auto 20px",
  },
};
class AdminFaqForm extends Component {
  constructor(props) {
    super(props);
    this._onClose = this._onClose.bind(this);
  }
  _onClose() {
    const { history } = this.props;
    history.push("/staff/faq/list");
  }
  render() {
    const { id, classes } = this.props;
    return (
      <div>
        <Paper classes={{ root: classes.paper }}>
          <Form
            id={id}
            onFormComplete={this._onClose}
            onFormCancel={this._onClose}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(AdminFaqForm));
