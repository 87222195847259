import { Component } from "react";
// import PropTypes from 'prop-types';
// import ModuleConfigProvider from 'components/UI/ModuleConfigProvider';
// import { connect } from 'react-redux';
// import Immutable from 'immutable';

// import {
// fetchData,
// postData,
// getDataFaq,
// getLoadingError,
// } from 'ducks/asyncFetcher';
// import { withRouter } from 'react-router-dom';

class DynamicListComponent extends Component {
  setupList(moduleConfig) {
    // console.log('RUNNING SETUP !!')
    // if (!this.state) this.state = {}
    // this.state.answerVisible = null;
    // this.state.dialogDeleteState = null;
    this.setState({
      answerVisible: null,
      dialogDeleteState: null,
    });
    this._onDelete = this._onDelete.bind(this);
    this._onDeleteConfirm = this._onDeleteConfirm.bind(this);
    this._onDeleteCancel = this._onDeleteCancel.bind(this);
    this._onFormComplete = this._onFormComplete.bind(this);
    this.moduleConfig = moduleConfig;
  }

  _onDeleteConfirm(item) {
    // console.log('deleting', item)
    const { onDeleteRow } = this.props;
    const { dialogDeleteState } = this.state;
    const current = dialogDeleteState;
    // this.setState({dialogDeleteState: item})
    // const t = this.state.dialogDeleteState;
    this.setState({ dialogDeleteState: false }, function () {
      onDeleteRow(this.moduleConfig, current.get("id"));
    });
  }
  _onDeleteCancel() {
    this.setState({ dialogDeleteState: false });
    // this.props.onDelete(item)
  }
  _onDelete(e, $$item) {
    this.setState({ dialogDeleteState: $$item });
    // this.setState({answerVisible: visibility})
  }
  _onEdit(e, $$item) {
    this.props.history.push(this.moduleConfig.editUrl + "/" + $$item.get("id"));
    // this.setState({answerVisible: visibility})
  }

  // _onFormComplete() {
  //   console.log('should refresh now!');
  //   this.props.onMount(this.moduleConfig);
  // }
}

// HelpFaq.propTypes = {
//   $$data: PropTypes.oneOfType([
//     PropTypes.object,
//     PropTypes.instanceOf(Immutable.List)
//   ]),
//   userRole: PropTypes.string
//   // userRole: PropTypes.string.isRequired
//   // userRole: PropTypes.number
// };

export default DynamicListComponent;
