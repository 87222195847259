import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import ReplayPlayer from 'components/UI/Replays/ReplayPlayer';

const ReplayPlayerStyled = withStyles((theme) => {
  return {
    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '& .MuiTypography-h5': {
        // color: '#fff',
      },
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.secondary.dark,
        // backgroundImage: 'url('+require('assets/images/header_replay.jpg')+')',
        backgroundResize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: -1,
      },
    },
    breadCrumbs: {
      color: '#fff',
      '& a': {
        color: '#fff',
      },
    },

    itemVideo: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    itemDescription: {
      marginBottom: theme.spacing(2),
    },
    itemDuration: {
      marginBottom: theme.spacing(2),
    },
    itemCopyright: {
      color: theme.palette.primary.main,
    },
    itemGuests: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  };
})(ReplayPlayer);

class ReplayPlayerWrapper extends Component {
  render() {
    return <ReplayPlayerStyled templateProps={{ kind: 'fullWidth', maxWidth: 'auto' }} />;
  }
}

export default ReplayPlayerWrapper;
