import mirrorCreator from "mirror-creator";
import Immutable from "immutable";
import { createSelector } from "reselect";
import pipe from "helpers/redux-state-mutators.js";
import queryString from "query-string";
import config from "config/api";
// import history from '../helpers/history'

// import { clearAllState } from '../ducks/global'

const actionTypes = mirrorCreator(
  [
    "SET_CURRENT_USER",
    "SET_RESET_PASSWORD",
    "SET_ERROR",
    "SET_LOGGING_IN",
    "SET_STATE_KNOWN",
    "SET_PENDING_ACCOUNTS",
    "SET_UNIVERSE",
    "SET_USER_UPDATED_OPTINS",
  ],
  { prefix: "authentication/" }
);

const mutators = {
  setCurrentUser: (user) => ($$state) =>
    $$state.set("user", Immutable.fromJS(user)),
  setPendingAccounts: (count) => ($$state) =>
    $$state.set("pendingAccounts", count),
  setResetPassword: (step, result, log) => ($$state) =>
    $$state.set("resetPassword", Immutable.fromJS({ step, result, log })),
  clearErrors: ($$state) => $$state.delete("error"),
  setError: (error) => ($$state) => $$state.set("error", error),
  setLoggingIn: (loggingIn) => ($$state) => $$state.set("loggingIn", loggingIn),
  setUniverse: (universe) => ($$state) => $$state.set("universe", universe),
  setStateKnown: (stateKnown = true) => ($$state) =>
    $$state.set("stateKnown", stateKnown),
};

export default function reducer($$state = Immutable.Map(), action) {
  switch (action.type) {
    case actionTypes.SET_CURRENT_USER:
      return pipe(
        [
          mutators.setCurrentUser(action.user),
          mutators.clearErrors,
          mutators.setStateKnown(),
        ],
        $$state
      );

    case actionTypes.SET_RESET_PASSWORD:
      return pipe(
        [
          mutators.setResetPassword(action.step, action.result, action.log),
          mutators.clearErrors,
          mutators.setStateKnown(),
        ],
        $$state
      );

    case actionTypes.SET_ERROR:
      return pipe([mutators.setError(action.error)], $$state);

    case actionTypes.SET_LOGGING_IN:
      return pipe([mutators.setLoggingIn(action.status)], $$state);

    case actionTypes.SET_STATE_KNOWN:
      return pipe([mutators.setStateKnown(action.stateKnown)], $$state);

    case actionTypes.SET_PENDING_ACCOUNTS:
      return pipe([mutators.setPendingAccounts(action.count)], $$state);

    case actionTypes.SET_UNIVERSE:
      return pipe([mutators.setUniverse(action.universe)], $$state);
    
    case actionTypes.SET_USER_UPDATED_OPTINS:
        console.log("setUserUpdatedOptins", action.value);
        return $$state.setIn(['user', 'hasUpdatedOptins'], action.value);
  
    default:
      return $$state;
  }
}

export const getRoot = (state) => state.authentication || Immutable.Map();
export const isStateKnown = createSelector([getRoot], ($$state) =>
  $$state.get("stateKnown")
);

export const isLoggingIn = createSelector([getRoot], ($$state) =>
  $$state.get("loggingIn", false)
);
export const isLoggedIn = createSelector([getRoot], ($$state) => {
  // if ($$state.get('user') !== undefined)
  // console.log($$state.get('user').toJS())
  return (
    $$state.get("user") !== undefined &&
    $$state.get("user") !== false &&
    $$state.get("user").get("id") !== false
  );
});
export const getResetPassword = createSelector([getRoot], ($$state) =>
  $$state.get("resetPassword") !== undefined
    ? $$state.get("resetPassword")
    : false
);
// export const isUserStatusOk = createSelector(
//   [getRoot],
//   $$state => ($$state.get('user') !== undefined && $$state.get('user') !== false && $$state.getIn(['user', 'status']) == 'ok')
// );
export const getUniverse = createSelector(
  [getRoot],
  ($$state) => $$state.get("universe", undefined)
);
export const isLoggedOut = createSelector(
  [getRoot],
  ($$state) => $$state.get("user") !== true
);
export const getPendingAccounts = createSelector(
  [getRoot],
  ($$state) => $$state.get("pendingAccounts", 0)
);
// export const getCurrentUserStatus = createSelector(
//   [getRoot],
//   $$state => ($$state.getIn(['user', 'status']))
// );
// export const getCurrentUserName = createSelector(
//   [getRoot],
//   $$state => ($$state.getIn(['user', 'name']))
// );
export const getCurrentUserRole = createSelector([getRoot], ($$state) =>
  $$state.getIn(["user", "role"])
);
export const getCurrentUserSubRole = createSelector([getRoot], ($$state) =>
  $$state.getIn(["user", "subRole"])
);
export const getCurrentUser = createSelector([getRoot], ($$state) =>
  $$state.getIn(["user"])
);
export const getCurrentUserId = createSelector([getRoot], ($$state) =>
  $$state.getIn(["user", "id"])
);
// export const getCurrentUserGroup = createSelector(
//   [getRoot],
//   $$state => ($$state.getIn(['user', 'group']))
// );
// export const getCurrentUserFirstname = createSelector(
//   [getRoot],
//   $$state => ($$state.getIn(['user', 'firstname']))
// );
// export const getCurrentUserLastname = createSelector(
//   [getRoot],
//   $$state => ($$state.getIn(['user', 'lastname']))
// );
// // export const getCurrentUserInitials = createSelector(
// //   [getRoot],
// //   $$state => {
// //     const firstname = $$state.getIn(['user', 'firstname']);
// //     const lastname = $$state.getIn(['user', 'lastname']);
// //     return (firstname[0] + ' '+lastname[0]);
// //   }
// // );
// export const getCurrentUserAvatar = createSelector(
//   [getRoot],
//   $$state => ($$state.getIn(['user', 'avatar']))
// );
// export const getCurrentUserGroup = () => {
//   return '100';
// }

export const hasLoginFailed = createSelector(
  [getRoot],
  ($$state) =>
    $$state.get("error") !== undefined && $$state.get("error") !== null
);

export const getError = createSelector([getRoot], ($$state) =>
  $$state.get("error")
);

export function login(postValues) {
  // console.log({login, password})
  return (dispatch) => {
    let url = postValues.kind === 'private' ? config.private : config.login;
    dispatch(setLoggingIn(true));
    let values = postValues !== undefined ? postValues : {};
    if (!values) {
      return dispatch(setError("no values !"));
    }
    // console.log({values})
    values.authChallenge = "true";
    // console.log({values})
    fetch(url, {
      credentials: "include",
      method: config.urlMethod || "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: queryString.stringify(values),
    })
      //.then((result) => new Promise((resolve) => setTimeout(() => resolve(result), 1000)))
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        // dispatch(setLoggingIn(false));
        if (response.auth && response.auth === "ok") {
          dispatch(setCurrentUser(response.user));
          dispatch(setPendingAccounts(response.pendingAccounts));
          dispatch(setStateKnown(true));
        } else {
          dispatch(setError(response.log));
          dispatch(setStateKnown(true));
        }
        setLoggingIn(false);
      })
      .catch((error) => dispatch(setError(error.message)))
      .finally(() => dispatch(setLoggingIn(false)));
  };
}
export function setUniverse(universe) {
  // console.log({login, password})
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_UNIVERSE,
        universe,
      });
  };
}

export const getToken = (kind) => {
  // console.log({login, password})
  let url = config.ping;
  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: "include",
      method: config.urlMethod || "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
    })
      .then((response) => response.json())
      .then((response) => {
        //.then((result) => new Promise((resolve) => setTimeout(() => resolve(result), 1000)))
        if (response.pong && response.pong === "complete") {
          return resolve(response.token);
        } else {
          return resolve(undefined);
        }
      });
  });
};

export function resetPassword(mode, login, key, password, passwordConf) {
  return (dispatch) => {
    let url = mode === "request" ? config.rpwd : config.cpwd;
    dispatch(setLoggingIn(true));

    fetch(url, {
      credentials: "include",
      method: config.urlMethod || "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: queryString.stringify({
        email: login,
        key,
        password,
        passwordConf,
        authReset: true,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.sent && response.sent === "ok")
          return dispatch(setResetPassword(mode, true, response.log));
        else if (response.challenge && response.challenge === "ok")
          return dispatch(setResetPassword(mode, true, response.log));
        else return dispatch(setError(response.log));
      })
      .catch((error) => dispatch(setError(error.message)))
      .finally(() => dispatch(setLoggingIn(false)));
  };
}

export function destroyAccount({password}) {
  return async (dispatch) => {
    // console.log('will do', password)
    const url = config.dpwd;
    const csrf = await getToken();

    dispatch(setError(undefined));
    fetch(url, {
      credentials: "include",
      method: "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: queryString.stringify({
        csrf,
        password,
        authDestroy: true,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log({response})
        if (response.deleted && response.deleted === true) {
          dispatch(setCurrentUser(undefined));
        } else return dispatch(setError(response.log));
      })
      .catch((error) => dispatch(setError(error.message)))
  };
}

export function logout() {
  return (dispatch) => {
    let url = config.logout;

    fetch(url, {
      credentials: "include",
      method: config.urlMethod || "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: queryString.stringify({
        logout: true,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        // if (response.redirect) {
        // dispatch(clearAllState())
        dispatch(setStateKnown(true));
        dispatch(setCurrentUser(undefined));

        // history.push('/')
        // } else
        // return dispatch(setError(response.log));
      })
      .catch((error) => dispatch(setError(error.message)));
    // .finally(() => dispatch(setLoggingIn(false)))
  };
}

export function fetchCurrentUser(uri) {
  return (dispatch) => {
    console.log('fetchCurrentUser!')
    let url = config.url.login;
    if (uri.k) url = url + "?key=" + uri.k;
    fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.auth || response.auth !== "ok")
          throw new Error("No user received");
        else {
          console.log('GOGOGO')
          dispatch(setCurrentUser(response.user));
          dispatch(setPendingAccounts(response.pendingAccounts));
        }
      })
      .catch((error) => {
        dispatch(setStateKnown(true));
        // Rediriger vers login

        throw error;
      });
  };
}

export function setPendingAccounts(count) {
  return {
    type: actionTypes.SET_PENDING_ACCOUNTS,
    count,
  };
}
export function setCurrentUser(user) {
  return {
    type: actionTypes.SET_CURRENT_USER,
    user,
  };
}
export function setResetPassword(step, result, log) {
  return {
    type: actionTypes.SET_RESET_PASSWORD,
    step,
    result,
    log,
  };
}

export function setError(error) {
  return {
    type: actionTypes.SET_ERROR,
    error,
  };
}

export function setLoggingIn(loggingIn) {
  return {
    type: actionTypes.SET_LOGGING_IN,
    status: loggingIn,
  };
}

export function setStateKnown(stateKnown = true) {
  return {
    type: actionTypes.SET_STATE_KNOWN,
    stateKnown,
  };
}

export function setUserUpdatedOptins(value) {
  return {
    type: actionTypes.SET_USER_UPDATED_OPTINS,
    value,
  };
}
