import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';
import { getToken } from 'components/UI/Account/ducks/authentication';
import { getUniverse } from 'components/UI/Account/ducks/authentication'

const actionTypes = mirrorCreator(['SET_MEDIA_DATA', 'SET_MEDIAS_DATA', 'SET_MEDIA_ERROR', 'CLEAR_MEDIA', 'CLEAR_MEDIAS'], { prefix: 'media/' });

const mutators = {
  setMediaData: (media) => ($$state) => $$state.set('media', media),
  setMediaError: (error) => ($$state) => $$state.set('error', error),
  setMediasData: (data) => ($$state) => $$state.set('medias', data),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_MEDIAS_DATA:
      acts.push(mutators.setMediasData(action.medias));
      return pipe(acts, $$state);
    case actionTypes.SET_MEDIA_DATA:
      acts.push(mutators.setMediaData(action.media));
      return pipe(acts, $$state);
    case actionTypes.SET_MEDIA_ERROR:
      return pipe([mutators.setMediaError(action.error)], $$state);
    case actionTypes.CLEAR_MEDIA:
      return pipe([mutators.setMediaError(undefined), mutators.setMediaData(undefined)], $$state);
    case actionTypes.CLEAR_MEDIAS:
      return pipe([mutators.setMediaError(undefined), mutators.setMediasData(undefined)], $$state);
    default:
      return $$state;
  }
}

export const getRoot = (state) => state.media || Immutable.Map();
export const getMediaData = createSelector([getRoot], ($$state) => $$state.get('media'));
export const getMediasData = createSelector([getRoot], ($$state) => {
  // console.log('debug check', $$state.toJS())
  return $$state.get('medias')
});
export const getMediaError = createSelector([getRoot], ($$state) => $$state.get('error'));
export function fetchMedia({ mediaId, params }) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.medias + '/get/'+ mediaId;

    if (params.k !== undefined && params.r === undefined) url = url+'?k='+params.k;
    if (params.r !== undefined && params.k === undefined) url = url+'?r='+params.r;
    if (params.r !== undefined && params.k !== undefined) url = url+'?k='+params.k+'&r='+params.r;

    let formData = {};
    formData.contact_form = true;

    dispatch(clearMedia());

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.media && response.media === 'complete') {
          dispatch(setMediaData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setMediaError(response.log));
        }
      })
      .catch((error) => dispatch(setMediaError(error.message)));
  };
}
export function fetchMedias({ params }) {
  return (dispatch, getState) => {
    let url = config.medias + '/get/all';
    const universe = getUniverse(getState());
    if (universe !== undefined) url += '?universe='+universe
    // console.log('flushing medias')
    dispatch(clearMedias());
    // console.log('fetching medias')

    let formData = {};
    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.medias && response.medias === 'complete') {
          dispatch(setMediasData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setMediaError(response.log));
        }
      })
      .catch((error) => {
        dispatch(setMediaError(error.message))
      });
  };
}

export function cleanup() {
  return (dispatch) => {
    dispatch(clearMedia());
  }
}

export function setMediasData(medias) {
  return {
    type: actionTypes.SET_MEDIAS_DATA,
    medias,
  };
}
export function setMediaData(media) {
  return {
    type: actionTypes.SET_MEDIA_DATA,
    media,
  };
}
export function setMediaError(error) {
  return {
    type: actionTypes.SET_MEDIA_ERROR,
    error,
  };
}
export function clearMedia() {
  return {
    type: actionTypes.CLEAR_MEDIA,
  };
}
export function clearMedias() {
  return {
    type: actionTypes.CLEAR_MEDIAS,
  };
}
