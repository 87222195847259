import React, { Component } from 'react';

class Version extends Component {
  render() {
    const { app } = this.props;
    const SharedVersion="v 240229";
    console.log("App : "+app);
    console.log("Shared : "+SharedVersion);
  
    return (
      <span></span>
    );
  }
}
export default Version;
