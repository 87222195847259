import React, { Component } from "react";
import { connect } from "react-redux";
import config from "config/api";
import {  withRouter } from "react-router-dom";
import { getCurrentUserRole } from "components/UI/Account/ducks/authentication";
import List from "./AdminMessageList";
import Form from "./AdminMessageForm";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { formatDate } from "helpers/stringUtils";
import Template from 'components/UI/Template/Template'
import Box from '@material-ui/core/Box';

import {
  fetchData,
  getDataHome,
  // getLoadingError,
} from "ducks/asyncFetcher";

const AF_KEY = "home";

class AdminMessage extends Component {
  // constructor(props) {
  //   super(props);
  //   // this._runRemainingTimer = this._runRemainingTimer.bind(this);
  //   // this.state = {
  //   //   showLiveButton: false,
  //   //   timeRemaining: {},
  //   // };
  // }


  componentDidMount() {
    this.props.onMount();

  }
  render() {
    const { $$data, match, userRole } = this.props;
    // console.log(match)
    // if (userRole !== 'admin' && userRole !== 'member')
    // return <Redirect to={{
    //     pathname: '/',
    //     state: { from: location }
    //   }}/>
    return (
      <Template maxWidth='auto' current="admin" headerPosition={'relative'} headerImage={config.template && config.template.admin && config.template.admin.background ? config.template.admin.background : undefined}>
        <Box width="100%">
          <If condition={match.params !== undefined && match.params.action === "list" && match.params.id === undefined}>

            <If condition={$$data !== undefined && $$data.get('allEvents') !== undefined}>
              <Box mt={5} mb={6}>
                <Typography align="center" variant={'h2'} color="primary" gutterBottom={true}>Veuillez choisir la session à modérer</Typography>
              </Box>
              <Box>
                {$$data.get('allEvents').reverse().map(($$e) => {
                  return <Box key={'vt_'+$$e.get('id')} align="middle" m={2}>
                    <Typography variant={'h5'} gutterBottom={true} component="div">
                      <Paragrapher text={$$e.get('eventTitle')} prettyfy={true}/>
                    </Typography>
                    <Typography variant={'body1'} gutterBottom={true}>
                      {formatDate(
                        $$e.get("eventDate"),
                        "dddd dd mmmm yyyy '•' HH'h'MM"
                      )}
                    </Typography>
                    <Button variant="outlined" color="primary" onClick={(e) => {this.props.history.push('/staff/message/list/'+$$e.get('id'))}}>Voir les messages</Button>
                  </Box>
                }).toArray()}
              </Box>
            </If>
          </If>
          <If condition={match.params.action === "list" && match.params.id !== undefined}>
            <Box align="center" pt={4}>
              <Button variant={'outlined'}  onClick={() => this.props.history.push('/staff/message/list')} color="primary">Retour à la liste</Button>
              <If condition={userRole === 'staff' || userRole === 'admin'}>
                <Button variant={'outlined'} color={'primary'} onClick={() => window.open('/live/'+match.params.id)}>Ouvrir le live dans une autre fenêtre</Button>
              </If>
            </Box>
            <List parentList={match.params.id}/>
          </If>
          <If condition={match.params.action === "edit"}>
            <Form id={match.params.id} />
          </If>
        </Box>
      </Template>
    );
  };
};


function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    userRole: getCurrentUserRole(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
  };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminMessage));
