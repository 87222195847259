import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
// import Network from 'network-js';
// import worker_script from './SpeedTestWorker';
import config from "config/api";
import colors from "config/colors";
// import Worker from 'worker';
// if (process.env.NODE_ENV !== 'production')
// import CrossOriginWorker from 'co-web-worker';
// import CrossOriginWorker from './CrossOriginWorker.js';
import CrossOriginWorker from "@worker";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
// import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import IconOk from "@material-ui/icons/CheckCircle";
import IconError from "@material-ui/icons/ErrorOutline";
import IconWarning from "@material-ui/icons/Warning";
import BlockButton from "components/UI/BlockButton";
import i18n from "helpers/i18n";

// import net from 'net';
// import network from 'net';
// const FastSpeedtest = require("fast-speedtest-api");
var w = null; //speedtest worker

const style = {
  // paper: {
  //   padding: '20px 40px',
  //   margin: '0 20px 20px'
  // }
};
class SpeedTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTestRunning: false,
      pingStatus: "...",
      jitterStatus: "...",
      dlStatus: "...",
      ulStatus: "...",
      clientIp: "...",
      completion: 0,
    };
    this._timer = null;
  }

  componentWillUnmount() {
    // console.log('UNMOUNT')
    if (this._timer !== undefined) clearInterval(this._timer);

    if (w !== null) {
      w.postMessage("abort");
      w = null;
    }
  }

  _progress = () => {
    const { duration, onTestComplete } = this.props;
    const {
      completion: current,
      pingStatus,
      jitterStatus,
      dlStatus,
      ulStatus,
      clientIp,
    } = this.state;

    const completion = current + 0.5;

    if (completion === duration) {
      if (this._timer !== undefined) clearInterval(this._timer);
      // this.setState({ completed: 0 });
      this.setState(
        { completion: 100, isTestComplete: true, isTestRunning: false },
        function () {
          if (onTestComplete !== undefined)
            onTestComplete({
              pingStatus,
              jitterStatus,
              dlStatus,
              ulStatus,
              clientIp,
            });
        }
      );
    } else {
      this.setState({ completion: completion });
    }
  };

  _runTest() {
    const {
      duration,
      isTestRunning,
      // isTestComplete,
      onTestStart,
    } = this.props;
    // console.log({isTestRunning, isTestComplete})
    if (isTestRunning === true) return;

    if (onTestStart) onTestStart();
    if (w != null) {
      //speedtest is running, abort
      w.postMessage("abort");
      w = null;
    } else {
      var parameters = {
        //custom test parameters. See doc.md for a complete list
        time_dl_max: Math.round(duration / 2) - 1, //download test lasts up to 20 seconds
        time_ul_max: Math.round(duration / 2) - 1, //upload test lasts up to 20 seconds
        count_ping: 15, //ping+jitter test does 15 pings
        // getIp_ispInfo: false, //will only get IP address without ISP info
        test_order: "IP_D_U",
        time_auto: false,
        url_dl: config.stWorker + "/garbage.php", // path to a large file or garbage.php, used for download test. must be relative to this js file
        url_ul: config.stWorker + "/empty.php", // path to an empty file, used for upload test. must be relative to this js file
        url_ping: config.stWorker + "/empty.php", // path to an empty file, used for ping test. must be relative to this js file
        url_getIp: config.stWorker + "/getIP.php", // path to getIP.php relative to this js file, or a similar thing that
        // telemetry_level: 1,
        // xhr_dlMultistream: 4, //10
        // xhr_ulMultistream: 2, //3
        // outputs the client's ip
      };
      // w = new Worker('http://wecaweco.lan.new-net.net:35080/api/st/speedtest_worker.js');
      // if (process.env.NODE_ENV === 'production')
      // w = new Worker(config.stWorker+'/speedtest.worker.js');
      // else
      try {
        w = new CrossOriginWorker(config.stWorker + "/speedtest.worker.js");

        //test is not running, begin
        // w=new Worker(workerco);
        // return;
        w.onmessage = function (e) {
          var data = JSON.parse(e.data);
          var status = data.testState;
          // console.log('status is', data)
          if (status >= 4) {
            //test completed
            w = null;
          }
          this.setState({
            pingStatus: data.pingStatus,
            jitterStatus: data.jitterStatus,
            dlStatus: data.dlStatus !== "" ? data.dlStatus : "...",
            ulStatus: data.ulStatus !== "" ? data.ulStatus : "...",
            clientIp: data.clientIp,
          });
        }.bind(this);
        this.setState(
          { isTestRunning: true, isTestComplete: false, completion: 0 },
          function () {
            w.postMessage("start " + JSON.stringify(parameters)); //run the test with custom parameters
            this._timer = setInterval(
              function () {
                if (w) w.postMessage("status");
                this._progress();
              }.bind(this),
              500
            );
          }.bind(this)
        );
      } catch (e) {
        console.log("no interweb!");
        this.setState({
          isTestRunning: false,
          isTestComplete: false,
          completion: 0,
        });
      }
    }
  }
  // net.download.abort();
  // }
  render() {
    const {
      // title,
      // variant,
      duration,
      restart,
      // classes,
      // disabled,
    } = this.props;
    const {
      isTestRunning,
      isTestComplete,
      pingStatus,
      jitterStatus,
      dlStatus,
      ulStatus,
      clientIp,
      completion,
    } = this.state;

    const iconStyle = {
      verticalAlign: "middle",
      marginRight: 5,
      marginTop: "-2px",
    };
    let IconDownload = undefined;
    let TestResult = 0;
    if (dlStatus !== "" && dlStatus !== "...") {
      if (dlStatus < 1.8) {
        IconDownload = (
          <IconError
            style={{ ...iconStyle, marginLeft: 5, color: colors.red }}
          />
        );

        TestResult = (
          <span>
            <IconError
              style={{
                color: "red",
                verticalAlign: "middle",
                marginTop: "-2px",
                marginRight: 2,
              }}
            />{" "}
            Attention, la qualité de votre connexion Internet ne permet pas de
            garantir le bon déroulé de l'événement
          </span>
        );
      } else if (dlStatus < 2) {
        IconDownload = (
          <IconWarning
            style={{ ...iconStyle, marginLeft: 5, color: colors.orange }}
          />
        );

        TestResult = 2;
        TestResult = (
          <span>
            <IconWarning
              style={{
                color: "orange",
                verticalAlign: "middle",
                marginTop: "-2px",
                marginRight: 2,
              }}
            />{" "}
            Attention, la qualité de votre connexion Internet ne permet pas de
            garantir le bon déroulé de l'événement
          </span>
        );
      } else if (dlStatus >= 2) {
        IconDownload = (
          <IconOk
            style={{ ...iconStyle, marginLeft: 5, color: colors.green }}
          />
        );

        TestResult = 1;
        TestResult = (
          <span>
            <IconOk
              style={{
                color: "green",
                verticalAlign: "middle",
                marginTop: "-2px",
                marginRight: 2,
              }}
            />{" "}
            La qualité de votre connexion Internet est favorable au bon
            déroulement de l'événement
          </span>
        );
      }
    }
    if (isTestComplete && TestResult === 0) {
      TestResult = (
        <span style={{ color: "red" }}>
          <IconError
            style={{
              color: "red",
              verticalAlign: "middle",
              marginTop: "-2px",
              marginRight: 2,
            }}
          />{" "}
          Impossible de se connecter au serveur, êtes-vous bien connécté à
          Internet ?!
        </span>
      );
    }

    let IconUpload = undefined;
    if (ulStatus !== "" && ulStatus !== "...") {
      if (ulStatus < 0.5)
        IconUpload = (
          <IconError
            style={{ ...iconStyle, marginLeft: 5, color: colors.red }}
          />
        );
      else if (ulStatus < 1.1)
        IconUpload = (
          <IconWarning
            style={{ ...iconStyle, marginLeft: 5, color: colors.orange }}
          />
        );
      else if (ulStatus >= 1.2)
        IconUpload = (
          <IconOk
            style={{ ...iconStyle, marginLeft: 5, color: colors.green }}
          />
        );
    }
    let IconPing = undefined;
    if (pingStatus !== "" && pingStatus !== "...") {
      if (pingStatus < 250) {
        IconPing = (
          <IconOk
            style={{ ...iconStyle, marginLeft: 5, color: colors.green }}
          />
        );
      } else if (pingStatus < 700) {
        IconPing = (
          <IconWarning
            style={{ ...iconStyle, marginLeft: 5, color: colors.orange }}
          />
        );
      } else if (pingStatus >= 700) {
        IconPing = (
          <IconError
            style={{ ...iconStyle, marginLeft: 5, color: colors.red }}
          />
        );
      }
    }

    const progress = Math.round(completion * (100 / duration));
    return (
      <div style={{ overflow: "hidden", margin: '0 20px' }}>
        <Grid
          container
          spacing={8}
          alignItems="center"
          justifyContent="space-around"
          className={"grid"}
          style={{ opacity: isTestRunning || isTestComplete ? "1.0" : "0.3" }}
        >
          <Grid item xs={12}>
            <Typography>IP: {clientIp}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Ping : {pingStatus} ms
              <If condition={IconPing !== undefined}>{IconPing}</If>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Variation : {jitterStatus} ms</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Download : {dlStatus} Mbps
              <If condition={IconDownload !== undefined}>{IconDownload}</If>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Upload : {ulStatus} Mbps
              <If condition={IconUpload !== undefined}>{IconUpload}</If>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress variant="determinate" value={progress} />
          </Grid>
          <If condition={isTestComplete}>
            <Grid item xs={12}>
              {TestResult}
            </Grid>
          </If>
        </Grid>
        <div className="actions" style={{ padding: "20px 0" }}>
          <If
            condition={
              (restart === false && !isTestComplete) || restart !== false
            }
          >
            <BlockButton
              size="small"
              onClick={this._runTest.bind(this)}
              label={
                completion === 100
                  ? i18n.get("speedTestButtonRestart")
                  : i18n.get("speedTestButtonStart")
              }
              style={{
                margin: "0 auto",
                display: "block",
                opacity: isTestRunning ? 0.3 : 1,
              }}
              disabled={isTestRunning}
            />
          </If>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(SpeedTest);
