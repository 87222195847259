import React, { Component } from 'react';
// import { Route } from 'react-router-dom'
import cropStr from 'mout/string/crop';
import { withRouter } from "react-router-dom";
import i18n from 'helpers/i18n';
import Immutable from 'immutable';
import combine from "mout/array/combine";
// import Rating from "@material-ui/lab/Rating";
import Typography from '@material-ui/core/Typography';
import Select from 'components/UI/Form/Select';
import config from 'config/api';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchDashboardStats, clearDashboardStats, getDashboardError, getDashboardStats } from './ducks/dashboard';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
// import Paper from '@material-ui/core/Paper';
// import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
// import Prettyfier from 'components/UI/Prettyfier';
// import { formatDate } from "helpers/stringUtils";
import Chart from "react-google-charts";

const styles = (theme) => {
  return {
    root: {
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
      '& .MuiTypography-h1': {
        fontSize: '2.4rem',
        marginBottom: theme.spacing(4)
      },
      '& label + .MuiInput-formControl': {
        marginTop: -4
      }
    },
    breadCrumbs: {
      'a': {
        display: 'inline'
      }
    },
    identification: {
      '& .MuiTypography-subtitle1': {
        display: 'inline-flex',
        minWidth: 200,
        fontWeight: 'bold'
      },
      '& .MuiTypography-body1': {
        display: 'inline-flex'
      }
    },
    subscriptions: {

    },
    block: {
      boxShadow: theme.shadows[6],
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    }
  }
}

const STAT_TYPES = [
  {label: i18n.get('dashboardNavigationStatsJobs', {pretty: false}), value: 'jobs'},
  {label: i18n.get('dashboardNavigationStatsSpeciality', {pretty: false}), value: 'speciality'},
  // {label: i18n.get('dashboardNavigationStatsLocations', {pretty: false}), value: 'locations'},
  {label: i18n.get('dashboardNavigationStatsRegistrations', {pretty: false}), value: 'registrations'},
  {label: i18n.get('dashboardNavigationStatsSubscriptions', {pretty: false}), value: 'subscriptions'},
]
class DashboardAccountViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadCumbKind: null
    }
  }
  componentDidMount() {
    const {kind} = this.props;
    this.setState({breadCumbKind: kind}, () => {
      this.props.onMount({kind: kind});
    })
  }
  componentDidUpdate(oldProps) {
    if (oldProps.kind !== undefined && oldProps.kind !== this.props.kind) {
      // const {kind} = this.props;
      this.setState({breadCumbKind: this.props.kind}, () => {
        this.props.onMount({kind: this.props.kind});
      })
    }
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }
  getStats = () => {
    const {kind, $$stats} = this.props;
    if ($$stats === undefined) return []
    let stats;
    switch(kind) {
      case 'speciality':
      stats = combine(
        [[i18n.get('dashboardStatsSpecialityX', {pretty: false}), i18n.get('dashboardStatsSpecialityY', {pretty: false})]],
        $$stats.map($$s => [$$s.get('speciality'), parseInt($$s.get('cnt'), 10)]).toArray()
        // $$stats.map($$s => [cropStr($$s.get('speciality'), 20, '...'), parseInt($$s.get('cnt'), 10)]).toArray()
      )
      // stats = combine([''], $$stats.map($$s => [$$s.get('speciality'), $$s.get('cnt')]).toArray()]
      break;
      case 'jobs':
      stats = combine(
        [[i18n.get('dashboardStatsJobsX', {pretty: false}), i18n.get('dashboardStatsJobsY', {pretty: false})]],
        $$stats.map($$s => [$$s.get('job'), parseInt($$s.get('cnt'), 10)]).toArray()
      )
      break;
      case 'subscriptions':
      stats = combine(
        [[i18n.get('dashboardStatsSubscriptionsX', {pretty: false}), i18n.get('dashboardStatsSubscriptionsY', {pretty: false})]],
        $$stats.map($$s => [$$s.get('date'), parseInt($$s.get('cnt'), 10)]).toArray()
      )
      break;
      case 'registrations':
      stats = combine(
        [[i18n.get('dashboardStatsRegistrationsX', {pretty: false}), i18n.get('dashboardStatsRegistrationsY', {pretty: false})]],
        $$stats.map($$s => [$$s.get('date'), parseInt($$s.get('cnt'), 10)]).toArray()
      )
      break;
      default:
      break;
    }
    return stats;
  }
  onBreadCrumbChanged = (kind, o) => {
    this.setState({breadCumbKind: o.value}, () => {
      this.props.history.push('/dashboard/stats/'+o.value)
    })
  }
  render() {
    const {$$stats, classes} = this.props;
    const { breadCumbKind } = this.state;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <If condition={$$stats !== undefined}>
            <Grid item xs={12}>
              <Box className={classes.breadCrumbs}>
                <Link to={'/dashboard'}>Dashboard</Link>&nbsp;&gt;&nbsp;{i18n.get('dashboardNavigationStats', {pretty: false})}&nbsp;&gt;&nbsp;
                  <Select id={'breadCrumbSelector'} options={Immutable.fromJS(STAT_TYPES)} value={breadCumbKind}  label={false} fullWidth={false} paperWidth={350} onChange={this.onBreadCrumbChanged}/>
                </Box>
              </Grid>
              <Grid item xs={12} container justifyContent={'flex-start'} className={clsx(classes.block, classes.identification)}>
                <Grid item xs={12}>
                  <Typography variant="h1" color="primary" gutterBottom>
                    <Choose>
                      <When condition={breadCumbKind === 'jobs'}>
                        {i18n.get('dashboardNavigationStatsJobs', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'speciality'}>
                        {i18n.get('dashboardNavigationStatsSpeciality', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'registrations'}>
                        {i18n.get('dashboardNavigationStatsRegistrations', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'locations'}>
                        {i18n.get('dashboardNavigationStatsLocations', {pretty: false})}
                      </When>
                      <When condition={breadCumbKind === 'subscriptions'}>
                        {i18n.get('dashboardNavigationStatsSubscriptions', {pretty: false})}
                      </When>
                      <Otherwise><span></span></Otherwise>
                    </Choose>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Choose>
                    <When condition={breadCumbKind === 'speciality'}>
                      <Chart
                        width={'100%'}
                        height={'800px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={this.getStats()}
                        options={{
                          chartArea: { width: '80%' },
                          isStacked: true,
                          animation: {
                            duration: 1000,
                            easing: 'out',
                            startup: true,
                          },
                          sliceVisibilityThreshold: 1/10000
                        }}
                        rootProps={{ 'data-testid': '3' }}
                        />
                    </When>
                    <When condition={breadCumbKind === 'subscriptions'}>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={this.getStats()}
                        options={{
                          useFirstColumnAsDomain: true,
                          animation: {
                            duration: 1000,
                            easing: 'out',
                            startup: true,
                          },
                          legend: {
                            position: 'none'
                          },
                          hAxis: {
                          },
                          sliceVisibilityThreshold: 1/10000
                        }}
                        rootProps={{ 'data-testid': '2' }}
                        />
                    </When>
                    <When condition={breadCumbKind === 'jobs'}>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={this.getStats()}
                        options={{
                          chartArea: { width: '100%' },
                          useFirstColumnAsDomain: true,
                          animation: {
                            duration: 1000,
                            easing: 'out',
                            startup: true,
                          },
                        }}
                        rootProps={{ 'data-testid': '4' }}
                        />
                    </When>
                    <When condition={breadCumbKind === 'registrations'}>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={this.getStats()}
                        options={{
                          useFirstColumnAsDomain: true,
                          animation: {
                            duration: 1000,
                            easing: 'out',
                            startup: true,
                          },
                          legend: {
                            position: 'none'
                          }
                        }}
                        rootProps={{ 'data-testid': '5' }}
                        />
                    </When>
                    <When condition={breadCumbKind === 'locations'}>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="GeoChart"
                        data={[
                          ['City', 'Popularity'],
                          ['Paris', 200],
                          ['Lyon', 300],
                        ]}
                        mapsApiKey={config.mapsApiKey}
                        rootProps={{ 'data-testid': '1' }}
                        />
                    </When>
                    <Otherwise>
                      Hello world!
                    </Otherwise>
                  </Choose>
                </Grid>
              </Grid>
            </If>
          </Grid>
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    const props = {
      $$stats: getDashboardStats(state),
      $$error: getDashboardError(state),
    };
    return props;
  }
  function mapDispatchToProps(dispatch) {
    return {
      onMount: (props) => {
        dispatch(fetchDashboardStats(props));
      },
      onUnmount: (props) => {
        dispatch(clearDashboardStats());
      },
    };
  }

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardAccountViewer)));
