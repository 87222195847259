import React from "react";
import { Component } from "react";

const getAbsPosition = function(el){
    var el2 = el;
    var curtop = 0;
    var curleft = 0;
    if (document.getElementById || document.all) {
        do  {
            curleft += el.offsetLeft-el.scrollLeft;
            curtop += el.offsetTop-el.scrollTop;
            el = el.offsetParent;
            el2 = el2.parentNode;
            while (el2 !== el) {
                curleft -= el2.scrollLeft;
                curtop -= el2.scrollTop;
                el2 = el2.parentNode;
            }
        } while (el.offsetParent);

    } else if (document.layers) {
        curtop += el.y;
        curleft += el.x;
    }
    return [curtop, curleft];
};
class ScrollToElementOnMount extends Component {
  componentDidMount() {
    require("smoothscroll-polyfill").polyfill();
    // console.log(this.$ref)
    const curPosition = getAbsPosition(this.$ref);
    window.scrollTo(0, curPosition[0]);
  }

  render() {
    return <div style={{visibility: 'hidden', width: 1, height: 1}} ref={r => this.$ref = r}></div>;
  }
}

export default ScrollToElementOnMount;
