import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isLoggedIn } from 'components/UI/Account/ducks/authentication';
import { fetchOfferPending } from 'components/UI/Offers/ducks/offer';

const refreshTimeout = 60; //s

class OfferPending extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
  }

  componentDidMount() {
    this.onUpdate();
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  onUpdate() {
    this.clearTimeout();
    if (this.props.isLoggedIn) this.props.dipatchOfferPending();
    this.timeout = setTimeout(() => {
      this.onUpdate();
    }, refreshTimeout * 1000);
  }

  clearTimeout() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    return '';
  }
}

function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    dipatchOfferPending: () => {
      dispatch(fetchOfferPending());
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OfferPending));
