import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from 'config/api';
import Spinner from 'components/UI/Spinner';
import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import StyledBadge from 'components/UI/StyledBadge/StyledBadge';
// import { getAvailableEvents, getReplayEvents } from '../../ducks/asyncFetcher';
import { getEventsData, getReplaysData } from 'components/UI/Event/ducks/event';
import HomeIntro from 'components/Home/HomeIntro';
import HomeHelp from 'components/Home/HomeHelp';
import News from 'components/UI/News/News';
import Template from 'components/UI/Template/Template';
import EventList from 'components/UI/Event/EventList';
import MediaList from 'components/UI/Media/MediaList';

import HomeDefault from './HomeDefault';

import { fetchData, getDataHome, getLoadingError, getSubscriptions } from '../../ducks/asyncFetcher';
import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
  isStateKnown,
  getUniverse,
  setUniverse,
} from 'components/UI/Account/ducks/authentication';
import i18n from 'helpers/i18n';

const styles = (theme) => {
  return {
    root: {
      minWidth: '100%',
      minHeight: '920px',
    },
    block: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      marginBottom: theme.spacing(4),
      maxWidth: 1280,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#fff',
    },
    itemTitle: {
      color: theme.palette.secondary.dark,
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    itemDate: {
      width: '100%',
      fontSize: '0.95rem',
    },
    badge: {
      transform: 'none',
      top: 'auto',
      right: 'auto',
      position: 'relative',
      backgroundColor: theme.palette.primary.dark,
      alignSelf: 'center',
      marginLeft: 16,
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 20,
        fontSize: '.8rem',
      },
    },
    universeTitle: {
      fontWeight: 'bold',
      color: '#033B4C',
      marginBottom: theme.spacing(4),
    },
    universeSelector: {
      maxWidth: 1024,
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    universeChoice: {
      '& > div': {
        // height: '100%',
        color: '#fff',
        borderRadius: '16px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '5% 50%',
        '& .MuiTypography-h3': {
          fontWeight: 'bold',
          fontSize: '1.8rem',
        },
        '& .MuiButton-outlined': {
          color: '#fff',
          borderColor: '#fff',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#fff',
          },
        },
      },
      '&:nth-of-type(1) > div': {
        backgroundColor: '#3EA5FF',
        backgroundImage: 'url(' + require('assets/images/universe_blue.png') + ')',
        '& .MuiButton-outlined': {
          '&:hover': {
            color: '#3EA5FF',
          },
        },
      },
      '&:nth-of-type(2) > div': {
        backgroundColor: '#F88D5E',
        backgroundImage: 'url(' + require('assets/images/universe_orange.png') + ')',
        '& .MuiButton-outlined': {
          '&:hover': {
            color: '#F88D5E',
          },
        },
      },
    },
    tabs: {
      width: '100%',
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      overflow: 'visible',
      marginBottom: theme.spacing(1),
    },
    tab: {
      '& .MuiTypography-h3': {
        color: theme.palette.primary.dark,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontWeight: 'bold',
        marginBottom: 0,
        fontSize: '1.7rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.4rem',
        },
      },
      textTransform: 'none',
      position: 'relative',
      maxWidth: 'none',
      overflow: 'visible',
      padding: 0,
      [theme.breakpoints.down('md')]: {},
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 22,
        marginRight: theme.spacing(0),
        marginBottom: theme.spacing(2),
      },
    },
    tabPanel: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
    singleTab: {
      borderBottom: 'none',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    newsBgDefault: {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: 'url(' + require('assets/images/header_news.png') + ')',
      backgroundSize: 'cover',
      backgroundPosition: '50%',
    },
    newsBgGastro: {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: 'url(' + require('assets/images/header_news.png') + ')',
      backgroundSize: 'cover',
      backgroundPosition: '50%',
    },
    newsBgGyneco: {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: 'url(' + require('assets/images/header_news.png') + ')',
      backgroundSize: 'cover',
      backgroundPosition: '50%',
    },
    hidden: {
      display: 'none',
    },
  };
};

const AF_KEY = 'home';

const NewsStyled = withStyles((theme) => {
  return {
    root: {
      maxWidth: 1280,
      '& .MuiCardContent-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .SwipeableTextMobileStepper-card': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiMobileStepper-dotActive': {
        backgroundColor: '#fff',
      },
    },
    wrapper: {
      // backgroundColor: theme.palette.secondary.main,
      // backgroundImage: 'url('+require('assets/images/header_news.png')+')',
      // backgroundSize: 'cover',
      // backgroundPosition: '50%',
    },
    buttonNav: {
      backgroundColor: '#fff',
      color: theme.palette.secondary.dark,
    },
    background: {
      backgroundColor: 'transparent',
    },
    newsWrapper: {
      color: '#fff',
      backgroundColor: 'transparent',
      '& .MuiTypography-h2': {
        color: '#333',
      },
    },
    newsWrapperPadded: {
      paddingLeft: theme.spacing(6),
    },
    newsHeader: {
      '& .MuiTypography-caption': {
        color: '#000',
        textTransform: 'none',
        fontWeight: 'bold',
        backgroundColor: '#FFC337',
        borderRadius: 35,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    newsSeparator: {
      border: 'none',
      width: 'auto',
      height: 'auto',
      color: '#D0CAD0',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    mediaWrapper: {
      marginTop: theme.spacing(-2),
    },
    media: {
      clipPath: 'circle(73.6% at 80% 49%)',
    },
    buttonLeft: {
      left: 8,
    },
    buttonRight: {
      right: 8,
    },
  };
})(News);

const homeIntroItemsStyle = [
  {
    borderBottom: '4px #599dd5 solid',
  },
  {
    borderBottom: '4px #F3B7CC solid',
  },
  {
    borderBottom: '4px #f8bc9e solid',
  },
];

const HomeIntroStyled = withStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.secondary.main,
    // backgroundImage: 'url('+require('assets/images/home_intro.png')+')',
    backgroundSize: 'cover',
    width: '100%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  title: {
    color: '#fff',
  },
  subTitle: {
    color: '#fff',
  },
  item: {
    border: '6px transparent solid',
    backgroundColor: '#fff',
    color: '#033B4C',
    padding: '1.3rem',
    borderRadius: '16px',
    aspectRatio: 1,
    width: '16rem',
    textAlign: 'center',
    margin: '0 auto',
    '& img': {
      width: 58,
      marginTop: 0,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: 65,
        marginTop: 0,
        marginBottom: theme.spacing(0.5),
      },
    },
  },
}))(HomeIntro);

const HomeHelpStyled = withStyles((theme) => ({
  root: {
    '& .MuiButton-containedPrimary': {
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: '#fff',
        borderColor: theme.palette.primary.dark,
      },
    },
    '& .MuiButton-containedSecondary': {
      color: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
      '&:hover': {
        color: '#fff',
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}))(HomeHelp);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

const EventListStyled = withStyles((theme) => ({
  root: {
    marginBottom: 0,
  },
}))(EventList);

const MediaListStyled = withStyles((theme) => ({
  root: {
    marginBottom: 0,
  },
}))(MediaList);

class HomeClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePanel: 0,
      counterAvailableEvents: null,
      counterReplays: null,
      counterMedias: null,
    };
  }
  componentDidMount() {
    this.props.onMount();
    if (this.props.section) this.scrollToSection(this.props.section);
    if (this.props.loadUniverse !== undefined) this.setUniverse(this.props.loadUniverse);
  }
  componentDidUpdate(oldProps) {
    if (oldProps.loadUniverse === undefined && this.props.loadUniverse !== undefined) {
      this.setUniverse(this.props.loadUniverse);
      // this.setState({
      //   counterAvailableEvents: null,
      //   counterReplays: null,
      //   counterMedias: null,
      // }, () => {
      //   this.props.onMount();
      // })
    }
    if (oldProps.loadUniverse !== this.props.loadUniverse) {
      this.setState(
        {
          counterAvailableEvents: null,
          counterReplays: null,
          counterMedias: null,
        },
        () => {
          this.props.onMount();
        },
      );
    }
  }
  scrollToSection = (section) => {
    const elem = document.getElementById(section);
    if (!elem) return;
    let rect = elem.getBoundingClientRect();

    window.scroll({
      top: rect.y,
      left: 0,
      behavior: 'smooth',
    });
  };
  onPanelChange = (event, newValue) => {
    this.setState({ activePanel: newValue });
  };
  onCounterChange = (kind, value) => {
    // console.log('counter changed!', kind, value);
    this.setState({ counterAvailableEvents: value });
  };
  onReplaysLoaded = (kind, value) => {
    this.setState({ counterReplays: value });
  };
  onMediasLoaded = (kind, value) => {
    this.setState({ counterMedias: value });
  };
  setUniverse = (universe) => {
    this.props.setUniverse(universe);
  };
  render() {
    const { $$data, classes, $$subscriptions, $$availableEvents, universe } = this.props;
    const { activePanel, counterAvailableEvents, counterMyEvents, counterReplays, counterMedias } =
      this.state;
    if (universe === 'offers') this.setUniverse(undefined);
    // console.log({counterAvailableEvents, counterReplays, counterMedias})
    let newsCover;
    let newsBgClass;
    let homeIntroBg;
    if (universe === undefined) {
      newsBgClass = classes.newsBgDefault;
      newsCover = {
        title: 'Des rendez-vous réguliers\n& interactifs avec les \n[b]experts de votre spécialité[/b]',
        thumb: require('assets/images/header_news_intro.png'),
      };
      homeIntroBg = undefined;
    } else if (universe === 'gastro') {
      newsBgClass = classes.newsBgGastro;
      newsCover = {
        title: 'Des rendez-vous réguliers\n& interactifs en\n[b]gastro-entérologie[/b]',
        thumb: require('assets/images/header_news_intro.png'),
      };
      homeIntroBg = require('assets/images/home_intro_blue.png');
    } else if (universe === 'gyneco') {
      newsBgClass = classes.newsBgGyneco;
      newsCover = {
        title: 'Des rendez-vous réguliers\n& interactifs en\n[b]gynécologie[/b]',
        thumb: require('assets/images/header_news_intro.png'),
      };
      homeIntroBg = require('assets/images/home_intro_rose.png');
    }
    // console.log({counterMedias})
    // console.log({counterAvailableEvents, $$availableEvents, $$subscriptions})
    // console.log({activePanel})
    return (
      <Template current="home" kind={'fullWidth'} maxWidth={'auto'}>
        <Choose>
          <When condition={$$data !== undefined}>
            <div className={classes.root}>
              <ScrollToTopOnMount />

              <If condition={universe === undefined}>
                <HomeDefault />
              </If>
              <If condition={universe !== undefined}>
                <NewsStyled
                  $$data={$$data}
                  newsSeparator={'•'}
                  newsCover={newsCover}
                  universe={universe}
                  classes={{ wrapper: newsBgClass }}
                />
                <Hidden xsDown>
                  <Paper
                    className={clsx(classes.block, {
                      [classes.hidden]:
                        counterAvailableEvents !== null &&
                        counterAvailableEvents < 1 &&
                        ($$subscriptions === undefined || $$subscriptions.size < 1),
                    })}
                    elevation={0}
                  >
                    <If
                      condition={
                        ($$availableEvents !== undefined && $$availableEvents.size > 0) ||
                        ($$subscriptions !== undefined && $$subscriptions.size > 0)
                      }
                    >
                      <Tabs
                        value={activePanel}
                        onChange={this.onPanelChange}
                        aria-label="Tabs"
                        className={clsx(classes.tabs, {
                          [classes.singleTab]: $$subscriptions === undefined || $$subscriptions.size === 0,
                        })}
                      >
                        <If condition={$$availableEvents !== undefined && $$availableEvents.size > 0}>
                          <Tab
                            label={
                              <Typography variant="h3" component="div">
                                <StyledBadge
                                  classes={{ badge: classes.badge }}
                                  badgeContent={counterAvailableEvents}
                                  color="secondary"
                                >
                                  {i18n.get('homeAvailableEvents', { pretty: false })}
                                </StyledBadge>
                              </Typography>
                            }
                            className={classes.tab}
                          />
                        </If>
                        <If
                          condition={
                            $$subscriptions !== undefined &&
                            $$subscriptions.size > 0 &&
                            counterAvailableEvents > 0
                          }
                        >
                          <Tab
                            label={
                              <Typography variant="h3" component="div">
                                <StyledBadge
                                  classes={{ badge: classes.badge }}
                                  badgeContent={$$subscriptions !== undefined ? $$subscriptions.size : 0}
                                  color="secondary"
                                >
                                  {i18n.get('homeYourEvents', { pretty: false })}
                                </StyledBadge>
                              </Typography>
                            }
                            className={classes.tab}
                          />
                        </If>
                      </Tabs>
                    </If>
                    <TabPanel value={activePanel} index={0} className={classes.tabPanel}>
                      <EventListStyled
                        universe={universe}
                        showCount={true}
                        kind="events"
                        eventCardClasses={{ itemTitle: classes.itemTitle, itemDate: classes.itemDate }}
                        onCounterChange={this.onCounterChange}
                      />
                    </TabPanel>
                    <TabPanel value={activePanel} index={1} className={classes.tabPanel}>
                      <EventListStyled
                        universe={universe}
                        showCount={true}
                        kind="events"
                        itemFilter={($$item) => $$subscriptions.contains($$item.get('id'))}
                        eventCardClasses={{ itemTitle: classes.itemTitle, itemDate: classes.itemDate }}
                      />
                    </TabPanel>
                  </Paper>
                </Hidden>
                <Hidden smUp>
                  <If condition={$$availableEvents !== undefined && $$availableEvents.size > 0}>
                    <div
                      className={clsx(classes.tab, {
                        [classes.hidden]: counterAvailableEvents === null && counterAvailableEvents < 1,
                      })}
                    >
                      <Typography variant="h3" component="div">
                        <StyledBadge
                          classes={{ badge: classes.badge }}
                          badgeContent={counterAvailableEvents}
                          color="secondary"
                        >
                          {i18n.get('homeAvailableEvents', { pretty: false })}
                        </StyledBadge>
                      </Typography>
                    </div>
                  </If>

                  <TabPanel
                    value={99}
                    index={99}
                    className={classes.tabPanel}
                    style={{
                      display:
                        $$availableEvents !== undefined && $$availableEvents.size > 0 ? 'inherit' : 'none',
                    }}
                  >
                    <Paper className={classes.block} elevation={0}>
                      <EventListStyled
                        universe={universe}
                        showCount={true}
                        kind="events"
                        eventCardClasses={{ itemTitle: classes.itemTitle, itemDate: classes.itemDate }}
                        onCounterChange={this.onCounterChange}
                      />
                    </Paper>
                  </TabPanel>

                  <If condition={$$subscriptions !== undefined && $$subscriptions.size > 0}>
                    <div className={classes.tab}>
                      <Typography variant="h3" component="div">
                        <StyledBadge
                          classes={{ badge: classes.badge }}
                          badgeContent={counterMyEvents}
                          color="secondary"
                        >
                          {i18n.get('homeYourEvents', { pretty: false })}
                        </StyledBadge>
                      </Typography>
                    </div>

                    <TabPanel value={99} index={99} className={classes.tabPanel}>
                      <Paper className={classes.block} elevation={0}>
                        <EventListStyled
                          universe={universe}
                          showCount={true}
                          kind="events"
                          itemFilter={($$item) => $$subscriptions.contains($$item.get('id'))}
                          eventCardClasses={{ itemTitle: classes.itemTitle, itemDate: classes.itemDate }}
                        />
                      </Paper>
                    </TabPanel>
                  </If>
                </Hidden>

                <Paper
                  className={clsx(classes.block, {
                    [classes.hidden]: counterReplays !== null && counterReplays === 0,
                  })}
                  elevation={0}
                >
                  <Tabs value={activePanel} aria-label="Tabs" className={clsx(classes.tabs)}>
                    <Tab
                      label={
                        <Typography variant="h3" component="div">
                          <StyledBadge
                            classes={{ badge: classes.badge }}
                            badgeContent={counterReplays}
                            color="secondary"
                          >
                            {i18n.get('homeReplays', { pretty: false })}
                          </StyledBadge>
                        </Typography>
                      }
                      className={classes.tab}
                    />
                  </Tabs>
                  <EventListStyled
                    universe={universe}
                    showCount={true}
                    kind="replays"
                    onCounterChange={this.onReplaysLoaded}
                  />
                </Paper>

                <Paper
                  className={clsx(classes.block, {
                    [classes.hidden]: counterMedias !== null && counterMedias === 0,
                  })}
                  elevation={0}
                >
                  <Tabs value={activePanel} aria-label="Tabs" className={clsx(classes.tabs)}>
                    <Tab
                      label={
                        <Typography variant="h3" component="div">
                          <StyledBadge
                            classes={{ badge: classes.badge }}
                            badgeContent={counterMedias}
                            color="secondary"
                          >
                            {i18n.get('homeMedias', { pretty: false })}
                          </StyledBadge>
                        </Typography>
                      }
                      className={classes.tab}
                    />
                  </Tabs>
                  <MediaListStyled
                    universe={universe}
                    showCount={true}
                    kind="medias"
                    onCounterChange={this.onMediasLoaded}
                  />
                </Paper>
                <HomeIntroStyled
                  background={homeIntroBg}
                  $$intro={$$data.get('intro').get('items')}
                  itemsStyle={homeIntroItemsStyle}
                />
              </If>
              <Box mb={4} width={'100%'}>
                <HomeHelpStyled />
              </Box>
            </div>
          </When>
          <Otherwise>
            <Spinner>{i18n.get('loading')}</Spinner>
          </Otherwise>
        </Choose>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    error: getLoadingError(state),
    isLoggedIn: isLoggedIn(state),
    userRole: getCurrentUserRole(state),
    authStateKnown: isStateKnown(state),
    $$user: getCurrentUser(state),
    $$subscriptions: getSubscriptions(state),
    // $$availableEvents: getAvailableEvents(state),
    // $$replayEvents: getReplayEvents(state),
    $$availableEvents: getEventsData(state),
    $$replayEvents: getReplaysData(state),
    universe: getUniverse(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
    setUniverse: (universe) => {
      dispatch(setUniverse(universe));
    },
  };
}

const HomeWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomeClass)));

export default HomeWrapper;
