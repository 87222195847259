import React from 'react';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Draggable from 'react-draggable';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  withStyles
} from "@material-ui/core/styles";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} style={{width: '100%'}}/>
    </Draggable>
  );
}
const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.dialog ? theme.palette.dialog.backgroundColor : undefined,
        color: theme.palette.dialog ? theme.palette.dialog.color : undefined,
      },
      '& .MuiDivider-root': {
        marginTop: 5,
        marginBottom: 10
      },
      '& .MuiBackdrop-root': {
        backgroundColor: 'transparent',
        display: 'none'
      },
      '& .MuiDialogTitle-root': {
        paddingTop: 20,
        paddingBottom: 5,
        '& .MuiTypography-h6': {
          color: theme.palette.primary.main,
          textAlign: 'left',
          paddingLeft: 10
        }
      },
      '& .MuiDialogContent-root': {
        paddingTop: 0,
        paddingBottom: 20
      }
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },

  }
}
class DraggableModal extends React.PureComponent {
  render() {
    const {open, showOnClose, onClose, title, children, classes, style} = this.props;

    // const scrollPaper = {
    //   position: 'absolute',
    //   maxWidth: 600,
    //   left: '50%',
    //   transform: 'transposeX(-50%)'
    // }
    // console.log(style)
    return (
      <div>
      <Dialog
      className={classes.root}
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      scroll={'paper'}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth={'xl'}
      style={style}
      onEntered={e => {
        document.body.style.overflow = 'inherit'
      }}
      >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"  element='div'>
      {title}
      <If condition={showOnClose === true}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </If>

      </DialogTitle>
      <Divider />
      <DialogContent element='div'>
      {children}
      </DialogContent>
      {/*
        <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
        Cancel
        </Button>
        <Button onClick={handleClose} color="primary">
        Subscribe
        </Button>
        </DialogActions>
        */}
        </Dialog>
        </div>
      );
    }
  }
  export default withStyles(styles)(DraggableModal);
