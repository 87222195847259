import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Error from 'components/UI/Error';
import Loading from 'components/UI/Loading';
import apiFetch from 'components/UI/helpers/fetch';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '15px 0',
  },
  ratingBox: {
    display: 'flex',
    gap: '10px',
  },
  message: {
    fontSize: '80%',
    marginTop: '0.75em',
  },
}));

export default function NPS({ module, id, data = { rating: 0, total: null }, align = null }) {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ratingValue, setRatingValue] = useState(data.rating);

  useEffect(() => {
    setLoading(false);
    setResult(false);
  }, [module, id]);

  const send = (value) => {
    sendData({
      module: module,
      id: id,
      rating: value,
    });
  };

  const sendData = (params) => {
    setResult(false);
    setError(null);
    setLoading(true);
    apiFetch('/nps/rating', params)
      .then((data) => {
        console.log('fetchData ok', data);
        setResult(data);
        setLoading(false);
      })
      .catch(({ response, error, msg }) => {
        console.log('fetchData error', error, response);
        setResult(false);
        setError({ error: error, msg: msg });
        setLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    if (newValue === null) {
      newValue = ratingValue;
    }
    setRatingValue(newValue);
    send(newValue);
  };

  const rating_str = result && 'average' in result ? result.average.rating : data.rating;
  const rating = parseFloat(rating_str);
  const total = result && 'average' in result ? result.average.total : data.total;

  return (
    <Box className={classes.root}>
      <Typography variant={'subtitle1'} gutterBottom={true}>
        Je donne mon avis&nbsp;:
      </Typography>
      <Box className={classes.ratingBox} style={align ? { justifyContent: align } : {}}>
        <Rating
          name={'rating'}
          style={{ display: 'inline-flex' }}
          value={rating}
          onChange={handleChange}
        />
        <Typography>
          <b>{!isNaN(rating) ? rating.toFixed(1) : ''}</b>
        </Typography>
        <Typography>({total})</Typography>
        {loading && <Loading size={20} inline={true} text={false} />}
      </Box>
      {result && (
        <Typography className={classes.message}>Votre avis ({result.rating}/5) a bien été pris en compte.</Typography>
      )}
      {error && (
        <Typography className={classes.message}>
          <Error result={error} inline={true} />
        </Typography>
      )}
    </Box>
  );
}
