import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from 'components/Theme';
import Home from 'components/Home/Home';
import EventDetails from 'components/Event/EventDetails';
import LivePlayer from 'components/Lives/LivePlayerOrchestrator';
import BrowserBarrier from 'components/UI/BrowserBarrier/BrowserBarrier';
import Replays from 'components/Replays/Replays';
import ReplayPlayer from 'components/Replays/ReplayPlayer';
import Contact from 'components/Contact/Contact';
import Medias from 'components/Media/Medias';
import MediaPlayer from 'components/Media/MediaPlayer';
import Help from 'components/Help/Help';
import DetectBrowser from 'components/UI/DetectBrowser/DetectBrowser';
import Account from 'components/UI/Account/Account';
import AdminAccount from 'components/UI/Account/AdminAccount';
import AdminEvent from 'components/UI/Event/AdminEvent';
import AdminNews from 'components/UI/News/AdminNews';
import AdminFaq from 'components/UI/Faq/AdminFaq';
import AdminTheme from 'components/UI/Theme/AdminTheme';
import AdminMessage from 'components/UI/Message/AdminMessage';
import AdminOrchestrator from 'components/UI/Orchestrator/AdminOrchestrator';
import AdminOrchestratorIndex from 'components/UI/Orchestrator/AdminOrchestratorIndex';
import AdminMedia from 'components/UI/Media/AdminMedia';

import Feedback from 'components/UI/Feedback/WrapperFeedback';
import AdminFeedback from 'components/UI/Feedback/AdminFeedback';
import Announce from 'components/UI/Announce/WrapperAnnounce';
import AdminAnnounce from 'components/UI/Announce/AdminAnnounce';
import AdminQuiz from 'components/UI/Quiz/AdminQuiz';
import AdminUtm from 'components/UI/Utm/AdminUtm';
import Dashboard from 'components/UI/Dashboard/Dashboard';

import AdminCMS from 'components/UI/Cms/AdminCms';
import CMSPage from 'components/UI/Cms/CMSPage';
import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
import NoMatch from 'components/NoMatch';
import Logout from 'components/Logout';
import Login from 'components/Login/Login';
import EventRegistration from 'components/Event/EventRegistration';
import { ResetPasswordRequest, ResetPasswordChallenge } from 'components/UI/ResetPassword';
import {
  login as initialeLogin,
} from 'components/UI/Account/ducks/authentication';
import queryString from 'query-string';
import Offers from 'components/Offers/Offers';
import OfferDetails from 'components/UI/Offers/OfferDetails';
import OfferForm from './UI/Offers/OfferForm';
import OfferMyList from './UI/Offers/OfferMyList';
import AdminOffer from './UI/Offers/AdminOffer';

import '../App.css';

import Version from 'components/UI/Version';

const styles = (theme) => {
  return {
    root: {
      // backgroundImage: `url(${require('assets/images/' + process.env.REACT_APP_SITE + '/background.jpg')})`,
      // backgroundPosition: '0 0',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        // backgroundPosition: '0 0',
      },
    },
  };
};

class App extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {
    // document.title = 'Canal ' + properCase(process.env.REACT_APP_SITE);
    this.props.onMount();
  }
  render() {
    const {
      // loggedIn,
      // userRole,
      // authStateKnown,
      classes,
      // width,
      // $$data
    } = this.props;
    // console.log({authStateKnown})
    /*
    const rte=[
      <Route
    exact
    path={'/gastro'}
    component={({ match }) => <Home loadUniverse={'gastro'} foo={'bar'} />}
  />,
  <Route exact path={'/gyneco'} component={({ match }) => <Home loadUniverse={'gyneco'} />} />,
    ];
    <Switch children={rte}/>

    */
    return (
      <div className={classes.root}>
        <Version app="Norgine v 230315"/>
        <ThemeProvider>
          <CssBaseline />
          <ScrollToTopOnMount />
          <DetectBrowser />
          <BrowserBarrier />
          {/*<PWAPrompt
            copyTitle={"Ajouter à l'écran d'accueil"}
            copyBody={"Ce site à le fonctionnement d'une WebApp. Ajoutez le à votre écran d'accueil pour y accéder hors connextion !"}
            copyShareButtonLabel={'1) Appuyez sur le bouton "Partager"'}
            copyAddHomeButtonLabel={"2) Appuyez sur ajouter \"Sur l'écran d'accueil\""}
            copyClosePrompt={'Annuler'}
            promptOnVisit={1} timesToShow={3}
            permanentlyHideOnDismiss={false}
            />*/}

          <Router>
            <Switch>
              {/*<Route exact path={'/replays'} component={({ match }) => <Home section={'replays'}/>} />*/}
              <Route
                exact
                path={'/gastro'}
                component={({ match }) => <Home loadUniverse={'gastro'} foo={'bar'} />}
              />
              <Route exact path={'/gyneco'} component={({ match }) => <Home loadUniverse={'gyneco'} />} />
              <Route exact path={'/offers'} component={({ match }) => <Offers />} />
              <Route
                path="/offers/:action/:id"
                component={({ match }) => <OfferForm match={match} id={match.params.id} />}
              />
              <Route exact path="/offers/new" component={({ match }) => <OfferForm match={match} />} />
              <Route exact path="/offers/mylist" component={({ match }) => <OfferMyList match={match} />} />
              <Route exact path={'/offers/:id'} component={({ match }) => <OfferDetails />} />
              <Route exact path={'/'} component={({ match }) => <Home />} />
              <Route
                exact={true}
                path={'/event/:id'}
                component={({ match }) => <EventDetails eventId={match.params.id} />}
              />
              {/*'/:universe/event/:id' plus bas après /staff/ */}
              
              <Route
                exact={true}
                path={'/subscribe/:id'}
                component={({ match }) => <EventRegistration eventMatchId={match.params.id} />}
              />
              <Route exact={true} path={'/subscribe'} component={({ match }) => <EventRegistration />} />
              <Route exact path={'/login'} component={({ match }) => <Login />} />
              {/*<Route exact path={'/registration'} component={({ match }) => <Login showStep={'registration'}/>} />*/}
              <Route exact path={'/_admin'} component={({ match }) => <Login kind="private" />} />
              <Route exact path={'/help'} component={({ match }) => <Help />} />
              <Route exact path={'/contact'} component={({ match }) => <Contact />} />
              {/*<Route path={'/legal'} component={() => <Legal />} />
              <Route path={'/confidentiality'} component={() => <Confidentiality />} />*/}
              <Route path={'/confidentiality'} component={() => <CMSPage page="confidentiality" />} />

              <Route path={'/legal'} component={() => <CMSPage page="legal" />} />

              <Route path={'/cookies'} component={() => <CMSPage page="cookies" />} />
              <Route path={'/effets'} component={() => <CMSPage page="effets" />} />

              <Route
                exact
                path={'/live/:id'}
                component={({ match }) => <LivePlayer match={match} onPanelToggle={this._panelToggle} />}
              />
              <Route
                path={'/:universe/live/:id'}
                component={({ match }) => <LivePlayer match={match} onPanelToggle={this._panelToggle} />}
              />
              <Route exact path={'/logout'} component={() => <Logout />} />
              <Route path="/rpwd" component={({ match }) => <ResetPasswordRequest match={match} />} />
              <Route path="/cpwd" component={() => <ResetPasswordChallenge />} />
              <Route path={'/replays/:id'} component={({ match }) => <ReplayPlayer match={match} />} />
              <Route path={'/replays'} component={({ match }) => <Replays />} />
              <Route
                path={'/:universe/replays/:id'}
                component={({ match }) => <ReplayPlayer match={match} />}
              />
              <Route path={'/:universe/replays'} component={({ match }) => <Replays match={match} />} />
              <Route
                path="/staff/account/:action/:id"
                component={({ match }) => <AdminAccount match={match} />}
              />
              <Route
                path="/staff/account/:action"
                component={({ match }) => <AdminAccount match={match} />}
              />
              <Route path="/staff/account" component={() => <AdminAccount />} />
              <Route
                path="/staff/event/:action/:id"
                component={({ match }) => <AdminEvent match={match} />}
              />
              <Route path="/staff/event/:action" component={({ match }) => <AdminEvent match={match} />} />
              <Route path="/staff/event" component={() => <AdminEvent />} />

              <Route
                path={'/:universe/event/:id'}
                component={({ match }) => <EventDetails eventId={match.params.id} match={match} />}
              />

              <Route path="/staff/news/:action/:id" component={({ match }) => <AdminNews match={match} />} />
              <Route path="/staff/news/:action" component={({ match }) => <AdminNews match={match} />} />
              <Route path="/staff/faq/:action/:id" component={({ match }) => <AdminFaq match={match} />} />
              <Route path="/staff/faq/:action" component={({ match }) => <AdminFaq match={match} />} />
              <Route
                path="/staff/feedback/:eventId/:action/:id"
                component={({ match }) => <AdminFeedback match={match} />}
              />
              <Route
                path="/staff/feedback/:eventId/:action"
                component={({ match }) => <AdminFeedback match={match} />}
              />
              <Route path="/feedback/:eventId" component={({ match }) => <Feedback match={match} />} />
              <Route
                path="/staff/announce/:eventId/:action/:id"
                component={({ match }) => <AdminAnnounce match={match} />}
              />
              <Route
                path="/staff/announce/:eventId/:action"
                component={({ match }) => <AdminAnnounce match={match} />}
              />
              <Route path="/announce/:eventId" component={({ match }) => <Announce match={match} />} />
              <Route
                exact
                path={'/staff/quiz/:eventId/:action/:id'}
                component={({ match }) => <AdminQuiz match={match} />}
              />
              <Route
                exact
                path={'/staff/quiz/:eventId/:action'}
                component={({ match }) => <AdminQuiz match={match} />}
              />
              <Route
                exact
                path={'/staff/orchestrator/:eventId/:action'}
                component={({ match }) => <AdminOrchestrator match={match} />}
              />
              <Route
                exact
                path={'/staff/orchestrator/list'}
                component={({ match }) => <AdminOrchestratorIndex match={match} />}
              />
              <Route
                path="/dashboard/:module/:action/:id"
                component={({ match }) => <Dashboard match={match} />}
              />
              <Route
                path="/dashboard/:module/:action"
                component={({ match }) => <Dashboard match={match} />}
              />
              <Route path="/dashboard/:module" component={({ match }) => <Dashboard match={match} />} />
              <Route path="/dashboard" component={({ match }) => <Dashboard match={match} />} />
              <Route
                path="/staff/theme/:action/:id"
                component={({ match }) => <AdminTheme match={match} />}
              />
              <Route path="/staff/theme/:action" component={({ match }) => <AdminTheme match={match} />} />
              <Route
                path="/staff/media/:action/:id"
                component={({ match }) => <AdminMedia match={match} />}
              />
              <Route path="/staff/media/:action" component={({ match }) => <AdminMedia match={match} />} />
              <Route path={'/media/:id'} component={({ match }) => <MediaPlayer match={match} />} />
              <Route exact path={'/medias'} component={({ match }) => <Medias />} />
              <Route path={'/:universe/media/:id'} component={({ match }) => <MediaPlayer match={match} />} />
              <Route exact path={'/:universe/medias'} component={({ match }) => <Medias match={match} />} />
              <Route
                path="/staff/utm/:action/:id"
                component={({ match }) => <AdminUtm allowedRoles={['admin', 'staff']} match={match} />}
              />
              <Route
                path="/staff/utm/:action"
                component={({ match }) => <AdminUtm allowedRoles={['admin', 'staff']} match={match} />}
              />
              <Route path="/staff/utm" component={() => <AdminUtm allowedRoles={['admin', 'staff']} />} />
              <Route path="/staff/cms/:action/:id" component={({ match }) => <AdminCMS match={match} />} />
              <Route path="/staff/cms/:action" component={({ match }) => <AdminCMS match={match} />} />
              <Route
                path="/staff/message/:action/:id"
                component={({ match }) => <AdminMessage match={match} />}
              />
              <Route
                path="/staff/message/:action"
                component={({ match }) => <AdminMessage match={match} />}
              />
              <Route
                exact
                path={'/staff/offers/:action/:id'}
                component={({ match }) => <AdminOffer match={match} />}
              />
              <Route
                exact
                path={'/staff/offers/:action'}
                component={({ match }) => <AdminOffer match={match} />}
              />

                <Route path="/account/changepassword" component={({ match }) => <Account match={match} showPasswordForm={true}/>} />
                <Route path="/account/changeoptin" component={({ match }) => <Account match={match} showOptinForm={true}/>} />
                <Route path="/account" component={({ match }) => <Account match={match} showOptins={false} showDelete={true}/>} />
              <Route component={() => <NoMatch />} />
            </Switch>
          </Router>
        </ThemeProvider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    // authStateKnown: authStateKnown(state),
    // loggedIn: isLoggedIn(state),
    // userRole: getCurrentUserRole(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      const uri = queryString.parse(window.location.search);

      dispatch(initialeLogin({ referer: uri.r }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
