import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import withWidth from '@material-ui/core/withWidth';
// import { withStyles } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
// import Immutable from 'immutable';

import '../App.css';

// import Header from 'components/Header/Header';
import Header from 'components/Header/Header';
// import HeaderMenu from 'components/Header/HeaderMenu';
// import ThemeProvider from 'components/Theme';
// import Footer from 'components/Footer';
// import Home from 'components/Home/Home';
// import Contact from 'components/Contact/Contact';
// import Help from 'components/Help/Help';
// import Agenda from 'components/Agenda/Agenda';
// import Admin from 'components/Admin/Admin';
// import Account from 'components/Account/Account';
// import AdminAccount from 'components/Account/AdminAccount';
// import AdminEvent from 'components/Event/AdminEvent';
import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
// // import Login from 'components/Login';
// import Logout from 'components/Logout';
// import Legal from 'components/Legal/Legal';
// import Confidentiality from 'components/Confidentiality/Confidentiality';
// import { ResetPasswordRequest, ResetPasswordChallenge } from 'components/ResetPassword';
// import {
//   login as initialeLogin,
//   isStateKnown as authStateKnown,
//   getCurrentUserRole,
//   isLoggedIn,
// } from 'components/UI/Account/ducks/authentication';
//

class NoMatch extends Component {
  render() {
    const { embed } = this.props;
    if (embed) {
      return (
        <div className="HomeBlock">
          <Box align="middle" className="content">
            <h1>Erreur 404</h1>
            <p>Page introuvable... :(</p>
          </Box>
        </div>
      );
    } else {
      return (
        <div className="AppContent">
          <ScrollToTopOnMount />
          <Header userRole={undefined} current="lives" headerImage="background" />
          <div className="HomeBlock" id="Error404">
            <div className="wrapper">
              <Box align="middle" className="content">
                <h1>Erreur 404</h1>
                <p>Page introuvable... :(</p>
              </Box>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default NoMatch;
