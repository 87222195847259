import React, { useEffect, useReducer } from 'react';
import config from 'config/api';
import Player from '@vimeo/player';
import queryString from 'query-string';


export default function VideoTracker({eventId}) {

  if(config.enableVideoTracker===undefined || !config.enableVideoTracker)
    return null;
  const verbose=false;
  const superVerbose=false;

  const sendTick=10000;// (ms)
  const max_timeupdate_diff=1.0;// (s), should be around 0.25s (~0.285 in practice), sometimes fire before seeking
  const apiUrl=config.baseUrl+'/api/videoTracker';

  const [player, setPlayer] = React.useState(null);
  useEffect(()=>{
    console.log("VideoTracker init", player);
    loadPlayer();
  }, []);

  const [state, setState] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {viewStart: null, viewLast: null, forceSend: false, seeking:false}
  )
  const {viewStart, viewLast, forceSend, seeking}=state;

  const [lastSend, setlastSend] = React.useState(Date.now());
  useEffect(()=>{
    let diff=Date.now()-lastSend;
    if(superVerbose)
      console.log("VideoTracker useEffect", state);
    if(viewStart===null || (!forceSend && diff<sendTick))
      return;
    let data={
        'viewStart':viewStart,
        'viewLast':viewLast,
    };
    setlastSend(Date.now());
    if(forceSend)
      setState({forceSend:false});
    track(data);
  }, [state]);


  const [playerState, setPlayerState] = useReducer(
    (playerState, newPlayerState) => ({...playerState, ...newPlayerState}),
    {playerEvent: null, playerSeconds: null}
  )
  const {playerEvent, playerSeconds}=playerState;

  useEffect(()=>{
    if(superVerbose)
      console.log("VideoTracker useEffect", playerState);
    if(playerEvent=="playing")
      if(viewLast===null || playerSeconds!==viewLast)//pour éviter de reset viewStart après pause/reprise
        setState({viewStart:playerSeconds, viewLast:playerSeconds, forceSend:true});
    if(playerEvent=="pause")
      setState({viewLast:playerSeconds, forceSend:true});
    if(playerEvent=="seeking")
      setState({forceSend:true, seeking:true});
    if(playerEvent=="seeked")
      setState({viewStart:playerSeconds, viewLast:playerSeconds, forceSend:true, seeking:false});
    if(playerEvent=="ended")
      if(playerSeconds>0 && playerSeconds>viewStart)
        setState({viewLast:playerSeconds, forceSend:true});
    if(playerEvent=="timeupdate"){
      let diff=playerSeconds-viewLast;
      if(!seeking && playerSeconds>viewStart && playerSeconds>viewLast && diff<max_timeupdate_diff)
        setState({viewLast:playerSeconds});
    }
  }, [playerState]);


  const loadPlayer=()=>{
    if(verbose)
      console.log("VideoTracker load player");
    const iframe = document.querySelector('#player');
    if(!iframe){
      console.log("VideoTracker error, #player not found");
      return false;
    }
    let player = new Player(iframe);
    //https://www.npmjs.com/package/@vimeo/player
    player.on('loaded', onLoaded);
    player.on('play', onPlay);
    player.on('playing', onPlaying);
    player.on('ended', onEnded);
    player.on('timeupdate', onTimeUpdate);
    player.on('chapterchange', onChapterChange);
    player.on('pause', onPause);
    player.on('seeked', onSeeked);
    player.on('seeking', onSeeking);
    console.log("VideoTracker player loaded", player);
    setPlayer(player);
    return true;
  }
  const onLoaded=(data)=>{
    if(verbose)
      console.log("VideoTracker onLoaded", data);
  }
  const onPlay=(data)=>{
    if(verbose)
      console.log("VideoTracker onPlay", data);
  }
  const onPlaying=(data)=>{
    if(verbose)
      console.log("VideoTracker onPlaying", data);
    setPlayerState({playerEvent: "playing", playerSeconds:data.seconds});
  }
  const onEnded=(data)=>{
    if(verbose)
      console.log("VideoTracker onEnded", data);
    setPlayerState({playerEvent: "ended", playerSeconds:data.seconds});
  }
  const onTimeUpdate=(data)=>{
    if(superVerbose)
      console.log("VideoTracker onTimeUpdate", data);
    setPlayerState({playerEvent: "timeupdate", playerSeconds:data.seconds});
  }
  const onChapterChange=(data)=>{
    if(verbose)
      console.log("VideoTracker onChapterChange", data);
  }
  const onPause=(data)=>{
    if(verbose)
      console.log("VideoTracker onPause", data);
    setPlayerState({playerEvent: "pause", playerSeconds:data.seconds});
  }
  const onSeeking=(data)=>{
    if(verbose)
      console.log("VideoTracker onSeeking", data);
    setPlayerState({playerEvent: "seeking", playerSeconds:data.seconds});
  }
  const onSeeked=(data)=>{
    if(verbose)
      console.log("VideoTracker onSeeked", data);
      setPlayerState({playerEvent: "seeked", playerSeconds:data.seconds});
    }

  const track=(data)=>{
    data['eventId']=eventId;
    if(verbose)
      console.log('VideoTracker fetch', apiUrl, data);
    fetch(apiUrl, {
      credentials: 'include',
      method: 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if(verbose)
          console.log('VideoTracker response', response);
      })
      .catch((error) =>{
        console.log('VideoTracker ERROR', error);
      });
  };

  return (
    <React.Fragment/>
  );
}
