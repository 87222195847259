import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Immutable from 'immutable';
import MenuItem from '@material-ui/core/MenuItem';
import getCardGuests from 'components/UI/helpers/getCardGuests';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import { connect } from 'react-redux';
import queryString from 'query-string';
import config from 'config/api';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import BlockRatio from 'components/BlockRatio';
import Spinner from 'components/UI/Spinner';
import Template from 'components/UI/Template/Template';
import { withRouter } from 'react-router-dom';
import { toHHMM } from 'components/UI/helpers/time';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Prettyfier from 'components/UI/Prettyfier';
import { fetchReplay, getReplayData } from './ducks/replay';
import { fetchData, getDataHome, getLoadingError, getReplayEvents } from 'ducks/asyncFetcher';
import { isLoggedIn, getCurrentUserRole, getCurrentUser, getUniverse, setUniverse } from 'components/UI/Account/ducks/authentication';
import i18n from 'helpers/i18n';
import { Link } from 'react-router-dom';
import rtrim from 'mout/string/rtrim'
import Player from '@vimeo/player';
import VideoTracker from './VideoTracker';
import Share from 'components/UI/Share';
import NPS from 'components/UI/NPS';

const AF_KEY = 'home';

const styles = (theme) => {

  return {
    root: {
      '& .MuiTypography-subtitle1': {
        textAlign: 'left',
      },
      '& .MuiSvgIcon-root': {
        verticalAlign: 'middle',
        marginTop: -2
      }

    },
    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.replays && theme.palette.replays.backgroundColor ? theme.palette.replays.backgroundColor : theme.palette.primary.light,
        zIndex: -1
      }
    },
    paper: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& a': {
        color: 'inherit'
      }
    },
    inline: {
      display: 'inline-block',
    },

    breadCrumbs: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(8),
      fontSize: '0.8125rem',
      color: theme.palette.replays && theme.palette.replays.breadCrumbColor ? theme.palette.replays.breadCrumbColor : theme.palette.primary.main,
      '& a': {
        color: theme.palette.replays && theme.palette.replays.breadCrumbColor ? theme.palette.replays.breadCrumbColor : theme.palette.primary.main,
        textDecoration:'underline',
        display: 'inline-block',
        verticalAlign: 'middle',
      },
      '& .MuiTypography-subtitle1' :{
        color: theme.palette.replays && theme.palette.replays.breadCrumbColor ? theme.palette.replays.breadCrumbColor : theme.palette.primary.main,
      }
    },

    itemVideo: {
      position: 'relative',
      marginBottom: theme.spacing(2)
    },
    itemDescription: {
      marginBottom: theme.spacing(2)
    },
    itemDuration: {
      marginBottom: theme.spacing(2)
    },
    itemCopyright: {
      color: theme.palette.primary.main
    },
    itemCode: {
      // color: theme.palette.primary.main
      textAlign: 'right',
      fontSize: '.6rem'
    },
    itemGuests: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    navWrapper: {
      position: 'relative',
      height: 60
    },
    navButton: {
      position: 'absolute',
      bottom: 0,
    },
    navPrevious: {
      left: 0
    },
    navNext: {
      right: 0
    },
    itemTitle: undefined,
    itemTheme: undefined,
    itemTimecodesToggler: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '10px 10px 0 0',
      color: '#fff',
    },
    itemTimecodesWrapper: {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      position: 'absolute',
      top: 0,
      left: '-100%',
      height: '100%',
      width: '100%',
      maxWidth: 300,
      transition: [
        theme.transitions.create("left", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      ],
      '&.show': {
        left: 0
      },
      '& .MuiTypography-h3': {
        color: 'inherit',
        marginBottom: theme.spacing(1)
      }
    },
    itemTimecodes: {
      padding: theme.spacing(1)+'px '+theme.spacing(2)+'px'
    }
  };
};
class ReplayPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logMessage: false,
      timeCodes: false
    };
  }
  componentDidMount() {
    const replayId = this.props.match.params.id;
    const uri = queryString.parse(window.location.search);
    this.props.onMount({ replayId, params: uri });
    if (this.props.match && this.props.match.params.universe) this.props.setUniverse(this.props.match.params.universe);

    // this._startTimer();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.match.params.id !== this.props.match.params.id) {
      const replayId = this.props.match.params.id;
      const uri = queryString.parse(window.location.search);
      this.props.onMount({ replayId, params: uri });
    }

    // if (oldProps.$$card !== undefined && this.props.$$card !== undefined || oldProps.$$card !== this.props.$$card) {
    //   const {$$card} = this.props;
    //   if ($$card.get('eventTimecodeReplay') !== '' && $$card.get('eventTimecodeReplay') !== null && $$card.get('eventTimecodeReplay') !== undefined) {
    //     console.log('found timecode!')
    //     let timeCodes = $$card.get('eventTimecodeReplay').split("\n");
    //     console.log(timeCodes)
    //     // this.setState({timeCodes: 'test'})
    //     // this.setState({timeCodes: timeCodes})
    //   } else {
    //     // this.setState({timeCodes: false})
    //   }
    // }
    // if (oldProps.$$replayData !== this.props.$$replayData) {
    //   this._startTimer();
    // }
  }

  gotoReplay = (direction) => {
    const {$$card, $$replays, universe} = this.props;
    const index = $$replays.findIndex($$r => $$r.get('id') === $$card.get('id'))
    let newReplay = false;
    const debug = false;
    if (direction === 'next' && index < ($$replays.size-1)) {
      if (debug) console.log('next 1');
      newReplay = $$replays.get(index+1)
    } else if (direction === 'next' && index >= ($$replays.size -1)) {
      if (debug) console.log('next 2');
      newReplay = $$replays.get(0);
    } else if (direction === 'previous' && index > 0) {
      if (debug) console.log('previous 1');
      newReplay = $$replays.get(index-1)
    } else if (direction === 'previous' && index === 0) {
      if (debug) console.log('previous 2');
      newReplay = $$replays.get($$replays.size -1)
    }

    // if (!newReplay) return;
    // console.log(newReplay.toJS())
    // console.log('univse', universe)
    this.props.history.push((universe !== undefined ? '/'+universe : '')+'/replays/'+newReplay.get('id'));
  }
  _redirectToLogin = (action) => {
    let pn = window.location.pathname;
    // console.log(pn[pn.length -1])
    if (pn[pn.length -1] === '/') {
      pn = pn.substring(0, pn.length -1);
      // console.log('mathced!')
    }

    let returnUrl = pn+window.location.search;
    // console.log(returnUrl)
    returnUrl = encodeURIComponent(returnUrl);
    // console.log(returnUrl)
    this.props.history.push(
      "/login?action="+action+"&_pn="+
      returnUrl
    )
  }

  _toggleTimecode = () => {
    const { showTimecode } = this.state;
    this.setState({showTimecode: !showTimecode})
  }

  _itemTimeCodesGoto = (idx) => {
    const {$$card} = this.props;
    if ($$card !== undefined && $$card.get('eventTimecodeReplay') !== '' && $$card.get('eventTimecodeReplay') !== null && $$card.get('eventTimecodeReplay') !== undefined) {
      const tcLines = $$card.get('eventTimecodeReplay').split("\n");
      const tcString = tcLines[idx];
      const tcRegExp = /^[0-9]{2}:[0-9]{2}:[0-9]{2} /;
      const tcTime = rtrim(tcString.match(tcRegExp)[0])
      // const tcTime = '00:03:00'
      const tcTimeArr = tcTime.split(':');
      const tcTimeSeconds = Math.round(parseInt(tcTimeArr[0], 10)*60*60 + parseInt(tcTimeArr[1], 10)*60 + parseInt(tcTimeArr[2], 10))
      // console.log(tcTimeSeconds)
      const iframe = document.querySelector('#player');
      // console.log(iframe)
      if (!this.player)
      this.player = new Player(iframe);
      // console.log('will play at ', tcTimeSeconds)
      this.player.setCurrentTime(tcTimeSeconds).then(() => {
        this.player.play();
        this.setState({showTimecode: false})
      })


      // if (this.$player)
      //   this.$player.play(tcTime)
    }
  }

  render() {
    const { $$data, userRole, classes, $$card, $$replays, templateProps, kind } = this.props;
    const $$guests = getCardGuests !== undefined ? getCardGuests($$card) : undefined;
    const { showTimecode } = this.state;
    // const replayId = this.props.match.params.id;

    //console.log($$card ? $$card.toJS() : $$card);

    let timeCodes = false;
    if ($$card !== undefined && $$card.get('eventTimecodeReplay') !== '' && $$card.get('eventTimecodeReplay') !== null && $$card.get('eventTimecodeReplay') !== undefined) {
      timeCodes = [];
      const tcLinex = $$card.get('eventTimecodeReplay').split("\n");
      tcLinex.forEach((line) => {
        // const lineTimeRegExp = /^[0-9]{2}:[0-9]{2}:[0-9]{2} /;
        // const lineTime = rtrim(line.match(lineTimeRegExp)[0])
        const tcDataRegExp = /(?:[0-9]{2}:[0-9]{2}:[0-9]{2} )(.*)/;
        const tcData = line.match(tcDataRegExp)[1]
        timeCodes.push(tcData);

        // timeCodes.push({time: lineTime})
        // console.log(lineData)
      })
    }
    const $$timeCodes = Immutable.fromJS(timeCodes);

    return (
      <Template current="replays" resize={false} {...templateProps}>
        <Box className={classes.paperWrapper} elevation={3}>
          <Paper square elevation={0} className={clsx(classes.root, classes.paper)}>

            <Choose>
              <When condition={$$data !== undefined && userRole === undefined}>
                <Box my={6} align="center">
                  <Typography
                    variant="h5"
                    gutterBottom={true}
                    className={clsx(classes.legend, classes.h6extends)}
                    >
                    Merci de vous connecter pour accéder à ce contenu
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '0 5px'}}
                    onClick={e => this._redirectToLogin('login')}>
                    {" "}
                    {i18n.get('loginAction', {pretty: false})}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '0 5px'}}
                    onClick={e => this._redirectToLogin('register')}
                    >
                    {' '}
                    {i18n.get('subscribeAction', {pretty: false})}
                  </Button>
                </Box>
              </When>
              <When
                condition={
                  $$data !== undefined &&
                  (userRole === 'member' || userRole === 'admin' || userRole === 'staff')
                }
                >
                <If condition={$$card !== undefined}>
                  <Grid container alignContent="center">
                    <Grid item xs={12} className={classes.breadCrumbs}>
                      <Typography variant="subtitle1"><Link to="/">{i18n.get('home', {pretty: false})}</Link>&nbsp;&nbsp;&gt;&nbsp;&nbsp;
                      <Choose>
                        {/*format spécifique à Biocodex avec autres vidéos */}
                        <When condition={kind=="replay"}>
                          <Link to="/videos#replays">{i18n.get('Replays', {pretty: false})}</Link>
                        </When>
                        <When condition={kind=="other"}>
                          <Link to="/videos#other">{i18n.get('othervideos', {pretty: false})}</Link>
                        </When>
                        <Otherwise>
                          {/*format par défaut utilisé ailleurs*/}
                          <Link to="/replays">{i18n.get('Replays', {pretty: false})}</Link>
                        </Otherwise>
                      </Choose>
                      &nbsp;&nbsp;&gt;&nbsp;&nbsp;<Prettyfier text={$$card.get('eventTitle')}/></Typography>
                    </Grid>


                    <If condition={$$card !== undefined && $$card.get('event_wrong_user_job')}>

                <Grid item xs={12}>
                  <Box my={10} align="center">
                      <Typography
                        variant="h5"
                        gutterBottom={true}
                        className={clsx(classes.legend, classes.h6extends)}
                        >
                        <b>Ce replay est réservé</b><br/>Nous vous invitons à choisir un autre replay en cliquant sur un bouton ci-dessous.
                        </Typography>
                        <Box mt={3}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              window.location.replace('/replays');
                            }}
                            >
                            Retour aux replays
                          </Button>
                        </Box>
                      </Box>
                </Grid>


                <Else/>

                    <If condition={$$card.get('eventLinkReplay') !== undefined && $$card.get('eventLinkReplay') !== '' && $$card.get('eventLinkReplay') !== null}>
                      <If condition={$$card.get('eventTimecodeReplay') !== undefined && $$card.get('eventTimecodeReplay') !== null && $$card.get('eventTimecodeReplay') !== ''}>
                        <Grid item xs={12}>
                          <Button variant="contained"  className={classes.itemTimecodesToggler} onClick={e => this._toggleTimecode()}>{i18n.get('replayTimecodesToggler',{pretty: false})}</Button>
                        </Grid>
                      </If>
                      <Grid item xs={12}>
                        <BlockRatio ratio={'16_9'} className={classes.itemVideo}>
                          <If condition={showTimecode === true}>
                            <div className={clsx(classes.itemTimecodesWrapper, {['show']: showTimecode === true})}>
                              <div className={classes.itemTimecodes}>
                                <Typography variant="h3">{i18n.get('replayTimecodeTitle', {pretty: false})}</Typography>
                                {$$timeCodes.map(($$timecodeData, tIdx) => {
                                  return (<MenuItem key={'tc_'+tIdx} onClick={e => this._itemTimeCodesGoto(tIdx)}>{$$timecodeData}</MenuItem>)
                                }).toArray()}
                              </div>
                            </div>
                          </If>
                          <iframe
                            id="player"
                            title="replay"
                            src={$$card.get('eventLinkReplay')}
                            className={'embed'}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            />
                            <VideoTracker eventId={$$card.get("id")}/>
                        </BlockRatio>
                      </Grid>
                    </If>
                    <If condition={$$card.get('eventLinkReplay') === undefined || $$card.get('eventLinkReplay') === '' || $$card.get('eventLinkReplay') === null}>
                      <Grid item xs={12} >
                        <Box mt={8}>
                          <Paper>
                            <Typography variant="h3" align="center" style={{padding: '32px 16px'}}>
                              {i18n.get('newsSoonAvailable',{pretty: false})}
                            </Typography>
                          </Paper>
                        </Box>
                      </Grid>
                    </If>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" component="div" gutterBottom className={classes.itemTheme}>
                        <Paragrapher
                          text={$$card.get('eventTheme')}
                          elementClassName={classes.content}
                          prettyfy={true}
                          element={'div'}
                          />
                      </Typography>
                      <Typography variant="h5" component="div" gutterBottom className={classes.itemTitle}>
                        <Paragrapher
                          text={$$card.get('eventTitle')}
                          elementClassName={classes.content}
                          prettyfy={true}
                          element={'div'}
                          />
                      </Typography>
                      <If condition={$$guests !== false && $$guests.size > 0}>
                        <Box className={classes.itemGuests}>
                          <Typography variant={'caption'} gutterBottom style={{marginBottom: 8, display: 'block'}}>{i18n.get('eventAnimatedBy', {pretty: false})}</Typography>
                          {$$guests.map(($$g, aIdx) => {
                            return (
                              <Box w={'100%'} key={'av_'+aIdx} style={{clear: 'both'}}>
                                <Avatar src={$$g.get('dest') !== false ? $$g.get('dest') : undefined} alt={$$g.getIn(['attributes','name'])} style={{float: 'left', marginRight: 8, marginBottom: 8, backgroundColor: '#fff'}}/>
                                <Typography variant={'body1'} >{$$g.getIn(['attributes','name'])}</Typography>
                                <Typography variant={'caption'} gutterBottom>{$$g.getIn(['attributes','description'])}</Typography>
                              </Box>)
                            }).toArray()}
                          </Box>
                        </If>
                        <Box style={{textAlign:"center"}}>
                {config.NPS_replay && 
                  <Grid item xs={12} className={classes.shareWrapper}>
                    <NPS module="event" id={$$card.get('id')} data={$$card.get('nps')!==undefined ? $$card.get('nps').toJS() : {}} align="center"/>
                  </Grid>
                }
                {config.Share_replay && 
                  <Grid item xs={12} className={classes.shareWrapper}>
                    <Share module="event" id={$$card.get('id')} title={$$card.get('eventTitle')} />
                  </Grid>
                }
                </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box mt={0} mb={1} px={4}>
                          <If condition={$$card.get('eventDescription')}>
                            <Typography variant="body1" component="div" gutterBottom className={classes.itemDescription}>
                              <Paragrapher
                                text={$$card.get('eventDescription')}
                                elementClassName={classes.content}
                                prettyfy={true}
                                element={'div'}
                                />
                            </Typography>
                          </If>
                          <Typography variant="body1" component="div" gutterBottom className={classes.itemDuration}>
                            <If condition={$$card.get('eventDuration') && $$card.get('eventDuration') !== false && $$card.get('eventDuration') !== false && $$card.get('eventDuration') !== '' && $$card.get('eventDuration') > 0}>
                              <Typography variant="body1" component="div" gutterBottom>
                                <WatchLaterIcon/> {toHHMM($$card.get('eventDuration')*60)}
                                </Typography>
                              </If>
                            </Typography>

                            <If condition={i18n.has('replayCopyrightWarning') && ($$card.get('eventLegalReplay') === undefined || $$card.get('eventLegalReplay') === '' || $$card.get('eventLegalReplay') === null)}>
                              <Typography variant="caption" className={classes.itemCopyright} gutterBottom>
                                <span className={classes.inline}>{i18n.get('replayCopyrightWarning')}</span>
                              </Typography>
                            </If>
                            <If condition={$$card.get('eventLegalReplay') !== undefined && $$card.get('eventLegalReplay') !== '' && $$card.get('eventLegalReplay') !== null}>
                              <Typography variant="caption" className={classes.itemCopyright} gutterBottom>
                                <span className={classes.inline}>$$card.get('eventLegalReplay')</span>
                              </Typography>
                            </If>
                            <If condition={$$card.get('eventCodeReplay') !== undefined && $$card.get('eventCodeReplay') !== '' && $$card.get('eventCodeReplay') !== null}>
                              <Typography variant="body1" className={classes.itemCode} gutterBottom>
                                <span className={classes.inline}>{$$card.get('eventCodeReplay')}</span>
                              </Typography>
                            </If>
                          </Box>
                        </Grid>
                        </If>
                        <If condition={$$replays !== undefined && $$replays.size > 1 && $$card.get('eventState')!='other'}>
                          <Grid item xs={12} className={classes.navWrapper}>
                            <Button variant="outlined" color="secondary" className={clsx(classes.navButton, classes.navPrevious)} onClick={e => this.gotoReplay('previous')}>{i18n.get('replayPrevious',{pretty: false})}</Button>
                            <Button variant="contained" color="primary" className={clsx(classes.navButton, classes.navNext)} onClick={e => this.gotoReplay('next')}>{i18n.get('replayNext',{pretty: false})}</Button>
                          </Grid>
                        </If>
                      </Grid>
                    </If>
                    
                    <If condition={$$card === undefined}>
                      <Box my={8} align="center">
                        <Typography
                          variant="h5"
                          gutterBottom={true}
                          className={clsx(classes.legend, classes.h6extends)}
                          >
                          Il n'y a pas de Replay pour cette webconférence :(
                          </Typography>
                          <Box mt={3}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                window.location.replace('/');
                              }}
                              >
                              Cliquez pour retourner à l'accueil
                            </Button>
                          </Box>
                        </Box>
                      </If>
                    </When>
                    <When condition={$$data !== undefined && userRole === 'speaker'}>
                      <p>No place for speaker</p>
                    </When>
                    <Otherwise>
                      <div className={'Loading'}>
                        <Spinner style={{ marginTop: 200 }}>Chargement...</Spinner>
                      </div>
                    </Otherwise>
                  </Choose>
                </Paper>
              </Box>
            </Template>
          );
        }
      }

      function mapStateToProps(state) {
        const props = {
          $$data: getDataHome(state),
          $$replays: getReplayEvents(state),
          error: getLoadingError(state),
          isLoggedIn: isLoggedIn(state),
          userRole: getCurrentUserRole(state),
          $$user: getCurrentUser(state),
          $$card: getReplayData(state),
          universe: getUniverse(state)
        };
        return props;
      }
      function mapDispatchToProps(dispatch) {
        return {
          onMount: (props) => {
            dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
            dispatch(fetchReplay(props));
          },
          setUniverse: (universe) => {
            dispatch(setUniverse(universe));
          }
        };
      }

      // export default ;
      export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReplayPlayer)));
