import React from "react";
// import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
// import ModuleConfigProvider from "components/UI/ModuleConfigProvider";
// import BlockButton from 'components/UI/BlockButton';
import Grid from "@material-ui/core/Grid";
import FaqItemList from "./FaqItemList";
import FaqItemBox from "./FaqItemBox";
// import DialogPopinButton from 'components/UI/Dialog/DialogPopinButton';
// import FaqForm from 'components/Forms/FaqForm';
// import Dialog from 'components/UI/Dialog/Dialog';
import {
  withRouter
} from "react-router-dom";
import DynamicListComponent from "components/UI/DynamicList/DynamicListComponent";
import config from "config/api";
const moduleConfig = {
  module: "faq",
  fetchUrl: config.faq + "/list",
  deleteUrl: config.faq + "/delete",
  editUrl: "help/edit",
  title: "FAQ",
};
const FAQ_DISPLAY = "box";
class Faq extends DynamicListComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this._onClick = this._onClick.bind(this);
  }
  componentDidMount() {
    this.setupList(moduleConfig);
    this.props.onMount(this.moduleConfig);
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }
  _onClick(visibility) {
    this.setState({
      answerVisible: visibility
    });
  }
  _onFormComplete() {}
  render() {
    const {
      forceOpen,
      $$rows,
      direction,
      bulletDirection,
      faqItemClasses,
      faqItemButlletIcons,
      showQuestionBullet,
      showAnserBullet,
      onePerRow=true,
      offsetWidth,
      gridMainTextMinSize,
      // $$actions,
    } = this.props;
    // const dense = window.innerWidth >= 1024;
    let FaqItem;
    if(FAQ_DISPLAY === "box") FaqItem = FaqItemBox;
    else FaqItem = FaqItemList;
    const {
      answerVisible,
      // dialogDeleteState,
    } = this.state;
    return (
      <Grid
        className="Faq"
        container
        spacing={0}
        justifyContent={onePerRow ? 'flex-start' : "center"}
        item
        xs={12}
      >
        <If condition={$$rows !== undefined}>
          {$$rows
            .map(($$help, hIdx) => {
              return (
                <FaqItem
                  bulletIcon={faqItemButlletIcons !== undefined && faqItemButlletIcons[hIdx] !== undefined ? faqItemButlletIcons[hIdx] : false}
                  direction={direction}
                  bulletDirection={bulletDirection}
                  key={"itm_" + hIdx}
                  $$help={$$help}
                  hIdx={hIdx}
                  onClick={this._onClick}
                  answerVisible={hIdx === answerVisible || forceOpen}
                  forceOpen={forceOpen}
                  last={hIdx === $$rows.size-1}
                  classes={faqItemClasses}
                  showQuestionBullet={showQuestionBullet}
                  showAnserBullet={showAnserBullet}
                  onePerRow={onePerRow}
                  offsetWidth={offsetWidth}
                  gridMainTextMinSize={gridMainTextMinSize}
                />
              );
            })
            .toArray()}
        </If>
      </Grid>
    );
  }
}
export default withRouter(Faq);
