import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from 'components/UI/Form/Select';
import Input from 'components/UI/Form/Input';
import Checkbox from 'components/UI/Form/Checkbox';
import AutoComplete from 'components/UI/Form/AutoComplete';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import i18n from 'helpers/i18n';
// import BlockButton from "components/UI/BlockButton";
import remove from 'mout/array/remove';
import Logger from 'components/UI/Logger';
import isInCategory from 'components/UI/helpers/isInCategory';

import { capitalize } from 'helpers/stringUtils';
// import debounce from 'mout/function/debounce';
import Immutable from 'immutable';
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      erroredFields: [],
    };
    this._onFormSubmit = this._onFormSubmit.bind(this);
    this._onFormCancel = this._onFormCancel.bind(this);
    this._onFieldValueChange = this._onFieldValueChange.bind(this);
    this._getConfig = this._getConfig.bind(this);
    this._getI18n();
  }
  componentDidMount() {
    this._setupState({}, () => {
      if (this.props.$$formValues !== undefined) {
        // console.log('mapping values..')
        this._mapRawValues(this.props.$$formValues);
      }
    });
    // this.props.onMount(this.props.moduleConfig);
  }
  // componentDidUpdate(oldProps) {
  //   if (oldProps.$$formValues === undefined && this.props.$$formValues !== undefined)
  //   {
  //     this._mapRawValues(this.props.$$formValues);
  //   }
  // }
  componentWillUnmount() {
    // this.props.onUnmount(this.props.moduleConfig);
  }
  // componentDidUpdate(oldProps) {
  //   // console.log('old', oldProps)
  //   // console.log('new', this.props)
  //   // if (oldProps.$$formValues !== this.props.$$formValues)
  //     // console.log(oldProps.$$formValues.toJS(), this.props.$$formValues.toJS())
  //     console.log(this.props)
  //   if (oldProps.isFormComplete === undefined && this.props.isFormComplete === true) {
  //     console.log('DID COMPLETE')
  //     if (this.props.onFormComplete) this.props.onFormComplete();
  //   }
  // }
  // _setFormValues: function() {
  //   const { $$formValues } = this.props;
  //
  // }
  _setupState = (extraState, callback) => {
    const s = {
      ...{
        activeCategories: [],
        erroredFields: {},
        $$formValues: Immutable.fromJS({}),
      },
      ...(extraState !== undefined ? extraState : {}),
    };
    // console.log('s is', s)
    this.setState(s, callback);
  }
  // let $$formValues = Immutable.fromJS({});
  // if ($$formRawValues !== undefined)
  //  $$formValues = this._mapRawValues($$formRawValues);
  _getLabel = ($$field) => {
    //MODIF B : correction parenthèse
    return $$field.get('translate') ? i18n.get($$field.get('label'), {pretty: false}) : $$field.get('label')
  }
  _getI18n = () => {
    this.i18n = {
      send: 'Envoyer',
      sending: 'Envoyer',
      requiredFieldsLegend: i18n.get('formFieldsRequiredLegend', {pretty: false}),
      buttonClassSuffix:'',
    };
  }
  _validateCell = ($$field, value) => {
    let check = true;
    if (value !== undefined) {
      value = value.toString().replace(/ /g, '');
      check = /^(?:\+[0-9]{2,3})[0-9]{8}$/.test(value);
      // console.log('check 1', check)
      if (check !== true) check = /^(0[67])[0-9]{8}$/.test(value);
      // console.log('check 1', check)
    }
    const label = this._getLabel($$field);
    const log =
    check === true
    ? undefined
    : 'Le champs ' + label + ' doit être un numéro de téléphone portable';
    return log;
  }
  _validateNum = ($$field, value) => {
    let check = true;
    if (value !== undefined) check = !/([^0-9])/.test(value);
    // console.log('CHECK NUM', check)
    const label = this._getLabel($$field);
    const log = check === true ? undefined : 'Le champs ' + label + ' doit être numérique';
    return log;
  }
  _validateAlphaNum = ($$field, value) => {
    let check = true;
    if (value !== undefined) check = !/([^0-9a-z\-_îïéèëêâàôöüûùç&É' ])/i.test(value);
    // console.log('CHECK ALPHA NUM', check)
    const label = this._getLabel($$field);
    const log =
    check === true ? undefined : 'Le champs ' + label + ' doit être alphanumérique';
    return log;
  }
  // _validateEmail($$field, value) {
  //   let check = true;
  //   if (value !== undefined) check = !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
  //   const log = check === true ? undefined : 'Le champs ' + $$field.get('label') + ' est invalide';
  //   return log;
  // }
  _validateEmail = ($$field, value) => {
    let check = true;
    const label = this._getLabel($$field);
    if (value !== undefined) check = !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
    const log = check === true ? undefined : 'Le champs ' + label + ' est invalide';
    return log;
  }
  _validatePassword = ($$field, value) => {
    let log;
    const label = this._getLabel($$field);
    if (!value || value === undefined)
    log =
    'Le champs ' +
    label +
    ' doit contenir au moins 8 charactères, une majuscule et un chiffre';
    else if (value.toString().length < 8)
    log = 'Le champs ' + label + ' doit contenir au moins 8 charactères';
    else if (!/[A-Z]/.test(value))
    log = 'Le champs ' + label + ' doit contenir au moins une majuscule';
    else if (!/[0-9]/.test(value))
    log = 'Le champs ' + label + ' doit contenir au moins un chiffre';
    return log;
  }
  _validateSameAs = ($$field, value, secondValue) => {
    let log;
    // console.log({value, secondValue})
    const label = this._getLabel($$field);
    if (!value || value === undefined || value !== secondValue)
    log =
    'Le champs ' +
    label +
    ' doit corréspondre pas au champs "' + $$field.get('sameAs').get('label')+'"';
    // else if (value)
    return log;
  }
  // _validateNotEmpty($$field, value) {
  //   let check = true;
  //   // console.log('NOT EMPTY CHECK', value)
  //   if (value === null || value === undefined || value === '') check = false;
  //   const log = check === true ? undefined : 'Le champs ' + $$field.get('label') + ' est requis';
  //   return log;
  // }
  _validateNotEmpty = ($$field, value, validationAtt) => {
    let check = true;
    // console.log('NOT EMPTY CHECK', $$field.get('label'), value)
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      (value === 'off' && $$field.get('type') === 'Checkbox') ||
      (value.size !== undefined && value.size === 0)
    )
    check = false;
    const label = this._getLabel($$field);
    const log = check === true ? undefined : 'Le champ ' + label + ' est requis';
    return log;
  }

  _validateMinLength = ($$field, value) => {
    let check = true;
    if (value === undefined || value === null || value.length < $$field.get('minLength')) check = false;
    const label = this._getLabel($$field);
    const log =
    check === true
    ? undefined
    : 'Le champs ' +
    label +
    ' doit contenir au moins ' +
    $$field.get('minLength') +
    ' charactères';
    // console.log({
    //   k: 'min',
    //   check,
    //   log,
    //   value,
    //   'minLength': $$field.get('minLength'),
    //   test: value.length < $$field.get('minLength')
    // })
    return log;
  }
  _validateMaxLength = ($$field, value) => {
    let check = true;
    const label = this._getLabel($$field);
    if (value !== undefined && value !== null && value.length > $$field.get('maxLength')) check = false;
    const log =
    check === true
    ? undefined
    : 'Le champs ' +
    label +
    ' doit contenir au maximum ' +
    $$field.get('maxLength') +
    ' charactères';
    // console.log({
    //   k: 'max',
    //   check,
    //   log,
    //   value,
    //   'maxLength': $$field.get('maxLength'),
    //   test: value.length > $$field.get('maxLength')
    // })
    return log;
  }
  // _setFieldValue(e) {
  //   let h = {}
  //   h[e.target.id] = e.target.value;
  //   this.setState(h)
  // }
  _validateFieldsInput = () => {
    const { activeCategories } = this.state;
    let log = [];
    let erroredFields = {};
    this.$$FIELDS.map((field) => {
      let check = undefined;
      const fieldName = field.get('id');
      const value = this.state['f_' + fieldName];
      let inCategory = isInCategory(field, activeCategories);
      if (inCategory === true && field.get('validation') !== undefined) {
        field.get('validation').map((validation) => {
          check = undefined;
          switch (validation) {
            case 'email':
            check = this._validateEmail(field, value);
            break;
            case 'notEmpty':
            check = this._validateNotEmpty(field, value);
            break;
            case 'num':
            check = this._validateNum(field, value);
            break;
            case 'alphaNum':
            check = this._validateAlphaNum(field, value);
            break;
            case 'cell':
            check = this._validateCell(field, value);
            break;
            case 'password':
            check = this._validatePassword(field, value);
            break;
            case 'sameAs':
            // console.log('state', this.state);
            // console.log(field.get('sameAs').get('field'))
            // console.log(field.get('sameAs').toJS())
            check = this._validateSameAs(field, value, this.state['f_' + field.get('sameAs').get('field')]);
            break;
            default:
            console.log('missing validator', validation);
            break;
          }
          if (check !== undefined) {
            erroredFields[field.get('id')] = check;
            log.push(check);
          }
          return true;
        });
        if (check === undefined && field.get('minLength') !== undefined) {
          // console.log('test min', field.get('label'), field.get('minLength'))
          check = this._validateMinLength(field, value);
          if (check !== undefined) {
            erroredFields[field.get('id')] = check;
            log.push(check);
          }
        }
        if (check === undefined && field.get('maxLength') !== undefined) {
          // console.log('test max', field.get('label'), field.get('maxLength'))
          check = this._validateMaxLength(field, value);
          if (check !== undefined) {
            erroredFields[field.get('id')] = check;
            log.push(check);
          }
        }
      } else {
        console.log('skipping', field.get('label'));
        return false;
      }
      return true;
    });
    // console.log({
    //   erroredFields
    // })
    this.setState({
      erroredFields,
    });
    return log.length === 0 ? true : log;
  }
  // onSubmit = (event) => {
  //   const {
  //     confirmBeforeAdd,
  //     confirmBeforeEdit
  //   } = this.props.moduleConfig;
  //
  //   event.preventDefault(event);
  //   if (confirmBeforeAdd && !this.props.id) {
  //     confirmBeforeAdd.onSubmit = this.props.handleSubmit;
  //     this.props.onConfirm(confirmBeforeAdd);
  //   }
  //   else if (confirmBeforeEdit && this.props.id) {
  //     confirmBeforeEdit.onSubmit = this.props.handleSubmit;
  //     this.props.onConfirm(confirmBeforeEdit);
  //   }
  //   else
  //    this.props.handleSubmit(event);
  // }
  //
  // onCancel = (event) => {
  //   event.preventDefault();
  // }
  _onFormSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // debounce(this._sendForm.bind(this), 1000);
    this._sendForm();
  }
  _sendForm = (overrides) => {
    const { loading, extraFields } = this.props;
    if (loading) {
      console.log('already loading!');
      return;
    }
    const log = this._validateFieldsInput();
    if (log === true) {
      let formData = {};
      this.$$FIELDS.map((field) => {
        return (formData[field.get('id')] = this.state['f_' + field.get('id')]);
      });
      // console.log('extraFields', extraFields.toJS())
      if (extraFields !== undefined) {
        extraFields.map(function (v, k) {
          formData[k] = v;
          return v;
        });
      }
      // console.log({overrides})
      if (overrides !== undefined) {
        overrides.map(function (v, k) {
          formData[k] = v;
          return v;
        });
      }
      if (this._onSubmit) this._onSubmit(formData);
      else this.props.onSubmit(formData);
    } else {
      this.setState({
        log: log,
      });
    }
  }
  _onFormCancel = () => {
    const { onFormCancel } = this.props;
    if (onFormCancel) onFormCancel();
  }
  _onFieldValueChange = (kind, target) => {
    // console.log('changing', kind, target)
    const fid = target.id;
    const value = target.value;
    const $$field = this.$$FIELDS.find(($$f) => $$f.get('id') === fid);
    if ($$field === undefined) {
      // console.log('unuset init field', fid);
    } else {
      if (($$field.get('type') === 'select' || $$field.get('type') === 'SelectField' || $$field.get('type') === 'checkbox' || $$field.get('type') === 'Checkbox') && $$field.get('categoryTrigger') !== undefined) {
        this._onCategoryTrigger(fid, value, $$field.get('categoryTrigger'), $$field.get('type'));
      }

      const fn = '_on' + capitalize(fid) + 'Changed';
      this.setState(
        {
          ['f_' + fid]: value,
        },
        function () {
          if (this[fn]) {
            this[fn](fid, value);
          }
        }.bind(this),
      );
    }
  }
  _mapRawValues = ($$values) => {
    // var tmp = {}
    if ($$values === undefined) return;
    // console.log('should map', $$values)
    $$values.map((val, k) => {
      // console.log('changing', val, k)
      this._onFieldValueChange(null, {
        id: k,
        value: val,
      });
      return true;
    });
    return $$values;
  }
  _getConfig = (name) => {
    // console.log(this.$$CONFIG, name, )
    return this.$$CONFIG !== undefined ? this.$$CONFIG.get(name) : undefined;
  }
  _getFormFooter = () => {
    return this.props.formFooter || undefined;
  }
  _getFormNotice = () => {
    return this.props.formNotice || undefined;
  }
  _onCategoryTrigger = (field, value, $$triggers, fieldType) => {
    if ($$triggers === undefined) return;
    let catName;
    if (fieldType  === 'checkbox' || fieldType === 'Checkbox')
    catName = $$triggers.get('true');
    else
    catName = $$triggers.get(value);
    let currentCategories = this.state.activeCategories;

    let newCategories = [];

    if ((fieldType  === 'checkbox' || fieldType === 'Checkbox') && value !== 'on' && currentCategories.indexOf(catName) >= 0) {
      newCategories = currentCategories;
      remove(newCategories, catName);
    } else {
      if (currentCategories.indexOf(catName) >= 0) return;

      if (currentCategories.length > 0) {
        currentCategories.forEach((cat, cId) => {
          // console.log('looking for', cat, $$triggers.toJS())
          if (!$$triggers.find((t) => t === cat)) {
            newCategories.push(cat);
          }
        });
      }
      newCategories.push(catName);
    }
    this.setState({
      activeCategories: newCategories,
    });
  }

  _renderForm = () => {
    const {
      error,
      loading,
      showRequiredLegend,
      footerAlign,
      classes,
      // $$formValues: $$formRawValues,
    } = this.props;
    // console.log({classes})
    const compact = this.$$CONFIG !== undefined ? this.$$CONFIG.get('compact') : true;
    let fieldVisibility;
    const { activeCategories, erroredFields: stateErroredFields } = this.state;
    let erroredFields = {};
    if (stateErroredFields !== undefined) erroredFields = stateErroredFields;
    // const { log } = this.state;
    // let $$formFilteredValues = {}
    // // if ($$formValues) {
    //   $$formFilteredValues = {
    //     accountName: $$formValues.get('name'),
    //     accountFirstname: $$formValues.get('firstname'),
    //   }
    //   $$formValues.map((value, idx) => $$formFilteredValues[idx] = value)
    // }
    // let $$formValues = Immutable.fromJS({});
    // if ($$formRawValues !== undefined)
    //  $$formValues = this._mapRawValues($$formRawValues);
    // const { $$formValues } = this.state;
    let value;
    let variant =
    this._getConfig('fieldVariant') !== undefined ? this._getConfig('fieldVariant') : 'outlined';
    const formFooter = this._getFormFooter();
    const formNotice = this._getFormNotice();

    // console.log(this.i18n.cancel)
    return (
      <form className="Form" onSubmit={this._onFormSubmit} key="form">
        <Grid
          className="grid"
          container
          spacing={2}
          alignItems="center"
          justify="space-around"
          style={{ height: '100%' }}
          >
          {this.$$FIELDS
            .map((field, fIdx) => {
              // if (field.get('category') === undefined)
              // fieldVisibility = true;
              // else if (activeCategories.indexOf(field.get('category')) >= 0)
              // fieldVisibility = true;
              // else
              // fieldVisibility = false;
              fieldVisibility = isInCategory(field, activeCategories);

              // value = $$formValues.get(field.get('name')) !== undefined ? $$formValues.get(field.get('name')) : undefined;
              value =
              this.state['f_' + field.get('name')] !== undefined
              ? this.state['f_' + field.get('name')]
              : undefined;
              // console.log(field.get('name'), 'value is', value, fieldVisibility)
              // console.log('TESTTEST')
              // console.log('TESTTEST')
              // console.log('TESTTEST')
              // console.log($$formValues.toJS())
              // const label = field.get('translate') ? i18n.get(field.get('label'), {pretty: false}) : field.get('label');
              const label = field.get('translate') ? i18n.get(field.get('label'), {pretty: false}) : field.get('label');
              return (
                <Grid
                  item
                  xs={field.get('sizes') && field.get('sizes').get('xs') ? field.get('sizes').get('xs') : 12}
                  sm={field.get('sizes') && field.get('sizes').get('sm') ? field.get('sizes').get('sm') : 12}
                  key={'f_' + fIdx}
                  style={{
                    paddingLeft: 10,
                    paddingRight: '10px',
                    display: fieldVisibility === true ? undefined : 'none',
                  }}
                  className={fieldVisibility === true ? 'field' : 'hidden'}
                  >
                  <Choose>
                    <When condition={field.get('type') === 'select'}>
                      <Select
                        id={field.get('id')}
                        name={field.get('name')}
                        value={value}
                        label={label}
                        placeholder={field.get('placeholder')}
                        align={field.get('align') ? field.get('align') : 'left'}
                        onChange={this._onFieldValueChange}
                        options={field.get('options')}
                        disabled={field.get('disabled')}
                        multiple={field.get('multiple')}
                        onCategoryTrigger={this._onCategoryTrigger}
                        required={
                          field.get('validation') !== undefined &&
                          field.get('validation').indexOf('notEmpty') >= 0
                        }
                        error={
                          erroredFields[field.get('id')] !== undefined &&
                          erroredFields[field.get('id')] === true
                        }
                        errorMessage={
                          erroredFields[field.get('id')] !== undefined &&
                          erroredFields[field.get('id')] !== false
                          ? erroredFields[field.get('id')]
                          : false
                        }
                        variant={variant}
                        />
                    </When>
                    <When condition={field.get('type') === 'checkbox'}>
                      <Checkbox
                        name={field.get('id')}
                        id={field.get('id')}
                        value={value}
                        label={label}
                        onChange={this._onFieldValueChange}
                        options={field.get('options')}
                        disabled={field.get('disabled')}
                        multiple={field.get('multiple')}
                        required={
                          field.get('validation') !== undefined &&
                          field.get('validation').indexOf('notEmpty') >= 0
                        }
                        error={
                          erroredFields[field.get('id')] !== undefined &&
                          erroredFields[field.get('id')] !== false
                        }
                        errorMessage={
                          erroredFields[field.get('id')] !== undefined &&
                          erroredFields[field.get('id')] !== false
                          ? erroredFields[field.get('id')]
                          : false
                        }
                        variant={variant}
                        />
                    </When>

                    <When condition={field.get('type') === 'AutoComplete'}>
                      <AutoComplete
                        id={field.get('id')}
                        name={field.get('name')}
                        value={value}
                        type={field.get('type')}
                        onChange={this._onFieldValueChange}
                        label={label}
                        error={
                          erroredFields[field.get('id')] !== undefined &&
                          erroredFields[field.get('id')] !== false
                        }
                        errorMessage={
                          erroredFields[field.get('id')] !== undefined &&
                          erroredFields[field.get('id')] !== false
                          ? erroredFields[field.get('id')]
                          : false
                        }
                        maxLength={field.get('maxLength')}
                        multiline={field.get('multiline')}
                        disabled={field.get('disabled')}
                        rows={field.get('rows')}
                        required={
                          field.get('validation') !== undefined &&
                          field.get('validation').indexOf('notEmpty') >= 0
                        }
                        variant={variant}
                        />
                    </When>
                    <Otherwise>
                      <Input
                        id={field.get('id')}
                        name={field.get('name')}
                        value={value}
                        align={field.get('align') ? field.get('align') : 'left'}
                        type={field.get('type')}
                        onChange={this._onFieldValueChange}
                        label={label}
                        placeholder={field.get('placeholder')}
                        error={
                          erroredFields[field.get('id')] !== undefined &&
                          erroredFields[field.get('id')] !== false
                        }
                        errorMessage={
                          erroredFields[field.get('id')] !== undefined &&
                          erroredFields[field.get('id')] !== false
                          ? erroredFields[field.get('id')]
                          : false
                        }
                        helperText={<Paragrapher
                          text={field.get('helperText')}
                          prettyfy={true}
                          element={"span"}
                        />}
                        maxLength={field.get('maxLength')}
                        multiline={field.get('multiline')}
                        disabled={field.get('disabled')}
                        rows={field.get('rows')}
                        required={
                          field.get('validation') !== undefined &&
                          field.get('validation').indexOf('notEmpty') >= 0
                        }
                        variant={variant}
                        />
                    </Otherwise>
                  </Choose>
                </Grid>
              );
            })
            .toArray()}

            <If condition={error !== undefined && error !== false && error !== null}>
              <Grid item xs={12} className={''}>
                <Box align="center">
                  <Logger
                    status={'error'}
                    primary={<Paragrapher text={error} element="div" prettyfy={true} />}
                    />
                </Box>
              </Grid>
            </If>
            <If condition={showRequiredLegend !== false && (this.$$CONFIG !== undefined && this.$$CONFIG.get('requiredFieldsLegendPosition') !== undefined && this.$$CONFIG.get('requiredFieldsLegendPosition') === 'beforeActions')}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ textAlign: this.$$CONFIG !== undefined && this.$$CONFIG.get('requiredFieldsLegendAlign') !== undefined ? this.$$CONFIG.get('requiredFieldsLegendAlign'):'right' }}
                >
                <Box mt={2}>
                  <Typography variant={'body1'} className={classes.requiredFieldsLegend}> {this.i18n.requiredFieldsLegend}</Typography>
                </Box>
              </Grid>
            </If>
            <If condition={formNotice !== undefined}>
              <Grid
                item
                xs={12}
                sm={12}
                className="formNotice"
                >
                {formNotice}
              </Grid>
            </If>

            <Grid item xs={12}>
              <Box align="middle" mt={0}>
                <If condition={this._getConfig('btCancel') === true}>
                  <Button
                    size={this._getConfig('buttonSize') || 'large'}
                    disabled={loading}
                    color={this._getConfig('buttonColorCancel') || 'secondary'}
                    variant={this._getConfig('buttonVariant') || 'outlined'}
                    onClick={this._onFormCancel}
                    style={{ margin: '8px' }}
                    >
                    {this.i18n.cancel}
                  </Button>
                </If>
                <Button
                  size={this._getConfig('buttonSize') || 'large'}
                  disabled={loading}
                  color={this._getConfig('buttonColorSubmit') || 'secondary'}
                  variant={this._getConfig('buttonVariant') || 'contained'}
                  onClick={this._onFormSubmit}
                  style={{ margin: '8px' }}
                  className={
                    "FORM_SUBMIT_BUTTON"
                    +(this.i18n.buttonClassSuffix!=='' ? "_"+this.i18n.buttonClassSuffix : "")
                  }
                  >
                  {loading ? this.i18n.sending : this.i18n.send}
                </Button>
              </Box>
            </Grid>
            <If condition={formFooter !== undefined}>
              <Grid
                item
                xs={12}
                sm={compact !== false ? 6 : 12}
                className={classes ? classes.formFooter : undefined}
                style={{
                  textAlign: !classes ? (compact !== false ? (footerAlign !== undefined ? footerAlign : 'right') : 'center') : undefined,
                }}
                >
                {formFooter}
              </Grid>
            </If>
            <If condition={showRequiredLegend !== false && this.$$CONFIG !== undefined && (this.$$CONFIG.get('requiredFieldsLegendPosition') === undefined || this.$$CONFIG.get('requiredFieldsLegendPosition') === 'afterActions')}>
              <Grid
                item
                xs={12}
                sm={formFooter !== undefined && compact !== false ? 6 : 12}
                style={{ textAlign: this.$$CONFIG !== undefined && this.$$CONFIG.get('requiredFieldsLegendAlign') !== undefined ? this.$$CONFIG.get('requiredFieldsLegendAlign'):'right' }}
                >
                <Typography variant={'body1'} className={classes ? classes.requiredFieldsLegend : undefined}> {this.i18n.requiredFieldsLegend}</Typography>
              </Grid>
            </If>
          </Grid>
        </form>
      );
    }
    //   render() {
    //     const {
    //       component,
    //       initialProps,
    //       location,
    //       fadeOpacity,
    //       fadeDuration,
    //     } = this.props
    //     const { hidden, transform } = this.state;
    //
    //     let props = {};
    //
    // var methods = ['_validateEmail', '_validateNotEmpty', '_validateNum', '_validateAlphaNum', '_validateCell', '_onFormSubmit', '_validateFieldsInput', '_setFieldValue'];
    //
    // methods.forEach((m) => {
    //   props[m] = this[m];
    // }.bind(this))
    //
    //     return React.createElement(component, props)
    //   }
  }
  // const withFormValidation = (subComponent) => {
  //   return (props) => <WithFormValidation component={subComponent} initialProps={props} />
  // }
  //
  // export default withFadeIn
  export default Form;
