import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';

const actionTypes = mirrorCreator(['SET_SENT', 'SET_LOG', 'SET_ERROR', 'SET_SENDING', 'SET_STATE_KNOWN'], {
  prefix: 'contact/',
});

const mutators = {
  setSent: (state) => ($$state) => $$state.set('sent', state),
  clearErrors: ($$state) => $$state.delete('error'),
  setLog: (log) => ($$state) => $$state.set('log', log),
  setError: (error) => ($$state) => $$state.set('error', error),
  setSending: (sending) => ($$state) => $$state.set('sending', sending),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_SENT:
      acts.push(mutators.setSent(action.sent));
      if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_ERROR:
      return pipe([mutators.setError(action.error)], $$state);

    case actionTypes.SET_LOG:
      return pipe([mutators.setLog(action.log)], $$state);

    case actionTypes.SET_SENDING:
      return pipe([mutators.setSending(action.status)], $$state);

    case actionTypes.SET_STATE_KNOWN:
      return pipe([mutators.setStateKnown(action.stateKnown)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.contact || Immutable.Map();
export const isStateKnown = createSelector([getRoot], ($$state) => $$state.get('stateKnown'));

export const isSending = createSelector([getRoot], ($$state) => $$state.get('sending', false));
export const isSent = createSelector([getRoot], ($$state) => $$state.get('sent') === true);
export const hasSentFailed = createSelector([getRoot], ($$state) => $$state.get('error') !== undefined);
export const getLog = createSelector([getRoot], ($$state) => $$state.get('log'));
export const getError = createSelector([getRoot], ($$state) => $$state.get('error'));

export function send(formData) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.contact;
    dispatch(setSending(true));

    formData.contact_form = true;

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.contact && response.contact === 'complete') {
          dispatch(setSent(true));
          dispatch(setLog(response.log));
        } else {
          dispatch(setError(response.log));
        }
        setSending(false);
      })
      .catch((error) => dispatch(setError(error.message)))
      .finally(() => dispatch(setSending(false)));
  };
}

export function setSent(sent) {
  return {
    type: actionTypes.SET_SENT,
    sent,
  };
}
export function setError(error) {
  return {
    type: actionTypes.SET_ERROR,
    error,
  };
}
export function setLog(log) {
  return {
    type: actionTypes.SET_LOG,
    log,
  };
}

export function setSending(sending) {
  return {
    type: actionTypes.SET_SENDING,
    status: sending,
  };
}

export function setStateKnown(stateKnown = true) {
  return {
    type: actionTypes.SET_STATE_KNOWN,
    stateKnown,
  };
}
