import React from 'react';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';

// import _LOCALE from "i18n/neuro_fr.js";
// const _LOCALE = require.ensure(`../i18n/${process.env.REACT_APP_SITE}_fr.js`);

// let _LOCALE = null;

// import(`../i18n/${process.env.REACT_APP_SITE}_fr.js`).then((module) => {
//   console.log('imported')
//   _LOCALE = module.default;
// });

// const loadLangFile = async () => {
//   console.log('TRYING TO LOAD LANG FILE')
//   console.log(_LOCALE)
//   if (_LOCALE !== null) return;
//   console.log('READING NOW')
//     const awaited = await import(`../i18n/${process.env.REACT_APP_SITE}_fr.js`).then((r) => {
//       console.log('AWAITED')
//       return r
//     });
//     console.log('AWAITED END')
//     _LOCALE = module.default;
//     console.log('TEST', _LOCALE);
// };

let _LOCALE = require(`i18n/${process.env.REACT_APP_SITE}_fr.js`);
_LOCALE = _LOCALE.default;
const get = (string, options) => {
  if (!_LOCALE) {
    console.log('error no locale found! cannot get ', string);
    throw Error('foo');
    // return '';
  }
  // loadLangFile();
  let r, s;
  r = _LOCALE[string] !== undefined ? _LOCALE[string] : string;

  if (
    string !== false &&
    ((options !== undefined && options.pretty === true) || options === undefined || options.pretty !== false)
  )
    s = (
      <Paragrapher
        prettyfy={true}
        text={r}
        element={options !== undefined && options.element ? options.element : 'span'}
        elementStyle={{
          display: 'block',
          minHeight: '16px',
          ...(options !== undefined && options.elementStyle !== undefined ? options.elementStyle : {}),
        }}
        substitute={
          options !== undefined && options.substitute !== undefined ? options.substitute : undefined
        }
      />
    );
  else {
    s = r;
  }
  return s;
};

let has = (string) => {
  if (!_LOCALE) return '';

  return _LOCALE[string] !== undefined && _LOCALE[string] !== false;
};

export default {
  get,
  has,
};
