import React, {
  Component
} from "react";
// import Fade from "@material-ui/core/Fade";
import {
  withStyles
} from "@material-ui/core/styles";
// import getCardImage from 'helpers/getCardImage'
import {
  connect
} from "react-redux";
import Immutable from "immutable";
// import config from "config/api";
import Button from "@material-ui/core/Button";
// import Container from "@material-ui/core/Container";
// import Paper from "@material-ui/core/Paper";
// import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";
// import BlockTitle from "components/UI/BlockTitle";
// import Rating from '@material-ui/lab/Rating';
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import Quiz from "components/UI/Quiz/Quiz";
import Announce from "components/UI/Announce/AnnounceEmbed";
import Feedback from "components/UI/Feedback/FeedbackEmbed";
import SocketHelper from 'components/UI/Socket/SocketHelper'
import DialogMovable from "components/UI/Dialog/DialogMovable";
// import BlockRatio from "components/BlockRatio";
// import LiveMessage from "components/Lives/LiveMessage";


import {
  withRouter
} from "react-router-dom";
// import {
// fetchQuiz,
// getQuizData,
// getQuizAnswered,
// getQuizError,
// sendQuiz,
// } from "components/UI/Quiz/ducks/quiz";
import {
  // fetchData,
  getDataHome,
  // getLoadingError,
} from "ducks/asyncFetcher";
import {
  isStateKnown as authStateKnown,
  // isLoggedIn,
  // getCurrentUserRole,
  getCurrentUser,
} from "components/UI/Account/ducks/authentication";
// import i18n from "helpers/i18n";

import {
  getEventState,
  fetchEventState,
} from "components/UI/Socket/ducks/relay"


const USE_WEBSOCKET = false;

const styles = (theme) => {
  // console.log(theme)
  return {
    inline: {
      display: 'inline-block'
    },
    overlayedButtonWrapper: {
      position: 'relative'
    },
    overlayedButton: {
      position: 'fixed',
      zIndex: 999,
      right: 10,
      bottom: 10
    }
  }
};
class Orchestrator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogIsOpen: false
    }
  }
  componentDidMount() {
    // this.props.onMount();
    if (this.props.authStateKnown !== undefined)
    {
      const {
        eventId,
      } = this.props;
      this.props.onMount({eventId});
    }
  }
  componentDidUpdate(oldProps) {
    if (oldProps.authStateKnown === undefined && this.props.authStateKnown !== undefined) {
      const {
        eventId,
      } = this.props;
      this.props.onMount({eventId});
    }

    if (oldProps.$$eventState !== this.props.$$eventState)
    {
      if (this.props.$$eventState.get('id') !== 'none')
      this.setState({dialogIsOpen: true})
      else
      this.setState({dialogIsOpen: false})
    }
  }

  _openDialog = (e) => {
    e.preventDefault();
    this.setState({dialogIsOpen: true})
  }
  _onClose = () => {
    this.setState({dialogIsOpen: false})
  }
  render() {
    const {
      $$data,
      $$user,
      $$eventState,
      eventId,
      // $$card,
      classes,
      // onMessageSent
    } = this.props;
    const {
      dialogIsOpen
    } = this.state;
    // const {
    //   eventId,
    // } = this.props.match.params;
    const $$event = Immutable.fromJS({id: eventId})
    // if ($$eventState !== false && $$eventState !== undefined)
    // console.log($$eventState.toJS())
    // else console.log('no eventstate?!', $$eventState)
    // const showLive = $$eventState !== undefined && $$eventState.get('contentType') !== 'feedback';
    // console.log({showLive})
    const dialogStyle = {maxWidth: 600, width: '80%'};
    return (
      <React.Fragment>
        <If condition={$$user !== undefined}>
          <SocketHelper $$event={$$event} $$user={$$user} endpoint={'participant'} pull={USE_WEBSOCKET === false}/>
        </If>
        <If condition={$$data !== undefined  && $$eventState !== undefined}>

          {/*  <If condition={showLive === true}>
          <Box mt={0} mb={1} px={4} align="center">
          <Typography variant="caption">
          <span className={classes.inline}>
          {i18n.get("liveInfo")}
          </span>
          </Typography>
          </Box>

          <If condition={$$card.get("eventLinkInstructions") !== undefined && $$card.get("eventLinkInstructions") !== false}>
          <Typography variant={'subtitle1'} align="center" gutterBottom={true}>{$$card.get("eventLinkInstructions")}</Typography>
          </If>
          <BlockRatio ratio={"16_9"}>
          <iframe
          title="replay"
          src={$$card.get("eventLinkLive")}
          className={"embed"}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          />
          </BlockRatio>

          <Box my={2}>
          <Typography variant="h5">
          Posez votre question ici :
          </Typography>
          </Box>
          <LiveMessage
          $$event={$$card}
          onMessageSent={onMessageSent}
          />
          </If>*/}

          <Choose>
            <When condition={$$eventState.get('contentType') === 'comment'}>
              <Typography variant="h5">Posez votre question :</Typography>
            </When>
            <When condition={$$eventState.get('contentType') === 'quiz'}>
              <DialogMovable open={dialogIsOpen} showOnClose={true} onClose={this._onClose} title={'Quizz :'} style={dialogStyle}>
                <Quiz eventId={eventId} questionId={$$eventState.get('contentId')} revision={$$eventState.get('contentRevision')} pull={USE_WEBSOCKET === false}/>
              </DialogMovable>
              <If condition={dialogIsOpen !== true}>
                <div className={classes.overlayedButtonWrapper}>
                  <Button variant="contained" color="primary" className={classes.overlayedButton} onClick={this._openDialog}> Quiz </Button>
                </div>
              </If>
            </When>
            <When condition={$$eventState.get('contentType') === 'announce'}>
              <DialogMovable open={dialogIsOpen} showOnClose={false} onClose={this._onClose} title={undefined} style={dialogStyle}>
                <Announce eventId={eventId} questionId={$$eventState.get('contentId')} revision={$$eventState.get('contentRevision')} pull={USE_WEBSOCKET === false}/>
              </DialogMovable>
              <If condition={dialogIsOpen !== true}>
                <div className={classes.overlayedButtonWrapper}>
                  <Button variant="contained" color="primary" className={classes.overlayedButton} onClick={this._openDialog}> Message important </Button>
                </div>
              </If>
            </When>
            <When condition={$$eventState.get('contentType') === 'poll'}>
              <DialogMovable open={dialogIsOpen} showOnClose={true} onClose={this._onClose} title={'Sondage :'} style={dialogStyle}>
                <Quiz eventId={eventId} questionId={$$eventState.get('contentId')} revision={$$eventState.get('contentRevision')} pull={USE_WEBSOCKET === false}/>
              </DialogMovable>
              <If condition={dialogIsOpen !== true}>
                <div className={classes.overlayedButtonWrapper}>
                  <Button variant="contained" color="primary"  className={classes.overlayedButton} onClick={this._openDialog}> Sondage </Button>
                </div>
              </If>

            </When>
            <When condition={$$eventState.get('contentType') === 'feedback'}>
              <DialogMovable open={dialogIsOpen} showOnClose={true} onClose={this._onClose} title={'Votre avis nous intéresse'} style={dialogStyle}>
                <Feedback eventId={eventId} onFeedbackComplete={this._onClose} footer={$$event.get('eventLegalFeedback')}/>
              </DialogMovable>
              <If condition={dialogIsOpen !== true}>
                <div className={classes.overlayedButtonWrapper}>
                  <Button variant="contained" color="primary"  className={classes.overlayedButton} onClick={this._openDialog}> Feedback </Button>
                </div>
              </If>

            </When>
            <Otherwise>
              &nbsp;
            </Otherwise>
          </Choose>
        </If>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    authStateKnown: authStateKnown(state),
    $$user: getCurrentUser(state),
    $$eventState: getEventState(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      dispatch(fetchEventState({
        eventId: props.eventId
      }));
    },
  };
}
// export default ;
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Orchestrator))
);
