import React, { Component } from "react";
// import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import Immutable from "immutable";
// import clsx from "clsx";
// import queryString from "query-string";
import getFetchOptions from "helpers/getFetchOptions";
// import Dialog from "components/UI/Dialog/Dialog";
import DialogConfirm from "components/UI/Dialog/DialogConfirm";

// import getCardImage from 'helpers/getCardImage'
// import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
// import remove from "mout/array/remove";
import config from "config/api";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
// import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
// import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Quiz from "components/UI/Quiz/Quiz";
// import AdminMessageGrid from "components/UI/Message/AdminMessageGrid";
// import AdminOrchestratorBlock from "./AdminOrchestratorBlock";
import Select from "components/UI/Form/Select";
import { withRouter } from "react-router-dom";

import {
  setQuizManagerOption,
  fetchQuizQuestions,
  getQuizQuestions,
  // getQuizManagerResults,
  clearQuizQuestions,
  clearQuizManagerResults,
  getQuizResults,
  resetQuizQuestion,
} from "components/UI/Quiz/ducks/quiz";
import {
  // isLoggedIn,
  // getCurrentUserRole,
  getCurrentUser,
  getToken,
} from "components/UI/Account/ducks/authentication";
import {
  sendMessage,
  // getContent,
  setEventState,
  getEventState,
  setAdminEventState,
  getAdminEventState,
  fetchEventState,
} from "components/UI/Socket/ducks/relay";
// qid: 'c6b5279e681b7866afcc94d5fef27a3a',
// import i18n from "helpers/i18n";
// const AF_KEY = "home";
const USE_WEBSOCKET = false;
const styles = (theme) => {
  // console.log(theme)
  // theme.palette.contrastThreshold = 0.1
  return {
    root: {
      minWidth: 500,
      "& .MuiDivider-vertical": {
        marginLeft: 10,
        marginRight: 10,
      },
      "& .MuiButton-root": {
        "& .MuiButton-label": {
          [theme.breakpoints.down("md")]: {},
        },
      },
    },
    popinPlaceholder: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.dialog && theme.palette.dialog.backgroundColor ? theme.palette.dialog.backgroundColor : undefined,
      color: theme.palette.dialog && theme.palette.dialog.color ? theme.palette.dialog.color : undefined,
    },
    leftColumn: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: theme.spacing(2),
      },
    },
    rightColumn: {
      [theme.breakpoints.down("md")]: {
        "& .MuiButton-root": {
          "& .MuiButton-label": {
            // padding: 0,
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        "& .MuiButton-root": {
          "& .MuiButton-label": {
            padding: 0,
            fontSize: "0.6rem",
          },
        },
      },
    },
    // onAir: {
    //   fontWeight: "bold",
    //   marginBottom: 40,
    //   border: "1px " + theme.palette.primary.main + " solid",
    //   display: "inline-block",
    //   padding: "5px 10px",
    // },
    paperWrapper: {
      paddingLeft: 20,
      paddingRight: 20,
      margin: "20px auto",
      maxWidth: 1200,
      width: "100%",
      padding: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
    // questionWrapper: {
    //   marginBottom: theme.spacing(2),
    // },
    // question: {
    //   boxSizing: "inherit",
    //   cursor: "pointer",
    //   "& .MuiTypography-subtitle1": {
    //     fontSize: "1.2rem",
    //     lineHeight: 1.85,
    //     color: "inherit",
    //     textAlign: "right",
    //   },
    //   "& .MuiTypography-subtitle2": {
    //     fontSize: "1.2rem",
    //     lineHeight: 1.85,
    //     color: "inherit",
    //   },
    //   border: "1px " + theme.palette.secondary.light + " solid",
    //   width: "100%",
    //   height: "100%",
    //   display: "flex",
    //   alignItems: "center",
    //   "& .MuiTypography-body1": {
    //     color: "inherit",
    //   },
    // },
    // selected: {
    //   cursor: "default",
    //   backgroundColor: theme.palette.secondary.light,
    //   color: theme.palette.secondary.contrastText,
    //   "& .MuiLinearProgress-colorPrimary": {
    //     backgroundColor: theme.palette.secondary.light,
    //   },
    //   "& .MuiLinearProgress-barColorPrimary": {
    //     backgroundColor: theme.palette.getContrastText(
    //       theme.palette.secondary.light
    //     ),
    //   },
    // },
    // correct: {
    //   backgroundColor: theme.palette.success.dark,
    //   color: theme.palette.getContrastText(theme.palette.success.dark),
    //   '& .MuiLinearProgress-colorPrimary': {
    //     backgroundColor: theme.palette.success.light
    //   },
    //   '& .MuiLinearProgress-barColorPrimary': {
    //     backgroundColor: theme.palette.getContrastText(theme.palette.success.dark)
    //   }
    // },
    // incorrect: {
    //   backgroundColor: theme.palette.error.dark,
    //   color: theme.palette.getContrastText(theme.palette.error.dark),
    //   '& .MuiLinearProgress-colorPrimary': {
    //     backgroundColor: theme.palette.error.light
    //   },
    //   '& .MuiLinearProgress-barColorPrimary': {
    //     backgroundColor: theme.palette.getContrastText(theme.palette.error.dark)
    //   }
    // },
    actions: {
      minHeight: 54,
    },
    log: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    // percentage: {
    //   fontSize: '1.6rem'
    // }
  };
};
class AdminOrchestratorQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: [],
      answered: undefined,
      logMessage: false,
      errored: false,
      // activeTab: "messages",
      dialogDeleteState: undefined,
    };
  }
  componentDidMount() {
    const {
      // eventId,
      match,
    } = this.props;
    const { eventId } = match.params;
    this.props.onMount({
      eventId,
    });
    if (this.props.$$eventState.get("contentType") === 'quiz') {
      let newState = {
        questionActive: this.props.$$eventState.get("contentId"),
      };
      // console.log('GOGOGO')
      this.setState(newState);
    }

    if (
      this.props.$$quizQuestions !== undefined &&
      this.props.$$quizQuestions.get("rows").size > 0
    ) {
      this.setState({
        questionSelected: this.props.$$quizQuestions
        .get("rows")
        .get(0)
        .get("id"),
      });
    }

    // const $$initValues = uri.from && uri.from === 'rpps' ? Immutable.fromJS({
  }
  componentDidUpdate(oldProps, oldState) {
    // Keep
    if (
      oldProps.$$quizQuestions === undefined &&
      this.props.$$quizQuestions !== undefined &&
      this.props.$$quizQuestions.get("rows").size > 0
    ) {
      // console.log(
      //   "test forcing question to",
      //   this.props.$$quizQuestions.get("rows").get(0).toJS()
      // );
      this.setState({
        questionSelected: this.props.$$quizQuestions
        .get("rows")
        .get(0)
        .get("id"),
      });
    }
    if (oldProps.$$eventState !== this.props.$$eventState) {
      // const { $$eventState, sendMessage } = this.props;
      // const { questionActive, questionSelected } = this.state;
      if (this.props.$$eventState.get("contentType") === 'quiz') {
        let newState = {
          questionActive: this.props.$$eventState.get("contentId"),
        };
        this.setState(newState);
      }
    }
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }
  _onContentActivate = (questionId) => {
    const { questionActive } = this.state;
    const {
      match,
      setEventState,
    } = this.props;
    const { eventId } = match.params;
    if (questionId !== questionActive) {
      const $$content = this._getItemQuestion(questionId);
      // console.log('DEBUG', $$content.toJS())
      this.setState({
        questionActive: $$content.get("id")
      }, () => {
        setEventState({
          eventId,
          contentId: $$content.get("id"),
          // contentType: $$content.get("quizType"),
          contentType: 'quiz',
        });
      });
    } else {
      // const $$content = this._getQuestion(questionId);
      this.setState({
        questionActive: false
      }, () => {
        setEventState({
          eventId,
          contentId: "none",
          contentType: "none",
        });

      })
    }
  };
  _onQuestionSelect = (questionId) => {
    // const {} = this.props;
    const { questionSelected } = this.state;
    if (questionId !== questionSelected)
    this.setState({
      questionSelected: questionId,
    });
  };
  _onQuizShowResults = (questionId) => {
    // const { clearQuizManagerResults, $$results } = this.props;
    const { $$results } = this.props;
    const questionShowResults =
    $$results !== null && $$results !== undefined && $$results !== false;
    const {
      match,
      // eventId,
      // $$content,
      setQuizManagerOption,
    } = this.props;
    const { eventId } = match.params;
    const { questionActive } = this.state;
    if (questionShowResults === undefined || questionShowResults === false) {
      // this.setState({
      //   questionShowResults: true
      // }, () => {
      setQuizManagerOption({
        eventId,
        questionId: questionActive,
        name: "showResults",
        value: true,
      });
      // })
    } else {
      this.setState(
        {
          questionShowResults: undefined,
        },
        () => {
          setQuizManagerOption({
            eventId,
            questionId: questionActive,
            name: "showResults",
            value: false,
          });
        }
      );
    }
  };

  _onQuizDeleteConfirmed = async ($$item) => {
    const url = config.quiz + "/delete";
    const token = await getToken();
    const data = { id: $$item.get("id"), csrf: token };
    fetch(url, getFetchOptions("POST", data))
    .then((response) => response.json())
    .then((response) => {
      if (response.deleted && response.deleted === "complete") {
        const { eventId, onMount } = this.props;
        this.setState({
          dialogDeleteState: undefined,
          questionSelected: undefined,
        });
        onMount({ eventId });
        // this.setState({redirectToList: true, locked: false});
      }
    });
  };
  _onQuizDeleteCanceled = ($$item) => {
    this.setState({ dialogDeleteState: undefined });
  };
  _onQuizDelete = () => {
    const { questionSelected } = this.state;
    // console.log({'delete': questionSelected})
    this.setState({
      dialogDeleteState: Immutable.fromJS({ id: questionSelected }),
    });
  };

  _onQuizResetConfirmed = ($$item) => {
    const {
      // eventId,
      match,
      resetQuizQuestion,
    } = this.props;
    const { eventId } = match.params;
    resetQuizQuestion({
      eventId,
      questionId: $$item.get("id"),
    });
    this._onQuizResetCanceled();
  };
  _onQuizResetCanceled = ($$item) => {
    this.setState({ dialogResetState: undefined });
  };
  _onQuizReset = () => {
    const { questionSelected } = this.state;
    this.setState({
      dialogResetState: Immutable.fromJS({ id: questionSelected, kind: 'quiz' }),
    });
  };

  _getItemQuestion = (questionId) => {
    const { $$quizQuestions } = this.props;
    return $$quizQuestions
    .get("rows")
    .filter(($$q) => $$q.get("id") === questionId)
    .get(0);
  }

  render() {
    const {
      $$user,
      classes,
      $$quizQuestions,
      match,
      $$results,
      $$eventState,
      history,
    } = this.props;
    const { eventId } = match.params;
    const {
      // errored,
      // choice,
      // showResults,
      questionSelected,
      questionActive,
      // questionShowResults,
      // activeTab,
      dialogDeleteState,
      dialogResetState,
    } = this.state;
    // const gridQuestionsSize = 4;
    // const $$event = Immutable.fromJS({
      // id: eventId,
    // });
    // console.log({questionShowResults})
    // console.log({$$results})
    // console.log({
    //   questionActive
    // })
    const questionTypes = { poll: "Sondage", quiz: "Question" };
    // if ($$user !== undefined)
    const userRole = $$user !== undefined ? $$user.get("role") : "visitor";
    // const userSubRole =
    // $$user !== undefined ? $$user.get("subRole") : undefined;

    const isAdmin = userRole === "admin";

    let $$selectorOptions = Immutable.fromJS([]);

    if ($$quizQuestions !== undefined)
    $$selectorOptions = $$quizQuestions.get("rows").map(($$r) =>
    Immutable.fromJS({
      label:
      questionTypes[$$r.get("quizType")] +
      " N°" +
      $$r.get("quizPosition") +
      " : " +
      $$r.get("quizQuestion"),
      value: $$r.get("id"),
    })
  )

  return (<Grid xs={12} item container>
    <Paper className={classes.paperWrapper}>
      <Box m={3} align="center" display="block">
        <Typography variant="h3" gutterBottom>
          Quiz
        </Typography>

        <Grid container style={{ width: "100%", marginBottom: 30 }}>
          <Grid item xs={12} align="center">
            <FormGroup row={true}>
              <Typography variant="subtitle1">
                Selectionnez une question afin de la visualiser
              </Typography>

              <Select
                style={{ width: "100%" }}
                variant="outlined"
                fullWidth={false}
                name="questionSelector"
                id="questionSelected"
                value={questionSelected}
                onFocus={(e) => {
                  this.props.refreshQuestions({ eventId });
                }}
                onChange={(k, o) => {
                  this._onQuestionSelect(o.value);
                }}
                options={$$selectorOptions}
                makeItems={({ options, selectValue, multiple }) => {
                  return options
                  .map((opt, idx) => {
                    return (
                      <MenuItem
                        key={"o_" + idx}
                        value={opt.get("value")}
                        >
                        <Typography
                          variant={"body1"}
                          display="inline"
                          >
                          {opt.get("label")}
                        </Typography>
                        <If
                          condition={
                            opt.get("value") === questionActive
                          }
                          >
                          <Typography
                            variant={"body1"}
                            color="primary"
                            display="inline"
                            >
                            &nbsp;Activée !
                          </Typography>
                        </If>
                      </MenuItem>
                    );
                  })
                  .toArray();
                }}
                />
            </FormGroup>
          </Grid>
        </Grid>
        <If condition={questionSelected === undefined}>
          <If condition={isAdmin === true}>
            <Grid item xs={12} container alignContent={"center"}>
              <Grid
                item
                sm={12}
                md={4}
                container
                justifyContent={"flex-start"}
                >
                <Button
                  color="secondary"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/staff/quiz/" + eventId + "/edit");
                  }}
                  >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </If>
        </If>
        <If condition={questionSelected !== undefined}>
          <Grid item xs={12} container alignContent={"center"}>
            <Grid
              item
              sm={12}
              md={4}
              container
              justifyContent={"flex-start"}
              className={classes.leftColumn}
              >
              <If
                condition={
                  questionActive === questionSelected &&
                  questionSelected !== "none" && $$eventState.get('contentType') === 'quiz'
                }
                >
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    this._onContentActivate(questionSelected);
                  }}
                  >
                  Désactiver
                </Button>
              </If>
              <If
                condition={
                  (questionActive !== questionSelected &&
                    questionSelected !== "none") || $$eventState.get('contentType') !== 'quiz'
                  }
                  >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      this._onContentActivate(questionSelected);
                    }}
                    >
                    Activer
                  </Button>
                </If>
                <If condition={isAdmin === true}>
                  <Divider orientation="vertical" />
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/staff/quiz/" + eventId + "/edit");
                    }}
                    >
                    Ajouter
                  </Button>
                </If>
              </Grid>
              <Grid
                item
                sm={12}
                md={8}
                container
                justifyContent={"flex-end"}
                className={classes.rightColumn}
                >
                <If
                  condition={
                    isAdmin === true && questionSelected !== "none"
                  }
                  >
                  <Button
                    color="secondary"
                    size="small"
                    disabled={questionSelected === questionActive}

                    onClick={(e) => {
                      e.preventDefault();
                      this._onQuizReset();
                    }}
                    >
                    Remise à zéro
                  </Button>
                  <If condition={questionSelected !== questionActive}>
                  <Divider orientation="vertical" />
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      this._onQuizDelete();
                    }}
                    >
                    Supprimer
                  </Button>
                  <Divider orientation="vertical" />
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        "/staff/quiz/" +
                        eventId +
                        "/edit/" +
                        questionSelected
                      );
                    }}
                    >
                    Editer
                  </Button>
                  </If>
                </If>
                <If
                  condition={
                    questionSelected === questionActive &&
                    questionActive !== "none"
                  }
                  >
                  <Divider orientation="vertical" />
                  <Button
                    color="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      this._onQuizShowResults(questionActive);
                    }}
                    disabled={
                      questionActive === false ||
                      questionActive === undefined
                    }
                    >
                    {($$results !== undefined &&
                      $$results !== false &&
                      $$results !== null
                      ? "Masquer"
                      : "Afficher") + " Les Résultats"}
                    </Button>
                  </If>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 30 }}>
                  <Box className={classes.popinPlaceholder}>
                    <Quiz
                      eventId={eventId}
                      questionId={questionSelected}
                      embed={true}
                      revision={$$eventState.get("contentRevision")}
                      pull={USE_WEBSOCKET === false}
                      />
                  </Box>
                </Grid>
              </Grid>
            </If>
          </Box>
        </Paper>
        <If
          condition={
            dialogDeleteState !== undefined &&
            dialogDeleteState !== false &&
            dialogDeleteState !== null
          }
          >
          <DialogConfirm
            open={true}
            title="Confirmation de suppression"
            onConfirmed={(e) => {
              if (e) e.preventDefault();
              this._onQuizDeleteConfirmed(dialogDeleteState);
            }}
            onCanceled={this._onQuizDeleteCanceled}
            >
            <Typography align="center" gutterBottom>
              Souhaitez vous supprimer cette question ?
            </Typography>
          </DialogConfirm>
        </If>
        <If
          condition={
            dialogResetState !== undefined &&
            dialogResetState !== false &&
            dialogResetState !== null
          }
          >
          <DialogConfirm
            open={true}
            title="Confirmation de remize à zéro"
            onConfirmed={() => {
              if (dialogResetState.get('kind') === 'quiz')
              this._onQuizResetConfirmed(dialogResetState);
              else if (dialogResetState.get('kind') === 'feedback')
              this._onFeedbackResetConfirmed(dialogResetState);
            }}
            onCanceled={() => {
              if (dialogResetState.get('kind') === 'quiz')
              this._onQuizResetCanceled()
              else if (dialogResetState.get('kind') === 'feedback')
              this._onFeedbackResetCanceled()
            }
          }
          >
          <If condition={dialogResetState.get('kind') === 'quiz'}>
            <Typography align="center" gutterBottom>
              Souhaitez vous remettre à zéro cette question ?<br /> Cela
              effacera toutes les réponses des participants.
            </Typography>
          </If>
          <If condition={dialogResetState.get('kind') === 'feedback'}>
            <Typography align="center" gutterBottom>
              Souhaitez vous remettre à zéro le feedback ?<br /> Cela
              effacera toutes les réponses des participants.
            </Typography>
          </If>
        </DialogConfirm>
      </If>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    // isLoggedIn: isLoggedIn(state),
    $$user: getCurrentUser(state),
    $$quizQuestions: getQuizQuestions(state),
    // $$announceQuestions: getAnnouncesData(state),
    // $$content: getContent(state),
    // $$managerResults: getQuizManagerResults(state),
    $$results: getQuizResults(state),
    $$eventState: getEventState(state),
    $$adminEventState: getAdminEventState(state),
    // feedbackReset: getFeedbackReset(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      if (props.eventId !== undefined) {
        dispatch(fetchQuizQuestions(props));
        dispatch(fetchEventState(props));
      }
    },
    onUnmount: () => {
      dispatch(clearQuizQuestions());
    },
    refreshQuestions: (props) => {
      if (props.eventId !== undefined) {
        dispatch(fetchQuizQuestions(props));
      }
    },
    sendMessage: (payload) => {
      dispatch(sendMessage(payload));
    },
    setQuizManagerOption: (props) => {
      if (props.eventId !== undefined) {
        dispatch(setQuizManagerOption(props));
      }
    },
    clearQuizManagerResults: () => {
      dispatch(clearQuizManagerResults());
    },
    resetQuizQuestion: (props) => {
      dispatch(resetQuizQuestion(props));
    },
    setEventState: (props) => {
      dispatch(setEventState(props));
    },
    setAdminEventState: (props) => {
      dispatch(setAdminEventState(props));
    },
  };
}
// export default ;
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AdminOrchestratorQuiz))
);
