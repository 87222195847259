import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import BlockButton from 'components/UI/BlockButton';
import config from 'config/api';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconArrow from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import 'react-sticky-header/styles.css';
import HeaderMenu from './HeaderMenu';
import i18n from 'helpers/i18n';
import { fetchData } from '../../ducks/asyncFetcher';
import PersonIcon from '@material-ui/icons/Person';
import {
  isLoggedIn,
  getCurrentUser,
  getPendingAccounts,
  setUniverse,
  getUniverse,
} from 'components/UI/Account/ducks/authentication';
import { getOfferPending, fetchOfferPending } from 'components/UI/Offers/ducks/offer';
//import RGPDBanner from 'components/UI/RGPDBanner/RGPDBanner';
import OfferPending from 'components/UI/Offers/OfferPending';

const AF_KEY = 'home';

const styles = (theme) => {
  return {
    root: {
      position: 'relative',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: '#fff',
      '& .MuiButton-root.right': {
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
      '& .MuiMenuItem-root': {
        paddingRight: '27px',
      },
      '& .MuiMenuItem-root .MuiBadge-anchorOriginTopRightRectangle': {
        top: '12px',
        right: '-14px',
      },
    },
    wrapper: {
      // maxWidth: 1280,
      justifySelf: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logo: {
      height: '100%',
      // width: 200,
      width: 300,
      cursor: 'pointer',
      backgroundImage: `url(${require('assets/images/logo.png')})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      margin: '0 auto 0 0',
      display: 'block',
      backgroundPosition: '50% 50%',
      [theme.breakpoints.down('sm')]: {
        width: 140,
        margin: '0 auto 16px',
      },
    },
    logoVariant1: {
      cursor: 'pointer',
      backgroundColor: '#fff',
      height: '70px',
      marginTop: '0',
      marginLeft: '0',
      backgroundPosition: '50% 50%',
      // backgroundImage: `url(${require('assets/images/logo2.png')})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },

    profileWrapper: {
      padding: 0,
      marginTop: '-40px',
    },
    profileMenu: {
      boxShadow: 'none',
      '& .MuiButton-label': {},
    },
    buttonMyAccount: {
      marginLeft: 10,
      [theme.breakpoints.down('sm')]: {},
      marginTop: 0,
      '& .MuiButton-label': {},
      [theme.breakpoints.down('sm')]: {
        marginTop: 5,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    headerMenu: {
      textAlign: 'right',
      // borderBottom: '1px solid ' + (menuColor || '#fff'),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        padding: 0,
        marginTop: 0,
        border: 'none',
      },
    },
    headerLogo: {
      height: 80,
    },
    headerContent: {
      textAlign: 'center',
      maxWidth: '100%',
      padding: 10,
      marginTop: theme.spacing(4),
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '90%',
      },
      '& img': {
        [theme.breakpoints.down('xs')]: {
          maxWidth: 'normal',
          margin: '-40px auto -30px',
          width: 214,
          height: 134,
        },
      },
    },
    headerTitle: {
      width: '100%',
      maxWidth: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      top: '50%',
      '& .MuiTypography-h1': {
        height: '100px',
        width: '100%',
        color: '#fff',
        fontSize: '33px',
        letterSpacing: '0',
        lineHeight: '50px',
        textAlign: 'center',
      },
    },
  };
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLogginIn: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this._gotoHome = this._gotoHome.bind(this);
    this._setIsLogginIn = this._setIsLogginIn.bind(this);
    this._onFormComplete = this._onFormComplete.bind(this);
  }
  _gotoHome() {
    this.props.setUniverse(undefined);
    this.props.history.push('/');
  }

  handleToggle = (e, url) => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleClose = (event, url) => {
    this.setState({ isLogginIn: false });

    if (this.anchorEl.contains(event.target)) {
      return;
    }

    const { history } = this.props;
    this.setState({ open: false }, function () {
      // console.log('k', url)
      history.push(url);
    });
  };
  _setIsLogginIn = () => {
    this.setState({ isLogginIn: true });
  };
  _onFormComplete = () => {
    const { onLoggedIn } = this.props;
    console.log('_onFormComplete utilisé !!!!!!!!!!!!!!!!!!!!!!!!');
    this.setState({ isLogginIn: false }, () => {
      onLoggedIn();
    });
  };
  render() {
    const {
      current,
      headerImage,
      headerTitle,
      headerContent,
      isLoggedIn,
      $$user,
      classes,
      headerStyle,
      headerClass,
      pendingAccounts,
      pendingOffers,
      style,
      universe,
    } = this.props;
    const { open, isLogginIn } = this.state;
    // console.log({isLogginIn})
    const menuSize = ['sm', 'xs'].indexOf(this.props.width) < 0 ? 40 : 28;

    const headerType = headerImage || void 0;

    let finalHeaderStyle = { ...style };

    if (headerType) {
      finalHeaderStyle.backgroundPosition = '0 25%';
      finalHeaderStyle.backgroundSize = 'cover';
      finalHeaderStyle.backgroundRepeat = 'no-repeat';
    }

    const $$menu = config.getMenu($$user, { asImmutable: true, universe });

    const $$profileMenu = config.getProfileMenu($$user, { asImmutable: true });

    const userRole = $$user !== undefined ? $$user.get('role') : undefined;
    const userId = $$user !== undefined ? $$user.get('id') : null;
    // const userSubRole = $$user !== undefined ? $$user.get('subRole') : undefined;
    // const logoMargin = ['sm', 'xs'].indexOf(this.props.width) < 0 ? '0 0 0 auto' : '30px auto 10px';
    // console.log({isLogginIn, userRole, userSubRole, $$user})
    return (
      <Grid item xs={12} className={classes.root} style={finalHeaderStyle}>
        <OfferPending key={userId} />
        <header id="header" className={clsx(classes.header, headerClass)} style={headerStyle}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="space-around"
            className={classes.wrapper}
          >
            <Grid item xs={12} md={4} className={classes.headerLogo}>
              <div className={classes.logo} onClick={this._gotoHome}></div>
            </Grid>
            <Grid item xs={12} md={8} className={classes.headerMenu}>
              <HeaderMenu
                menuSize={menuSize}
                style={{ whiteSpace: 'nowrap' }}
                current={current}
                userRole={userRole}
                isLoggedIn={isLoggedIn}
                $$menu={$$menu}
                universe={universe}
              />
              <If condition={isLogginIn || !isLoggedIn}>
                <Button
                  id="header_connexion"
                  color="primary"
                  variant="contained"
                  className={classes.buttonMyAccount}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/login?action=login');
                  }}
                >
                  <PersonIcon />
                  {i18n.get('loginAction')}
                </Button>
              </If>
              <If
                condition={
                  isLogginIn !== true &&
                  (userRole === 'member' ||
                    userRole === 'admin' ||
                    userRole === 'speaker' ||
                    userRole === 'staff')
                }
              >
                <Button
                  onClick={this.handleToggle}
                  endIcon={<IconArrow />}
                  ref={(node) => {
                    this.anchorEl = node;
                  }}
                  aria-owns={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  style={{ visibility: open === true ? 'hidden' : 'visible' }}
                  className={classes.buttonMyAccount}
                >
                  <If condition={pendingAccounts > 0 || pendingOffers > 0}>
                    <Badge color="error" variant="dot">
                      {$$user.get('email')}
                    </Badge>
                    <Else />
                    {$$user.get('email')}
                  </If>
                </Button>
              </If>

              <Popper
                open={open}
                anchorEl={this.anchorEl}
                transition
                disablePortal
                style={{ zIndex: 999 }}
                placement={'bottom-start'}
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps} id="menu-list-grow">
                    <Paper classes={{ root: classes.profileWrapper }}>
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <Box className={classes.padding}>
                          <BlockButton
                            className={classes.profileMenu}
                            lowerCase={true}
                            label={
                              <span>
                                MON COMPTE
                                <IconArrow
                                  style={{
                                    verticalAlign: 'middle',
                                    marginTop: '-2px',
                                  }}
                                />
                              </span>
                            }
                            size="small"
                            variant="regular"
                            onClick={this.handleToggle}
                            aria-owns={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            style={{ borderRadius: '0' }}
                          />
                          <MenuList>
                            {$$profileMenu
                              .map((p, pIdx) => {
                                if (p.get('target') === '_blank') {
                                  return (
                                    <MenuItem
                                      key={'pm_' + pIdx}
                                      onClick={(e) => {
                                        window.open(p.get('url'));
                                      }}
                                      classes={{
                                        root: classes.profileButton,
                                      }}
                                    >
                                      {i18n.get(p.get('title'), { pretty: false })}
                                    </MenuItem>
                                  );
                                } else {
                                  return (
                                    <MenuItem
                                      key={'pm_' + pIdx}
                                      onClick={(e) => {
                                        this.handleClose(e, p.get('url'));
                                      }}
                                      classes={{
                                        root: classes.profileButton,
                                      }}
                                    >
                                      <Choose>
                                        <When
                                          condition={p.get('title') === 'navAccounts' && pendingAccounts > 0}
                                        >
                                          <Badge color="error" badgeContent={pendingAccounts}>
                                            {i18n.get(p.get('title'), { pretty: false })}
                                          </Badge>
                                        </When>
                                        <When
                                          condition={p.get('title') === 'navAdminOffers' && pendingOffers > 0}
                                        >
                                          <Badge color="error" badgeContent={pendingOffers}>
                                            {i18n.get(p.get('title'), { pretty: false })}
                                          </Badge>
                                        </When>
                                        <Otherwise>{i18n.get(p.get('title'), { pretty: false })}</Otherwise>
                                      </Choose>
                                    </MenuItem>
                                  );
                                }
                              })
                              .toArray()}
                          </MenuList>
                        </Box>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
          <If condition={headerTitle !== undefined}>
            <Grid item xs={12} className={classes.headerTitle}>
              <Typography variant="h1">{headerTitle}</Typography>
            </Grid>
          </If>
          <If condition={headerContent !== undefined}>
            <Box className={classes.headerContent}>{headerContent}</Box>
          </If>
        </header>
        {/*<RGPDBanner key="rpgd" />*/}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
    // userRole: getCurrentUserRole(state),
    // userSubRole: getCurrentUserSubRole(state),
    $$user: getCurrentUser(state),
    universe: getUniverse(state),
    pendingAccounts: getPendingAccounts(state),
    pendingOffers: getOfferPending(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onLoggedIn: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
    setUniverse: (universe) => {
      dispatch(setUniverse(universe));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header)));
