import React, {
  Component
} from "react";
import config from "config/api";
import DynamicForm from "components/UI/DynamicForm";
import DynamicField from "./DynamicField";
import Immutable from 'immutable';
class FaqForm extends Component {
  _getI18n() {
    this.i18n = {
      send: "Enregistrer",
      sending: "Enregistrement...",
    };
  }
  _onJobChanged(fid, value) {
    switch (value) {
      case "intern":
        this.setState({
          activeCategories: ["isIntern"]
        });
        break;
      case "md":
      case "ph":
        this.setState({
          activeCategories: ["isMd"]
        });
        break;
      default:
        break;
    }
  }
  render() {
    const {
      onFormComplete,
      eventId
    } = this.props;
    const moduleConfig = {
      module: "quiz",
      fetchUrl: config.quiz + "/edit",
      postUrl: config.quiz + "/edit",
      formTitle: this.props.id !== undefined ? "Edition" : "Nouvel élément de quiz",
      // redirectAfterEdit: userGroup != 50 ? '/help' : undefined,
      // afterEdit: userGroup == 50 ? 'log' : undefined,
      // afterEditLog: userGroup == 50 ? 'Une invitation a été envoyée par Email, ce nouveau profil sera disponible dès son activation par Prodeezi' : undefined,
      // afterEditHideForm: userGroup == 50 ? true : undefined
      redirectAfterEdit: false,
      afterEdit: "onFormComplete",
      afterEditLog: "",
      afterEditHideForm: true,
      onFormComplete,
      DynamicField: DynamicField
      // onCancel: onFormComplete
      // onCancel: onClose
    };
    return <DynamicForm moduleConfig={moduleConfig} extraFields={Immutable.fromJS({quizParent: eventId})} {...this.props} />;
  }
}
export default FaqForm;