import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import { connect } from 'react-redux';

import { getUniverse } from 'components/UI/Account/ducks/authentication';

// import {
//   light,
//   grey,
//   dark,
//   disabledColor,
//   backgroundColor,
//   primaryColor,
//   primaryColorLight,
//   secondaryColor,
//   tertiaryColor,
//   bodyColor,
//   titleColor,
// } from 'helpers/colors';

const Theme = class Theme extends React.Component {
  render() {
    let mainColorPrimary, mainColorSecondary, mainColorPrimaryDark, mainColorSecondaryDark, mainColorHeader;

    let buttonTextColorPrimary='#fff';
    let TextOnBackgroundColor='#fff';
    const universe = this.props.universe;
    if (universe === undefined) {
      mainColorPrimary = '#0A569A';
      mainColorSecondary = '#0D4678';
      mainColorPrimaryDark = '#033B4C';
      mainColorSecondaryDark = '#0A569A';
    } else if (universe === 'gastro') {
      mainColorPrimary = '#0A569A';
      mainColorSecondary = '#3EA5FF';
      mainColorPrimaryDark = '#033B4C';
      mainColorSecondaryDark = '#0A569A';
    } else if (universe === 'gyneco') {
      mainColorPrimary = '#f3b7cc';//rose clair
      mainColorSecondary = '#f3b7cc';
      mainColorPrimaryDark = '#D1829E';//rose foncé
      mainColorSecondaryDark = '#D1829E';
      mainColorHeader = '#444';
      buttonTextColorPrimary='#000';
      TextOnBackgroundColor='#000';
    } else if (universe === 'offers') {
      mainColorPrimary = '#0A569A';
      mainColorSecondary = '#3EA5FF';
      mainColorPrimaryDark = '#033B4C';
      mainColorSecondaryDark = '#0A569A';
    }
    const palette = {
      type: 'light',
      primary: {
        main: mainColorPrimary, // bleu
        // light: '#6fcaf2', //bleu light
        light: '#6C207E', //bleu light
        dark: mainColorPrimaryDark,
      },
      secondary: {
        main: mainColorSecondary, // rouge
        dark: mainColorSecondaryDark, // rouge
        light: mainColorSecondary,
        contrastText: '#fff',
      },
      tertiary: {
        main: '#87c7a6',
      },
      buttonTextColorPrimary:buttonTextColorPrimary,
      text: {
        primary: '#033B4C',
        light: '#eee',
        onColoredBackground:TextOnBackgroundColor,
        // secondary: secondaryColor,
        // light,
        // dark,
      },
      action: {
        // disabled: disabledColor,
      },
      background: {
        // default: '#F2F2F2',
        default: '#fff',
        // input: primaryColor,
        // paper: '#fff',
      },
      header: {
        buttonColor: mainColorHeader!==undefined ? mainColorHeader : mainColorSecondaryDark,
        buttonBackgroundColor: 'transparent',
        buttonBorder: '',
        buttoBorderBottom: '4px transparent solid',
        buttoHoverColor: mainColorPrimaryDark,
        // buttonHoverBorderBottom: '4px #E37EB3 solid',
        buttonSelectedColor: mainColorPrimaryDark,
        buttonSelectedBackgroundColor: 'transparent',
        buttonSelectedBeforeContent: "' '",
        buttonSelectedBorderBottom: '4px ' + mainColorPrimaryDark + ' solid',
        buttonSelectedFontWeight: 'bold',
        buttonFontWeight: 'regular',
      },
      faq: {
        backgroundColor: undefined,
        bulletColor: '#6C207E',
        itemColor: '#0087AF',
        itemBackgroundColor: '#fff',
        itemShadow: '1px 1px 6px #666',
        textColor: '#0087AF',
        answerColor: '#0087AF',
      },
      speedTest: {
        backgroundColor: 'transparent',
        color: '#fff',
        buttonColor: '#fff',
        buttonBackgroundColor: mainColorSecondaryDark,
      },
      footer: {
        color: '#033B4C',
        linkColor: '#033B4C',
        background: '#fff',
      },
      news: {
        backgroundColor: '#F4F4F4',
        color: '#212121',
      },
      replays: {
        breadCrumbColor: '#fff',
        backgroundColor: '#6C207E',
      },
      live: {
        breadCrumbColor: '#fff',
        backgroundColor: mainColorSecondary,
      },
      template: {
        color: '#212121',
      },
      registration: {
        titleColor: '#212121',
      },
      rgpd: {
        backgroundColor: mainColorSecondaryDark,
        color: '#fff',
      },
      offer: {
        main: mainColorPrimary,
        light: '#fff',
        dark: mainColorPrimaryDark,
      },
    };

    const { children } = this.props;
    const theme = createTheme({
      palette,
      typography: {
        // fontFamily: ['Aleo, Arial, sans-serif'].join(','),
        fontFamily: ['Montserrat, sans-serif'].join(','),
        useNextVariants: true,
        h1: {
          fontSize: '3rem',
          marginTop: 8 * 4,
          marginBottom: 8 * 12,
          textAlign: 'center',
          // color: palette.primary.dark,
          color: '#fff',
          fontWeight: 'bold',
        },
        h2: {
          fontSize: '2.1875rem',
          '& a': {
            color: 'inherit',
          },
        },
        h3: {
          fontSize: '2rem',
          marginBottom: 24,
        },
        h5: {
          fontSize: '1.375rem',
          color: palette.secondary.dark,
          display: 'block',
          lineHeight: '2.1875rem',
          '& .MuiSvgIcon-root': {
            borderColor: palette.secondary.dark,
            //   marginTop: -6,
            //   marginRight: 8,
            //   marginBottom: 8
          },
        },
        h6: {
          color: palette.secondary.main,
          fontWeight: 'bold',
        },
        body1: {
          fontSize: '0.9375rem',
          lineHeight: '1.4375rem',
        },
        caption: {
          lineHeight: '1.4375rem',
          color: '#898089',
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      overrides: {
        PrivateTabIndicator: {
          colorSecondary: {
            display: 'none',
          },
        },
        MuiSvgIcon: {
          root: {
            display: 'inline-block',
            verticalAlign: 'middle',
            marginTop: -3,
            marginRight: 4,
          },
        },
        MuiBadge: {
          colorSecondary: {
            backgroundColor: palette.primary.main,
          },
        },
        MuiAvatarGroup: {
          avatar: {
            color: palette.secondary.light,
            border: '2px ' + palette.secondary.light + ' solid',
            backgroundColor: '#fff',
          },
        },
        MuiAvatar: {
          root: {
            border: '2px ' + palette.secondary.light + ' solid',
          },
        },
        MuiInputLabel: {
          root: {
            fontWeight: 'bold',
            color: palette.text.primary,
          },
        },
        MuiButton: {
          root: {
            fontSize: '0.875rem',
            textTransform: 'none',
            borderRadius: 20,
            '& .MuiSvgIcon-root': {
              display: 'inline-block',
              verticalAlign: 'middle',
              marginTop: -2,
              marginRight: 5,
            },
            '&.MuiButton-containedPrimary': {
              boxShadow: 'none',
              color: buttonTextColorPrimary,
              backgroundColor: palette.primary.main,
              border: '1px ' + palette.primary.main + ' solid',
              borderRadius: '20px',
            },
            '&.MuiButton-containedPrimary:hover': {
              boxShadow: 'none',
              backgroundColor: '#fff',
              color: palette.primary.dark,
            },
            '&.MuiButton-containedSecondary': {
              boxShadow: 'none',
              color: palette.secondary.dark,
              backgroundColor: '#fff',
              border: '1px ' + palette.secondary.main + ' solid',
            },
            '&.MuiButton-containedSecondary:hover': {
              boxShadow: 'none',
              backgroundColor: palette.secondary.main,
              color: '#fff',
            },
            '&.MuiButton-outlinedSecondary': {
              color: palette.secondary.dark,
              border: '1px ' + palette.secondary.dark + ' solid',
            },
            '&$disabled': {
              color: '#fff',
            },
          },
          contained: {
            '&$disabled': {
              color: 'original',
              backgroundColor: 'original',
              opacity: 0.4,
            },
          },
        },
        // MuiFormLabel: {
        //   root: {
        //     // color: '#fff',
        //     '&.Mui-focused': {
        //       // color: primaryColor,
        //     },
        //   },
        // },
        // MuiFormHelperText: {
        //   root: {
        //     color: primaryColor,
        //   },
        // },
        // 'MuiStepper-root': {
        //   padding: 0,
        // },
        // MuiStep: {
        //   root: {
        //     '& > .MuiStepConnector-root': {
        //       display: 'none',
        //     },
        //   },
        // },
        // MuiMenuItem: {
        //   root: {
        //     // color: '#fff',
        //   },
        // },
        // MuiAlert: {
        //   standardSuccess: {
        //     // color: '#fff',
        //     fontWeight: 'bold'
        //   }
        // }
      },
    });

    // theme.overrides.MuiButton.root.boxShadow = theme.shadows[2];
    // theme.typography.h1 = {
    //   ...theme.typography.h1,
    //   marginTop: theme.spacing(2),
    //   marginBottom: theme.spacing(5),
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2),
    //   // boxShadow: theme.shadows[3],
    //   [theme.breakpoints.down('md')]: {
    //     fontSize: '1.4rem',
    //   },
    //   [theme.breakpoints.down('sm')]: {
    //     fontSize: '1.3rem',
    //   },
    //   [theme.breakpoints.down('xs')]: {
    //     fontSize: '1.1rem',
    //   },
    // [theme.breakpoints.up('xs')]: {
    //     fontSize: '2.4rem',
    //   },
    //   [theme.breakpoints.up('sm')]: {
    //     fontSize: '3.5rem',
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     fontSize: '4rem',
    //   },
    //   [theme.breakpoints.up('lg')]: {
    //     fontSize: '5rem',
    //   },
    //   [theme.breakpoints.up('xl')]: {
    //     fontSize: '5.5rem',
    //   },
    // };

    // theme.typography.h2 = {
    //   ...theme.typography.h2,
    //   // [theme.breakpoints.up('xs')]: {
    //   //   fontSize: '1.5rem',
    //   //   textAlign: 'center',
    //   // },
    //   // [theme.breakpoints.up('sm')]: {
    //   //   fontSize: '2.2rem',
    //   // },
    //   // [theme.breakpoints.up('md')]: {
    //   //   fontSize: '2.6rem',
    //   //   textAlign: 'left',
    //   //   lineHeight: '4.8rem',
    //   // },
    //   // [theme.breakpoints.up('lg')]: {
    //   //   fontSize: '3rem',
    //   //   lineHeight: '5.2rem',
    //   // },
    //   // [theme.breakpoints.up('xl')]: {
    //   //   fontSize: '3.5rem',
    //   //   lineHeight: '5.8rem',
    //   // },
    // };
    //
    // theme.overrides['MuiContainer'] = {
    //   fixed: {
    //     [theme.breakpoints.down('lg')]: {
    //       maxWidth: '100%',
    //     },
    //   },
    // };

    // console.log('generated theme', theme)
    // console.log('theme debug', getMuiTheme(getCustomPalette(currentUserGroup)))
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
  }
};

function mapStateToProps(state) {
  const props = {
    universe: getUniverse(state),
  };
  return props;
}

export default connect(mapStateToProps)(Theme);
