import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import DescriptionIcon from '@material-ui/icons/Description';

import { OfferDates, OfferFullname, OfferRetrocession } from './OfferHelper';

import { getSelectedOffer, setSelectedOffer } from './ducks/offer';

const styles = (theme) => {
  return {
    root: {
      minWidth: 350,
      margin: 8,
      padding: 8,
      border: '1px solid transparent',
      borderRadius: '15px',
      [theme.breakpoints.down('md')]: {
        minWidth: 'unset',
        margin: 6,
        padding: 6,
      },
      [theme.breakpoints.down('sm')]: {
        margin: 5,
        padding: 6,
      },
      [theme.breakpoints.down('xs')]: {
        margin: 3,
        padding: 6,
        borderRadius: '13px',
        //textAlign:'center',//VGauche
      },
      '& .MuiCardActions-root': {
        padding: '0px',
      },
    },
    category: {
      color: '#0A569A',
      background: '#0A569A26 0% 0% no-repeat padding-box',
      borderRadius: '11px',
      padding: '2px 6px',
      margin: '2px 6px',
      display: 'inline-block',
      float: 'right',
      fontSize: '90%',
      /*
      //version gauche
      [theme.breakpoints.down('xs')]: {
        float: 'unset',
        textAlign: 'center',
        display:'inlineBlock',
      },
      */
    },
    remp: {
      color: '#E35323',
      background: '#fbe5de',
    },
    inst: {
      color: '#0097D9',
      background: '#0097D926',
    },
    detailLink: {
      color: '#3EA5FF !important',
      textDecoration: 'underline',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    ListItemText: {
      paddingLeft: theme.spacing(4),
    },
    ListItem: {
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
    },
    selected: {
      border: '2px solid #0097d9',
      outline: '2px solid #cee5ee',
      transition: '0.3s all ease-out',
    },
    mainTable: {
      textAlign: 'left',
      marginTop: '1em',
      color: 'rgb(10, 86, 154)',
      '& td': {
        padding: '5px 8px',
        [theme.breakpoints.down('sm')]: {
          padding: '3px 3px',
        },
        /*
        //Vgauche
        [theme.breakpoints.down('xs')]: {
          padding:"2px 1px",
        },
        */
      },
      '& svg': {
        [theme.breakpoints.down('sm')]: {
          scale: '80%',
        },
        /*
        //VGauche
        [theme.breakpoints.down('xs')]: {
          scale:'75%',
          marginRight: "0px",
        },
        */
      },
    },
    tableIcon: {
      /*
      //VGauche
      [theme.breakpoints.down('xs')]: {
        display:'none',
      },
      */
    },
    tableLabel: {},
    tableContent: {
      paddingLeft: '5em !important',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '1px !important',
      },
    },
    detailLinkBox: {
      marginTop: '-30px',
      textAlign: 'right',
      marginRight: '8px',
      width: '100%',
      /*
      //VGAuche
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px',
      },
      */
      /*
      //VGauche
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        marginRight: '0px',
      },
      */
    },
  };
};
class OfferCard extends Component {
  constructor(props) {
    super(props);
    this.rootRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    const { rootRef } = this;
    const root = rootRef.current;

    if (!prevProps.selected && selected && root) {
      root.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        //behavior: 'smooth',//ne fonctionne pas tjrs avec Chrome/Edge
      });
    }
  }

  render() {
    const { classes, item, selected, onClick, $$selects } = this.props;
    let categories = {};
    let practiceModes = {};
    if ($$selects !== undefined && $$selects.get('values')) {
      const values = $$selects.get('values');
      if (values.get('offerCategory')) categories = values.get('offerCategory').toJS();
      if (values.get('offerPracticeMode')) practiceModes = values.get('offerPracticeMode').toJS();
    }
    const categValue = item !== undefined && item.get('offerCategory') ? item.get('offerCategory') : '';
    const category = categValue in categories ? categories[categValue] : '';
    const practiceMode =
      item !== undefined && item.get('offerPracticeMode') && item.get('offerPracticeMode') in practiceModes
        ? practiceModes[item.get('offerPracticeMode')]
        : '';

    return (
      <Card ref={this.rootRef} className={clsx(classes.root, { [classes.selected]: selected })}>
        <Box className={clsx(classes.category, classes[categValue])}>{category}</Box>
        <CardContent onClick={onClick}>
          <Typography variant="h5" component="h2">
            {item.get('offerCity')}
          </Typography>
          <table className={classes.mainTable}>
            <tbody>
              <tr>
                <td className={classes.tableIcon}>
                  <DateRangeIcon />
                </td>
                <td className={classes.tableLabel}>Dates</td>
                <td className={classes.tableContent}>{<OfferDates item={item} />}</td>
              </tr>
              <tr>
                <td className={classes.tableIcon}>
                  <HomeWorkIcon />
                </td>
                <td className={classes.tableLabel}>{practiceMode}</td>
                <td className={classes.tableContent}>{item.get('offerInstitutionName')}</td>
              </tr>
              <tr>
                <td className={classes.tableIcon}>
                  <LocalHospitalIcon />
                </td>
                <td className={classes.tableLabel}>Docteur.e</td>
                <td className={classes.tableContent}>{<OfferFullname item={item} />}</td>
              </tr>
              <tr>
                <td className={classes.tableIcon}>
                  <DescriptionIcon />
                </td>
                <td className={classes.tableLabel}>Contrat</td>
                <td className={classes.tableContent}>{<OfferRetrocession item={item} />}</td>
              </tr>
            </tbody>
          </table>
        </CardContent>
        <CardActions>
          <Box className={classes.detailLinkBox}>
            <Link
              className={classes.detailLink}
              href={'/offers/' + item.get('id')}
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push('/offers/' + item.get('id'));
              }}
            >
              Voir plus
            </Link>
          </Box>
        </CardActions>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selected: getSelectedOffer(state) === ownProps.item,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => dispatch(setSelectedOffer(ownProps.item)),
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OfferCard)));
