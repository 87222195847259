import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from 'config/api'
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import isArray from 'mout/lang/isArray';
import Immutable from 'immutable';
import Dialog from 'components/UI/Dialog/Dialog'
import Select from "components/UI/Form/Select";

// import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { getToken } from 'components/UI/Account/ducks/authentication';
import { makeSelectors, makeActionCreators } from './ducks/dynamicForm';
import { defaultMemoize as memoize } from 'reselect';
import ModuleConfigProvider from 'components/UI/ModuleConfigProvider';
// import BlockButton from "components/UI/BlockButton";
import Spinner from 'components/UI/Spinner';
import DynamicField from './DynamicField';
import Logger from 'components/UI/Logger';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import { capitalize } from 'helpers/stringUtils';
import isInCategory from 'components/UI/helpers/isInCategory';


// import {
  // useGoogleReCaptcha,
  // GoogleReCaptchaProvider,
  // GoogleReCaptcha
// } from 'react-google-recaptcha-v3';



const styles = (theme) => {
  return {
    error: {
      backgroundColor: '#ffdbdb',
    },
    logError: {
      color: '#ff0000',
    },
    errorIcon: {
      color: '#ff0000',
    },
    margin: {
      margin: theme.spacing(1),
    },
    form: {
      '& .fieldGroup:last-of-type': {
        borderBottom: 'none',
      },
    },
    padderClass: {
      margin: '40px 0 20px',
      textAlign: 'left',
      '& .MuiTypography-h6': {
        textAlign: 'left',
      },
    },
    prettyLabel: {
      '& small': {
        display: 'block'
      }
    },
    // fieldGroup: {
    //   marginBottom: theme.spacing(4),
    //   paddingBottom: theme.spacing(4),
    // },
    // fieldGroupHeader: {
    //   textAlign: 'left',
    //   textDecoration: 'underline',
    //   marginBottom: theme.spacing(2),
    // }
  };
};


const unloadCaptcha = (recaptchaSiteKey) => {
  const nodeBadge = document.querySelector('.grecaptcha-badge');
  if (nodeBadge) {
    document.body.removeChild(nodeBadge.parentNode);
  }

  const scriptSelector = 'script[src=\'https://www.google.com/recaptcha/api.js?render=' +
  recaptchaSiteKey + '\']';
  const script = document.querySelector(scriptSelector);
  if (script) {
    script.remove();
  }
};



class DynamicForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
    forceReadOnly: PropTypes.bool,
    moduleConfig: PropTypes.object,
    onPostComplete: PropTypes.func,
  };
  static contextTypes = {
    muiTheme: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      activeCategories: [],
      activeCategoriesMap: {},
      popinPrecisionState: false,
    };
    this._onBottomActionClick = this._onBottomActionClick.bind(this);
    this._onFieldValueChange = this._onFieldValueChange.bind(this);
    this._onCategoryTrigger = this._onCategoryTrigger.bind(this);
    this._onCancel = this._onCancel.bind(this);
  }
  componentDidMount() {
    const { initialCategories } = this.props;
    let s = {};
    // console.log('MOUNT', initialCategories)


    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    }

    if (this.props.moduleConfig.captcha === true) {
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${config.recaptchaKey}`,  () => {
      if (initialCategories !== undefined) s.activeCategories = initialCategories;
      this._setupState(s);
      this.props.onMount(this.props.moduleConfig);
    })
  } else {
    if (initialCategories !== undefined) s.activeCategories = initialCategories;
    this._setupState(s);
    this.props.onMount(this.props.moduleConfig);

  }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) this.props.onIdChange();
    if (
      (prevProps.$$values === undefined && this.props.$$values !== undefined) ||
      prevProps.$$values !== this.props.$$values
    ) {
      this.props.$$values.map((val, k) => {
        this._onFieldValueChange(null, {
          id: k,
          value: val,
        });
        return true;
      });
    }

    if ((prevProps.$$values === undefined && this.props.$$values !== undefined) || (prevProps.$$values.size === 0 && this.props.$$values.size > 0))
    {
      if (this.props.onFormLoaded !== undefined){
        //MODIF B : ajout valeur de retour pour forcer des changements de valeurs
        const changedValues=this.props.onFormLoaded({values: this.props.$$values});
        if(changedValues!==undefined && typeof changedValues==='object'){
          Object.keys(changedValues).forEach( k => {
            this.props.$$values.setIn(k, changedValues[k]);
            this._onFieldValueChange(null, {
              id: k,
              value: changedValues[k],
            });
          });
        }
      }
    }


    // console.log('C', this.props.moduleConfig.afterEdit,this.props.onFormComplete )
    if (
      prevProps.postComplete !== true &&
      this.props.postComplete === true &&
      (this.props.moduleConfig.afterEdit === 'onFormComplete' || this.props.moduleConfig.afterEdit === 'both') &&
      this.props.onFormComplete
    ) {
      // console.log('GO onFormComplete')
      // if (this.props.onClose)
      // console.log('CHECK RESP', this.props.responseData)
      this.props.onFormComplete(this.props.responseData);
      // else console.log('no onClose event found!')
      // return <p>Bye</p>
    }
    if (prevProps.token !== this.props.token && this.props.onTokenSet !== undefined)
    this.props.onTokenSet(this.props.token);

    if (
      (prevProps.$$fields === undefined || prevProps.$$fields.size === 0) &&
      this.props.$$fields !== undefined
    ) {
      if (this.props.onFormReady) this.props.onFormReady();
    }
    // console.log('updated', prevProps.$$values.size === 0, this.props.$$values, this.props.$$initialActiveCategories, prevState.activeCategories)
    // console.log('updated', this.props.$$values, prevState.activeCategories, (this.props.$$values !== undefined && this.props.$$values.size === 0 && prevState.activeCategories.length === 0))
    // if ((prevProps.$$values === undefined || prevProps.$$values.size === 0 ) && ((this.props.$$values !== undefined && this.props.$$values.size > 0) || (this.props.$$values !== undefined && this.props.$$values.size === 0 && prevState.activeCategories.length === 0)) && this.props.$$initialActiveCategories !== undefined && this.props.$$initialActiveCategories !== false && this.props.$$initialActiveCategories !== null && this.props.$$initialActiveCategories.size > 0)
    if ((prevProps.$$values === undefined || prevProps.$$values.size === 0 ) && ((this.props.$$values !== undefined && this.props.$$values.size > 0)) && this.props.$$initialActiveCategories !== undefined && this.props.$$initialActiveCategories !== false && this.props.$$initialActiveCategories !== null && this.props.$$initialActiveCategories.size > 0)
    {
      // console.log('GOT ACTIVE', this.props.$$initialActiveCategories.toJS())
      // this.setState({activeCategories: this.props.$$initialActiveCategories.toJS()})
      this.setState({activeCategories: this.props.$$initialActiveCategories.toJS()})
    }


    if (prevProps.$$requiredPrecisions === undefined && (this.props.$$requiredPrecisions !== undefined || (prevProps.$$requiredPrecisions !== undefined && prevProps.$$requiredPrecisions !== this.props.$$requiredPrecisions))) {
      this.setState({popinPrecisionState: true})
    }
  }
  componentWillUnmount() {
    this.props.onUnmount(this.props.moduleConfig);

    if (this.props.moduleConfig.captcha === true) {
      unloadCaptcha(config.recaptchaKey)
    }
  }
  _onCategoryTriggerLegacy(field, value, $$triggers) {
  if ($$triggers === undefined) return;
  const catName = $$triggers.get(value);
  // console.log('triggered new categor', field, catName)
  let currentCategories = this.state.activeCategories;
  // if (currentCategories.indexOf(catName) >= 0) return;
  let newCategories = [];
  if (currentCategories.length > 0) {
    currentCategories.forEach((cat, cId) => {
      // console.log('looking for', cat, $$triggers.toJS())
      if (!$$triggers.find((t) => t === cat)) {
        newCategories.push(cat);
      }
    });
    if (newCategories.length === 0) {
      const def = $$triggers.find((t) => t === '*')
      console.log('def is ', def)
    }
  }
  newCategories.push(catName);
  // console.log('new is', newCategories)
  this.setState({
    activeCategories: newCategories,
  });
}

  _onCategoryTrigger(field, value, $$triggers) {
    if ($$triggers === undefined) return;
    let categories = $$triggers.get(value);
    if (categories !== undefined && value !== null && value !==  undefined)
      categories = $$triggers.get(value.toString());
    if (categories ===  undefined && $$triggers.get('*'))
      categories = $$triggers.get('*');
      // console.log('cattergory triggered', field, value, categories)


    if (categories !== undefined && categories !== null && categories !== false && !categories.size) categories = Immutable.fromJS([categories]);

     // console.log(fieldsWithTriggers);

    // let activeCategories = this.state.activeCategories;
    let newCategoriesMap = this.state.activeCategoriesMap;
    let newCategories = [];

    if (categories === undefined || categories === false || categories === null)
    {
      // do nothing !
    } else {
      categories.forEach((cat) => {
        newCategories.push(cat);
      });

      if (newCategories.length === 0) {
        console.log('looking for default')
      }
    }
    let newActiveCategories = [];
    newCategoriesMap[field] = newCategories;
    // console.log(newCategoriesMap)
    Immutable.fromJS(newCategoriesMap).map((g, f) => {
      g.map(n => {
        if (newActiveCategories.indexOf(n) < 0)
        newActiveCategories.push(n);
        return true;
      })
      return true;
    })
    // console.log('new activeCategories', {newActiveCategories, newCategoriesMap})
    this.setState({
      activeCategoriesMap: newCategoriesMap,
      activeCategories: newActiveCategories
    });
  }
  _setupState(extraState) {
    // this.state = {...{
    //   activeCategories: [],
    //   erroredFields: {},
    //   $$formValues: Immutable.fromJS({})
    // }, ...(extraState !== undefined ? extraState : {})};
    // return true;
    const s = {
      ...{
        activeCategories: [],
        activeCategoriesMap: {},
        erroredFields: {},
        $$formValues: Immutable.fromJS({}),
      },
      ...(extraState !== undefined ? extraState : {}),
    };
    // console.log('s is', s)
    this.setState(s);
  }
  onSubmit = (event) => {
    const { confirmBeforeAdd, confirmBeforeEdit } = this.props.moduleConfig;
    event.preventDefault(event);
    if (confirmBeforeAdd && !this.props.id) {
      // confirmBeforeAdd.onSubmit = this.props.handleSubmit;
      confirmBeforeAdd.onSubmit = this._onFormSubmit;
      this.props.onConfirm(confirmBeforeAdd);
    } else if (confirmBeforeEdit && this.props.id) {
      // confirmBeforeEdit.onSubmit = this.props.handleSubmit;
      confirmBeforeEdit.onSubmit = this._onFormSubmit;
      this.props.onConfirm(confirmBeforeEdit);
    }
    // this.props.handleSubmit(event);
    else this._onFormSubmit(event);
  };
  _validateCell($$field, value, validationAtt) {
    let check = true;
    if (value !== undefined && value !== '') {
      value = value.toString().replace(/ /g, '');
      check = /^(?:\+[0-9]{2,3})[0-9]{8}$/.test(value);
      // console.log('check 1', check)
      if (check !== true) check = /^(0[67])[0-9]{8}$/.test(value);
      // console.log('check 1', check)
    }
    const log =
    check === true
    ? undefined
    : 'Le champ ' + $$field.get('label') + ' doit être un numéro de téléphone portable';
    return log;
  }
  _validatePhone($$field, value, validationAtt) {
    let check = true;
    if (value !== undefined && value !== '') {
      value = value.toString().replace(/ /g, '');
      check = /^(?:\+[0-9]{2,3})[0-9]{8}$/.test(value);
      if (check !== true) check = /^0[0-9]{9}$/.test(value);
    }
    const log =
      check === true
        ? undefined
        : 'Le champ ' + $$field.get('label') + ' doit être un numéro de téléphone';
    return log;
  }
  _validateNum($$field, value, validationAtt) {
    let check = true;
    if (
      value !== undefined &&
      ((value === null && validationAtt !== undefined && validationAtt.get('canBe') !== 'empty') ||
      value !== null)
    )
    check = !/([^0-9])/.test(value);
    const log = check === true ? undefined : 'Le champ ' + $$field.get('label') + ' doit être numérique';
    return log;
  }
  _validateAlphaNum($$field, value, validationAtt) {
    let check = true;
    let log = '';
    if (value !== undefined) {
      // check = !(/([^0-9a-z\-_' ])/i.test(value))
      // var rgxp = new RegExp('([^0-9a-z\-_\'])', 'i')
      // check = !(rgxp.test(value))
      let rgxp = "([^0-9a-zA-Z-_'";
      // const testimm = validationAtt.isIterable;
      // console.log('ULTRA TEST', validationAtt, validationAtt.isIterable)
      let rflags = 'im';
      if (
        validationAtt !== undefined &&
        validationAtt.size !== undefined &&
        validationAtt.get('extraChars') !== undefined &&
        validationAtt.get('extraChars') !== false
      ) {
        // console.log('adding extra')
        rgxp += validationAtt.get('extraChars');
        if (validationAtt.get('multiline') === true) {
          rgxp += '\n';
          // rflags += 'm';
        }
      }
      rgxp += '])';
      // let rflags = 'im';
      check = !new RegExp(rgxp, rflags).test(value);
      if (value !== null && check !== true)
      console.log('Invalid text:', value.toString().toLowerCase().match(rgxp));
      log = check === true ? undefined : 'Le champ ' + $$field.get('label') + ' doit être alphanumérique, caractère invalide: "'+value.toString().toLowerCase().match(rgxp)[0]+'".'

    } else {
    // console.log('CHECK ALPHA NUM', check)
    log = check === true ? undefined : 'Le champ ' + $$field.get('label') + ' doit être alphanumérique';
    }
    return log;
  }
  _validateDate($$field, value, validationAtt) {
    let check = true;
    if (value !== undefined) check = !!/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/i.test(value);
    const log = check === true ? undefined : 'Le champ ' + $$field.get('label') + ' est invalide';
    return log;
  }
  _validateEmail($$field, value, validationAtt) {
    let check = true;
    if (value !== undefined) check = !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
    const log = check === true ? undefined : 'Le champ ' + $$field.get('label') + ' est invalide';
    return log;
  }
  _validateIme($$field, value, validationAtt) {
    let check = true;
    if (value !== undefined) check = /^[0-9]{9,10}[a-zA-Z]{1,2}$/i.test(value);
    // console.log('IME CHECK', check)
    const log = check === true ? undefined : 'Le champ ' + $$field.get('label') + ' est invalide';
    return log;
  }
  _validatePassword($$field, value, validationAtt) {
    let log;
    if ((!value || value === undefined) && $$field.get('hardened') !== true)
    log = 'Le champ ' + $$field.get('label') + ' doit contenir au moins 8 caractères, une majuscule et un chiffre';
    else if ((!value || value === undefined) && $$field.get('hardened') === true)
    log = 'Le champ ' + $$field.get('label') + ' doit contenir au moins 13 caractères, une majuscule, un chiffre et 3 caractères spéciaux';
    else if (value.toString().length < 8 && $$field.get('hardened') !== true)
    log = 'Le champ ' + $$field.get('label') + ' doit contenir au moins 8 caractères';
    else if (value.toString().length < 13 && $$field.get('hardened') === true)
    log = 'Le champ ' + $$field.get('label') + ' doit contenir au moins 13 caractères';
    else if (!/[A-Z]/.test(value))
    log = 'Le champ ' + $$field.get('label') + ' doit contenir au moins une majuscule';
    else if (!/[0-9]/.test(value))
    log = 'Le champ ' + $$field.get('label') + ' doit contenir au moins un chiffre';
    else if ($$field.get('hardened') === true && !(!/[^A-Za-z0-9]{3,}/.test(value)))
    log = 'Le champ ' + $$field.get('label') + ' doit contenir un ou plusieurs caractères spéciaux';
    return log;
  }
  _validateNotEmpty($$field, value, validationAtt) {
    let check = true;
    // console.log('NOT EMPTY CHECK', $$field.get('label'), value)
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      (value === 'off' && $$field.get('type') === 'Checkbox') ||
      (value.length === 0 && $$field.get('type') === 'SelectBlocks') ||
      (value.size !== undefined && value.size === 0)
    )
    check = false;
    console.log($$field.get('id'), value)
    const log = check === true ? undefined : 'Le champ « ' + $$field.get('label') + ' » est requis';
    return log;
  }
  // _setFieldValue(e) {
  //   let h = {}
  //   h[e.target.id] = e.target.value;
  //   this.setState(h)
  // }
  // _processFieldsInput() {
  //   const { $$fields, extraFields, forceHiddenFields } = this.props;
  //   const { activeCategories } = this.state;
  //   // let log = [],
  //   let formData = {},
  //   check;
  //   // console.log({forceHiddenFields})
  //   let erroredFields = {},
  //   errorCount = 0;
  //   $$fields.map((group, gIdx) => {
  //     group.get("items").map((field, fIdx) => {
  //       // console.log('field is', field.toJS())
  //       const fieldName = field.get("id");
  //       const value = this.state["f_" + fieldName];
  //       const inCategory = isInCategory(field, activeCategories);
  //       const isNotHidden =
  //       forceHiddenFields === undefined ||
  //       (forceHiddenFields !== undefined &&
  //         forceHiddenFields.indexOf(field.get("id")) < 0);
  //         if (inCategory && isNotHidden) {
  //           // console.log('ok field', field.get('label'))
  //           if (
  //             field.get("validators") !== undefined &&
  //             field.get("validators").size > 0
  //           ) {
  //             field.get("validators").map((validationAtt, validation) => {
  //               check = undefined;
  //               switch (validation) {
  //                 case "email":
  //                 check = this._validateEmail(field, value, validationAtt);
  //                 break;
  //                 case "ime":
  //                 check = this._validateIme(field, value, validationAtt);
  //                 break;
  //                 case "password":
  //                 check = this._validatePassword(field, value, validationAtt);
  //                 break;
  //                 case "notEmpty":
  //                 check = this._validateNotEmpty(field, value, validationAtt);
  //                 break;
  //                 case "num":
  //                 case "isNumber":
  //                 check = this._validateNum(field, value), validationAtt;
  //                 break;
  //                 case "alphaNum":
  //                 check = this._validateAlphaNum(field, value, validationAtt);
  //                 break;
  //                 case "date":
  //                 check = this._validateDate(field, value, validationAtt);
  //                 break;
  //                 case "cell":
  //                 check = this._validateCell(field, value, validationAtt);
  //                 break;
  //                 default:
  //                 console.log("missing validator", validation);
  //                 break;
  //               }
  //               if (check !== undefined) {
  //                 erroredFields[field.get("id")] = check;
  //                 errorCount = errorCount + 1;
  //                 // log.push(check);
  //               }
  //               // console.log('meh', field.get('id'), this.state['f_'+field.get('id')])
  //               formData[field.get("id")] = this.state["f_" + field.get("id")];
  //
  //               return true;
  //             });
  //           } else {
  //             // console.log('no vlidator for', field.get('label'))
  //             formData[field.get("id")] = this.state["f_" + field.get("id")];
  //             return true;
  //           }
  //           // return formData;
  //         } else {
  //           // console.log('skipping', field.get('label'))
  //           return false;
  //         }
  //         return true;
  //       });
  //       return true;
  //     });
  //
  //     if (formData !== undefined && extraFields !== undefined) {
  //       // console.log(formData, extraFields.toJS())
  //       // console.log(extraFields, extraFields.toJS())
  //       extraFields.map((v, f) => {
  //         if (v === undefined) {
  //           // formData[f]
  //         } else if (isArray(v) === true) formData[f] = JSON.stringify(v);
  //         else if (v.toJS !== undefined) formData[f] = v.toJS();
  //         else formData[f] = v;
  //         return true;
  //       });
  //     }
  //     // console.log({formData})
  //     this.setState(
  //       { erroredFields },
  //       function () {
  //         if (errorCount < 1) this.props.onSubmit(formData);
  //         // console.log('errors', errorCount, erroredFields)
  //       }.bind(this)
  //     );
  //   }

  getCaptcha = async () => {
    const token = await new Promise((resolve, reject) => {
      window.grecaptcha.ready(async () => {
        const token2 = await window.grecaptcha.execute(config.recaptchaKey, { action: 'submit' }).then(async token => {
          // console.log('pretoken', token)
          return token;
        });
        // console.log('token2 is', token2)
        return resolve(token2)
      });
    });
    return token;
  }
  _processFieldsInput() {
    // const { $$fields, extraFields, forceHiddenFields } = this.props;
    // const { activeCategories } = this.state;
    // let log = [],
    // let formData = {},
    // check;
    // // console.log({forceHiddenFields})
    // let erroredFields = {},
    // errorCount = 0;
    // $$fields.map((group, gIdx) => {
    //   group.get("items").map((field, fIdx) => {
    //     // console.log('field is', field.toJS())
    //     const fieldName = field.get("id");
    //     const value = this.state["f_" + fieldName];
    //     const inCategory = isInCategory(field, activeCategories);
    //     const isNotHidden =
    //     forceHiddenFields === undefined ||
    //     (forceHiddenFields !== undefined &&
    //       forceHiddenFields.indexOf(field.get("id")) < 0);
    //       if (inCategory && isNotHidden) {
    //         // console.log('ok field', field.get('label'))
    //         if (
    //           field.get("validators") !== undefined &&
    //           field.get("validators").size > 0
    //         ) {
    //           field.get("validators").map((validationAtt, validation) => {
    //             check = undefined;
    //             switch (validation) {
    //               case "email":
    //               check = this._validateEmail(field, value, validationAtt);
    //               break;
    //               case "ime":
    //               check = this._validateIme(field, value, validationAtt);
    //               break;
    //               case "password":
    //               check = this._validatePassword(field, value, validationAtt);
    //               break;
    //               case "notEmpty":
    //               check = this._validateNotEmpty(field, value, validationAtt);
    //               break;
    //               case "num":
    //               case "isNumber":
    //               check = this._validateNum(field, value), validationAtt;
    //               break;
    //               case "alphaNum":
    //               check = this._validateAlphaNum(field, value, validationAtt);
    //               break;
    //               case "date":
    //               check = this._validateDate(field, value, validationAtt);
    //               break;
    //               case "cell":
    //               check = this._validateCell(field, value, validationAtt);
    //               break;
    //               default:
    //               console.log("missing validator", validation);
    //               break;
    //             }
    //             if (check !== undefined) {
    //               erroredFields[field.get("id")] = check;
    //               errorCount = errorCount + 1;
    //               // log.push(check);
    //             }
    //             // console.log('meh', field.get('id'), this.state['f_'+field.get('id')])
    //             formData[field.get("id")] = this.state["f_" + field.get("id")];
    //
    //             return true;
    //           });
    //         } else {
    //           // console.log('no vlidator for', field.get('label'))
    //           formData[field.get("id")] = this.state["f_" + field.get("id")];
    //           return true;
    //         }
    //         // return formData;
    //       } else {
    //         // console.log('skipping', field.get('label'))
    //         return false;
    //       }
    //       return true;
    //     });
    //     return true;
    //   });
    //
    //   if (formData !== undefined && extraFields !== undefined) {
    //     // console.log(formData, extraFields.toJS())
    //     // console.log(extraFields, extraFields.toJS())
    //     extraFields.map((v, f) => {
    //       if (v === undefined) {
    //         // formData[f]
    //       } else if (isArray(v) === true) formData[f] = JSON.stringify(v);
    //       else if (v.toJS !== undefined) formData[f] = v.toJS();
    //       else formData[f] = v;
    //       return true;
    //     });
    //   }
    //   // console.log({formData})
    this._getFormValues(({ erroredFields, errorCount, formData }) => {
      this.setState(
        {
          erroredFields,
        },
        async function () {
          // const {token} = this.props
          // formData.csrf = token;
          formData.csrf = await getToken();

          if (this.props.moduleConfig.captcha === true) {
            formData.captcha = await this.getCaptcha()
          }
          if (errorCount < 1) this.props.onSubmit(formData);
          else {
            // console.log('errors', errorCount, erroredFields)
            this.props.setPostError('Le formulaire est incomplet. Veuillez corriger les erreurs et recommencer.')
          }
          // console.log('errors', errorCount, erroredFields)
        }.bind(this),
      );
    });
  }
  _getFormValues(callback) {
    // console.log('GET VALUES ?!!!! ')
    const { $$fields, extraFields, forceHiddenFields, $$requiredPrecisions } = this.props;
    const { activeCategories } = this.state;
    // let log = [],
    let formData = {},
    check;
    // console.log({forceHiddenFields})
    let erroredFields = {},
    errorCount = 0;
    let tmpValue;
    $$fields.map((group, gIdx) => {
      group
      .get('items')
      .filter((f) => f.get('disabled') !== true)
      .map((field, fIdx) => {
        // console.log('field is', field.toJS())
        const fieldName = field.get('id');

        //MODIF B : utilisation de defaultValue si value undefined
        //IMPACT POSSIBLE mais semble faible
        //defaultValue semble seulement utilisé pour les checkboxs, les radios utilisent "tf_default", les selects "defValue", les strings ne semblent pas avoir de défaut possible.
        let value = this.state['f_' + fieldName];
        const defaultValue=field.get('defaultValue');
        if(value===undefined && defaultValue!==undefined){
          console.log(fieldName+" : using defaultValue ", defaultValue);
          value=defaultValue;
        }
        //FIN
        const inCategory = isInCategory(field, activeCategories);
        const isNotHidden =
        forceHiddenFields === undefined ||
        (forceHiddenFields !== undefined && forceHiddenFields.indexOf(field.get('id')) < 0);
        if (inCategory && isNotHidden) {
          // console.log('ok field', field.get('label'))
          if (field.get('validators') !== undefined && field.get('validators').size > 0) {
            field.get('validators').map((validationAtt, validation) => {
              check = undefined;
              switch (validation) {
                case 'email':
                check = this._validateEmail(field, value, validationAtt);
                break;
                case 'ime':
                check = this._validateIme(field, value, validationAtt);
                break;
                case 'password':
                check = this._validatePassword(field, value, validationAtt);
                break;
                case 'notEmpty':
                check = this._validateNotEmpty(field, value, validationAtt);
                break;
                case 'num':
                check = this._validateNum(field, value, validationAtt);
                break;
                case 'isNumber':
                check = this._validateNum(field, value, validationAtt);
                break;
                case 'alphaNum':
                check = this._validateAlphaNum(field, value, validationAtt);
                break;
                case 'date':
                check = this._validateDate(field, value, validationAtt);
                break;
                case 'cell':
                check = this._validateCell(field, value, validationAtt);
                break;
                case 'phone':
                check = this._validatePhone(field, value, validationAtt);
                break;
                default:
                console.log('missing validator', validation);
                break;
              }
              if (check !== undefined) {
                erroredFields[field.get('id')] = check;
                errorCount = errorCount + 1;
                // log.push(check);
              }
              // console.log('meh', field.get('id'), this.state['f_'+field.get('id')])
              
              //MODIF B : usage de value pour avoir le défaut, pourquoi relire le state ??
              //tmpValue = this.state['f_' + field.get('id')];
              tmpValue=value;
              //FIN
              if (tmpValue !== null && tmpValue !== undefined && tmpValue.size !== undefined) {
                tmpValue = tmpValue.toJS();
              }
              formData[field.get('id')] = tmpValue;
              return true;
            });
          } else {
            // console.log('no vlidator for', field.get('label'))
            // formData[field.get("id")] = this.state["f_" + field.get("id")];
            //MODIF B : idem ci-dessus
            tmpValue=value;
            //tmpValue = this.state['f_' + field.get('id')];
            //FIN
            if (tmpValue !== null && tmpValue !== undefined && tmpValue.size !== undefined)
            tmpValue = tmpValue.toJS();
            formData[field.get('id')] = tmpValue;
            return true;
          }
          // return formData;
        } else {
          // console.log('skipping', field.get('label'))
          return false;
        }
        return true;
      });
      return true;
    });
    if (formData !== undefined && extraFields !== undefined) {
      // console.log('formData pre', formData)
      // console.log('extraFields', extraFields.toJS())
      extraFields.map((v, f) => {
        if (v === undefined || v === null) {
          // formData[f]
        } else if (isArray(v) === true) formData[f] = JSON.stringify(v);
        else if (v.toJS !== undefined) formData[f] = v.toJS();
        else formData[f] = v;
        return true;
      });
    }
    if (formData !== undefined && $$requiredPrecisions !== undefined && $$requiredPrecisions.size > 0) {
      // console.log(formData, extraFields.toJS())
      // console.log(extraFields, extraFields.toJS())
      $$requiredPrecisions.map((rows, k) => {
        if (this.state['precision_'+k])
          formData['precision_'+k] = this.state['precision_'+k];
        return true;
      });
    }
    // console.log({formData})
    // this.setState(
    //   { erroredFields },
    //   function () {
    //     if (errorCount < 1)
    //     // console.log('errors', errorCount, erroredFields)
    //   }.bind(this)
    // );
    callback({
      errorCount,
      erroredFields,
      formData,
    });
  }
  _onPrecisionChanged = (kind, o) => {
    // console.log(kind, o)
    const id = o.id
    const value = o.value;
    this.setState({[id]: value})
  }
  _onPrecisionSubmit = () => {
    const {$$requiredPrecisions} = this.props;

    let failed = false;
    // console.log(this.state)
    $$requiredPrecisions.map((rows, f) => {
      // console.log('working on', f, rows.toJS())
      const stateValue = this.state['precision_'+f];
      if (!stateValue || stateValue === undefined) failed = 'failed1';
      if (!$$requiredPrecisions.get(f).get('values').find(p => p.get('value') === stateValue)) failed = 'failed2';

      $$requiredPrecisions.get(f).get('values').find(p => {
        // console.log(p.get('value'), stateValue)
        return p.get('value') === stateValue
      })
      return true;
    })
    if (failed === false)
    {
      this.setState({popinPrecisionState: false}, () => {
        this._sendForm();
      })
    }
  }
  _onPrecisionCancel = () => {
      this.setState({popinPrecisionState: false})
  }

  _onCancel(event) {
    event.preventDefault();
    if (this.props.onClose) this.props.onClose();
    else if (this.props.onFormCancel) this.props.onFormCancel();
  }
  _onFormSubmit(e) {
    e.stopPropagation();
    e.preventDefault();
    // debounce(this._sendForm.bind(this), 1000);
    this._sendForm();
  }
  // _postProcessFormData(formData) {
  //   return formData;
  // }
  _sendForm() {
    // const { loading, extraFields } = this.props;
    const { loading } = this.props;
    if (loading) {
      console.log('already loading!');
      return;
    }
    // console.log('TEST', this.props.$$fields.toJS())
    // let formData =
    this._processFieldsInput();
    // formData = this._postProcessFormData(formData);
    // console.log('processed data', formData)
    // if (formData !== false) {
    //   this.props.onSubmit(formData);
    // } else {
    //   // this.setState({log: log})
    // }
  }
  _onFieldValueChange(kind, target) {
    // console.log('RECEIVED', target)
    const fid = target.id;
    const value = target.value;
    // console.log('CHANGED', fid, value)
    const fn = '_on' + capitalize(fid) + 'Changed';
    const {erroredFields} = this.state;

    let newState = {}
    if (erroredFields[fid]) {
      // console.log('pre', erroredFields)
      delete erroredFields[fid];
      // console.log('post', erroredFields)
      newState.erroredFields = erroredFields;
      if (Object.keys(erroredFields).length === 0)
      this.props.setPostError(undefined)
    }

    newState['f_' + fid] = value;

    this.setState(
      newState,
      function () {
        if (this[fn]) {
          console.log('found fn');
          this[fn](fid, value);
        }
      }.bind(this),
    );
  }
  // _mapRawValues($$values) {
  //   var tmp = {}
  //   $$values.map((val, k) => {
  //     this._onFieldValueChange(null, {id: k, value: val});
  //   })
  //   return $$values;
  // }
  _onBottomActionClick = function (event, action) {
    const {
      moduleConfig,
      // $$bottomActions,
    } = this.props;
    const eventMethod = moduleConfig.bottomActionsEvents[action.eventName];
    // console.log(moduleConfig.bottomActionsEvents, action.eventName, eventMethod)
    this._getFormValues(({ erroredFields, errorCount, formData }) => {
      this.setState(
        {
          erroredFields,
        },
        function () {
          if (errorCount < 1) eventMethod(event, action.values, Immutable.fromJS(formData));
          else {
            // setPostError
            this.props.setPostError('Le formulaire est incomplet. Veuillez corriger les erreurs et recommencer.')
            // console.log('errors', errorCount, erroredFields)
          }
        },
      );
    });
  };
  render() {
    const {
      // $$data,
      dataLoaded,
      $$fields,
      $$linkMap,
      // error,
      otherError,
      postError,
      validationError,
      postRunning,
      postComplete,
      disabled,
      loading,
      moduleConfig,
      $$bottomActions,
      initialValues,
      afterEditHideForm,
      padderClass,
      groupClass,
      classes,
      formFooter,
      formNotice,
      forceHiddenFields,
      $$requiredPrecisions,
      // id,
      // styles: mdStyle,
    } = this.props;
    // console.log('mdStyle',mdStyle);
    console.log('initialValues', initialValues?initialValues.toJS():initialValues);
    let readOnly = false;
    // console.log('active cat', activeCategories)
    const { activeCategories, erroredFields, popinPrecisionState } = this.state;
    // console.log({activeCategories})
    // console.log({activeCategories})
    if (this.props.readOnly) readOnly = true;
    else if (this.props.forceReadOnly) readOnly = true;
    // console.log({readOnly})
    // if (data !== undefined)
    // console.log('LALALALA', data.toJS())
    // const {
    //   readOnly
    // } = this.props.moduleConfig;
    // console.log('form fetched data is', (data ? data.toJS(): 'none'))
    // console.log({postComplete}, moduleConfig.afterEdit)
    if (postComplete && moduleConfig.redirectAfterEdit) {
      return (
        <Redirect
          to={{
            pathname: moduleConfig.redirectAfterEdit,
            state: { from: this.props.location },
          }}
          />
      );
    }
    // if (postComplete === true && moduleConfig.afterEdit === 'eventClose') {
    //   // console.log('MEMEMEEMEMEME', this.props.onClose)
    //   // if (this.props.onClose) this.props.onClose();
    //   // else console.log('no onClose event found!')
    //   return <p>Bye</p>
    // }
    let btSubmit = moduleConfig.btSubmit;
    let btSubmitLabel = moduleConfig.btSubmitLabel;
    let btCancel = moduleConfig.btCancel;
    let btCancelLabel = moduleConfig.btCancelLabel;
    // console.log('2', {moduleConfig}, $$bottomActions.toJS())
    let bottomActions = [];
    // if (moduleConfig.bottomActions) {
    //   moduleConfig.bottomActions.forEach((action, idx) => {
    btSubmit = $$bottomActions.get('btSubmit') !== undefined ? $$bottomActions.get('btSubmit') : btSubmit;
    btSubmitLabel =
    $$bottomActions.get('btSubmitLabel') !== undefined
    ? $$bottomActions.get('btSubmitLabel')
    : btSubmitLabel;
    btCancel = $$bottomActions.get('btCancel') !== undefined && btCancel !== false ? $$bottomActions.get('btCancel') : btCancel;
    btCancelLabel =
    $$bottomActions.get('btCancelLabel') !== undefined
    ? $$bottomActions.get('btCancelLabel')
    : btCancelLabel;
    // console.log('btSubmit', btSubmit)
    if ($$bottomActions && $$bottomActions.get('actions') && $$bottomActions.get('actions').size > 0) {
      const actions = $$bottomActions.get('actions').toJS();
      actions.forEach((action, idx) => {
        if (readOnly !== true || (action.showIfReadOnly === true && readOnly === true))
        // console.log('run')
        bottomActions.push(
          <Button
            variant={moduleConfig.buttonVariant !== undefined ? moduleConfig.buttonVariant : 'outlined'}
            color="primary"
            key={'ba' + idx}
            onClick={(e) => {
              this._onBottomActionClick(e, action);
            }}
            className={action.className}
            >
            {' '}
            {action.label}
          </Button>,
        );
      });
    }
    // console.log({forceHiddenFields})
    // console.log($$fields.filter(f => forceHiddenFields === undefined || (forceHiddenFields !== undefined && forceHiddenFields.indexOf(f.get('id')) < 0)).toJS())
    let value, fieldVisibility;
    // console.log('BLEH FINAL', (!postComplete && !afterEditHideForm), dataLoaded)
    const fieldVariant = moduleConfig.fieldVariant ? moduleConfig.fieldVariant : 'outlined';
    // console.log({
    //   disabled,
    //   postComplete
    // })
    let externalValues;
    // console.log({activeCategories})
    return (
      <ModuleConfigProvider moduleConfig={moduleConfig}>
        <If condition={moduleConfig.formTitle}>
          <Typography variant="h5" gutterBottom>
            {moduleConfig.formTitle}
          </Typography>
        </If>

        {/*<Choose>
          <When condition={id}>Modification de {id}</When>
          <Otherwise>Nouveau</Otherwise>
          </Choose>*/}
          <form className={classes.form}>
            <If condition={dataLoaded && (!postComplete || (postComplete && !afterEditHideForm))}>
              {$$fields
                .filter(
                  group => {
                    let hasContent = false;
                    group.get('items').forEach(field => {
                      if (!hasContent) {
                        const inCategory = isInCategory(field, activeCategories);
                        const isNotHidden =
                        forceHiddenFields === undefined ||
                        (forceHiddenFields !== undefined && forceHiddenFields.indexOf(field.get('id')) < 0);
                        if (inCategory && isNotHidden)
                        hasContent = true;
                      }
                    })
                    return hasContent
                  }
                )
                .map((row, idx) => {
                  return (
                  <Grid
                    item
                    xs={12}
                    key={'grp_' + idx}
                    className={clsx(classes.fieldGroup, groupClass, {[row.get('groupClass')]: row.get('groupClass') !== undefined})}
                    style={{
                      color: row.get('color') !== undefined ? row.get('color') : undefined,
                      backgroundColor: row.get('bgColor') !== undefined ? row.get('bgColor') : undefined,
                    }}
                    >
                    <Grid
                      container
                      spacing={0}
                      alignItems="flex-start"
                      justify="space-around"
                      className={padderClass !== undefined ? padderClass : classes.padderClass}
                      >
                      <If condition={row.get('label') !== undefined && row.get('label') !== ''}>
                        <Grid item xs={12}>
                          <Box mb={3}>
                            <Typography variant="h6" gutterBottom>
                              <Paragrapher prettyfy={true} text={row.get('label')} />
                            </Typography>
                          </Box>
                        </Grid>
                      </If>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={moduleConfig.fieldSpacing !== undefined ? moduleConfig.fieldSpacing : 4}
                          alignItems="flex-start"
                          justify="space-around"
                          style={{ height: '100%' }}
                          >
                          {row.get('items') !== undefined &&
                            row.get('items').size > 0 &&
                            row
                            .get('items')
                            .filter((att, idx) => {
                              fieldVisibility = isInCategory(att, activeCategories);
                              return (
                                fieldVisibility === true &&
                                (forceHiddenFields === undefined ||
                                  (forceHiddenFields !== undefined &&
                                    forceHiddenFields.indexOf(att.get('id')) < 0))
                                  );
                                })
                                .map((att, idx) => {
                                  value =
                                  this.state['f_' + att.get('id')] !== undefined
                                  ? this.state['f_' + att.get('id')]
                                  : undefined;

                                  externalValues = undefined;
                                  if (att.get('externalValues')) {
                                    externalValues = {};
                                    att.get('externalValues').map((ev) => {
                                      externalValues[ev] =
                                      this.state['f_' + ev] !== undefined ? this.state['f_' + ev] : undefined;
                                      return true;
                                    });
                                  }
                                  return (
                                    <Grid item xs={12} sm={att.get('fullSize') === true ? 12 : 6} key={idx}>
                                        {React.createElement(
                                          moduleConfig.DynamicField ? moduleConfig.DynamicField : DynamicField,
                                          {
                                            idx: idx,
                                            row: att,
                                            $$linkMap: $$linkMap,
                                            readOnly: readOnly,
                                            erroredFields: erroredFields,
                                            externalValues: externalValues,
                                            activeCategories: activeCategories,
                                            fieldVisibility: fieldVisibility,
                                            variant: fieldVariant,
                                            value: value,
                                            onChange: this._onFieldValueChange,
                                            onCategoryTrigger: this._onCategoryTrigger,
                                            disabled: att.get('disabled') || postComplete || readOnly || disabled,
                                          },
                                        )}
                                      </Grid>
                                    );
                                  })
                                  .toArray()}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )})
                        .toArray()}
                      </If>
                      <If condition={!dataLoaded}>
                        <Grid item xs={12}>
                          <Spinner text="Chargement..." />
                        </Grid>
                      </If>

                      <If condition={!postComplete && dataLoaded}>
                        <If condition={postError || validationError || otherError}>
                          <Grid item xs={12}>
                            <Box my={2}>
                              <Logger status="error" primary={<Paragrapher text={postError || validationError || otherError} element="div" prettyfy={true} />} />
                            </Box>
                          </Grid>
                        </If>
                        <If condition={moduleConfig.preActionsLegend !== undefined}>
                          <Grid item={true} xs={12}>
                            <Box my={3}>
                              <Typography variant="body1">{moduleConfig.preActionsLegend}</Typography>
                            </Box>
                          </Grid>
                        </If>
                        <If condition={formNotice !== undefined}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="formNotice"
                            >
                            {formNotice}
                          </Grid>
                        </If>
                        <If condition={bottomActions.length > 0}>
                          <Grid item xs={12}>
                            <Box my={4} align="middle">
                              {bottomActions}
                            </Box>
                          </Grid>
                        </If>
                        <If condition={$$requiredPrecisions !== undefined && $$requiredPrecisions.size > 0}>
                        <Grid item xs={12}>
                          <Dialog closeButton={false} disableBackdropClick={true} open={popinPrecisionState}>
                          <Box mb={2}>
                          <Typography variant="h2" gutterBottom>{ moduleConfig.precisionLabel ? moduleConfig.precisionLabel : 'Veuillez faire votre choix'}</Typography></Box>
                          {$$requiredPrecisions.map((p, pIdx) => {
                            return (
                              <Box mb={2} key={'p_'+pIdx}>
                              <Typography variant="body1" gutterBottom>{p.get('label')}</Typography>
                              <Select id={'precision_'+pIdx} name={'precision_'+pIdx} options={p.get('values')} onChange={(kind, o) => {
                              this._onPrecisionChanged(kind, o);
                            }} variant="outlined" value={this.state['precision_'+pIdx]} prettyfyLabel={true} prettyfyClassName={classes.prettyLabel}/></Box>);
                          }).toArray()}
                          <Box align="middle" mb={3}>
                            <Button variant={ moduleConfig.buttonVariant !== undefined ? moduleConfig.buttonVariant : 'outlined'} onClick={this._onPrecisionCancel} style={{marginRight: 5}}>{btCancelLabel}</Button>
                            <Button color="primary" variant={ moduleConfig.buttonVariant !== undefined ? moduleConfig.buttonVariant : 'outlined'} onClick={this._onPrecisionSubmit} style={{marginLeft: 5}}>{btSubmitLabel}</Button>
                          </Box>
                          </Dialog>

                        </Grid>
                        </If>
                        <Grid item xs={12}>
                          <Box my={3} align="middle">
                            <If condition={btCancel !== false}>
                              <Button
                                variant={
                                  moduleConfig.buttonVariant !== undefined ? moduleConfig.buttonVariant : 'outlined'
                                }
                                color="secondary"
                                disabled={loading || disabled}
                                onClick={this._onCancel}
                                className={
                                  "DYNFORM_CANCEL_BUTTON"+(moduleConfig.module!==undefined ? "_"+moduleConfig.module : "")
                                  +" "+classes.margin}
                                size="large"
                                >
                                {btCancelLabel ? btCancelLabel : 'Annuler'}
                              </Button>
                            </If>
                            {/*<If condition={btSubmit !== false && readOnly !== true}>*/}
                            <If condition={btSubmit !== false}>
                              <Button
                                variant={
                                  moduleConfig.buttonVariant !== undefined ? moduleConfig.buttonVariant : 'outlined'
                                }
                                color={
                                  moduleConfig.buttonSubmitColor !== undefined
                                  ? moduleConfig.buttonSubmitColor
                                  : 'primary'
                                }
                                disabled={loading || disabled}
                                onClick={this.onSubmit.bind(this)}
                                className={
                                  "DYNFORM_SUBMIT_BUTTON"+(moduleConfig.module!==undefined ? "_"+moduleConfig.module : "")
                                  +" "+classes.margin}
                                size="large"
                                >
                                {(postRunning === true
                                  ? (moduleConfig.btSubmittingLabel
                                  ? moduleConfig.btSubmittingLabel
                                  : 'Enregistrement...')
                                  : (btSubmitLabel
                                  ? btSubmitLabel
                                  : 'Enregistrer'))}
                                </Button>
                              </If>
                            </Box>
                          </Grid>
                        </If>

                        <If
                          condition={
                            postComplete && (moduleConfig.afterEdit === 'log' || moduleConfig.afterEdit === 'both') && moduleConfig.afterEditLogger === undefined
                          }
                          >
                          <Box my={2}>
                            <Logger status="success" classes={{ root: classes.ok }} primary={<Paragrapher text={moduleConfig.afterEditLog} element="div" prettyfy={true} />}/>
                          </Box>
                        </If>

                        <If
                          condition={
                            postComplete && (moduleConfig.afterEdit === 'log' ||moduleConfig.afterEdit === 'both') && moduleConfig.afterEditLogger !== undefined
                          }
                          >
                          {React.createElement(moduleConfig.afterEditLogger, {
                            initialValues,
                            className: 'Log success ',
                            style: classes.ok,
                          })}
                        </If>

                        <div className="formFooter" style={{ textAlign: 'right' }}>
                          * Champs obligatoires
                        </div>
                        <If condition={formFooter !== undefined}>
                          <div className="formFooter">{formFooter}</div>
                        </If>
                      </form>
                    </ModuleConfigProvider>
                  );
                }
              }
              const mapStateToProps = () => {
                const getSelectors = memoize(makeSelectors);
                return (state, ownProps) => {
                  const selectors = getSelectors(ownProps.form);
                  return {
                    dataLoaded: selectors.getIsDataLoaded(state),
                    $$data: selectors.getData(state),
                    $$fields: selectors.getFields(state),
                    $$values: selectors.getValues(state),
                    $$initialActiveCategories: selectors.getInitialActiveCategories(state),
                    $$bottomActions: selectors.getBottomActions(state),
                    error: selectors.getLoadingError(state),
                    postError: selectors.getPostError(state),
                    postComplete: selectors.isPostComplete(state),
                    postRunning: selectors.isPostRunning(state),
                    initialValues: selectors.getValues(state),
                    responseData: selectors.getResponseData(state),
                    readOnly: selectors.isReadOnly(state),
                    $$linkMap: selectors.getLinkMap(state),
                    $$requiredPrecisions: selectors.getRequiredPrecisions(state),
                    // token: selectors.getFormToken(state),
                    enableReinitialize: true,
                  };
                };
              };
              const mapDispatchToProps = (dispatch, ownProps) => {
                const actionCreators = makeActionCreators(ownProps.form);
                return {
                  onMount: () => dispatch(actionCreators.initForm(ownProps.moduleConfig, ownProps.id, ownProps.kind)),
                  onUnmount: () => dispatch(actionCreators.clearForm()),
                  onIdChange: () => dispatch(actionCreators.initForm(ownProps.moduleConfig, ownProps.id, ownProps.kind)),
                  onSubmit: (values) => {
                    dispatch(actionCreators.postData(values, ownProps.moduleConfig, ownProps.id, ownProps.kind, ownProps.onPostComplete));
                  },
                  onConfirm: (confirm) => {
                    // dispatch(openModal(confirm))
                  },
                  setPostError: (error) => {
                    dispatch(actionCreators.setPostError(error))
                  }
                };
              };
              // DynamicForm = withStyles(DynamicForm)
              DynamicForm = withStyles(styles)(DynamicForm);
              // DynamicForm = reduxForm({
              //   form: 'dynamicForm'
              // })(DynamicForm)
              const Container = connect(mapStateToProps, mapDispatchToProps)(DynamicForm);
              let nextId = 0;
              export default class DynamicFormWithDynamicId extends React.Component {
                constructor(props) {
                  super(props);
                  this.id = nextId;
                  nextId++;
                }
                render() {
                  const formId = `dynamicForm-${this.id}`;
                  const { props } = this;
                  return <Container {...props} form={formId} />;
                }
              }
