import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import Immutable from "immutable";
import { Redirect } from "react-router-dom";
import { isLoggedIn } from "components/UI/Account/ducks/authentication";
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import Header from "components/Header/Header";
import ResetPasswordChallengeForm from "./ResetPasswordChallengeForm";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import Footer from "components/Footer";

const styles = (theme) => {
  return {
    root: {
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      "& > *": {
        flex: 1,
      },
    },
    paper: {
      padding: theme.spacing(4),
    },
  };
};

class ResetPasswordChallenge extends Component {
  render() {
    const { onPanelToggle, classes, userRole, isLoggedIn } = this.props;
    const uri = queryString.parse(window.location.search);
    const $$formValues = Immutable.fromJS({});
    const $$extraFields = Immutable.fromJS({ key: uri.k });

    if (isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <Fade in timeout={1000}>
        <div className={classes.root}>
          <Container fixed disableGutters style={{ display: "flex" }}>
            <ScrollToTopOnMount />
            <Grid container direction="column" style={{ flex: 1 }}>
              <Grid item xs={12} style={{ flex: 0 }}>
                <Header
                  onPanelToggle={onPanelToggle}
                  userRole={userRole}
                  current={"admin"}
                  headerImage={undefined}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ flex: 1 }}
                container
                direction="column"
              >
                <Box className={classes.paperWrapper}>
                  <Paper square elevation={0} className={classes.paper}>
                    <ResetPasswordChallengeForm
                      $$formValues={$$formValues}
                      extraFields={$$extraFields}
                    />
                  </Paper>
                </Box>
              </Grid>
              <Footer />
            </Grid>
          </Container>
        </div>
      </Fade>
    );
  }
}

const mapStateToProps = ($$state) => {
  return {
    isLoggedIn: isLoggedIn($$state),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(ResetPasswordChallenge)
);
