import React, { Component } from "react";
import { connect } from "react-redux";
import config from "config/api";
import {  withRouter } from "react-router-dom";
import { getCurrentUserRole } from "components/UI/Account/ducks/authentication";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { formatDate } from "helpers/stringUtils";
// import { Switch, Route } from 'react-router-dom'
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import Template from "components//Template/Template";
import { fetchModeration, clearModerationData, getModerationData } from './ducks/moderation';
import getEventState from 'helpers/getEventState'

// import BlockButton from 'components/UI/BlockButton';
// import Header from "components/Header/Header";
// import Footer from "components/Footer";
// import NoMatch from 'components/NoMatch';
// import Bubble from 'components/UI/Bubble';
import {
  fetchData,
  getDataHome,
  // getLoadingError,
} from "ducks/asyncFetcher";

const AF_KEY = "home";

class AdminMessage extends Component {
  // constructor(props) {
  //   super(props);
  //   // this._runRemainingTimer = this._runRemainingTimer.bind(this);
  //   // this.state = {
  //   //   showLiveButton: false,
  //   //   timeRemaining: {},
  //   // };
  // }


  componentDidMount() {
    const {userRole} = this.props;
    this.props.onMount();
    if (userRole !== undefined && userRole !== false && userRole !== 'visitor')
    this.props.onUserRoleKnown();
  }
  componentDidUpdate(oldProps) {
    const {userRole} = this.props;
    if (oldProps.userRole === undefined && userRole !== undefined && userRole !== false && userRole !== 'visitor')
    this.props.onUserRoleKnown();
  }
  componentWillMount() {
    this.props.onUnmount();
  }
  render() {
    const { $$data, $$moderationData } = this.props;
    // console.log(match)
    // if (userRole !== 'admin' && userRole !== 'member')
    // return <Redirect to={{
    //     pathname: '/',
    //     state: { from: location }
    //   }}/>
    return (
      <Template>
        <If condition={$$data !== undefined}>
          <Box  mt={5} mb={6} width="100%">
            <Typography align="center" variant={'h2'} color="primary" gutterBottom={true}>Veuillez choisir la session à modérer</Typography>
          </Box>
          <Box  width="100%">
            <Grid container justifyContent="center">
              <If condition={$$moderationData !== undefined}>
              {$$moderationData.map(($$e) => {
                return <Grid item key={'vt_'+$$e.get('id')}  xs={6}>
                  <Box align="left" m={2}>
                    <Paper>
                      <Box align="left" p={2}>
                        <span style={{float: 'right'}}>
                          <Typography variant={'caption'}>{getEventState($$e)}</Typography>
                        </span>
                        <Typography variant={'h5'} gutterBottom={false}>
                          {formatDate(
                            $$e.get("eventDate"),
                            "dddd dd mmmm yyyy '•' HH'h'MM"
                          )}
                        </Typography>
                        <Typography variant={'body1'} gutterBottom={true}>
                          <Paragrapher prettyfy={true} element="span" text={$$e.get('eventTitle')}/>
                        </Typography>
                        <Button variant="outlined" color="primary" onClick={(e) => {this.props.history.push('/staff/orchestrator/'+$$e.get('id')+'/control')}} style={{marginTop: 5}}>Modérer</Button>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
              }).toArray()}
              </If>
            </Grid>
          </Box>
        </If>
      </Template>
    );
  };
};





function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    $$moderationData: getModerationData(state),
    userRole: getCurrentUserRole(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
    onUserRoleKnown: () => {
      dispatch(fetchModeration());
    },
    onUnmount: () => {
      dispatch(clearModerationData());
    },
  };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminMessage));
