import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicList from 'components/UI/DynamicList';
import config from 'config/api';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';

class AdminOfferList extends Component {
  render() {
    const { embed, showFilters, showActions } = this.props;
    const moduleConfig = {
      module: 'offer',
      fetchUrl: config.offers + '/list',
      deleteUrl: config.offers + '/delete',
      editUrl: 'staff/offers/edit',
      filter: this.props.filter,
      showFilters: showFilters,
      showActions: showActions,
      // deleteUrl: '/staff/event/edit',
      // title: 'Evénements'
    };
    return (
      <div>
        <If condition={embed !== true}>
          <Box align="center" mt={3} mb={2} px={2}>
            <Typography variant="h2" gutterBottom>
              Modération des annonces
            </Typography>
          </Box>
        </If>
        <DynamicList moduleConfig={moduleConfig} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {};
  return props;
}
export default withRouter(connect(mapStateToProps)(AdminOfferList));
