// import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -30,
    top: '50%',
    width: 30,
    height: 30,
    fontSize: '1rem',
    borderRadius: '180px'
  },
// }))(Badge);
}))(Badge);

export default StyledBadge;
