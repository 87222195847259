import React from "react";
import EventList from "components/UI/Event/AdminEventList";
// import { Route } from 'react-router-dom'

const EventListEmbed = ({ $$panel, $$row }) => {
  const filter =
    $$row !== undefined && $$row.get("id")
      ? $$panel.get("filter") + "=" + $$row.get("id")
      : null;
  return (
    <div>
      <EventList
        embed={true}
        filter={filter}
        showFilters={false}
        showActions={false}
      ></EventList>
    </div>
  );
};

export default EventListEmbed;
