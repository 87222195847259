import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import config from 'config/api';
import { setLocationFilter } from './ducks/offer';

class OfferLocationFilter extends React.Component {
  constructor(props) {
    super(props);
    this.query = undefined;
    this.lastQuery = undefined;
    this.state = {
      error: false,
      loading: false,
    };
  }

  handleChange = (event) => {
    this.query = event.target.value;

    const { error } = this.state;
    if (error) this.setState({ error: false });
  };

  handleBlur = (event) => {
    this.search({ onlyIfChanged: true });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) this.search({ onlyIfChanged: false });
  };

  handleSearchClick = (event) => {
    this.search({ onlyIfChanged: false });
  };

  search = async ({ onlyIfChanged }) => {
    const { loading } = this.state;
    if (loading) return;

    const { query, lastQuery } = this;

    if (onlyIfChanged && query === lastQuery) return;

    this.lastQuery = query;

    this.setState({ loading: true });

    try {
      const url = config.offers + '/searchloc?' + queryString.stringify({ search: query });

      const response = await fetch(url);
      if (response.status !== 200) {
        this.setState({ error: true });
        return;
      }

      const parsed = await response.json();

      if (parsed.result === 0) {
        this.setState({ error: true });
        return;
      }

      const result = parsed.data;
      const { bounds, location, viewport } = result;
      if (bounds === undefined) {
        this.setState({ error: true });
        return;
      }
      //const northEast = bounds.northeast;
      //const southWest = bounds.southwest;
      //viewport fonctionne mieux pour "France"
      const northEast = viewport.northeast;
      const southWest = viewport.southwest;

      const { onLocationFilterChange } = this.props;
      onLocationFilterChange({
        bounds: { northEast, southWest },
        location,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { error, loading } = this.state;

    return (
      <FormControl style={{}} error={error}>
        <InputLabel shrink>Lieu</InputLabel>
        <Input
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              {loading ? (
                <Box mx={2}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <IconButton onClick={this.handleSearchClick}>
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }
}

export default connect(undefined, (dispatch) => ({
  onLocationFilterChange: (data) => dispatch(setLocationFilter(data)),
}))(OfferLocationFilter);
