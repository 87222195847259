import mirrorCreator from "mirror-creator";
import Immutable from "immutable";
import { createSelector } from "reselect";
import pipe from "helpers/redux-state-mutators.js";
import queryString from "query-string";
import config from "config/api";
import { getToken } from "components/UI/Account/ducks/authentication";
import getFetchOptions from "helpers/getFetchOptions"
const actionTypes = mirrorCreator(
  [
    "SET_ANNOUNCES_DATA",
    "SET_ANNOUNCE_DATA",
    "SET_ANNOUNCE_ERROR",
    "SET_ANNOUNCE_RESET",
    "SET_ANNOUNCE_ANSWERED",
    "CLEAR_ANNOUNCE_DATA",
    "CLEANUP"
  ],
  { prefix: "announce/" }
);

const mutators = {
  setAnnouncesData: (announces) => ($$state) => $$state.set("announces", announces),
  setAnnounceData: (announce) => ($$state) => $$state.set("announce", announce),
  setAnnounceError: (error) => ($$state) => $$state.set("error", error),
  setAnnounceReset: (str) => ($$state) => $$state.set("reset", str),
  setAnnounceAnswered: (answered) => ($$state) =>
    $$state.set("answered", answered),
  clearAnnounceData: () => ($$state) =>
    $$state.set("announce", Immutable.fromJS({})),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_ANNOUNCE_DATA:
      acts.push(mutators.clearAnnounceData(action.announce));
      acts.push(mutators.setAnnounceData(action.announce));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);
    case actionTypes.SET_ANNOUNCES_DATA:
      // acts.push(mutators.clearAnnounceData());
      // acts.push(mutators.clearAnnounceState());
      acts.push(mutators.setAnnouncesData(action.announces));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);
    case actionTypes.CLEANUP:
      acts.push(mutators.setAnnounceAnswered(undefined));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);
    case actionTypes.SET_ANNOUNCE_ANSWERED:
      acts.push(mutators.setAnnounceAnswered(action.answered));
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_ANNOUNCE_ERROR:
      return pipe([mutators.setAnnounceError(action.error)], $$state);

    case actionTypes.SET_ANNOUNCE_RESET:
      return pipe([mutators.setAnnounceReset(action.str)], $$state);

    case actionTypes.CLEAR_ANNOUNCE_DATA:
      return pipe([mutators.clearAnnounceData()], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.announce || Immutable.Map();
export const getAnnouncesData = createSelector([getRoot], ($$state) =>
$$state.get("announces")
);

export const getAnnounceData = createSelector([getRoot], ($$state) =>
  $$state.get("announce")
);
export const getAnnounceAnswered = createSelector([getRoot], ($$state) =>
  $$state.get("answered")
);
export const getAnnounceError = createSelector([getRoot], ($$state) =>
  $$state.get("error")
);
export const getAnnounceReset = createSelector([getRoot], ($$state) =>
  $$state.get("reset")
);

export function resetAnnounce({eventId}) {
  return (dispatch) => {
    let url = config.announce+'/reset/'+eventId
    let formData = {};
    // console.log(url)
    formData.announce_form = true;

    fetch(url, getFetchOptions('POST', formData))
    .then((response) => response.json())
    .then((response) => {
      // console.log('RESPONSE IS', response)
      if (response.reset && response.reset === "complete") {
        dispatch(setAnnounceReset(Math.rand()));
      } else {
        dispatch(setAnnounceError(response.log));
      }
    })
    .catch((error) => dispatch(setAnnounceError(error.message)))
  };
}

export function fetchAnnounces({eventId}) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.announce+'/list/'+eventId

    let formData = {};
    formData.contact_form = true;

    fetch(url, getFetchOptions(config.urlMethod || "POST", formData))
    .then((response) => response.json())
    .then((response) => {
      // console.log('RESPONSE IS', response)
      if (response.list && response.list === "complete") {
        // console.log('got', response.data)
        dispatch(setAnnouncesData(Immutable.fromJS(response.data)));
      } else {
        dispatch(setAnnounceError(response.log));
      }
    })
    .catch((error) => dispatch(setAnnounceError(error.message)))
  };
}

export function fetchAnnounce({eventId, questionId, revision, pull}) {

  // console.log({login, password})
  return (dispatch) => {
    let url = config.announce + "/get/" + eventId + "/?q="+questionId;

    let formData = {};
    formData.contact_form = true;

    // fetch(url, {
    //   credentials: "include",
    //   method: config.urlMethod || "post",
    //   headers: {
    //     Accept: "application/json, application/xml, text/plain, text/html, *.*",
    //     "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    //     "X-Requested-With": "XMLHttpRequest", // needed by php api
    //   },
    //   body: queryString.stringify(formData, { arrayFormat: "bracket" }),
    // })
    fetch(url, getFetchOptions(config.urlMethod || "post", formData))
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.announce && response.announce === "complete") {
          // dispatch(setAnnounceAnswered(response.answered));
          dispatch(setAnnounceData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setAnnounceError(response.log));
        }
      })
      .catch((error) => dispatch(setAnnounceError(error.message)));
  };
}
export function sendAnnounce({ eventId, announceData }) {
  // console.log({login, password})
  return async (dispatch) => {
    let url = config.announce + "/store/" + eventId + "/";
    // let formData = {};
    // formData.announceData = announceData;
    announceData.csrf = await getToken();
    // console.log(announceData)
    announceData.announce_form = true;
    // console.log(announceData)
    // formData.contact_form = true;

    fetch(url, {
      credentials: "include",
      method: "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: queryString.stringify(announceData, { arrayFormat: "bracket" }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.announce && response.announce === "complete") {
          dispatch(setAnnounceAnswered(true));
          dispatch(clearAnnounceData());
        } else {
          dispatch(setAnnounceError(response.log));
        }
      })
      .catch((error) => dispatch(setAnnounceError(error.message)));
  };
}

export function setAnnouncesData(announces) {
  return {
    type: actionTypes.SET_ANNOUNCES_DATA,
    announces,
  };
}

export function setAnnounceData(announce) {
  return {
    type: actionTypes.SET_ANNOUNCE_DATA,
    announce,
  };
}
export function cleanupAnnounce() {
  return {
    type: actionTypes.CLEANUP,
  };
}
export function setAnnounceAnswered(answered) {
  return {
    type: actionTypes.SET_ANNOUNCE_ANSWERED,
    answered,
  };
}
export function setAnnounceError(error) {
  return {
    type: actionTypes.SET_ANNOUNCE_ERROR,
    error,
  };
}
export function setAnnounceReset(str) {
  return {
    type: actionTypes.SET_ANNOUNCE_RESET,
    str,
  };
}
export function clearAnnounceData() {
  return {
    type: actionTypes.CLEAR_ANNOUNCE_DATA,
  };
}
