import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import config from 'config/api';
import getFetchOptions from 'helpers/getFetchOptions';
// import DynamicList from 'components/UI/DynamicList';
import Dialog from 'components/UI/Dialog/Dialog';
import { formatDate } from 'helpers/stringUtils';
// import BlockButton from 'components/UI/BlockButton';
// import EventListEmbed from 'components/Event/EventListEmbed';
// import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
// import IconDone from '@material-ui/icons/Done';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core/styles';
import {
  withRouter
} from "react-router-dom";

import {
  getCurrentUser,
  getToken,
} from "components/UI/Account/ducks/authentication";
import {
  fetchMessages,
  clearMessages,
  getMessages,
} from './ducks/adminMessages';

const styles = theme => ({
  root: {
    textAlign: 'left',
    '& .MuiTypography-h3': {
      color: 'inherit'
      // color: theme.palette.primary.light
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '0.8rem',
      marginRight: 10,
      color: 'inherit'
    },
    '& .MuiTypography-subtitle2': {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginRight: 10
    },
    '& .MuiTypography-caption': {
      textAlign: 'right',
      float: 'right'
    },
    '& .MuiTypography-body1': {
      color: 'inherit',
    }
  },
  approved: {
    color: theme.palette.messages ? theme.palette.messages.approved : theme.palette.primary.main
  },
  item: {
    margin: '10px',
    padding: '10px 20px',
    color: theme.palette.messages ? theme.palette.messages.color : 'inherit',
    // color: theme.palette.secondary.light,
    // backgroundColor: theme.palette.background.default,
    backgroundColor: theme.palette.messages ? theme.palette.messages.background : undefined,
    border: '1px '+theme.palette.primary.main+' solid',
    '& .MuiIconButton-root': {
      color: theme.palette.messages ? theme.palette.messages.color : 'inherit',
    }
  }
})
class AdminMessageGrid extends React.Component {

  // Analyser la route actuelle, et determiner si on doit afficher le form ou la list
  // Pour l'instant on affiche que le form
  constructor(props) {
    super(props);
    this.state = {
      dialogDeleteState: false
    }
  }
  componentDidMount()
  {
    const {eventId, onMount} = this.props;
    onMount({eventId})
    this.$timer = setInterval(() => {
      onMount({eventId});
    }, 3000)
  }
  componentWillUnmount() {
    this.props.onUnmount()
    if (this.$timer) clearInterval(this.$timer)
  }
  _onApprobationToggle = async ($$item) => {
    const url = config.message+'/toggle';
    const csrf = await getToken();
    const data = {message: $$item.get('id'), csrf};

    fetch(url, getFetchOptions('POST', data))
    .then((response) => response.json())
    .then((response) => {
      if (response.moderation && response.moderation === 'complete') {
        const {eventId, onMount} = this.props;
        onMount({eventId})
        // this.setState({redirectToList: true, locked: false});
      }
    })
  }
  _onDeleteConfirmed = async ($$item) => {
    const url = config.message+'/delete';
    const csrf = await getToken();
    const data = {id: $$item.get('id'), csrf};
    fetch(url, getFetchOptions('POST', data))
    .then((response) => response.json())
    .then((response) => {
      if (response.deleted && response.deleted === 'complete') {
        const {eventId, onMount} = this.props;
        this.setState({dialogDeleteState: undefined})
        onMount({eventId})
        // this.setState({redirectToList: true, locked: false});
      }
    })
  }
  _onDeleteCanceled = ($$item) => {
    this.setState({ dialogDeleteState: undefined });
  }
  _onDelete = ($$item) => {
    this.setState({ dialogDeleteState: $$item });
  }

  render() {
    const { $$messages, classes, history, $$user, eventId } = this.props;
    let author;
    const { dialogDeleteState } = this.state;

    // const isAdmin = $$user !== undefined ? $$user.get('role') === 'admin' : false;
    // const isAdmin = $$user !== undefined ? $$user.get('role') === 'admin' : false;
    const userRole = $$user !== undefined ? $$user.get('role') : 'visitor';
    const userSubRole = $$user !== undefined ? $$user.get('subRole') : undefined;
    const isAdmin =
      userRole === 'admin' || (userRole === 'staff' && ['buadm', 'mod'].indexOf(userSubRole) >=0)

    // console.log({userRole, userSubRole});



    // if ($$messages !== undefined) console.log($$messages.toJS())
    return (
      <Grid xs={12} container item className={classes.root}>
        <If condition={$$messages !== undefined && $$messages.get('rows').size > 0}>
          {$$messages.get('rows').map(($$message, mIdx) => {
            author = $$messages.get('linkMap').get('messageAuthor').filter($$m => $$m.get('value') === $$message.get('messageAuthor')).get(0)
            author = author !== undefined ? author.get('text') : '';
            return (<Grid key={'m_'+mIdx} item xs={12} container className={clsx(classes.item, {[classes.approved]: $$message.get('messageApproved') === 'on'})} >
              <Grid item xs={12}>
                <Typography variant="caption" gutterBottom>{author}</Typography>
                <Typography variant="subtitle1" gutterBottom display="inline">{formatDate($$message.get('dateCreated'), 'dddd dd mmmm yyyy \'•\' HH\'h\'MM')}</Typography>
                <If condition={$$message.get('messageApproved') === 'on'}>
                  <Typography variant="subtitle2" gutterBottom display="inline" className={classes.approved}>Approuvé !</Typography>
                </If>
              </Grid>
              <Grid item xs={12}>
                <If condition={isAdmin === true}>
                  <Typography variant="caption" gutterBottom>

                    <IconButton size="small" title="Supprimer" onClick={(e) => {e.preventDefault(); this._onDelete($$message)}}><IconDelete/></IconButton>
                    <IconButton size="small" title="Editer" onClick={(e) => {e.preventDefault(); history.push('/staff/message/edit/'+$$message.get('id')+'?ru=/staff/orchestrator/'+eventId+'/control')}}><IconEdit/></IconButton>
                    <IconButton size="small" title={$$message.get('messageApproved') === 'on' ? 'Désapprouver' : 'Approuver'} onClick={(e) => {e.preventDefault(); this._onApprobationToggle($$message)}}>{$$message.get('messageApproved') === 'on' ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}</IconButton>
                  </Typography>
                </If>
                <Typography variant="body1" gutterBottom>{$$message.get('messageContent')}</Typography>
              </Grid>
            </Grid>)
          }).toArray()}


          <If
            condition={
              dialogDeleteState !== undefined &&
              dialogDeleteState !== false &&
              dialogDeleteState !== null
            }
          >
            <Dialog
              title={"Confirmation de suppression"}
              actions={
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    margin: "20px 0",
                  }}
                >
                  <Button
                    onClick={this._onDeleteCanceled}
                    style={{ margin: "0 10px" }}
                    variant="contained"
                  >Annuler</Button>
                  <Button
                    onClick={(e) => {e.preventDefault(); this._onDeleteConfirmed(dialogDeleteState)}}
                    color="primary"
                    variant="contained"
                    style={{ margin: "0 10px" }}
                  >Continuer</Button>
                </div>
              }
              open={
                dialogDeleteState !== undefined && dialogDeleteState !== false
              }
            >
              <Typography align="center" gutterBottom>
                Souhaitez vous supprimer ce message ?
              </Typography>
              <Typography align="center">
                <strong>{dialogDeleteState.get('messageContent')}</strong>
              </Typography>
            </Dialog>
          </If>
        </If>

        <If condition={$$messages === undefined || ($$messages !== undefined && $$messages.get('rows').size === 0)}>
          <Grid item xs={12} align="center">
            <Typography align="center">
              <strong>Pas de message pour l'instant</strong>
            </Typography>
          </Grid>
        </If>

      </Grid>
    );

  }
}

function mapStateToProps(state) {
  const props = {
    $$user: getCurrentUser(state),
    $$messages: getMessages(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      dispatch(fetchMessages(props));
    },
    onUnmount: (props) => {
      dispatch(clearMessages(props));
    },
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AdminMessageGrid)));
