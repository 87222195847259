import React from 'react';
import TextField from '@material-ui/core/TextField';
import MUIInput from '@material-ui/core/Input';
import dateFormat from 'dateformat';
import inputIron from './inputIron';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

// import debounce from 'mout/function/debounce';
import { withStyles } from '@material-ui/core/styles';
const styles = (theme) => {
  // console.log('input theme', theme)
  return {
    root: inputIron(theme),
  };
};
class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this._onChange = this._onChange.bind(this);
    // this._onChange = debounce(this._onChange.bind(this), 500);
  }
  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }
  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      // console.log('FIELD VALUE CHANGED !!',oldProps.value, this.props.value)
      this.setState({
        value: this.props.value,
      });
    }
  }
  _onChange(e) {
    e.persist();
    const { onChange } = this.props;
    const id = e.target.id;
    const value = e.target.value;
    // console.log(id, value)
    // debounce(function() {
    // console.log('adding', id, value)
    this.setState(
      {
        value: value.replace(/ /g, '').replace(/–/g, '-'),
      },
      function () {
        if (onChange)
          onChange('input', {
            id: id,
            value: value.replace(/ /g, '').replace(/–/g, '-'),
          });
      },
    );
    // }.bind(this), 100)
  }
  togglePasswordVisibility = () => {
    this.setState({showPassword: !this.state.showPassword})
  }
  render() {
    const {
      id,
      // name,
      label,
      type,
      align,
      variant,
      color,
      classes,
      margin,
      maxLength,
      multiline,
      fullWidth,
      helperText,
      required,
      placeholder,
      disabled,
      rows,
      error,
      errorMessage,
      style,
      // startAdornment,
      // endAdornment,
    } = this.props;
    const { value, showPassword } = this.state;
    // console.log(this.props)
    // console.log('type', type)
    let nValue;
    if (type === 'datetime-local' && value !== undefined && !/T/.test(value)) {
      // const d = new Date(value*1000);
      // nValue = d.getUTCFullYear()+'/'+(d.getUTCMonth()+1)+'/'+(d.getUTCDate()+1)+' '+d.getUTCHours()+':'+d.getUTCMinutes();
      // nValue = dateFormat(value*1000, 'dd/mm/yyyy HH:MM');
      nValue = dateFormat(value * 1000, 'yyyy-mm-dd') + 'T' + dateFormat(value * 1000, 'HH:MM');
      // fValue =
    } else if (type === 'date' && value !== undefined && typeof value === "number") {
      nValue = dateFormat(value * 1000, 'yyyy-mm-dd')
    } else {
      nValue = value;
    }
    let compiledProps = {
      id,
      name: id,
      // name,
      // id: name,
      label,
      classes,
      value: nValue !== undefined ? nValue : '',
      multiline,
      helperText,
      align,
      required,
      color,
      disabled,
      placeholder,
      fullWidth: fullWidth !== undefined ? fullWidth : true,
      variant: variant !== undefined ? variant : undefined,
      margin: margin !== undefined ? margin : undefined,
      type: type && !showPassword ? type : 'text',
      // startAdornment: type === 'password' ? <InputAdornment position="start"><VisibilityIcon/></InputAdornment> : undefined,

      onChange: this._onChange,
      rows,
      maxRows: rows + 5,
      error,
      style,
      InputProps: {}
      // startAdornment: startAdornment !== undefined ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
      // endAdornment: endAdornment !== undefined ? <InputAdornment position="start">{endAdornment}</InputAdornment> : undefined,

    };
    if ((type === 'Password' || type === 'password') && value !== 'Secret1234' && value !== '')
    compiledProps.InputProps.endAdornment = (
      <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={this.togglePasswordVisibility}
            onMouseDown={(e) => {e.preventDefault()}}
            >
            <If condition={showPassword}><VisibilityIcon /></If>
            <If condition={!showPassword}><VisibilityIcon /></If>
            </IconButton>
      </InputAdornment>
    );

    if (maxLength !== undefined)
      compiledProps.InputProps.maxLength = maxLength;
    // compiledProps.InputProps = {
    //   maxLength,
    // };
    if (errorMessage !== undefined && errorMessage !== false) compiledProps.helperText = errorMessage;
    if (type === 'date') {
      compiledProps.InputLabelProps = {
        ...compiledProps.InputLabelProps,
        shrink: true,
      }
    }
    if (type === 'hidden') return <MUIInput {...compiledProps}  />;
    else return <TextField {...compiledProps} />;
  }
}
export default withStyles(styles)(Input);
