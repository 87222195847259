import React from 'react';
import { connect } from 'react-redux';
import config from 'config/api';
import Box from '@material-ui/core/Box';
import DynamicList from 'components/UI/DynamicList';
// import Button from '@material-ui/core/Button';
// import EventListEmbed from 'components/UI/Event/EventListEmbed';
import Typography from "@material-ui/core/Typography";
// import { getCurrentUserRole } from 'components/UI/Account/ducks/authentication';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import i18n from 'helpers/i18n';
import { Link } from 'react-router-dom';
import DashboardExportButton from "./DashboardExportButton";

const styles = (theme) => {
  return {
    root:{
      '& .MuiTypography-h2': {
        fontSize: '2.4rem',
        marginBottom: theme.spacing(4),
        padding: '0 92px',
      },
    },
    breadCrumbs: {
      'a': {
        display: 'inline'
      }
    },
  }
}
class DashboardEventList extends React.Component {
  // Analyser la route actuelle, et determiner si on doit afficher le form ou la list

  // Pour l'instant on affiche que le form
  render() {
    const moduleConfig = {
      module: 'account',
      fetchUrl: config.dashboard + '/event/list',
      // title: 'Liste des comptes',
      filter: this.props.filter,
      showFilters: this.props.showFilters,
      editUrl: 'dashboard/event/edit',
    };
    const { embed, classes } = this.props;
    // console.log('USER ROLE ?', userRole)
    return (
      <div className={classes.root}>
        <Box className={classes.breadCrumbs}>
          <Link to={'/dashboard'}>Dashboard</Link>&nbsp;&gt;&nbsp;{i18n.get('dashboardNavigationEvents', {pretty: false})}
        </Box>

        <If condition={embed !== true }>
          <Box align="center" mt={3} mb={2} px={2}>
            <Typography variant="h2" gutterBottom color="primary">{i18n.get('dashboardNavigationEvents', {pretty: false})}</Typography>
            <DashboardExportButton path="event"/>
            {/*  <If condition={ userRole === 'admin'}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push('/staff/account/edit');
              }}
            >
              Ajouter
            </Button>
            </If>*/}
          </Box>
        </If>
        <DynamicList moduleConfig={moduleConfig} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    // userRole: getCurrentUserRole(state),
  };
  return props;
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(DashboardEventList)));
