import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Template from 'components/UI/Template/Template';
import Paper from '@material-ui/core/Paper';
import Spinner from 'components/UI/Spinner';
import { withRouter } from 'react-router-dom';
import PageWithHeader from 'components/UI/PageWithHeader';

import {
  fetchOfferSelects,
  getOfferSelectsData,
  getOffersData,
  fetchOffers,
  getOfferError,
} from './ducks/offer';
import OfferList from './OfferList';
import OfferUniverse from './OfferUniverse';
import OfferProtection from './OfferProtection';

const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
      width: '100%',
    },
    paper: {
      backgroundColor: 'transparent',
      paddingBottom: theme.spacing(4),
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& a': {
        color: 'inherit',
      },
    },

    mainWrapper: {
      position: 'relative',
      zIndex: 9,
      color: '#fff',
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {},
    },
    mainTitle: {
      textAlign: 'center',
      marginBottom: 40,
    },
    livePaper: {
      color: '#fff',
      margin: '0 auto',
      padding: '40px 20px 50px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 20px',
      },
    },
    legend: {
      marginBottom: 30,
    },
  };
};
class Offers extends Component {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    const { classes, $$selects, $$error } = this.props;

    return (
      <Template current="home">
        <OfferUniverse />

        <PageWithHeader
          title="Offres entre professionnels"
          subtitle="Vous cherchez un(e) remplaçant(e), des remplacements dans votre région ? Vous souhaitez vous installer ou céder votre cabinet ?"
          style={{ maxWidth: '1280px' }}
        >
          <Paper square elevation={0} className={classes.paper}>
            <OfferProtection forceDisallowed={$$error === 'disallowed'}>
              <OfferList $$selects={$$selects} />
            </OfferProtection>
          </Paper>
        </PageWithHeader>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$selects: getOfferSelectsData(state),
    $$offers: getOffersData(state),
    $$error: getOfferError(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchOfferSelects());
      dispatch(fetchOffers('all'));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Offers)));
