import React, { Component } from "react";

// import Immutable from 'immutable';
import { connect } from "react-redux";
// import config from "config/api";
// import queryString from "query-string";
// import { getIOToken } from "./io";
// import exceptionLogger from 'helpers/exceptionLogger';
// import getCaptureArgs from 'helpers/getCaptureArgs';

import {
  connectToRelay,
  disconnectFromRelay,
  sendMessage,
  // getContent,
  // getConnectionId,
} from "./ducks/relay";

import { withRouter } from "react-router-dom";

import { isLoggedIn, getCurrentUser } from "components/UI/Account/ducks/authentication";
import { getLiveData } from "ducks/live";

class SocketHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestConnection: false,
      userName: false,
      disconnected: false,
    };
    // this.$video;
    this._toggleConnection = this._toggleConnection.bind(this);
  }

  componentDidMount() {
    this._toggleConnection(true);
  }
  componentDidUpdate(oldProps, oldState) {}
  componentWillUnmount() {
    this._stopSocket();
  }

  _toggleConnection(forceState, forceCallback) {
    const { requestConnection } = this.state;
    const { $$user, eventId } = this.props;

    const userName =
      $$user !== undefined && $$user.get("id")
        ? $$user.get("firstname") + " " + $$user.get("lastname")
        : undefined;
    if (userName === false || userName === undefined || userName === "") {
      console.log("no userName found");
      return;
    }

    if (
      requestConnection === false ||
      (forceState !== undefined && forceState === true)
    ) {
      this.setState(
        {
          requestConnection: true,
        },
        function () {
          this._initSocket(eventId);
          if (forceCallback) forceCallback();
        }.bind(this)
      );
    } else if (
      requestConnection !== false ||
      (forceState !== undefined && forceState === false)
    ) {
      this.setState(
        {
          requestConnection: false,
        },
        function () {
          // console.log('SHOULD KILL');
          this._stopSocket();
          if (forceCallback) forceCallback();
          // this._initSocket(this.props.$$roomData.get('roomHash'));
        }.bind(this)
      );
    }
  }
  _stopSocket() {
    try {
      console.log("SHOULD STOP SOCKET");
      const { pull } = this.props;
      this.props.onDisconnectFromRelay({ pull });
    } catch (e) {
      // exceptionLogger(e)
      console.error(e);
    }
  }

  async _initSocket() {
    try {
      const { $$user, $$event, endpoint, pull } = this.props;

      const userId = $$user !== undefined ? $$user.get("id") : undefined;

      if (userId === undefined || userId === "") {
        console.log("missing aid, aborting !!!");
        return;
      }
      const eventId = $$event !== undefined ? $$event.get("id") : undefined;

      if (eventId === undefined || userId === "") {
        console.log("missing eid, aborting !!!");
        return;
      }
      // console.log('initializing socket for event', eventId, userId, pull);
      // console.log('initializing socket for event', eventId, userId, pull);
      console.log("initializing socket for event", eventId, userId, pull);

      await this.props.onConnectToRelay({
        endpoint:
          ($$user.get("role") === "staff" || $$user.get("role") === "admin" || $$user.get("role") === "speaker") &&
          endpoint === "presenter"
            ? "presenter"
            : "participant",
        $$user,
        $$event,
        pull,
      });
      //;
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
    $$user: getCurrentUser(state),
    $$liveData: getLiveData(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onConnectToRelay: (args) => dispatch(connectToRelay(args)),
    onDisconnectFromRelay: (args) => dispatch(disconnectFromRelay(args)),
    onMessage: (message) => dispatch(sendMessage(message)),
  };
}

// export default ;
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SocketHelper)
);
