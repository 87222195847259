import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { getCurrentUser } from 'components/UI/Account/ducks/authentication';
import Form from './OfferAdminForm';
import List from './AdminOfferList';
import config from 'config/api';
import Template from 'components/UI/Template/Template';
import Box from '@material-ui/core/Box';

const AdminOffer = (props) => {
  const { match, $$user, location } = props;
  const userRole = $$user !== undefined ? $$user.get('role') : undefined;
  const userSubRole = $$user !== undefined ? $$user.get('subRole') : undefined;
  if (userRole !== undefined && userRole !== 'admin' && userSubRole !== 'buadm' && userSubRole !== 'invit')
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );

  return (
    <Template
      maxWidth="auto"
      current="admin"
      headerPosition={'relative'}
      headerImage={
        config.template && config.template.admin && config.template.admin.background
          ? config.template.admin.background
          : undefined
      }
    >
      <Box width="100%">
        <If condition={userRole !== undefined}>
          <div className="HomeBlock offsetSemiTop fullWidth">
            <If condition={match.params.action === 'list'}>
              <List />
            </If>
            <If condition={match.params.action === 'edit'}>
              <Form id={match.params.id} admin={true} />
            </If>
          </div>
        </If>
      </Box>
    </Template>
  );
};

function mapStateToProps(state) {
  const props = {
    $$user: getCurrentUser(state),
  };
  return props;
}

export default withRouter(connect(mapStateToProps)(AdminOffer));
