import React, { Component } from "react";
// import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
// import getCardImage from 'helpers/getCardImage'
import { connect } from "react-redux";
// import config from "config/api";
// import Button from "@material-ui/core/Button";
// import Container from "@material-ui/core/Container";
// import Paper from "@material-ui/core/Paper";
// import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import BlockTitle from "components/UI/BlockTitle";
// import Rating from "@material-ui/lab/Rating";
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
// import Input from "components/UI/Form/Input";
import { withRouter } from "react-router-dom";
import {
  fetchAnnounce,
  getAnnounceData,
  getAnnounceAnswered,
  // getAnnounceError,
  cleanupAnnounce,
  // sendAnnounce,
} from "./ducks/announce";
// import { fetchData, getDataHome, getLoadingError } from "ducks/asyncFetcher";
import { getLoadingError } from "ducks/asyncFetcher";
import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
} from "components/UI/Account/ducks/authentication";
// import i18n from "helpers/i18n";
const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      // maxWidth: "1280px",
      margin: "20px auto",
      // minHeight: 300,
      padding: "0 10px",
      // width: 600,
      // width: 600,
      width: "100%",
      textAlign: "left",
      // maxWidth: 420,
      [theme.breakpoints.down("md")]: {
        // width: 420,
      },
      [theme.breakpoints.down("sm")]: {
        // width: 300,
      },
      '& .MuiRating-root': {
        marginBottom: theme.spacing(2)
      }
    },
    fullWidth: {
      width: "100%",
    },
    headerBg: {
      // minHeight: 210,
      // backgroundImage: "url(" + require("assets/images/header.jpg") + ")",
      backgroundPosition: "0 0",
      [theme.breakpoints.down("sm")]: {
        backgroundImage: "none",
      },
    },
    mainWrapper: {
      fontSize: "14px",
    },
    mainTitle: {
      marginBottom: 40,
      textAlign: "center",
    },
    livePaper: {
      color: theme.palette.common.black,
      maxWidth: 1280,
      margin: "30px auto",
      padding: "40px 20px 50px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 20px",
      },
    },
    inline: {
      display: "inline-block",
    },
    header: {
      // height: 150,
      // [theme.breakpoints.down("sm")]: {
      //   height: 200
      // }
    },
    content: {
      // height: 'calc(100% - 150px - 120px)',
      // [theme.breakpoints.down("sm")]: {
      // height: 'auto'
      // }
    },
    regularHeight: {
      height: "auto",
    },
    footer: {
      width: "100%",
      // height: 120,
      // [theme.breakpoints.down("sm")]: {
      //   height: 'auto'
      // }
    },
  };
};
class Announce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logMessage: false,
      errored: false,
    };
    // this._onAnnounceChange = this._onAnnounceChange.bind(this);
    // this._onAnnounceHover = this._onAnnounceHover.bind(this);
    // this._onFormSubmit = this._onFormSubmit.bind(this);
  }
  componentDidMount() {
    // const liveId = this.props.match.params.id;
    // console.log(this.props)
    const eventId = this._getEventId();
    const {
      // eventId,
      questionId,
      revision,
      pull,
      // $$content
    } = this.props;
    this.props.onMount({
      eventId,
      revision,
      pull,
      // questionId
      questionId: questionId,
    });

    // this.props.onMount(eventId);
    // this._startTimer();
  }
  componentDidUpdate(oldProps, oldState) {
    if (oldProps.questionId !== undefined && this.props.questionId !== oldProps.questionId) {
      const eventId = this._getEventId();
      const {
        // eventId,
        questionId,
        revision,
        pull,
        // $$content
      } = this.props;
      this.props.onMount({
        eventId,
        revision,
        pull,
        // questionId
        questionId: questionId,
      });
    }
    // console.log("updating", oldProps.$$announceData, this.props.$$announceData)
    // if (
    //   (oldProps.$$announceData === undefined  &&
    //     this.props.$$announceData !== undefined &&
    //     this.props.$$announceData !== false)
    //     || (oldProps.$$announceData !== undefined && this.props.$$announceData !== undefined && this.props.$$announceData !== false && oldProps.$$announceData !== this.props.$$announceData)
    //   ) {
    //     // console.log('GOT DATA')
    //     // console.log('GOT DATA')
    //     // console.log('GOT DATA')
    //     let newState = {};
    //     this.props.$$announceData.map(($f, fIdx) => {
    //       if ($f.get('announceType') === 'stars')
    //       newState["v_" + fIdx] = 0;
    //       else
    //       newState["v_" + fIdx] = '';
    //       newState["h_" + fIdx] = false;
    //       return true;
    //     });
    //     newState["isReady"] = true;
    //     this.setState(newState, () => {
    //       // console.log('update set !', this.state.isReady)
    //     });
    //   }
    //   // console.log(oldProps.isReady, this.props.isReady)
    //
    //   if (oldProps.answered !== true && this.props.answered === true && this.props.btClose === false && this.props.onAnnounceComplete)
    //   this.props.onAnnounceComplete()
    }
    componentWillUnmount() {
      this.props.cleanup();
    }
    _getEventId = () => {
      let eventId;
      if (this.props.eventId) eventId = this.props.eventId;
      else if (this.props.match !== undefined && this.props.match.params.eventId)
      eventId = this.props.match.params.eventId;
      // console.log(this.props.eventId, this.props.match);
      return eventId;
    };
    _onAnnounceHover = (idx, newHover) => {
      this.setState({
        ["h_" + idx]: newHover,
      });
    };
    _onAnnounceChange = (idx, value) => {
      this.setState({
        ["v_" + idx]: value,
        errored: false,
      });
    };
    // _onFormSubmit = () => {
    //   let announceData = {};
    //   // console.log('SUBMIT')
    //   const { sendAnnounce } = this.props;
    //   // const {
    //   //   eventId
    //   // } = this.props;
    //   const eventId = this._getEventId();
    //   let errored = false;
    //   this.props.$$announceData.map(($f, fIdx) => {
    //     if (this.state["v_" + fIdx] === 0) {
    //       errored = fIdx;
    //     } else {
    //       announceData["e_" + fIdx] = this.state["v_" + fIdx];
    //     }
    //     return true;
    //   });
    //   // console.log({errored})
    //   if (errored !== false) {
    //     this.setState({
    //       errored,
    //     });
    //   } else {
    //     this.setState({
    //       errored: false,
    //     });
    //     // console.log('SUBMIT !', eventId, announceData);
    //     sendAnnounce(eventId, announceData);
    //   }
    // };
    // _getAnnounceCard() {
    //   const {
    //     eventId
    //   } = this.props.match.params;
    //   const {
    //     $$data
    //   } = this.props;
    //   if($$data === undefined) return;
    //   // console.log(eventId, $$data.toJS())
    //   const found = $$data.get('allEvents').find(f => f.get('id') === eventId);
    //   return found
    // }
    // _onAnnounceComplete = () => {
    //   const { onAnnounceComplete } = this.props;
    //   if (onAnnounceComplete !== undefined) onAnnounceComplete();
    // };
    render() {
      const {
        // userRole,
        classes,
        $$announceData,
        // answered,
        // labelComplete,
        // labelBack,
        // btClose,
        // btBack,
        // footer,
        // $$data
      } = this.props;
      // const { errored, isReady } = this.state;
      // const $$card = this._getAnnounceCard();
      // const cardImage = getCardImage($$card);
      // console.log({
      //   answered,
      //   isReady
      // })
      // console.log('check', $$announceData)
      // console.log('check', {isReady})
      // console.log('check', {answered})
      // console.log($$announceData)
      return (
        <Grid
          item={true}
          xs={12}
          className={classes.root}
          container
          spacing={0}
          alignContent="center"
          >
          <If condition={$$announceData !== undefined}>
            <Grid item xs={12}>
              <Typography variant="h3"><Paragrapher prettyfy={true} text={$$announceData.get('announceTitle')}/></Typography>
            </Grid>
            <Grid item xs={12}>
              <Paragrapher prettyfy={true} text={$$announceData.get('announceText')}/>
            </Grid>
          </If>
        </Grid>);
      }
    }

    function mapStateToProps(state) {
      const props = {
        error: getLoadingError(state),
        isLoggedIn: isLoggedIn(state),
        userRole: getCurrentUserRole(state),
        answered: getAnnounceAnswered(state),
        $$user: getCurrentUser(state),
        $$announceData: getAnnounceData(state),
      };
      return props;
    }

    function mapDispatchToProps(dispatch) {
      return {
        onMount: (props) => {
          // console.log('mouting with', props)
          if (props.eventId !== undefined) {
            dispatch(fetchAnnounce(props));
          }
        },
        cleanup: (eventId) => {
          dispatch(
            cleanupAnnounce()
          );
        },
        // sendAnnounce: (eventId, announceData) => {
        //   if (eventId !== undefined) {
        //     dispatch(
        //       sendAnnounce({
        //         eventId,
        //         announceData,
        //       })
        //     );
        //   }
        // },
      };
    }
    // export default ;
    export default withRouter(
      connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Announce))
    );
