/*eslint-disable no-unused-vars*/

import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
// import queryString from 'query-string';
import getFetchOptions from 'helpers/getFetchOptions';
import isObject from 'mout/lang/isObject';
import keys from 'mout/object/keys';

const actionTypes = mirrorCreator(['SET_DATA', 'CLEAR_DATA', 'SET_LOADING_ERROR'], { prefix: 'dashboard/' });

const mutators = {
  setData: (key, data) => ($$state) => $$state.set(key, Immutable.fromJS(data)),
  clearData: (key) => ($$state) => $$state.delete(key),
  clearLoadingError: ($$state) => $$state.delete('loadingError'),
  setLoadingError: (error) => ($$state) => $$state.set('loadingError', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  switch (action.type) {
    case actionTypes.SET_DATA:
      return pipe([mutators.setData(action.key, action.data), mutators.clearLoadingError], $$state);

    case actionTypes.CLEAR_DATA:
      return pipe([mutators.clearData(action.key), mutators.clearLoadingError], $$state);

    case actionTypes.SET_LOADING_ERROR:
      return pipe([mutators.setLoadingError(action.error)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.asyncFetcher;
export const getData = createSelector([getRoot], ($$state) => {
  // console.log('REQUESTED KEY', key)
  // const tKey = key !== undefined ? key : 'data';
  // console.log('FOUND KEY', tKey)
  return $$state;
});
export const getDataHome = createSelector([getData], (data) => {
  const tKey = 'home';
  return data.get(tKey);
});

export const getSubscriptions = createSelector([getData], (data) => {
  // console.log(data.toJS())
  return data.getIn(['home', 'myfutureevents']) !== undefined ? data.getIn(['home', 'myfutureevents']).map((e) => e.get('id'))
  : Immutable.Map();
})
export const getAvailableEvents = createSelector([getData], (data) => data.getIn(['home', 'availableEvents']))
export const getReplayEvents = createSelector([getData], (data) => data.getIn(['home', 'replays']))

export const getDataStreamTest = createSelector([getData], (data) => {
  const tKey = 'streamtest';
  return data.get(tKey);
});
export const getDataFaq = createSelector([getData], (data) => {
  const tKey = 'faq';
  return data.get(tKey);
});
export const getDataLegal = createSelector([getData], (data) => {
  const tKey = 'legal';
  return data.get(tKey);
});
export const getDataConfidentiality = createSelector([getData], (data) => {
  const tKey = 'confidentiality';
  return data.get(tKey);
});

export const getLoadingError = createSelector([getRoot], ($$state) => $$state.get('loadingError'));

export function fetchData(moduleConfig) {
  return (dispatch) => {
    return fetch(moduleConfig.fetchUrl, getFetchOptions())
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        console.log('version_wecaweco', "data" in response ? response.data.version_wecaweco : null);
        console.log('version_conf', 'data' in response ? response.data.version_conf : null);
        dispatch({
          type: actionTypes.SET_DATA,
          data: response.data,
          key: moduleConfig.key ? moduleConfig.key : 'data',
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: actionTypes.SET_LOADING_ERROR,
          error: error.message,
        });
      });
  };
}
export function clearData(moduleConfig) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_DATA,
      key: moduleConfig.key ? moduleConfig.key : 'data',
    });
  };
}

const convertObjectsInQuery = (data) => {
  keys(data).forEach((key) => {
    const value = data[key];
    if (!isObject(value)) return;

    const subKeys = keys(value);
    if (subKeys.length > 0) {
      delete data[key];
      subKeys.forEach((subKey) => {
        const newKey = `${key}[${subKey}]`;
        data[newKey] = value[subKey];
      });
    }
  });
};
