import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicList from 'components/UI/DynamicList';
import config from 'config/api';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Template from 'components/UI/Template/Template';
import OfferSelectFilter from './OfferSelectFilter';
import OfferUniverse from './OfferUniverse';
import PageWithHeader from 'components/UI/PageWithHeader';
import DialogConfirm from 'components/UI/Dialog/DialogConfirm';
import { getToken } from 'components/UI/Account/ducks/authentication';
import getFetchOptions from 'helpers/getFetchOptions';
import { withRouter } from 'react-router-dom';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import {
  fetchOffers,
  getOffersData,
  getMyOffersData,
  getFilters,
  fetchOfferSelects,
  getOfferSelectsData,
} from './ducks/offer';

import { OfferDates, OfferFullname, OfferRetrocession } from './OfferHelper';
import OfferProtection from './OfferProtection';

const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
    },

    paper: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& a': {
        color: 'inherit',
      },
    },
    actions:{
      whiteSpace:"nowrap",
    },
    offerList:{
      '& .MuiIconButton-root':{
          padding:0,
          paddingLeft:5,
          verticalAlign: 'unset',
      },
      '& .MuiSvgIcon-root':{
        width: '0.8em',
        height: '0.8em',
    },
    }
  };
};

class OfferMyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogDelete: false,
    };
  }

  componentDidMount() {
    this.props.onMount('user');
  }
  componentDidUpdate(oldProps) {
    const { $$filters, onFiltersChange } = this.props;
    if ($$filters !== oldProps.$$filters) onFiltersChange();
  }

  onDelete = async (id) => {
    const url = config.offers + '/user_delete';
    let data = { id: id };
    data.csrf = await getToken();
    fetch(url, getFetchOptions('POST', data))
      .then((response) => response.json())
      .then((response) => {
        if (response.delete && response.delete === 'complete') {
          this.props.onMount('user');
        } else if (response.status && response.status === 'error') {
          this.setState({ error: response.log });
        }
      });
  };


  render() {
    const { $$myoffers, $$selects, classes } = this.props;
    const { dialogDelete }=this.state;
    let statusOptions = [];
    let statusValues = {};
    if ($$selects !== undefined) {
      statusOptions = $$selects.get('options').get('offerStatus').toJS();
      statusValues = $$selects.get('values').get('offerStatus').toJS();
    }
    return (
      <Template current="home">
        <OfferUniverse />

        <PageWithHeader title="Mes annonces">
          <OfferProtection type="post">
            
          <div>
            <Box align="center">
              <OfferSelectFilter
                name="offerStatus"
                label="Statut"
                width={250}
                placeholder="Tous"
                options={statusOptions}
              />
              <Box sx={{ marginLeft: '20px' }} component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/offers/new');
                  }}
                >
                  Ajouter une annonce
                </Button>
              </Box>
            </Box>

            <If condition={$$myoffers !== undefined && $$myoffers.size > 0}>
              <Box align="center" mt={3} mb={2} px={2}>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table" className={classes.offerList}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Statut</TableCell>
                        <TableCell align="left">Vues<br/><span style={{whiteSpace:'nowrap'}}>médecins<Tooltip title="Nombre unique de médecins ayant regardé l'annonce depuis le 22/11/2022.">
                          <IconButton><small><HelpOutlineIcon/></small></IconButton>
                        </Tooltip></span></TableCell>
                        <TableCell align="left">Ville</TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Clinique</TableCell>
                        <TableCell align="left">Docteur.e</TableCell>
                        <TableCell align="left">Contrat</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {$$myoffers.map((item) => (
                        <TableRow key={item.get('id')} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell align="left">{statusValues[item.get('offerStatus')]}</TableCell>
                          <TableCell align="center">{item.get('offerViews')}</TableCell>
                          <TableCell align="left">{item.get('offerCity')}</TableCell>
                          <TableCell align="left">
                            <OfferDates item={item} />
                          </TableCell>
                          <TableCell align="left">{item.get('offerInstitutionName')}</TableCell>
                          <TableCell align="left">{<OfferFullname item={item} />}</TableCell>
                          <TableCell align="center">
                            <OfferRetrocession item={item} />
                          </TableCell>
                          <TableCell align="left" className={classes.actions}>
                          <Button
                              variant="contained"
                              color="primary"
                              style={{ marginRight: 5 }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/offers/' + item.get('id'));
                              }}
                            >
                              Voir
                            </Button>
                            <If condition={item.get('offerStatus')=='dra'}>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginRight: 5 }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push('/offers/edit/' + item.get('id'));
                                }}
                              >
                                Modifier
                              </Button>
                                <If condition={dialogDelete==item.get('id')}>
                                  <DialogConfirm
                                    open={true}
                                    onConfirmed={() => {
                                      this.setState({
                                        dialogDelete: false,
                                      });
                                      this.onDelete(item.get('id'));
                                    }}
                                    name="Name"
                                    title="Souhaitez-vous supprimer cette annonce ?"
                                    text=""
                                    textStyle={{ textAlign: 'center' }}
                                    onCanceled={() => {
                                      this.setState({
                                        dialogDelete: false,
                                      });
                                    }}
                                  />
                                </If>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    this.setState({
                                      error: null,
                                      dialogDelete: item.get('id'),
                                    });
                                  }}
                                >
                                  Supprimer
                                </Button>

                            </If>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </If>
        </div>

            
            
            </OfferProtection>
        </PageWithHeader>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$offers: getOffersData(state),
    $$myoffers: getMyOffersData(state),
    $$filters: getFilters(state),
    $$selects: getOfferSelectsData(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      dispatch(fetchOffers(props));
      dispatch(fetchOfferSelects());
    },
    onFiltersChange: () => {
      dispatch(fetchOffers('user'));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OfferMyList)));
