import React, {
  Component
} from "react";
import {
  connect
} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import DynamicList from "components/UI/DynamicList";
import config from "config/api";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import {
  getCurrentUserRole
} from "components/UI/Account/ducks/authentication";
class AdminAnnounceList extends Component {
  render() {
    const {
      userRole,
      eventId
    } = this.props;
    const moduleConfig = {
      module: "event",
      fetchUrl: config.announce + "/list/" + eventId,
      deleteUrl: config.announce + "/delete",
      editUrl: "staff/announce/" + eventId + "/edit",
    };
    // const accessUrl = window.location.protocol+'://'+window.location.host+'/announce/'+eventId;
    return (
      <React.Fragment>
      <Grid item xs={12}>
        <If condition={userRole === "admin"}>
          <Box align="center" mt={3} mb={2} px={2}>
          <Typography variant="h2" gutterBottom color="primary">Annonces</Typography>
          <Button variant="contained" color="primary" onClick={(e) => {this.props.history.push("/staff/announce/"+eventId+"/edit")}}>Ajouter</Button>
        {/*  <Button variant="contained" color="primary" onClick={(e) => {this.props.history.push("/announce/"+eventId)}}>Preview</Button>*/}
          </Box>
          {/*<Box align="center" mt={3} mb={2} px={2}>
            URL d'accès direct :<br />
          {accessUrl}
          </Box>*/}
        </If>
        <DynamicList moduleConfig={moduleConfig} />
        </Grid>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}
export default withRouter(connect(mapStateToProps)(AdminAnnounceList));
