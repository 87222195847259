import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicList from 'components/UI/DynamicList';
import config from 'config/api';
import BlockButton from 'components/UI/BlockButton';
import {
  getCurrentUserRole,
} from 'components/UI/Account/ducks/authentication';

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

class AdminNewsList extends Component {

  render() {
    const {
      userRole,
    } = this.props;
    const moduleConfig = {
      module: 'event',
      fetchUrl: config.news+'/list',
      deleteUrl: config.news+'/delete',
      editUrl: 'staff/news/edit',
    }
    return (
      <div>
        <If condition={userRole === "admin"}>
          <Box align="center" mt={3} mb={2} px={2}>
          <Typography variant="h2" gutterBottom color="primary">News</Typography>

          <BlockButton label="Ajouter" to={"/staff/news/edit"} />
          </Box>
        </If>
        <DynamicList moduleConfig={moduleConfig} />
      </div>

      );
    }
  }

  function mapStateToProps(state) {
    const props = {
      userRole: getCurrentUserRole(state),
    };
    return props;
  }

  export default connect(mapStateToProps)(AdminNewsList);
