import React from "react";
// import Immutable from 'immutable'
import Select from "components/UI/Form/Select";
import SelectBlocks from "components/UI/Form/SelectBlocks";
import Input from "components/UI/Form/Input";
import AutoComplete from "components/UI/Form/AutoComplete";
import Checkbox from "components/UI/Form/Checkbox";
import Upload from "components/UI/Form/Upload";
import Country from "components/UI/Form/Country";
import Captcha from "components/UI/Form/Captcha";
// import isArray  from 'mout/lang/isArray'

// import { reduxForm, Field } from 'redux-form';
// import {
//   Checkbox,
//   RadioButtonGroup,
//   SelectField,
//   TextField,
//   TimePicker,
//   Toggle,
// } from 'redux-form-material-ui'
// import {RadioButton} from '@material-ui/core/RadioButton';
// import TextField from '@material-ui/core/TextField';
// import SelectField from '@material-ui/core/Select';
// import TimePicker from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
// import RadioButton from '@material-ui/core/Radio';

// import MenuItem from '@material-ui/core/MenuItem';
// import AutoComplete from 'material-ui/AutoComplete';
// import DynamicFetchAutoComplete from './DynamicFetchAutoComplete';
// import DynamicFetchAutoCompleteMultiple from './DynamicFetchAutoCompleteMultiple';
// import Avatar from '@material-ui/core/Avatar';

// import DynamicFetchSelect from './DynamicFetchSelect';
// import DynamicUpload from './DynamicUpload';
// import AvatarUpload from './AvatarUpload';
// import TimestampDatePicker from './TimestampDatePicker'
// import TimestampDate from './TimestampDate'
// import StaticField from './StaticField'
// import StaticMultiDateField from './StaticMultiDateField'
// import MultiDateRangePicker from './MultiDateRangePicker'
// import DynamicRadio from './DynamicRadio'

import PropTypes from "prop-types";
import withModuleConfig from "helpers/withModuleConfig";

import areIntlLocalesSupported from "intl-locales-supported";

// import styles from './DynamicForm.cssmodule.scss';
// import Grid from '@material-ui/core/Grid';

/**
 * Use the native Intl.DateTimeFormat if available, or a polyfill if not.
 */
let DateTimeFormat = undefined;
if (areIntlLocalesSupported(["fr"])) {
  DateTimeFormat = global.Intl.DateTimeFormat;
} else {
  // const IntlPolyfill = require('react-intl');
  // DateTimeFormat = IntlPolyfill.DateTimeFormat;
  // require('react-intl/locale-data/fr');
}

// const getStyles = (props, context, state) => {

// Comment passer muiTheme pour le récupérer dans le contexte ?
// Je risque d'en avoir besoin à plusieurs endroits..

// const {
//   baseTheme,
//   textField: {
//     floatingLabelColor,
//     disabledTextColor,
//   }
// } = context.muiTheme;

// const styles = {
// floatingLabel: {
//   color: props.disabled ? disabledTextColor : floatingLabelColor,
//   pointerEvents: 'none',
// },
// radioButton: {
//   display: 'inline-block',
//   width: 'auto',
//   marginRight: 30
// }
//
// };
// console.log('computed styles', styles)
// return styles;
// };

// const validateRequired = value => value == null ? 'Requis' : undefined
//
// const validateEmail = value => value &&
// !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Identifiant invalide' : undefined
//
// // A faire proprement...
// const validatePhone = (value) => {
//   value = value.replace(/[_ +]/, '');
//   return true;
// }

// A faire proprement... mais comment récupérer la max value ? :/ récupérer avec un validateMaxLength.bind(this, value) ?
// const validateMaxLength = (value) => {
//
// }
// Idem ici
// const validateMinLength = (value) => {
//
// }

// class DynamicLabel extends React.Component {
//   // static contextTypes = {
//   //   muiTheme: PropTypes.object
//   // }
//
//   render() {
//     const {
//       label,
//       required,
//       classes
//     } = this.props;
//
//     // const styles = getStyles(this.props, this.context, this.state);
//
//
//     return (
//       <span className={styles.label} classes={{root: classes.floatingLabel}}>
//         {label} {required ? ' *' : ''}
//       </span>
//     );
//   }
// }

class DynamicField extends React.Component {
  static contextTypes = {
    muiTheme: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this._onFieldValueChange = this._onFieldValueChange.bind(this);
  }
  componentDidMount() {
    // console.log('test mounted', this.props.row.get('id'), this.props.value)
    const {value, row} = this.props;
    if (value !== undefined && ((row.get("type") === "select" || row.get("type") === "SelectField") && row.get('categoryTrigger') && this.props.onCategoryTrigger)) {
      // console.log('triggered cdm', row.get('id'))
      this.props.onCategoryTrigger(row.get('id'), this.props.value, row.get("categoryTrigger"));
    } else {
      // console.log(' ignored cdm', row.get('id'))
    }

  }
  componentDidUpdate(oldProps) {
    if (oldProps.value === undefined && this.props.value !== undefined) {
      const {row} = this.props;
      if ((row.get("type") === "select" || row.get("type") === "SelectField") && row.get('categoryTrigger') && this.props.onCategoryTrigger) {
        // console.log('triggered cdu', row.get('id'))
        this.props.onCategoryTrigger(row.get('id'), this.props.value, row.get("categoryTrigger"));
      } else {
        // console.log(' ignored cdu', row.get('id'))
      }
    }
  }

  _onFieldValueChange(kind, target) {
    // this.props.onChange(this.props.row.get('type'), {
    //   id: e.target.id,
    //   value: e.target.value
    // })
    // console.log('changed', target.id, target.value)
    const { row, onCategoryTrigger } = this.props;
    if (row.get("type") === "select" || row.get("type") === "SelectField" || row.get("categoryTrigger")) {
      if (onCategoryTrigger)
        onCategoryTrigger(target.id, target.value, row.get("categoryTrigger"));
    }
    if (!target.id) console.log("missing id", target.value);
    // console.log(kind, target)
    this.props.onChange(kind, target);
  }
  _getValidators(validators) {
    // console.log('current validators', validators)
    // var methods = [];
    // Comment itérer sur les validators pour renvoyer un array de methodes du style:
    //  return [required, email];
    // return (validators.map((att, idx) => {
    // switch(idx) {
    //   case 'required':
    //     methods.push(validateRequired);
    //   break;
    //   case 'email':
    //     methods.push(validateEmail);
    //   break;
    // }
    //
    // }).toArray())
  }

  getLabel(label, required) {
    return label + (required ? " *" : "");
  }
  render() {
    const {
      // idx,
      row,
      // module,
      // $$linkMap,
      // moduleConfig,
      disabled,
      readOnly,
      erroredFields,
      variant,
      // fieldVisibility,
      // onChange,
      value,
    } = this.props;
    // const disabled = true;
    // if (row.get('multiple') === true)
    // console.log(row.get('id'), value)
    let minDate;
    if (row.get("type") === "DatePicker") {
      minDate = new Date();
      if (row.get("minDate") !== undefined) {
        const md = row.get("minDate").toJS();
        minDate.setFullYear(md[0], md[1], md[2]);
      }
      minDate.setHours(0, 0, 0, 0);
      // console.log('mindate', minDate.getFullYear(), minDate.getMonth(), minDate.getDay());
    }

    // const muiStyle = getStyles(this.props, this.context, this.state);
    // return (
    //   <Grid item xs={12} md={row.get('fullSize') == true ? 12 : 6} lg={row.get('fullSize') == true ? 12 : 4}>
    //
    //     <Choose>
    //       <When condition={row.get('type') == 'Static' && !row.get('multiDate')}>
    //         <Field name={row.get('id')}
    //             component={StaticField}
    //             floatingLabelText={this.getLabel(row.get('label'))}
    //             multiple={row.get('multiple')}
    //             orgType={row.get('orgType')}
    //             initialOptions={$$linkMap.get(row.get('id'), Immutable.List()).toJS()}
    //             style={row.get('fullWidth') ? {width: '100%'} : {}}
    //             />
    //       </When>
    //       <When condition={row.get('type') == 'Static' && row.get('multiDate')}>
    //         <p>disabled StaticMultiDateField</p>
    //         {/*<Field name={row.get('id')}
    //             component={StaticMultiDateField}
    //             floatingLabelText={this.getLabel(row.get('label'))}
    //             multiple={row.get('multiple')}
    //             initialOptions={$$linkMap.get(row.get('id'), Immutable.List()).toJS()}
    //             />
    //             */}
    //       </When>
    //       <When condition={row.get('type') == 'TextField'}>
    //         <Field name={row.get('id')}
    //           component={TextField}
    //           floatingLabelText={this.getLabel(row.get('label'), row.get('required'))}
    //           maxLength={row.get('maxLength')}
    //           minLength={row.get('minLength')}
    //           hintText={row.get('placeholder')}
    //           validate={this._getValidators(row.get('validators'))}
    //           multiple={row.get('multiple')}
    //           disabled={disabled}
    //           style={row.get('fullWidth') === true? {width: '100%'} : {}}
    //           />
    //       </When>
    //       <When condition={row.get('type') == 'Password'}>
    //         <Field name={row.get('id')}
    //           component={TextField}
    //           type="password"
    //           floatingLabelText={this.getLabel(row.get('label'), row.get('required'))}
    //           maxLength={row.get('maxLength')}
    //           minLength={row.get('minLength')}
    //           hintText={row.get('placeholder')}
    //           validate={this._getValidators(row.get('validators'))}
    //           disabled={disabled}
    //           />
    //
    //         <div className={styles.altRow}>
    //           <Field name={row.get('id')+'@@conf'}
    //             component={TextField}
    //             type="password"
    //             floatingLabelText={this.getLabel(row.get('label_alt'), row.get('required'))}
    //             maxLength={row.get('maxLength')}
    //             minLength={row.get('minLength')}
    //             hintText={row.get('placeholder')}
    //             validate={this._getValidators(row.get('validators'))}
    //             disabled={disabled}
    //             />
    //         </div>
    //       </When>
    //       <When condition={row.get('type') == 'TextArea'}>
    //         <Field name={row.get('id')}
    //           component={TextField}
    //           type="password"
    //           floatingLabelText={this.getLabel(row.get('label'), row.get('required'))}
    //           maxLength={row.get('maxLength')}
    //           minLength={row.get('minLength')}
    //           hintText={row.get('placeholder')}
    //           validate={this._getValidators(row.get('validators'))}
    //           multiLine={true}
    //           rows={3}
    //           disabled={disabled}
    //           style={row.get('fullWidth') ? {width: '100%'} : {}}
    //           />
    //
    //       </When>
    //       <When condition={row.get('type') == 'SelectField'}>
    //         <Field name={row.get('id')}
    //           component={SelectField}
    //           floatingLabelText={this.getLabel(row.get('label'), row.get('required'))}
    //           hintText={row.get('placeholder')}
    //           validate={this._getValidators(row.get('validators'))}
    //           multiple={row.get('multiple')}
    //           autoWidth={true}
    //           className={styles.select}
    //           disabled={disabled}
    //           style={row.get('fullWidth') ? {width: '100%', whiteSpace: 'nowrap'} : {whiteSpace: 'nowrap'}}
    //           >
    //           {row.get('options').map((label, idx) =>
    //             <MenuItem key={idx} value={idx} primaryText={label} />,
    //           ).toArray()}
    //         </Field>
    //
    //       </When>
    //       <When condition={row.get('type') == 'AutoComplete'}>
    //         <p>Field type AutoComplete is disabled!</p>
    //         {/*
    //         <Field
    //           component={DynamicFetchAutoComplete}
    //           name={row.get('id')}
    //           floatingLabelText={this.getLabel(row.get('label'), row.get('required'))}
    //           initialOptions={$$linkMap.get(row.get('id'), Immutable.List()).toJS()}
    //           multiple={true}
    //           url={row.get('url')}
    //           $$filterFields={row.get('filterFields')}
    //           filtersRequired={/*row.get('filtersRequired')*/ row.get('filterFields') !== undefined}
    //           disabled={disabled}
    //           />
    //         */}
    //       </When>
    //       <When condition={row.get('type') == 'AutoCompleteMultiple'}>
    //         <p>Field type AutoCompleteMultiple is disabled!</p>
    //           {/*
    //         <Field
    //           component={DynamicFetchAutoCompleteMultiple}
    //           name={row.get('id')}
    //           fieldName={row.get('id')}
    //           initialOptions={$$linkMap.get(row.get('id'), Immutable.List()).toJS()}
    //           floatingLabelText={this.getLabel(row.get('label'), row.get('required'))}
    //           url={row.get('url')}
    //           disabled={disabled}
    //           />
    //           */}
    //       </When>
    //       <When condition={row.get('type') == 'DatePicker'}>
    //         <Field name={row.get('id')}
    //           component={TimestampDate}
    //           format={null}
    //           floatingLabelText={this.getLabel(row.get('label'), row.get('required'))}
    //           hintText={row.get('placeholder')}
    //           validate={this._getValidators(row.get('validators'))}
    //           className={styles.date}
    //           autoOk={true}
    //           container="inline"
    //           minDate={minDate}
    //           DateTimeFormat={DateTimeFormat}
    //           cancelLabel="Fermer"
    //           locale="fr"
    //           disabled={disabled}
    //           />
    //       </When>
    //       <When condition={row.get('type') == 'MultiDateRangePicker'}>
    //         <Field name={row.get('id')}
    //           component={MultiDateRangePicker}
    //           format={null}
    //           floatingLabelText={this.getLabel(row.get('label'), row.get('required'))}
    //           hintText={row.get('placeholder')}
    //           validate={this._getValidators(row.get('validators'))}
    //           className={styles.date}
    //           autoOk={true}
    //           container="inline"
    //           minDate={minDate}
    //           DateTimeFormat={DateTimeFormat}
    //           cancelLabel="Fermer"
    //           locale="fr"
    //           disabled={disabled}
    //           allowBeforeCurrentMonth={row.get('allowBeforeCurrentMonth')}
    //           />
    //       </When>
    //       <When condition={row.get('type') == 'TimePicker'}>
    //         <Field name={row.get('id')}
    //           component={TimePicker}
    //           format={null}
    //           floatingLabelText={this.getLabel(row.get('label'), row.get('required'))}
    //           hintText={row.get('placeholder')}
    //           validate={this._getValidators(row.get('validators'))}
    //           className={styles.date}
    //           autoOk={true}
    //           format={null}
    //           props={{format: "24hr"}}
    //           cancelLabel="Fermer"
    //           disabled={disabled}
    //           />
    //       </When>
    //       <When condition={row.get('type') == 'RadioButton'}>
    //         <DynamicLabel label={row.get('label')} required={row.get('required')}/>
    //         <Field name={row.get('id')}
    //           component={DynamicRadio}
    //           hintText={row.get('placeholder')}
    //           validate={this._getValidators(row.get('validators'))}
    //           label={row.get('label')}
    //           disabled={disabled}
    //           style={{...this.props.style, maxWidth: 130, marginBottom: 20}}
    //           name={row.get('id')}
    //           defaultValue={row.get('tf_default')}
    //           options={row.get('options')}
    //           />
    //       </When>
    //       <When condition={row.get('type') == 'Avatar'}>
    //         <Field name={row.get('id')}
    //           previewUrl={row.get('previewUrl')}
    //           component={AvatarUpload}
    //           acceptedMime={row.get('mime')}
    //           url={row.get('url')}
    //           disabled={disabled}
    //           instructions={row.get('instructions')}
    //           />
    //       </When>
    //       <When condition={row.get('type') == 'Files' || row.get('type') == 'File'}>
    //         <Field name={row.get('id')}
    //           previewUrl={row.get('previewUrl')}
    //           component={DynamicUpload}
    //           multiple={row.get('multiple')}
    //           acceptedMime={row.get('mime')}
    //           url={row.get('url')}
    //           tmpUrl={row.get('tmpUrl')}
    //           disabled={disabled}
    //           readOnly={(disabled || readOnly || row.get('readOnly') ? true : false)}
    //           />
    //       </When>
    //     </Choose>
    //   </Grid>
    // );
    // return <p>hello</p>

    // value = $$formValues.get(row.get('name')) !== undefined ? $$formValues.get(row.get('name')) : undefined;
    // console.log('ROW IS', row.toJS())
    // console.log('ROW IS', row.get('type'), row.get('static'), row.toJS())
    let required = false;
    if (row.get("validators") !== undefined) {
      // console.log(row.get('validators').toJS())
      required =
        row.get("validators").keySeq().toArray().indexOf("notEmpty") >= 0;
      // console.log('R IS', r);
    }
    // required = true;
    // const required = (row.get('validators') !== undefined && row.get('validators').find && row.get('validators').find('notEmpty') !== undefined);

    // return <Grid item xs={(row.get('sizes') && row.get('sizes').get('xs')) ? row.get('sizes').get('xs') : 12}  className={(fieldVisibility === true ? 'field' : 'hidden')}
    //   >
    // console.log(erroredFields)
    return (
      <Choose>
        <When condition={row.get("type") === "Checkbox"}>
          <Checkbox
            name={row.get("id")}
            id={row.get("id")}
            value={value}
            label={row.get("label")}
            onChange={this._onFieldValueChange}
            options={row.get("options")}
            defaultValue={row.get('defaultValue')}
            disabled={
              (row.get("static") !== undefined
                ? row.get("static")
                : (disabled
                  ? true
                  : undefined))
            }
            multiple={row.get("multiple")}
            required={required}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
          />
        </When>

        <When condition={row.get("type") === "upload"}>
          <Upload
            name={row.get("id")}
            id={row.get("id")}
            value={value}
            onChange={this._onFieldValueChange}
            label={row.get("label")}
            previewUrl={row.get("previewUrl")}
            previewTmpUrl={row.get("previewTmpUrl")}
            maxFiles={row.get("maxFiles")}
            multiple={row.get("multiple")}
            acceptedMime={row.get("mime")}
            uploadAttributes={row.get("uploadAttributes")}
            url={row.get("url")}
            tmpUrl={row.get("tmpUrl")}
            required={required}
            disabled={disabled}
            readOnly={
              disabled || readOnly || row.get("readOnly") ? true : false
            }
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
          />
        </When>
        <When
          condition={
            row.get("type") === "select" || row.get("type") === "SelectField"
          }
        >
          <Select
            id={row.get("id")}
            value={value}
            label={row.get("label")}
            defValue={row.get("defValue")}
            onChange={this._onFieldValueChange}
            options={row.get("options")}
            disabled={
              row.get("static") !== undefined
                ? row.get("static")
                : disabled
                  ? true
                  : undefined
            }
            multiple={row.get("multiple")}
            required={required}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
          />
        </When>
        <When
          condition={
            row.get("type") === "SelectBlocks"
          }
        >
          <SelectBlocks
            id={row.get("id")}
            value={value}
            label={row.get("label")}
            defValue={row.get("defValue")}
            onChange={this._onFieldValueChange}
            options={row.get("options")}
            align={row.get("align")}
            disabled={
              row.get("static") !== undefined
                ? row.get("static")
                : disabled
                ? true
                : undefined
            }
            multiple={true}
            required={required}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            showLabel={true}
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
          />
        </When>
        <When condition={row.get("type") === "AutoComplete"}>
          <AutoComplete
            id={row.get("id")}
            value={value}
            type={row.get("typeVariant")}
            onChange={this._onFieldValueChange}
            label={row.get("label")}
            autoComplete={row.get("autoComplete")}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            helperText={row.get("helperText")}
            multiple={row.get("multiple")}
            disabled={row.get("static") || disabled}
            url={row.get("url")}
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
            dateTimeFormat={DateTimeFormat}
            required={required}
          />
        </When>
        <When condition={row.get("type") === "Country"}>
          <Country
            id={row.get("id")}
            value={value}
            type={row.get("typeVariant")}
            onChange={this._onFieldValueChange}
            label={row.get("label")}
            autoComplete={row.get("autoComplete")}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            helperText={row.get("helperText")}
            disabled={row.get("static") || disabled}
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
            dateTimeFormat={DateTimeFormat}
            required={required}
          />
        </When>
        <When condition={row.get("type") === "Captcha"}>
          <Captcha
            id={row.get("id")}
            value={value}
            type={row.get("typeVariant")}
            onChange={this._onFieldValueChange}
            label={row.get("label")}
            autoComplete={row.get("autoComplete")}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            helperText={row.get("helperText")}
            disabled={row.get("static") || disabled}
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
            required={required}
          />
        </When>
        <Otherwise>
          <Input
            id={row.get("id")}
            value={value !== null ? value : ""}
            type={row.get("typeVariant")}
            onChange={this._onFieldValueChange}
            label={row.get("label")}
            autoComplete={row.get("autoComplete")}
            error={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
            }
            errorMessage={
              erroredFields[row.get("id")] !== undefined &&
              erroredFields[row.get("id")] !== false
                ? erroredFields[row.get("id")]
                : false
            }
            maxLength={row.get("maxLength")}
            multiline={row.get("multiline")}
            helperText={row.get("helperText")}
            placeholder={row.get('placeholder')} //AJOUT B
            disabled={row.get("static") || disabled}
            rows={row.get("rows")}
            variant={
              row.get("variant") !== undefined ? row.get("variant") : variant
            }
            dateTimeFormat={DateTimeFormat}
            required={required}
          />
        </Otherwise>
      </Choose>
    );
  }
}
export default withModuleConfig(DynamicField);
