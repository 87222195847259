import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import config from 'config/api';
import i18n from 'helpers/i18n';
import { fetchData, getDataHome, getSubscriptions,  } from 'ducks/asyncFetcher';
import { fetchEvents, clearEvents,getEventsData, getReplaysData, getEventsKindData } from 'components/UI/Event/ducks/event';
import isSubscribed from 'components/UI/helpers/isSubscribed';
import Spinner from 'components/UI/Spinner/Spinner';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import EventCard from 'components/Event/EventCard';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => {
  return {
    root: {
      //maj fullwidth
      //maxWidth: 1280,
      //marginLeft: 'auto',
      //marginRight: 'auto',
      marginLeft: '0',
      marginRight: '0',
      //


      marginBottom: theme.spacing(4),
      '& .MuiTypography-h3': {
        paddingRight: 4,
        paddingLeft: 22,
        marginBottom: theme.spacing(2),
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.1rem'
      },
      '& .MuiMobileStepper-dot': {
        marginLeft: '4px',
        marginRight: '4px',
      }

    },
    badge: {
      top: '50%',
      right: '-16px'
    },

    mobileStepper: {
      marginTop: 0,
      paddingTop: 0,
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        margin: 0,
      }
    },
    navigation: {
      color: theme.palette.primary.main,
      '& .MuiIconButton-label': {
        textAlign: 'center',
        width: 17.59,
        justifyContent: 'center',
        padding: 0
      },
    },
    invisible: {
      visibility: 'hidden'
    },
  };
};

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -30,
    top: '50%',
    width: 30,
    height: 30,
    fontSize: '1rem',
    borderRadius: '180px'
  },
}))(Badge);

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      groupStep: 3
    }
  }

  componentDidMount() {
    const {kind} = this.props;
    // console.log('mount', kind)
    this.props.onMount({kind})
    // if (this.props.onCounterChange !== undefined && kind === 'events') this.props.onCounterChange(kind, this.props.$$availableEvents.size);
    // if (this.props.onCounterChange !== undefined && kind === 'replays') this.props.onCounterChange(kind, this.props.$$replayEvents.size);
  }

  
  componentDidUpdate(oldProps) {
    const { kind } = this.props;
    // console.log(kind, oldProps, this.props, this.props.$$availableEvents, this.props.$$replayEvents)
    /*
    if (oldProps.$$availableEvents !== this.props.$$availableEvents && kind === 'events') {
      // console.log('available events are ',kind, this.props.$$availableEvents.size)
      if (this.props.onCounterChange !== undefined) {
        if (this.props.$$availableEvents !== undefined)
          this.props.onCounterChange(kind, this.props.$$availableEvents.size);
        else
          this.props.onCounterChange(kind, 0);
      }
    }
    if (oldProps.$$replayEvents !== this.props.$$replayEvents && kind === 'replays') {
      // console.log('replay events are ',kind, this.props.$$replayEvents.size)
      if (this.props.onCounterChange !== undefined) {
        if (this.props.$$replayEvents !== undefined)
          this.props.onCounterChange(kind, this.props.$$replayEvents.size);
        else
          this.props.onCounterChange(kind, 0);
      }
    }
    */

    if (oldProps.$$events !== this.props.$$events) {
      if (this.props.onCounterChange !== undefined) {
        if (this.props.$$events !== undefined)
          this.props.onCounterChange(kind, this.props.$$events.size);
        else
          this.props.onCounterChange(kind, 0);
      }
    }

    // if (oldProps.universe !== undefined && oldProps.universe !== this.props.universe) {
    //   console.log('reloading eventlsit', this.props.universe)
    //   this.props.onMount({kind: this.props.kind})
    // }
  }
  

  componentWillUnmount() {
    // console.log('unmount')
    this.props.onUnmount({kind: this.props.kind})
  }

  gotoEvent = (e, $$event) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/event/' + $$event.get('id'));
  }

  gotoLive = (e, $$event) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/live/' + $$event.get('id'));
  }

  render() {
    const { title, titleStyle, showCount, classes, kind, $$availableEvents, $$replayEvents, eventCardClasses, $$subscriptions, itemFilter, $$events } = this.props;
    //console.log({kind});
    //console.log('$$events', $$events ? $$events.toJS() : $$events);
    const variant=this.props.variant!==undefined ? this.props.variant : 'vertical';
    //const $$items = kind === 'events' ? $$availableEvents : $$replayEvents;
    const $$items = $$events;
    const direction = 'regular';
    const { activeStep, groupStep } = this.state;
    // const maxSteps = 2;
    const maxSteps = $$items !== undefined ? Math.ceil($$items.size/groupStep) : 0;

    // const autoPlay = maxSteps > 1;
    const autoPlay = false;
    const gridSize=variant=='horizontal' ? {sm: 12, md:12 } : {sm: 6, md:4 }

    /*
    if ($$items !== undefined && $$items.size === 1)
      gridSize = {sm: 6, md:4 };
    else if ($$items !== undefined && $$items.size === 2)
      gridSize = {sm: 6, md:4 };
    else
      gridSize = {sm: 6, md:4 };
    */

// if ($$items !== undefined)
// console.log(kind, $$items.toJS())
    if ($$items === undefined || $$items.size === 0)
    return <span></span>
    return (
      <Grid
        container
        alignItems="center"
        justify="flex-start"
        className={classes.root}
        ref={r => { this.$grid = r}}
        >
        <If condition={title !== undefined}>
        <Grid item xs={12}>
          <If condition={showCount === true}>
            <Typography variant="h3" style={{titleStyle}}><StyledBadge badgeContent={$$items !== undefined ? $$items.size : 0} color="primary">{title}</StyledBadge></Typography>
          </If>
          <If condition={showCount !== true}>
            <Typography variant="h3" style={{titleStyle}}>{title}</Typography>
          </If>
        </Grid>
      </If>
        <Choose>
          <When condition={$$items !== undefined && $$items.size > 0}>
            <Grid item xs={12} container align="flex-start">
              {$$items.filter($$item => { return itemFilter !== undefined ? itemFilter($$item) : $$item }).map(($$item, idx) => {
                return (
                  <Grid  key={'i_'+idx} item xs={12} sm={gridSize.sm} md={gridSize.md}><EventCard itemIndex={idx} $$item={$$item} kind={kind} classes={eventCardClasses} variant={variant} isSubscribed={isSubscribed($$item.get('id'), $$subscriptions)}/></Grid>);
                })
                .toArray()}
              </Grid>
            </When>
            <Otherwise>
              <Spinner>{i18n.get('loading')}</Spinner>
            </Otherwise>
          </Choose>

        </Grid>
      );
    }
  }
  function mapStateToProps(state, ownProps) {
    const props = {
      //$$data: getDataHome(state),
      $$subscriptions: getSubscriptions(state),
      //$$availableEvents: getEventsData(state),
      //$$replayEvents: getReplaysData(state),
      $$events: getEventsKindData(state, ownProps.kind),
      // $$user: getCurrentUser(state),
    };
    return props;
  }
  function mapDispatchToProps(dispatch) {
    return {
      onMount: (props) => {
        // dispatch(fetchData({ key: 'home', fetchUrl: config.home }));
        dispatch(fetchEvents({ kind: props.kind }));
      },
      onUnmount: (props) => {
        dispatch(clearEvents({kind: props.kind}))
      }
    };
  }

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventList)));
