import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => {
  return {
    root: {
    },
    loadingCircle:{
      marginRight:'25px',
      verticalAlign:'middle',
      width:'500px',
    },
    loadingText:{
      display:'inline-block',
    },
  };
});

export default function Loading({size=30, text=true, inline=false}) {
  const classes = useStyles();

  return (
    <Box className={classes.root} style={{
      display:inline ? 'inline-block' : 'block',
      margin:inline ? '0 0 0 1em' : '5%',
    }}>
        <CircularProgress className={classes.loadingCircle} size={size}/> 
        <If condition={text}>
          <Box className={classes.loadingText}>Chargement ...</Box>
        </If>
    </Box>
  );
}
