import React from "react";
import { connect } from "react-redux";
import Faq from "./Faq";
import { makeSelectors, makeActionCreators } from "ducks/dynamicList";
import { defaultMemoize as memoize } from "reselect";
// import withStyles from 'helpers/withStyles'

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  bullet: {
    backgroundColor: "transparent",
    color: "#EB9250",
    fontSize: "3em",
  },
  list: {
    maxWidth: "33.33%",
    margin: "0 auto 20px",
    display: "block",
  },
  listItem: {
    width: "100%",
  },
  listItemText: {
    fontSize: "1.4em",
  },
};

const mapStateToProps = () => {
  const getSelectors = memoize(makeSelectors);
  return (state, ownProps) => {
    const selectors = getSelectors(ownProps.listId);
    return {
      loaded: selectors.getIsDataLoaded(state),
      $$fields: selectors.getFields(state),
      $$rows: selectors.getFilteredRows(state),
      $$actions: selectors.getActions(state),
      $$filters: selectors.getFilters(state),
      $$apparence: selectors.getApparence(state),
      // $$toDelete: selectors.getToDelete(state),
      readOnly: selectors.isReadOnly(state),
      $$linkMap: selectors.getLinkMap(state),
      currentPage: selectors.getCurrentPage(state),
      pageCount: selectors.getPageCount(state),
    };
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  const actionCreators = makeActionCreators(ownProps.listId);
  return {
    onMount: (moduleConfig) => dispatch(actionCreators.initList(moduleConfig)),
    onUnmount: () => dispatch(actionCreators.removeList()),
    // onTextFilterChange: (event, newValue) => dispatch(actionCreators.setTextFilter(newValue)),
    onDeleteRow: (moduleConfig, id) =>
      dispatch(actionCreators.deleteRow(moduleConfig, id)),
    onSetRowFieldData: (rowId, fieldId, value) =>
      dispatch(actionCreators.setRowFieldValue(rowId, fieldId, value)),
    onSetRowApparence: (rowId, value) =>
      dispatch(actionCreators.setRowApparence(rowId, value)),
    onOrderChange: (orderBy, orderWay) =>
      dispatch(
        actionCreators.changeOrder(ownProps.moduleConfig, orderBy, orderWay)
      ),
    onFilterChange: (filterBy, filterValue) =>
      dispatch(
        actionCreators.changeFilter(
          ownProps.moduleConfig,
          filterBy,
          filterValue
        )
      ),
  };
}

const Container = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Faq)
);

let nextId = 0;
export default class FaqtWithDynamicId extends React.Component {
  constructor(props) {
    super(props);
    this.listId = "faq" + nextId;
    nextId++;
  }

  render() {
    const listId = `list-${this.listId}`;
    const { props } = this;

    return <Container {...props} {...{ listId }} />;
  }
}
