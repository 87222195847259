import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import clsx from 'clsx';
import i18n from 'helpers/i18n';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom';
import { isLoggedIn, getCurrentUserRole } from 'components/UI/Account/ducks/authentication';

const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
      width: '100%',
    },
    pageTitle: {
      paddingTop: 16,
      paddingLeft: '1em',
      textAlign: 'left',
    },
    subTitle: {
      paddingTop: '1em',
      fontSize: '30%',
    },

    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.primary.light,
        zIndex: -1,
      },
    },
    paper: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& a': {
        color: 'inherit',
      },
    },

    mainWrapper: {
      position: 'relative',
      zIndex: 9,
      color: '#fff',
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        // marginTop: "-270px",
      },
    },
    mainTitle: {
      textAlign: 'center',
      marginBottom: 40,
    },
    livePaper: {
      color: '#fff',
      margin: '0 auto',
      padding: '40px 20px 50px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 20px',
      },
    },
    legend: {
      marginBottom: 30,
    },
  };
};
class NotConnected extends Component {
  _redirectToLogin = (action) => {
    let pn = window.location.pathname;
    if (pn[pn.length - 1] === '/') {
      pn = pn.substring(0, pn.length - 1);
    }
    let returnUrl = pn + window.location.search;
    returnUrl = encodeURIComponent(returnUrl);
    this.props.history.push('/login?action=' + action + '&_pn=' + returnUrl);
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper square elevation={0} className={classes.paper}>
        <Box my={6} align="center">
          <Typography variant="h5" gutterBottom={true} className={clsx(classes.legend, classes.h6extends)}>
            Merci de vous connecter pour accéder à ce contenu
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '0 5px' }}
            onClick={(e) => this._redirectToLogin('login')}
          >
            {' '}
            {i18n.get('loginAction', { pretty: false })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '0 5px' }}
            onClick={(e) => this._redirectToLogin('register')}
          >
            {' '}
            {i18n.get('subscribeAction', { pretty: false })}
          </Button>
        </Box>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
    userRole: getCurrentUserRole(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {};
}

// export default ;
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotConnected)),
);
