import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


class DynamicFormHelp extends React.Component {

  render() {

    return (
      <Box>
      <Typography variant={'h5'}>Notice:</Typography>
        <h1 style={{fontSize: '110%', display: 'inline'}}>Titre:</h1> [title]Hello World![/title]<br />
        <strong>Gras:</strong> [b]Hello World![/b]<br />
        <i>Italic:</i> [i]Hello World![/i]<br />
        <small>Petit:</small> [small]Hello World![/small]<br />
        <u>Souligné:</u> [u]Hello World![/u]<br />
        <li style={{display: 'inline'}}>Puces:</li> [*]Hello World!<br />
        <sup>Expo:</sup> [sup]Hello World![/sup]<br />
        <Typography color="secondary" style={{display: 'inline'}}>Couleur secondaire:</Typography> [altColor]Hello World![/altColor]<br />
        <span style={{color: '#ff0000'}}>Couleur custom:</span> [color=#ff0000]Hello World![/color]<br />
        <a style={{color: 'inherit'}}>Liens HTML</a> [link=https://google.com]Hello World![/link]<br />

      </Box>);
  }
}
export default DynamicFormHelp;
