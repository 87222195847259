import React from 'react';
import PropTypes from 'prop-types';

export default function withStyles(subComponent) {
  const Style = function (props, context) {
    const { styles } = context;
    const newProps = {
      ...props,
      styles,
    };
    return React.createElement(subComponent, newProps);
  };

  Style.contextTypes = {
    styles: PropTypes.object,
  };

  return Style;
}
