import React, { Component } from "react";
// import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import Immutable from "immutable";
// import clsx from "clsx";
// import queryString from "query-string";
import getFetchOptions from "helpers/getFetchOptions";
// import Dialog from "components/UI/Dialog/Dialog";
import DialogConfirm from "components/UI/Dialog/DialogConfirm";

// import getCardImage from 'helpers/getCardImage'
// import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
// import remove from "mout/array/remove";
import config from "config/api";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
// import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
// import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
// import BlockTitle from "components/UI/BlockTitle";
// import Rating from "@material-ui/lab/Rating";
// import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
// import SocketHelper from "components/UI/Socket/SocketHelper";
// import Feedback from "components/UI/Feedback/FeedbackEmbed";
// import Template from "components/Template/Template";
import Announce from "components/UI/Announce/AnnounceEmbed";
// import AdminMessageGrid from "components/UI/Message/AdminMessageGrid";
// import AdminOrchestratorBlock from "./AdminOrchestratorBlock";
import Select from "components/UI/Form/Select";
import { withRouter } from "react-router-dom";
// import {
//   getFeedbackReset,
//   resetFeedback,
// } from "components/UI/Feedback/ducks/feedback";
import {
  // setAnnounceManagerOption,
  fetchAnnounces,
  getAnnouncesData,
  clearAnnounceData,
  // getAnnounceManagerResults,
  // clearAnnounceQuestions,
  // clearAnnounceManagerResults,
  // getAnnounceResults,
  // resetAnnounceQuestion,
} from "components/UI/Announce/ducks/announce";
// import {
//   setAnnounceManagerOption,
//   fetchAnnounces,
//   getAnnounceQuestions,
//   getAnnounceManagerResults,
//   clearAnnounceQuestions,
//   clearAnnounceManagerResults,
//   getAnnounceResults,
//   resetAnnounceQuestion,
// } from "components/UI/Announce/ducks/announce";
import {
  // isLoggedIn,
  // getCurrentUserRole,
  getCurrentUser,
  getToken,
} from "components/UI/Account/ducks/authentication";
import {
  sendMessage,
  // getContent,
  setEventState,
  getEventState,
  setAdminEventState,
  getAdminEventState,
  fetchEventState,
} from "components/UI/Socket/ducks/relay";
// qid: 'c6b5279e681b7866afcc94d5fef27a3a',
// import i18n from "helpers/i18n";
// const AF_KEY = "home";
const USE_WEBSOCKET = false;
const styles = (theme) => {
  // console.log(theme)
  // theme.palette.contrastThreshold = 0.1
  return {
    root: {
      minWidth: 500,
      "& .MuiDivider-vertical": {
        marginLeft: 10,
        marginRight: 10,
      },
      "& .MuiButton-root": {
        "& .MuiButton-label": {
          [theme.breakpoints.down("md")]: {},
        },
      },
    },
    popinPlaceholder: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.dialog && theme.palette.dialog.backgroundColor ? theme.palette.dialog.backgroundColor : undefined,
      color: theme.palette.dialog && theme.palette.dialog.color ? theme.palette.dialog.color : undefined,
    },
    leftColumn: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: theme.spacing(2),
      },
    },
    rightColumn: {
      [theme.breakpoints.down("md")]: {
        "& .MuiButton-root": {
          "& .MuiButton-label": {
            // padding: 0,
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        "& .MuiButton-root": {
          "& .MuiButton-label": {
            padding: 0,
            fontSize: "0.6rem",
          },
        },
      },
    },
    onAir: {
      fontWeight: "bold",
      marginBottom: 40,
      border: "1px " + theme.palette.primary.main + " solid",
      display: "inline-block",
      padding: "5px 10px",
    },
    paperWrapper: {
      paddingLeft: 20,
      paddingRight: 20,
      margin: "20px auto",
      maxWidth: 1200,
      width: "100%",
      padding: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
    questionWrapper: {
      marginBottom: theme.spacing(2),
    },
    question: {
      boxSizing: "inherit",
      cursor: "pointer",
      "& .MuiTypography-subtitle1": {
        fontSize: "1.2rem",
        lineHeight: 1.85,
        color: "inherit",
        textAlign: "right",
      },
      "& .MuiTypography-subtitle2": {
        fontSize: "1.2rem",
        lineHeight: 1.85,
        color: "inherit",
      },
      border: "1px " + theme.palette.secondary.light + " solid",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-body1": {
        color: "inherit",
      },
    },
    selected: {
      cursor: "default",
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: theme.palette.secondary.light,
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.secondary.light
        ),
      },
    },
    // correct: {
    //   backgroundColor: theme.palette.success.dark,
    //   color: theme.palette.getContrastText(theme.palette.success.dark),
    //   '& .MuiLinearProgress-colorPrimary': {
    //     backgroundColor: theme.palette.success.light
    //   },
    //   '& .MuiLinearProgress-barColorPrimary': {
    //     backgroundColor: theme.palette.getContrastText(theme.palette.success.dark)
    //   }
    // },
    // incorrect: {
    //   backgroundColor: theme.palette.error.dark,
    //   color: theme.palette.getContrastText(theme.palette.error.dark),
    //   '& .MuiLinearProgress-colorPrimary': {
    //     backgroundColor: theme.palette.error.light
    //   },
    //   '& .MuiLinearProgress-barColorPrimary': {
    //     backgroundColor: theme.palette.getContrastText(theme.palette.error.dark)
    //   }
    // },
    actions: {
      minHeight: 54,
    },
    log: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    // percentage: {
    //   fontSize: '1.6rem'
    // }
  };
};
class AdminOrchestratorAnnounce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: [],
      answered: undefined,
      logMessage: false,
      errored: false,
      dialogDeleteState: undefined,
    };
  }
  componentDidMount() {
    const {
      match,
    } = this.props;
    const { eventId } = match.params;
    this.props.onMount({
      eventId,
    });
    if (this.props.$$eventState.get("contentType") === 'announce') {
      let newState = {
        questionActive: this.props.$$eventState.get("contentId"),
      };
      this.setState(newState);
    }

    if (
      this.props.$$announceQuestions !== undefined &&
      this.props.$$announceQuestions.get("rows").size > 0
    ) {
      this.setState({
        questionSelected: this.props.$$announceQuestions
        .get("rows")
        .get(0)
        .get("id"),
      });
    }

    // const $$initValues = uri.from && uri.from === 'rpps' ? Immutable.fromJS({
  }
  componentDidUpdate(oldProps, oldState) {
    // Keep
    if (
      oldProps.$$announceQuestions === undefined &&
      this.props.$$announceQuestions !== undefined &&
      this.props.$$announceQuestions.get("rows").size > 0
    ) {
      // console.log(
      //   "test forcing question to",
      //   this.props.$$announceQuestions.get("rows").get(0).toJS()
      // );
      this.setState({
        questionSelected: this.props.$$announceQuestions
        .get("rows")
        .get(0)
        .get("id"),
      });
    }

    // if (
    //   oldProps.$$announceQuestions === undefined &&
    //   this.props.$$announceQuestions !== undefined &&
    //   this.props.$$announceQuestions.get("rows").size > 0
    // ) {
    //   // console.log(
    //   //   "test forcing question to",
    //   //   this.props.$$announceQuestions.get("rows").get(0).toJS()
    //   // );
    //   this.setState({
    //     questionSelected: this.props.$$announceQuestions
    //     .get("rows")
    //     .get(0)
    //     .get("id"),
    //   });
    // }
    //
    // if (this.props.$$eventState !== undefined) console.log(this.props.$$eventState.toJS())
    if (oldProps.$$eventState !== this.props.$$eventState) {
      // const { $$eventState, sendMessage } = this.props;
      // const { questionActive, questionSelected } = this.state;
      if (this.props.$$eventState.get("contentType") === 'announce') {
        let newState = {
          questionActive: this.props.$$eventState.get("contentId"),
        };
        this.setState(newState);
      }
    }
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }
  // _getQuestion = (questionId) => {
  //   const { $$announceQuestions, $$eventState } = this.props;
  //   let $$content;
  //   console.log('DEBUG D', questionId)
  //   $$content = this._getItemAnnounce(questionId)
  //   return $$content;
  // };
  _onContentActivate = (questionId) => {
    const { questionActive } = this.state;
    const {
      match,
      setEventState,
    } = this.props;
    const { eventId } = match.params;
// console.log(questionActive, questionId)
    if (questionId !== questionActive) {
      const $$content = this._getItemAnnounce(questionId);
      // console.log('DEBUG disable after this', $$content.toJS())
      this.setState({
        questionActive: $$content.get("id")
      }, () => {
        setEventState({
          eventId,
          contentId: $$content.get("id"),
          // contentType: $$content.get("announceType"),
          contentType: 'announce',
        });
      });
    } else {
      // console.log('questions are same, disabling')
      // const $$content = this._getQuestion(questionId);
      this.setState({
        questionActive: false
      }, () => {
        setEventState({
          eventId,
          contentId: "none",
          contentType: "none",
        });

      })
    }
  };
  _onQuestionSelect = (questionId) => {
    // const {} = this.props;
    console.log('SELECTING ', questionId)
    const { questionSelected } = this.state;
    if (questionId !== questionSelected)
    this.setState({
      questionSelected: questionId,
    });
  };
  // _onAnnounceShowResults = (questionId) => {
  //   // const { clearAnnounceManagerResults, $$results } = this.props;
  //   const { $$results } = this.props;
  //   const questionShowResults =
  //   $$results !== null && $$results !== undefined && $$results !== false;
  //   const {
  //     match,
  //     // eventId,
  //     // $$content,
  //     setAnnounceManagerOption,
  //   } = this.props;
  //   const { eventId } = match.params;
  //   const { questionActive } = this.state;
  //   if (questionShowResults === undefined || questionShowResults === false) {
  //     // this.setState({
  //     //   questionShowResults: true
  //     // }, () => {
  //     setAnnounceManagerOption({
  //       eventId,
  //       questionId: questionActive,
  //       name: "showResults",
  //       value: true,
  //     });
  //     // })
  //   } else {
  //     this.setState(
  //       {
  //         questionShowResults: undefined,
  //       },
  //       () => {
  //         setAnnounceManagerOption({
  //           eventId,
  //           questionId: questionActive,
  //           name: "showResults",
  //           value: false,
  //         });
  //       }
  //     );
  //   }
  // };

  _onAnnounceDeleteConfirmed = async ($$item) => {
    const url = config.announce + "/delete";
    const token = await getToken();
    const data = { id: $$item.get("id"), csrf: token };
    fetch(url, getFetchOptions("POST", data))
    .then((response) => response.json())
    .then((response) => {
      if (response.deleted && response.deleted === "complete") {
        const { eventId, onMount } = this.props;
        this.setState({
          dialogDeleteState: undefined,
          questionSelected: undefined,
        });
        onMount({ eventId });
        // this.setState({redirectToList: true, locked: false});
      }
    });
  };
  _onAnnounceDeleteCanceled = ($$item) => {
    this.setState({ dialogDeleteState: undefined });
  };
  _onAnnounceDelete = () => {
    const { questionSelected } = this.state;
    console.log({'delete': questionSelected})
    this.setState({
      dialogDeleteState: Immutable.fromJS({ id: questionSelected }),
    });
  };

  // _onAnnounceResetConfirmed = ($$item) => {
  //   const {
  //     // eventId,
  //     match,
  //     resetAnnounceQuestion,
  //   } = this.props;
  //   const { eventId } = match.params;
  //   resetAnnounceQuestion({
  //     eventId,
  //     questionId: $$item.get("id"),
  //   });
  //   this._onAnnounceResetCanceled();
  // };
  // _onAnnounceResetCanceled = ($$item) => {
  //   this.setState({ dialogResetState: undefined });
  // };
  // _onAnnounceReset = () => {
  //   const { questionSelected } = this.state;
  //   this.setState({
  //     dialogResetState: Immutable.fromJS({ id: questionSelected, kind: 'announce' }),
  //   });
  // };
  _getItemAnnounce = (questionId) => {
    const { $$announceQuestions } = this.props;
    return $$announceQuestions
    .get("rows")
    .filter(($$q) => $$q.get("id") === questionId)
    .get(0);
  }

  render() {
    const {
      $$user,
      classes,
      $$announceQuestions,
      match,
      // $$results,
      $$eventState,
      history,
    } = this.props;
    const { eventId } = match.params;
    const {
      questionSelected,
      questionActive,
      dialogDeleteState,
      // dialogResetState,
    } = this.state;
    // const $$event = Immutable.fromJS({
      // id: eventId,
    // });
    // console.log({questionShowResults})
    // console.log({$$results})
    // console.log({
    //   questionActive
    // })
    // const questionTypes = { poll: "Sondage", announce: "Question" };
    // if ($$user !== undefined)
    const userRole = $$user !== undefined ? $$user.get("role") : "visitor";
    // const userSubRole =
    // $$user !== undefined ? $$user.get("subRole") : undefined;

    const isAdmin = userRole === "admin";
    let $$selectorOptions = Immutable.fromJS([]);

    if ($$announceQuestions !== undefined)
    $$selectorOptions = $$announceQuestions.get("rows").map(($$r) =>
    Immutable.fromJS({
      label:
      "N°" +
      $$r.get("announcePosition") +
      " : " +
      $$r.get("announceTitle"),
      value: $$r.get("id"),
    })
  )
  // } else if (activeTab === "announce" && $$announceQuestions !== undefined) {
  //   $$selectorOptions = $$announceQuestions.get("rows").map(($$r) =>
  //   Immutable.fromJS({
  //     label: $$r.get("announceQuestion"),
  //     value: $$r.get("id"),
  //   })
  // )
  // }
  // console.log({questionActive, questionSelected})

  return (<Grid xs={12} item container>
    <Paper className={classes.paperWrapper}>
      <Box m={3} align="center" display="block">
        <Typography variant="h3" gutterBottom>
          Annonces
        </Typography>

        <Grid container style={{ width: "100%", marginBottom: 30 }}>
          <Grid item xs={12} align="center">
            <FormGroup row={true}>
              <Typography variant="subtitle1">
                Selectionnez une annonce afin de la visualiser
              </Typography>

              <Select
                style={{ width: "100%" }}
                variant="outlined"
                fullWidth={false}
                name="questionSelector"
                id="questionSelected"
                value={questionSelected}
                onFocus={(e) => {
                  this.props.refreshQuestions({ eventId });
                }}
                onChange={(k, o) => {
                  this._onQuestionSelect(o.value);
                }}
                options={$$selectorOptions}
                makeItems={({ options, selectValue, multiple }) => {
                  return options
                  .map((opt, idx) => {
                    return (
                      <MenuItem
                        key={"o_" + idx}
                        value={opt.get("value")}
                        >
                        <Typography
                          variant={"body1"}
                          display="inline"
                          >
                          {opt.get("label")}
                        </Typography>
                        <If
                          condition={
                            opt.get("value") === questionActive
                          }
                          >
                          <Typography
                            variant={"body1"}
                            color="primary"
                            display="inline"
                            >
                            &nbsp;Activée !
                          </Typography>
                        </If>
                      </MenuItem>
                    );
                  })
                  .toArray();
                }}
                />
            </FormGroup>
          </Grid>
        </Grid>
        <If condition={questionSelected === undefined}>
          <If condition={isAdmin === true}>
            <Grid item xs={12} container alignContent={"center"}>
              <Grid
                item
                sm={12}
                md={4}
                container
                justifyContent={"flex-start"}
                >
                <Button
                  color="secondary"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/staff/announce/" + eventId + "/edit");
                  }}
                  >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </If>
        </If>
        <If condition={questionSelected !== undefined}>
          <Grid item xs={12} container alignContent={"center"}>
            <Grid
              item
              sm={12}
              md={4}
              container
              justifyContent={"flex-start"}
              className={classes.leftColumn}
              >
              <If
                condition={
                  questionActive === questionSelected &&
                  questionSelected !== "none" && $$eventState.get('contentType') === 'announce'
                }
                >
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    this._onContentActivate(questionSelected);
                  }}
                  >
                  Désactiver
                </Button>
              </If>
              <If
                condition={
                  (questionActive !== questionSelected &&
                    questionSelected !== "none") || $$eventState.get('contentType') !== 'announce'
                  }
                  >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      this._onContentActivate(questionSelected);
                    }}
                    >
                    Activer
                  </Button>
                </If>
                <If condition={isAdmin === true}>
                  <Divider orientation="vertical" />
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/staff/announce/" + eventId + "/edit");
                    }}
                    >
                    Ajouter
                  </Button>
                </If>
              </Grid>
              <Grid
                item
                sm={12}
                md={8}
                container
                justifyContent={"flex-end"}
                className={classes.rightColumn}
                >
                <If
                  condition={
                    isAdmin === true && questionSelected !== "none" && questionSelected !== questionActive
                  }
                  >
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      this._onAnnounceDelete();
                    }}
                    >
                    Supprimer
                  </Button>
                  <Divider orientation="vertical" />
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        "/staff/announce/" +
                        eventId +
                        "/edit/" +
                        questionSelected
                      );
                    }}
                    >
                    Editer
                  </Button>
                </If>
                <If
                  condition={
                    questionSelected === questionActive &&
                    questionActive !== "none"
                  }
                  >
                  </If>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 30 }}>
                  <Box className={classes.popinPlaceholder}>
                    <Announce
                      eventId={eventId}
                      questionId={questionSelected}
                      embed={true}
                      revision={$$eventState.get("contentRevision")}
                      pull={USE_WEBSOCKET === false}
                      />
                  </Box>
                </Grid>
              </Grid>
            </If>
          </Box>
        </Paper>
        <If
          condition={
            dialogDeleteState !== undefined &&
            dialogDeleteState !== false &&
            dialogDeleteState !== null
          }
          >
          <DialogConfirm
            open={true}
            title="Confirmation de suppression"
            onConfirmed={(e) => {
              if (e) e.preventDefault();
              this._onAnnounceDeleteConfirmed(dialogDeleteState);
            }}
            onCanceled={this._onAnnounceDeleteCanceled}
            >
            <Typography align="center" gutterBottom>
              Souhaitez vous supprimer cette annonce ?
            </Typography>
          </DialogConfirm>
        </If>
        {/*<If
          condition={
            dialogResetState !== undefined &&
            dialogResetState !== false &&
            dialogResetState !== null
          }
          >
          <DialogConfirm
            open={true}
            title="Confirmation de remize à zéro"
            onConfirmed={() => {
              if (dialogResetState.get('kind') === 'announce')
              this._onAnnounceResetConfirmed(dialogResetState);
              else if (dialogResetState.get('kind') === 'feedback')
              this._onFeedbackResetConfirmed(dialogResetState);
            }}
            onCanceled={() => {
              if (dialogResetState.get('kind') === 'announce')
              this._onAnnounceResetCanceled()
              else if (dialogResetState.get('kind') === 'feedback')
              this._onFeedbackResetCanceled()
            }
          }
          >
          <If condition={dialogResetState.get('kind') === 'announce'}>
            <Typography align="center" gutterBottom>
              Souhaitez vous remettre à zéro cette question ?<br /> Cela
              effacera toutes les réponses des participants.
            </Typography>
          </If>
          <If condition={dialogResetState.get('kind') === 'feedback'}>
            <Typography align="center" gutterBottom>
              Souhaitez vous remettre à zéro le feedback ?<br /> Cela
              effacera toutes les réponses des participants.
            </Typography>
          </If>
        </DialogConfirm>
      </If>*/}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    // isLoggedIn: isLoggedIn(state),
    $$user: getCurrentUser(state),
    $$announceQuestions: getAnnouncesData(state),
    // $$announceQuestions: getAnnouncesData(state),
    // $$content: getContent(state),
    // $$managerResults: getAnnounceManagerResults(state),
    // $$results: getAnnounceResults(state),
    $$eventState: getEventState(state),
    $$adminEventState: getAdminEventState(state),
    // feedbackReset: getFeedbackReset(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: (props) => {
      if (props.eventId !== undefined) {
        dispatch(fetchAnnounces(props));
        // dispatch(fetchAnnounces(props));
        dispatch(fetchEventState(props));
      }
    },
    onUnmount: () => {
      // dispatch(clearAnnounceQuestions());
      dispatch(clearAnnounceData());
    },
    refreshQuestions: (props) => {
      if (props.eventId !== undefined) {
        dispatch(fetchAnnounces(props));
      }
    },
    // refreshAnnounces: (props) => {
    //   if (props.eventId !== undefined) {
    //     dispatch(fetchAnnounces(props));
    //   }
    // },
    sendMessage: (payload) => {
      dispatch(sendMessage(payload));
    },
    // setAnnounceManagerOption: (props) => {
    //   if (props.eventId !== undefined) {
    //     dispatch(setAnnounceManagerOption(props));
    //   }
    // },
    // clearAnnounceManagerResults: () => {
    //   dispatch(clearAnnounceManagerResults());
    // },
    // resetAnnounceQuestion: (props) => {
    //   dispatch(resetAnnounceQuestion(props));
    // },
    // resetFeedback: (props) => {
    //   dispatch(resetFeedback(props));
    // },
    setEventState: (props) => {
      dispatch(setEventState(props));
    },
    setAdminEventState: (props) => {
      dispatch(setAdminEventState(props));
    },
  };
}
// export default ;
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AdminOrchestratorAnnounce)));
